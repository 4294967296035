import { useEffect } from "react";

import RegisterOrLoginButton, { ILoyaltyInfoIconProps } from "./RegisterOrLoginButton";
import useAppContext from "../../../../useAppContext";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

export default function IncustAuth(props: ILoyaltyInfoIconProps) {
	const {
		brandInfo,
		authService: { user, setShowAuthorisationModal },
	} = useAppContext();

	const { customerData } = useUserIncustCustomerData();

	useEffect(() => {
		setShowAuthorisationModal(false);
	}, [customerData?.token, setShowAuthorisationModal]);

	if (
		!!user ||
		!brandInfo?.loyalty_info?.loyalty_enabled ||
		brandInfo.loyalty_info.loyalty_type !== "incust"
	)
		return null;

	return (
		<RegisterOrLoginButton
			localizations={props.localizations}
			description={props.description}
		/>
	);
}

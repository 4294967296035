import {useEffect, useRef, useState} from "react";
import api from "../../../api";
import {GetMinMaxProductsPricesArgs, MinMaxProductsPrices} from "../../../api/shop/basic/types";
import {useSelectedStoreContext} from "../../SelectedStore/context";


export default function useMinMaxPrices(): {
    min: number
    max: number
    isLoading: boolean
} {
    const {
        selectedStore,
        categoriesService,
        filtersService,
    } = useSelectedStoreContext()

    const [minMaxPrices, setMinMaxPrices] = useState<MinMaxProductsPrices>({
        min: 0,
        max: 0,
    })


    const [isLoading, setIsLoading] = useState(false)

    const lastRequestArgsRef = useRef<GetMinMaxProductsPricesArgs | null>(null)

    useEffect(() => {
        const args: GetMinMaxProductsPricesArgs = {
            category_id: categoriesService.selectedCategoryId,
            filters_set_id: filtersService.filtersSetId,
        }

        const lastArgs = lastRequestArgsRef.current

        if (
            lastArgs &&
            args.category_id === lastArgs.category_id &&
            args.filters_set_id === lastArgs.filters_set_id
        ) {
            return
        }

        lastRequestArgsRef.current = args

        setIsLoading(true)

        api.shop.basic.getMinMaxProductsPrices(selectedStore.id, args).then(response => {
            const currentArgs = lastRequestArgsRef.current
            if (
                !currentArgs ||
                currentArgs.category_id !== args.category_id ||
                currentArgs.filters_set_id !== args.filters_set_id
            ) return

            setMinMaxPrices(response.data)
            setIsLoading(false)
        }).catch(err => {
            console.log(err)
            const currentArgs = lastRequestArgsRef.current
            if (
                currentArgs &&
                currentArgs.category_id === args.category_id &&
                currentArgs.filters_set_id === args.filters_set_id
            ) {
                setIsLoading(false)
            }
        })
    }, [selectedStore.id, categoriesService.selectedCategoryId, filtersService.filtersSetId])

    useEffect(() => {
        if (filtersService.maxPrice && (
            filtersService.maxPrice > minMaxPrices.max ||
            filtersService.maxPrice < minMaxPrices.min
        )) {
            filtersService.setMaxPrice(minMaxPrices.max)
        } else if (filtersService.minPrice && (
            filtersService.minPrice < minMaxPrices.min ||
            filtersService.minPrice > minMaxPrices.max
        )) {
            filtersService.setMinPrice(minMaxPrices.min)
        } else if (filtersService.minPrice === minMaxPrices.min) {
            filtersService.setMinPrice(null)
        } else if (filtersService.maxPrice === minMaxPrices.max) {
            filtersService.setMaxPrice(null)
        }
    }, [minMaxPrices.max, filtersService.maxPrice, filtersService, minMaxPrices.min])

    return {
        ...minMaxPrices,
        isLoading,
    }
}

import {useEffect, useState, useMemo, useCallback} from "react";

export default function useTipsService(
    tipAmount: number,
    setTipAmount: (amount: number) => void,
    totalAmount: number,
) {
    const [selectedTipPercent, setSelectedTipPercent] =
        useState<number>(0)
    const [isUserEnteredSum, setIsUserEnteredSum] =
        useState<boolean>(false)
    // TODO: need to show rounded sum difference?
    const [roundingSum, setRoundingSum] = useState<number>(0)

    const roundingValue = 1
    const tipPercentItems = useMemo(() => [0, 5, 10, 20], [])

    const computedIsAvailableRounding = useMemo(() => {
        const result = (totalAmount + tipAmount) / roundingValue
        return !Number.isInteger(result)
    }, [tipAmount, totalAmount])

    const calcRoundingSum = useCallback(() => {
        const roundedValue = Math.ceil(
            (totalAmount + tipAmount) / roundingValue) * roundingValue

        setRoundingSum(roundedValue - totalAmount)

        setTipAmount(Number((tipAmount + (roundedValue - (totalAmount + tipAmount))).toFixed(2)))
    }, [setTipAmount, tipAmount, totalAmount])

    const resetRounding = useCallback(() => {
        setTipAmount(Number(
            (totalAmount * selectedTipPercent / 100).toFixed(2))
        )
        setRoundingSum(0)
    }, [selectedTipPercent, setTipAmount, totalAmount])

    useEffect(() => {
        if(!isUserEnteredSum){
            if(selectedTipPercent === 0){
                setTipAmount(0)
            }
            else {
                setTipAmount(Number(
                    (totalAmount * selectedTipPercent / 100).toFixed(2))
                )
            }
        }
        setRoundingSum(0)
    }, [isUserEnteredSum, selectedTipPercent, setTipAmount, totalAmount])

    return useMemo(() => ({
        selectedTipPercent,
        setSelectedTipPercent,
        isUserEnteredSum,
        setIsUserEnteredSum,
        tipPercentItems,
        computedIsAvailableRounding,
        calcRoundingSum,
        resetRounding,
    }), [
        selectedTipPercent, isUserEnteredSum,
        tipPercentItems, computedIsAvailableRounding,
        calcRoundingSum, resetRounding,
    ])
}

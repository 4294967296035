import { useState, useCallback, useEffect } from "react";

import useDebounced from "../../hooks/useDebounced";
import {
	IAddressComponent,
	IGeocodingResultItem,
	IReverseGeocodingParams,
} from "../../../api/map/types";
import api from "../../../api";
import useAppContext from "../../../useAppContext";
import { Coordinates, IAddressValid } from "../../AddressInput/AddressInput";

export default function useCenterChanged(
	map: any,
	setDragStarted: (value: boolean) => void,
	validateAndSave: (
		addrComponents: IAddressComponent[],
		address: string,
		coords: [number, number],
		placeId: string | null,
		isGoogle: boolean,
		fromMap: boolean
	) => Promise<boolean>,
	lang: string,
	businessLanguage: string | null | undefined,
	centerLat?: number,
	centerLng?: number,
	setValidAddress?: (value: IAddressValid | null) => void,
	confirmCallback?: (address: IGeocodingResultItem) => void,
	enabledAnyAddressFromMap?: boolean,
	userLocationCoords?: Coordinates | null
): IUseCenterChangedType {
	const {
		localisation: { maps },
	} = useAppContext();

	const [selectedAddress, setSelectedAddress] = useState<IGeocodingResultItem | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [prevCoords, setPrevCoords] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [similarPredictions, setSimilarPredictions] = useState<IGeocodingResultItem[]>([]);

	const getFilteredPrediction = useCallback(
		(predictions: IGeocodingResultItem[]): IGeocodingResultItem | null => {
			setSimilarPredictions([]);

			const filteredByStreetAddress = predictions.filter(
				item => item.types && item.types.includes("street_address")
			);

			if (filteredByStreetAddress.length === 1) return filteredByStreetAddress[0];
			if (filteredByStreetAddress.length > 1) {
				setSimilarPredictions(filteredByStreetAddress.slice(1));
				return filteredByStreetAddress[0];
			}

			return predictions[0];
		},
		[]
	);

	const onCenterChanged = useDebounced(
		async (force: boolean = false) => {
			console.log("*** ON CENTER CHANGED");
			setDragStarted(false);
			if (map) {
				// @ts-ignore
				const lat = map.getCenter().lat();
				// @ts-ignore
				const lng = map.getCenter().lng();
				const coords = `${lat},${lng}`;
				const centerCoords = `${centerLat},${centerLng}`;
				if ((coords === prevCoords || coords === centerCoords) && !force) return;
				setPrevCoords(coords);

				try {
					setError(null);
					setIsLoading(true);
					const args: IReverseGeocodingParams = {
						latlng: coords,
						language: lang,
						location_type: "ROOFTOP",
					};
					if (businessLanguage) args.language = businessLanguage;
					const response = await api.mapGoogle.reverseGeocoding(args);
					const prediction = getFilteredPrediction(response.data.results);

					if (prediction) {
						if (enabledAnyAddressFromMap) {
							setValidAddress &&
								setValidAddress({ valid: true, purpose: "default", text: "" });
						} else {
							const validateResponse = await api.mapGoogle.validateAddress(
								prediction.address_components
							);
							if (validateResponse.data) {
								setValidAddress && setValidAddress(validateResponse.data);
							}
						}
						const valid = await validateAndSave(
							prediction.address_components,
							prediction.formatted_address,
							[lng, lat],
							prediction.place_id,
							true,
							true
						);
						if (valid) confirmCallback && confirmCallback(prediction);
						setSelectedAddress(prediction);
					} else {
						setError(maps.zeroResultsError);
					}
				} catch (ex: any) {
					setError(
						ex.response?.data?.detail || maps.mapsError.replace("{status}", "unknown")
					);
				} finally {
					setIsLoading(false);
				}
			}
		},
		[
			setDragStarted,
			map,
			centerLat,
			centerLng,
			prevCoords,
			lang,
			businessLanguage,
			getFilteredPrediction,
			validateAndSave,
			confirmCallback,
			setValidAddress,
			maps.zeroResultsError,
			maps.mapsError,
			enabledAnyAddressFromMap,
		],
		1000
	);

	useEffect(() => {
		if (!!userLocationCoords) {
			map.setCenter({
				lat: userLocationCoords.lat,
				lng: userLocationCoords.lon,
			});
			onCenterChanged(true);
		}
	}, [userLocationCoords, map]); //TODO: unstable onCenterChanged

	return {
		selectedAddress,
		isLoading,
		error,
		onCenterChanged,
		similarPredictions,
		setSimilarPredictions,
		setSelectedAddress,
	};
}

export interface IUseCenterChangedType {
	selectedAddress: IGeocodingResultItem | null;
	isLoading: boolean;
	error: string | null;
	onCenterChanged: (force?: boolean) => void;
	similarPredictions: IGeocodingResultItem[];
	setSimilarPredictions: (value: IGeocodingResultItem[]) => void;
	setSelectedAddress: (value: IGeocodingResultItem | null) => void;
}

import { useParams } from "react-router-dom";
import useAppContext from "../../useAppContext";
import Coupon from "./components/Coupon/Coupon";
import { NonModalDialogPage } from "../../features/NonModalDialog";

export default function CouponPage() {
	const { setPageTitle } = useAppContext();

	const { codeOrId } = useParams();
	setPageTitle("7Loc");

	return (
		<NonModalDialogPage
			containerProps={{
				maxWidth: "md",
			}}
		>
			<Coupon codeOrId={codeOrId || null} showOnPage={true} />
		</NonModalDialogPage>
	);
}

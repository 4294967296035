import { BotType } from "../../api/bots/types";

export default function useMessangerName(botType?: BotType | null | undefined) {
	if (!botType) {
		return "";
	}

	const MessangersNames = {
		telegram: "Telegram",
		whatsapp: "WhatsApp",
	};
	return MessangersNames[botType] ?? "";
}

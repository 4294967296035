import { TgLink } from "../../../../helpers/ThemeComponents";
import changePage from "../../../../helpers/changePage";
import { OrderStepperLastStepButtons } from "./OrderStepperButtons";
import { useEffect, useState } from "react";
import { Alert, Box, Link, TextField } from "@mui/material";
import {
	BillingAddress,
	CreateOrderPayload,
	NotAvailableProduct,
	Order,
} from "../../../../api/shop/order/types";
import { SetMergeState } from "../../../../helpers/useMergedState";
import useAppContext from "../../../../useAppContext";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import { SelectedShipmentServiceType } from "../useSelectedShipmentService";
import { SetState } from "../../../../types";
import { IUseIncustServiceType } from "../../../../features/services/useIncustService/types";
import { Check } from "../../../../features/Check";
import DetailedProductModal from "../../../menu/product/DetailedProductModal";
import CartNote from "../../../CartNote";
import AgreementCheckbox from "../../../../auth/Autorisation/inputs/AgreementCheckbox";
import Interweave from "../../../../features/Interweave";
import NeedAuthCartAlert from "../../../cart/NeedAuthCartAlert";
import { IShipmentPricesService } from "../../../services/useShipmentPrices";
import useAuthSettings from "../../../../services/useAuthSettings";
import useEpayLibraries from "../../../payments/hooks/useEpayLibraries";
import { IUsePaymentsService } from "../../../payments/hooks/useNewPaymentsService";
import useExtraFee from "../../../payments/hooks/useExtraFee";
import { IUseUpdateOrder } from "../useUpdateOrder";
import MarketingCheckbox from "../../../../auth/Autorisation/inputs/MarketingCheckbox";
import ConsentWrapper from "../../../../auth/Autorisation/inputs/ConsentWrapper";
import ConsentInfo from "../../../../auth/Autorisation/inputs/ConsentInfo";

interface ConfirmationStepProps {
	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;

	billingForm: BillingAddress;
	setBillingForm: SetMergeState<BillingAddress>;

	paymentsService: IUsePaymentsService;
	selectedShipmentService: SelectedShipmentServiceType;

	needLoginForEmail: boolean;
	setNeedLoginForEmail: SetState<boolean>;

	errorText: string | null;
	setErrorText: SetState<string | null>;

	setCreatedOrder: SetState<Order | null>;
	createdOrder: Order | null;

	computedTotalAmount: number;

	notAvailableProducts: NotAvailableProduct[];
	setNotAvailableProducts: (products: NotAvailableProduct[]) => void;

	setPrevStep: () => void;
	setNextStep: () => void;

	incustService: IUseIncustServiceType;

	shipmentPricesService: IShipmentPricesService;

	setShowPayments: (val: boolean) => void;
	updateOrderService: IUseUpdateOrder;
}

export default function ConfirmationStep(props: ConfirmationStepProps) {
	const {
		lang,
		localisation,
		brandInfo,
		authService: { user, setShowAuthorisationModal },
		groupService,
	} = useAppContext();
	const { selectedStore, cartService, productsService } = useSelectedStoreContext();
	const extraFeeService = useExtraFee(
		{ totalSum: props.computedTotalAmount, sumToPay: props.computedTotalAmount },
		selectedStore.id,
		selectedStore.currency
	);

	useEpayLibraries(
		props.paymentsService.methods.some(i => i.provider === "epay") || false,
		props.paymentsService.paymentsInfo?.is_epay_test_mode || false
	);

	const isOfferEnabled = brandInfo?.is_offer_doc_exist || selectedStore.is_offer_doc_exist;

	const authSettings = useAuthSettings(brandInfo?.group_id);

	const setSums = props.paymentsService.setSums;
	useEffect(() => {
		setSums({
			totalSum: props.computedTotalAmount,
			sumToPay: extraFeeService.calcTotalSumWithExtraFee + (props.form.tips_sum || 0),
		});
	}, [
		extraFeeService.calcTotalSumWithExtraFee,
		props.computedTotalAmount,
		props.form.tips_sum,
		setSums,
	]);

	return (
		<Box>
			<Check
				data={{
					toName:
						props.form.first_name && props.form.last_name
							? `${props.form.first_name} ${props.form.last_name}`
							: props.form.first_name || props.form.last_name,
					onlyName: true,
					email: props.form.email,
					phone: props.form.phone,
					deliveryMethodName: props.selectedShipmentService.selectedShipment?.name,
					deliveryMethodBaseType: props.selectedShipmentService.base_type,
					deliveryPickupPointName: selectedStore.name,
					deliveryAddress: props.form.delivery_address,
					deliveryMethodCommentLabel:
						props.selectedShipmentService.selectedShipment?.custom_type ===
						"custom_shipment"
							? props.selectedShipmentService.selectedShipment.label_comment
							: null,
					deliveryMethodComment: props.form.custom_shipment_comment,
					desiredDeliveryDate: props.form.desired_delivery_date
						? new Date(props.form.desired_delivery_date).toLocaleDateString(
								lang || "en",
								{
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
									hour: "2-digit",
									minute: "2-digit",
								}
							)
						: null,

					items: cartService.cart?.cart_products?.map(cartProduct => ({
						code: cartProduct.product.product_id,
						name: cartProduct.product.name,
						quantity: cartProduct.quantity,
						price: cartProduct.floating_sum || cartProduct.product.price,
						sum: cartProduct.floating_sum
							? cartProduct.floating_sum * cartProduct.quantity
							: cartProduct.product.price * cartProduct.quantity,
						discount_sum: props.incustService.processedCheck?.check_items?.find(
							item => {
								// check price if multiple products with the same code
								// (backend bot/db/crud/store/order/create make_order_product)
								if (
									cartProduct.product.attribute_groups &&
									cartProduct.product.attribute_groups.length > 0
								) {
									const prodTotalSum =
										cartService.calculateProductTotalPrice(cartProduct);
									let prodTotalSumOne = cartProduct.product.price;
									if (prodTotalSum) {
										prodTotalSumOne = prodTotalSum / cartProduct.quantity;
									}

									return (
										item.code === cartProduct.product.product_id &&
										item.price === prodTotalSumOne
									);
								} else {
									return item.code === cartProduct.product.product_id;
								}
							}
						)?.calculated_discount_amount,
						onClick: () => {
							cartService.setSelectedVariationCartProductId(cartProduct.id);
							productsService.selectProduct(cartProduct.product);
						},
						thumbnail_url: cartProduct.product.thumbnail_url,
						attributes: cartProduct.cart_attributes?.map(cartAttr => ({
							code: cartAttr.attribute.attribute_id,
							name: cartAttr.attribute.name,
							quantity: cartAttr.quantity,
							price: cartAttr.attribute.price_impact,
							sum: cartAttr.attribute.price_impact * cartAttr.quantity,
						})),
					})),
					subtotal: cartService.calculateCartTotalSum(),
					totalDiscount:
						(props.incustService.processedCheck?.discount_amount || 0) +
						(props.incustService.processedCheck?.bonuses_redeemed_amount || 0),
					deliveryPrice:
						props.selectedShipmentService.selectedShipmentPrice?.cost_delivery,
					isDeliveryPaymentSeparately:
						props.selectedShipmentService.selectedShipment?.is_paid_separately,
					totalAmount: props.computedTotalAmount,
					tips: props.form.tips_sum,
					totalAmountWithTips:
						extraFeeService.calcTotalSumWithExtraFee + (props.form.tips_sum || 0),
					isLoyaltyAwards:
						!!props.incustService.processedCheck?.bonuses_added_amount ||
						!!props.incustService.processedCheck?.special_accounts_charges?.length ||
						!!props.incustService.processedCheck?.emitted_coupons?.length,
					bonuses: props.incustService.processedCheck?.bonuses_added_amount,
					couponsCount: props.incustService.processedCheck?.emitted_coupons?.length,
					customerAccounts: props.incustService.processedCheck?.special_accounts_charges,
					viewOrder: true,
					type: "regular",
					payer_fee: props.paymentsService.calculateFeeRange(),
					payer_fee_row: props.paymentsService.showPayerFeeRow,
					sum_to_pay: props.paymentsService.calculateFeeRange(true),
					extraFee: extraFeeService.extraFees,
					total_sum_with_extra_fee: extraFeeService.calcTotalSumWithExtraFee,
				}}
				afterItems={
					<Link
						variant={"body2"}
						role={"button"}
						onClick={() => changePage(`/shop/${selectedStore.id}/cart`)}
					>
						{localisation.orders.editItems}
					</Link>
				}
			/>

			<CartNote selectedShipmentService={props.selectedShipmentService} />

			{props.selectedShipmentService.selectedShipment?.is_paid_separately && (
				<div className="small fw-bold theme-text">
					{localisation.orders.separateDeliveryMsg}
				</div>
			)}

			{brandInfo?.order_fields_settings.order_comment_mode !== "disabled" && (
				<TextField
					multiline
					maxRows={10}
					size={"small"}
					required={brandInfo?.order_fields_settings.order_comment_mode === "required"}
					fullWidth
					label={groupService.group?.texts.web.order.order_comment_label}
					value={props.form.comment || ""}
					onChange={e => props.setForm({ comment: e.target.value })}
					sx={{ mt: 4 }}
				/>
			)}

			<ConsentWrapper>
				<>
					<AgreementCheckbox
						value={props.form.is_accepted_agreement || false}
						setValue={newValue => props.setForm({ is_accepted_agreement: newValue })}
						isOfferEnabled={isOfferEnabled}
					/>
					{!!user &&
						(!user.marketing_consent || brandInfo?.consent_mode === "per_order") && (
							<MarketingCheckbox
								value={!!props.form.marketing_consent}
								setValue={newValue =>
									props.setForm({ marketing_consent: newValue })
								}
							/>
						)}
				</>
			</ConsentWrapper>

			{props.needLoginForEmail && (
				<div className="small fw-bold text-danger">
					<div>{localisation.global.emailExistMsg}</div>

					<div className={"mt-2"}>
						<TgLink
							className={"cursor-pointer"}
							onClick={() => setShowAuthorisationModal(true)}
						>
							{localisation.auth.loginRegisterButton}
						</TgLink>
					</div>
				</div>
			)}

			{!!props.errorText && (
				<Alert severity={"error"} variant={"outlined"} className={"small p-3"}>
					<Interweave content={props.errorText} />
				</Alert>
			)}

			{!user &&
				(!!cartService.computedNeedAuthProductsInCart.length ||
					authSettings.settings?.is_auth_for_orders_enabled) &&
				(authSettings.settings?.is_auth_for_orders_enabled ? (
					<NeedAuthCartAlert products={[]} />
				) : (
					<NeedAuthCartAlert products={cartService.computedNeedAuthProductsInCart} />
				))}

			{!!props.shipmentPricesService.error && (
				<Alert severity={"error"} variant={"outlined"} className={"small p-3"}>
					<Interweave content={props.shipmentPricesService.error.text} />
				</Alert>
			)}

			<DetailedProductModal />

			<OrderStepperLastStepButtons
				setPrevStep={props.setPrevStep}
				setNextStep={props.setNextStep}
				form={props.form}
				setForm={props.setForm}
				selectedShipmentService={props.selectedShipmentService}
				paymentsService={props.paymentsService}
				notAvailableProducts={props.notAvailableProducts}
				setNotAvailableProducts={props.setNotAvailableProducts}
				billingForm={props.billingForm}
				setNeedLoginForEmail={props.setNeedLoginForEmail}
				setErrorText={props.setErrorText}
				setCreatedOrder={props.setCreatedOrder}
				createdOrder={props.createdOrder}
				shipmentPricesService={props.shipmentPricesService}
				setShowPayments={props.setShowPayments}
				updateOrderService={props.updateOrderService}
			/>

			<ConsentInfo isOfferEnabled={isOfferEnabled} />
		</Box>
	);
}

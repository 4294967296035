import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ViewListIcon from "@mui/icons-material/ViewList";
import WalletIcon from "@mui/icons-material/Wallet";
import LoginIcon from "@mui/icons-material/Login";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PeopleIcon from "@mui/icons-material/People";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Box } from "@mui/material";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import { useShopContext } from "../../context";
import { useSelectedStoreOptional } from "../../SelectedStore/context";
import { LogoutButton } from "../../../auth/buttons";
import useAppContext from "../../../useAppContext";
import changePage from "../../../helpers/changePage";
import useScreenService from "../../../services/useScreenService";
import { AvatarObject } from "../../services/useProfileService/types";
import useCurrency from "../../../services/useCurrencyService";
import useIncustService from "../../../features/services/useIncustService";
import useUserIncustCustomerData from "../../../hooks/incust/useUserIncustCustmerData";

interface IProfileButtonProps {
	isRegister?: boolean;
}

export default function ProfileButton(props: IProfileButtonProps) {
	const { isMobile } = useScreenService();
	const {
		sidebarsService,
		profileService,
		authService: { setShowAuthorisationModal },
	} = useAppContext();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		if (isMobile && props.isRegister) return setShowAuthorisationModal(true);
		if (!isMobile) return setAnchorEl(event.currentTarget);
		sidebarsService.setIsProfileSidebarOpen(true);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<div className={"cursor-pointer"} onClick={handleClick}>
				<ProfileIconButton />
			</div>

			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							overflow: "visible",
							filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
							mt: 1.5,
							"& .MuiAvatar-root": {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							"&:before": {
								content: '""',
								display: "block",
								position: "absolute",
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: "background.paper",
								transform: "translateY(-50%) rotate(45deg)",
								zIndex: 0,
							},
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<ProfileMenuItems
					handleClose={handleClose}
					stringAvatar={profileService.stringAvatar}
					isRegister={props.isRegister || false}
				/>
			</Menu>
		</>
	);
}

function ProfileIconButton() {
	const { isMobile } = useScreenService();
	const {
		localisation,
		authService: { user },
	} = useAppContext();

	return (
		<Box
			className={"d-flex align-items-center"}
			sx={!isMobile ? { color: "primary.main", mx: 1 } : {}}
		>
			<div>
				<Box
					className={"white-space-nowrap align-self-center"}
					component="div"
					sx={{
						textOverflow: "ellipsis",
						overflow: "hidden",
						fontSize: ".975rem",
						maxWidth: { xs: "5rem", sm: "30rem", md: "auto" },
					}}
				>
					{!!user ? user.first_name || user.email : localisation.global.signIn}
				</Box>
			</div>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<ChevronRightOutlinedIcon
					sx={{
						width: 16,
						height: 16,
						marginRight: "-0.3rem",
					}}
				/>
				<PersonOutlinedIcon
					sx={{
						width: 40,
						height: 40,
					}}
				/>
			</Box>
		</Box>
	);
}

interface IProfileMenuItemsProps {
	handleClose: () => void;
	stringAvatar: AvatarObject;
	isRegister: boolean;
}

function ProfileMenuItems(props: IProfileMenuItemsProps) {
	const { brandInfo, webAppData } = useShopContext();
	const {
		localisation,
		authService: { setShowAuthorisationModal, user },
		groupService,
		lang,
		scanReceiptService,
	} = useAppContext();

	const { customerData } = useUserIncustCustomerData();

	const currency = useCurrency();
	const selectedStore = useSelectedStoreOptional();

	const incustService = useIncustService(
		brandInfo?.id,
		selectedStore?.id,
		brandInfo?.incust_data,
		lang,
		currency || ""
	);

	const handleProfileMenuItemClick = (path: string) => {
		try {
			if (selectedStore) {
				return changePage(`/shop/${selectedStore.id}/profile/${path}`);
			}
			return changePage(`/shop/profile/${path}`);
		} finally {
			props.handleClose();
		}
	};

	const handleRegisterClick = () => {
		setShowAuthorisationModal(true);
		props.handleClose();
	};

	const handleScan = () => {
		scanReceiptService.setShowModal(true);
		props.handleClose();
	};

	return (
		<>
			{!props.isRegister ? (
				<>
					<MenuItem onClick={() => handleProfileMenuItemClick("")}>
						<Avatar {...props.stringAvatar} />
						<span className={"ms-1"}>{localisation.profile.profile}</span>
					</MenuItem>
					<Divider />
					{!!(brandInfo.loyalty_info?.loyalty_enabled && user) && (
						<>
							{incustService.loyaltyReferralSettings?.referral_program?.active && (
								<MenuItem
									onClick={() => handleProfileMenuItemClick("share_and_earn")}
								>
									<ListItemIcon>
										<PaidOutlinedIcon
											className={"theme-text"}
											fontSize="small"
										/>
									</ListItemIcon>
									{groupService.group?.texts.web.loyalty.referral_system
										.info_button || localisation.profile.shareAndEarn}
								</MenuItem>
							)}
						</>
					)}
					{brandInfo.stores_count > 0 && (
						<>
							<MenuItem onClick={() => handleProfileMenuItemClick("orders")}>
								<ListItemIcon>
									<ViewListIcon className={"theme-text"} fontSize="small" />
								</ListItemIcon>
								{localisation.orders.myOrders}
							</MenuItem>
							<MenuItem onClick={() => handleProfileMenuItemClick("favorites")}>
								<ListItemIcon>
									<FavoriteIcon className={"theme-text"} fontSize="small" />
								</ListItemIcon>
								{localisation.profile.favoritesHeader}
							</MenuItem>
						</>
					)}

					{brandInfo.is_friend_payment && (
						<MenuItem onClick={() => handleProfileMenuItemClick("friends")}>
							<ListItemIcon>
								<PeopleIcon className={"theme-text"} fontSize="small" />
							</ListItemIcon>
							{localisation.profile.friendsHeader}
						</MenuItem>
					)}

					{!!(brandInfo.loyalty_info?.loyalty_enabled && user) && (
						<>
							<MenuItem onClick={() => handleProfileMenuItemClick("loyalty")}>
								<ListItemIcon>
									<WalletIcon className={"theme-text"} fontSize="small" />
								</ListItemIcon>
								{localisation.profile.hiddenIncustWalletHeader}
							</MenuItem>

							{customerData?.token && (
								<MenuItem onClick={() => handleProfileMenuItemClick("my_card")}>
									<ListItemIcon>
										<CardGiftcardIcon
											className={"theme-text"}
											fontSize="small"
										/>
									</ListItemIcon>
									{localisation.profile.myCard}
								</MenuItem>
							)}
						</>
					)}

					{brandInfo.scan_receipts_settings?.enabled && (
						<MenuItem onClick={handleScan}>
							<ListItemIcon>
								<QrCodeScannerIcon className={"theme-text"} fontSize="small" />
							</ListItemIcon>
							{localisation.receipt.scanButton}
						</MenuItem>
					)}

					{!webAppData && (
						<>
							<Divider />
							<MenuItem sx={{ padding: "0!important" }}>
								<LogoutButton
									text={localisation.profile.logout}
									customClassesText={"ms-0"}
									customClassesIcon={"me-3"}
									onClosePopConfirm={props.handleClose}
									nodeWrapperProps={{
										sx: {
											width: "100%",
											paddingX: "16px",
											paddingY: "6px",
										},
									}}
								/>
							</MenuItem>
						</>
					)}
				</>
			) : (
				<>
					<MenuItem onClick={handleRegisterClick}>
						<ListItemIcon>
							<LoginIcon className={"theme-text"} fontSize="small" />
						</ListItemIcon>
						{localisation.auth.loginRegisterButton}
					</MenuItem>
					{brandInfo.stores_count > 0 && (
						<>
							<Divider />
							<MenuItem onClick={() => handleProfileMenuItemClick("favorites")}>
								<ListItemIcon>
									<FavoriteIcon className={"theme-text"} fontSize="small" />
								</ListItemIcon>
								{localisation.profile.favoritesHeader}
							</MenuItem>
						</>
					)}

					{brandInfo.loyalty_info?.loyalty_enabled &&
						incustService.loyaltyReferralSettings?.referral_program?.active && (
							<MenuItem onClick={() => handleProfileMenuItemClick("share_and_earn")}>
								<ListItemIcon>
									<PaidOutlinedIcon className={"theme-text"} fontSize="small" />
								</ListItemIcon>
								{groupService.group?.texts.web.loyalty.referral_system
									.info_button || localisation.profile.shareAndEarn}
							</MenuItem>
						)}

					{brandInfo.scan_receipts_settings?.enabled && (
						<MenuItem onClick={handleScan}>
							<ListItemIcon>
								<QrCodeScannerIcon className={"theme-text"} fontSize="small" />
							</ListItemIcon>
							{localisation.receipt.scanButton}
						</MenuItem>
					)}
				</>
			)}
		</>
	);
}

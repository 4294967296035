import { styled, keyframes } from "@mui/system";

const shimmerEffect = keyframes`
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
`;

export const ShimmerIcon = styled("div")<{ shimmerIntense?: number }>(
	({ shimmerIntense = 0.5 }) => ({
		position: "relative",
		overflow: "hidden",

		"&::after": {
			content: '""',
			position: "absolute",
			top: 0,
			left: "-150%",
			width: "300%",
			height: "100%",
			background: `linear-gradient(90deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, ${shimmerIntense}) 50%, rgba(255, 255, 255, 0) 75%)`,
			animation: `${shimmerEffect} 2s infinite`,
			zIndex: 0,
		},
	})
);

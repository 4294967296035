import { memo, ReactNode } from "react";
import useLocalisation from "../../../../hooks/localisation/useLocalisation";
import TelegramLoginButton from "./telegram/TelegramLoginButton";
import { AuthorisationComponentProps } from "../../types";
import useAppContext from "../../../../useAppContext";
import WhatsappLoginButton from "./whatsapp/WhatsappLoginButton";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import OAuthOptions from "../oauth/OAuthOptions/OAuthOptions";
import useAuthSettings from "../../../../services/useAuthSettings";

function ExternalLoginOptions(props: AuthorisationComponentProps) {
	const localisation = useLocalisation();
	const { bot, brandInfo } = useAppContext();

	const authSettings = useAuthSettings(brandInfo?.group_id);

	let loginButton: ReactNode;

	if (!!bot && authSettings.settings?.is_auth_messanger_enabled) {
		switch (bot.bot_type) {
			case "telegram":
				loginButton = (
					<TelegramLoginButton
						form={props.form}
						onClick={() => {
							props.form.startExternalLogin("telegram");
						}}
					/>
				);
				break;
			case "whatsapp":
				loginButton = (
					<WhatsappLoginButton
						form={props.form}
						onClick={() => {
							props.form.startExternalLogin("whatsapp");
						}}
					/>
				);
				break;
		}
	}

	if (!authSettings.computedIsAnyExternalEnabled) {
		return <></>;
	}

	return (
		<Box mt={2}>
			{authSettings.settings?.is_auth_email_enabled &&
				authSettings.computedIsAnyOAuthEnabled && (
					<Divider
						sx={{
							my: 2,
						}}
					>
						{localisation.auth.orText}
					</Divider>
				)}

			<Box my={1}>
				{!!loginButton && !props.disabledMessengerExternalLogin && loginButton}
				{authSettings.computedIsAnyOAuthEnabled && <OAuthOptions {...props} />}
			</Box>
		</Box>
	);
}

export default memo(ExternalLoginOptions);

import { storeBasePath } from "../../base";
import { AxiosInstance } from "axios";
import {
	AddAttributeToProductData,
	AddProductToCartData,
	Cart,
	CartAttributeResponse,
	CartProductResponse,
	CheckInStockArgs,
	SyncCartData,
	UpdateCartProductRequest,
} from "./types";
import { NotAvailableProduct } from "../order/types";

export default function CartModule(instance: AxiosInstance) {
	const getPath = (path: string) => `${storeBasePath}/cart/${path}`;

	const getHeaders = (token: string | null | undefined) =>
		token ? { Authorization: `Bearer ${token}` } : undefined;

	return {
		getCart(
			storeId?: number | null | undefined,
			token?: string | null | undefined,
			anonToken?: string | null | undefined
		) {
			let config: { params: { store_id: number | null | undefined }; headers?: any } = {
				params: { store_id: storeId },
			};
			if (token) config.headers = getHeaders(token);
			if (anonToken) config.headers["X-ANON-CART-TOKEN"] = anonToken;

			return instance.get<Cart>(getPath(""), config);
		},

		addProductToCart(data: AddProductToCartData, token?: string | null | undefined) {
			let config: { headers?: any } = {};
			if (token) config.headers = getHeaders(token);

			return instance.post<CartProductResponse>(getPath("products/add"), data, config);
		},

		deleteProductFromCart(
			productId: number,
			storeId: number | null = null,
			is_cart_product_id: boolean = true,
			token?: string | null | undefined
		) {
			let config: {
				params: { store_id: number | null; is_cart_product_id: boolean };
				headers?: any;
			} = { params: { store_id: storeId, is_cart_product_id: is_cart_product_id } };
			if (token) config.headers = getHeaders(token);

			return instance.delete<Cart>(getPath(`products/${productId}`), config);
		},

		updateProductInCart(
			cartProductId: number,
			payload: UpdateCartProductRequest,
			storeId: number | null = null,
			token?: string | null | undefined
		) {
			let config: { params: { store_id: number | null }; headers?: any } = {
				params: { store_id: storeId },
			};
			if (token) config.headers = getHeaders(token);

			return instance.patch<Cart>(getPath(`products/${cartProductId}`), payload, config);
		},

		addAttributeToProduct(
			productId: number,
			data: AddAttributeToProductData,
			token?: string | null | undefined
		) {
			let config: { headers?: any } = {};
			if (token) config.headers = getHeaders(token);

			return instance.post<CartAttributeResponse>(
				getPath(`products/${productId}/addAttribute`),
				data,
				config
			);
		},

		syncUsersCartData(data: SyncCartData) {
			return instance.post<null>(getPath("sync_users_cart"), data);
		},

		clearCart(storeId: number, token?: string | null | undefined) {
			let config: { headers?: any } = {};
			if (token) config.headers = getHeaders(token);

			return instance.post<Cart>(getPath("clear"), { store_id: storeId }, config);
		},

		checkProductsAvailability(args: CheckInStockArgs, token?: string | null | undefined) {
			let config: { params: CheckInStockArgs; headers?: any } = { params: args };
			if (token && token !== "") config.headers = getHeaders(token);

			return instance.get<NotAvailableProduct[]>(
				`${storeBasePath}/check_instock/cart`,
				config
			);
		},

		getLocalCartToken(brandId: number, storeId: number | string) {
			return localStorage.getItem(`cart-token-${brandId}-${storeId}`);
		},

		setLocalCartToken(token: string, brandId: number, storeId: number) {
			localStorage.setItem(`cart-token-${brandId}-${storeId}`, token);
		},

		removeLocalCartToken(brandId: number, storeId: number) {
			localStorage.removeItem(`cart-token-${brandId}-${storeId}`);
		},
	};
}

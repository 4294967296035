import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { GoogleMap, useJsApiLoader, Polygon, Circle } from "@react-google-maps/api";
import { IBaseMapProps, IMapWithAttributesPolygon } from "./types";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	Tooltip,
	Typography,
	useColorScheme,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NearMeIcon from "@mui/icons-material/NearMe";
import { LatLngLiteral } from "leaflet";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";

import { IGeocodingResultItem, IAddressComponent } from "../../api/map/types";
import api from "../../api";
import useScreenService from "../../services/useScreenService";
import useAppContext from "../../useAppContext";
import { IPricesError, IZonePolygon, IZoneRadius } from "../../shop/services/useShipmentPrices";
import { Coordinates, IAddressValid } from "../AddressInput/AddressInput";
import { nightModeStyle } from "./styles/nightMode";
import { TgLink, ThemeModal } from "../../helpers/ThemeComponents";
import useCenterChanged from "./hooks/useCenterChanged";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface ISelectAddressMapProps extends IBaseMapProps {
	confirmCallback?: (address: IGeocodingResultItem) => void;
	addressValue?: string;
	polygons?: IZonePolygon[];
	radius?: IZoneRadius[];
	priorityCoords?: Coordinates | null;
	setShowMap: (value: boolean) => void;
	setValidAddress?: (value: IAddressValid | null) => void;
	validAddress?: IAddressValid | null;
	lang: string;
	businessLanguage: string;
	validateAndSave: (
		addrComponents: IAddressComponent[],
		address: string,
		coords: [number, number],
		placeId: string | null,
		isGoogle: boolean,
		fromMap: boolean
	) => Promise<boolean>;
	pricesError: IPricesError | null;
	userLocationCoords?: Coordinates | null;
	enabledAnyAddressFromMap?: boolean;
}

function SelectAddressMap(props: ISelectAddressMapProps) {
	const { brandInfo } = useAppContext();
	const { isMobile } = useScreenService();
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: brandInfo?.google_maps_public_api_key || "",
		language: props.businessLanguage || props.lang,
	});
	const {
		localisation: { maps },
	} = useAppContext();
	const { colorScheme } = useColorScheme();

	const [map, setMap] = useState(null);
	const [dragStarted, setDragStarted] = useState(false);
	const [savedPolygons, setSavedPolygons] = useState<IZonePolygon[] | null>(null);
	const [savedRadius, setSavedRadius] = useState<IZoneRadius[] | null>(null);

	const {
		selectedAddress,
		isLoading,
		error,
		onCenterChanged,
		similarPredictions,
		setSimilarPredictions,
		setSelectedAddress,
	} = useCenterChanged(
		map,
		setDragStarted,
		props.validateAndSave,
		props.lang,
		props.businessLanguage,
		props.centerLat,
		props.centerLng,
		props.setValidAddress,
		props.confirmCallback,
		props.enabledAnyAddressFromMap,
		props.userLocationCoords
	);

	const onLoad = useCallback(
		function callback(map: any) {
			const center = {
				lat: props.centerLat ? props.centerLat : -3.745,
				lng: props.centerLng ? props.centerLng : -38.523,
			};
			const bounds = new window.google.maps.LatLngBounds(center);
			map.fitBounds(bounds);

			map.initialZoom = true;
			setMap(map);
			api.mapGoogle.getMapsKey().then(); //TODO: map was loaded for analytics
		},
		[props.centerLat, props.centerLng]
	);

	const onUnmount = useCallback(function callback() {
		setMap(null);
	}, []);

	const onBoundsChanged = useCallback(() => {
		// @ts-ignore
		if (map && map.initialZoom) {
			// @ts-ignore
			map.initialZoom = false;
			// @ts-ignore
			map.setZoom(15);
			console.log("*** onBoundsChanged");
		}
	}, [map]);

	const onZoomChanged = useCallback(() => {
		// @ts-ignore
		if (map.initialZoom) return;
		const center = {
			lat: props.centerLat ? props.centerLat : -3.745,
			lng: props.centerLng ? props.centerLng : -38.523,
		};

		// @ts-ignore
		map.setCenter(center); //TODO: not working
	}, [map, props.centerLat, props.centerLng]);

	const handleLocationError = useCallback(
		(
			browserHasGeolocation: boolean,
			infoWindow: google.maps.InfoWindow,
			pos: google.maps.LatLng
		) => {
			infoWindow.setPosition(pos);
			infoWindow.setContent(
				browserHasGeolocation ? maps.geoLocationServiceError : maps.geoLocationBrowserError
			);
			infoWindow.open(map);
		},
		[map, maps.geoLocationBrowserError, maps.geoLocationServiceError]
	);

	const handleLocationButton = useCallback(() => {
		const infoWindow = new google.maps.InfoWindow();
		// Try HTML5 geolocation.
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position: GeolocationPosition) => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};

					infoWindow.setPosition(pos);
					infoWindow.setContent(maps.geoLocationSuccessMessage);
					infoWindow.open(map);
					// @ts-ignore
					map?.setCenter(pos);
					onCenterChanged();
				},
				() => {
					// @ts-ignore
					handleLocationError(true, infoWindow, map?.getCenter()!);
				}
			);
		} else {
			// Browser doesn't support Geolocation
			// @ts-ignore
			handleLocationError(false, infoWindow, map?.getCenter()!);
		}
	}, [handleLocationError, map, maps.geoLocationSuccessMessage, onCenterChanged]);

	const handleStoreLocationButton = useCallback(() => {
		const center = {
			lat: props.priorityCoords?.lat,
			lng: props.priorityCoords?.lon,
		};

		// @ts-ignore
		map?.setCenter(center);
	}, [map, props.priorityCoords?.lat, props.priorityCoords?.lon]);

	const computedPolygons: IMapWithAttributesPolygon[] | null = useMemo(() => {
		if (savedPolygons) {
			const polygons: IMapWithAttributesPolygon[] = [];
			savedPolygons.forEach(polygon => {
				const convertedPoly = convertPolygonObject(polygon.polygon);
				const obj: IMapWithAttributesPolygon = {
					polygon: convertedPoly,
					title: polygon.pricesString,
					color: polygon.color || "",
				};
				polygons.push(obj);
			});
			return polygons;
		}
		return [];
	}, [savedPolygons]);

	function convertPolygonObject(polygonObject: number[][]): LatLngLiteral[] {
		// eslint-disable-next-line array-callback-return
		const res = polygonObject.map(poly => {
			const item: LatLngLiteral = {
				lat: poly[0],
				lng: poly[1],
			};
			if (item) return item;
		});

		return res as LatLngLiteral[];
	}

	const computedMapObjectColor = useMemo(() => {
		if (colorScheme === "light") return "#22222257";
		return "#f7f7f757";
	}, [colorScheme]);

	const computedMapObjectStrokeColor = useMemo(() => {
		if (colorScheme === "light") return "#22222299";
		return "#f7f7f799";
	}, [colorScheme]);

	useEffect(() => {
		setSavedPolygons(props.polygons || []);
	}, [props.polygons]);

	useEffect(() => {
		setSavedRadius(props.radius || []);
	}, [props.radius]);

	if (!isLoaded)
		return (
			<Box sx={{ width: "100%", textAlign: "center" }}>
				<CircularProgress style={{ width: "38px", height: "38px" }} />
			</Box>
		);

	return (
		isLoaded && (
			<Box
				sx={{
					width: "100%",
					height: "100%",
					position: "relative",
					overflow: "hidden",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<GoogleMap
					onDragEnd={onCenterChanged}
					onDragStart={() => setDragStarted(true)}
					mapContainerStyle={{
						width: props.width ? props.width : "100%",
						height: props.height ? props.height : "100%",
						flex: "1",
					}}
					onLoad={onLoad}
					onUnmount={onUnmount}
					onBoundsChanged={onBoundsChanged}
					onZoomChanged={onZoomChanged}
					options={{
						disableDefaultUI: true,
						zoomControl: true,
						styles: colorScheme === "light" ? [] : nightModeStyle,
					}}
				>
					{computedPolygons &&
						computedPolygons.map((poly, index) => (
							<Polygon
								key={index}
								path={poly.polygon}
								options={{
									fillColor: computedMapObjectColor,
									strokeColor: computedMapObjectStrokeColor,
									strokeWeight: 1,
								}}
							/>
						))}
					{savedRadius &&
						savedRadius
							.sort((a, b) => a.radius - b.radius)
							.map(
								(radius, index) =>
									!!(
										props.priorityCoords &&
										props.priorityCoords.lon &&
										props.priorityCoords.lat
									) && (
										<Circle
											key={index}
											center={{
												lat: props.priorityCoords.lat,
												lng: props.priorityCoords.lon,
											}}
											radius={radius.radius}
											options={{
												fillColor:
													index === savedRadius.length - 1
														? computedMapObjectColor
														: "transparent",
												strokeColor: computedMapObjectStrokeColor,
												strokeWeight: 1,
											}}
										/>
									)
							)}

					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -100%)",
							pointerEvents: "none",
						}}
					>
						<LocationOnIcon sx={{ fontSize: 40, color: "primary.main" }} />
					</Box>
				</GoogleMap>

				{!!(
					props.priorityCoords &&
					props.priorityCoords.lon &&
					props.priorityCoords.lat
				) && (
					<MapControlCustomButton
						bottom={isMobile ? 275 + 24 : 216 + 24}
						action={handleStoreLocationButton}
						tooltipTitle={maps.showPointOfSaleOnMap}
					>
						<MapsHomeWorkIcon />
					</MapControlCustomButton>
				)}

				<MapControlCustomButton
					bottom={isMobile ? 231 + 24 : 170 + 24}
					action={handleLocationButton}
					tooltipTitle={maps.determineLocation}
				>
					<NearMeIcon />
				</MapControlCustomButton>

				<ConfirmComponent
					confirmCallback={props.confirmCallback}
					addressValue={props.addressValue}
					isLoading={isLoading}
					selectedAddress={selectedAddress}
					setShowMap={props.setShowMap}
					error={error}
					validAddress={props.validAddress}
					pricesError={props.pricesError}
					dragStarted={dragStarted}
					handleLocationButton={handleLocationButton}
					similarPredictions={similarPredictions}
					setSimilarPredictions={setSimilarPredictions}
					setSelectedAddress={setSelectedAddress}
					validateAndSave={props.validateAndSave}
				/>
			</Box>
		)
	);
}

interface IConfirmComponentProps {
	confirmCallback?: (address: IGeocodingResultItem) => void;
	addressValue?: string;
	isLoading: boolean;
	selectedAddress: IGeocodingResultItem | null;
	setShowMap: (value: boolean) => void;
	error: string | null;
	validAddress?: IAddressValid | null;
	pricesError: IPricesError | null;
	dragStarted: boolean;
	handleLocationButton: () => void;
	similarPredictions: IGeocodingResultItem[];
	setSimilarPredictions: (value: IGeocodingResultItem[]) => void;
	setSelectedAddress: (value: IGeocodingResultItem | null) => void;
	validateAndSave: (
		addrComponents: IAddressComponent[],
		address: string,
		coords: [number, number],
		placeId: string | null,
		isGoogle: boolean,
		fromMap: boolean
	) => Promise<boolean>;
}

function ConfirmComponent(props: IConfirmComponentProps) {
	const { isMobile } = useScreenService();
	const {
		localisation: { global, maps },
	} = useAppContext();

	const [similarModalShow, setSimilarModalShow] = useState(false);

	const computedError = useMemo(() => {
		if (props.validAddress && !props.validAddress.valid) {
			return props.validAddress.text;
		}
		if (props.pricesError && props.pricesError.internalCode === "address_error") {
			return props.pricesError.text;
		}
		return null;
	}, [props.pricesError, props.validAddress]);

	const handleConfirm = () => {
		props.setShowMap(false);
	};

	const computedColor = useMemo(() => {
		if (props.error || computedError) {
			return "error.main";
		}
		return "text.primary";
	}, [computedError, props.error]);

	return (
		<>
			<Grid container my={2}>
				<Grid item md={6} xs={12} sx={{ mt: 2 }} alignSelf={"center"}>
					{props.isLoading ? (
						<Box>
							<CircularProgress sx={{ color: "primary.main" }} size={28} />
						</Box>
					) : (
						<>
							<Box display={"flex"}>
								<LocationOnIcon
									sx={{ color: "primary.main", alignSelf: "center" }}
									fontSize={"large"}
								/>
								<Box alignSelf={"center"}>
									<Box display={"flex"} flexWrap={"wrap"}>
										<Typography
											fontWeight={"bold"}
											sx={{ ml: 2 }}
											alignSelf={"center"}
											color={computedColor}
										>
											{!!props.error
												? props.error
												: props.selectedAddress?.formatted_address ||
													props.addressValue ||
													maps.selectLocation}
										</Typography>
										{props.similarPredictions.length > 0 && (
											<Box ml={2}>
												(
												<TgLink
													onClick={() => setSimilarModalShow(true)}
													className={"cursor-pointer"}
												>
													{maps.similarButton}
												</TgLink>
												)
											</Box>
										)}
									</Box>
									{!!computedError && (
										<Typography
											sx={{ ml: 2 }}
											variant={"body2"}
											color={"error.main"}
										>
											{computedError}
										</Typography>
									)}
								</Box>
							</Box>
						</>
					)}
					<Box sx={{ ml: "42px" }}>
						<TgLink className={"cursor-pointer"} onClick={props.handleLocationButton}>
							{maps.determineLocation}
						</TgLink>
					</Box>
				</Grid>
				<Grid item md={6} xs={12} sx={{ mt: 2 }} textAlign={"end"} alignSelf={"center"}>
					<Button
						variant={"contained"}
						size={"large"}
						fullWidth={isMobile}
						onClick={handleConfirm}
					>
						{global.confirmButton}
					</Button>
				</Grid>
			</Grid>

			<SimilarPredictionsModal
				show={similarModalShow}
				setShow={setSimilarModalShow}
				similarPredictions={props.similarPredictions}
				setSimilarPredictions={props.setSimilarPredictions}
				selectedAddress={props.selectedAddress}
				setSelectedAddress={props.setSelectedAddress}
				confirmCallback={props.confirmCallback}
				validateAndSave={props.validateAndSave}
			/>
		</>
	);
}

interface ISimilarPredictionsModalProps {
	show: boolean;
	setShow: (value: boolean) => void;
	similarPredictions: IGeocodingResultItem[];
	setSimilarPredictions: (value: IGeocodingResultItem[]) => void;
	selectedAddress: IGeocodingResultItem | null;
	setSelectedAddress: (value: IGeocodingResultItem | null) => void;
	confirmCallback?: (address: IGeocodingResultItem) => void;
	validateAndSave: (
		addrComponents: IAddressComponent[],
		address: string,
		coords: [number, number],
		placeId: string | null,
		isGoogle: boolean,
		fromMap: boolean
	) => Promise<boolean>;
}

function SimilarPredictionsModal(props: ISimilarPredictionsModalProps) {
	const {
		localisation: { maps },
	} = useAppContext();

	const handleItemClicked = (item: IGeocodingResultItem) => {
		const items = props.similarPredictions.filter(i => i.place_id !== item.place_id);

		if (props.selectedAddress) items.push(props.selectedAddress);

		props
			.validateAndSave(
				item.address_components,
				item.formatted_address,
				[item.geometry.location.lng, item.geometry.location.lat],
				item.place_id,
				true,
				true
			)
			.then(() => {
				props.setSimilarPredictions(items);
				props.setSelectedAddress(item);
				props.confirmCallback && props.confirmCallback(item);
				props.setShow(false);
			});
	};

	return (
		<ThemeModal
			open={props.show}
			setOpen={props.setShow}
			title={maps.similarHeader}
			contentProps={{
				sx: { p: 0 },
			}}
		>
			<Box
				sx={{
					"div:last-child": {
						borderBottom: 0,
						button: {
							borderBottom: 0,
						},
					},
				}}
			>
				{props.similarPredictions.map(item => (
					<Button
						onClick={() => handleItemClicked(item)}
						color={"inherit"}
						endIcon={
							<ArrowForwardIosIcon
								sx={{
									color: "text.secondary",
									fontSize: "1.5rem!important",
								}}
							/>
						}
						key={item.place_id}
						sx={{
							borderBottom: 1,
							borderColor: "divider",
							paddingX: 3,
							paddingY: "12px",
							py: 1.5,
							borderRadius: 0,
							justifyContent: "space-between",
							textAlign: "start",
						}}
					>
						<Typography
							textAlign={"start"}
							// variant={'body2'}
							// color={'text.secondary'}
							textTransform={"none"}
						>
							{item.formatted_address}
						</Typography>
					</Button>
				))}
			</Box>
		</ThemeModal>
	);
}

interface IMapControlCustomButtonProps {
	bottom: number;
	children: ReactNode;
	action: () => void;
	tooltipTitle: string;
}

function MapControlCustomButton(props: IMapControlCustomButtonProps) {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				position: "absolute",
				bottom: props.bottom,
				right: 10,
				width: 40,
				height: 40,
				background: "#fff",
				boxShadow: "0 1px 4px -1px rgba(0,0,0,0.3)",
				borderRadius: "2px",
				cursor: "pointer",
			}}
			onClick={props.action}
		>
			<Tooltip enterTouchDelay={1500} title={props.tooltipTitle} placement={"left"}>
				<Box
					sx={{
						color: "#666666",
						"&:hover": {
							color: "#000",
						},
					}}
				>
					{props.children}
				</Box>
			</Tooltip>
		</Box>
	);
}

export default React.memo(SelectAddressMap);

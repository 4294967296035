import formatNumber from "../../helpers/formatNumber";

export const buildChargeString = (
	title: string,
	chargePercent: number,
	chargeFixed: number,
	lang: string,
	position: "start" | "end",
	symbol: string
) => {
	let text = "";

	if (chargePercent || chargeFixed) {
		text += `${title} `;
	}
	let fixed = "";
	if (chargeFixed) {
		fixed = formatNumber(chargeFixed, lang);
		fixed = position === "start" ? `${symbol} ${fixed}` : `${fixed} ${symbol}`;
	}
	if (!!chargePercent && fixed) {
		text += `${chargePercent}% + ${fixed}`;
		return text;
	}
	if (chargePercent) {
		text += `${chargePercent}%`;
		return text;
	}
	if (fixed) {
		text += fixed;
		return text;
	}

	return text;
};

import { Box, Button, Grid, Typography } from "@mui/material";

import { CreateOrderPayload } from "../../../../api/shop/order/types";
import { SetMergeState } from "../../../../helpers/useMergedState";
import { OrderStepperButtons } from "./OrderStepperButtons";
import { useSelectedStore } from "../../../SelectedStore/context";
import formatCurrency from "../../../../helpers/formatCurrency";
import useAppContext from "../../../../useAppContext";
import { IUseTipsServiceType } from "../../../../features/services/useTipsService/types";
import ChipButton from "../../../../features/ChipButton";
import { useCallback } from "react";
import { getOrderState, saveOrderState } from "./orderSessionState";
import { IUseUpdateOrder } from "../useUpdateOrder";

interface ITipsProps {
	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;
	computedTotalAmount: number;
	tipsService: IUseTipsServiceType;

	setPrevStep: () => void;
	setNextStep: () => void;

	updateService: IUseUpdateOrder;
}

export default function Tips(props: ITipsProps) {
	const {
		localisation: { orders },
	} = useAppContext();

	const setNextStep = props.setNextStep;
	const updateTips = props.updateService.updateTips;
	const setNext = useCallback(() => {
		const orderState = getOrderState();
		if (!orderState || !orderState.createdOrder) {
			setNextStep();
		}
		saveOrderState(props.tipsService.selectedTipPercent, "tipPercent");
		if (orderState && orderState.createdOrder) {
			Promise.resolve<{ ok: boolean } | undefined>(updateTips()).then(result => {
				if (result && result.ok) {
					setNextStep();
				}
			});
		}
	}, [props.tipsService.selectedTipPercent, setNextStep, updateTips]);

	return (
		<Box>
			<Typography mb={2}>{orders.tipsSubHeader}</Typography>

			<Grid container alignItems={"stretch"} spacing={2}>
				{props.tipsService.tipPercentItems.map(tipItem => (
					<TipItem key={tipItem} percent={tipItem} tipsService={props.tipsService} />
				))}
			</Grid>

			<TotalAndRounding
				amount={props.computedTotalAmount + (props.form.tips_sum || 0)}
				tipsService={props.tipsService}
				tipsSum={props.form.tips_sum || 0}
			/>

			<OrderStepperButtons
				setPrevStep={props.setPrevStep}
				setNextStep={setNext}
				isNextLoading={props.updateService.isLoading}
			/>
		</Box>
	);
}

interface ITipItemProps {
	percent: number;
	tipsService: IUseTipsServiceType;
}

function TipItem(props: ITipItemProps) {
	const isSelected = props.tipsService.selectedTipPercent === props.percent;

	const handleClick = () => {
		if (isSelected) {
			props.tipsService.resetRounding();
		}
		props.tipsService.setIsUserEnteredSum(false);
		props.tipsService.setSelectedTipPercent(props.percent);
	};

	return (
		<Grid item xs={3} sm={2}>
			<ChipButton selected={isSelected} onClick={handleClick}>
				{props.percent + "%"}
			</ChipButton>
		</Grid>
	);
}

interface ITotalAndRoundingProps {
	amount: number;
	tipsService: IUseTipsServiceType;
	tipsSum: number;
}

function TotalAndRounding(props: ITotalAndRoundingProps) {
	const {
		lang,
		localisation: { orders, global },
		brandInfo,
	} = useAppContext();
	const selectedStore = useSelectedStore();

	return (
		<Box sx={{ mt: 2 }}>
			<Box sx={{ color: "text.secondary" }}>
				{global.tips}:{" "}
				{formatCurrency(
					props.tipsSum,
					brandInfo?.default_lang || lang,
					selectedStore.currency
				)}
			</Box>

			<Box sx={{ textAlign: "start", fontSize: "1.2rem", display: "flex" }}>
				<Box sx={{ alignSelf: "center" }}>
					<span>{orders.overallHeader}: </span>
					{formatCurrency(
						props.amount,
						brandInfo?.default_lang || lang,
						selectedStore.currency
					)}
				</Box>

				{props.tipsService.computedIsAvailableRounding && (
					<Button
						variant={"text"}
						onClick={props.tipsService.calcRoundingSum}
						sx={{ ml: 2, p: 0, fontSize: "1rem" }}
					>
						{global.round}
					</Button>
				)}
			</Box>
		</Box>
	);
}

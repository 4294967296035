import {MouseEvent} from "react";
import useLocalisation from "../../../../../hooks/localisation/useLocalisation";
import {MDBBtn} from "mdb-react-ui-kit";
import {AuthorisationComponentProps} from "../../../types";


interface WhatsappLoginButtonProps extends AuthorisationComponentProps {
    onClick: (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => any
}


export default function WhatsappLoginButton(props: WhatsappLoginButtonProps) {
    const localisation = useLocalisation()

    return (
        <MDBBtn
            outline
            className={
            'theme-button outline border px-0' +
                ' w-100 text-transform-none ' +
                'fw-normal'
            }
            onClick={props.onClick}
        >
            <div className={'w-100 d-flex external-login-container'}>
                <img className={'external-login-logo'} alt={'whatsapp'} src={require('./WhatsappLogo.png')}/>
                <span className={'ms-1 flex-1'} style={{fontSize: '14px'}}>
                    {localisation.auth.continueWithWhatsapp}
                </span>
            </div>
        </MDBBtn>
    )
}

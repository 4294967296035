import formatNumber from "./formatNumber";
import { NO_CENT_CURRENCIES } from "./noCentCurrencies";

export default function formatCurrency(
	sum: number | string,
	locales: string | null,
	currency?: string | null
): string {
	if (!currency) {
		return formatNumber(sum, locales);
	}

	try {
		const parsedSum = parseFloat(sum.toString());
		const options: Intl.NumberFormatOptions = {
			style: "currency",
			currency: currency,
			currencyDisplay: "narrowSymbol",
			minimumFractionDigits:
				NO_CENT_CURRENCIES.has(currency) && !Number(parsedSum % 1) ? 0 : 2,
			maximumFractionDigits:
				NO_CENT_CURRENCIES.has(currency) && !Number(parsedSum % 1) ? 0 : 2,
		};
		return parsedSum.toLocaleString(locales || undefined, options);
	} catch (e) {
		return formatNumber(sum, locales);
	}
}

import React from "react";
import { motion } from "framer-motion";
import { Box, Icon } from "@mui/material";

interface IAnimateIconProps {
	icon: React.ElementType;
}

export const AnimateIcon = ({ icon }: IAnimateIconProps): React.JSX.Element => {
	return (
		<Box
			component={motion.div}
			animate={{
				scale: [1, 1, 1.05, 1, 1.05, 1, 1, 1, 1],
				rotate: [0, 0, -5, 0, 5, 0, 0, 0, 0],
				transition: { duration: 2, repeat: Infinity, repeatType: "reverse", delay: 0.7 },
			}}
		>
			<Icon component={icon} sx={{ color: "inherit", fontSize: "4rem" }} />
		</Box>
	);
};

import {useEffect, useRef} from "react"

interface PageTitleProps {
    title: string | null
}

export default function PageTitle(props: PageTitleProps) {
    const beforeRef = useRef<string | null>(null)

    useEffect(() => {
        if (!beforeRef.current) {
            beforeRef.current = document.title
        }
        if (props.title) {
            document.title = props.title
        } else if (beforeRef.current) {
            document.title = beforeRef.current
        }

        return () => {
            if (beforeRef.current) {
                document.title = beforeRef.current
            }
        }
    }, [props.title])

    return null
}

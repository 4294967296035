import useAppContext from "../../../../../useAppContext";
import { useColorScheme } from "@mui/material";
import IframeButton from "../../../../../features/IframeButton";
import { memo } from "react";
import useAuthSettings from "../../../../../services/useAuthSettings";
import OAuthWidget from "../../../../../features/OAuthWidget/OAuthWidget";
import { isIOS, isSafariAndMac } from "../../../../../helpers";

interface IOAuthOptionsButtonsProps {
	googleIframeSrc: string | null;
	appleIframeSrc: string | null;
}

function OAuthOptionsButtons(props: IOAuthOptionsButtonsProps) {
	const {
		localisation: { auth },
		brandInfo,
	} = useAppContext();

	const colorSchema = useColorScheme();
	const authSettings = useAuthSettings(brandInfo?.group_id);

	const isIos = isIOS();
	const isSafariAndMacValue = isSafariAndMac();

	return (
		<>
			{!!props.googleIframeSrc && authSettings.settings?.is_auth_google_enabled && (
				<IframeButton
					iframeSrc={`${props.googleIframeSrc}&theme=${colorSchema.mode}`}
					buttonProps={{
						variant: "outlined",
						fullWidth: true,
						sx: { mt: 2 },
					}}
				>
					<div className={"w-100 d-flex external-login-container"}>
						<span className={"external-login-logo google-logo align-self-center"} />
						<span
							className={"ms-1 flex-1 align-self-center"}
							style={{ fontSize: "13px" }}
						>
							{auth.continueWithGoogle}
						</span>
					</div>
				</IframeButton>
			)}

			{isIos || isSafariAndMacValue ? (
				<OAuthWidget provider={"apple"} native={true} />
			) : (
				<>
					{!!props.appleIframeSrc && authSettings.settings?.is_auth_apple_enabled && (
						<IframeButton
							iframeSrc={`${props.appleIframeSrc}&theme=${colorSchema.mode}`}
							buttonProps={{
								variant: "outlined",
								fullWidth: true,
								sx: { mt: 2 },
							}}
						>
							<div className={"w-100 d-flex external-login-container"}>
								<img
									className={"external-login-logo align-self-center"}
									alt={"telegram"}
									src={
										colorSchema.mode === "dark"
											? require("../apple/appleLogoWhite.png")
											: require("../apple/appleLogoBlack.png")
									}
								/>
								<span className={"ms-1 flex-1"} style={{ fontSize: "13px" }}>
									{auth.continueWithApple}
								</span>
							</div>
						</IframeButton>
					)}
				</>
			)}
			{/*<OAuthWidget provider={"apple"} />*/}
			{/*<AppleLoginButton />*/}
		</>
	);
}

export default memo(OAuthOptionsButtons);

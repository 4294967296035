import { Box, SxProps, Theme } from "@mui/material";

interface CustomSlideButtonProps {
	className: string;
	icon: React.ReactNode;
	customStyle?: SxProps<Theme>;
}

export const CustomSlideButton = (props: CustomSlideButtonProps) => {
	const { icon, customStyle, className } = props;

	return (
		<Box
			className={className}
			component={"button"}
			sx={{
				border: "none",
				outline: "none",

				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				zIndex: 100,
				cursor: "pointer",
				backgroundColor: "#494949",
				color: "#fff",
				width: "40px",
				height: "40px",
				borderRadius: "100px",
				fontSize: "24px",

				transition: "background-color 0.3s, opacity 0.3s, filter 0.3s",

				"&:hover": {
					backgroundColor: "#000",
					opacity: 0.6,
					filter: "brightness(120%)",
				},

				...customStyle,
			}}
		>
			{icon}
		</Box>
	);
};

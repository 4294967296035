import {Box, BoxProps} from "@mui/material";
import React from "react";


export default function CheckLine({children, ...props}: BoxProps) {
    return (
        <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'start'}
            {...props}
        >
            {children}
        </Box>
    )
}

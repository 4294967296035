import {useQuery} from "@tanstack/react-query";
import {useCallback, useMemo} from "react";
import api from "../api";
import {Group} from "../api/groups/types";
import {UseQueryResult} from "@tanstack/react-query/src/types";


export default function useGroupService(
    groupId: number | null | undefined,
    lang: string | null,
): GroupServiceType {
    const fetchGroup = useCallback(async () => {
        if (!groupId) return null
        return await api.groups.getGroupById(groupId).then(response => response.data)
    }, [groupId])

    const query = useQuery<Group | null, any>({
        queryKey: ['group-service-group', groupId, lang],
        queryFn: fetchGroup,
        enabled: !!groupId && !!lang,
        initialData: null
    })

    return useMemo(() => {
        const {
            data: group,
            ...rest
        } = query
        return {
            group,
            ...rest,
        }
    }, [query])
}


export type GroupServiceType = Omit<UseQueryResult<Group | null>, 'data'> & {
    group: Group | null
}

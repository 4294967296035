import {IAddressOptionItem, IAutoCompleteAdapterResult} from "./types";
import {Coordinates} from "./AddressInput";
import {Feature, IAutocompleteParams, PhotonSearchArgs} from "../../api/map/types";
import api from "../../api";

export default async function autoCompleteAdapter(
    lang: string, searchQuery: string,
    isGoogle: boolean = false, priorityCoords: Coordinates | null = null,
    sessionToken: string | null = null, unknownErrorText: string,
    setInternalError: (value: string | null) => void,
    countries: string[] | null = null,
): Promise<IAutoCompleteAdapterResult> {
    setInternalError(null)
    const res: IAddressOptionItem[] = []

    if(!isGoogle){
        const args: PhotonSearchArgs = {
            q: searchQuery,
            lang: lang,
            limit: 30,
        }
        if(priorityCoords){
            args.lon = priorityCoords.lon
            args.lat = priorityCoords.lat
        }

        let response;
        try{
            response = await api.mapPhoton.getCoordsByAddress(args)
        }
        catch(ex: any){
            setInternalError(ex?.response?.data?.detail || unknownErrorText)
            return {items: []}
        }

        const filtered = response.data.features?.filter((item) => {
            return item.properties?.city &&
                (item.properties?.name || item.properties?.street) &&
                item.properties.osm_type !== "N"
        })

        const formattedFilteredList: IAddressOptionItem[] | null | undefined = filtered?.map(i => {
            return {
                fullTitle: getPhotonAddressTitle(i),
                title: getPhotonAddressTitle(i),
                subTitle: getPhotonAddressSubTitle(i),
                lat: i.geometry?.coordinates[1],
                lng: i.geometry?.coordinates[0],
                id: i.properties?.osm_id?.toString() || getPhotonAddressTitle(i),
            }
        })
        if(formattedFilteredList) res.push(...formattedFilteredList)
    }
    else {
        let components: string | null = null
        if(countries){
            components = countriesToComponents(countries)
        }
        const args: IAutocompleteParams = {
            input: searchQuery,
            // location?: string | null
            // origin?: string | null
            // region?: string | null
            sessiontoken: sessionToken,
            language: lang,
            // types: "address",
            components: components,
        }

        let response;
        try{
            response = await api.mapGoogle.autocomplete(args)
        }
        catch(ex: any){
            setInternalError(ex?.response?.data?.detail || unknownErrorText)
            return {items: []}
        }

        const formattedFilteredList: IAddressOptionItem[] | null | undefined =
            response.data.predictions?.predictions?.map(i => {
            return {
                fullTitle: i.description,
                title: i.structured_formatting?.main_text || i.description,
                subTitle: i.structured_formatting?.secondary_text || "",
                place_id: i.place_id,
                id: i.place_id,
            }
        })
        if(formattedFilteredList) res.push(...formattedFilteredList)
    }

    return {items: res}
}

const getPhotonAddressTitle = (feature: Feature) => {
    if(feature.properties?.street && feature.properties?.housenumber){
        return `${feature.properties?.city}, ${feature.properties?.street}, ${feature.properties?.housenumber}`
    }
    return `${feature.properties?.city}, ${feature.properties?.name}`
}

const getPhotonAddressSubTitle = (feature: Feature) => {
    if(feature.properties?.street && feature.properties?.housenumber){
        return `${feature.properties?.city}, ${feature.properties?.street}, ${feature.properties?.housenumber}`
    }
    return `${feature.properties?.city}, ${feature.properties?.name}`
}

const countriesToComponents = (countries: string[]): string | null => {
    let res: string | null = null
    if(countries.length > 0){
        res = ""
        countries.forEach((item, index) => {
            res += `country:${item}`
            if(index < countries.length - 1) res += "|"
        })
    }

    return res
}

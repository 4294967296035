interface useShareType {
    tryShare: (shareObj: ShareData) => Promise<void>
    isShareAvailable: boolean
}

export default function useShare(): useShareType {
    const tryShare = async(shareObj: ShareData) => {
        if(navigator.canShare(shareObj)){
            try {
                await navigator.share(shareObj)
            } catch (err) {
                console.log(err)
            }
        }
    }

    return {
        tryShare: tryShare, isShareAvailable: !!(navigator.canShare),
    }
}




const scrollToProductsTop = () => {
    const el = document.getElementById('products-container')
    if (!el) return

    window.scrollTo({
        behavior: 'smooth',
        top: el.offsetTop - 88
    })
}


export default scrollToProductsTop

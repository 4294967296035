import {Toast, ToastServiceType} from "./types";
import {useCallback, useMemo, useState} from "react";


export default function useToastService(): ToastServiceType {
    const [toast, setToast] = useState<Toast | null>(null)

    const showToast = useCallback((toast: Toast) => {
        setToast(toast)
    }, [])

    const hide = useCallback(() => {
        setToast(null)
    }, [])

    return useMemo(() => ({
        toast, showToast, hide
    }), [toast, showToast, hide])
}

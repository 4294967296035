import { memo, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, ButtonOwnProps } from "@mui/material";

interface IframeButtonProps {
	iframeSrc: string;
	children: ReactNode;
	buttonProps: ButtonOwnProps;
}

function IframeButton(props: IframeButtonProps) {
	const buttonRef = useRef<HTMLButtonElement>(null);

	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const [isLoaded, setIsLoaded] = useState(false);
	const [iframeError, setIframeError] = useState(false);

	const computedIframe = useMemo(() => {
		return (
			<iframe
				onError={() => setIframeError(true)}
				onLoad={() => setIsLoaded(true)}
				src={props.iframeSrc}
				title={"iframe-button"}
				height={height}
				width={width}
				scrolling={"no"}
			/>
		);
	}, [height, props.iframeSrc, width]);

	useEffect(() => {
		if (buttonRef.current) {
			setHeight(buttonRef.current.clientHeight + 8);
			setWidth(buttonRef.current.clientWidth);
		}
	}, []);

	if (iframeError) {
		return <></>;
	}

	return (
		<Box sx={{ position: "relative" }}>
			<Box sx={{ zIndex: 1 }}>
				<Button {...props.buttonProps} disabled={!isLoaded} ref={buttonRef}>
					{props.children}

					<Box
						sx={{
							zIndex: 2,
							position: "absolute",
							top: 0,
							width: "100%",
						}}
					>
						<Box>{computedIframe}</Box>
					</Box>
				</Button>
			</Box>
		</Box>
	);
}

export default memo(IframeButton);

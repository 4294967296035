import { useCallback, useEffect, useState } from "react";
import { MenuInStore } from "../../../api/menuInStore/types";
import api from "../../../api";
import { MenuInStoreServiceType } from "./types";
import { BrandInfo } from "../../../api/shop/basic/types";
import useDeviceInfo from "../../../features/hooks/useDeviceInfo";
import { isAvailableOsAndBrowserForTgRedirect } from "../../../helpers";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import useAppContext from "../../../useAppContext";

export default function useMenuInStoreService(brandInfo: BrandInfo): MenuInStoreServiceType {
	const { lang, bot } = useAppContext();

	const deviceInfo = useDeviceInfo();

	const [searchParams, setSearchParams] = useSearchParamsFixed();

	const menuInStoreIdParam = searchParams.get("in_store_id");
	const enteredAmount = searchParams.get("entered_amount");

	const [menuInStore, setMenuInStore] = useState<MenuInStore | null>(null);
	const [triedRedirect, setTriedRedirect] = useState(false);

	const redirectMode = menuInStore?.redirect_type ?? null;

	const computedTgLink = useCallback(
		(forPayment: boolean = false) => {
			return `tg://resolve?domain=${brandInfo.bot_name}&start=qrmenu-${menuInStore?.id}-${+forPayment}`;
		},
		[brandInfo.bot_name, menuInStore?.id]
	);

	const computedTgLinkMe = useCallback(
		(forPayment: boolean = false) => {
			return `https://t.me/${brandInfo.bot_name}?start=qrmenu-${menuInStore?.id}-${+forPayment}`;
		},
		[brandInfo.bot_name, menuInStore?.id]
	);

	const computedWhatsappLinkMe = useCallback(
		(forPayment: boolean = false) => {
			return `https://wa.me/${bot?.whatsapp_from_phone_number}?text=qrmenu-${menuInStore?.id}`;
		},
		[bot?.whatsapp_from_phone_number, menuInStore?.id]
	);

	const processRedirectMode = useCallback(
		(callback: () => void | null, force: boolean = false, forPayment: boolean = false) => {
			if (!force && !triedRedirect)
				if (!isAvailableOsAndBrowserForTgRedirect(deviceInfo)) {
					if (callback)
						setTimeout(() => {
							console.log("call callback");
							callback();
						}, 500);

					return;
				}

			if (
				redirectMode === "only_tg" ||
				(redirectMode === "try_tg" && !triedRedirect) ||
				force
			) {
				window.location.href = computedTgLink(forPayment);
				if (!force) setTriedRedirect(true);

				setTimeout(() => {
					// If the user is redirected to Telegram, the function will not reach this line
					// If Telegram is not installed, this will be executed after a short delay
					if (callback) {
						console.log("call callback");
						callback();
					}
				}, 500);
			}
		},
		[computedTgLink, deviceInfo, redirectMode, triedRedirect]
	);

	const forgetMenuInStore = useCallback(() => {
		setMenuInStore(null);

		setSearchParams(prev => {
			prev.delete("in_store_id");
			return prev;
		});
	}, [setSearchParams]);

	useEffect(() => {
		if (!menuInStoreIdParam || !lang) return;

		const menuInStoreId = parseInt(menuInStoreIdParam) ?? null;
		if (!menuInStoreId) return;

		api.menuInStore
			.getMenuInStore(menuInStoreId, brandInfo.id)
			.then(response => {
				setMenuInStore(response.data);
				console.log("in menu in store service");

				setSearchParams(prev => {
					prev.set("in_store_id", response.data.id.toString());
					return prev;
				});
			})
			.catch(err => {
				console.log(err);
				forgetMenuInStore();
			});
	}, [menuInStoreIdParam, lang, brandInfo.id, setSearchParams, forgetMenuInStore]);

	return {
		menuInStoreIdParam,
		menuInStore,
		setMenuInStore,
		redirectMode,
		processRedirectMode,
		computedTgLinkMe,
		forgetMenuInStore,
		enteredAmount,
		computedWhatsappLinkMe,
	};
}

import { useEffect, useMemo, useRef, useState } from "react";
import { MDBIcon, MDBBtn } from "mdb-react-ui-kit";

import ProductCard, { GridProductCardProps } from "./ProductCard";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { Category, Product } from "../../../api/shop/basic/types";
import scrollToProductsTop from "../../helpers";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import useScreenService from "../../../services/useScreenService";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { IFavoriteProduct } from "../../../api/shop/favorites/types";
import { ViewType } from "../../../services/useViewService";
import useAppContext from "../../../useAppContext";
import ProductCardSkeleton from "./ProductCardSkeleton";
import useProductViewSize from "./hooks/useProductsViewSize";

interface ProductsProps {
	view: ViewType;
	isFavorites?: boolean;
}

export default function Products({ view, isFavorites = false }: ProductsProps) {
	const { localisation } = useAppContext();

	const { productsService, favoritesService, categoriesService } = useSelectedStoreContext();

	const [lastProductsLength, setLastProductsLength] = useState<number | null>(null);

	const { computedDefaultSkeletonCount } = useProductViewSize();

	const products = isFavorites
		? favoritesService.favorites?.favorite_products?.map((x: IFavoriteProduct) => x.product) ||
			[]
		: productsService.products;

	const fatherCategoryId = categoriesService.selectedCategoryId || "top";
	const categories = categoriesService.getCategories(fatherCategoryId);

	const firstNotFullIDX =
		categories?.findIndex(category => {
			if (category.products_count < 1) {
				return false;
			}

			return (
				category.products_count >
				products.filter(product => productsService.isProductInCategory(product, category))
					.length
			);
		}) ?? -1;

	const filledCategories =
		(firstNotFullIDX !== -1 ? categories?.slice(0, firstNotFullIDX + 1) : categories) || [];

	useEffect(() => {
		if (products.length) {
			setLastProductsLength(products.length);
		}
	}, [products.length]);

	if (products.length === 0 && !productsService.isLoading && productsService.isLoaded) {
		return (
			<div className="w-100 text-center small text-muted">{localisation.menu.noProducts}</div>
		);
	}

	return (
		<>
			<Box mt={2} width={"100%"} id={"list-container"}>
				{(!productsService.isLoading || productsService.isFetchingNextPage) &&
				products.length &&
				(filledCategories.length || !categories?.length) ? (
					<>
						<ProductsExists
							view={view}
							products={products}
							isFavorites={isFavorites}
							filledCategories={filledCategories}
						/>
						{productsService.isFetchingNextPage && (
							<ProductsSkeleton view={view} count={2} />
						)}
						{!productsService.isLoading && productsService.isLoaded && !isFavorites && (
							<BackToTopOrLoadMoreButton
								view={view}
								productsCount={products.length}
							/>
						)}
					</>
				) : (
					<ProductsSkeleton
						view={view}
						count={lastProductsLength || computedDefaultSkeletonCount}
					/>
				)}
			</Box>
		</>
	);
}

interface BackToTopOrLoadMoreButtonProps {
	view: ViewType;
	productsCount: number;
}

function BackToTopOrLoadMoreButton({ view, productsCount }: BackToTopOrLoadMoreButtonProps) {
	const { localisation, appearance } = useAppContext();
	const { productsService } = useSelectedStoreContext();
	const { isMobile } = useScreenService();

	const handleClick = () => {
		if (productsService.isEndOfList) {
			return scrollToProductsTop();
		}

		if (productsService.isLoading) {
			return;
		}

		productsService.fetchNextPage();
	};

	const btnRef = useRef<HTMLDivElement | null>(null);
	const observer = useRef<IntersectionObserver | null>(null);
	const fetchNextPage = productsService.fetchNextPage;
	useEffect(() => {
		if (productsService.isLoading || !appearance.appearanceQuery?.show_more_infinite) return;

		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting) {
					fetchNextPage();
				}
			},
			{ threshold: 0.3 }
		);

		if (btnRef.current) {
			observer.current.observe(btnRef.current);
		}

		return () => {
			if (observer.current) observer.current.disconnect();
		};
	}, [
		productsService.isLoading,
		productsCount,
		fetchNextPage,
		appearance.appearanceQuery?.show_more_infinite,
	]);

	if (productsService.isAi || (productsService.isEndOfList && productsCount <= 6)) {
		return null;
	}

	return (
		<div className={"w-100 mt-2 border"} ref={btnRef}>
			{!appearance.appearanceQuery?.show_more_infinite && (
				<MDBBtn
					outline
					size={"lg"}
					onClick={() => handleClick()}
					className={`w-100 theme-button outline ${
						view === "list" && isMobile ? "rounded-0" : ""
					}`}
				>
					{productsService.isEndOfList ? (
						<>
							<span>{localisation.global.backToStart} </span>
							<MDBIcon
								fas
								icon="chevron-up"
								className="align-self-center"
								size={"lg"}
							></MDBIcon>
						</>
					) : (
						localisation.global.showMore
					)}
				</MDBBtn>
			)}
		</div>
	);
}

interface ProductsExistsProps extends ProductsProps {
	products: Product[];
	filledCategories: Category[];
}

function ProductsExists({ view, products, isFavorites, filledCategories }: ProductsExistsProps) {
	const { localisation } = useAppContext();

	const { categoriesService, productsService } = useSelectedStoreContext();

	const fatherCategoryId = categoriesService.selectedCategoryId || "top";
	const categories = categoriesService.getCategories(fatherCategoryId);

	const { computedDefaultSkeletonCount } = useProductViewSize();

	if (products.length === 0 && !productsService.isLoading && productsService.isLoaded) {
		return (
			<div className="w-100 text-center small text-muted">{localisation.menu.noProducts}</div>
		);
	}

	return (
		<>
			{!productsService.search &&
			productsService.sort === "categories" &&
			(categories?.length || categoriesService.selectedCategoryId) &&
			!isFavorites ? (
				categories?.length && productsService.sort === "categories" ? (
					<>
						{filledCategories.map(category => (
							<CategoryProducts
								view={view}
								isAvailable={true}
								key={category.id}
								category={category}
							/>
						))}

						<CategoryProducts view={view} isAvailable={true} key={"other"} />

						{products.some(x => !x.is_available) && (
							<>
								<CategoryProducts
									view={view}
									isAvailable={false}
									key={"not-available"}
								/>
							</>
						)}
					</>
				) : (
					<>
						<CategoryProducts
							view={view}
							isAvailable={true}
							category={categoriesService.getActiveCategory()}
							key={`category-${categoriesService.selectedCategoryId}`}
						/>

						{products.some(x => !x.is_available) && (
							<>
								<CategoryProducts
									view={view}
									isAvailable={false}
									key={"not-available"}
								/>
							</>
						)}
					</>
				)
			) : (
				<Grid container columnSpacing={2} rowSpacing={view === "grid" ? 2 : 1} px={2}>
					{products?.map(
						product =>
							!!product && (
								<ProductComponent view={view} key={product.id} product={product} />
							)
					)}
				</Grid>
			)}

			{productsService.isLoading && (
				<ProductsSkeleton
					view={view}
					count={view === "grid" ? computedDefaultSkeletonCount : 1}
				/>
			)}
		</>
	);
}

interface ProductsSkeletonProps {
	view: ViewType;
	count?: number;
	hideHeader?: boolean;
}

function ProductsSkeleton({ view, count = 2, hideHeader }: ProductsSkeletonProps) {
	const { computedGridViewSize } = useProductViewSize();

	return (
		<Grid container columnSpacing={2} rowSpacing={view === "grid" ? 2 : 1} px={2}>
			{!hideHeader && (
				<Grid item xs={12}>
					<Typography
						variant={"h5"}
						fontWeight={"bold"}
						mt={1}
						sx={{ userSelect: "none" }}
					>
						<Skeleton width={"100%"} />
					</Typography>
				</Grid>
			)}

			{[...Array(count).keys()].map(key => (
				<Grid
					item
					key={key}
					xs={view === "list" ? 12 : 6}
					lg={view === "list" ? 12 : computedGridViewSize}
				>
					<ProductCardSkeleton view={view} />
				</Grid>
			))}
		</Grid>
	);
}

interface CategoryProductsProps {
	category?: Category | null;
	view: ViewType;
	isAvailable: boolean;
}

function CategoryProducts({ category, view, isAvailable }: CategoryProductsProps) {
	const { cartService, productsService, categoriesService } = useSelectedStoreContext();
	const localisation = useLocalisation();

	const activeCategory = categoriesService.getActiveCategory();
	const computedCategoryName = useMemo(() => {
		if (category?.name) return category.name;

		if (!isAvailable) return localisation.cart.notAvailable;

		return localisation.menu.categoryOtherProducts
			.replace("{category_name}", activeCategory?.name || "")
			.trimEnd();
	}, [
		activeCategory?.name,
		category?.name,
		isAvailable,
		localisation.cart.notAvailable,
		localisation.menu.categoryOtherProducts,
	]);

	let categoryProducts = productsService.products.filter(product => {
		if (category) {
			return productsService.isProductInCategory(product, category);
		}

		if (!categoriesService.selectedCategoryId) {
			return !product.categories?.length || !categoriesService.isAnyCategories;
		}

		const childCategories = categoriesService.getCategories(
			categoriesService.selectedCategoryId
		);
		if (!childCategories) {
			return true;
		}

		return !childCategories.some(child => productsService.isProductInCategory(product, child));
	});

	const getNeedAdditionalMarginBottom = (product: Product, index: number) => {
		if (
			cartService.getCartProduct(product.id) &&
			productsService.canAddProductWithAnotherAttributes(product)
		) {
			return "";
		}
		if (index % 2 !== 0) {
			const prod = categoryProducts[index - 1];
			if (
				prod &&
				cartService.getCartProduct(prod.id) &&
				productsService.canAddProductWithAnotherAttributes(prod)
			) {
				return "calc(0.875rem * 1.43 + 2px)";
			}
		} else {
			const prod = categoryProducts[index + 1];
			if (
				prod &&
				cartService.getCartProduct(prod.id) &&
				productsService.canAddProductWithAnotherAttributes(prod)
			) {
				return "calc(0.875rem * 1.43 + 2px)";
			}
		}
		return "";
	};

	categoryProducts = categoryProducts.filter(product => product.is_available === isAvailable);

	if (!isAvailable)
		categoryProducts = productsService.products.filter(product => !product.is_available);

	if (!categoryProducts.length) return null;

	return (
		<Grid container columnSpacing={2} rowSpacing={view === "grid" ? 2 : 1} px={2}>
			<Grid item xs={12}>
				<Typography variant={"h5"} fontWeight={"bold"} mt={1} sx={{ userSelect: "none" }}>
					{computedCategoryName}
				</Typography>
			</Grid>

			{categoryProducts.map((product, index) => (
				<ProductComponent
					view={view}
					key={product.id}
					product={product}
					additionalMarginBottom={getNeedAdditionalMarginBottom(product, index)}
				/>
			))}
		</Grid>
	);
}

interface ProductComponentProps extends GridProductCardProps {
	view: ViewType;
	product: Product;
}

function ProductComponent({ product, view, additionalMarginBottom }: ProductComponentProps) {
	const { computedGridViewSize } = useProductViewSize();

	return (
		<Grid
			item
			key={product.id}
			xs={view === "list" ? 12 : 6}
			lg={view === "list" ? 12 : computedGridViewSize}
		>
			<ProductCard
				view={view}
				product={product}
				additionalMarginBottom={additionalMarginBottom}
			/>
		</Grid>
	);
}

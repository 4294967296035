export enum ProfileTab {
    overall = "overall",
    loyalty = "loyalty",
    orders = "orders",
    favorites = "favorites",
    myCard = "my_card",
    shareAndEarn = "share_and_earn",
    friends = "friends",
}

export interface IEditPasswordForm {
    currentPassword: string
    newPassword: string
    repeatNewPassword: string
}

import { Check } from "../../../features/Check";
import { Invoice } from "../../../api/invoice/types";
import { IUsePaymentsService } from "../hooks/useNewPaymentsService";

interface InvoiceCheckProps {
	invoice: Invoice;
	paymentService?: IUsePaymentsService | null;
}

export default function InvoiceCheck({ invoice, paymentService }: InvoiceCheckProps) {
	return (
		<Check
			data={{
				type: "regular",
				status_pay: "payed",
				viewOrder: true,
				items: invoice.items.map(item => ({
					code: item.item_code !== "entered_amount" ? item.item_code : null,
					name: item.name,
					quantity: item.quantity,
					price: item.price,
					sum: item.before_loyalty_sum,
					discount_sum: item.discount,
					hideImage: true,
					hideQuantity1: true,
				})),
				subtotal: invoice.items.reduce((total, item) => total + item.before_loyalty_sum, 0),
				totalDiscount: invoice.discount_and_bonuses_redeemed,
				deliveryPrice: invoice.shipment_cost,
				paymentPrice: invoice.custom_payment_cost,
				totalAmount: invoice.total_sum,
				tips: invoice.tips_sum,
				totalAmountWithTips: invoice.sum_to_pay,
				paid_sum: invoice.paid_sum,
				extraFee: invoice.extraFee,
				total_sum_with_extra_fee: invoice.total_sum_with_extra_fee,
				payer_fee: paymentService ? paymentService.calculateFeeRange() : invoice.payer_fee,
				payer_fee_row: paymentService ? paymentService.showPayerFeeRow : invoice.payer_fee,
				sum_to_pay: paymentService
					? paymentService.calculateFeeRange(true)
					: invoice.paid_sum,
				isLoyaltyAwards:
					invoice.payer_id === invoice.user_id
						? !!invoice.incust_check?.bonuses_added_amount ||
							!!invoice.incust_check?.special_accounts_charges?.length ||
							!!invoice.incust_check?.emitted_coupons?.length
						: false,
				bonuses: invoice.incust_check?.bonuses_added_amount,
				customerAccounts: invoice.incust_check?.special_accounts_charges,
				coupons: invoice.incust_vouchers,
			}}
		/>
	);
}

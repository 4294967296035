import { FormEvent, useState } from "react";
import TextField from "@mui/material/TextField";

import useMergeState from "../../../../helpers/useMergedState";
import { IEditPasswordForm } from "../../types";
import useAppContext from "../../../../useAppContext";
import { ChangePasswordData } from "../../../../api/auth/types";
import api from "../../../../api";
import FormFooter from "./FormFooter";
import { Alert, Box } from "@mui/material";
import { TgLink } from "../../../../helpers/ThemeComponents";
import Authorisation from "../../../../auth/Autorisation/Authorisation";
import useAuthorisationForm from "../../../../auth/Autorisation/useAuthorisationForm";
import Interweave from "../../../../features/Interweave";

export default function EditPassword() {
	const {
		localisation: { auth },
		authService: { user, loadUser },
	} = useAppContext();
	const authForm = useAuthorisationForm();

	const [form, setForm] = useMergeState<IEditPasswordForm>({
		currentPassword: "",
		newPassword: "",
		repeatNewPassword: "",
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [apiError, setApiError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);
	const [isResetPassword, setIsResetPassword] = useState<boolean>(false);

	const submit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setApiError(null);

		if (!form.newPassword || !form.repeatNewPassword) return;
		if (!form.currentPassword && user?.is_password) return;

		if (form.newPassword !== form.repeatNewPassword) {
			return setApiError(auth.passwordsDoNotMatchError);
		}

		try {
			setIsLoading(true);

			const payload: ChangePasswordData = {
				current_password: form.currentPassword || null,
				new_password: form.newPassword,
			};

			await api.auth.changePassword(payload);
			await loadUser();
			setSuccess(true);
		} catch (ex: any) {
			setApiError(ex.response?.data?.detail || ex.message || "unknown error");
		} finally {
			setIsLoading(false);
		}
	};

	if (success) {
		return (
			<Alert
				severity={"success"}
				variant={"outlined"}
				className={"small ms-1 p-3 w-100 mt-2"}
			>
				<Interweave content={auth.changePasswordSuccess} />
			</Alert>
		);
	}

	return (
		<>
			{!isResetPassword ? (
				<form onSubmit={submit}>
					{!!user?.is_password && (
						<>
							<TextField
								required
								size={"medium"}
								fullWidth
								id={"password"}
								type={"password"}
								label={auth.loginPasswordLabel}
								value={form.currentPassword}
								onChange={e => setForm({ currentPassword: e.target.value })}
							/>

							<Box sx={{ mb: 2 }} className={"form-text text-end"}>
								{!!user.email && (
									<TgLink
										onClick={e => {
											e.preventDefault();
											authForm.startResetPassword(user?.email || "");
											setIsResetPassword(true);
										}}
									>
										{auth.forgotPass}
									</TgLink>
								)}
							</Box>
						</>
					)}

					<TextField
						sx={{ mb: 2 }}
						required
						size={"medium"}
						fullWidth
						id={"newPassword"}
						type={"password"}
						label={auth.newPasswordLabel}
						value={form.newPassword}
						onChange={e => setForm({ newPassword: e.target.value })}
					/>

					<TextField
						sx={{ mb: 2 }}
						required
						size={"medium"}
						fullWidth
						id={"repeatNewPassword"}
						type={"password"}
						label={auth.repeatNewPasswordLabel}
						value={form.repeatNewPassword}
						onChange={e => setForm({ repeatNewPassword: e.target.value })}
					/>

					<FormFooter isLoading={isLoading} apiError={apiError} />
				</form>
			) : (
				<Authorisation
					form={authForm}
					resetPasswordBackCallback={() => setIsResetPassword(false)}
				/>
			)}
		</>
	);
}

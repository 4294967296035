import { catalogBasePath } from "../base";
import { AxiosInstance } from "axios";
import { Group } from "./types";
import { AuthSettings } from "../shop/basic/types";
import { IAppearanceSettings } from "../../shop/services/useAppearanceSettings/types";

export default function GroupsApiModule(instance: AxiosInstance) {
	const getPath = (path: any) => `groups/${path}`;

	return {
		// old catalog
		getIsMoreThanOneGroup(bot_id: number) {
			const url = `${catalogBasePath}/isMoreThanOneGroup/${bot_id}`;
			return instance.get(url);
		},

		// old catalog
		getGroups(bot_id: number, args: {}) {
			const url = `${catalogBasePath}/groups/${bot_id}`;
			return instance.get(url, {
				params: args,
			});
		},

		getGroupById(id: number) {
			return instance.get<Group>(getPath(id));
		},

		getGroupAuthSettings(id: number) {
			return instance.get<AuthSettings>(`${getPath(id)}/auth_settings`);
		},
		getGroupAppearanceSettings(id: number) {
			return instance.get<IAppearanceSettings>(`${getPath(id)}/appearance_settings`);
		},
	};
}

import { useMemo } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import { IncustWallet } from "../../loyalty/components/Wallet/IncustWallet";
import ProfileLayout from "../ProfileLayout";
import { ProfileTab } from "../types";
import useAppContext from "../../../useAppContext";
import ProfileIdItem from "../ProfileIdItem";
import useUserIncustCustomerData from "../../../hooks/incust/useUserIncustCustmerData";

export default function ProfileLoyalty() {
	const {
		brandInfo,
		authService: { user },
	} = useAppContext();

	const tab = ProfileTab.loyalty;

	const { customerData } = useUserIncustCustomerData();

	const computedIncustCustomerId = useMemo(() => {
		return customerData?.external_id || "unknown";
	}, [customerData?.external_id]);

	if (!brandInfo || !brandInfo.loyalty_info?.loyalty_enabled) return null;

	return (
		<ProfileLayout tab={tab}>
			<MDBRow className={"justify-content-center"}>
				<MDBCol size={12}>
					{!!(user && customerData?.token) && (
						<ProfileIdItem
							id={computedIncustCustomerId}
							iconSize={18}
							classes={"text-center mb-2"}
						/>
					)}
					<IncustWallet widgetHeight={"600px"} />
				</MDBCol>
			</MDBRow>
		</ProfileLayout>
	);
}

import InvoicePage from "./InvoicePage/InvoicePage";
import CustomerStoreOrderPage from "../order/CustomerOrderPage"

interface IFriendPaymentPage {
    mode: 'order' | 'invoice'
}


export default function FriendPaymentPage(props: IFriendPaymentPage) {

    return (<>
        {props.mode === 'invoice' && <InvoicePage/>}
        {props.mode === 'order' && <CustomerStoreOrderPage/>
        }
    </>)
}
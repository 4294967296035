import { useParams } from "react-router-dom";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import QRCode from "qrcode.react";

export default function QrCodePage() {
	const params = useParams();
	const data = params.data;

	if (!data) return null;

	const description = params.description;
	console.log(`desc=${description}`);
	const qrCodeStyle = {
		margin: "0 auto", // для центрування по середині за шириною
	};

	return (
		<MDBRow className={"justify-content-center"}>
			<MDBCol size={12} md={8} lg={6}>
				<div>
					{/* {localisation.orders.qr_code_show_text}
                {description} */}
				</div>
				<div style={{ textAlign: "center" }}>
					<QRCode value={data} style={qrCodeStyle} />
				</div>
			</MDBCol>
		</MDBRow>
	);
}

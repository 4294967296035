import { Box, Grid, Typography } from "@mui/material";
import formatCurrency from "../../../../helpers/formatCurrency";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import BasePayButton from "../BasePayButton";
import useAppContext from "../../../../useAppContext";
import useCurrency from "../../../../services/useCurrencyService";
import { IncustPayAccountData } from "./types";
import useUnifiedSpecialAccountData from "./useUnifiedSpecialAccountData";
import { PayButtonIcon } from "../PayButton";
import { PaymentMethod } from "../../../../api/shop/basic/types";
import { PaymentFee } from "../../Payment/PaymentItem";
import { useEffect, useRef } from "react";
import f from "../../../../helpers/formatText";

interface IncustPayButtonProps {
	data: IncustPayAccountData;
	setData: (data: IncustPayAccountData) => void;
	logo?: string | null;
	method?: PaymentMethod | null;
	totalAmount?: number;
	currency?: string;
	payButtonMaxHeight?: number | null;
	setPayButtonMaxHeight?: (value: number) => void;
	anyFee?: boolean;
}

export default function IncustPayButton(props: IncustPayButtonProps) {
	const btnRef = useRef<HTMLButtonElement | null>(null);
	const { lang, localisation, brandInfo } = useAppContext();

	const currency = useCurrency();

	const specialAccountData = useUnifiedSpecialAccountData(props.data);

	const setPayButtonMaxHeight = props.setPayButtonMaxHeight;
	useEffect(() => {
		if (btnRef.current && setPayButtonMaxHeight) {
			let height = btnRef.current.clientHeight;
			if (!height || height < 24) {
				height = 24;
			}
			setPayButtonMaxHeight(height);
		}
	}, [setPayButtonMaxHeight]);

	return (
		<BasePayButton onClick={() => props.setData(props.data)}>
			<Box
				textAlign={"start"}
				width={"100%"}
				ref={btnRef}
				sx={{
					minHeight: props.payButtonMaxHeight ? `${props.payButtonMaxHeight}px` : "auto",
					alignContent: "center",
					alignSelf: "center",
					overflow: "hidden",
					overflowWrap: "break-word",
					width: "100%",
				}}
			>
				<Box display={"flex"} alignSelf={"center"} flex={1} width={"100%"}>
					{props.logo && (
						<PayButtonIcon path={props.logo} alt={specialAccountData.title} />
					)}
					<Box pl={2} alignSelf={"center"} overflow={"hidden"} width={"100%"}>
						<Typography
							sx={{ lineHeight: 1.4, alignSelf: "center" }}
							overflow={"hidden"}
							textOverflow={"ellipsis"}
							variant={"subtitle1"}
						>
							{specialAccountData.title}
						</Typography>
						<Box alignSelf={"center"} overflow={"hidden"} width={"100%"}>
							<Grid container gap={1} flexWrap={"nowrap"} alignItems={"center"}>
								<Grid
									item
									xs={true}
									display={"flex"}
									overflow={"hidden"}
									whiteSpace={"nowrap"}
								>
									<Typography
										sx={{ lineHeight: 1.2, alignSelf: "center" }}
										overflow={"hidden"}
										textOverflow={"ellipsis"}
										variant={"body2"}
										color={"text.secondary"}
										textTransform={"none"}
									>
										{localisation.payment.incustPayAvailableAmountText + ": "}
										{specialAccountData.is_unlimited ? (
											<AllInclusiveIcon
												color={"inherit"}
												fontSize={"inherit"}
											/>
										) : (
											formatCurrency(
												specialAccountData.available_amount || 0,
												brandInfo?.default_lang || lang,
												currency
											)
										)}
									</Typography>
								</Grid>
							</Grid>
							{!!(props.method && props.totalAmount && props.currency) && (
								<PaymentFee
									feeValue={props.method.fee_value || 0}
									feePercent={props.method.fee_percent || 0}
									totalAmount={props.totalAmount}
									currency={props.currency}
									anyFee={props.anyFee}
								/>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</BasePayButton>
	);
}

import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";

import { AttributeGroup } from "../../../../../api/shop/basic/types";
import { PopupBody } from "../../../../../features/PopupBody";
import Interweave from "../../../../../features/Interweave";

interface IAttributeErrorPopoverProps {
	text: string | null;
	attrGroup: AttributeGroup;
	anchor?: any;
}

export default function AttributeErrorPopover(props: IAttributeErrorPopoverProps) {
	return (
		<BasePopup
			id={props.attrGroup.id.toString()}
			open={!!props.text}
			anchor={props.anchor}
			disablePortal={true}
			placement={"top-start"}
		>
			<PopupBody sx={{ maxWidth: 250 }}>
				<Interweave content={props.text || ""} />
			</PopupBody>
		</BasePopup>
	);
}

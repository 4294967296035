import {useState, useCallback} from "react";
import {Box, Button, CircularProgress, TextField} from "@mui/material";

import {AuthorisationComponentProps} from "./types";
import api from "../../api";
import {PurposeType} from "../../api/auth/types";
import useAppContext from "../../useAppContext";
import FormError from "./FormError";
import DialogActions from "@mui/material/DialogActions";
import Interweave from "../../features/Interweave";


export default function ResetPassword(props: AuthorisationComponentProps) {
    if (props.form.state !== 'resetPassword') {
        throw new Error('EmailLogin must be called only when state is emailLogin')
    }

    const {localisation: {auth}} = useAppContext()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [isSent, setIsSent] = useState<boolean>(false)

    const sendConfirmEmail = useCallback(() => {
        if (props.form.state !== 'resetPassword') return null // for typescript
        const payload = {
            purpose: 'reset_password' as PurposeType,
            email: props.form.email,
        }

        setIsLoading(true)

        api.auth.sendConfirmEmail(payload).then((() => {
            setIsSent(true)
        })).catch((err) => {
            setError(err?.response?.data?.detail || err.message)
        }).finally(() => setIsLoading(false))
    }, [props.form.email, props.form.state])

    return (
        <>
            <FormError errText={error}/>

            {!isSent
                ? (
                    <>
                        <Box>
                            <TextField
                                size={'small'}
                                fullWidth
                                name={'email'}
                                type={'email'}
                                value={props.form.email}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                            <Box sx={{textAlign: 'left', mt: 2}}>
                                <Interweave
                                    content={auth.resetPasswordInfo}
                                />
                            </Box>
                        </Box>
                    </>
                )
                : (
                    <Box sx={{textAlign: 'left'}}>
                        <Interweave
                            content={auth.resetPasswordInfoSent.replace('{email}', props.form.email)}
                        />
                    </Box>
                )}

            <ResetPasswordButtons
                form={props.form}
                isSent={isSent}
                sendFunc={sendConfirmEmail}
                isLoading={isLoading}
                resetPasswordBackCallback={props.resetPasswordBackCallback}
            />
        </>
    )
}

interface IResetPasswordButtonsProps extends AuthorisationComponentProps {
    isSent: boolean
    sendFunc: () => void
    isLoading: boolean
    resetPasswordBackCallback?: () => void
}

function ResetPasswordButtons(props: IResetPasswordButtonsProps) {
    const {localisation: {global}} = useAppContext()

    return (
        <DialogActions>
            <Button
                variant={'text'}
                sx={{my: 2}}
                onClick={() => {
                    if(props.resetPasswordBackCallback){
                        props.resetPasswordBackCallback()
                    }
                    props.form.startEmailLogin()
                }}
                disabled={props.isLoading}
            >
                {global.back}
            </Button>

            {!props.isSent && (
                <Button
                    variant={'contained'}
                    onClick={props.sendFunc}
                    sx={{my: 2}}
                    disabled={props.isLoading}
                >
                    {props.isLoading && (
                        <CircularProgress
                            className={'me-2'}
                            style={{width: '14px', height: '14px'}}
                        />
                    )}
                    {global.confirmButton}
                </Button>
            )}
        </DialogActions>
    )
}

import { ExternalType } from "../types";
import { ShipmentBaseTypeLiteral } from "../../../shop/types";
import { IIncustCouponShowData, IIncustData } from "../loyalty/types";
import { IIncustCheck, ILoyaltyInfo } from "../../../shop/loyalty/types";
import { IAddressComponent } from "../../map/types";
import { IncustPayPaymentData, PaymentPayload } from "../payments/types";

export type GetCitiesArgs = {
	brand_id: number;
	is_delivery?: boolean;
	is_in_store?: boolean;
	is_pickup?: boolean;
};

export type GetStoreArgs = {
	brand_id: number;
	user_id?: number | null;
};

export type GetStoresArgs = {
	city?: string | null;
	is_delivery?: boolean;
	is_pickup?: boolean;
	is_in_store?: boolean;
	latitude?: number | null;
	longitude?: number | null;
} & GetStoreArgs;

export type BillingSettings = {
	is_enable: boolean;
	is_require: boolean;
	need_person: boolean;
	need_organization: boolean;
};

export type WorkingSlot = {
	start_time_text: string;
	end_time_text: string;
	id: number;
	working_day_id: number;
};

export type WorkingDay = {
	store_id: number;
	day: string;
	is_weekend: boolean;
	id: number;
	slots: WorkingSlot[] | null;
};

export type Store = {
	id: number;
	brand_id: number;
	external_id: string | null;
	external_type: ExternalType | null;

	name: string;
	description: string | null;
	image_url: string | null;

	get_order_id: number | null;

	latitude: string | null;
	longitude: string | null;
	distance: number | null;
	is_swap_coordinates: boolean | null;
	polygon: { [key: string]: string } | null;

	currency: string;

	address_times: string | null;

	custom_fields: StoreCustomField[];
	banners: Banner[];

	is_offer_doc_exist: boolean;
	is_about_doc_exist: boolean;
	billing_settings?: BillingSettings | null;
	working_days: WorkingDay[] | null;

	has_categories: boolean;
	is_enabled_emenu: boolean;
};

export type CitiesResponse = {
	cities: string[];
};

export type StoreCustomField = {
	id: string;
	name: string;
	value: string | Array<string> | null;
};

export type Banner = {
	url: string | null;
	image_path: string;
};

export type GetCategoriesTreeArgs = {
	store_id: number;
	brand_id?: number | null | undefined;
	filters_set_id?: number | null | undefined;
	products_search?: string | null | undefined;
};

export type GetCategoriesArgs = GetCategoriesTreeArgs & {
	father_category_id?: number | null | undefined;
};

export type Category = {
	id: number;
	has_child_categories: boolean;
	father_category_id: number | null;

	external_id: string | null;
	external_type: ExternalType | null;

	name: string;
	position: number | null;

	image_url: string | null;
	products_count: number;

	filters: Characteristic[];

	children?: Category[] | null;
};

export type Characteristic = {
	id: number;
	name: string;
	filter_type: FilterType;
};

export type FilterType = "v" | "m" | "ri" | "i" | "f";

export enum FilterTypeEnum {
	VALUE = "v",
	MULTI = "m",
	RANGE_INTEGER = "ri",
	INTEGER = "i",
	FLOAT = "f",
}

export type GetProductsArgs = {
	category_id?: number | null | undefined;
	offset?: number | null | undefined;
	limit?: number | null | undefined;
	search_text?: string | null | undefined;
	filters_set_id?: number | null | undefined;
	sort?: "low_price" | "high_price" | "categories" | undefined;
	brand_id?: number | null | undefined;
};

export type GetCharacteristicValuesArgs = {
	brand_id: number | null | undefined;
	category_id?: number | null | undefined;
};

export type GetMinMaxProductsPricesArgs = {
	category_id?: number | null | undefined;
	filters_set_id?: number | null | undefined;
};

export type MinMaxProductsPrices = {
	min: number;
	max: number;
};

export type GetProductArgs = {
	brand_id: number | null | undefined;
};

export type FindProductModificationData = {
	find_for_characteristic_id: number;
	filters: { [key: number]: string };
};

export type FloatingSumSettings = {
	is_enabled: boolean;
	min?: number | null;
	max?: number | null;
	options?: number[] | null;
	user_sum_enabled: boolean;
};

export type ProductType = "goods" | "info" | "gift" | "service" | "topup";

export type Product = {
	id: number;
	is_available: boolean;

	external_id: string | null;
	external_type: ExternalType | null;

	product_id: string;
	get_order_id: number | null;

	name: string;
	description: string | null;
	image_url: string | null;
	thumbnail_url: string | null;

	gallery: Gallery | null;

	price: number;
	old_price: number;

	is_weight: boolean | null;
	unit: string | null;

	buy_min_quantity: number;
	position: number | null;
	brand_id: number;
	categories: number[] | null;

	attribute_groups: AttributeGroup[];
	modifiers: ProductModifiers;
	characteristics: ProductCharacteristics;

	floating_sum_settings: FloatingSumSettings | null;

	pti_info_text?: string | null;
	pti_info_link?: string | null;
	type: ProductType;
	need_auth: boolean;
	floating_qty_enabled: boolean;

	topup_account_id: string | null;
};

export type Gallery = {
	items: GalleryItem[];
};

export type GalleryItem = {
	id: number;
	media_type: string;
	media_url: string;
	position: number;
};

export type ProductCharacteristics = { [key: string]: string };

export type ProductModifier = {
	id: number;
	name: string;
	value: string;
	options: ProductModifierOption[];
};

export type ProductModifierOption = {
	value: string;
	orig_value: string;
	is_available: boolean;
};

export type ProductModifiers = { [key: number]: ProductModifier };

export type ProductList = {
	products: Product[];
	is_end: boolean;
	is_ai: boolean;
};

export type AttributeGroup = {
	id: number;
	attribute_group_id: number;
	name: string;
	external_id: string | null;
	external_type: ExternalType | null;
	min: number;
	max: number | null;
	is_default: boolean;
	attributes: Attribute[];
	brand_id: number;
};

export type Attribute = {
	id: number;
	attribute_id: string;
	attribute_group_id: number;

	name: string;
	is_available: boolean;
	selected_by_default: boolean;

	price_impact: number;
	product_id: number | null;
	external_id: string | null;
	external_type: ExternalType | null;
	min: number | null;
	max: number | null;
	is_default: boolean;
	brand_id: number;
};

export type BrandViewType = "default_grid" | "default_list" | "only_grid" | "only_list";
export type MobileCartButtonModeType = "bottom" | "bar";
export type DeliveryTimeType = "datetime" | "date" | "disabled";

export type BrandScanSettingsCountryType = "kz" | "uz" | "sk";

export type BrandScanReceiptsSettings = {
	enabled: boolean;
	country?: BrandScanSettingsCountryType | null;
	bin_codes?: string[] | null;
};

export type BrandInfo = {
	id: number;
	name: string;

	image_url: string | null;
	logo_url: string | null;

	is_get_order: boolean;
	group_id: number;
	bot_id: number | null;
	bot_name: string | null;
	default_lang: string;
	is_translate: boolean;

	is_offer_doc_exist: boolean;
	is_about_doc_exist: boolean;

	delivery_datetime_mode: DeliveryTimeType;
	delivery_time_warning_enabled: boolean | null;

	incust_data?: IIncustData | null;
	loyalty_info?: ILoyaltyInfo | null;
	default_currency?: string | null;
	stores_count: number;

	order_fields_settings: {
		web: OrderClientFieldsSettings;
		messanger: OrderClientFieldsSettings;
		order_comment_mode: OrderFieldSettingMode;
	};

	footer_sign: string;
	terms_of_use_link: string;
	is_friend_payment: boolean;
	google_maps_public_api_key?: string | null;
	is_enabled_google_maps_api?: boolean;
	is_enabled_ai: boolean;
	scan_receipts_settings?: BrandScanReceiptsSettings | null;
	consent_mode?: ConsentMode | null;

	transactions_activated: boolean;
};

export type DetectedBrand = {
	brand: BrandInfo;
	is_brand_domain: boolean;
};

export type OrderClientFieldsSettings = {
	email: OrderFieldSettingMode;
	phone: OrderFieldSettingMode;
	order_name_mode: OrderNameFieldSettings;
};

export type OrderFieldSettingMode = "required" | "optional" | "disabled";
export type OrderNameFieldSettings = "first_and_last" | "only_first_name" | "optional";
export type ConsentMode = "per_order" | "one_time" | "info";

export type GetPaymentsArgs = {
	store_id?: number | null | undefined;
	order_id?: number | null | undefined;
	brand_id?: number | null | undefined;
	bot_id?: number | null | undefined;
	is_qr_menu?: boolean | null | undefined;
	shipment_id?: number | null | undefined;
	incust_account_id?: string | null | undefined;
	invoice_template_id?: number | null | undefined;
};

export type PrePaymentPayload = {
	payment_settings_id: number;
	comment?: string | null;
	brand_id: number;
} & PaymentPayload;

export type AvailablePaymentFee = {
	payment_method: string;
	fee: number;
};

export type ExtraFeeSchema = {
	extra_fee_id: number;
	name: string;
	extra_fee_percent: string | null;
	extra_fee_value: string | null;
	applied_amount: number;
	invoice_id: number | null;
	order_id: number | null;
	applied_amount_float: number;
};

export type AdditionalFees = {
	service_fees: AvailablePaymentFee[];
	extra_fees: ExtraFeeSchema[];
};

export type GetPaymentsFeeArgs = {
	brand_id?: number | null | undefined;
	store_id?: number | null | undefined;
	order_id?: number | null | undefined;
	total_sum: number;
	sum_to_pay: number;
	currency: string;
	invoice_template_id: number | null | undefined;
};

export type ShipmentsData = {
	base: Shipment[];
	groups: CustomShipmentGroup[];
	rest: CustomShipment[];
};

export type BaseShipment = {
	id: number;
	base_type: ShipmentBaseTypeLiteral;

	name: string;

	price: number;
	min_price: number;
	max_price: number;

	is_paid_separately: boolean;

	allow_online_payment: boolean;
	allow_friend_payment?: boolean;
	allow_cash_payment: boolean;
	payments_ids: number[];

	delivery_datetime_mode: DeliveryTimeType;
	delivery_time_warning_enabled: boolean;

	enabled_tips: boolean;

	prices: ShipmentPrice[];

	store_settings_id?: number | null;

	map_countries?: string[] | null;
	enabled_any_address_from_map: boolean;

	shipment_time?: ShipmentTime | null;
};

export type ShipmentTime = {
	min_time?: number | null;
	max_time?: number | null;
	execution_time?: number | null;
};

export type Shipment = {
	custom_type: "shipment";
} & BaseShipment;

export type CustomShipment = {
	custom_type: "custom_shipment";

	description: string | null;
	icon_url: string | null;

	need_comment: boolean;
	label_comment: string;
	need_address: boolean;
	not_working_hours: string;
} & BaseShipment;

export type CustomShipmentGroup = {
	id: number;

	custom_type: "custom_shipment_group";
	name: string;
	description: string | null;
	icon_url: string | null;
	shipments: CustomShipment[];

	prices?: ShipmentPrice[] | null;
	store_settings_id?: number | null;
};

export type PaymentMethodBase = {
	provider: string;
	is_online: boolean;
};

export type PaymentCommentLiteral = "required" | "optional" | "disabled";

export type PaymentMethod = {
	logo: string;
	fee_value?: number | null;
	fee_percent?: number | null;
	name?: string | null;
	default_name?: string | null;
	desc?: string | null;
	settings_id: number;
	need_comment: PaymentCommentLiteral;
	label_comment?: string | null;
	incust_pay_data?: IncustPayPaymentData | null;
} & PaymentMethodBase;

export type AvailablePayments = {
	is_multiple_payment_methods: boolean;
	single_payment_method: PaymentMethod | null;
	available_tg: boolean;
	email_required: string[];
	is_epay_test_mode: boolean;
	methods: PaymentMethod[];
};

export type GetDocumentArgs = {
	store_id?: number | null | undefined;
	document_type: "agreement" | "about";
};

export type TextResponse = {
	text: string;
};

export type CharacteristicValue = {
	value: string;
	display_value: string;
};

export type CharacteristicValues = {
	characteristic_id: number;
	store_id: number;
	values: CharacteristicValue[];
};

export type Filter = {
	filter_type: FilterType;
	value?: number | string | null | undefined;
	values_list?: Array<number | string | null> | undefined;
	range_min?: number | null | undefined;
	range_max?: number | null | undefined;
};

export type Filters = {
	[key: number]: Filter | null;
};

export type CreateFiltersData = {
	filters: Filters;
	min_price?: number | null | undefined;
	max_price?: number | null | undefined;
};

export type FiltersSetCreated = {
	filters_set_id: number;
};

export type CustomPayment = {
	id: number;
	name: string;
	description?: string | null;
	icon_url?: string | null;
	price: number;
	need_comment: boolean;
	label_comment: string;
	is_enabled: boolean;
	need_info: boolean;
	label_info: string;
};

export type ShipmentIdObj = {
	shipment_id?: number | null;
	store_settings_id?: number | null;
};

export type ShipmentPriceInputData = {
	store_id?: number | null;

	order_sum?: number;
	address?: string | null;

	is_next_price_cheaper?: boolean;

	shipment_id?: ShipmentIdObj | null | string;
	shipment_ids?: ShipmentIdObj[] | null | string;

	address_lat?: number | null;
	address_lng?: number | null;
};

export type ShipmentPrice = {
	id: number;
	cost_delivery: number;
	minimum_order_amount: number;
	maximum_order_amount: number;
};

export type ShipmentZone = {
	id: number;
	name: string;

	is_distance: boolean;
	is_polygon: boolean;
	is_swap_coordinates: boolean;

	distance: number;
	polygon?: number[][] | null;

	prices: ShipmentPrice[];
};

export type ShipmentPriceResultData = {
	select_price?: ShipmentPrice | null;
	next_price?: ShipmentPrice | null;
	available_prices?: ShipmentPrice[] | null;
	available_zones?: ShipmentZone[] | null;
	has_zones?: boolean;
	shipment_id?: number | null;
	all_zones?: ShipmentZone[] | null;
};

export type IStorageAddressType = "google" | "default";

export type IStorageAddressObject = {
	type: IStorageAddressType;
	addressValue: string;
	addressCoords?: [number, number] | null;
	googleAddressComponents?: IAddressComponent[];
	placeId?: string | null;
	selectedFromMap: boolean;
};

export type ScanPayload = {
	data: string;
	with_make_check?: boolean;
	order_id?: number | null;
	store_id?: number | null;
};

export type ScanResponse = {
	receipt_id: string;
	check: IIncustCheck;
};

export type ScanResponseUnAuth = {
	receipt: any;
	check: IIncustCheck;
};

export type Receipt = {
	id: number;
	receipt_id: number;
	incust_check?: IIncustCheck | null;
	vouchers?: IIncustCouponShowData[] | null;
	total_price: number;
	bin_code?: string | null;
};

export type AuthSettings = {
	is_auth_email_enabled: boolean;
	is_auth_messanger_enabled: boolean;
	is_auth_google_enabled: boolean;
	is_auth_apple_enabled: boolean;
	is_auth_for_orders_enabled: boolean;
};

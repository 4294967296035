import {useEffect, useRef} from 'react'

import useLocalisation from "../../hooks/localisation/useLocalisation"
import {useShopContext} from "../context";
import scrollToProductsTop from "../helpers";
import {Category} from "../../api/shop/basic/types";
import {UnmountClosed} from "react-collapse";
import {
    Box,
    CircularProgress,
    ListItemButton,
    ListItemButtonProps,
    ListItemText,
    Skeleton,
    Typography
} from "@mui/material";
import {useSelectedStoreContext} from "../SelectedStore/context";
import useAppContext from '../../useAppContext';
import changePage from "../../helpers/changePage";
import List from "@mui/material/List";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Categories({isSidebar = false}){
    const {groupService} = useAppContext()
    const {setCategoriesMenuTitle} = useShopContext()
    const {categoriesService} = useSelectedStoreContext()

    const activeCategory = categoriesService.getActiveCategory()

    const localisation = useLocalisation()

    useEffect(() => {
        if(activeCategory?.name){
            setCategoriesMenuTitle(activeCategory.name)
        } else {
            setCategoriesMenuTitle(groupService.group?.texts.web.menu.categories.all_text || '')
        }
    }, [
        activeCategory?.name,
        groupService.group?.texts.web.menu.categories.all_text,
        setCategoriesMenuTitle,
    ])

    return (<>
        {!isSidebar && (
            <Typography variant={'h6'} mb={2} fontWeight={'bold'}>
                {localisation.global.sections}
            </Typography>
        )}

        {categoriesService.isLoaded ? (
            <CategoriesComponent isSidebar={isSidebar}/>
        ) : (
            <CategoriesSkeleton/>
        )}
    </>)
}


function CategoriesSkeleton() {
    return (
        <List
            className={'shop-categories'}
            sx={{py: 0}}
        >
            {[...Array(5).keys()].map(key => (
                <CategoryListItemButton
                    disableGutters
                    key={key}
                >
                    <ListItemText sx={{my: 0}}>
                        <Skeleton/>
                    </ListItemText>
            </CategoryListItemButton>
            ))}
    </List>
    )
}


function CategoriesComponent({fatherCategoryId = 'top', isSidebar}: {fatherCategoryId?: 'top' | number, isSidebar: boolean}) {
    const {categoriesService, selectedStore} = useSelectedStoreContext()
    const {sidebarsService} = useAppContext()
    const localisation = useLocalisation()

    const childCategories = categoriesService.getCategories(fatherCategoryId)

    const handleTopCategoryClick = () => {
        try {
            if (categoriesService.isSelected('top')) return

            if(fatherCategoryId === 'top') {
                categoriesService.handleClick(fatherCategoryId).then(() => {
                    scrollToProductsTop()
                })
            }
        }
        finally {
            if (isSidebar) {
                sidebarsService.setIsMainSidebarOpen(false)
                changePage(`/shop/${selectedStore.id}/menu`)
            }
        }
    }

    const allSelected = categoriesService.isSelected(fatherCategoryId)

    return (
        <List
            id={isSidebar.toString() + fatherCategoryId}
            className={(fatherCategoryId === 'top' ? 'shop-categories' : '')}
            sx={{py: 0}}
        >
            {fatherCategoryId === 'top' && !isSidebar && (
                <CategoryListItemButton
                    key={'all'}
                    onClick={handleTopCategoryClick}
                    selected={allSelected}
                >
                    <ListItemText sx={{my: 0}}>
                        {localisation.global.categoryAllButton.replace(
                            '{category_name}',
                            (
                                fatherCategoryId === 'top' ? '' :
                                    categoriesService.getCategoryById(fatherCategoryId)?.name || ''
                            ),
                        ).trimEnd()}
                    </ListItemText>
                </CategoryListItemButton>
            )}
            {childCategories && childCategories.map(category => (
                <CategoryComponent
                    key={category.id}
                    category={category}
                    isSidebar={isSidebar}
                />
            ))}
        </List>
    )
}


function CategoryListItemButton({children, selected, ...props}: ListItemButtonProps) {
    return (
        <ListItemButton
            {...props}
            selected={selected}
            sx={{
                borderRadius: '.5rem',
                px: 2,
                py: 1,
                ...selected && {
                    backgroundColor: theme => `${theme.palette.primary.main}!important`,
                    color: 'primary.contrastText',
                },
                ...props.sx,
            }}
        >
            {children}
        </ListItemButton>
    )
}


interface CategoryProps {
    category: Category
    isSidebar: boolean
}


function CategoryComponent({category, isSidebar}: CategoryProps) {
    const {categoriesService, selectedStore} = useSelectedStoreContext()
    const {sidebarsService} = useAppContext()

    const categoryRef = useRef<HTMLDivElement | null>(null)
    // const [isCollapsedSame, setIsCollapsedSame] = useState(false)

    const handleCategoryClick = () => {
        // isCollapsedSame
        if(categoriesService.isExpanded(category) && categoriesService.selectedCategoryId === category.id){
            sidebarsService.setIsMainSidebarOpen(false)
            changePage(`/shop/${selectedStore.id}/menu`)
            // return setIsCollapsedSame(false)
        }
        if(categoriesService.isExpanded(category)
                && categoriesService.selectedCategoryId === category.id){
            sidebarsService.setIsMainSidebarOpen(false)
            changePage(`/shop/${selectedStore.id}/menu`)
            // return setIsCollapsedSame(true)
        }

        categoriesService.handleClick(category).then(() => {
            // setIsCollapsedSame(false)
            if (isSidebar && !category.has_child_categories) {
                sidebarsService.setIsMainSidebarOpen(false)
                changePage(`/shop/${selectedStore.id}/menu`)
            }
        })

        scrollToProductsTop()
    }

    const isSelected = categoriesService.isSelected(category.id)

    return (
        <>
            <CategoryListItemButton
                ref={categoryRef}
                selected={isSelected}
                onClick={handleCategoryClick}
            >
                <ListItemText sx={{my: 0}}>
                    <span>{category.name}</span>
                </ListItemText>

                {category.has_child_categories && (
                    <Box
                        width={'24px'}
                        height={'24px'}
                        overflow={'hidden'}
                        color={isSelected ? 'primary.contrastText' : 'text.secondary'}
                    >
                        {categoriesService.isClickLoading(category.id) ? (
                            <CircularProgress
                                size={24}
                                color={'inherit'}
                            />
                        ) : (
                            isSelected ? (
                                <KeyboardArrowDownIcon fontSize={'medium'} color={'inherit'} />
                            ) : (
                                <KeyboardArrowRightIcon fontSize={'medium'} color={'inherit'} />
                            )
                        )}
                    </Box>
                )}
            </CategoryListItemButton>
            <UnmountClosed
                checkTimeout={25}
                isOpened={categoriesService.isExpanded(category)} //!isCollapsedSame
                onRest={({isFullyOpened}) => {
                    if (isFullyOpened && categoryRef.current) {
                        const container = categoryRef.current.closest('#categories-container') as HTMLElement;
                        if (container) {
                            const categoryRect = categoryRef.current.getBoundingClientRect();
                            const containerRect = container.getBoundingClientRect();

                            if (categoryRect.bottom > containerRect.bottom || categoryRect.top < containerRect.top) {
                                container.scrollTo({
                                    top: categoryRef.current.offsetTop - container.offsetTop,
                                    behavior: 'smooth',
                                });
                            }
                        }
                    }
                }}
            >
                <Box pl={3}>
                    <CategoriesComponent fatherCategoryId={category.id} isSidebar={isSidebar}/>
                </Box>
            </UnmountClosed>
        </>
    )
}


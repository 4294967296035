import React, { useCallback, useEffect, useState } from "react";

import { CreateOrderPayload, OrderPersonalData } from "../../../../api/shop/order/types";
import { SetMergeState } from "../../../../helpers/useMergedState";
import useAppContext from "../../../../useAppContext";
import PersonalDataStepBase from "../../../../components/steppers/steps/PersonalDataStepBase/PersonalDataStepBase";
import { OrderStepperButtons } from "./OrderStepperButtons";
import { useShopContext } from "../../../context";
import { Alert } from "@mui/material";
import useCheckEmail, { CheckEmailResult } from "../../../../hooks/useCheckEmail";
import Interweave from "../../../../features/Interweave";
import { getOrderState, saveOrderState } from "./orderSessionState";
import { IUseUpdateOrder } from "../useUpdateOrder";

interface PersonalDataProps {
	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;
	email_required: string[];

	setPrevStep: () => void;
	setNextStep: () => void;

	prevEnabled?: boolean;
	updateService?: IUseUpdateOrder | null;
}

export default function PersonalDataStep(props: PersonalDataProps) {
	const {
		authService: { user, client },
	} = useAppContext();
	const { brandInfo } = useShopContext();

	const [isCheckEmailLoading, setIsCheckEmailLoading] = useState(false);
	const [checkEmailError, setCheckEmailError] = useState("");

	const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
	const [showInvalidError, setShowInvalidError] = useState(false);

	const isEmailEnabled =
		brandInfo.order_fields_settings[client].email !== "disabled" ||
		props.email_required.length > 0;
	const isPhoneEnabled = brandInfo.order_fields_settings[client].phone !== "disabled";

	const isEmailRequired =
		brandInfo.order_fields_settings[client].email === "required" ||
		props.email_required.length > 0;
	const isPhoneRequired = brandInfo.order_fields_settings[client].phone === "required";
	const isFirstNameRequired =
		brandInfo.order_fields_settings[client].order_name_mode === "first_and_last" ||
		brandInfo.order_fields_settings[client].order_name_mode === "only_first_name";
	const isLastNameRequired =
		brandInfo.order_fields_settings[client].order_name_mode === "first_and_last";

	const checkEmail = useCheckEmail();

	const updatePersonalData = props.updateService?.updatePersonalData;
	const propsSetNextStep = props.setNextStep;
	const setNextStep = useCallback(() => {
		const orderState = getOrderState();

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
		if (
			(isFirstNameRequired && !props.form.first_name) ||
			(isLastNameRequired && !props.form.last_name) ||
			(isEmailRequired && !props.form.email) ||
			(isPhoneRequired && !props.form.phone) ||
			(props.form.phone && !isValidPhone) ||
			(props.form.email && !emailRegex.test(props.form.email))
		) {
			setShowInvalidError(true);
		} else {
			setIsCheckEmailLoading(true);
			const check = () => {
				if (!props.form.email) {
					return { ok: true, error: null };
				} else {
					return checkEmail(props.form.email);
				}
			};

			Promise.resolve<CheckEmailResult>(check())
				.then(result => {
					if (!result.ok) {
						setCheckEmailError(result.error || "");
					} else {
						const personalData: OrderPersonalData = {
							first_name: props.form.first_name,
							last_name: props.form.last_name,
							phone: props.form.phone,
							email: props.form.email,
						};
						saveOrderState(personalData, "personalData");

						if (orderState && orderState.createdOrder) {
							if (updatePersonalData) {
								Promise.resolve<{ ok: boolean } | undefined>(
									updatePersonalData()
								).then(result => {
									if (result && result.ok) {
										propsSetNextStep();
										return;
									}
								});
							} else {
								propsSetNextStep();
								return;
							}
						} else {
							propsSetNextStep();
						}
					}
				})
				.finally(() => {
					setIsCheckEmailLoading(false);
				});
		}
	}, [
		props.form.first_name,
		props.form.last_name,
		props.form.email,
		props.form.phone,
		isEmailRequired,
		isPhoneRequired,
		isFirstNameRequired,
		isLastNameRequired,
		isValidPhone,
		checkEmail,
		propsSetNextStep,
		updatePersonalData,
	]);

	useEffect(() => {
		setCheckEmailError("");
	}, [props.form.email]);

	return (
		<form onSubmit={setNextStep}>
			<PersonalDataStepBase
				form={props.form}
				setForm={props.setForm}
				isValidPhone={isValidPhone}
				setIsValidPhone={setIsValidPhone}
				showInvalidError={showInvalidError}
				isPhoneRequired={isPhoneRequired}
				isEmailRequired={isEmailRequired}
				isFirstNameRequired={isFirstNameRequired}
				isLastNameRequired={isLastNameRequired}
				fields={{
					email: isEmailEnabled,
					phone: isPhoneEnabled,
				}}
			/>
			{checkEmailError && (
				<Alert severity={"error"} sx={{ mt: 2 }}>
					<Interweave content={checkEmailError} />
				</Alert>
			)}
			<OrderStepperButtons
				prevDisabled={props.prevEnabled ? false : !!user}
				nextDisabled={!!checkEmailError}
				setPrevStep={props.setPrevStep}
				setNextStep={setNextStep}
				isNextLoading={isCheckEmailLoading || props.updateService?.isLoading}
			/>
		</form>
	);
}

import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import {TgLink} from "../../helpers/ThemeComponents";
import {useShopContext} from "../context";
import BaseShopFooter from "./BaseShopFooter";
import classnames from "classnames";
import {Box, Link} from "@mui/material";
import useAppContext from "../../useAppContext";
import MessangerIcon from "../../features/MessangerIcon";


export default function NoSelectedStoreShopFooter() {
    const {brandInfo} = useShopContext()
    const {localisation, bot} = useAppContext()

    return (
        <BaseShopFooter>
            <MDBContainer breakpoint={'xxl'} className={'px-0'}>
                <MDBRow className={'mt-3 w-100 mx-0'}>
                    <MDBCol size={6} className='mx-auto mb-4'>
                        {bot && (<>
                            <p className={classnames(
                                'mw-100',
                                'one-line'
                            )}>
                                <MessangerIcon botType={bot.bot_type}/>
                                <Link
                                    href={bot.link}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                >
                                    {localisation.global.chatButton}
                                </Link>
                            </p>
                        </>)}

                    </MDBCol>

                    <MDBCol size={6} className='mx-auto mb-4'>
                        {brandInfo.is_about_doc_exist && (
                            <Box mt={3}>
                                <TgLink
                                    href={window.location.origin + `/shop/document/about`}
                                    target={'_blank'}
                                >
                                    {localisation.global.aboutButton}
                                </TgLink>
                            </Box>
                        )}
                        {brandInfo.is_offer_doc_exist && (
                            <Box mt={3}>
                                <TgLink
                                    href={
                                        window.location.origin + `/shop/document/agreement`
                                    }
                                    target={'_blank'}
                                >
                                    {localisation.global.agreementButton}
                                </TgLink>
                            </Box>
                        )}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </BaseShopFooter>
    )
}

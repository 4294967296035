import {IQrMenuButtonProps} from "../types";
import QrMenuButtonWrapper from "./QrMenuButtonWrapper";

export default function DownloadTgButton(props: IQrMenuButtonProps){

    const onClick = () =>
        window.open("https://telegram.org/", "_blank")

    return (
        <QrMenuButtonWrapper onClick={onClick}>
            {props.buttonName}
        </QrMenuButtonWrapper>
    )
}
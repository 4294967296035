import {isShopClosed, loadWorkingTimes} from "../scripts/info";
import {WorkingTimes} from "../types";
import {
    useSelectedStoreOptional
} from "../SelectedStore/context";
import {useMemo} from "react";
import {Store} from "../../api/shop/basic/types";
import useAppContext from "../../useAppContext";


export type WorkingTimesServiceType = {
    workingTimes: WorkingTimes | null
    isShopClosed: boolean
}


export default function useWorkingTimesService(store?: Store | null): WorkingTimesServiceType {
    const {lang, groupService} = useAppContext()
    const selectedStore = useSelectedStoreOptional()

    store = store || selectedStore

    const workingTimes = useMemo(() => (
        store?.working_days
            ? loadWorkingTimes(store?.working_days, lang)
            : null
    ), [store?.working_days, lang])

    const isClosed = useMemo(() => (
        workingTimes?.currentWorkingDay
        ? isShopClosed(workingTimes?.currentWorkingDay, null, groupService.group?.timezone)
        : false
    ), [workingTimes?.currentWorkingDay])

    return useMemo(() => ({
        workingTimes,
        isShopClosed: !!isClosed,
    }), [isClosed, workingTimes])
}

import Friends from "../../friends/Friends";
import {ProfileTab} from "../types";
import ProfileLayout from "../ProfileLayout";
import {Container} from "@mui/material";

export default function ProfileFriends(){
    const tab = ProfileTab.friends

    return (
        <ProfileLayout tab={tab}>
            <Container maxWidth={'md'}>
                <Friends mode={'profile'}/>
            </Container>
        </ProfileLayout>
    )
}

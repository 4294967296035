import {ChangeEvent, ChangeEventHandler, useEffect, useState} from "react";
import {IAddressOptionItem} from "../types";
import {
    IAddressComponent,
    IGeocodingGeometry,
    IGeocodingParams,
    IPlaceDetailsParams,
    IPlaceGeometry
} from "../../../api/map/types";
import api from "../../../api";
import useToken from "../../useToken";
import useAppContext from "../../../useAppContext";
import {IAddressValid} from "../AddressInput";
import { IStorageAddressObject } from "../../../api/shop/basic/types";

export default function useAddressValue(
    id: string | undefined,
    isGoogle: boolean, addressValue: string | null | undefined,
    setShowMap: (value: boolean) => void,
    businessLanguage: string | null | undefined,
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    setInternalError: (value: string | null) => void,
    setAddressValid?: (value: IAddressValid | null) => void,
    selectCallback?: (value: IAddressOptionItem | null) => void,
    setCoords?: (value: [number, number] | null) => void,
    enabledAnyAddressFromMap?: boolean,
): IUseAddressValueType {
    const {token, generate} = useToken()
    const {localisation: {maps}} = useAppContext()

    const [internalAddress, setInternalAddress] =
        useState<string | null | undefined>(addressValue)
    const [selectedValue, setSelectedValue] =
        useState<IAddressOptionItem | null | undefined>(null)
    const [lastAutoCompleteGeometry, setLastAutoCompleteGeometry] =
        useState<IPlaceGeometry | IGeocodingGeometry | null>(null)
    const handleAddressChanged = (e: ChangeEvent<HTMLInputElement>) => setInternalAddress(e.target.value)

    const processByPlacesDetails = async(placeId: string) => {
        let formattedAddress: string | null = null

        const args: IPlaceDetailsParams = {
            place_id: placeId,
            sessiontoken: token || "",
        }
        if(businessLanguage) args.language = businessLanguage
        let response
        try {
            response = await api.mapGoogle.details(args)
            if(response?.data?.result?.formatted_address){
                formattedAddress = response.data.result.formatted_address
            }
            const valid = await validateAndSave(
                response.data.result.address_components || [], 
                formattedAddress || "", 
                [response.data.result.geometry.location.lng, response.data.result.geometry.location.lat],
                placeId, true, false, response.data.result.geometry || null,
            )
            if(response?.data.result.geometry.location && valid){
                setCoords && setCoords([
                    response.data.result.geometry.location.lng,
                    response.data.result.geometry.location.lat
                ])
                generate(true)
            }
        }
        catch(ex: any){
            setInternalError(ex?.response?.data?.detail ||
                maps.mapsError.replace("{status}", "unknown"))
            setCoords && setCoords(null)
        }

        return formattedAddress
    }

    const processByGeocoding = async(address: string) => {
        let formattedAddress: string | null = null
        const args: IGeocodingParams = {
            address: address || "",
        }
        if(businessLanguage) args.language = businessLanguage

        let response;
        try {
            response = await api.mapGoogle.geocoding(args)
            if(response?.data.results[0].formatted_address){
                formattedAddress = response.data.results[0].formatted_address
            }
            const valid = await validateAndSave(
                response.data.results[0].address_components || [],
                formattedAddress || "",
                [response.data.results[0].geometry.location.lng,
                    response.data.results[0].geometry.location.lat],
                response.data.results[0].place_id, true, false,
                response.data.results[0].geometry || null,
            )
            if(response?.data.results[0]?.geometry?.location && valid){
                setCoords && setCoords([
                    response.data.results[0].geometry.location.lng,
                    response.data.results[0].geometry.location.lat
                ])
            }
        }
        catch(ex: any){
            setInternalError(ex?.response?.data?.detail
                || maps.mapsError.replace("{status}", "unknown"))
            setCoords && setCoords(null)
        }

        return formattedAddress
    }

    const handleValueChanged = async(
        _: object, value: IAddressOptionItem | null,
        forceCurrentInput: boolean = false
    ) => {
        setInternalError(null)
        if(value?.id === "map") return setShowMap(true)
        if(forceCurrentInput && internalAddress){
            value = {title: internalAddress, fullTitle: internalAddress, custom: true, id: "custom"}
        }

        setSelectedValue(value)

        let formattedAddress: string | null = null
        if(setCoords && !forceCurrentInput){
            if(value && ((value.lat && value.lng) || !isGoogle)){
                let coords: [number, number] | null = null
                if(value.lat && value.lng){
                    coords = [value.lng, value.lat]
                }
                await validateAndSave([], value?.fullTitle || "", coords)
                setCoords(coords)
            }
            else {
                if(value && value.place_id && token){
                    formattedAddress = await processByPlacesDetails(value.place_id)
                }
                else{
                    if(value?.fullTitle && addressValue !== value.fullTitle) {
                        formattedAddress = await processByGeocoding(value.fullTitle)
                    }
                    else{
                        setCoords(null)
                    }
                }
            }
        }

        onChange({
            target: {
                name: id ? id : 'address_input',
                value: formattedAddress || value?.fullTitle || ""
            }
        } as ChangeEvent<HTMLInputElement>)
        handleAddressChanged({
            target: {
                name: id ? id : 'address_input',
                value: formattedAddress || value?.fullTitle || ""
            }
        } as ChangeEvent<HTMLInputElement>)

        selectCallback && selectCallback(value)
    }

    const validateAndSave = async(
        addrComponents: IAddressComponent[],
        address: string,
        coords: [number, number] | null = null,
        placeId: string | null = null,
        isGoogle: boolean = false,
        fromMap: boolean = false,
        geometry?: IPlaceGeometry | IGeocodingGeometry| null,
    ) => {
        let canSave: boolean = false
        if(isGoogle){
            if(enabledAnyAddressFromMap && fromMap){
                canSave = true
            }
            else {
                setLastAutoCompleteGeometry(geometry || null)
                const validateResponse = await api.mapGoogle
                .validateAddress(addrComponents)
                if(validateResponse.data){
                    if(validateResponse.data.valid) canSave = true
                    setAddressValid && setAddressValid(validateResponse.data)
                }
            }
        }
        else {
            canSave = true
        }

        if(canSave){
            const lastAddressObject: IStorageAddressObject = {
                type: isGoogle ? 'google' : 'default',
                addressValue: address,
                addressCoords: coords,
                googleAddressComponents: addrComponents,
                placeId: placeId,
                selectedFromMap: fromMap,
            }
            api.shop.basic.saveLastAddressObject(JSON.stringify(lastAddressObject))
        }

        return canSave
    }

    useEffect(() => {
        if(isGoogle && !token) generate()
    }, [generate, isGoogle, token])

    return {
        token, selectedValue, setSelectedValue, internalAddress, handleValueChanged,
        handleAddressChanged, validateAndSave, lastAutoCompleteGeometry,
    }
}

interface IUseAddressValueType {
    token: string | null | undefined
    selectedValue: IAddressOptionItem | null | undefined
    setSelectedValue: (value: IAddressOptionItem | null | undefined) => void
    internalAddress: string | null | undefined
    handleValueChanged:
        (event: object, value: IAddressOptionItem | null, forceCurrentInput?: boolean) => void
    handleAddressChanged: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    validateAndSave: (
        addrComponents: IAddressComponent[],
        address: string,
        coords: [number, number],
        placeId: string | null,
        isGoogle: boolean,
    ) => Promise<boolean>,
    lastAutoCompleteGeometry: IPlaceGeometry | IGeocodingGeometry | null
}

import {MDBAlert, MDBSpinner} from "mdb-react-ui-kit"
import useAppContext from "../useAppContext";

export default function BotWaitAlert({botWaitInfo, setBotWaitInfo}) {
    const {
        localisation
    } = useAppContext()

    const onErrorAlertClose = () => {
        setBotWaitInfo({active: false, isError: false})
    }


    if (botWaitInfo.isError) {
        return (
            <MDBAlert
                className={'w-100'}
                color='danger'
                position='bottom-center'
                delay={3000}
                autohide
                onClosed={onErrorAlertClose}
                show
            >
                {localisation.global.botWaitAlertErrorText}
            </MDBAlert>
        )
    }

    if (botWaitInfo.active) {
        return (
            <>
                <div className='mask'
                     style={{backgroundColor: 'rgba(0, 0, 0, 0.8)', position: 'fixed', zIndex: "999999"}}></div>
                <MDBAlert
                    style={{zIndex: "9999991"}}
                    className={'w-100'}
                    color='primary'
                    position='bottom-center'
                    show
                    delay={-1}
                >
                    <div className="text-center">
                        <MDBSpinner role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                    </div>
                    <div>
                        {localisation.global.botWaitAlertWaitText}
                    </div>
                </MDBAlert>
            </>
        )
    }

    return null
}

import { useCallback, useEffect, useMemo, useState } from "react";

export default function useLoadingManager(defaultInit?: LoadingProcesses): LoadingManager {
	const [loadingProcesses, setLoadingProcesses] = useState<LoadingProcesses>(defaultInit || {});

	const getIsLoading = useCallback(
		(processName?: string) => {
			if (processName) {
				return loadingProcesses[processName] || false;
			} else {
				return Object.values(loadingProcesses).some(x => x);
			}
		},
		[loadingProcesses]
	);
	const setIsLoading = useCallback((processName: string, isLoading: boolean) => {
		setLoadingProcesses(prevState => {
			// Optimisation. Make loadingProcesses not redefine if specified the same state for a loading process
			if ((processName in prevState ? prevState[processName] : false) === isLoading) {
				return prevState;
			}

			return {
				...prevState,
				[processName]: isLoading,
			};
		});
	}, []);

	useEffect(() => {
		console.log("loadingProcesses", loadingProcesses);
	}, [loadingProcesses]);

	return useMemo(
		() => ({
			isSomethingLoading: getIsLoading(),
			getIsLoading,
			setIsLoading,
		}),
		[getIsLoading, setIsLoading]
	);
}

export type LoadingProcesses = {
	[key: string]: boolean | undefined;
};

export type LoadingManager = {
	isSomethingLoading: boolean;
	getIsLoading: (processName?: string) => boolean;
	setIsLoading: (processName: string, isLoading: boolean) => void;
};

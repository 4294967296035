import {INeedRegistrationBaseProps} from "../types";
import {TgLink} from "../../../helpers/ThemeComponents";
import useAppContext from "../../../useAppContext";
import Interweave from "../../../features/Interweave";

export default function NeedRegistrationBase(props: INeedRegistrationBaseProps) {
    const {
        localisation,
        authService: {setShowAuthorisationModal}
    } = useAppContext()

    const handleLoginClick = () => {
        if(props.setShow) props.setShow(false)
        setShowAuthorisationModal(true)
    }

    return (
        <div className={'text-start px-1'}>
            <Interweave
                content={props.messageText}
            />

            <div className={'mt-2'}>
                <TgLink
                    className={'cursor-pointer'}
                    onClick={() => handleLoginClick()}
                >
                    {localisation.auth.loginRegisterButton}
                </TgLink>
            </div>

        </div>
    )
}

import {Alert, Button, CircularProgress} from "@mui/material";
import Interweave from "../../../../features/Interweave";

import useAppContext from "../../../../useAppContext";

interface IFormFooterProps {
    isLoading?: boolean
    apiError?: string | null
}

export default function FormFooter(props: IFormFooterProps) {
    const {localisation: {global}} = useAppContext()

    return (
        <>
            {!!props.apiError && (
                <Alert
                    severity={'error'}
                    variant={'outlined'}
                    className={'small p-3 w-100 mt-2'}
                >
                    <Interweave content={props.apiError} />
                </Alert>
            )}

            <Button
                variant={'contained'}
                sx={{width: '100%', mt: 2}}
                size={'large'}
                type={'submit'}
                disabled={props.isLoading}
            >
                {props.isLoading && (
                    <CircularProgress className={'me-2'} style={{width: '18px', height: '18px'}} />
                )}
                {global.confirmButton}
            </Button>
        </>
    )
}

import useMergeState from "../../../helpers/useMergedState";
import {Filters} from "../../../api/shop/basic/types";
import {useEffect, useMemo, useState} from "react";
import api from "../../../api";
import {FiltersServiceType} from "./types";


export default function useFiltersService(): FiltersServiceType {
    const [selectedFilters, setSelectedFilters] = useMergeState<Filters>({})
    const [minPrice, setMinPrice] = useState<number | null>(null)
    const [maxPrice, setMaxPrice] = useState<number | null>(null)
    const [filtersSetId, setFiltersSetId] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!Object.values(selectedFilters).some(x => !!x) && !minPrice && !maxPrice) {
            setFiltersSetId(null)
        } else {
            setIsLoading(true)
            api.shop.basic.createFilters({
                filters: selectedFilters,
                min_price: minPrice,
                max_price: maxPrice,
            }).then(response => {
                setFiltersSetId(response.data.filters_set_id)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [minPrice, maxPrice, selectedFilters])

    const computedFiltersCount = useMemo(() => {
        let count = 0;
        for (const key in selectedFilters) {
            if (selectedFilters[key] !== null) {
                count++
            }
        }

        return count
    }, [selectedFilters])

    return useMemo(() => ({
        selectedFilters,
        setSelectedFilters,
        filtersSetId,
        isLoading,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        computedFiltersCount,
    }), [
        selectedFilters,
        setSelectedFilters,
        filtersSetId,
        isLoading,
        minPrice,
        maxPrice,
        setMaxPrice,
        computedFiltersCount,
    ])
}

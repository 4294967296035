import { AxiosInstance } from "axios"

import {
    AvailableLanguage,
    AvailableLanguagesArgs, GetLocalisationDataSetArgs,
    GetLocalisationListArgs, LocalisationDataSetDataType,
} from "./types";

export default function LocalisationModule(instance: AxiosInstance) {
    const getPath = (path: string) => `/localisation/${path}`

    return {
        getLocalisationList<T extends {} = { [key: string]: string }>(
            data: GetLocalisationListArgs<T>
        ) {
            return instance.post<T>(getPath('list'), data)
        },

        getLocalisationDataSet<T extends LocalisationDataSetDataType = LocalisationDataSetDataType>(
            data: GetLocalisationDataSetArgs<T>
        ) {
            return instance.post<T>(getPath('dataSet'), data)
        },

        getAvailableLanguages(args: AvailableLanguagesArgs) {
            return instance.get<AvailableLanguage[]>(getPath('available_languages'), {params: args})
        },

        saveLanguageToLocalStorage(lang: string){
            localStorage.setItem('lang', lang)
        },

        getLanguageFromLocalStorage(): string | null{
            const value = localStorage.getItem('lang')
            if(!value) return null
            return value
        }
    }
}

import React, { useEffect, useState } from "react";

import { GetOrdersArguments, Order } from "../../../api/shop/order/types";
import api from "../../../api";
import useMergeState from "../../../helpers/useMergedState";
import OrderCard from "./OrderCard/OrderCard";
import OrdersFilter from "../OrdersFilter";
import { useSelectedStore } from "../../SelectedStore/context";
import useAppContext from "../../../useAppContext";
import OrderModal from "./OrderModal";
import { Grid } from "@mui/material";
import useNewPaymentsService from "../../payments/hooks/useNewPaymentsService";
import { PaymentsModal } from "../../payments/Payment/Payments";

export default function OrdersList() {
	const {
		loadingManager,
		localisation,
		showError,
		authService: { user },
	} = useAppContext();

	const initialFilters: GetOrdersArguments = {
		order_status: "all",
		date_end: null,
		date_start: null,
		store_id: 0,
	};
	const [statuses, setStatuses] = useState<any>(null);

	const [orders, setOrders] = useState<Order[]>([]);
	const [filters, setFilters] = useMergeState<GetOrdersArguments>(initialFilters);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showOrderModal, setShowOrderModal] = useState<boolean>(false);
	const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
	const [showPayments, setShowPayments] = useState<boolean>(false);

	const selectedStore = useSelectedStore();

	const paymentService = useNewPaymentsService(selectedStore.id, null, selectedStore.currency);

	const updateOrdersList = async () => {
		if (isLoading || filters.store_id === 0) return;

		setIsLoading(true);
		loadingManager.setIsLoading("profile", true);
		try {
			const response = await api.shop.order.getOrders({ ...filters });
			setOrders(response.data);
		} catch (err) {
			console.log(err);
			showError(err);
		} finally {
			loadingManager.setIsLoading("profile", false);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		updateOrdersList().then();
	}, [JSON.stringify(filters)]);

	useEffect(() => {
		if (selectedStore) {
			setFilters({ store_id: selectedStore.id });
		}
	}, [selectedStore, setFilters]);

	useEffect(() => {
		if (localisation) {
			setStatuses([
				{ name: localisation.orders.allFilter, value: "all" },
				{ name: localisation.orders.unconfirmedFilter, value: "open_unconfirmed" },
				{ name: localisation.orders.confirmedFilter, value: "open_confirmed" },
				{ name: localisation.orders.closedFilter, value: "closed" },
				{ name: localisation.orders.canceledFilter, value: "canceled" },
			]);
		}
	}, [localisation]);

	useEffect(() => {
		const startDate = new Date();
		startDate.setMonth(startDate.getMonth() - 1);

		setFilters({
			date_start: startDate.toISOString(),
			date_end: new Date().toISOString(),
		});
	}, [setFilters]);

	useEffect(() => {
		if (selectedOrder) setShowOrderModal(true);
	}, [selectedOrder]);

	return (
		<>
			<Grid container mt={2} spacing={2}>
				<Grid item xs={12}>
					{statuses && (
						<OrdersFilter
							filters={filters}
							setFilters={setFilters}
							statuses={statuses}
							isLoading={isLoading}
						/>
					)}
				</Grid>

				{(!orders || orders.length < 1) && !isLoading && (
					<Grid item xs={12}>
						<div className="text-center theme-text">
							<h3>{localisation.orders.notFound}</h3>
						</div>
					</Grid>
				)}

				{!isLoading &&
					orders?.map((order: Order) => (
						<Grid key={order.id} item xs={12} md={6} lg={4}>
							<OrderCard
								order={order}
								setOrders={setOrders}
								setSelectedOrder={setSelectedOrder}
								paymentsService={paymentService}
								setShowPayments={setShowPayments}
							/>
						</Grid>
					))}
			</Grid>

			{showPayments && (
				<PaymentsModal
					show={showPayments}
					setShow={setShowPayments}
					type={"order"}
					selectedStoreId={selectedStore.id}
					paymentService={paymentService}
					user={user}
				/>
			)}

			<OrderModal
				order={selectedOrder}
				show={showOrderModal}
				setShow={setShowOrderModal}
				setSelectedOrder={setSelectedOrder}
				paymentService={paymentService}
			/>
		</>
	);
}

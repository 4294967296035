import {Box, BoxProps, Chip, ChipProps} from "@mui/material";
import {useMemo} from "react";


interface CardSelectItemProps extends BoxProps {
    selected?: boolean
    color?: string
    chipProps?: ChipProps
    noSelectBorder?: boolean
    forceBackgroundVar?: string
    noShadow?: boolean
    disabledHover?: boolean
    noSelectedColor?: boolean
    chipParentBackground?: boolean
    selectedInsetShadowWidth?: number
    disabled?: boolean
}


export default function ChipButton(
    {
        children, onClick,
        selected, color = 'primary',
        noSelectBorder, forceBackgroundVar, noShadow, disabledHover, noSelectedColor, chipParentBackground,
        selectedInsetShadowWidth = 2, disabled,
        chipProps: {sx: chipSx, ...chipProps} = {},
        ...props
    }: CardSelectItemProps
) {
    const ItemActiveStyles = useMemo(() => {
        return {
            boxShadow: `inset var(--mui-palette-${color}-main) 0px 0px 0 ${selectedInsetShadowWidth}px`,
            transition: 'all 0.2s',
            backgroundColor: `${color}.main`,
            color: `${color}.contrastText`,
            '.MuiTypography-body2': {
                color: 'primary.contrastText',
            },
            '.MuiSvgIcon-root': {
                color: `${color}.contrastText`,
            },
        }
    }, [color, selectedInsetShadowWidth])

    const ItemHoverStyles = useMemo(() => {
        if(selected){
            return ItemActiveStyles
        }

        return {
            boxShadow: `inset var(--mui-palette-${color}-main) 0px 0px 0 1px`,
            transition: 'all 0.2s',
            color: `${color}.main`,
        }
    }, [selected, color, ItemActiveStyles])

    const computedSelectedStyle = useMemo(() => {
        const selectedColor = `${color}.main`

        let styleObj: {
            color: string, borderColor?: string, borderWidth?: number, backgroundColor?: string, boxShadow?: string
        } = {
            color: selected && !noSelectedColor ? selectedColor : 'text.secondary',
        }
        if(selected && !noSelectedColor){
            styleObj.boxShadow = `inset 0 0 0 ${selectedInsetShadowWidth}px rgba(var(--mui-palette-${color}-mainChannel))`
        }
        if(!!forceBackgroundVar){
            styleObj.backgroundColor = `rgba(var(${forceBackgroundVar}) / 0.05)`
        }
        if(noShadow){
            styleObj.boxShadow = `none`
        }
        if(noSelectBorder){
            if(!forceBackgroundVar){
                styleObj.backgroundColor = selected
                    ? `rgba(var(--mui-palette-${color}-mainChannel) / 0.1)`
                    : "transparent"
            }
        }
        return styleObj
    }, [
        color, forceBackgroundVar, noSelectBorder,
        noSelectedColor, noShadow, selected, selectedInsetShadowWidth,
    ])

    const computedDisabledStyle = useMemo(() => {
        if(disabled){
            return {
                color: 'text.secondary',
                backgroundColor: 'rgba(0, 0, 0, 0.025)',
                boxShadow: 'inset rgba(0, 0, 0, 0.05) 0 0 0 1px',
            }
        }
        return {}
    }, [disabled])

    return (
        <Box {...props}>
            <Box
                sx={{
                    cursor: disabled ? 'not-allowed' : 'pointer', height: '100%',
                    backgroundColor: chipParentBackground ? 'var(--mui-palette-background-default)' : 'transparent',
                    //@ts-ignore
                    borderRadius: chipSx?.borderRadius || "0.5rem",
                    backgroundImage: 'var(--mui-overlays-1)'
                }}
                onClick={onClick}
            >
                <Chip
                    variant="outlined"
                    label={children}
                    sx={{
                        border: 0,
                        width: '100%',
                        fontSize: '1.1rem',
                        py: 2,
                        height: '100%',
                        borderRadius: '0.5rem',
                        transition: 'all 0.2s',
                        boxShadow: selected ?
                            0 :
                            'var(--mui-shadows-1), inset rgba(var(--mui-palette-text-primaryChannel) / 0.05) 0px 0px 0 1px',
                        userSelect: 'none',
                        whiteSpace: 'pre-wrap',
                        ...computedSelectedStyle,
                        '&:hover': disabledHover || disabled ? {} : ItemHoverStyles,
                        '&:active&:not(:has(.MuiButtonBase-root:active))': disabled ? {} : ItemActiveStyles,
                        ...chipSx,
                        ...computedDisabledStyle,
                    }}
                    {...chipProps}
                />
            </Box>
        </Box>
    )
}

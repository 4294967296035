interface IPayButtonIconProps {
	path: string;
	alt: string;
}

export function PayButtonIcon(props: IPayButtonIconProps) {
	return (
		<img
			src={props.path}
			alt={props.alt}
			className={"ms-2 align-self-center"}
			width="24"
			style={{ verticalAlign: "sub" }}
		/>
	);
}

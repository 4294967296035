import { useEffect, useMemo, useState } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import dayjs, { Dayjs } from "dayjs";

import useLocalisation from "../../../hooks/localisation/useLocalisation";
import ProfileLayout from "../ProfileLayout";
import { ProfileTab } from "../types";
import { UpdateUserData } from "../../../api/auth/types";
import useMergeState from "../../../helpers/useMergedState";
import { Box, Button, CircularProgress } from "@mui/material";
import OnlyRegistered from "../../order/MakeOrder/OnlyRegistered";
import useAppContext from "../../../useAppContext";
import { ApiUrl } from "../../../config";
import useScreenService from "../../../services/useScreenService";
import { ThemeModal } from "../../../helpers/ThemeComponents";
import EditImage from "../../../features/EditImage";
import ProfileIdItem from "../ProfileIdItem";
import ChangeDataItems from "../changeData/changeDataItems";
import { dateToISOFormat } from "../../../helpers";
import { IProfileOverallItemPropsBase } from "./ProfileOverallItemPropsBase";
import { ProfileDateBirth } from "./ProfileDateBirth";

export default function ProfileOverall() {
	const { isMobile } = useScreenService();
	const { ...localisation } = useLocalisation();

	const tab = ProfileTab.overall;
	const {
		authService: { user, updateUser },
		toastService,
		showError,
	} = useAppContext();

	const [form, setForm] = useMergeState<UpdateUserData>({
		first_name: user?.first_name,
		last_name: user?.last_name,
		birth_date: user?.birth_date,
	});

	const [isLoading, setIsLoading] = useState(false);

	const handleUpdateClick = async () => {
		try {
			setIsLoading(true);

			const isValuesNotChanged =
				form.first_name === user?.first_name &&
				form.last_name === user?.last_name &&
				form.birth_date === user?.birth_date;

			if (isValuesNotChanged || !validateForm()) return;

			if (user?.chat_id && !user.email) {
				setForm({ first_name: user.first_name, last_name: user.last_name });
				if (form.birth_date === user.birth_date || !form.birth_date) {
					return toastService.showToast({
						severity: "warning",
						message: localisation.profile.unableSaveCauseTg,
					});
				}
			}

			await updateUser(form);
			toastService.showToast({
				severity: "success",
				message: localisation.global.dataSaved,
			});
		} catch (ex: any) {
			if (ex.response.data) return showError(ex.response.data.detail);
			return showError(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const validateForm = () => {
		return !!form.first_name || !!form.last_name || !!form.birth_date;
	};

	const computedNeedSaveButton = useMemo(() => {
		if (form.first_name && form.last_name) {
			let birthData = false;
			if (form.birth_date) {
				try {
					if (user?.birth_date && form.birth_date) {
						birthData =
							user?.birth_date.split("T")[0] !== form.birth_date.split("T")[0];
					} else birthData = true;
				} catch {}
			}
			return (
				form.first_name !== user?.first_name ||
				form.last_name !== user?.last_name ||
				birthData
			);
		}
		return false;
	}, [
		form.birth_date,
		form.first_name,
		form.last_name,
		user?.birth_date,
		user?.first_name,
		user?.last_name,
	]);

	useEffect(() => {
		if (user?.birth_date && !form.birth_date)
			setForm({ birth_date: dayjs(new Date(user.birth_date)).toISOString() });
		if (user?.first_name && !form.first_name) setForm({ first_name: user.first_name });
		if (user?.last_name && !form.last_name) setForm({ last_name: user.last_name });
	}, [
		form.birth_date,
		form.first_name,
		form.last_name,
		setForm,
		user?.birth_date,
		user?.first_name,
		user?.last_name,
	]);

	return (
		<ProfileLayout tab={tab}>
			{!user && (
				<OnlyRegistered
					title={localisation.profile.notAuthHeader}
					subTitle={localisation.profile.notAuthSubHeader}
				/>
			)}

			{!!user && (
				<>
					<MDBRow className={"justify-content-center"}>
						<MDBCol size={"12"} lg={"2"}>
							<div className={`h-100 ${isMobile ? "mb-3" : ""}`}>
								<ProfilePhoto />
							</div>
						</MDBCol>

						<MDBCol
							size={"12"}
							lg={"10"}
							className={`align-self-center ${isMobile ? "text-start mt-4" : ""}`}
						>
							<ProfileOverallItem
								header={localisation.forms.formName}
								value={user.first_name}
								isEditable={!user.chat_id}
								onChange={(value: string) => setForm({ first_name: value })}
							/>
							<ProfileOverallItem
								header={localisation.forms.formLastName}
								value={user.last_name}
								isEditable={!user.chat_id}
								onChange={(value: string) => setForm({ last_name: value })}
							/>
							<ProfileDateBirth
								header={localisation.auth.birthLabel}
								value={user.birth_date ? user.birth_date : null}
								formValue={form.birth_date}
								onChange={(value: string | Dayjs | null) => {
									if (!value || typeof value === "string")
										return setForm({ birth_date: null });
									setForm({ birth_date: dateToISOFormat(value?.toDate()) });
								}}
							/>
						</MDBCol>

						<Box sx={{ width: "100%", textAlign: "end" }}>
							<Button
								variant={"contained"}
								onClick={handleUpdateClick}
								disabled={!computedNeedSaveButton || isLoading}
							>
								{isLoading && (
									<CircularProgress
										className={"me-2"}
										style={{ width: "18px", height: "18px" }}
									/>
								)}
								{localisation.global.save}
							</Button>
						</Box>
					</MDBRow>

					<MDBRow className={"mt-3 justify-content-center"}>
						<MDBCol size={"12"}>
							<ChangeDataItems />
						</MDBCol>
					</MDBRow>

					<ProfileIdItem
						classes={"mt-1 mb-3 small text-center mt-5"}
						iconSize={14}
						id={user.id}
					/>
				</>
			)}
		</ProfileLayout>
	);
}

interface IProfileOverallItemProps extends IProfileOverallItemPropsBase {
	onChange?: (value: string) => void;
}

function ProfileOverallItem(props: IProfileOverallItemProps) {
	return (
		<div className={"mb-3"}>
			<div>
				<TextField
					size={"small"}
					id="filled-hidden-label-normal"
					defaultValue={props.value}
					variant="outlined"
					fullWidth
					label={props.header}
					onChange={e => props.onChange && props.onChange(e.target.value)}
					inputProps={{
						readOnly: !props.isEditable,
					}}
				/>
			</div>
		</div>
	);
}

function ProfilePhoto() {
	const { isMobile } = useScreenService();
	const {
		authService: { user },
	} = useAppContext();

	const [showEdit, setShowEdit] = useState<boolean>(false);

	return (
		<>
			<div className={"h-100"}>
				<div
					className={`w-100 d-flex h-100 ${isMobile ? "justify-content-center" : "justify-content-start"}`}
				>
					<Avatar
						alt={`${user?.first_name}`}
						src={user?.photo ? `${ApiUrl}/${user.photo}` : undefined}
						sx={{ width: 94, height: 94, alignSelf: "center" }}
					/>
				</div>
			</div>

			<ProfilePhotoEditModal show={showEdit} setShow={setShowEdit} />
		</>
	);
}

interface IProfilePhotoEditModalProps {
	show: boolean;
	setShow: (value: boolean) => void;
}

function ProfilePhotoEditModal(props: IProfilePhotoEditModalProps) {
	const localisation = useLocalisation();

	return (
		<ThemeModal
			open={props.show}
			setOpen={props.setShow}
			title={"edit photo"} //TODO: add localization
			maxWidth={"xs"}
			classes={{
				paper: "w-100",
			}}
			contentProps={{
				className: "text-center",
			}}
		>
			<EditImage
				uploadText={"Upload your photo"} //TODO: add localization
				uploadButtonText={"Upload"} //TODO: add localization
				saveButtonText={localisation.global.save}
			/>
		</ThemeModal>
	);
}

import {useEffect, useState} from "react";

import {IQrMenuReviewButtonProps} from "../types";
import QrMenuButtonWrapper from "./QrMenuButtonWrapper";
import {ReviewModal} from "../../review";
import useAppContext from "../../../useAppContext";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";

export default function ReviewButton(props: IQrMenuReviewButtonProps){
    const [searchParams, setSearchParams] = useSearchParamsFixed()

    const {
        authService: {user},
    } = useAppContext()

    const [show, setShow] = useState(false)

    const onClick = () => {
        if(!user){
            return props.setShowNeedRegistrationModal(true)
        }
        setShow(true)
    }

    const isActionReview = searchParams.get("action") === 'review'
    useEffect(() => {
        if (isActionReview) {
            setShow(true)
            setSearchParams(prev => {
                prev.delete("action")
                return prev
            })
        }
    }, [isActionReview, setSearchParams]);

    return (
        <>
            <QrMenuButtonWrapper
                onClick={onClick}
            >
                {props.buttonName}
            </QrMenuButtonWrapper>

            <ReviewModal
                show={show}
                setShow={setShow}
            />
        </>

    )
}

import { useEffect } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import useAppContext from "../useAppContext";
import { ApiUrl, SevenLocDomain } from "../config";
import f from "../helpers/formatText";

export default function StorePlaceholder() {
	const { loadingManager, localisation, setPageTitle } = useAppContext();

	useEffect(() => {
		const titleBase = localisation.global.notFoundBrandTitle;
		if (!!setPageTitle && !!titleBase) {
			setPageTitle(`${titleBase}`);
		}
	}, [localisation.global.notFoundBrandTitle, setPageTitle]);

	if (loadingManager.isSomethingLoading || !localisation.isLoaded) return null;

	return (
		<>
			<Container
				sx={{
					display: "flex",
					height: "100vh",
					justifyContent: "center",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<img
							src={`${ApiUrl}/static/images/default-404-image.png`}
							alt="404 Not Found"
							className={"img-fluid"}
							style={{ width: "20rem", borderRadius: "0.5rem" }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							my: 2,
							textAlign: { xs: "center", sm: "start" },
						}}
					>
						<Typography
							variant={"h6"}
							fontWeight={"bold"}
							whiteSpace={"pre-wrap"}
							sx={{ wordBreak: "break-all" }}
						>
							{f(localisation.global.notFoundBrandContent, {
								link: window.location.href,
							})}
						</Typography>

						<Typography gutterBottom>
							{localisation.global.notFoundBrandSubContent}
						</Typography>

						<Button
							sx={{ alignSelf: { sm: "flex-end" }, mt: 1 }}
							variant={"contained"}
							onClick={() => {
								window.location.href = SevenLocDomain;
							}}
						>
							{localisation.global.notFoundBrandLink}
						</Button>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

import {InviteInterfaceProps} from "./types";
import {Box, Link, Typography} from "@mui/material";
import f from "../../helpers/formatText";
import {useMessangerName} from "../../hooks/messanger";
import ShareQrCode from "../ShareQrCode";
import {ThemeModal} from "../../helpers/ThemeComponents";
import {useState} from "react";
import ShareTextInInput from "../../features/ShareTextInInput";
import useAppContext from "../../useAppContext";


export default function InviteInterface(props: InviteInterfaceProps) {
    const messangerName = useMessangerName(props.botType)
    const [showNoMessangerModal, setShowNoMessangerModal] = useState(false)
    const {localisation: {profile,},} = useAppContext()

    return (
        <Box>
            <Typography fontWeight={'bold'} pl={1}>
                {props.bodyText}
            </Typography>

            <Typography mt={2} variant={'body2'} pl={1}>
                {props.botUrl
                    ? f(props.linkDescBot, {messanger: messangerName})
                    : props.linkDescWeb
                }
            </Typography>

            {props.benefitsText && (
                <Typography mt={2} variant={'body2'} pl={1}>
                    {profile.shareCouponBenefits}<br/>{props.benefitsText}
                </Typography>
            )}

            <ShareTextInInput
                url={props.botUrl || props.webUrl}
                imgUrl={props.shareLinkImage}
            />

            {props.botUrl && (<>
                <Link
                    pl={1}
                    fontSize={'small'}
                    style={{wordBreak: 'break-word'}}
                    role={'button'}
                    onClick={() => setShowNoMessangerModal(true)}
                >
                    {f(props.noMessangerText, {
                        messanger_name: messangerName,
                    })}
                </Link>

                <ThemeModal
                    maxWidth={'xs'}
                    fullWidth
                    open={showNoMessangerModal}
                    setOpen={setShowNoMessangerModal}
                    title={
                        f(props.noMessangerModalTitle, {
                            messanger_name: messangerName,
                        })
                    }
                    contentProps={{
                        sx: {
                            pt: 2,
                        }
                    }}
                >
                    <Typography pl={1}>
                        {props.linkDescWeb}
                    </Typography>

                    {props.benefitsText && (
                        <Typography mt={2} variant={'body2'} pl={1}>
                            {profile.shareCouponBenefits}<br/>{props.benefitsText}
                        </Typography>
                    )}

                    <ShareTextInInput
                        url={props.webUrl}
                        imgUrl={props.shareLinkImage}
                    />

                    <ShareQrCode
                        url={props.webUrl}
                        boxProps={{
                            sx: {
                                mt: 3,
                            }
                        }}
                    />
                </ThemeModal>
            </>)}

            <ShareQrCode
                url={props.botUrl || props.webUrl}
                boxProps={{
                    sx: {
                        mt: 2,
                        opacity: showNoMessangerModal ? 0 : 1,
                        transition: 'opacity .25s'
                    }
                }}
            />
        </Box>
    )
}

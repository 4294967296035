import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Box, Typography } from "@mui/material";
import CardGiftcard from "@mui/icons-material/CardGiftcard";
import Wallet from "@mui/icons-material/Wallet";
import useAppContext from "../../../../../useAppContext";
import { ApplyCouponType, IIncustCoupon, IncustTransaction } from "../../../types";
import formatCurrency from "../../../../../helpers/formatCurrency";
import { Interweave } from "interweave";
import f from "../../../../../helpers/formatText";
import { CouponCodExternalPopup } from "./CouponCodExternalPopup";
import { AnimateIcon } from "./AnimateIcon";

interface ApplyCouponPopupProps {
	title?: string | null | undefined;
	type: ApplyCouponType | undefined;
	coupon: IIncustCoupon;
	showCoupon: boolean | undefined;
	redeemedCouponCheck: IncustTransaction | null;
	onClose: () => unknown;
}

export const ApplyCouponPopup = ({
	type,
	title,
	coupon,
	showCoupon,
	redeemedCouponCheck,
	onClose,
}: ApplyCouponPopupProps): React.JSX.Element => {
	const icon = type === ApplyCouponType.USED ? Wallet : CardGiftcard;
	const isExternalType = coupon.type === "external";

	const {
		localisation,
		authService: { user },
		brandInfo,
	} = useAppContext();

	React.useEffect(() => {
		if (!showCoupon) return;

		const timeoutId = setTimeout(() => {
			onClose();
		}, 5000);

		return () => {
			clearTimeout(timeoutId);
			onClose();
		};
	}, [onClose, showCoupon]);

	return (
		<>
			<Box
				position={"absolute"}
				top={"50%"}
				left={"50%"}
				sx={{ transform: "translate(-50%, -50%)" }}
				width={{ xs: "90%", sm: "70%" }}
				zIndex={"99"}
				borderRadius={"10px"}
			>
				<AnimatePresence mode="wait">
					{showCoupon && (
						<Box
							component={motion.div}
							display={"flex"}
							justifyContent={"center"}
							alignItems={"center"}
							gap={2}
							p={3}
							bgcolor={{ xs: "primary.main", sm: "background.paper" }}
							borderRadius={"10px"}
							initial={{
								x: "20%",
								y: "200%",
								clipPath: "inset(10% 50% 90% 50% round 10px)",
							}}
							animate={{
								x: 0,
								y: 0,
								clipPath: "inset(0% 0% 0% 0% round 10px)",
							}}
							exit={{
								x: "50%",
								y: isExternalType ? "0" : "30%",
								clipPath: "inset(10% 50% 90% 50% round 10px)",
								transition: { duration: 0.5 },
							}}
							transition={{
								type: "spring",
								damping: 18,
								stiffness: 300,
								bounce: 0.1,
								duration: 0.3,
								delayChildren: 0.4,
								staggerChildren: 0.05,
							}}
							sx={{ pointerEvents: "none" }}
							color={{ xs: "primary.contrastText", sm: "primary.main" }}
						>
							<Box
								component={motion.div}
								initial={{ opacity: 0, scale: 0 }}
								animate={{ opacity: 1, scale: 1 }}
								exit={{ opacity: 0, scale: 0 }}
								transition={{ delay: 0.4, duration: 0.3 }}
							>
								<AnimateIcon icon={icon} />
							</Box>

							<Box
								display={"flex"}
								alignItems={"center"}
								p={2}
								flexDirection={"column"}
								component={motion.div}
								initial={{ opacity: 0, scale: 0 }}
								animate={{ opacity: 1, scale: 1 }}
								exit={{ opacity: 0, scale: 0 }}
								transition={{ delay: 0.6, duration: 0.3 }}
							>
								<Typography
									sx={{
										color: "inherit",
									}}
									variant="h6"
									fontWeight={"bold"}
									mx={"auto"}
								>
									{title ?? localisation.global.usedVoucherNotify}
								</Typography>

								{coupon?.external_code && (
									<CouponCodExternalPopup
										codeForUseLabel={localisation.global.yourCodeForUse}
										copyCouponLabel={localisation.global.copyCouponCode}
										coupon={coupon}
									/>
								)}

								{redeemedCouponCheck && redeemedCouponCheck?.bonuses_added > 0 && (
									<Typography
										variant={"body1"}
										component={motion.p}
										initial={{ opacity: 0, scale: 0 }}
										animate={{ opacity: 1, scale: 1 }}
										exit={{ opacity: 0, scale: 0 }}
										transition={{ delay: 0.8, duration: 0.3 }}
									>
										{localisation.profile.loyaltyBonusesAwardedAfter}:{" "}
										<Box component={"span"} fontWeight={"bold"}>
											{redeemedCouponCheck &&
												redeemedCouponCheck.bonuses_added}
										</Box>
									</Typography>
								)}

								{user &&
									redeemedCouponCheck &&
									redeemedCouponCheck.check?.special_account &&
									redeemedCouponCheck.summary_amount && (
										<Interweave
											component={motion.div}
											initial={{ opacity: 0, scale: 0 }}
											animate={{ opacity: 1, scale: 1 }}
											exit={{ opacity: 0, scale: 0 }}
											transition={{ delay: 0.8, duration: 0.3 }}
											content={f(
												localisation.profile.loyaltySpAccountTopped,
												{
													sp_account_name:
														redeemedCouponCheck.check.special_account
															.title,
													amount: redeemedCouponCheck.check
														.special_account.currency
														? formatCurrency(
																redeemedCouponCheck.summary_amount,
																brandInfo?.default_lang ||
																	user?.lang ||
																	"en",
																redeemedCouponCheck.check
																	.special_account.currency ??
																	"UAH"
															)
														: redeemedCouponCheck.summary_amount,
												}
											)}
										/>
									)}
							</Box>
						</Box>
					)}
				</AnimatePresence>
			</Box>
		</>
	);
};

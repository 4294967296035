import DetailedProduct from "./DetailedProduct";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";
import useScreenService from "../../../services/useScreenService";
import { ReactNode } from "react";
import SwipeableDrawerWrapper from "../../../features/SwipeableDrawerWrapper";

export default function DetailedProductModal() {
	const { productsService, cartService } = useSelectedStoreContext();

	const closeModal = () => {
		cartService.clearCartProductLocalData();
		productsService.selectProduct(null);
	};

	return (
		<DetailedProductWrapper closeModal={closeModal}>
			<Box>
				<DetailedProduct isModal={true} closeModal={closeModal} />
			</Box>
		</DetailedProductWrapper>
	);
}

interface IDetailedProductWrapperProps {
	children: ReactNode;
	closeModal: () => void;
}

function DetailedProductWrapper(props: IDetailedProductWrapperProps) {
	const { isMobile } = useScreenService();
	const { productsService } = useSelectedStoreContext();

	const selectedProduct = productsService.selectedProduct;

	return (
		<>
			{isMobile ? (
				<SwipeableDrawerWrapper
					open={!!selectedProduct}
					onClose={props.closeModal}
					setOpen={(_: boolean) => {}}
					displayNone={productsService.hideDetailedProductModal}
				>
					{props.children}
				</SwipeableDrawerWrapper>
			) : (
				<Dialog
					sx={productsService.hideDetailedProductModal ? { display: "none" } : {}}
					open={!!selectedProduct}
					onClose={props.closeModal}
					maxWidth={"sm"}
					fullWidth
					PaperProps={{
						sx: {
							borderRadius: "1rem",
							margin: "8px",
							width: "calc(100% - 16px)",
							maxHeight: "calc(100% - 16px)",
						},
					}}
					slotProps={{
						backdrop: {
							sx: {
								backdropFilter: selectedProduct ? "blur(50px)" : "blur(0px)",
								transition: "backdropFilter .5s",
							},
						},
					}}
					transitionDuration={{
						enter: 500,
						exit: 100,
					}}
				>
					<DialogContent
						id={"modal-or-swipeable-overflow-wrapper"}
						sx={{
							position: "relative",
							p: 0,
						}}
					>
						{props.children}
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}

import { useEffect, useMemo, useRef, useState } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import classnames from "classnames";
import { Box, CircularProgress, Skeleton, Stack, Typography } from "@mui/material";

import {
	CustomShipment,
	CustomShipmentGroup,
	Shipment,
	ShipmentPrice,
} from "../../../../../api/shop/basic/types";
import BaseShippingIcons from "../../../../shipping";
import { SelectedShipmentServiceType } from "../../useSelectedShipmentService";
import formatCurrency from "../../../../../helpers/formatCurrency";
import useCurrency from "../../../../../services/useCurrencyService";
import useAppContext from "../../../../../useAppContext";
import ChipButton from "../../../../../features/ChipButton";
import Interweave from "../../../../../features/Interweave";

interface ShippingCardProps {
	shipment: Shipment | CustomShipment | CustomShipmentGroup;
	isError?: boolean;
	selectedShipmentService: SelectedShipmentServiceType;

	getShipmentPricesById: (shipmentId: number) => ShipmentPrice[];
	selectedShipmentPrice: ShipmentPrice | null;
	isLoading: boolean;
	isGroup?: boolean;
	getPricesString: (prices: ShipmentPrice[], currency?: string) => string;
	allLoading: boolean;
}

export default function ShippingCard({
	shipment,
	isError = false,
	selectedShipmentService: {
		selectedShipment,
		setSelectedShipment,
		selectedCustomShipmentGroup,
		setSelectedCustomShipmentGroup,
	},
	...props
}: ShippingCardProps) {
	const currency = useCurrency();
	const {
		lang,
		localisation: { orders },
		brandInfo,
	} = useAppContext();
	const cardRef = useRef<HTMLDivElement>(null);
	const [lastCardHeight, setLastCardHeight] = useState<number | null>(null);

	const handleDeliveryTypeClick = () => {
		switch (shipment.custom_type) {
			case "custom_shipment_group":
				if (selectedCustomShipmentGroup?.id === shipment.id) {
					setSelectedCustomShipmentGroup(null);
					setSelectedShipment(null);
				} else {
					setSelectedCustomShipmentGroup(shipment);
					setSelectedShipment(shipment.shipments[0]);
				}
				break;
			case "custom_shipment":
			case "shipment":
				setSelectedCustomShipmentGroup(null);
				if (selectedShipment?.id === shipment.id) {
					setSelectedShipment(null);
				} else {
					setSelectedShipment(shipment);
				}
				break;
		}
	};

	const computedIsActiveState = useMemo(() => {
		return (
			(shipment.custom_type === "custom_shipment_group" &&
				shipment.id === selectedCustomShipmentGroup?.id) ||
			shipment.id === selectedShipment?.id
		);
	}, [shipment.id, shipment.custom_type, selectedShipment?.id, selectedCustomShipmentGroup?.id]);

	const getShipmentPricesById = props.getShipmentPricesById;
	const getPricesString = props.getPricesString;
	const computedPrices = useMemo(() => {
		let prices = getShipmentPricesById(shipment.id);
		if (computedIsActiveState) {
			if (
				(!props.selectedShipmentPrice ||
					props.selectedShipmentPrice?.cost_delivery === 0) &&
				prices.length === 0 &&
				shipment.custom_type !== "custom_shipment_group"
			) {
				if (selectedShipment?.is_paid_separately) return "";
				return orders.free;
			}
			if (props.selectedShipmentPrice?.cost_delivery) {
				return formatCurrency(
					props.selectedShipmentPrice?.cost_delivery.toFixed(2),
					brandInfo?.default_lang || lang,
					currency || ""
				);
			} else {
				if (prices.length) {
					const pricesStr = getPricesString(prices, currency || "");
					if (
						pricesStr === orders.free &&
						shipment?.custom_type !== "custom_shipment_group" &&
						shipment.is_paid_separately
					)
						return "";
					return pricesStr || orders.free;
				}
			}
		}
		const freePriceTemplate: ShipmentPrice = {
			cost_delivery: 0,
			id: 0,
			minimum_order_amount: 0,
			maximum_order_amount: 0,
		};
		if (shipment.custom_type === "custom_shipment_group") {
			shipment.shipments.forEach(ship => {
				let groupPrices = getShipmentPricesById(ship.id);
				if (groupPrices.length === 0) {
					groupPrices.push(freePriceTemplate);
				}
				prices = prices.concat(groupPrices);
			});
		} else {
			if (prices.length === 0) {
				prices.push(freePriceTemplate);
			}
		}

		const res = getPricesString(prices, currency || "");
		if (
			res === orders.free &&
			shipment.custom_type !== "custom_shipment_group" &&
			shipment.is_paid_separately
		) {
			return "";
		}
		return res;
	}, [
		getShipmentPricesById,
		shipment,
		computedIsActiveState,
		getPricesString,
		currency,
		orders.free,
		props.selectedShipmentPrice,
		selectedShipment?.is_paid_separately,
		brandInfo?.default_lang,
		lang,
	]);

	const computedDescription = useMemo(() => {
		if (
			shipment.custom_type === "custom_shipment_group" ||
			shipment.custom_type === "custom_shipment"
		) {
			return shipment.description;
		}
		return null;
	}, [shipment]);

	useEffect(() => {
		if (
			cardRef &&
			cardRef.current &&
			cardRef.current.offsetHeight &&
			!props.isLoading &&
			!props.allLoading
		) {
			setLastCardHeight(cardRef.current.offsetHeight);
		}
	}, [cardRef, props.allLoading, props.isLoading]);

	return (
		<ChipButton
			selected={computedIsActiveState}
			onClick={handleDeliveryTypeClick}
			className={classnames("cursor-pointer h-100", {
				"border-error": isError,
			})}
			chipProps={{
				sx: {
					minHeight: "65px",
					paddingX: "4px",
					paddingY: 0,
					display: "block",
					".MuiChip-label": {
						paddingLeft: 0,
						paddingRight: 0,
						whiteSpace: "wrap",
					},
					":hover": {
						".MuiCircularProgress-root": {
							color: computedIsActiveState ? "primary.contrastText" : "primary.main",
						},
					},
				},
			}}
		>
			<Box
				className={"p-2"}
				ref={cardRef}
				sx={
					(props.isLoading || props.allLoading) && lastCardHeight
						? { height: lastCardHeight }
						: {}
				}
			>
				{props.allLoading || !shipment ? (
					<Stack spacing={1}>
						<Box display={"flex"}>
							<Skeleton variant="circular" width={22} height={22} sx={{ mr: 2 }} />
							<Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
						</Box>
						<Skeleton variant="text" sx={{ fontSize: "0.875rem" }} />
					</Stack>
				) : (
					<Box
						display={"flex"}
						sx={props.isLoading ? { opacity: "0.5", transition: "0.5s" } : {}}
					>
						<Box>
							<Box textAlign={"start"} display={"flex"}>
								<Box alignSelf={"center"}>
									{shipment.custom_type === "shipment" || !shipment.icon_url ? (
										shipment.custom_type === "custom_shipment_group" ? (
											<MDBIcon fas icon="globe" />
										) : (
											BaseShippingIcons[shipment.base_type]
										)
									) : (
										<img
											src={shipment.icon_url}
											alt={shipment.name}
											className={"img-fluid"}
											style={{ height: "2rem" }}
										/>
									)}
								</Box>
								<Box alignSelf={"center"} sx={{ ml: 2 }}>
									{shipment.name}
								</Box>
							</Box>

							{!!computedDescription && (
								<Typography
									variant={"body2"}
									className={"one-line-w"}
									sx={{
										maxHeight: "1.7em",
										whiteSpace: "break-spaces",
									}}
								>
									<Interweave content={computedDescription} />
								</Typography>
							)}

							<Box fontSize={"0.875rem"} fontWeight={"bold"}>
								{computedPrices}
								{!!(
									(shipment.custom_type !== "custom_shipment_group" &&
										shipment.is_paid_separately) ||
									(computedIsActiveState && selectedShipment?.is_paid_separately)
								) && (
									<>
										{!!computedPrices && " | "}
										{orders.paidSeparately}
									</>
								)}
							</Box>
						</Box>
						{props.isLoading && (
							<Box alignSelf={"center"} ml={"auto"}>
								<CircularProgress size={"1.5rem"} />
							</Box>
						)}
					</Box>
				)}
			</Box>
		</ChipButton>
	);
}

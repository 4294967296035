import React from "react";
import { Box } from "@mui/material";

interface Props {
	label: string;
}

export const UsedBadge = ({ label }: Props): React.JSX.Element => {
	return (
		<Box
			position={"absolute"}
			sx={{
				rotate: "-45deg",
				top: { xs: "25%", sm: "40%" },
				left: { xs: "20px", sm: "-10px" },
				transform: { xs: "translateY(-25%)", sm: "translateY(-40%)" },
				px: 2,
				filter: "blur(0.3px)",
			}}
			zIndex={"99"}
			textTransform={"uppercase"}
			border={2}
			borderColor={"primary.error"}
			borderRadius={"5px"}
			color={"error.main"}
			fontWeight={"500"}
			fontSize={{
				xs: "20px",
				md: "22px",
			}}
		>
			{label}
		</Box>
	);
};

import {AspectRatioType} from "../types";
import {useEffect, useMemo, useRef, useState} from "react";
import {Box, BoxProps} from "@mui/material";


export interface RatioBoxProps {
    ratio: AspectRatioType
    width?: string
    boxProps?: Omit<BoxProps, 'ref' | 'children'>
    children?: any
}


export default function RatioBox({ratio, width = '100%', boxProps, children}: RatioBoxProps) {
    const ref = useRef<HTMLDivElement | null>(null)
    const [height, setHeight] = useState('')

    useEffect(() => {
        const calculateHeight = () => {
            const el = ref.current
            if (el) {
                const aspectRatio = ratio[0] / ratio[1]
                const height = el.getBoundingClientRect().width / aspectRatio + 'px'
                el.style.height = height
                setHeight(height)
            }
        }

        if (ref.current) {
            calculateHeight()
            const resizeObserver = new ResizeObserver(() => {
                calculateHeight()
            })
            resizeObserver.observe(ref.current)

            return () => {
                resizeObserver.disconnect()
            }
        }
    }, [ratio]);

    const calculatedBoxProps = useMemo(() => {
        const internalSx = {
            width: width,
            height: height + '!important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }

        if (!boxProps) {
            return {sx: internalSx, ref: ref}
        } else {
            const {sx, ...rest} = boxProps
            return {
                ...rest,
                ref: ref,
                sx: sx ? {...sx, ...internalSx} : internalSx
            }
        }
    }, [boxProps, width, height])

    return (
        <Box {...calculatedBoxProps}>
            {children}
        </Box>
    )
}

import {useShopContext} from "../../../shop/context";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {Fab} from "@mui/material";

type OpenAIChatButtonProps = {
    noFixed?: boolean
}

export default function OpenAIChatButton({noFixed}: OpenAIChatButtonProps){
    const {showAIChat, setShowAIChat} = useShopContext()

    if (showAIChat) return null

    return (
        <Fab
            size="medium"
            color={'primary'}
            onClick={(e) => {
                e.stopPropagation()
                setShowAIChat(prevState => !prevState)
            }}
        >
            <SmartToyIcon />
        </Fab>
    )
}

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { GetOrdersArguments } from "../../api/shop/order/types";
import { SetMergeState } from "../../helpers/useMergedState";
import useAppContext from "../../useAppContext";
import useDatetimeService from "../../services/useDatetimeService";
import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface OrdersFilterProps {
	filters: GetOrdersArguments;
	setFilters: SetMergeState<GetOrdersArguments>;
	statuses: { name: string; value: string }[];
	isLoading: boolean;
}

export default function OrdersFilter(props: OrdersFilterProps) {
	const { lang, localisation } = useAppContext();
	const { computedLocaleText } = useDatetimeService();

	const handleStatusChanged = (e: SelectChangeEvent) =>
		props.setFilters({ order_status: e.target.value });
	const handleDateStartChanged = (value: Dayjs | null) => {
		if (value) {
			props.setFilters({ date_start: value.toISOString() });
		}
	};
	const handleDateEndChanged = (value: Dayjs | null) => {
		if (value) {
			props.setFilters({ date_end: value.toISOString() });
		}
	};

	const computedDefaultStartDate = () => {
		const currentDate = new Date();
		currentDate.setMonth(currentDate.getMonth() - 1);

		const day = currentDate.getDate();
		const month = currentDate.getMonth() + 1;
		const year = currentDate.getFullYear();

		return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
	};

	const computedDefaultEndDate = () => {
		const currentDate = new Date();

		const day = currentDate.getDate();
		const month = currentDate.getMonth() + 1;
		const year = currentDate.getFullYear();

		return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
	};

	const datePickerValueStart = props.filters.date_start
		? dayjs(new Date(props.filters.date_start))
		: dayjs(new Date(computedDefaultStartDate()));

	const datePickerValueEnd = props.filters.date_end
		? dayjs(new Date(props.filters.date_end))
		: dayjs(new Date(computedDefaultEndDate()));

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
				<Select fullWidth value={props.filters.order_status} onChange={handleStatusChanged}>
					{props.statuses.map(status => (
						<MenuItem key={status.value} value={status.value}>
							{status.name}
						</MenuItem>
					))}
				</Select>
			</Grid>

			<Grid item xs={6} sm={3}>
				{lang && computedLocaleText && (
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={lang}
						localeText={{ ...computedLocaleText }}
					>
						<DatePicker
							className={"w-100"}
							disableFuture
							label={localisation.forms.showFromLabel}
							value={datePickerValueStart}
							disabled={props.isLoading}
							onChange={handleDateStartChanged}
							slotProps={{ textField: { fullWidth: true } }}
						/>
					</LocalizationProvider>
				)}
			</Grid>

			<Grid item xs={6} sm={3}>
				{lang && computedLocaleText && (
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={lang}
						localeText={{ ...computedLocaleText }}
					>
						<DatePicker
							disableFuture
							label={localisation.forms.showByLabel}
							value={datePickerValueEnd}
							disabled={props.isLoading}
							onChange={handleDateEndChanged}
							slotProps={{ textField: { fullWidth: true } }}
						/>
					</LocalizationProvider>
				)}
			</Grid>
		</Grid>
	);
}

import { ShareInfo, ShareInfoProps } from "./ShareInfo";
import { ShareModal, ShareModalProps } from "./ShareModal";
import React from "react";

type ShareProps = ShareInfoProps & ShareModalProps;

export default function Share(props: ShareProps) {
	return (
		<>
			<ShareInfo
				couponBenefitAmount={props.couponBenefitAmount}
				handleShare={props.handleShare}
				isShareLoading={props.isShareLoading}
			/>
			<ShareModal
				openShareCode={props.openShareCode}
				closeShareModel={props.closeShareModel}
				botUrl={props.botUrl}
				webUrl={props.webUrl}
			/>
		</>
	);
}

import {ReactNode} from "react";
import {LoadingButtonProps} from "@mui/lab";
import {SetState} from "../../types";

interface IModalBaseProps {
    show: boolean
    setShow: (show: boolean) => void
}

interface IPaymentButtonsBaseProps{
    cashButton: string | null
    cardButton: string | null
    onlineButton: string | null
    qrButton: string | null
}

interface IQrMenuPaymentButtonBaseProps extends IPaymentButtonsBaseProps{
    setMessage: (message: string) => void
    setShowAlertModal: (show: boolean) => void
}

export interface IQrMenuButtonProps {
    buttonName: string | null
    width?: string
}

export interface ITgRedirectButtonProps extends IQrMenuButtonProps{
    byLink?: boolean
}

export interface IQrMenuChatButtonProps extends IQrMenuButtonProps{
    setShowNeedTgModal: (show: boolean) => void
    setMessage: (message: string) => void
}

export interface IQrMenuReviewButtonProps extends IQrMenuButtonProps{
    setShowNeedRegistrationModal: (show: boolean) => void
}

export interface IQrMenuPaymentButtonProps extends IQrMenuButtonProps, IQrMenuPaymentButtonBaseProps{
    paymentState: QrMenuPaymentState
    setPaymentState: SetState<QrMenuPaymentState>
}

export interface IQrMenuTgAlertModalProps extends IModalBaseProps{
    isOnlyTg: boolean
}

export interface IQrMenuNeedRegistrationAlertModalProps extends IModalBaseProps{

}

export interface IQrMenuNeedMessangerAlertModalProps extends IModalBaseProps{
    message: string
}

export interface IQrMenuPaymentsModalProps extends IModalBaseProps, IQrMenuPaymentButtonBaseProps{
    paymentState: QrMenuPaymentState
    setPaymentState: SetState<QrMenuPaymentState>
}

export interface IPayCashOrCardLoyaltyPopConfirmProps{
    type: 'cash' | 'card'
    bonusesAmount: number
    buttonContent: ReactNode
    sendPaymentMessageWithLoyalty: (isCash: boolean, isLoyalty: boolean) => Promise<void>
}

export interface INeedRegistrationBaseProps{
    messageText: string
    setShow?: (show: boolean) => void
}

export interface IQrMenuPaymentsProps extends IPaymentButtonsBaseProps {
    setShow?: (show: boolean) => void
    paymentState: QrMenuPaymentState
    setPaymentState: SetState<QrMenuPaymentState>
    isModal?: boolean
    show?: boolean
    forceDelayedAction: boolean
    setForceDelayedAction: (forceDelayedAction: boolean) => void
}

export interface IQrMenuButtonWrapperProps {
    children: ReactNode
    onClick: () => void
    disabled?: boolean
    loading?: boolean
    startIcon?: LoadingButtonProps['startIcon']
    endIcon?: LoadingButtonProps['endIcon']
    loadingPosition?: LoadingButtonProps['loadingPosition']
    noBorder?: boolean
}

export enum QrMenuPaymentState{
    CanPay,
    UserSum,
}

import { useParams } from "react-router-dom";
import useAppContext from "../../../useAppContext";
import { useMemo } from "react";
import api from "../../../api";

export default function useExternalLoginExtraData(data: Object | null | undefined = undefined) {
	const { brandInfo } = useAppContext();
	const { storeId } = useParams();

	return useMemo(() => {
		if (brandInfo?.id && storeId) {
			const authCartToken = api.shop.cart.getLocalCartToken(brandInfo.id, storeId);
			if (authCartToken) {
				const obj = {
					anon_cart_token: authCartToken,
					store_id: storeId,
				};
				if (data) {
					return { ...obj, ...data };
				}
				return obj;
			}
		}
		if (data) {
			return data;
		}
		return undefined;
	}, [brandInfo?.id, data, storeId]);
}

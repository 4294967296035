import { Box } from "@mui/material";
import { CheckProps } from "./types";
import CheckItems from "./CheckItems";
import "./check.scss";
import CheckHeader from "./CheckHeader";
import CheckTotal from "./CheckTotal";
import CheckAwards from "./CheckAwards";
import classNames from "classnames";

export default function Check({ data, afterItems, itemsHeaderProps, containerProps }: CheckProps) {
	return (
		<Box {...containerProps} className={classNames("check", containerProps?.className)}>
			<CheckHeader data={data} />

			{!!data.items?.length && !!data.viewOrder && (
				<CheckItems items={data.items} headerProps={itemsHeaderProps} />
			)}

			{afterItems}

			{data.type === "regular" && <CheckTotal data={data} />}

			<CheckAwards data={data} />
		</Box>
	);
}

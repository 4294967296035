import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CachedIcon from "@mui/icons-material/Cached";
import ListItemText from "@mui/material/ListItemText";
import PaidIcon from "@mui/icons-material/Paid";
import Alert from "@mui/material/Alert";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";

import formatCurrency from "../../../../helpers/formatCurrency";
import { Order } from "../../../../api/shop/order/types";
import { useMemo } from "react";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import useAppContext from "../../../../useAppContext";
import { Box } from "@mui/material";
import formatNumber from "../../../../helpers/formatNumber";

interface IOrderCardContentProps {
	order: Order;
}

export default function OrderCardContent(props: IOrderCardContentProps) {
	const {
		lang,
		localisation,
		groupService: { group },
		brandInfo,
	} = useAppContext();
	const { selectedStore } = useSelectedStoreContext();

	const computedToPaySum = useMemo((): string => {
		if (
			props.order.original_incust_loyalty_check?.amount_to_pay === 0 &&
			props.order.type !== "gift"
		) {
			return (0.0).toFixed(2);
		}
		return props.order.status_pay === "payed" && props.order.paid_sum
			? props.order.paid_sum.toFixed(2)
			: props.order.sum_to_pay.toFixed(2);
	}, [
		props.order.original_incust_loyalty_check?.amount_to_pay,
		props.order.paid_sum,
		props.order.status_pay,
		props.order.sum_to_pay,
		props.order.type,
	]);

	const getOrderStatusOrPayStatus = (status: string): string =>
		// @ts-ignore
		localisation.orders[status] || localisation.global.unknownStatus;

	return (
		<>
			<Typography
				gutterBottom
				variant="h5"
				component="div"
				sx={props.order.type === "gift" ? { mb: 0 } : {}}
			>
				{localisation.orders.orderNumber.replace("{order_id}", props.order.id.toString())}
			</Typography>
			{props.order.type === "gift" && (
				<Typography
					variant={"body2"}
					color={"text.secondary"}
					sx={{ mb: 2 }}
					component={"div"}
				>
					<Box display={"flex"}>
						<CardGiftcardOutlinedIcon sx={{ mr: 2 }} fontSize={"small"} />
						<Box alignSelf={"center"}>{localisation.global.gift}</Box>
					</Box>
				</Typography>
			)}

			<Typography sx={{ mb: 1.5 }} className={"theme-text"}>
				{props.order.create_date
					? new Date(props.order.create_date).toLocaleString(lang || "en", {
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
							hour: "2-digit",
							minute: "2-digit",
							timeZone: group?.timezone,
						})
					: localisation.global.unknownStatus}
			</Typography>

			<List dense={true}>
				<ListItem className={"ps-0"}>
					<ListItemIcon>
						<CachedIcon className={"theme-text"} />
					</ListItemIcon>
					<ListItemText primary={getOrderStatusOrPayStatus(props.order.status)} />
				</ListItem>
				<ListItem className={"ps-0"}>
					<ListItemIcon>
						<PaidIcon className={"theme-text"} />
					</ListItemIcon>
					<ListItemText primary={getOrderStatusOrPayStatus(props.order.status_pay)} />
				</ListItem>
			</List>

			<Typography
				gutterBottom
				variant="h6"
				component="div"
				className={"border-bottom fs-4 mb-0"}
			>
				<>
					{props.order.total_sum > 0 && (
						<>
							{props.order.type === "gift"
								? formatNumber(computedToPaySum, brandInfo?.default_lang || lang)
								: formatCurrency(
										computedToPaySum,
										brandInfo?.default_lang || lang,
										selectedStore.currency
									)}
							{props.order.type === "gift" && <> ({localisation.global.bonuses})</>}
						</>
					)}
				</>
			</Typography>

			{props.order.original_incust_loyalty_check?.amount_to_pay === 0 &&
				props.order.type !== "gift" && (
					<Alert className={"mx-1 mt-2"} severity="success">
						{localisation.global.fullPaidByBonuses}
					</Alert>
				)}
		</>
	);
}

import {AxiosInstance} from "axios";
import {Bot} from "./types";


export default function BotsAPIModule(instance: AxiosInstance) {
    const getPath = (path: any) => `bots/${path}`
    return {
        getBot(id: number) {
            return instance.get<Bot>(getPath(id))
        },
    }
}

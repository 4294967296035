import useWebSocket from "react-use-websocket";
import { WsUrl } from "../../config";
import { AIChatAction, AIChatSocketMessage } from "./types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useShopContext } from "../../shop/context";
import Chat from "../../features/Chat";
import { ChatMessageType } from "../../features/Chat/types";
import { SetState } from "../../types";
import { useSelectedStore } from "../../shop/SelectedStore/context";
import { ChatProps } from "../../features/Chat/Chat";
import { Box, Button, Typography } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloseIcon from "@mui/icons-material/Close";
import useAppContext from "../../useAppContext";

const SocketUrl = `${WsUrl}/ai_chat/ws`;

const MODEL = "gpt-4";
const TEMPERATURE = 1;
const TOP_P = 0.1;

export interface AIChatProps extends Omit<ChatProps, "messages" | "onSend" | "header"> {
	children?: any;
	history: ChatMessageType[];
	setHistory: SetState<ChatMessageType[]>;
	needConnect?: boolean;
}

export default function AIChat({
	children,
	history,
	setHistory,
	needConnect = true,
	...chatProps
}: AIChatProps) {
	const {
		lang,
		localisation: { ai: locale },
	} = useAppContext();
	const { brandInfo, setShowAIChat } = useShopContext();
	const selectedStore = useSelectedStore();

	const [error, setError] = useState<string | null>(null);

	const [isLoading, setIsLoading] = useState(false);
	const [needNote, setNeedNote] = useState(false);

	const { sendJsonMessage, lastJsonMessage } = useWebSocket<AIChatAction>(
		SocketUrl,
		{
			queryParams: {
				brand_id: brandInfo.id,
				lang: lang || brandInfo.default_lang,
				model: MODEL,
				temperature: TEMPERATURE,
				top_p: TOP_P,
				store_id: selectedStore.id,
			},
			onOpen: event => {
				setError(null);
				setHistory([]);
				console.log("websocket open: ", event);
			},
			onError: event => {
				setHistory([]);
				setError(locale.websocketConnectionError);
				setIsLoading(false);
				console.log("websocket error: ", event);
			},
			shouldReconnect: () => true,
			reconnectAttempts: 5,
		},
		!!brandInfo.id && needConnect
	);

	useEffect(() => {
		if (!lastJsonMessage) return;

		if (lastJsonMessage.action_type === "message") {
			const message = lastJsonMessage.message;

			setHistory(prev => {
				console.log("message received", message);
				if (message.from === "server") {
					if (message.type !== "chunk") {
						setIsLoading(false);
					}

					if (message.type === "error") {
						return prev.concat({
							id: prev.length,
							role: "error",
							type: "text",
							text: message.error,
							datetime: new Date(),
						});
					} else if (message.type === "text") {
						return prev.concat({
							id: prev.length,
							role: "system",
							type: "text",
							text: message.text,
							datetime: new Date(),
						});
					} else if (message.type === "recommended_products") {
						return prev.concat({
							id: prev.length,
							role: "assistant",
							type: "recommended_products",
							recommended_products: message.recommended_products,
							datetime: new Date(),
						});
					} else {
						if (message.chunk.is_new_message) {
							setIsLoading(true);

							return prev.concat({
								id: prev.length,
								role: message.chunk.role,
								type: "text",
								text: message.chunk.content || "",
								datetime: new Date(),
							});
						} else {
							if (message.chunk.is_finished) {
								setIsLoading(false);
							}

							if (message.chunk.new_content) {
								const reversedPrev = [...prev].reverse();

								const lastTextMessage = reversedPrev.find(
									msg => msg.type === "text" && msg.role === "assistant"
								);

								if (!lastTextMessage || lastTextMessage.type !== "text") {
									return prev.concat({
										id: prev.length,
										role: message.chunk.role,
										type: "text",
										text: message.chunk.content || "",
										datetime: new Date(),
									});
								}

								return [
									...prev.slice(0, -1),
									{
										...lastTextMessage,
										text: lastTextMessage.text + message.chunk.new_content,
									},
								];
							} else {
								return prev;
							}
						}
					}
				} else {
					return prev;
				}
			});
		} else {
		}
	}, [lastJsonMessage, setHistory]);

	const onSend = useMemo(() => {
		return (text: string) => {
			if (error || isLoading) return false;

			if (text.startsWith("/note ")) {
				const command = text.split(" ", 2)[1];

				let isError: boolean = false;
				let systemText: string;

				if (command === "show") {
					setNeedNote(true);
					systemText = "showing note";
				} else if (command === "hide") {
					setNeedNote(false);
					systemText = "hiding note";
				} else {
					isError = true;
					systemText = "invalid command";
				}

				setTimeout(() => {
					setHistory(prev =>
						prev.concat({
							id: prev.length,
							role: isError ? "error" : "system",
							type: "text",
							text: systemText,
							datetime: new Date(),
						})
					);
				}, 500);
			} else if (text.startsWith("/param")) {
				const [param, paramValue]: [
					param: "algo" | "dist_limit" | string,
					paramValue: string,
				] = text.split(" ", 3).slice(1) as [
					param: "algo" | "dist_limit" | string,
					paramValue: string,
				];

				let systemText: string | null = null;
				let paramValueConv: number | null = null;

				if (param === "algo") {
					paramValueConv = parseInt(paramValue);
					if (!paramValueConv) {
						systemText = "algo param must be an integer";
					} else {
						systemText = `algo set to ${paramValueConv}`;
					}
				} else if (param === "dist_limit") {
					paramValueConv = parseFloat(paramValue);
					if (!paramValueConv) {
						systemText = "dist_limit param must be an float";
					} else {
						systemText = `dist_limit set to ${paramValueConv}`;
					}
				} else {
					systemText = "unknown command";
				}

				if ((param === "algo" || param === "dist_limit") && paramValueConv) {
					const message: AIChatSocketMessage = {
						from: "client",
						type: "param",
						param: param,
						param_value: paramValueConv,
					};
					sendJsonMessage(message);
				}

				setTimeout(() => {
					setHistory(prev =>
						prev.concat({
							id: prev.length,
							role: paramValueConv ? "system" : "error",
							type: "text",
							text: systemText,
							datetime: new Date(),
						} as ChatMessageType)
					);
				}, 500);
			} else {
				const message: AIChatSocketMessage = {
					from: "client",
					type: "text",
					text: text,
				};
				setIsLoading(true);
				sendJsonMessage(message);
			}

			setHistory(prev =>
				prev.concat({
					id: prev.length,
					role: "user",
					type: "text",
					text: text,
					datetime: new Date(),
				})
			);

			return true;
		};
	}, [sendJsonMessage, setHistory, error, isLoading]);

	const restart = useCallback(() => {
		const message: AIChatSocketMessage = {
			from: "client",
			type: "restart",
		};
		sendJsonMessage(message);
		setHistory([]);
	}, [sendJsonMessage, setHistory]);

	const header = (
		<Box display={"flex"} alignItems={"center"} paddingX={3} paddingY={2}>
			<Typography variant={"h4"} flex={1}>
				{locale.chatHeader}
			</Typography>
			<Button
				onClick={e => {
					e.preventDefault();
					restart();
				}}
				sx={{
					minWidth: "auto",
					padding: "0",
				}}
			>
				<RestartAltIcon fontSize={"large"} />
			</Button>
			<Button
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					setShowAIChat(false);
				}}
				sx={{
					minWidth: "auto",
					padding: "0",
				}}
			>
				<CloseIcon fontSize={"large"} />
			</Button>
		</Box>
	);

	return (
		<Chat
			header={header}
			messages={history}
			onSend={onSend}
			inputPlaceholder={locale.chatMessagePlaceholder}
			emptyText={locale.noMessagesText}
			recommendedProductsText={locale.recommendedProductsText}
			error={error}
			isLoading={isLoading}
			needNote={needNote}
			{...chatProps}
		>
			{children}
		</Chat>
	);
}

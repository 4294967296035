import {useMemo} from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {MDBIcon} from "mdb-react-ui-kit";
import CardMedia from "@mui/material/CardMedia";

import {OrderProduct} from "../../../../api/shop/order/types";
import {useSelectedStoreContext} from "../../../SelectedStore/context";
import {Box} from "@mui/material";

type PreviewImageType = {
    id: number
    image: string,
}

type IOrderCardMediaProps = {
    orderProducts: OrderProduct[]
}

const MaxPreviewImagesCount = 4

export default function OrderCardMedia(props: IOrderCardMediaProps) {
    const {computedShopImage, selectedStore} = useSelectedStoreContext()

    const {
        previewImages,
        previewImagesCount,
        previewImagesOverloadCount,
        imageListCols,
        imageListRowHeight,
    } = useMemo(() => {
        let productsImages: PreviewImageType[] = []
        // const testList = [
        //     {
        //         id: 0,
        //         image: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622?w=248&fit=crop&auto=format&dpr=2"
        //     },
        //     {
        //         id: 1,
        //         image: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622?w=248&fit=crop&auto=format&dpr=2"
        //     },
        //     {
        //         id: 2,
        //         image: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622?w=248&fit=crop&auto=format&dpr=2"
        //     },
        //     {
        //         id: 3,
        //         image: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6?w=242&h=121&fit=crop&auto=format&dpr=2"
        //     },
        //     {
        //         id: 4,
        //         image: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1?w=121&h=121&fit=crop&auto=format&dpr=2"
        //     },
        // ]

        props.orderProducts.forEach(orderProduct => {
            if (orderProduct.product.image_url) {
                productsImages.push({
                    id: orderProduct.product.id,
                    image: orderProduct.product.image_url
                })
            }
        })

        // productsImages = testList

        const productsImagesCount = productsImages.length

        let previewImages: PreviewImageType[]
        let previewImagesOverloadCount: number

        if (productsImagesCount > MaxPreviewImagesCount) {
            previewImages = productsImages.slice(0, MaxPreviewImagesCount - 1)
            previewImagesOverloadCount = productsImagesCount - previewImages.length
        } else {
            previewImages = productsImages
            previewImagesOverloadCount = 0
        }

        const previewImagesCount = previewImages.length

        let imageListRowHeight: number
        if(previewImagesOverloadCount > 0 || productsImagesCount === 4) {
            imageListRowHeight = 106
        } else  (
            imageListRowHeight = 212
        )

        let imageListCols: number
        if (previewImagesOverloadCount > 0 || previewImagesCount === 4) {
            imageListCols = 2
        } else {
            imageListCols = previewImagesCount
        }

        return {
            previewImages,
            previewImagesCount,
            previewImagesOverloadCount,
            imageListRowHeight,
            imageListCols,
        }
    }, [props.orderProducts])

    if (previewImagesCount === 0) {
        return (
            <CardMedia
                component={"img"}
                height={"212"}
                image={computedShopImage}
                alt={selectedStore.name}
            />
        )
    }

    return (
        <ImageList
            cols={imageListCols}
            // gap={8}
            rowHeight={imageListRowHeight}
            className={'mb-1'}
        >
            {previewImages.map((item) => (
                <ImageListItem key={item.id}>
                    <img
                        srcSet={`${item.image}`}
                        src={`${item.image}`}
                        alt={item.id.toString()}
                        loading="lazy"
                        // style={{objectFit: 'contain', overflow: 'hidden'}}
                    />
                    {/*<ImageComponent*/}
                    {/*    imageSrc={`${item.image}`}*/}
                    {/*    alt={item.id.toString()}*/}
                    {/*/>*/}
                </ImageListItem>
            ))}
            {previewImagesOverloadCount > 0 && (
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bgcolor={'primary.main'}
                    color={'primary.contrastText'}
                >
                    <div>
                        <MDBIcon fas icon="plus" size={'lg'} className={'me-1 fs-2'} />
                        <span className={'fs-2 fw-bold'}>
                            {previewImagesOverloadCount}
                        </span>
                    </div>
                </Box>
            )}
        </ImageList>
    )
}

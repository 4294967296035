import {useState} from "react";
import {SetState} from "../../types";


export default function useAddressValue(): [string, SetState<string>] {
    const [addressValue, setAddressValue] = useState<string>("")

    return [addressValue, setAddressValue]
}

export function useAddressCoords(): [[number, number] | null, SetState<[number, number] | null>] {
    const [addressCoords, setAddressCoords] = useState<[number, number] | null>(null)

    return [addressCoords, setAddressCoords]
}

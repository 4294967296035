import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAppContext from "../../../../../useAppContext";
import { MouseEventHandler, useMemo } from "react";
import { IncustPayAccountData, IncustPayDataService } from "../types";
import useUnifiedSpecialAccountData from "../useUnifiedSpecialAccountData";
import { IUsePaymentsService } from "../../../hooks/useNewPaymentsService";

interface IncustPayModalButtonsProps {
	accountData: IncustPayAccountData;
	incustPayDataService: IncustPayDataService;
	onClose?: MouseEventHandler<HTMLButtonElement>;
	hideCancel?: boolean;
	paymentsService: IUsePaymentsService;
	cancelOrderLoading?: boolean;
	amountToPay: number;
}

export default function IncustPayModalButtons({
	accountData,
	incustPayDataService,
	onClose,
	hideCancel,
	paymentsService,
	cancelOrderLoading,
	amountToPay,
}: IncustPayModalButtonsProps) {
	const { localisation } = useAppContext();

	const specialAccountData = useUnifiedSpecialAccountData(accountData);

	const isDisabled = useMemo(() => {
		return (
			(specialAccountData.security_code && !incustPayDataService.pin) ||
			(specialAccountData.odometer === "yes" && !incustPayDataService.odometer) ||
			(specialAccountData.vehicle_id === "yes" && !incustPayDataService.vehicleId) ||
			(!specialAccountData.is_unlimited &&
				(!specialAccountData.available_amount ||
					specialAccountData.available_amount < amountToPay))
		);
	}, [
		amountToPay,
		incustPayDataService.odometer,
		incustPayDataService.pin,
		incustPayDataService.vehicleId,
		specialAccountData.available_amount,
		specialAccountData.is_unlimited,
		specialAccountData.odometer,
		specialAccountData.security_code,
		specialAccountData.vehicle_id,
	]);

	return (
		<>
			{!hideCancel && <Button onClick={onClose}>{localisation.global.cancelButton}</Button>}
			<Box width={"100%"} position={"sticky"} pt={2}>
				<LoadingButton
					type={"submit"}
					fullWidth={true}
					disabled={
						paymentsService.isLoading ||
						!paymentsService.selectedProvider ||
						cancelOrderLoading ||
						isDisabled
					}
					variant={"contained"}
					// onClick={submit}
					loading={paymentsService.isPrePaymentLoading || incustPayDataService.isLoading}
					size={"large"}
				>
					{paymentsService.selectedProvider?.is_online
						? localisation.orders.payButton
						: localisation.global.confirmButton}{" "}
					{paymentsService.calcSumToPay}
				</LoadingButton>
			</Box>
		</>
	);
}

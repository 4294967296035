import ErrorBoundary from "../features/ErrorBoundary";
import { Outlet } from "react-router-dom";
import ScrollToTopOnLocationChange from "./ScrollToTopOnLocationChange";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

export default function RootLayout() {
	return (
		<ErrorBoundary>
			<QueryClientProvider client={queryClient}>
				<Outlet />
				<ScrollToTopOnLocationChange />
			</QueryClientProvider>
		</ErrorBoundary>
	);
}

import { useMemo } from "react";
import { AuthSettings } from "../api/shop/basic/types";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import api from "../api";

export default function useAuthSettings(groupId?: number | undefined): IUseAuthSettings {
	const { data: authSettings, isPending } = useQuery({
		queryKey: ["auth_settings", groupId],
		initialData: null,
		placeholderData: keepPreviousData,
		enabled: !!groupId,
		queryFn: async () => {
			if (groupId) {
				return (await api.groups.getGroupAuthSettings(groupId)).data;
			}
			return null;
		},
	});

	const computedIsAnyAuthEnabled = useMemo(() => {
		if (authSettings) {
			if (
				!authSettings.is_auth_apple_enabled &&
				!authSettings.is_auth_google_enabled &&
				!authSettings.is_auth_email_enabled &&
				!authSettings.is_auth_messanger_enabled
			) {
				return false;
			}
		}
		return true;
	}, [authSettings]);

	const computedIsOnlyMessanger = useMemo(
		() =>
			!!authSettings &&
			authSettings.is_auth_messanger_enabled &&
			!authSettings.is_auth_email_enabled &&
			!authSettings.is_auth_google_enabled &&
			!authSettings.is_auth_apple_enabled,
		[authSettings]
	);

	const computedIsAnyExternalEnabled = useMemo(() => {
		if (authSettings) {
			if (
				!authSettings.is_auth_apple_enabled &&
				!authSettings.is_auth_google_enabled &&
				!authSettings.is_auth_messanger_enabled
			) {
				return false;
			}
		}
		return true;
	}, [authSettings]);

	const computedIsAnyOAuthEnabled = useMemo(() => {
		if (authSettings) {
			if (!authSettings.is_auth_apple_enabled && !authSettings.is_auth_google_enabled) {
				return false;
			}
		}
		return true;
	}, [authSettings]);

	return useMemo(() => {
		return {
			isPending,
			settings: authSettings || null,
			computedIsAnyAuthEnabled,
			computedIsOnlyMessanger,
			computedIsAnyExternalEnabled,
			computedIsAnyOAuthEnabled,
		};
	}, [
		isPending,
		authSettings,
		computedIsAnyAuthEnabled,
		computedIsOnlyMessanger,
		computedIsAnyExternalEnabled,
		computedIsAnyOAuthEnabled,
	]);
}

export interface IUseAuthSettings {
	isPending: boolean;
	settings: AuthSettings | null;
	computedIsAnyAuthEnabled: boolean;
	computedIsOnlyMessanger: boolean;
	computedIsAnyExternalEnabled: boolean;
	computedIsAnyOAuthEnabled: boolean;
}

import {MDBContainer} from "mdb-react-ui-kit"

import MakeOrder from "./MakeOrder"

export default function MakeOrderPage(){
    return (
        <MDBContainer className={'p-0 px-1'}>
            <MakeOrder/>
        </MDBContainer>
    )
}

import { Alert, Button } from "@mui/material";
import { useSelectedStoreContext } from "./SelectedStore/context";
import changePage from "../helpers/changePage";
import { SelectedShipmentServiceType } from "./order/MakeOrder/useSelectedShipmentService";
import formatCurrency from "../helpers/formatCurrency";
import useAppContext from "../useAppContext";
import Interweave from "../features/Interweave";

interface CartNoteProps {
	isInCart?: boolean;
	selectedShipmentService?: SelectedShipmentServiceType;
}

export default function CartNote({ isInCart = false, selectedShipmentService }: CartNoteProps) {
	const { lang, localisation, brandInfo } = useAppContext();
	const { cartService, selectedStore } = useSelectedStoreContext();

	const computedNote = () => {
		const notes: string[] = [];
		if (selectedShipmentService?.errors.isMinAmountError) {
			notes.push(
				localisation.global.minOrderAmount.replace(
					"{amount}",
					formatCurrency(
						selectedShipmentService.selectedShipment?.min_price || 0,
						brandInfo?.default_lang || lang,
						selectedStore.currency
					)
				)
			);
		}
		if (selectedShipmentService?.errors.isMaxAmountError) {
			notes.push(
				localisation.global.maxOrderAmount.replace(
					"{amount}",
					formatCurrency(
						selectedShipmentService.selectedShipment?.max_price || 0,
						brandInfo?.default_lang || lang,
						selectedStore.currency
					)
				)
			);
		}

		if (cartService.cart?.cart_products?.some(cartProduct => cartProduct.product.is_weight)) {
			notes.push(localisation.global.weightWarning);
		}

		return notes.length ? notes.join("\n\n") : null;
	};
	const note = computedNote();

	if (!note) return null;

	return (
		<Alert
			severity={"warning"}
			className={"small p-3"}
			action={
				selectedShipmentService?.errors.isCriticalError &&
				(selectedShipmentService.errors.isMinAmountError ? (
					<Button
						color={"inherit"}
						onClick={() => {
							changePage(`/shop/${selectedStore.id}/menu`);
						}}
					>
						{localisation.cart.cartAddMore}
					</Button>
				) : (
					selectedShipmentService.errors.isMaxAmountError &&
					!isInCart && (
						<Button
							color={"inherit"}
							onClick={() => {
								changePage(`/shop/${selectedStore.id}/cart`);
							}}
						>
							{localisation.global.toCart}
						</Button>
					)
				))
			}
		>
			<Interweave content={note} />
		</Alert>
	);
}

import {HTMLAttributes, InputHTMLAttributes, useEffect, useMemo, useRef} from "react";
import {MDBIcon, MDBSpinner} from "mdb-react-ui-kit";


interface ChatMessageInputProps extends InputHTMLAttributes<HTMLInputElement> {
    isLoading?: boolean
    onSend: (text: string) => any
    focusEventHandler?: () => void
}


export default function ChatMessageInput(
    {placeholder, onSend, isLoading, focusEventHandler, ...inputProps}: ChatMessageInputProps)
{
    const inputRef = useRef<HTMLInputElement | null>(null)

    const submit = useMemo(() => {
        return (e: any) => {
            e.preventDefault()
            const inputEl = inputRef.current
            if (inputEl) {
                const value = inputEl.value.trim()
                if (value) {
                    Promise.resolve(onSend(value)).then((result) => {
                        if (result !== false) {
                            inputEl.value = ''
                        }
                    })
                }
            }
        }
    }, [onSend])

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                if (inputRef.current) {
                    submit(e)
                }
            }
        }

        window.addEventListener('keypress', listener)

        return () => window.removeEventListener('keypress', listener)
    }, [submit]);

    useEffect(() => {
        const el = inputRef.current
        if (el) {
            const handler = () => {
                setTimeout(() => {
                    focusEventHandler && focusEventHandler()
                }, 500)

                el.scrollIntoView({
                    block: 'end',
                    inline: 'start',
                    behavior: 'smooth'
                })
            }

            el.addEventListener('focusin', handler)

            return () => {
                el.removeEventListener('focusin', handler)
            }

        }
    }, [])

    return (
        <div className={'message-input'}>
            <input
                ref={inputRef}
                autoComplete={'false'}
                type={'text'}
                placeholder={placeholder}
                {...inputProps}
            />
            <div onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()

                if (inputRef.current && inputRef.current?.value && !isLoading) {
                    onSend(inputRef.current.value)
                    inputRef.current.value = ''
                }
            }} className={"message-input-link send cursor-pointer"}>
                <span className={'visually-hidden'}>Send a message</span>
                {isLoading ? (
                    <MDBSpinner size={'sm'} role={'status'} />
                ) : (
                    <MDBIcon size={'lg'} fas icon="paper-plane" />
                )}
            </div>
        </div>
    )
}

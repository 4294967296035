import { useEffect, useState } from "react";
import { Alert, Grid } from "@mui/material";

import useAppContext from "../../useAppContext";
import useSearchParamsFixed from "../../features/hooks/useSearchParamsFixed";
import { NonModalDialogPage } from "../../features/NonModalDialog";
import Coupon from "./components/Coupon/Coupon";
import CouponAuth from "./components/Coupon/CouponAuth";

export default function AddCouponPage() {
	const { setPageTitle, brandInfo, lang, localisation } = useAppContext();
	const [searchParams] = useSearchParamsFixed();

	const couponId = searchParams.get("id");

	const [error, setError] = useState<string | null>(null);
	const [showAuthModal, setShowAuthModal] = useState(false);
	const [showEmail, setShowEmail] = useState(false);

	useEffect(() => {
		setPageTitle(localisation.profile.acceptCouponTitle);
	}, [localisation.profile.acceptCouponTitle, setPageTitle]);

	useEffect(() => {
		if (!couponId) {
			setError(localisation.profile.acceptCouponNoIdErr);
		} else if (!brandInfo?.id) {
			setError(localisation.global.brandNotLoadedText);
		} else if (
			!brandInfo.loyalty_info?.loyalty_enabled ||
			brandInfo.loyalty_info.loyalty_type !== "incust"
		) {
			setError(localisation.global.loyaltyNotConnectedText);
		}
	}, [
		couponId,
		brandInfo?.id,
		brandInfo?.loyalty_info?.loyalty_enabled,
		brandInfo?.loyalty_info?.loyalty_type,
		lang,
		localisation.profile.acceptCouponNoIdErr,
		localisation.global.brandNotLoadedText,
		localisation.global.loyaltyNotConnectedText,
	]);

	return (
		<>
			<NonModalDialogPage
				containerProps={{
					maxWidth: "md",
				}}
			>
				{error && (
					<Grid container justifyContent={"center"}>
						<Grid item xs={12} sm={6} md={5} sx={{ width: "100%" }}>
							<Alert severity={"error"} variant={"filled"}>
								{error}
							</Alert>
						</Grid>
					</Grid>
				)}

				{!!(couponId && !error) && (
					<Coupon
						codeOrId={couponId}
						needAddToWallet={true}
						showOnPage={true}
						externalSetShowAuthModal={setShowAuthModal}
					/>
				)}
			</NonModalDialogPage>

			<CouponAuth
				showEmail={showEmail}
				setShowEmail={setShowEmail}
				show={showAuthModal}
				setShow={setShowAuthModal}
			/>
		</>
	);
}

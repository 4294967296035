import { useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { Button, Slider, Typography } from "@mui/material";

import { TgButton } from "../helpers/ThemeComponents";
import { readFile } from "../helpers";
import getCroppedImg from "../helpers/cropImage";

interface IEditImageProps {
	uploadText: string;
	uploadButtonText: string;
	saveButtonText: string;
}

export default function EditImage(props: IEditImageProps) {
	const [imageSrc, setImageSrc] = useState<string | null>(null);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

	const handleSave = async () => {
		if (imageSrc && croppedAreaPixels) {
			const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
			console.log("done", { croppedImage });
		}
	};

	const onFileChange = async (e: any) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			let imageDataUrl = await readFile(file);

			setImageSrc(imageDataUrl);
		}
	};

	const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	return (
		<>
			{!!imageSrc ? (
				<>
					<div className={"position-relative"} style={{ minHeight: "350px" }}>
						<Cropper
							image={imageSrc}
							crop={crop}
							zoom={zoom}
							aspect={4 / 4}
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
						/>
					</div>

					<div>
						<div>
							<Typography variant="overline">Zoom</Typography>
							<Slider
								value={zoom}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby="Zoom"
								onChange={(_: any, zoom: any) => setZoom(zoom)}
							/>
						</div>
						<Button onClick={handleSave} variant="contained" color="primary">
							{props.saveButtonText}
						</Button>
					</div>
				</>
			) : (
				<>
					<div>{props.uploadText}</div>
					<TgButton variant="contained" component="label">
						{props.uploadButtonText}
						<input type="file" hidden onChange={onFileChange} />
					</TgButton>
				</>
			)}
		</>
	);
}

import { LocaleType } from "../services/useLocalisationService/types";

export interface MenuLocalisationUi extends LocaleType {
	sortButton: string;
	categorySort: string;
	defaultSort: string;
	lowHighFilter: string;
	highLowFilter: string;
	selectAnotherShop: string;
	searchProducts: string;
	noShopInformation: string;
	noProducts: string;
	categoryOtherProducts: string;
	field_address: string;
	field_phone_number: string;
	field_email: string;
	field_wifi: string;
	field_work_times: string;
	filtersButton: string;
	filtersLoading: string;
	filterNotSelected: string;
	filtersModalTitle: string;
	closeFiltersModalButton: string;
	filtersItemsSelectedText: string;
	filtersOneItemSelectedText: string;
	priceFilterLabel: string;
	rangeFilterLabel: string;
	modifierTitle: string;
	rangeFilterFrom: string;
	rangeFilterTo: string;
	rangeFilterFromTo: string;
	productIdText: string;
	allCharacteristics: string;
	storeText: string;
	changeStoreButton: string;
	addWithOtherAttributes: string;
	addWithOtherAttributesActive: string;
	addedOptionsHeader: string;
	deleteAllProdOptionsBtn: string;
	deleteChooseOptionMsg: string;
	deleteChooseOptionBtn: string;
	deleteFromCartSingleMsg: string;
	deleteFromCartAllOptionsMsg: string;
	managePositionBtn: string;
	optionsToEditSubHeader: string;
	cancelAddingNewOptionHeader: string;
	cancelAddingNewOptionMsg: string;
	deleteAllOptionsHeader: string;
	deleteProductHeader: string;
	editOptionHeader: string;
	addOptionBtn: string;
	addNewOptionSubheader: string;
	attributesHeader: string;
	attributesEditHeader: string;
	attributesSubHeader: string;
	attributesEditSubHeader: string;
	attributesAddingNewSubHeader: string;
	floatingSumEdit: string;
	floatingSumMaxError: string;
	floatingSumMinError: string;
	floatingSumOptionsHeader: string;
	floatingSumUserInputOptionHeader: string;
	floatingSumInputLabel: string;
	floatingSumMinMaxText: string;
	floatingSumEnterSum: string;
	floatingSumOptionsOrSumHeader: string;
	floatingSumPickOrEnter: string;
	floatingSumPick: string;
	giftHeader: string;
	giftNeedAuth: string;
	giftAwardsHeader: string;
	giftBonusBalance: string;
	giftConfirmHeader: string;
	giftSuccess: string;
	giftNotEnoughBonuses: string;
	giftShopMore: string;
	giftAwardsAfterHeader: string;
	giftNeedLoyalty: string;
	giftPaidBonuses: string;
	giftActionButton: string;
	giftReceiving: string;
	giftConfirmZeroPriceHeader: string;
	qtyChangedText: string;
	productQtyPlaceholder: string;
	productQtyHeader: string;
	loyaltyCurrencyError: string;
}

export const MenuLocale: MenuLocalisationUi = {
	sortButton: "web store sort button text",
	categorySort: "web store category sort text",
	defaultSort: "web store default sort text",
	lowHighFilter: "web store low high filter text",
	highLowFilter: "web store high low filter text",
	selectAnotherShop: "web store select another shop text",
	searchProducts: "web store search products text",
	noShopInformation: "web store no shop information text",
	noProducts: "web store no products text",
	categoryOtherProducts: "web store category other products text",
	field_address: "web store custom field address header text",
	field_phone_number: "web store custom field phone header text",
	field_email: "web store custom field email header text",
	field_wifi: "web store custom field wifi header text",
	field_work_times: "web store custom field work times header text",
	filtersButton: "web filters button",
	filtersLoading: "filters loading text",
	filterNotSelected: "filter not selected text",
	filtersModalTitle: "filters modal title",
	closeFiltersModalButton: "close filters modal button",
	filtersItemsSelectedText: "filters items selected text",
	filtersOneItemSelectedText: "filters one item selected text",
	priceFilterLabel: "price filter label",
	rangeFilterLabel: "range filter label",
	modifierTitle: "web store product modifier title",
	rangeFilterFrom: "web store range filter from text",
	rangeFilterTo: "web store range filter to text",
	rangeFilterFromTo: "web range filter from to text",
	productIdText: "web store product id text",
	allCharacteristics: "web store all characteristics button",
	storeText: "web store store text",
	changeStoreButton: "web store change store button",
	addWithOtherAttributes: "web app add with other attributes button",
	addWithOtherAttributesActive: "web app add with other attributes active button",
	addedOptionsHeader: "web app added product variations header",
	deleteAllProdOptionsBtn: "web app delete all product options button",
	deleteChooseOptionMsg: "web app delete choose product to delete msg",
	deleteChooseOptionBtn: "web app delete choose product to delete button",
	deleteFromCartSingleMsg: "web app delete product from cart msg",
	deleteFromCartAllOptionsMsg: "web app delete all product options from cart msg",
	managePositionBtn: "web app edit product with options button",
	optionsToEditSubHeader: "web app choose product option to edit msg",
	cancelAddingNewOptionHeader: "web app cancel adding new product header",
	cancelAddingNewOptionMsg: "web app cancel adding new product msg",
	deleteAllOptionsHeader: "web app delete all product options header",
	deleteProductHeader: "web app delete product header",
	editOptionHeader: "web app product option edit header",
	addOptionBtn: "add store product button",
	addNewOptionSubheader: "web app add new product option subheader",
	attributesHeader: "web app attributes header",
	attributesEditHeader: "web app attributes edit header",
	attributesSubHeader: "web app attributes subheader",
	attributesEditSubHeader: "web app attributes edit subheader",
	attributesAddingNewSubHeader: "web app attributes add new product option subheader",
	floatingSumEdit: "web app floating sum edit button",
	floatingSumMaxError: "web app floating sum max error",
	floatingSumMinError: "web app floating sum min error",
	floatingSumOptionsHeader: "web app floating sum choose option header",
	floatingSumUserInputOptionHeader: "web app floating sum user sum option header",
	floatingSumInputLabel: "web app floating sum input label",
	floatingSumMinMaxText: "web app floating sum min max text",
	floatingSumEnterSum: "web app floating sum enter sum text",
	floatingSumOptionsOrSumHeader: "web app floating sum choose option or enter sum header",
	floatingSumPickOrEnter: "web app floating sum pick or enter sum text",
	floatingSumPick: "web app floating sum pick sum text",
	giftHeader: "web app product gift header",
	giftNeedAuth: "web app product gift need auth header",
	giftAwardsHeader: "web app product gift awards header",
	giftBonusBalance: "web app bonus account balance header",
	giftConfirmHeader: "web app product gift confirm header",
	giftSuccess: "web app product gift success header",
	giftNotEnoughBonuses: "web app product gift not enough bonuses error",
	giftShopMore: "web app product gift purchase more to get bonuses",
	giftAwardsAfterHeader: "web app product gift awards after header",
	giftNeedLoyalty: "web app product gift disabled loyalty error",
	giftPaidBonuses: "web app product gift payed bonuses",
	giftActionButton: "web app product gift action button",
	giftReceiving: "web app product gift receiving text",
	giftConfirmZeroPriceHeader: "web app product gift confirm zero price header",
	qtyChangedText: "web app product qty changed alert text",
	productQtyPlaceholder: "web app product quantity placeholder text",
	productQtyHeader: "web app product quantity header text",
	loyaltyCurrencyError: "web app loyalty currency error message",
};

import { ThemeModal } from "../../helpers/ThemeComponents";
import EmbeddedShopMap from "./EmbeddedShopMap";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import { useSelectedStore } from "../SelectedStore/context";
import f from "../../helpers/formatText";

interface IEmbeddedShopMapModalProps {
	show: boolean;
	setShow: (val: boolean) => void;
}

export default function EmbeddedShopMapModal(props: IEmbeddedShopMapModalProps) {
	const localisation = useLocalisation();
	const selectedStore = useSelectedStore();

	return (
		<ThemeModal
			open={props.show}
			setOpen={props.setShow}
			title={f(localisation.maps.embeddedMapTitle, { store_name: selectedStore.name })}
			fullWidth
			fullScreen
			keepMounted={true}
			sx={{ zIndex: 9991, ".MuiDialogContent-root": { p: 0 } }}
		>
			<EmbeddedShopMap height={"100%"} />
		</ThemeModal>
	);
}

import useAppContext from "../../../../../useAppContext";
import React, { useCallback } from "react";
import { Box, IconButton } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import MUIPopConfirm from "../../../../../features/MUIPopConfirm";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { IIncustCoupon } from "../../../types";
import { LoadingButton } from "@mui/lab";

interface ICouponCardAction {
	coupon: IIncustCoupon,
	setCoupon: (coupon: IIncustCoupon | null) => void,
	setShowQrCode: (_: boolean) => void,
	handlerDeleteButton: (coupon_id: string) => void,
	handlerApplyButton: (coupon: IIncustCoupon) => void,
	handlerShareButton: (coupon: IIncustCoupon) => void,
	isDeleting: boolean | false,
	isSharing: boolean | false,
	isApplying: boolean | false,
	currentCouponId: string | null,
}

export function CouponCardActions({
									  coupon,
									  setCoupon,
									  setShowQrCode,
									  handlerDeleteButton,
									  handlerApplyButton,
									  handlerShareButton,
									  isDeleting,
									  isSharing,
									  isApplying,
									  currentCouponId,
								  }: ICouponCardAction) {

	const {
		localisation: { profile, global },
		brandInfo,
	} = useAppContext();


	const handlerShowQrButton = useCallback(async () => {
		if (!brandInfo?.id) return;
		if (!coupon?.id) return;
		setCoupon(coupon);
		setShowQrCode(true);
	}, [coupon, brandInfo, setCoupon, setShowQrCode]);

	if (!coupon.id) return null;

	return (<>
			<Box
				display={"flex"}
				gap={{ xs: 1, sm: 2 }}
				alignItems={"center"}
				className={"fs-3"}
				mt={2}
				flexWrap={"wrap"}
			>
				<Box
					display={"flex"}
					alignItems={"center"}
					flexWrap={"wrap"}
				>
					{coupon.batch?.share_allowed ? (<Box>
						<LoadingButton
							onClick={() => handlerShareButton(coupon)}
							color="inherit"
							endIcon={<ShareIcon color="primary" />}
							loading={isSharing && currentCouponId === coupon.id}
							loadingPosition="end"
						>
						</LoadingButton>
					</Box>) : null}
					{(coupon.status) && (
						<Box className={"align-self-center"}>
							<MUIPopConfirm
								cancelText={global.cancelButton}
								confirmText={global.confirmButton}
								onConfirm={() => handlerDeleteButton(coupon.id || "")}
								message={profile.deleteCouponConfirm}
								node={(
									<LoadingButton
										color="inherit"
										endIcon={<DeleteIcon color="primary" />}
										loading={isDeleting && currentCouponId === coupon.id}
										loadingPosition="end"
									>
									</LoadingButton>
								)
								}
								title={profile.titleDeletionCoupon}
							/>
						</Box>
					)}

					{(
						!coupon.redeem_dt &&
						(coupon.type === "certificate"
							||
							(
								coupon.type === "external" &&
								!coupon.redeem_at_terminal &&
								coupon.external_code_visibility_type !== "always-open"
							)
						)) && (
						<Box className={"align-self-center"}>
							<MUIPopConfirm
								cancelText={global.cancelButton}
								confirmText={global.confirmButton}
								onConfirm={() => handlerApplyButton(coupon)}
								message={profile.applyVoucherBody + " ?"}
								node={(
									<LoadingButton
										color="inherit"
										loading={isApplying && currentCouponId === coupon.id}
										loadingPosition="end"
										variant={"outlined"}
										size={"small"} sx={{ ml: 2 }}
									>
										{profile.applyCoupon}
									</LoadingButton>
								)}
								title={profile.applyVoucherTitle}
							/>
						</Box>
					)}

					{coupon.external_code && (<Box>
						<IconButton onClick={() => handlerShowQrButton()}>
							<QrCodeScannerIcon color="primary" />
						</IconButton>
					</Box>)}
				</Box>
			</Box>
		</>
	);
}
import { useCallback, useState } from "react";
import { TgButton } from "../../../../helpers/ThemeComponents";
import { Button } from "@mui/material";

import { NotAvailableProduct, Order } from "../../../../api/shop/order/types";
import api from "../../../../api";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import { SetState } from "../../../../types";
import MUIPopConfirm from "../../../../features/MUIPopConfirm";
import useAppContext from "../../../../useAppContext";
import Interweave from "../../../../features/Interweave";
import useScreenService from "../../../../services/useScreenService";
import { IUsePaymentsService } from "../../../payments/hooks/useNewPaymentsService";

interface IOrderCardActionsProps {
	order: Order;
	setOrders: SetState<Order[]>;
	setOrderState: (order: Order) => void;
	setSelectedOrder: (order: Order | null) => void;
	paymentsService: IUsePaymentsService;
	setShowPayments: (show: boolean) => void;
}

type UpdateOrderData = {
	[key in keyof Order]?: Order[key];
};

export default function OrderCardActions(props: IOrderCardActionsProps) {
	const { showError, localisation } = useAppContext();
	const { isMobile } = useScreenService();
	const { ordersCountService } = useSelectedStoreContext();

	const [isLoading, setIsLoading] = useState(false);
	const [notAvailableProducts, setNotAvailableProducts] = useState<NotAvailableProduct[]>([]);

	const handleOpenPayment = useCallback(() => {}, []);

	const notAvailableCallback = (invalidProducts: NotAvailableProduct[]) => {
		setNotAvailableProducts(invalidProducts);
	};

	const updateOrderState = (newOrderData: UpdateOrderData) => {
		props.setOrders(prevState =>
			prevState.map(x => {
				if (x.id === props.order.id) {
					return {
						...x,
						...newOrderData,
					};
				}
				return x;
			})
		);
	};

	const cancelOrder = async () => {
		if (isLoading) return;

		setIsLoading(true);
		try {
			try {
				await api.shop.order.cancelOrder(props.order.id);
				updateOrderState({ status: "canceled" });
				ordersCountService.setOrdersCount(prevState => prevState - 1);
			} catch (err) {
				console.log(err);
				showError(err);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{props.order.status === "open_unconfirmed" && props.order.status_pay === "must_pay" && (
				<div className={"align-self-center"}>
					<MUIPopConfirm
						cancelText={localisation.global.cancelButton}
						confirmText={localisation.global.confirmButton}
						onConfirm={() => cancelOrder()}
						message={localisation.orders.cancelOrderMsg}
						node={
							<Button size="small" color="error">
								{localisation.orders.cancelOrder}
							</Button>
						}
					/>
				</div>
			)}

			{props.order.status_pay !== "payed" &&
			props.order.status_pay !== "processing" &&
			(props.order.status === "open_unconfirmed" ||
				props.order.status === "open_confirmed") &&
			props.order.payment_method === "online" ? (
				<div>
					<Button
						size={"large"}
						variant={"outlined"}
						onClick={() => handleOpenPayment()}
						className={isMobile ? "w-100" : ""}
					>
						{localisation.orders.payButton}
					</Button>
					{notAvailableProducts.length > 0 && (
						<div className={"small fw-bold text-danger mt-2"}>
							<Interweave
								content={localisation.orders.cantPayCauseAvailability.replace(
									"{products}",
									notAvailableProducts.map(p => p.name).join(", ")
								)}
							/>
						</div>
					)}
				</div>
			) : (
				<TgButton
					onClick={() => props.setSelectedOrder(props.order)}
					className={"ms-auto"}
					variant={"outlined"}
				>
					{localisation.global.open}
				</TgButton>
			)}
		</>
	);
}

import {Box, BoxProps, Link, Typography} from "@mui/material";
import useAppContext from "../../useAppContext";
import ReviewModal from "./ReviewModal";
import {useState} from "react";


export default function ReviewProposal (props: BoxProps) {
    const {groupService: {group}} = useAppContext()

    const [show, setShow] = useState(false)

    if (!group?.texts.review.leave_button) return null
    return (<>
        <Box textAlign={'start'} {...props}>
            {group.texts.review.proposal_text && (
                <Typography fontSize={'inherit'}>
                    {group.texts.review.proposal_text}
                </Typography>
            )}
            <Link
                role={'button'}
                fontSize={'smaller'}
                onClick={() => setShow(true)}
            >
                {group.texts.review.leave_button}
            </Link>
        </Box>
        <ReviewModal show={show} setShow={setShow}/>
    </>)
}

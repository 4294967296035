import { Product } from "../../../../api/shop/basic/types";
import { useMemo } from "react";

export default function useProductType(
	product: Product | null,
	floatingSumAmount: number
): IUseProductType {
	const computedNeedShowId = useMemo(() => {
		return !!(product?.product_id && product?.type !== "info");
	}, [product?.product_id, product?.type]);

	const computedNeedShowPrice = useMemo(() => {
		return (
			(product && !product?.floating_sum_settings?.is_enabled && product.price > 0) ||
			(product?.type === "topup" && !!floatingSumAmount)
		);
	}, [floatingSumAmount, product]);

	const computedNeedShowModifiers = useMemo(() => {
		return product?.type !== "info";
	}, [product?.type]);

	const computedNeedShowDescription = useMemo(() => {
		return !!(product?.description && product?.type === "info");
	}, [product?.description, product?.type]);

	const computedNeedShowFloatingSum = useMemo(() => {
		return !!(
			product?.floating_sum_settings?.is_enabled &&
			product?.type !== "info" &&
			product?.type !== "gift" &&
			product?.type !== "topup"
		);
	}, [product?.floating_sum_settings?.is_enabled, product?.type]);

	return {
		computedNeedShowId,
		computedNeedShowPrice,
		computedNeedShowModifiers,
		computedNeedShowDescription,
		computedNeedShowFloatingSum,
	};
}

export interface IUseProductType {
	computedNeedShowId: boolean;
	computedNeedShowPrice: boolean;
	computedNeedShowModifiers: boolean;
	computedNeedShowDescription: boolean;
	computedNeedShowFloatingSum: boolean;
}

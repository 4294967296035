import {Box, BoxProps, IconButton, Typography} from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DescriptionIcon from '@mui/icons-material/Description';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {SvgIconComponent} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import {MouseEventHandler, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";


interface SelectedFilePreviewProps {
    file: File
    onRemove?: MouseEventHandler<HTMLButtonElement>
    imageSize?: number
    boxProps?: BoxProps
}

export default function SelectedFilePreview({imageSize = 20,...props}: SelectedFilePreviewProps) {
    const [imagePreview, setImagePreview] = useState<string | null>(null)

    const getFilePreviewText = (filename: string) => {
        const parts = filename.split('.')

        let name = parts.slice(0, parts.length - 1).join('.')
        const extension = parts[parts.length - 1]

        if (name.length > 30) {
            name = name.slice(0, 27) + '...'
        }
        return name + '.' + extension
    }

    const FileIconComponent = useFileIconComponent(props.file.type)

    useEffect(() => {
        setImagePreview(null)

        if (props.file.type.startsWith('image')) {
            const reader = new FileReader()
            reader.readAsDataURL(props.file)

            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    console.log('reader result', reader.result)
                    setImagePreview(reader.result)
                }
            }
        }
    }, [props.file]);

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            border={1}
            borderColor={'divider'}
            borderRadius={'2.5rem'}
            fontSize={`${imageSize}px`}
            width={'fit-content'}
            px={2}
            {...props.boxProps}
        >
            {imagePreview ? (
                <FilePreviewImg
                    alt={'selected file preview'}
                    src={imagePreview}
                    sx={{
                        maxWidth: `${imageSize}px`,
                        maxHeight: `${imageSize}px`,
                        width: 'auto',
                        height: 'auto',
                        ml: 1,
                        my: 2,
                    }}
                />
            ) : (
                <FileIconComponent
                    fontSize={'inherit'}
                    sx={{ml: 1}}
                />
            )}
            <Typography
                ml={2}
                variant={'body2'}
                color={'text.secondary'}
            >
                {getFilePreviewText(props.file.name)}
            </Typography>
            <IconButton
                onClick={props.onRemove}
                sx={{
                    fontSize: 'medium',
                    p: 1,
                    ml: 1,
                }}
            >
                <CloseIcon fontSize={'inherit'}/>
            </IconButton>
        </Box>
    )
}


function useFileIconComponent(type: string): SvgIconComponent {
    const fileType = type.split('/')[0]

    switch (fileType) {
        case "image":
            return ImageIcon
        case "video":
            return VideoFileIcon
        case "audio":
            return AudioFileIcon
        case "application":
            return InsertDriveFileIcon
        case "text":
            return DescriptionIcon
        default:
            return QuestionMarkIcon
    }
}


const FilePreviewImg = styled("img")()

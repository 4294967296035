import classnames from "classnames";
import {HTMLAttributes} from "react";


interface FullPageProps extends HTMLAttributes<HTMLDivElement> {
    centered?: boolean
}


export default function FullPage({children, className = '', centered, ...rest}: FullPageProps) {
    return (
        <div className={classnames('full-page', className, {
            'd-flex justify-content-center align-items-center': centered
        })} {...rest}>
            {centered ? (
                <div>
                    {children}
                </div>
            ) : children}
        </div>
    )
}

import { useRef, useState } from "react";
import { MDBIcon, MDBListGroup, MDBListGroupItem, MDBInputGroup } from "mdb-react-ui-kit";

import { ITgWebViewProfileLangPicker } from "../lang/types";
import { AvailableLanguage } from "../api/localisation/types";
import useAppContext from "../useAppContext";
import { Box, Button, Divider, Popover, Typography } from "@mui/material";
import useScreenService from "../services/useScreenService";

interface ILangPickerProps extends ITgWebViewProfileLangPicker {
	classes?: string;
	fullLangName?: boolean;
}

export default function LangPicker(props: ILangPickerProps) {
	const { isMobile } = useScreenService();
	const anchorElRef = useRef<HTMLDivElement | null>(null);

	const {
		lang,
		setLang,
		localisation: { availableLangs },
		authService,
		loadingManager: { setIsLoading },
	} = useAppContext();

	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

	const { isAuthorised, updateUserLang } = authService;
	const onSelect = async (option: AvailableLanguage) => {
		setIsPopoverOpen(false);
		try {
			let newLang: string;
			if (isAuthorised) {
				setIsLoading("updating-lang", true);
				newLang = await updateUserLang(option.code);
			} else {
				newLang = option.code;
			}
			setLang(newLang);
		} finally {
			setIsLoading("updating-lang", false);
		}
	};

	if (!lang || !availableLangs || availableLangs.length < 2) return null;

	return (
		<div ref={anchorElRef}>
			<Button
				color={isMobile ? "inherit" : "primary"}
				size={"large"}
				sx={{
					maxWidth: "auto",
					fontSize: "1rem",
					fontWeight: "bold",
					px: 1,
					minWidth: "auto",
					whiteSpace: "nowrap",
				}}
				onClick={e => {
					e.preventDefault();
					setIsPopoverOpen(true);
				}}
			>
				{props.fullLangName
					? availableLangs.find(el => el.code === lang)?.original_name
					: lang.toString().toUpperCase()}
				<MDBIcon className={"ms-1"} fas icon={"caret-" + (isPopoverOpen ? "up" : "down")} />
			</Button>
			<Popover
				anchorEl={anchorElRef.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={isPopoverOpen}
				onClose={() => setIsPopoverOpen(false)}
			>
				<Box px={2} pt={2}>
					<Typography fontWeight={"bold"}>{props.selectHeader}</Typography>
					<Divider color={"borderColor"} sx={{ mt: 1 }} />
					<LangSelect
						availableLangs={availableLangs}
						value={lang}
						onSelect={onSelect}
						searchPlaceholder={props.searchPlaceholder}
					/>
				</Box>
			</Popover>
		</div>
	);
}

interface LangSelectProps {
	availableLangs: AvailableLanguage[];
	value: string | null;
	onSelect: (option: AvailableLanguage) => any;
	searchPlaceholder: string;
}

function LangSelect(props: LangSelectProps) {
	const [search, setSearch] = useState("");

	if (!props.availableLangs.length) {
		return null;
	}

	const isSearchTrue = (value: string | null) => {
		return value && value.toLowerCase().includes(search.toLowerCase());
	};

	const filteredAutoLangs = props.availableLangs.filter(option => {
		return (
			isSearchTrue(option.original_name) ||
			isSearchTrue(option.current_name) ||
			isSearchTrue(option.english_name) ||
			option.code.toLowerCase() === search.toLowerCase()
		);
	});

	return (
		<>
			<MDBInputGroup className={"tg-group px-0 pt-2"} style={{ borderRadius: "0" }}>
				<input
					id={"lang_picker_search"}
					className={"form-control theme-bg-paper"}
					placeholder={props.searchPlaceholder}
					type="text"
					value={search}
					onChange={e => setSearch(e.target.value)}
				/>
			</MDBInputGroup>
			<MDBListGroup
				className={"lang-list mt-1"}
				style={{
					height: "150px",
					overflow: "auto",
					borderRadius: "0",
				}}
			>
				{filteredAutoLangs.map(option => (
					<Box
						key={option.code}
						component={MDBListGroupItem}
						noBorders
						className={
							"item px-3 cursor-pointer theme-text" +
							(props.value === option.code ? " active" : "")
						}
						onClick={(e: { stopPropagation: () => void }) => {
							e.stopPropagation();
							props.onSelect(option);
						}}
						sx={{
							backgroundColor: theme => theme.palette.background.paper,
						}}
					>
						{option.original_name ||
							option.current_name ||
							option.english_name ||
							option.code}
						{option.is_auto_translate && (
							<span className={"rounded ms-1"}>
								<img
									style={{ width: 18, height: 18 }}
									alt={"translator logo"}
									src={
										"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Google_Translate_logo.svg/2048px-Google_Translate_logo.svg.png"
									}
								/>
							</span>
						)}
					</Box>
				))}
			</MDBListGroup>
		</>
	);
}

import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SetState } from "../types";
import isElementFullyVisible from "../helpers/isElementFullyVisible";

export interface StepperType {
	activeStep: number;
	setActiveStep: SetState<number>;

	setNextStep: () => void;
	setPrevStep: () => void;

	ref: MutableRefObject<HTMLDivElement | null>;
}

export default function useStepper(): StepperType {
	const ref = useRef<HTMLDivElement | null>(null);
	const [activeStep, setActiveStep] = useState(0);

	const scrollToStep = useCallback((step: number) => {
		const stepperWrapperEl = ref.current;
		if (stepperWrapperEl) {
			const stepperEl = stepperWrapperEl.children[0];
			const stepEl = stepperEl.children[step * 2];

			if (stepEl && !isElementFullyVisible(stepEl)) {
				console.log(`scrolling to`, stepEl);

				stepEl.scrollIntoView({
					inline: "start",
					block: "end",
				});
			}
		}
	}, []);

	const setNextStep = useCallback(() => {
		setActiveStep(prevState => {
			return prevState + 1;
		});
	}, []);

	const setPrevStep = useCallback(() => {
		setActiveStep(prevState => {
			return prevState - 1;
		});
	}, []);

	useEffect(() => {
		setTimeout(() => {
			scrollToStep(activeStep);
		}, 500);
	}, [activeStep, scrollToStep]);

	return useMemo(
		() => ({
			activeStep,
			setActiveStep,
			setNextStep,
			setPrevStep,
			ref,
		}),
		[activeStep, setNextStep, setPrevStep]
	);
}

import { keepPreviousData, useQuery } from "@tanstack/react-query";

import useAppContext from "../../useAppContext";
import api from "../../api";
import { IIncustCustomerService } from "../../api/shop/loyalty/types";
import { useSelectedStoreOptional } from "../../shop/SelectedStore/context";

export default function useUserIncustCustomerData(
	storeId__?: number | null | undefined
): IIncustCustomerService {
	const {
		lang,
		brandInfo,
		authService: { user },
	} = useAppContext();

	const store = useSelectedStoreOptional();

	const storeId = storeId__ || store?.id;

	const query = useQuery({
		queryKey: ["incust-customer-data", lang, user?.id, user?.client, storeId],
		queryFn: async () => {
			if(!user) return null
			const response = await api.auth.getUserIncustCustomerData(storeId);
			return response.data;
		},
		placeholderData: keepPreviousData,
		refetchOnMount: false,
		enabled: !!lang && !!user?.id && !!brandInfo?.loyalty_info?.loyalty_enabled,
	});

	return {
		query: query,
		customerData: query.data?.incust_customer_data || null,
		isCustomerPending: query.isPending,
		isCustomerFetching: query.isFetching,
		refetchCustomer: query.refetch,
	};
}

import { IncustPayConfiguration } from "../../api/shop/payments/types";

export interface IIncustCheckBase {
	amount: number;
	amount_to_pay: number; //amount === amount_to_pay on first attempt
	bonuses_redeemed_amount?: number;
}

export interface IIncustUserCardInfo {
	bonuses: IIncustBonusItem[] | null;
	coupons: IIncustCoupon[] | null;
	customer: IncustCustomer;
	identification?: any;
	rate_info: any;
	specials: any[] | null;
	tourist_cards?: any[];
}

export type YesNoPossible = "yes" | "no" | "possible";

export type CustomerSpecialAccount = {
	amount: number | null;
	available_amount: number | null;
	balance: number | null;
	customer_special_account_id: string | null;
	extended: boolean;
	goods_items: [] | null;
	id: string;
	limits: CustomerSpecialAccountLimits | null;
	odometer: YesNoPossible;
	redeem_amount: number | null;
	refill_amount: number | null;
	security_code: boolean;
	special_account: SpecialAccount;
	title: string | null;
	corporate?: boolean;
} & CreditLimit;

export type CorporateCustomerAccountsAccess = {
	available_amount: number | null;
	corporate_customer_special_account: CorporateCustomerSpecialAccount;
	goods_items: string[] | null;
	id: string | null;
	limits: CorporateSpecialAccountAccessLimits | null;
	odometer: YesNoPossible;
	security_code: boolean;
	vehicle_id: YesNoPossible;
};

export type CorporateSpecialAccountAccessLimits = {
	account_available_amount: number | null;
	account_day_limit: number | null;
	account_day_limit_available: number | null;
	account_day_limit_used: number | null;
	account_month_limit: number | null;
	account_month_limit_available: number | null;
	account_month_limit_used: number | null;
	user_day_limit: number | null;
	user_day_limit_available: number | null;
	user_day_limit_used: number | null;
	user_month_limit: number | null;
	user_month_limit_available: number | null;
	user_month_limit_used: number | null;
};

export type CorporateCustomerSpecialAccount = {
	active: boolean;
	available_amount: number | null;
	corporate_customer: CorporateCustomer | null;
	goods_items: any[] | null;
	id: string | null;
	limits: CorporateCustomerSpecialAccountLimits | null;
	special_account: SpecialAccount;
} & CreditLimit;

export type CorporateCustomerSpecialAccountLimits = {
	day_limit: number | null;
	day_limit_used: number | null;
	month_limit: number | null;
	month_limit_used: number | null;
};

export type CorporateCustomer = {
	corporate_user: CorporateUser | null;
	id: string | null;
	name: string | null;
	suspended: boolean;
};

export type CorporateUser = {
	email: string | null;
	name: string | null;
	status: boolean;
};

export interface CustomerSpecialAccountLimits {
	account_available_amount: number | null;
	day_limit: number | null;
	day_limit_available: number | null;
	day_limit_used: number | null;
	month_limit: number | null;
	month_limit_available: number | null;
	month_limit_used: number | null;
}

export interface IncustCustomer {
	access_type:
		| "add-bonuses-only"
		| "full-bonuses-access"
		| "waiting-for-confirmation"
		| "in-black-list";
	avatar: string | null;
	card_category: IncustCardCategory | null;
	have_mobileapp_token: boolean | null;
	id: string | null;
	last_sale_data: string | null;
	new: number | null;
	show_questionnaire: number | null;

	user_birth_data: string | null;
	user_consent_exist: boolean | null;
	user_created: string | null;
	user_email: string | null;
	user_external_id: string | null;
	user_gender: string | null;
	user_name: string | null;
	user_phone: string | null;
	user_status: string | null;
}

export interface IncustCardCategory {
	id: string | null;
	image: string | null;
	show_to_customers: number | null;
	title: string | null;
	type: string | null;
	weight: string | null;
}

interface IIncustLoyaltySettings {
	bonus_payment_limit?: number | null;
	bonus_payment_type?: string | null;
	show_customers_questionnaire?: string | null;
	unconfirmed_bonus_payment_amount?: number | null;
	unconfirmed_discount_amount?: number | null;
}

export interface IIncustLoyalty {
	id: string;
	photos?: string[] | null;
	title?: string | null;
	public_description?: string | null;
}

export interface IIncustSettings {
	business_code: string;
	currency: string;
	display_active: boolean;
	display_type: string;
	id: string;
	loyalty: IIncustLoyalty;
	loyalty_settings: IIncustLoyaltySettings;
	phone: string;
	pos: any;
	system_params: any;
	title: string;
	public_description?: string;
	photos?: string[];
}

export interface ILoyaltyInfo {
	loyalty_type: "incust" | null;
	loyalty_enabled: boolean;
}

export interface IIncustBonusItem {
	currency: string;
	permanent_bonuses_amount: number;
	temporary_bonuses_amount: number;
	bonuses_amount: number;
}

interface AdditionalServiceCostValue {
	threshold?: number | null;
	type?: string | null;
	value?: number | null;
}

interface AdditionalServiceCost {
	currency?: string | null;
	decimal_digits?: number | null;
	min_value?: number | null;
	values?: AdditionalServiceCostValue[] | null;
}

interface MobileAppInfo {
	id?: string | null;
	status?: string | null;
	type?: string | null;
}

interface AdditionalService {
	code?: string | null;
	cost?: AdditionalServiceCost[] | null;
	default?: number | null;
	id?: string | null;
	public_description?: string | null;
	public_title?: string | null;
	sort_order?: number | null;
	type?: string | null;
}

interface PhoneNumber {
	number?: string | null;
}

interface Image {
	id?: string | null;
	url?: string | null;
}

interface CheckTank {
	address?: string | null;
	code?: string | null;
	goods_code?: string | null;
	latitude?: number | null;
	longitude?: number | null;
	physical_dip_amount?: number | null;
	product_volume_after?: number | null;
	product_volume_before?: number | null;
	tank_id?: string | null;
	title?: string | null;
	volume?: number | null;
}

interface PriceExplainDetailingItem {
	decimal_digits?: number | null;
	exempt?: boolean | null;
	include_in_receipt?: boolean | null;
	initial_value?: number | null;
	source?: string | null;
	type?: string | null;
	value?: number | null;
	value_code?: string | null;
	value_id?: string | null;
	value_option?: string | null;
	value_origin?: number | null;
	value_title?: string | null;
	value_type?: string | null;
}

interface CheckMarginExplainDetailingItem {
	decimal_digits?: number | null;
	include_in_receipt?: boolean | null;
	source?: string | null;
	type?: string | null;
	value?: number | null;
	value_code?: string | null;
	value_id?: string | null;
	value_title?: string | null;
}

interface BillingInfo {
	address?: { [key: string]: any } | null;
	email?: string | null;
	name?: string | null;
	phone?: string | null;
}

interface BonusAdded {
	amount: number;
	expire?: string | null;
	starting?: string | null;
}

interface CheckBusiness {
	description?: string | null;
	id?: string | null;
	photos?: string[] | null;
	title?: string | null;
}

interface CheckMargins {
	terminal_additional_costs?: CheckMarginExplainDetailingItem[] | null;
}

interface Job {
	auto_capture_parent_transaction?: boolean | null;
	auto_start?: boolean | null;
	created?: string | null;
	details?: { [key: string]: any } | null;
	finalize_transaction_errors?: string[] | null;
	finalize_transaction_failed?: boolean | null;
	finalize_transaction_id?: string | null;
	finished?: string | null;
	id?: string | null;
	started?: string | null;
	status?: string | null;
	transaction_id?: string | null;
	type: string;
	user_id?: string | null;
}

interface GoodsPassThruFleet {
	measure_unit?: string | null;
	product_code?: string | null;
}

interface CheckTaxExplainDetailingItem {
	decimal_digits?: number | null;
	include_in_receipt?: boolean | null;
	source?: string | null;
	type?: string | null;
	value?: number | null;
	value_code?: string | null;
	value_id?: string | null;
	value_title?: string | null;
}

interface PriceMargins {
	terminal_additional_costs?: PriceExplainDetailingItem[] | null;
}

interface PriceTaxes {
	base?: PriceExplainDetailingItem[] | null;
	sales?: PriceExplainDetailingItem[] | null;
}

interface CorporateStaffPosition {
	id?: string | null;
	public_description?: string | null;
	public_title?: string | null;
	title?: string | null;
}

interface RuleImplementationData {
	coupon?: IIncustCoupon | null;
	implemented_by?: string | null;
	result?: InlineModel16 | null;
}

interface LoyaltyRulePos {
	id: string;
	title?: string | null;
}

interface ThresholdsValues {
	threshold: number;
	value: number;
}

interface LoyaltyCategory {
	id?: number | null;
	image_url?: string | null;
	title?: string | null;
}

interface InlineModel16 {
	facebook?: string | null;
	google?: string | null;
	instagram?: string | null;
	linkedin?: string | null;
	pinterest?: string | null;
	twitter?: string | null;
	vk?: string | null;
	web?: string | null;
}

interface BankCardExternalInfo {
	edc_card_type?: string | null;
	id?: string | null;
	status?: string | null;
	type?: string | null;
}

interface PaymentExternalInfo {
	app_version?: string | null;
	auth_code?: string | null;
	bank_response_code?: string | null;
	batch_number?: string | null;
	card_holder_name?: string | null;
	card_issuer_name?: string | null;
	card_label?: string | null;
	cvm?: string | null;
	emv_ac?: string | null;
	emv_aid?: string | null;
	emv_aid_name?: string | null;
	emv_atc?: string | null;
	emv_iad?: string | null;
	emv_tsi?: string | null;
	emv_tvr?: string | null;
	entry_mode?: string | null;
	gateway_transaction_id?: string | null;
	host_timestamp?: string | null;
	invoice_number?: string | null;
	merchant_id?: string | null;
	pan?: string | null;
	response_code?: string | null;
	response_code_iso?: string | null;
	response_text?: string | null;
	sequence_number?: string | null;
	system_type?: string | null;
	terminal_id?: string | null;
	transaction_name?: string | null;
	transaction_reference_number?: string | null;
}

interface CheckItemAdditionalInfo {
	job?: Job | null;
	loyalty_item_id?: string | null;
	processing_id?: string | null;
	quantity_decimal_digits?: number | null;
	quantity_recalculate_skipped?: boolean | null;
	quantity_recalculate_type?: string | null;
}

interface GoodsPassThru {
	fleet?: GoodsPassThruFleet | null;
}

interface CheckTaxes {
	base?: CheckTaxExplainDetailingItem[] | null;
	sales?: CheckTaxExplainDetailingItem[] | null;
}

export interface IIncustCheckItem {
	additions?: CheckItemAdditionalInfo | null;
	amount?: number | null;
	amount_decimal_digits?: number | null;
	authorized_amount?: number | null;
	authorized_quantity?: number | null;
	bonuses_added?: number | null;
	bonuses_decimal_digits?: number | null;
	calculated_bonuses_redeemed_amount?: number | null;
	calculated_discount_amount?: number | null;
	calculated_margins?: CheckMargins | null;
	calculated_price?: number | null;
	calculated_taxes?: CheckTaxes | null;
	calculated_unit_bonuses_redeemed_amount?: number | null;
	calculated_unit_discount_amount?: number | null;
	category?: string | null;
	code: string;
	discount_amount?: number | null;
	loyalty_item_id?: string | null;
	margins?: PriceMargins | null;
	passthru_data?: GoodsPassThru | null;
	price?: number | null;
	price_decimal_digits?: number | null;
	processed_amount?: number | null;
	processed_quantity?: number | null;
	quantity?: number | null;
	quantity_decimal_digits?: number | null;
	quantity_recalculate_type?: string | null;
	special_accounts_decimal_digits?: number | null;
	taxes?: PriceTaxes | null;
	title?: string | null;
	unit?: string | null;
	weighing?: number | null;
	whole_number?: number | null;
}

interface CheckService {
	additional_service_id?: string | null;
	amount?: number | null;
	code: string;
	cost?: number | null;
	decimal_digits?: number | null;
	title?: string | null;
	type?: string | null;
	value?: number | null;
}

interface CorporateVehicleInfo {
	avi_id?: string | null;
	description?: string | null;
	id?: string | null;
	position?: CorporateStaffPosition | null;
	position_id?: string | null;
	qr_code?: string | null;
	vehicle_id?: string | null;
}

interface CouponBatch {
	auto_redeem_on_add_to_wallet?: number | null;
	business_id?: string | null;
	customers_price_amount?: number | null;
	customers_price_currency?: string | null;
	customers_price_special_account_id?: string | null;
	customers_price_type?: string | null;
	id?: string | null;
	loyalty_id?: string | null;
	public_description?: string | null;
	public_title?: string | null;
	recommendation_fee_amount?: number | null;
	recommendation_fee_bonuses_currency?: string | null;
	recommendation_fee_special_account_id?: string | null;
	recommendation_fee_type?: string | null;
	recommendation_fee_coupon_batch_title?: string | null;
	recommendation_fee_special_account_title?: string | null;
	redeem_at_terminal?: number | null;
	share_allowed?: number | null;
	type?: string | null;
	emission_period_end?: string | null;
	image?: string | null;
}

export interface IIncustCoupon {
	applicable?: boolean | null;
	batch?: CouponBatch | null;
	code?: string | null;
	error?: string | null;
	external_code?: string | null;
	external_code_visibility_type?: string | null;
	gift_card_id?: string | null;
	id?: string | null;
	locked?: boolean | null;
	status?: string | null;
	valid?: boolean | null;
	title?: string | null;
	description?: string | null;
	image?: string | null;
	type: string;
	charge_amount?: number | null;
	charge_bonuses_currency?: string | null;
	charge_type?: string | null;
	redeem_result_transaction_id: string | null;
	expire_date?: string | null;
	redeem_dt?: string | null;
	redeem_at_terminal: number | null;
	recommendation_fee_amount?: number | null;
	recommendation_fee_bonuses_currency?: string | null;
	recommendation_fee_type?: string | null;
	recommendation_fee_special_account_title?: string | null;
	recommendation_fee_promotional_bonuses_expire_date?: string | null;
	recommendation_fee_promotional_bonuses_expire_type?: string | null;
	recommendation_fee_promotional_bonuses_expire_value?: number | null;
	recommendation_fee_coupon_batch_title?: string | null;
	do_not_add_to_wallet?: boolean | null;
}

interface LoyaltyRule {
	action_type?: string | null;
	active?: number | null;
	applicable_for_customer?: string | null;
	applicable_for_sale?: string | null;
	apply_when_bonuses_redeemed?: number | null;
	apply_when_customers_account_payment?: number | null;
	automatic?: number | null;
	card_category_id?: string | null;
	charge_type?: string | null;
	combine?: number | null;
	comment?: string | null;
	coupon_batch_code?: string | null;
	depends_calc_period?: string | null;
	depends_type?: string | null;
	excluded_item_categories?: string[] | null;
	excluded_items?: string[] | null;
	id?: string | null;
	implementation_data?: RuleImplementationData | null;
	included_item_categories?: string[] | null;
	included_items?: string[] | null;
	items_limit_max?: number | null;
	items_limit_min?: number | null;
	loyalty_id?: string | null;
	pos?: LoyaltyRulePos[] | null;
	priority?: number | null;
	promotional_bonuses_expire_date?: string | null;
	promotional_bonuses_expire_type?: string | null;
	promotional_bonuses_expire_value?: number | null;
	promotional_bonuses_starting_date?: string | null;
	promotional_bonuses_starting_type?: string | null;
	promotional_bonuses_starting_value?: number | null;
	scheduled?: number | null;
	special_account_id?: string | null;
	time_from?: string | null;
	time_to?: string | null;
	title?: string | null;
	valid_after?: string | null;
	valid_before?: string | null;
	value?: ThresholdsValues[] | null;
	week_days?: string[] | null;
	coupon_do_not_add_to_wallet?: number | null;
}

interface Loyalty {
	categories?: LoyaltyCategory[] | null;
	legal_info?: string | null;
	links?: InlineModel16 | null;
	photos?: string[] | null;
	privacy_policy_url?: string | null;
	title?: string | null;
}

interface Payment {
	amount: number;
	bank_card_external?: BankCardExternalInfo | null;
	change?: number | null;
	currency?: string | null;
	customer_fee_amount?: number | null;
	external_info?: PaymentExternalInfo | null;
	id?: string | null;
	mobileapp?: MobileAppInfo | null;
	receipt?: number | null;
	status?: string | null;
	type: string;
}

interface Pos {
	additional_services?: AdditionalService[] | null;
	additional_services_required?: number | null;
	addresses?: string | null;
	categories?: LoyaltyCategory[] | null;
	country?: string | null;
	id?: string | null;
	links?: string[] | null;
	maximum_service_distance?: number | null;
	phone_prefix?: string | null;
	phone_prefix_local?: string | null;
	phones?: PhoneNumber[] | null;
	photos?: Image[] | null;
	title?: string | null;
	type?: string | null;
}

export type CreditLimit =
	| {
			credit_type: "debit" | "credit-unlimited";
	  }
	| {
			credit_type: "credit";
			credit_limit: number;
	  };

export type CreditType = CreditLimit["credit_type"];

export type SpecialAccountType = "money" | "goods" | "punches";

export type SpecialAccount = {
	active: boolean;
	corporate: boolean;
	currency?: string | null;
	goods_items?: string[] | null;
	id?: string | null;
	public_title?: string | null;
	title?: string | null;
	type: SpecialAccountType;
	unconfirmed_redeem_amount?: number | null;
} & CreditLimit;

export interface SpecialAccountCharge {
	amount: number;
	currency?: string | null;
	id: string;
	type?: string | null;
	title: string | null;
}

interface TransactionReceiptSendTo {
	email: string;
}

interface UserExternalForm {
	created?: string | null;
	external_form_id?: string | null;
	file?: string | null;
	form_data?: Record<string, any> | null;
	id?: string | null;
	signature?: string | null;
	signed?: boolean | null;
	title?: string | null;
	type?: string | null;
	user_data?: Record<string, any> | null;
	user_id?: string | null;
}

interface TransactionReceiptActions {
	print_on_terminal?: boolean | null;
	send_to_customer?: TransactionReceiptSendTo | null;
}

interface CheckUserExternalFormRelations {
	required?: UserExternalForm[] | null;
}

export interface IIncustCheck extends IIncustCheckBase {
	amount_decimal_digits?: number | null;
	avi_id?: string | null;
	billing_info?: BillingInfo | null;
	bonuses_added?: BonusAdded[] | null;
	bonuses_added_amount?: number | null;
	bonuses_decimal_digits?: number | null;
	business?: CheckBusiness | null;
	calculated_margins?: CheckMargins | null;
	calculated_taxes?: CheckTaxes | null;
	cardotppassword?: string | null;
	check_items?: IIncustCheckItem[] | null;
	check_services?: CheckService[] | null;
	comment?: string | null;
	corporate_customer_id?: string | null;
	corporate_customer_special_account_id?: string | null;
	corporate_vehicle?: CorporateVehicleInfo | null;
	customer_id?: string | null;
	discount_amount?: number | null;
	emitted_coupons?: IIncustCoupon[] | null;
	external_additional_info?: { [key: string]: string | number | boolean } | null;
	external_check_id?: string | null;
	external_check_id_scope?: string | null;
	id?: string | null;
	id_type?: string | null;
	implemented_rules?: LoyaltyRule[] | null;
	loyalty?: Loyalty | null;
	manual_rules?: boolean | null;
	odometer?: number | null;
	odometer_unit?: string | null;
	online_store_order_id?: string | null;
	payment_id: string;
	payment_type: string;
	payments?: Payment[] | null;
	pos?: Pos | null;
	preliminary_transaction_id?: string | null;
	preliminary_transaction_type?: string | null;
	redeemed_coupons?: IIncustCoupon[] | null;
	route_operation_id?: string | null;
	services_amount?: number | null;
	shipping_amount?: number | null;
	special_account?: SpecialAccount | null;
	special_account_odometer_required?: string | null;
	special_account_pin?: string | null;
	special_account_pin_required?: boolean | null;
	special_account_required_values_verify?: boolean | null;
	special_account_vehicle_id_required?: string | null;
	special_accounts_charges?: SpecialAccountCharge[] | null;
	special_accounts_decimal_digits?: number | null;
	summary_amount?: number | null;
	tanks?: CheckTank[] | null;
	transaction_cancelled?: number | null;
	transaction_id?: string | null;
	transaction_receipt_actions?: TransactionReceiptActions | null;
	transaction_subtype?: string | null;
	transaction_type?: string | null;
	user_external_forms?: CheckUserExternalFormRelations | null;
	vehicle_id?: string | null;
}

interface IncustTransactionSpecialAccount {
	currency: string | null;
	id: string | null;
	title: string | null;
	type: string | null;
}

interface IncustTransactionCheck {
	amount: number;
	amount_decimal_digits: number;
	bonuses_decimal_digits: number;
	comment: string | null;
	customer_id: string | null;
	id: string | null;
	id_type: string | null;
	payment_id: string | null;
	services_amount: number;
	shipping_amount: number;
	special_account: IncustTransactionSpecialAccount | null;
	special_accounts_decimal_digits: number;
	summary_amount: number;
	transaction_cancelled: number;
	transaction_id: string | null;
	transaction_type: string | null;
}

export interface IncustTransaction {
	amount: number;
	amount_decimal_digits: number;
	amount_to_pay: number;
	bonuses_added: number;
	bonuses_decimal_digits: number;
	bonuses_on_account: number;
	bonuses_redeemed: number;
	business_customer_id: string;
	canceled: boolean;
	cancelled: number;
	check: IncustTransactionCheck | null;
	corporate_customer_email: string | null;
	corporate_customer_name: string | null;
	customer_special_account_id: string;
	description: string | null;
	discount_amount: number;
	finalize_transaction_id: string;
	finalized: boolean;
	id: string;
	loyalty_id: string;
	payment_type: string;
	processed: string;
	processed_local: string;
	services_amount: number;
	shipping_amount: number;
	special_account_amount: number;
	special_account_currency: string | null;
	special_account_id: string | null;
	special_account_title: string | null;
	special_account_type: string | null;
	special_accounts_decimal_digits: number;
	summary_amount: number;
	title: string | null;
	type: string;
	user_email: string | null;
	user_external_id: string;
	user_name: string;
	user_phone: string | null;
}

export type ModalData = {
	type: "card";
	accountData?: null;
	cardData: IncustPayConfiguration;
};

export interface IIncustCouponExt {
	coupon: IIncustCoupon;
	is_in_wallet: boolean;
	message: string | null;
	transaction: IncustTransaction | null;
}

export enum ApplyCouponType {
	USED = "used",
	SUCCESS = "success",
}

export interface IApplyCouponInfo {
	type: ApplyCouponType;
	show: boolean;
	title: string | null | undefined;
}

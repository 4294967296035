import {useEffect, useRef} from 'react'


interface StickyComponentProps {
    children?: any
    enabled?: boolean
    callback?: ((state: boolean) => any) | null
    customClasses?: string
    wrapperClasses?: string
    onBecomeSticky?: (el: HTMLDivElement) => any
    onBecomeAbsolute?: (el: HTMLDivElement) => any
    onBecomeStatic?: (el: HTMLDivElement) => any
}

const headerHeight = 88

export default function StickyComponent({
    children, enabled = true,
    customClasses = '',
    wrapperClasses = 'h-100',  // h-100 or flex-1 (with d-flex and flex-column in parent) must be specified,
    onBecomeSticky,
    onBecomeAbsolute,
    onBecomeStatic,
}: StickyComponentProps){
    const elementRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!enabled) {
            return elementRef.current?.classList.remove('is-sticky')
        }

        const listener = () => {
            const el = elementRef.current
            if (!el || !el.parentElement) return

            const topAlerts = document.getElementsByClassName('top-alert')
            const stickyContainer = el.parentElement
            let overHeight = 0
            // if(topAlerts.length){
            //     for(let i = 0; i < topAlerts.length; i++){
            //         // @ts-ignore
            //         overHeight += topAlerts[i].offsetHeight
            //     }
            //
            //     if(overHeight > 0) {
            //         overHeight += 20 * topAlerts.length
            //     }
            // }

            const wasSticky = el.classList.contains('is-sticky')
            const wasAbsolute = !wasSticky && el.style.position === 'absolute'

            el.classList.remove('is-sticky')
            el.style.width = ''
            el.style.position = ''
            el.style.bottom = ''


            const rect = el.getBoundingClientRect()
            const stickyContainerRect = stickyContainer.getBoundingClientRect()

            const availableScrollHeight = stickyContainerRect.height - rect.height
            const scrolled = headerHeight + overHeight - rect.top

            if (scrolled > availableScrollHeight) {
                if (availableScrollHeight) {
                    if (!wasAbsolute && onBecomeAbsolute) {
                        onBecomeAbsolute(el)
                    }
                    el.style.bottom = '0'
                    el.style.position = 'absolute'
                    el.style.width = '100%'
                }
            } else if (rect.top < headerHeight + overHeight) {
                if (!wasSticky && onBecomeSticky) {
                    onBecomeSticky(el)
                }
                el.classList.add('is-sticky')
                el.style.width = rect.width + 'px'
            } else if ((wasSticky || wasAbsolute) && onBecomeStatic) {
                onBecomeStatic(el)
            }
        }

        window.addEventListener('scroll', listener)
        window.addEventListener('resize', listener)

        return () => {
            window.removeEventListener('scroll', listener)
            window.removeEventListener('resize', listener)
        }
    }, [enabled])

    return (
        <div className={`${wrapperClasses} position-relative`}>
            <div ref={elementRef} className={`${customClasses} px-1 py-0 overflow-visible`}
            >
                {children}
            </div>
        </div>
    )
}

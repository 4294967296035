import IncustPayButton from "./IncustPayButton";
import { IncustPayConfiguration, IncustPayPaymentData } from "../../../../api/shop/payments/types";
import { useEffect, useMemo, useState } from "react";
import { IncustPayAccountData, PaymentObjectInfo } from "./types";
import { Box } from "@mui/material";
import IncustPayModal from "./modals/IncustPayModal";
import IncustPayError from "./IncustPayError";
import IncustPayCardButton from "./IncustPayCardButton";
import IncustPayCardModal from "./modals/IncustPayCardModal";
import { PaymentMethod } from "../../../../api/shop/basic/types";
import { IUsePaymentsService } from "../../hooks/useNewPaymentsService";

type IncustPayProps = {
	amountToPay: number;
	incustPay: IncustPayPaymentData;
	toShow?: boolean | null;
	noModals?: boolean;
	externalContentData?: IncustContentData | null;
	setExternalContentData?: (data: IncustContentData | null) => void;
	getIncustPaymentIcon?: (settingsId: number) => string;
	totalAmount: number;
	currency: string;
	getIncustMethod: (settingsId: number) => PaymentMethod | undefined;
	topupAccountId?: string;
	payButtonMaxHeight: number | null;
	setPayButtonMaxHeight: (value: number) => void;
	anyFee?: boolean;
	paymentService: IUsePaymentsService;
} & PaymentObjectInfo;

export type IncustContentData =
	| {
			type: "account";
			accountData: IncustPayAccountData;
			cardData?: null;
	  }
	| {
			type: "card";
			accountData?: null;
			cardData: IncustPayConfiguration;
	  };

export default function IncustPay(props: IncustPayProps) {
	const { incustPay, noModals } = props;

	const [internalModalData, setInternalModalData] = useState<IncustContentData | null>(null);

	const modalData = useMemo(() => {
		if (props.externalContentData !== undefined) {
			return props.externalContentData;
		}
		return internalModalData;
	}, [internalModalData, props.externalContentData]);

	const setModalData = useMemo(() => {
		if (props.setExternalContentData !== undefined) {
			return props.setExternalContentData;
		}
		return setInternalModalData;
	}, [props.setExternalContentData]);

	const setIgnoreIncustFee = props.paymentService.setIgnoreIncustFee;
	useEffect(() => {
		if (incustPay.incust_pay_list.length === 1) {
			if (incustPay.incust_pay_list[0].specials.length === 1) {
				if (incustPay.incust_pay_list[0].specials[0].id === props.topupAccountId) {
					setIgnoreIncustFee(true);
				}
			}
		}
	}, [incustPay.incust_pay_list, props.topupAccountId, setIgnoreIncustFee]);

	useEffect(() => {
		if (
			props.paymentService.paymentsInfo?.single_payment_method?.provider === "incust_pay" ||
			props.paymentService.forceSingleMethod?.provider === "incust_pay"
		) {
			if (
				incustPay?.incust_pay_list?.length === 1 &&
				incustPay?.incust_pay_list[0].specials?.length === 1 &&
				!incustPay.incust_pay_list.some(
					x => x.incust_pay_configuration.card_payment_enabled
				)
			) {
				setModalData({
					type: "account",
					accountData: {
						type: "special-account",
						incust_pay_configuration:
							incustPay?.incust_pay_list[0].incust_pay_configuration,
						special: incustPay?.incust_pay_list[0].specials[0],
					},
				});
			}
		}
	}, [
		incustPay.incust_pay_list,
		props.paymentService.forceSingleMethod?.provider,
		props.paymentService.paymentsInfo?.single_payment_method?.provider,
		setModalData,
	]);

	// if (!props.toShow && !incustPay.payment_token) return null;
	if (!props.toShow) return null;

	// console.log("incust-pay", props.incustPay);

	return (
		<Box
			sx={{
				"div:last-child": {
					borderBottom: 0,
					button: {
						borderBottom: 0,
					},
				},
			}}
		>
			{incustPay.incust_pay_list
				.filter(x => x.total_accounts_count > 0)
				.map(incust_pay =>
					incust_pay.specials.map(
						special =>
							!!special.amount &&
							special.id !== props.topupAccountId && (
								<IncustPayButton
									anyFee={!!props.anyFee}
									payButtonMaxHeight={props.payButtonMaxHeight}
									setPayButtonMaxHeight={props.setPayButtonMaxHeight}
									totalAmount={props.totalAmount}
									currency={props.currency}
									method={props.getIncustMethod(
										incust_pay.incust_pay_configuration.payment_settings_id
									)}
									logo={
										props.getIncustPaymentIcon
											? props.getIncustPaymentIcon(
													incust_pay.incust_pay_configuration
														.payment_settings_id
												)
											: null
									}
									key={special.id}
									data={{
										type: "special-account",
										incust_pay_configuration:
											incust_pay.incust_pay_configuration,
										special: special,
									}}
									setData={(data: IncustPayAccountData) =>
										setModalData({
											type: "account",
											accountData: data,
										})
									}
								/>
							)
					)
				)}

			{incustPay.incust_pay_list.map(incust_pay =>
				incust_pay.corporate_special_accounts_access.map(
					corporate_special_account_access => (
						<IncustPayButton
							payButtonMaxHeight={props.payButtonMaxHeight}
							setPayButtonMaxHeight={props.setPayButtonMaxHeight}
							totalAmount={props.totalAmount}
							currency={props.currency}
							method={props.getIncustMethod(
								incust_pay.incust_pay_configuration.payment_settings_id
							)}
							logo={
								props.getIncustPaymentIcon
									? props.getIncustPaymentIcon(
											incust_pay.incust_pay_configuration.payment_settings_id
										)
									: null
							}
							key={corporate_special_account_access.id}
							data={{
								incust_pay_configuration: incust_pay.incust_pay_configuration,
								type: "corporate-special-account-access",
								corporate_special_account_access: corporate_special_account_access,
							}}
							setData={(data: IncustPayAccountData) =>
								setModalData({
									type: "account",
									accountData: data,
								})
							}
						/>
					)
				)
			)}

			{incustPay.incust_pay_list.map(incust_pay => {
				if (!incust_pay.incust_pay_configuration.card_payment_enabled) return null;

				return (
					<IncustPayCardButton
						anyFee={!!props.anyFee}
						payButtonMaxHeight={props.payButtonMaxHeight}
						setPayButtonMaxHeight={props.setPayButtonMaxHeight}
						totalAmount={props.totalAmount}
						currency={props.currency}
						method={props.getIncustMethod(
							incust_pay.incust_pay_configuration.payment_settings_id
						)}
						logo={
							props.getIncustPaymentIcon
								? props.getIncustPaymentIcon(
										incust_pay.incust_pay_configuration.payment_settings_id
									)
								: null
						}
						key={incust_pay.incust_pay_configuration.id}
						data={incust_pay.incust_pay_configuration}
						setData={(data: IncustPayConfiguration | null) => {
							if (data) {
								setModalData({
									type: "card",
									cardData: data,
								});
							} else {
								setModalData(null);
							}
						}}
					/>
				);
			})}

			{incustPay.incust_pay_errors_list.map(incust_pay_error => (
				<IncustPayError
					key={incust_pay_error.incust_pay_configuration.id}
					incust_pay_error={incust_pay_error}
				/>
			))}

			{!noModals && (
				<>
					<IncustPayModal
						amountToPay={props.amountToPay}
						modalData={modalData?.accountData ?? null}
						setModalData={(data: IncustPayAccountData | null) => {
							if (data) {
								setModalData({
									type: "account",
									accountData: data,
								});
							} else {
								setModalData(null);
							}
						}}
						type={props.type}
						selectedStoreId={props.selectedStoreId}
						orderId={props.orderId}
						orderToken={props.orderToken}
						invoiceToken={props.invoiceToken}
					/>

					<IncustPayCardModal
						amountToPay={props.amountToPay}
						modalData={modalData?.cardData ?? null}
						setModalData={(data: IncustPayConfiguration | null) => {
							if (data) {
								setModalData({
									type: "card",
									cardData: data,
								});
							} else {
								setModalData(null);
							}
						}}
						type={props.type}
						selectedStoreId={props.selectedStoreId}
						orderId={props.orderId}
						orderToken={props.orderToken}
						invoiceToken={props.invoiceToken}
					/>
				</>
			)}
		</Box>
	);
}

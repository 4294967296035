


export default function CheckInfoLine(
    {
        enabled = true,
        newLine = true,
        children,
    }: {
        enabled?: boolean,
        newLine?: boolean,
        children: any,
    }
) {
    if (!enabled || !children) {
        return null
    }

    return (<>
        {children}
        {newLine && <br/>}
    </>)
}

import {ReactNode, useEffect, useMemo, useState, useRef, useCallback} from "react";
import {Box, ButtonBase, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import {AttributeError, AttributesViewType} from "../types";
import {Attribute, AttributeGroup} from "../../../../../api/shop/basic/types";
import useAppContext from "../../../../../useAppContext";
import {CreateCartAttributeData} from "../../../../../api/shop/cart/types";
import ChipButton from "../../../../../features/ChipButton";
import {SetState} from "../../../../../types";

interface IAttributeItemProps {
    type: AttributesViewType
    selected: boolean
    changeCallback: (
        attrId: number | null,
        quantity: number | '+1' | '-1' | 'check',
        radio?: boolean,
        forceUnselect?: boolean,
    ) => void
    attribute: Attribute | null
    price: string
    quantity: number
    attributeGroup: AttributeGroup
    selectedAttributes: CreateCartAttributeData[]
    attrError: AttributeError[] | null
    setAttrError: SetState<AttributeError[] | null>
    setErrorAnchor: SetState<any>
    setPopoverText: SetState<string | null>
    popoverText: string | null
}

interface IAttributeItemContent extends IAttributeItemProps {
    computedAttrError: AttributeError | null | undefined
    computedGroupError: AttributeError | null | undefined
}

export default function AttributeItem(props: IAttributeItemProps) {
    const computedAttrError = useMemo(() => {
        if(props.attrError){
            return props.attrError
                .find(x => x.attr.attribute_id === props.attribute?.attribute_id && !x.isGroupError)
        }
        return null
    }, [props.attrError, props.attribute?.attribute_id])

    const computedGroupError = useMemo(() => {
        if(props.attrError){
            return props.attrError
                .find(x => x.attr.attribute_group_id === props.attributeGroup.id && x.isGroupError)
        }
        return null
    }, [props.attrError, props.attributeGroup.id])

    if(props.type === AttributesViewType.Radio || props.type === AttributesViewType.RadioWithDefault) {
        return <AttributeItemRadio
            {...props} computedAttrError={computedAttrError} computedGroupError={computedGroupError} />
    }
    else {
        return <AttributeItemCheckBox
            {...props} computedAttrError={computedAttrError} computedGroupError={computedGroupError} />
    }
}

function AttributeItemRadio(props: IAttributeItemContent) {
    const {localisation: {global}} = useAppContext()
    const [selectedNothing, setSelectedNoting] = useState<boolean>(false)

    const handleChange = () => {
        if(props.selected || selectedNothing) return
        props.changeCallback(props.attribute?.id || null, "check", true)
    }

    useEffect(() => {
        if(!props.attribute && props.attributeGroup.attributes
            .filter(x => props.selectedAttributes
                .some(y => y.attribute_id === x.id)).length === 0
        ){
            setSelectedNoting(true)
        }
        else {
            setSelectedNoting(false)
        }
    }, [props.attribute, props.attributeGroup.attributes, props.selectedAttributes])

    return (
        <Box display={'flex'}>
            <AttributeItemContent
                name={props.attribute?.name || global.notSelected}
                price={props.price}
            />
            <Box ml={2}>
                <AttributeButtonWrapper
                    selectedNothing={selectedNothing}
                    handleClick={handleChange}
                    smallIcon={true}
                    {...props}
                >
                    {(props.selected || selectedNothing) ? (
                        <RadioButtonCheckedIcon fontSize={'small'} />
                    ): (
                        <RadioButtonUncheckedIcon fontSize={'small'} />
                    )}
                </AttributeButtonWrapper>
            </Box>
        </Box>
    )
}

function AttributeItemCheckBox(props: IAttributeItemContent) {
    const {localisation: {global}} = useAppContext()

    const handleChange = () => {
        let quantity: "check" | "+1" | "-1"
        let needUnselect = false
        if(!props.selected){
            quantity = "+1"
        }
        else {
            quantity = "-1"
            if(props.quantity > 1) needUnselect = true
        }
        props.changeCallback(props.attribute?.id || null, quantity, false, needUnselect)
    }

    return (
        <>
            <Box display={'flex'}>
                <AttributeItemContent
                    name={props.attribute?.name || global.notSelected}
                    price={props.price}
                />
                {!!(props.quantity >= 1 && props.attribute?.max && props.attribute?.max > 1) ? (
                    <Box>
                        <AttributeItemQuantity {...props} />
                    </Box>
                ): (
                    <Box ml={2}>
                        <AttributeButtonWrapper
                            {...props}
                            handleClick={handleChange}
                            disabled={
                                props.computedGroupError?.isGroupError &&
                                !props.computedGroupError.isMinus &&
                                !props.selected
                            }
                        >
                            {(props.selected) ? (
                                <CheckIcon />
                            ): (
                                <AddIcon />
                            )}
                        </AttributeButtonWrapper>
                    </Box>
                )}
            </Box>
        </>
    )
}

interface IAttributeItemContentProps {
    name: string
    price: string
}

function AttributeItemContent(props: IAttributeItemContentProps) {
    return (
        <Box display={'flex'} sx={{width: '100%'}}>
            <Typography alignSelf={'center'}>
                {props.name}
            </Typography>
            <Typography color={'text.secondary'} alignSelf={'center'} sx={{ml: 'auto'}}>
                {props.price}
            </Typography>
        </Box>
    )
}

function AttributeItemQuantity(props: IAttributeItemContent) {
    const [disabledPlus, setDisabledPlus] = useState<boolean>(false)

    const handlePlus = () => {
        if(disabledPlus) return
        if(props.quantity + 1 === props.attribute?.max){
            setDisabledPlus(true)
        }
        props.changeCallback(props.attribute?.id || null, '+1')
    }

    const handleMinus = () => {
        props.changeCallback(props.attribute?.id || null, '-1')
    }

    const setAttrError = props.setAttrError
    useEffect(() => {
        if(props.attribute?.max && (disabledPlus && props.quantity < props.attribute?.max)){
            setDisabledPlus(false)
        }
    }, [
        disabledPlus, setAttrError, props.attribute?.max,
        props.quantity, props.attribute?.attribute_id,
        props.attrError, props.computedAttrError,
    ])

    return (
        <Box ml={2} display={'flex'}>
            <AttributeButtonWrapper
                {...props}
                handleClick={handleMinus}
                isMinus={true}
                isQuantity={true}
                disabled={props.computedAttrError?.isMinus ||
                    (props.computedGroupError?.isGroupError && props.computedGroupError.isMinus)}
            >
                <RemoveIcon/>
            </AttributeButtonWrapper>

            <Typography fontWeight={'bold'} fontSize={'large'} sx={{mx: 2}} alignSelf={'center'}>
                {props.quantity}
            </Typography>

            <AttributeButtonWrapper
                handleClick={handlePlus}
                isMinus={false}
                isQuantity={true}
                disabled={disabledPlus ||
                    (props.computedGroupError?.isGroupError && !props.computedGroupError.isMinus)}
                {...props}
            >
                <AddIcon/>
            </AttributeButtonWrapper>
        </Box>
    )
}

interface IAttributeButtonWrapperProps extends IAttributeItemContent {
    children: ReactNode
    selected: boolean
    handleClick: () => void
    disabled?: boolean
    selectedNothing?: boolean
    isMinus?: boolean
    isQuantity?: boolean
    smallIcon?: boolean
}

function AttributeButtonWrapper(props: IAttributeButtonWrapperProps) {
    const [mouseOutTrigerred, setMouseOutTrigerred] =
        useState<boolean>(false)
    const btnRef = useRef<HTMLDivElement | null>(null)
    const [localPopoverText, setLocalPopoverText] = useState<string | null>(null)

    const setPopoverText = props.setPopoverText
    const setErrorAnchor = props.setErrorAnchor
    const onMouseOver = useCallback(() => {
        setMouseOutTrigerred(false)
        if((props.computedAttrError || props.computedGroupError) && props.disabled){
            if(!props.computedGroupError &&
                props.computedAttrError &&
                (props.computedAttrError.isMinus === !!props.isMinus || !props.isQuantity)
            ){
                setPopoverText(props.computedAttrError?.message || "")
                setLocalPopoverText(props.computedAttrError?.message || "")
            }
            else {
                let text = ""
                if(props.computedGroupError &&
                    (props.computedGroupError.isMinus === props.isMinus || !props.isQuantity)
                ){
                    text += props.computedGroupError?.message || ""
                }

                if(props.computedAttrError && props.computedAttrError &&
                    (props.computedAttrError.isMinus === !!props.isMinus || !props.isQuantity)
                ){
                    text += "<br/><br/>" + props.computedAttrError?.message || ""
                }

                setPopoverText(text)
                setLocalPopoverText(text)
            }

            setErrorAnchor && setErrorAnchor(btnRef.current)
        }
        else{
            setPopoverText(null)
            setLocalPopoverText(null)
        }
    }, [
        props.computedAttrError, props.computedGroupError,
        props.disabled, props.isMinus, props.isQuantity,
        setErrorAnchor, setPopoverText,
    ])

    const onMouseOut = () => {
        setMouseOutTrigerred(true)
        props.setPopoverText(null)
        setLocalPopoverText(null)
        props.setErrorAnchor && props.setErrorAnchor(null)
    }

    const onClick = () => {
        if(props.disabled) return
        props.handleClick()
    }

    useEffect(() => {
        if((props.computedAttrError || props.computedGroupError) && !localPopoverText && !mouseOutTrigerred){
            onMouseOver()
        }
    }, [mouseOutTrigerred, onMouseOver, props.computedAttrError, props.computedGroupError, localPopoverText])

    return (
        <Box
            key={props.popoverText}
            ref={btnRef}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <ButtonBase
                sx={{borderRadius: '0.5rem'}}
                disableRipple={props.disabled}
            >
                <ChipButton
                    disabled={props.disabled}
                    selected={props.selected || props.selectedNothing}
                    onClick={onClick}
                    selectedInsetShadowWidth={1}
                    chipProps={{
                        sx: {
                            py: props.smallIcon ? '4px' : 1,
                            display: 'block',
                            '.MuiChip-label': {
                                p: props.smallIcon ? '6px' : 1,
                            },
                        }
                    }}
                >
                    {props.children}
                </ChipButton>
            </ButtonBase>
        </Box>
    )
}

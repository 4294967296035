import { LocaleType } from "../services/useLocalisationService/types";

export interface ITopupLocale extends LocaleType {
	notificationError: string;
	card: string;
	account: string;
	charge: string;
	topupHeader: string;
	topupButton: string;
	balanceText: string;
	anotherCard: string;
	enterCardForAccounts: string;
	incustPayItem: string;
	loyaltyConfigError: string;
	accountsNotFound: string;
	selectHeader: string;
	corporateNote: string;
	willBeTopUpped: string;
	topupAccountHeader: string;
	successMsg: string;
	emptyAccountsList: string;
	noAuth: string;
	oneAccountHeader: string;
	cantTopup: string;
}

export const TopupLocale: ITopupLocale = {
	notificationError: "loyalty topup notification error",
	card: "loyalty topup card title",
	account: "special account title",
	charge: "loyalty topup charge title",
	topupHeader: "loyalty topup account header text",
	topupButton: "loyalty topup account button",
	balanceText: "loyalty topup balance header text",
	anotherCard: "loyalty topup another card text",
	enterCardForAccounts: "loyalty topup enter card to choose account text",
	incustPayItem: "loyalty topup incust pay item text",
	loyaltyConfigError: "loyalty topup not provided incust pay config error",
	accountsNotFound: "loyalty topup no accounts error",
	selectHeader: "loyalty topup choose account text",
	corporateNote: "loyalty topup corporate note text",
	willBeTopUpped: "loyalty topup will be top upped text",
	topupAccountHeader: "loyalty topup account topup header text",
	successMsg: "loyalty topup success payment alert text",
	emptyAccountsList: "loyalty topup empty accounts list msg",
	noAuth: "web app incust topup not auth user msg",
	oneAccountHeader: "web app topup one account header",
	cantTopup: "web app topup can not topup header",
};

import useConsentInfo from "./useConsentInfo";
import { Typography } from "@mui/material";
import useAppContext from "../../../useAppContext";
import Interweave from "../../../features/Interweave";

export default function ConsentInfo({ isOfferEnabled }: { isOfferEnabled: boolean }) {
	const { brandInfo } = useAppContext();
	const consentInfo = useConsentInfo(isOfferEnabled);

	if (brandInfo?.consent_mode !== "info") return null;

	return (
		<Typography sx={{ color: "text.secondary", mt: 2 }} variant={"body2"}>
			<Interweave className={"user-select-none"} content={consentInfo} />
		</Typography>
	);
}

import { IIncustCoupon } from "../../types";
import useAppContext from "../../../../useAppContext";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PictureAsPdf } from "@mui/icons-material";
import f from "../../../../helpers/formatText";
import BaseCouponDesc from "./shared/BaseCouponDesc";
import { ApplyButton } from "./shared/ApplyButton";

export function CouponInfoContent(props: {
	coupon: IIncustCoupon;
	insideCoupon: boolean;
	setIsInsideCouponTrue: () => void;
	handleDownload: () => Promise<void>;
	isLoadingPdf: boolean;
	inWallet: boolean;
	handleApplyCoupon: () => unknown;
	isAddCouponToWalletLoading: boolean;
	isShowApplyButton: boolean;
}) {
	const {
		localisation: { global },
	} = useAppContext();

	return (
		<>
			{props.coupon.description && (
				<Typography
					mt={1}
					mb={1}
					variant={"body1"}
					color={{ sm: "primary.contrastText" }}
					gap={"0px"}
					textAlign={"start"}
				>
					<BaseCouponDesc
						insideCoupon={props.insideCoupon}
						coupon={props.coupon}
						hideShowButton={false}
					/>
				</Typography>
			)}

			<Box>
				{props.isShowApplyButton && (
					<>
						<ApplyButton
							handleApply={props.handleApplyCoupon}
							loading={props.isAddCouponToWalletLoading}
							coupon={props.coupon}
						/>
					</>
				)}

				{props.coupon.type === "external" && (
					<Box mt={2} color={{ sm: "primary.contrastText" }}>
						<LoadingButton
							onClick={props.handleDownload}
							color="inherit"
							size="small"
							endIcon={<PictureAsPdf />}
							loading={props.isLoadingPdf}
							loadingPosition="end"
							sx={{
								"&.Mui-disabled": {
									color: { sm: "primary.contrastText" },
									opacity: 0.7,
								},
							}}
						>
							{f(global.downloadByFormat, {
								file_format: "PDF",
							})}
						</LoadingButton>
					</Box>
				)}
			</Box>
		</>
	);
}

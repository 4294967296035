import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import api from "../../../api";
import RouteRedirect from "../../../helpers/routeRedirect";

export function RedirectOrdersPage() {
    const [searchParams] = useSearchParamsFixed()

    const queryStoreId = searchParams.get('store_id')
    const getRedirectPath = () => {
        const storeId = (queryStoreId && parseInt(queryStoreId)) || api.shop.basic.getLastStoreId()
        if (storeId) {
            return `shop/${storeId}/profile/orders`
        } else {
            return 'shop/select'
        }
    }

    return <RouteRedirect to={getRedirectPath()} params={{
        store_id: null
    }}/>
}

import { useMemo } from "react";

import useAppContext from "../useAppContext";
import { useSelectedStoreOptional } from "../shop/SelectedStore/context";

export default function useCurrency(currency?: string | null) {
	const { brandInfo } = useAppContext();
	const selectedStore = useSelectedStoreOptional();

	return useMemo(() => {
		return currency || selectedStore?.currency || brandInfo?.default_currency || null;
	}, [brandInfo?.default_currency, currency, selectedStore?.currency]);
}

import { ThemeModal } from "../../../../helpers/ThemeComponents";
import { Box, IconButton } from "@mui/material";
import InviteInterface from "../../../../components/InviteInterface/InviteInterface";
import QRCode from "qrcode.react";
import f from "../../../../helpers/formatText";
import formatCurrency from "../../../../helpers/formatCurrency";
import InputWithEndButton from "../../../../features/InputWithEndButton";
import extractDigits from "../../../../helpers/extractDigits";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SendIcon from "@mui/icons-material/Send";
import { QrScanner } from "@yudiel/react-qr-scanner";
import IncustPayCardModal from "../../../payments/PaymentButtons/IncustPay/modals/IncustPayCardModal";
import { IncustPayConfiguration } from "../../../../api/shop/payments/types";
import { useMemo } from "react";
import { IIncustCoupon, IncustTransaction, ModalData } from "../../types";
import useAppContext from "../../../../useAppContext";
import { ExternalCouponCode } from "./Coupon/ExternalCouponCode";

interface IWalletPageModals {
	coupon: IIncustCoupon | null;
	setShowQrCode: (_: boolean) => void;
	showExternalCode: boolean;
	setShowExternalCode: (show: boolean) => void;
	showShareCode: boolean;
	setShowShareCode: (show: boolean) => void;
	showQrCode: boolean;
	showResultApply: boolean;
	setShowResultApply: (show: boolean) => void;
	showAddCoupon: boolean;
	setShowAddCoupon: (show: boolean) => void;
	startScan: boolean;
	setStartScan: (start: boolean) => void;
	showCouponMessage: boolean;
	setShowCouponMessage: (show: boolean) => void;
	addCouponValue: string;
	setAddCouponValue: (value: string) => void;
	couponError: string | null;
	addCouponResultText: string | null;
	modalData: ModalData | null;
	setModalData: (data: ModalData | null) => void;
	isLoading: boolean;
	addCouponButtonHandler: () => void;
	redeemedCouponCheck: IncustTransaction | null;
	onNewScanResult: (value: string) => void;
	shareCode: string | null;
}

export function WalletPageModals(props: IWalletPageModals) {
	const {
		localisation: { profile, global },
		authService: { user },
		bot,
		brandInfo,
	} = useAppContext();

	const {
		coupon,
		showExternalCode,
		setShowExternalCode,
		showShareCode,
		setShowShareCode,
		showQrCode,
		setShowQrCode,
		showResultApply,
		setShowResultApply,
		showAddCoupon,
		setShowAddCoupon,
		startScan,
		setStartScan,
		showCouponMessage,
		setShowCouponMessage,
		addCouponValue,
		setAddCouponValue,
		couponError,
		addCouponResultText,
		modalData,
		setModalData,
		isLoading,
		addCouponButtonHandler,
		redeemedCouponCheck,
		onNewScanResult,
		shareCode,
	} = props;

	const qrBotUrl = useMemo(() => {
		if (bot?.bot_type === "telegram" && user?.messangers?.includes(bot.bot_type)) {
			return `https://t.me/${bot.username}?start=coupon-${shareCode}`;
		}

		if (bot?.bot_type === "whatsapp" && user?.messangers?.includes(bot.bot_type)) {
			return `https://wa.me/${bot.whatsapp_from_phone_number}?text=coupon-${shareCode}`;
		}

		return null;
	}, [bot, user, shareCode]);

	const qrWebUrl = useMemo(() => {
		return `${window.location.origin}/shop/coupon/show/${shareCode}`;
	}, [shareCode]);

	const couponBenefitAmount = useMemo(() => {
		if (coupon) {
			if (
				["regular-bonuses", "promotional-bonuses", "bonuses"].includes(
					coupon.recommendation_fee_type || ""
				)
			) {
				return f(profile.shareCouponFeeBonuses, {
					recommendation_fee_amount: coupon.recommendation_fee_amount,
				});
			}
			if (coupon.recommendation_fee_type === "coupon") {
				return `${coupon.recommendation_fee_coupon_batch_title} ${
					coupon.recommendation_fee_amount ? ": " + coupon.recommendation_fee_amount : ""
				}`;
			}
			if (coupon.recommendation_fee_type === "special-account") {
				return `${coupon.recommendation_fee_special_account_title} + ${coupon.recommendation_fee_amount}`;
			}
		}
	}, [coupon, profile.shareCouponFeeBonuses]);

	return (
		<>
			<ThemeModal
				open={showExternalCode}
				setOpen={setShowExternalCode}
				title={profile.loyaltyCouponRedeemDate}
				contentProps={{ sx: { pt: 2 } }}
			>
				<Box>
					<ExternalCouponCode external_code={coupon?.external_code} />
				</Box>
			</ThemeModal>

			<ThemeModal
				open={showShareCode}
				setOpen={setShowShareCode}
				title={profile.shareCouponTitle}
				contentProps={{ sx: { pt: 2 } }}
			>
				<Box>
					<InviteInterface
						bodyText={profile.shareCouponBody}
						botType={bot?.bot_type}
						linkDescWeb={profile.shareCouponWeb}
						linkDescBot={profile.shareCouponMessenger}
						botUrl={qrBotUrl}
						webUrl={qrWebUrl}
						noMessangerText={global.friendHaveNoMessangerText}
						noMessangerModalTitle={global.friendHaveNoMessangerModalTitle}
						benefitsText={couponBenefitAmount}
					/>
				</Box>
			</ThemeModal>

			<ThemeModal
				open={showQrCode}
				setOpen={setShowQrCode}
				title={profile.loyaltyShowQrCodeBody}
				PaperProps={{
					sx: {
						m: 0,
						p: 0,
						overflow: "hidden",
					},
				}}
			>
				<Box margin={0} width="100%">
					{coupon && coupon.external_code && (
						<QRCode
							value={
								coupon.external_code.length
									? coupon.external_code.toString()
									: coupon.code?.toString() || ""
							}
							style={{ margin: 0 }}
							size={256}
						/>
					)}
				</Box>
			</ThemeModal>

			<ThemeModal
				open={showResultApply}
				setOpen={setShowResultApply}
				title={profile.resultApplyCouponTitle}
				contentProps={{ sx: { pt: 2 } }}
			>
				<Box>
					{redeemedCouponCheck && (
						<>
							{redeemedCouponCheck.bonuses_added > 0 && (
								<>
									{profile.loyaltyBonusesAwardedAfter}:{" "}
									{redeemedCouponCheck.bonuses_added}
								</>
							)}
							<br />

							{redeemedCouponCheck.check?.special_account &&
								redeemedCouponCheck.summary_amount && (
									<div
										dangerouslySetInnerHTML={{
											__html: f(profile.loyaltySpAccountTopped, {
												sp_account_name:
													redeemedCouponCheck.check.special_account.title,
												amount: redeemedCouponCheck.check.special_account
													.currency
													? formatCurrency(
															redeemedCouponCheck.summary_amount,
															brandInfo?.default_lang ||
																user?.lang ||
																"en",
															redeemedCouponCheck.check
																.special_account.currency ?? "UAH"
														)
													: redeemedCouponCheck.summary_amount,
											}),
										}}
									></div>
								)}
						</>
					)}
				</Box>
			</ThemeModal>

			<ThemeModal
				open={showAddCoupon}
				setOpen={setShowAddCoupon}
				title={profile.loyaltyAddVoucherTitle}
				PaperProps={{
					sx: {
						m: 0,
						p: 0,
						overflow: "hidden",
					},
				}}
			>
				<Box margin={0} width="100%">
					<InputWithEndButton
						textFieldProps={{
							sx: { my: 2 },
							label: profile.loyaltyAddVoucher,
							value: addCouponValue,
							onChange: e => {
								setAddCouponValue(extractDigits(e.target.value || ""));
							},
							InputProps: {
								endAdornment: (
									<IconButton onClick={() => setStartScan(true)}>
										<QrCodeScannerIcon fontSize={"small"} />
									</IconButton>
								),
							},
						}}
						inputEndAdornmentProps={{ sx: { gap: 1 } }}
						isLoading={isLoading}
						onEndButtonClick={addCouponButtonHandler}
						endButtonNode={<SendIcon />}
						error={!!couponError}
						helperText={!!couponError ? couponError : ""}
					/>
				</Box>
			</ThemeModal>

			<ThemeModal
				open={startScan}
				setOpen={setStartScan}
				title={profile.loyaltyScanCodeHeader}
				contentProps={{
					sx: { p: 0 },
				}}
				sx={{
					"& .MuiDialog-container .MuiDialog-paper": {
						margin: "8px",
					},
				}}
			>
				{startScan && (
					<QrScanner
						onDecode={result => onNewScanResult(result)}
						onError={error => console.log(error?.message)}
						scanDelay={1000}
					/>
				)}
			</ThemeModal>

			<ThemeModal
				open={showCouponMessage}
				setOpen={setShowCouponMessage}
				title={profile.loyaltyAddVoucherTitle}
				contentProps={{ sx: { pt: 2 } }}
			>
				<Box>{addCouponResultText}</Box>
			</ThemeModal>

			<IncustPayCardModal
				amountToPay={0}
				modalData={modalData?.cardData ?? null}
				setModalData={(data: IncustPayConfiguration | null) => {
					if (data) {
						setModalData({
							type: "card",
							cardData: data,
						});
					} else {
						setModalData(null);
					}
				}}
				type={"invoice"}
				selectedStoreId={null}
				orderId={null}
				orderToken={null}
				invoiceToken={null}
			/>
		</>
	);
}

import { useEffect } from "react";
import { Alert, AlertTitle, Container, Grid } from "@mui/material";
import useAppContext from "../useAppContext";
import { ApiUrl } from "../config";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import Interweave from "../features/Interweave";

export default function ServiceSuspendedError() {
	const { loadingManager, localisation, setPageTitle } = useAppContext();

	useEffect(() => {
		setPageTitle?.(`7Loc`);
	}, [setPageTitle]);

	if (loadingManager.isSomethingLoading || !localisation.isLoaded) return null;

	return (
		<>
			<Container
				sx={{
					display: "flex",
					height: "100vh",
					justifyContent: "center",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<img
							src={`${ApiUrl}/static/images/default-404-image.png`}
							alt="404 Not Found"
							className={"img-fluid"}
							style={{ width: "20rem", borderRadius: "0.5rem" }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							my: 2,
							textAlign: { xs: "center", sm: "start" },
						}}
					>
						<Alert
							severity={"error"}
							icon={<PauseCircleIcon fontSize={"large"} color={"inherit"} />}
						>
							<AlertTitle>
								{localisation.global.billingServiceSuspendedError}
							</AlertTitle>
							<Interweave
								content={localisation.global.billingServiceSuspendedErrorMessage}
							/>
						</Alert>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

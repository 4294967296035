export const createCustomEvent = function <TData = { [key: string]: string }>(
  eventName: string,
  data?: TData
): Event | CustomEvent<TData> {
  if (data) {
    return new CustomEvent<TData>(eventName, { detail: data });
  }

  return new Event(eventName);
};

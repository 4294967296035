import {SpecialAccountCharge} from "./types";


export default function useCalculatedSpecialAccountsCharges(specialAccountsCharges?: SpecialAccountCharge[] | null): SpecialAccountCharge[] | null {
    if (!specialAccountsCharges) {
        return null
    }

    const chargesById: {[key: string]: SpecialAccountCharge} = {}

    specialAccountsCharges.forEach(charge => {
        if (!charge.id) return

        if (!(charge.id in chargesById)) {
            chargesById[charge.id] = {
                amount: charge.amount,
                currency: charge.currency,
                id: charge.id,
                type: charge.type,
                title: charge.title,
            }
        } else {
            chargesById[charge.id].amount += charge.amount
        }
    })

    return Object.values(chargesById)
}

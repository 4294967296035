import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Category, Product, Store } from "../../../api/shop/basic/types";
import api from "../../../api";
import { ProductsServiceType } from "./types";
import { CategoriesServiceType } from "../useCategoriesService/types";
import { SetState } from "../../../types";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import { useShopContext } from "../../context";
import useAppContext from "../../../useAppContext";
import { TabType } from "../../menu/product/DetailedProduct";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";

export default function useProductsService(
	selectedStore: Store | null,
	filtersSetId: number | null,
	categoriesService: CategoriesServiceType,
	search: string | null,
	setSearch: SetState<string | null>
): ProductsServiceType {
	const {
		lang,
		showError,
		loadingManager: { setIsLoading: lmSetIsLoading },
		authService: { user },
		appearance,
	} = useAppContext();
	const { brandInfo } = useShopContext();

	const [searchParams, setSearchParams] = useSearchParamsFixed();

	const [sort, setSort] = useState<"low_price" | "high_price" | "categories">("categories");

	const [isAi, setIsAi] = useState(false);
	const [isEndOfList, setIsEndOfList] = useState(false);

	const lastRequestLang = useRef<string | null>(null);

	const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

	const [isLoaded, setIsLoaded] = useState(false);

	const [hideDetailedProductModal, setHideDetailedProductModal] = useState(false);

	const setIsLangLoading = useCallback(
		(isLoading: boolean) => {
			lmSetIsLoading("products-lang", isLoading);
		},
		[lmSetIsLoading]
	);

	const [flatProducts, setFlatProducts] = useState<Product[]>([]);

	const fetchProducts = useCallback(
		async ({ pageParam }: { pageParam: number | null }) => {
			if (pageParam === null) return [];
			try {
				if (!selectedStore || !brandInfo.id || !lang) {
					setIsLangLoading(false);
					return [];
				}

				const storeId = selectedStore.id;
				const categoryId = categoriesService.selectedCategoryId;

				if (lastRequestLang.current && lastRequestLang.current !== lang) {
					setIsLangLoading(true);
				}

				lastRequestLang.current = lang;

				try {
					const response = await api.shop.basic.getProducts(storeId, {
						category_id: categoryId,
						offset:
							(pageParam || 0) * (appearance?.appearanceQuery?.products_limit || 20),
						limit: appearance?.appearanceQuery?.products_limit || 20,
						filters_set_id: filtersSetId,
						search_text: search,
						sort: sort,
						brand_id: brandInfo.id,
					});

					setIsAi(response.data.is_ai);
					setIsEndOfList(response.data.is_end);

					return response.data.products;
				} catch (err) {
					console.log(err);
					showError(err);
					return [];
				} finally {
					setIsLoaded(true);
					setIsLangLoading(false);
				}
			} catch (err) {
				console.log(err);
				return [];
			}
		},
		[
			selectedStore,
			brandInfo.id,
			lang,
			categoriesService.selectedCategoryId,
			setIsLangLoading,
			appearance?.appearanceQuery?.products_limit,
			filtersSetId,
			search,
			sort,
			showError,
		]
	);

	const {
		data: infiniteProducts,
		fetchNextPage,
		isPending,
		isFetchingNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"products",
			selectedStore?.id,
			categoriesService.selectedCategoryId,
			filtersSetId,
			lang,
			search,
			sort,
			brandInfo.id,
		],
		queryFn: fetchProducts,
		getNextPageParam: (__, _, lastPageParam) => {
			if (isEndOfList) return null;
			return lastPageParam + 1;
		},
		initialPageParam: 0,
		placeholderData: keepPreviousData,
	});

	useEffect(() => {
		const flat = infiniteProducts?.pages.flatMap(x => x);
		setFlatProducts(flat || []);
	}, [infiniteProducts?.pages]);

	// reset search if needed
	useEffect(() => {
		setSearch(null);
	}, [
		selectedStore?.id,
		categoriesService.selectedCategoryId,
		user?.id,
		brandInfo.id,
		brandInfo.bot_id,
		setSearch,
		lang,
	]);

	const getLocalProduct = useCallback(
		(id: number): Product | null => {
			return flatProducts.find(x => x.id === id) ?? null;
		},
		[flatProducts]
	);

	const setProduct = useCallback(
		(product: Product | null, needClear: boolean = true, tab: TabType | null = null) => {
			setSearchParams(prev => {
				const newParams = new URLSearchParams(prev.toString());

				if (product) {
					newParams.set("product_id", product.id.toString());
				} else if (needClear) {
					newParams.delete("product_id");
				}

				if (tab) {
					newParams.set("tab", tab);
				} else {
					newParams.delete("tab");
				}
				return newParams;
			});

			setSelectedProduct(product);
		},
		[setSearchParams]
	);

	const selectProduct = useCallback(
		(product: number | Product | null, tab: TabType | null = null): void => {
			setHideDetailedProductModal(false);
			if (!product) {
				setProduct(null, true, tab);
			} else if (!selectedStore?.id) {
				return;
			} else if (typeof product === "number") {
				const localProduct = getLocalProduct(product);
				if (localProduct) {
					setProduct(localProduct, true, tab);
				} else {
					api.shop.basic
						.getProduct(selectedStore.id, product, {
							brand_id: brandInfo.id,
						})
						.then(response => {
							setProduct(response.data, false, tab);
						});
				}
			} else {
				setProduct(product, true, tab);
			}
		},
		[brandInfo.id, getLocalProduct, selectedStore?.id, setProduct]
	);

	const getProductLink = useCallback(
		(productId: number | null = null) => {
			let url = window.location.origin;
			if (productId) {
				url += `/shop/${selectedStore?.id}/menu/?product_id=${productId}`;
			} else {
				if (selectedProduct) {
					url += `/shop/${selectedStore?.id}/menu/?product_id=${selectedProduct.id}`;
				}
			}
			return url;
		},
		[selectedProduct, selectedStore?.id]
	);

	const isProductInCategory = useCallback((product: Product, category: Category): boolean => {
		return (
			product.categories?.includes(category.id) ||
			category.children?.some(child => isProductInCategory(product, child)) ||
			false
		);
	}, []);

	const canAddProductWithAnotherAttributes = useCallback((product: Product) => {
		return product.attribute_groups.some(x => x.attributes.length > 1);
	}, []);

	const queryProductId = searchParams.get("product_id");

	useEffect(() => {
		const queryProductIdNum = queryProductId ? parseInt(queryProductId) : null;
		if (!queryProductIdNum) {
			selectProduct(null);
		} else if (queryProductIdNum !== selectedProduct?.id) {
			selectProduct(queryProductIdNum);
		}
	}, [selectProduct, queryProductId, selectedProduct?.id]);

	useEffect(() => {
		return () => {
			lmSetIsLoading("products-lang", false);
		};
	}, [lmSetIsLoading]);

	return useMemo(
		() => ({
			isLoading: isPending,
			products: flatProducts || [],
			isFetchingNextPage,
			search,
			setSearch,
			sort,
			setSort,
			selectedProduct,
			selectProduct,
			getLocalProduct,
			isEndOfList,
			isAi,
			isLoaded,
			getProductLink,
			canAddProductWithAnotherAttributes,
			hideDetailedProductModal,
			setHideDetailedProductModal,
			fetchNextPage,
			isProductInCategory,
		}),
		[
			isFetchingNextPage,
			search,
			setSearch,
			sort,
			setSort,
			selectedProduct,
			selectProduct,
			getLocalProduct,
			isEndOfList,
			isAi,
			isLoaded,
			getProductLink,
			canAddProductWithAnotherAttributes,
			hideDetailedProductModal,
			setHideDetailedProductModal,
			fetchNextPage,
			flatProducts,
			isPending,
			isProductInCategory,
		]
	);
}

import { IncustPayPaymentData } from "../../api/shop/payments/types";

export enum PayProviders {
	LiqPay = "liqpay",
	Stripe = "stripe",
	Unipos = "unipos",
	Wave = "wave",
	Ewallet = "ewallet",
	Pl24 = "pl24",
	Tpay = "tpay",
	Orange = "orange",
	Fondy = "fondy",
	Freedompay = "freedompay",
	Flutterwave = "flutterwave",
	Comsa = "comsa",
	Epay = "epay",
	Momo = "momo",
	Directpay = "directpay",
	Tj = "tj",
}

export interface InterfaceSums {
	totalSum: number;
	sumToPay: number;
}

export interface Argument {
	label: string;
	name: string;
	value?: string;
}

export type GoPayDataType = {
	orderId?: number;
	orderToken?: string;
	invoiceId?: number;
	invoiceToken?: string;
};

export interface IUseIncustPayData {
	incustPayData: IncustPayPaymentData | null;
	isLoading: boolean;
	refetchPayData: () => void;
}

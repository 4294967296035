import React from "react";
import { CouponCodeExternal } from "./CouponCodeExternal";
import { CouponResult } from "../CouponResult";
import { ApplyCouponPopup } from "./ApplyCouponPopup";
import { IApplyCouponInfo, IIncustCoupon, IncustTransaction } from "../../../types";

interface CouponAwardsProps {
	isExternalType: boolean;
	coupon: IIncustCoupon;
	applyCouponInfo: IApplyCouponInfo | null;
	redeemedCouponCheck: IncustTransaction | null;
	onCloseApplyPopup: () => unknown;
	isInsideCoupon?: boolean;
}

export default function CouponAwards({
	coupon,
	isExternalType,
	applyCouponInfo,
	redeemedCouponCheck,
	onCloseApplyPopup,
	isInsideCoupon,
}: CouponAwardsProps) {
	return (
		<>
			{isExternalType && !!coupon?.external_code && (
				<CouponCodeExternal
					code={coupon.external_code}
					animate={!!applyCouponInfo?.show}
					isInsideCoupon={isInsideCoupon}
				/>
			)}

			<CouponResult
				addedCoupon={coupon}
				redeemedCouponCheck={redeemedCouponCheck}
				animate={!!applyCouponInfo?.show}
				isInsideCoupon={isInsideCoupon}
			/>

			<ApplyCouponPopup
				type={applyCouponInfo?.type}
				title={applyCouponInfo?.title}
				coupon={coupon}
				showCoupon={applyCouponInfo?.show}
				redeemedCouponCheck={redeemedCouponCheck}
				onClose={onCloseApplyPopup}
			/>
		</>
	);
}

import { AxiosInstance } from "axios";

import {
	IncustPayCardInfo,
	IncustPayPayData,
	IncustPayPaymentData,
	IncustPaySuccess,
	PaymentPayload,
	PaymentProvidersData,
} from "./types";

export default function PaymentsApiModule(instance: AxiosInstance) {
	const getPath = (path: string) => `/payments/${path}`;

	const getHeaders = (token: string | null | undefined) =>
		token ? { Authorization: `Bearer ${token}` } : undefined;

	return {
		makePayment(
			payload: PaymentPayload,
			token?: string | null | undefined,
			payment_method?: string
		) {
			console.log(`payment_method=${payment_method}`);
			return instance.post<PaymentProvidersData>(getPath("make_payment"), payload, {
				headers: getHeaders(token),
				params: {
					payment_method: payment_method,
				},
			});
		},

		getIncustPayCardInfo(
			cardNumber: string,
			storeId?: number | null,
			incustPayConfigurationId?: number | null,
			productId?: number | null
		) {
			return instance.get<IncustPayCardInfo>(getPath(`incustPay/card/${cardNumber}`), {
				headers: { Authorization: "" },
				params: {
					incust_pay_configuration_id: incustPayConfigurationId,
					product_id: productId,
					store_id: storeId,
				},
			});
		},

		payWithIncust(payload: IncustPayPayData) {
			return instance.post<IncustPaySuccess>(getPath("incustPay/pay"), payload, {
				headers: payload.id_type === "card" ? { Authorization: "" } : undefined,
			});
		},

		getIncustPayData(
			storeId?: number | null,
			productId?: number | null,
			isTopup: boolean = false
		) {
			const params: {
				store_id?: number | null;
				is_topup?: boolean;
				product_id?: number | null;
			} = {};
			if (storeId) params["store_id"] = storeId;
			if (isTopup) params["is_topup"] = true;
			if (productId) params["product_id"] = productId;

			return instance.get<IncustPayPaymentData>("/store/payments/incust_pay_data", {
				params: params,
			});
		},
	};
}

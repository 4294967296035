import { useCallback, useEffect, useRef, useState } from "react";

export type SetMergeStateValue<T> =
	| Partial<T>
	| null
	| ((prevState: Partial<T>) => Partial<T> | null);

export type SetMergeState<T extends {}> = (value: SetMergeStateValue<T>) => void;

export default function useMergeState<T extends {} = {}>(initialState: T): [T, SetMergeState<T>] {
	const [state, setState] = useState(initialState);

	const initialStateRef = useRef<T>(initialState);

	useEffect(() => {
		initialStateRef.current = initialState;
	}, [initialState]);

	const setMergeState = useCallback((value: SetMergeStateValue<T>) => {
		setState((prevState: T) => {
			let newState: Partial<T> | null;

			if (typeof value === "function") {
				newState = value(prevState);
			} else {
				newState = value;
			}

			if (newState === null) {
				return initialStateRef.current;
			}

			const newMergeState = { ...prevState, ...newState };
			return Object.fromEntries(
				Object.entries(newMergeState).filter(entry => entry[1] !== undefined)
			) as T;
		});
	}, []);

	return [state, setMergeState];
}

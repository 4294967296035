import { ReactNode, useEffect, useMemo, useState } from "react";
import { Alert, Box, Button } from "@mui/material";
import AgreementCheckbox from "../../../auth/Autorisation/inputs/AgreementCheckbox";
import Interweave from "../../../features/Interweave";
import { TgLink } from "../../../helpers/ThemeComponents";
import useAppContext from "../../../useAppContext";
import { BillingAddress, CreateOrderPayload, Order } from "../../../api/shop/order/types";
import { SetMergeState } from "../../../helpers/useMergedState";
import { SelectedShipmentServiceType } from "../MakeOrder/useSelectedShipmentService";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import { IShipmentPricesService } from "../../services/useShipmentPrices";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { SetState } from "../../../types";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import { ITopupMakeOrderResult, IUseProductTopup } from "../../menu/product/hooks/useProductTopup";
import { IUsePaymentsService } from "../../payments/hooks/useNewPaymentsService";
import MarketingCheckbox from "../../../auth/Autorisation/inputs/MarketingCheckbox";
import ConsentWrapper from "../../../auth/Autorisation/inputs/ConsentWrapper";
import ConsentInfo from "../../../auth/Autorisation/inputs/ConsentInfo";

interface IConfigurationStepLiteProps {
	content: ReactNode;
	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;
	selectedShipmentService: SelectedShipmentServiceType;
	needLoginForEmail: boolean;
	errorText: string | null;
	shipmentPricesService: IShipmentPricesService;
	setPrevStep: () => void;
	setNextStep: () => void;
	paymentsService: IUsePaymentsService;
	billingForm: BillingAddress;
	setNeedLoginForEmail: (needLogin: boolean) => void;
	setErrorText: SetState<string | null>;
	setCreatedOrder: (order: Order | null) => void;
	submit: (form: CreateOrderPayload) => Promise<ITopupMakeOrderResult>;
	createdOrder?: Order | null;
	setShowPayments: (show: boolean) => void;
	service?: IUseProductTopup | null;
}

export default function ConfirmationStepLite(props: IConfigurationStepLiteProps) {
	const {
		localisation,
		authService: { user, setShowAuthorisationModal },
		brandInfo,
	} = useAppContext();
	const { selectedStore } = useSelectedStoreContext();

	const isOfferEnabled = brandInfo?.is_offer_doc_exist || selectedStore.is_offer_doc_exist;

	const setSums = props.paymentsService.setSums;
	useEffect(() => {
		if (props.service) {
			setSums({
				totalSum: props.service.optionsService.computedAmountWithCharge,
				sumToPay: props.service.optionsService.computedAmountWithCharge,
			});
		}
	}, [props.service, setSums]);

	return (
		<Box>
			{props.content}

			{props.selectedShipmentService.selectedShipment?.is_paid_separately && (
				<div className="small fw-bold theme-text">
					{localisation.orders.separateDeliveryMsg}
				</div>
			)}

			<ConsentWrapper>
				<>
					<AgreementCheckbox
						value={props.form.is_accepted_agreement || false}
						setValue={newValue => props.setForm({ is_accepted_agreement: newValue })}
						isOfferEnabled={isOfferEnabled}
					/>
					{!!user &&
						(!user.marketing_consent || brandInfo?.consent_mode === "per_order") && (
							<MarketingCheckbox
								value={!!props.form.marketing_consent}
								setValue={newValue =>
									props.setForm({ marketing_consent: newValue })
								}
							/>
						)}
				</>
			</ConsentWrapper>

			{props.needLoginForEmail && (
				<div className="small fw-bold text-danger">
					<div>{localisation.global.emailExistMsg}</div>

					<div className={"mt-2"}>
						<TgLink
							className={"cursor-pointer"}
							onClick={() => setShowAuthorisationModal(true)}
						>
							{localisation.auth.loginRegisterButton}
						</TgLink>
					</div>
				</div>
			)}

			{!!props.errorText && (
				<Alert severity={"error"} variant={"outlined"} className={"small p-3"}>
					<Interweave content={props.errorText} />
				</Alert>
			)}

			{!!props.shipmentPricesService.error && (
				<Alert severity={"error"} variant={"outlined"} className={"small p-3"}>
					<Interweave content={props.shipmentPricesService.error.text} />
				</Alert>
			)}

			<OrderStepperLastStepButtonsLite
				setPrevStep={props.setPrevStep}
				setNextStep={props.setNextStep}
				selectedShipmentService={props.selectedShipmentService}
				paymentsService={props.paymentsService}
				billingForm={props.billingForm}
				setNeedLoginForEmail={props.setNeedLoginForEmail}
				setErrorText={props.setErrorText}
				setCreatedOrder={props.setCreatedOrder}
				shipmentPricesService={props.shipmentPricesService}
				submit={props.submit}
				form={props.form}
				createdOrder={props.createdOrder}
				setShowPayments={props.setShowPayments}
			/>

			<ConsentInfo isOfferEnabled={isOfferEnabled} />
		</Box>
	);
}

interface IOrderStepperLastStepButtonsLiteProps {
	setPrevStep: () => void;
	setNextStep: () => void;
	selectedShipmentService: SelectedShipmentServiceType;
	paymentsService: IUsePaymentsService;
	billingForm: BillingAddress;
	setNeedLoginForEmail: (needLogin: boolean) => void;
	setErrorText: SetState<string | null>;
	setCreatedOrder: (order: Order | null) => void;
	shipmentPricesService: IShipmentPricesService;
	submit: (form: CreateOrderPayload) => Promise<ITopupMakeOrderResult>;
	form: CreateOrderPayload;
	createdOrder?: Order | null;
	setShowPayments: (show: boolean) => void;
}

function OrderStepperLastStepButtonsLite(props: IOrderStepperLastStepButtonsLiteProps) {
	const localisation = useLocalisation();

	return (
		<Box display={"flex"} gap={1} mt={2}>
			<Button onClick={props.setPrevStep} sx={{ mt: 1, mr: 1 }}>
				{localisation.orders.stepPrev}
			</Button>
			<SubmitOrderButtonLite {...props} />
		</Box>
	);
}

function SubmitOrderButtonLite(props: IOrderStepperLastStepButtonsLiteProps) {
	const {
		localisation,
		authService: { user },
		brandInfo,
	} = useAppContext();

	const [isLoading, setIsLoading] = useState(false);

	const { makePrePayment } = props.paymentsService;

	const submit = async () => {
		try {
			if (props.createdOrder) {
				if (
					(props.paymentsService.paymentsInfo?.single_payment_method?.is_online ||
						props.paymentsService.forceSingleMethod?.is_online) &&
					props.paymentsService.paymentsInfo?.single_payment_method?.need_comment !==
						"required" &&
					props.paymentsService.forceSingleMethod?.need_comment !== "required"
				) {
					try {
						await makePrePayment(
							false,
							null,
							null,
							props.createdOrder.id,
							props.createdOrder.token
						);
						return;
					} catch (err) {
						console.log(err);
					}
				}
				props.setShowPayments(true);
			}
			setIsLoading(true);
			const order = await props.submit(props.form);
			if (order.error) {
				return props.setErrorText(order.error);
			}
			if (order.order) {
				props.setCreatedOrder(order.order);
			}
			if (
				(props.paymentsService.paymentsInfo?.single_payment_method?.is_online ||
					props.paymentsService.forceSingleMethod?.is_online) &&
				props.paymentsService.paymentsInfo?.single_payment_method?.need_comment !==
					"required" &&
				props.paymentsService.forceSingleMethod?.need_comment !== "required"
			) {
				try {
					await makePrePayment(false, null, null, order.order?.id, order.order?.token);
					return;
				} catch (err) {
					console.log(err);
				}
			}
			props.setShowPayments(true);
		} finally {
			setIsLoading(false);
		}
	};

	const isConfirmDisabled = useMemo(() => {
		if (brandInfo?.consent_mode === "info") {
			return !!props.shipmentPricesService.error;
		} else if (brandInfo?.consent_mode === "one_time" && user?.marketing_consent) {
			if (user && user.is_accepted_agreement) {
				return !!props.shipmentPricesService.error;
			}
		}
		return !props.form.is_accepted_agreement || !!props.shipmentPricesService.error;
	}, [props.form.is_accepted_agreement, props.shipmentPricesService.error, user]);

	return (
		<LoadingButton
			onClick={submit}
			variant={"contained"}
			loading={isLoading}
			loadingPosition={"start"}
			startIcon={<CheckIcon />}
			disabled={isConfirmDisabled}
		>
			{props.paymentsService.paymentsInfo?.single_payment_method ||
			props.paymentsService.forceSingleMethod
				? localisation.orders.payButton
				: localisation.global.continue}
		</LoadingButton>
	);
}

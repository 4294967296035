import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MDBContainer } from "mdb-react-ui-kit";

import DetailedProduct from "./DetailedProduct";
import api from "../../../api";
import Loader from "../../../loader/loader";
import { TgLink } from "../../../helpers/ThemeComponents";
import { useShopContext } from "../../context";
import { Product } from "../../../api/shop/basic/types";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import changePage from "../../../helpers/changePage";
import useAppContext from "../../../useAppContext";

export default function ProductPage() {
	const { productId } = useParams();

	const [currentProduct, setCurrentProduct] = useState<Product | null>(null);
	const [isLoading, setIsLoading] = useState(true);

	const { brandInfo } = useShopContext();
	const { setPageTitle, lang, localisation } = useAppContext();

	const {
		selectedStore,
		productsService: { selectProduct },
	} = useSelectedStoreContext();

	const isLang = !!lang;
	const onLoad = useCallback(async () => {
		if (!productId || !isLang) return;

		try {
			const productResponse = await api.shop.basic.getProduct(
				selectedStore.id,
				parseInt(productId),
				{
					brand_id: brandInfo.id,
				}
			);
			const product = productResponse.data;
			setCurrentProduct(product);
			selectProduct(product);

			setPageTitle(`${selectedStore.name} - ${product.name}`);
		} finally {
			setIsLoading(false);
		}
	}, [
		productId,
		isLang,
		selectedStore.id,
		selectedStore.name,
		brandInfo.id,
		selectProduct,
		setPageTitle,
	]);

	useEffect(() => {
		onLoad().then();
	}, [onLoad]);

	if (!currentProduct) return null;

	return (
		<MDBContainer>
			{(isLoading || !lang) && <Loader key={"loader"} show={isLoading} />}

			{selectedStore && currentProduct && !isLoading && (
				<>
					<div className={"fs-3 fw-bold theme-text"}>
						{currentProduct.name} ({selectedStore.name})
					</div>

					<div className={"d-flex mb-2"}>
						<div>
							<TgLink
								className={"cursor-pointer"}
								onClick={() => changePage(`/shop/${selectedStore.id}/menu`)}
							>
								{localisation.global.backHome}
							</TgLink>
						</div>
					</div>

					<DetailedProduct />
				</>
			)}
		</MDBContainer>
	);
}

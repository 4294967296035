import { useEffect, useState } from "react";

import { ThemeModal } from "../../../helpers/ThemeComponents";
import useAppContext from "../../../useAppContext";
import ScanReceipt from "./ScanReceipt";
import { IScanReceiptBase } from "../types";
import useSearchParamsFixed from "../../hooks/useSearchParamsFixed";
import ScanReceiptExitPopConfirm from "./ScanReceiptExitPopConfirm";

export default function ScanReceiptModal(props: IScanReceiptBase) {
	const {
		localisation: { receipt },
		authService: { user },
		loadingManager,
	} = useAppContext();
	const [searchParams, setSearchParams] = useSearchParamsFixed();

	const [showExitPopConfirm, setShowExitPopConfirm] = useState(false);

	const setShowModal = props.service.setShowModal;
	const showModal = props.service.showModal;
	useEffect(() => {
		if (
			searchParams.get("scan_receipt") &&
			!!user &&
			!loadingManager.isSomethingLoading &&
			!showModal
		) {
			setTimeout(() => {
				setShowModal(true);
				setSearchParams(
					new URLSearchParams(searchParams.toString().replace("scan_receipt", ""))
				);
			}, 500);
		}
	}, [
		setShowModal,
		searchParams,
		setSearchParams,
		user,
		loadingManager.isSomethingLoading,
		showModal,
	]);

	return (
		<ThemeModal
			open={props.service.showModal}
			setOpen={(value: boolean) => {
				if (!value && (props.service.isLoading || !!props.service.scannedResult)) {
					return setShowExitPopConfirm(true);
				}
				props.service.setShowModal(value);
			}}
			title={receipt.scanButton}
			onClose={() => {
				if (
					!props.service.isLoading &&
					!showExitPopConfirm &&
					!props.service.scannedResult
				) {
					props.service.setError(null);
					props.service.setOrderId(null);
					props.service.setScannedResult(null);
				}
			}}
		>
			{showModal && !props.service.scannedResult && (
				<ScanReceipt
					{...props}
					showExitPopConfirm={showExitPopConfirm}
					setShowExitPopConfirm={setShowExitPopConfirm}
					setShowModal={setShowModal}
				/>
			)}

			<ScanReceiptExitPopConfirm
				show={showExitPopConfirm}
				setShow={setShowExitPopConfirm}
				confirmCallback={() => {
					setShowExitPopConfirm(false);
					setShowModal(false);
					props.service.setScannedResult(null);
				}}
			/>
		</ThemeModal>
	);
}

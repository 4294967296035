import { Container } from "@mui/material";
import useAppContext from "../../useAppContext";
import NonModalDialog from "./NonModalDialog";
import { NonModalDialogPageProps } from "./types";

export default function NonModalDialogPage({
	containerProps,
	children,
	...props
}: NonModalDialogPageProps) {
	const { headerHeight } = useAppContext();

	return (
		<Container
			maxWidth={"sm"}
			{...containerProps}
			sx={{
				minHeight: `calc(100dvh - ${headerHeight || 0}px - 32px)`,
				display: "flex",
				alignItems: "center",
				paddingBottom: `${headerHeight || 0}px`,
				flex: 1,
				...containerProps?.sx,
			}}
		>
			<NonModalDialog {...props}>{children}</NonModalDialog>
		</Container>
	);
}

import { MDBContainer } from "mdb-react-ui-kit";

import { useShopContext } from "../../context";
import { useSelectedStoreOptional } from "../../SelectedStore/context";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import BrandInfo from "../../BrandInfo";
import LangPicker from "../../../helpers/LangPicker";
import MainSidebarDrawerButton from "../../Sidebars/MainSidebarDrawer/MainSidebarDrawerButton";
import ShopAuthButton from "../../../auth/buttons";
import FavoritesButton from "../../favorites/FavoritesButton";
import CartHeaderButton from "../CartHeaderButton";
import BackToQrMenuButton from "../../qr_menu/BackToQrMenuButton";
import { Box } from "@mui/material";
import useAppContext from "../../../useAppContext";
import { useMemo } from "react";

export default function BaseShopHeaderMobile({ children }: { children?: any }) {
	const { brandInfo } = useShopContext();
	const { appearance } = useAppContext();

	const localisation = useLocalisation();

	const { menuInStoreService } = useShopContext();

	const selectedStore = useSelectedStoreOptional();

	const computedButtonMode = useMemo(() => {
		return (
			appearance.appearanceState?.mobile_cart_button_mode ||
			appearance.appearanceQuery?.mobile_cart_button_mode
		);
	}, [
		appearance.appearanceQuery?.mobile_cart_button_mode,
		appearance.appearanceState?.mobile_cart_button_mode,
	]);

	return (
		<>
			<MDBContainer breakpoint={"xxl"} className={"m-0 p-0 mx-auto"}>
				<nav className={"w-100 mx-0"} style={{ wordBreak: "break-word" }}>
					<Box
						className={"d-flex w-100 m-0 align-items-center px-2 py-1"}
						sx={{ color: "primary.main" }}
					>
						<MainSidebarDrawerButton />

						{brandInfo && <BrandInfo />}

						<div className={"ms-auto d-flex"}>
							{children && children}

							<ShopAuthButton />

							{!!selectedStore && (
								<>
									<FavoritesButton />
									{computedButtonMode === "bar" && <CartHeaderButton />}
								</>
							)}

							<LangPicker
								searchPlaceholder={localisation.global.langSearch}
								selectHeader={localisation.global.langSelect}
							/>
						</div>
					</Box>
					{!!(
						menuInStoreService.menuInStore &&
						!window.location.pathname.includes("qr_menu")
					) && (
						<Box sx={{ button: { borderRadius: 0, boxShadow: "none" } }}>
							<BackToQrMenuButton />
						</Box>
					)}
				</nav>
			</MDBContainer>
		</>
	);
}

import { ApiUrl } from "../config";

export default function setFavicon(url?: string | null | undefined) {
	if (!url) {
		url = `${ApiUrl}/static/images/7loc-favicon-1024.png`;
	}

	let linkElement: HTMLLinkElement | null = document.querySelector('link[rel="icon"]');
	if (!linkElement) {
		linkElement = document.createElement("link");
		linkElement.rel = "icon";
		document.head.appendChild(linkElement);
	}
	linkElement.href = url;
}

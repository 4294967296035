import { IFriend } from "../../api/shop/friends/types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import {
	Box,
	IconButton,
	Link,
	ListItemText,
	Menu,
	MenuItem,
	Radio,
	Typography,
} from "@mui/material";
import { useState, MouseEvent, useCallback } from "react";
import useAppContext from "../../useAppContext";
import ListItemIcon from "@mui/material/ListItemIcon";
import MessangerIcon from "../../features/MessangerIcon";

interface IFriendProps {
	friends: IFriend[] | [];
	handleDelFriendClick: any;
	handleSelectFriendClick?: (checkedFriendUserId: number | null) => any;
	mode: "profile" | "select";
	checkedFriendUserId?: number | null;
}

export default function FriendsList(props: IFriendProps) {
	return (
		<>
			{props.friends.map(friend => (
				<Friend
					key={friend.id}
					friend={friend}
					mode={props.mode}
					checkedFriendUserId={props.checkedFriendUserId}
					handleSelectFriendClick={props.handleSelectFriendClick}
					handleDelFriendClick={props.handleDelFriendClick}
				/>
			))}
		</>
	);
}

interface FriendProps {
	friend: IFriend;
	mode: "profile" | "select";
	checkedFriendUserId?: number | null;
	handleSelectFriendClick?: (checkedFriendUserId: number | null) => any;
	handleDelFriendClick: any;
}

function Friend({
	friend,
	mode,
	handleSelectFriendClick,
	handleDelFriendClick,
	checkedFriendUserId,
}: FriendProps) {
	const { localisation } = useAppContext();

	const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

	const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setMenuAnchorEl(null);
	};

	const selectFriend = useCallback(() => {
		if (handleSelectFriendClick) {
			handleSelectFriendClick(friend.user_id !== checkedFriendUserId ? friend.user_id : null);
		}
	}, [checkedFriendUserId, friend.user_id, handleSelectFriendClick]);

	const checked = friend.user_id === checkedFriendUserId;

	return (
		<Box
			key={friend.id}
			display={"flex"}
			borderBottom={1}
			py={2}
			pl={mode === "select" ? 0 : 2}
			width={"100%"}
			maxWidth={"100%"}
			boxSizing={"border-box"}
			sx={{
				cursor: mode === "select" ? "pointer" : "default",
				userSelect: mode === "select" ? "none" : "auto",
				borderColor: "divider",
				pl: mode === "select" ? 0.25 : 2.25,
				pr: 0.25,
				...(checked
					? {
							boxShadow:
								"inset 2px 0px 0px 0px var(--mui-palette-primary-main), " +
								"inset -2px 0px 0px 0px var(--mui-palette-primary-main)",
						}
					: {
							boxShadow:
								"inset 1px 0px 0px 0px var(--mui-palette-divider), " +
								"inset -1px 0px 0px 0px var(--mui-palette-divider)",
							...(friend.sending_possible
								? {
										"&:hover": {
											boxShadow:
												"inset 2px 0px 0px 0px rgba(var(--mui-palette-primary-mainChannel) / 0.5), " +
												"inset -2px 0px 0px 0px rgba(var(--mui-palette-primary-mainChannel) / 0.5)",
										},
									}
								: {
										cursor: "not-allowed",
									}),
						}),
			}}
			onClick={selectFriend}
			style={{ opacity: friend.sending_possible ? "1" : "0.6" }}
		>
			{mode === "select" && (
				<Radio
					id={friend.id.toString()}
					itemID={friend.id.toString()}
					onChange={selectFriend}
					checked={checked}
					disabled={!friend.sending_possible}
				/>
			)}
			<Box mr={2} alignSelf={"center"}>
				<Avatar src={friend.photo} alt={friend.name} />
			</Box>
			<Box flex={1}>
				<Typography
					sx={{
						whiteSpace: "pre-wrap",
						fontWeight: "bold",
						wordBreak: "break-all",
					}}
				>
					{friend.name}
				</Typography>
				<Typography
					variant={"body2"}
					sx={{
						whiteSpace: "pre-wrap",
						wordBreak: "break-all",
					}}
				>
					{friend.email && <>{friend.email} </>}
				</Typography>
				<Link
					variant={"body2"}
					sx={{
						whiteSpace: "pre-wrap",
						wordBreak: "break-all",
					}}
					href={`https://t.me/${friend.username}`}
					onClick={e => {
						e.stopPropagation();
					}}
					target={"_blank"}
				>
					{friend.username && (
						<>
							<Box component={"span"} fontSize={"1rem"}>
								<MessangerIcon
									botType={"telegram"}
									fontSize={"inherit"}
									sx={{ mr: 1 }}
								/>
							</Box>
							@{friend.username}
						</>
					)}
				</Link>
				<Link
					variant={"body2"}
					sx={{
						whiteSpace: "pre-wrap",
						wordBreak: "break-all",
					}}
					href={`https://wa.me/${friend.wa_phone}`}
					onClick={e => {
						e.stopPropagation();
					}}
					target={"_blank"}
				>
					{friend.wa_phone && (
						<>
							<Box component={"span"} fontSize={"1rem"}>
								<MessangerIcon
									botType={"whatsapp"}
									fontSize={"inherit"}
									sx={{ mr: 1 }}
								/>
							</Box>
							+{friend.wa_phone}
						</>
					)}
				</Link>
			</Box>

			<IconButton onClick={e => handleMenuOpen(e)}>
				<MoreVertIcon fontSize={"small"} color={"inherit"} />
			</IconButton>
			<Menu
				id="friend-menu-button"
				anchorEl={menuAnchorEl}
				open={Boolean(menuAnchorEl)}
				onClose={handleMenuClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<MenuItem
					onClick={() => {
						handleDelFriendClick(friend);
						handleMenuClose();
					}}
					sx={{
						color: "error.main",
					}}
				>
					<ListItemIcon sx={{ color: "inherit!important" }}>
						<DeleteForeverIcon color={"inherit"} fontSize={"small"} />
					</ListItemIcon>
					<ListItemText>{localisation.profile.friendDelete}</ListItemText>
				</MenuItem>
			</Menu>
		</Box>
	);
}

import api from "../api";
import {LoadingManager} from "../features/hooks/useLoadingManager";
import {useQuery} from "@tanstack/react-query";
import {ShortTokenDataType} from "../api/auth/types";
import {useEffect, useState} from "react";
import useSearchParamsFixed from "../features/hooks/useSearchParamsFixed";
import {useParams} from "react-router-dom";
import changePage from "../helpers/changePage";


export default function useShortTokenService(
    loadingManager: LoadingManager
) {
    const {setIsLoading} = loadingManager
    const [searchParams, setSearchParams] = useSearchParamsFixed()
    const params = useParams()

    const [shortToken, setShortToken] = useState<string | null>(null)

    const shortTokenParam = searchParams.get("st") || params.short_token
    useEffect(() => {
        if (shortTokenParam) {
            setShortToken(shortTokenParam)
            setSearchParams(prev => {
                prev.delete("st")
                return prev
            })
        }
    }, [shortTokenParam, setSearchParams]);

    const {data: shortTokenData} = useQuery<ShortTokenDataType | null>({
        queryKey: ['short-token', shortToken],
        queryFn: async () => {
            if (!shortToken) return null

            setIsLoading('short-token', true)
            try {
                const response = await api.auth.loginByShortToken(shortToken)
                console.log('***short token response: ', response)
                return response.data
            } catch (err) {
                console.log(err)
                return null
            } finally {
                setIsLoading('short-token', false)
            }
        },
        initialData: null,
        enabled: !!shortToken,
    })


    useEffect(() => {
        if (shortTokenData?.url_path) {
            changePage('/' + shortTokenData.url_path, shortTokenData.params)
        } else if (shortTokenData?.params) {
            const newParams = shortTokenData.params
            setSearchParams(prev => {
                Object.entries(newParams).forEach(([key, value]) => {
                    prev.set(key, value)
                })
                console.log('setting short token params', newParams)
                return prev
            })
        }
    }, [setSearchParams, shortTokenData?.params, shortTokenData?.url_path])

    return shortTokenData
}

import OrderContent from "../MakeOrder/OrderSuccess/OrderContent";
import { Order } from "../../../api/shop/order/types";
import { ThemeModal } from "../../../helpers/ThemeComponents";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { Theme, useMediaQuery } from "@mui/material";
import { IUsePaymentsService } from "../../payments/hooks/useNewPaymentsService";

interface IOrderModalProps {
	order: Order | null;
	show: boolean;
	setShow: (show: boolean) => void;
	setSelectedOrder: (order: Order | null) => void;
	paymentService: IUsePaymentsService;
}

export default function OrderModal(props: IOrderModalProps) {
	const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

	const localisation = useLocalisation();

	const handleModalClose = () => {
		props.setShow(false);
		setTimeout(() => {
			props.setSelectedOrder(null);
		}, 300);
	};

	return (
		<ThemeModal
			fullScreen={!isTablet}
			fullWidth={isTablet}
			maxWidth={"sm"}
			open={props.show}
			title={
				props.order
					? localisation.orders.orderNumber.replace(
							"{order_id}",
							props.order.id.toString()
						)
					: ""
			}
			onClose={() => handleModalClose()}
			PaperProps={{
				sx: { m: { sm: 2 } },
			}}
			contentProps={{
				sx: { p: 0 },
			}}
			sx={{
				".MuiDialogContent-root": {
					p: 0,
				},
			}}
		>
			{!!props.order && (
				<OrderContent order={props.order} paymentService={props.paymentService} />
			)}
		</ThemeModal>
	);
}

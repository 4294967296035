import { motion } from "framer-motion";
import { Box, Skeleton } from "@mui/material";
import React from "react";

export default function CouponSkeletonMobile() {
	return (
		<Box
			component={motion.div}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
			display={"flex"}
			flexDirection={"column"}
		>
			<Skeleton
				variant="rounded"
				sx={{
					width: "100%",
					aspectRatio: "1 / 1",
				}}
				height={"auto"}
			/>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					position: "relative",
				}}
			>
				<Box p={2} pb={0} display={"flex"} flexDirection={"column"} textAlign={"center"}>
					<Box
						display={"flex"}
						flexDirection={"column"}
						gap={1}
						width={"100%"}
						justifyContent={"space-between"}
					>
						<Skeleton width={"100%"} variant="text" sx={{ fontSize: "3rem" }} />
						{[...Array(3).keys()].map((index: number) => (
							<Skeleton
								key={index}
								width={"100%"}
								variant="text"
								sx={{ fontSize: "0.5rem" }}
							/>
						))}
					</Box>
				</Box>

				<Box
					width={"100%"}
					color={"primary.contrastText"}
					display={"flex"}
					justifyContent={"flex-end"}
					alignItems={"center"}
					gap={2}
					bottom={0}
					mt={"10px"}
					bgcolor={{ sm: "primary.main" }}
					flexWrap={"wrap"}
					pb={{ xs: 3, sm: 0 }}
					pl={{ xs: 3 }}
					pr={{ xs: 3, sm: 2 }}
				>
					<Skeleton width={"40%"} variant="text" sx={{ fontSize: "2rem", mt: "auto" }} />
				</Box>
			</Box>
		</Box>
	);
}

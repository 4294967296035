export const GlobalLocale = {
	deliveryText: "store delivery text",
	pickupText: "store pickup text",
	inStoreText: "store in store text",
	paymentOnlineText: "store payment online text",
	paymentFriendText: "store payment friend text",
	paymentInStoreText: "store payment in store text",
	paymentLaterText: "store payment later text",
	allSections: "web store all categories text",
	basePageTitle: "web store base page title text",
	notFoundResult: "web store not found result text",
	openStoreButton: "web store open button text",
	cancelButton: "web store cancel button text",
	confirmButton: "web store confirm button text",
	gotoBotNameToPay: "ewallet payment goto bot to pay",
	gotoBotNameToPayShort: "ewallet payment goto bot to pay short",
	ordersButton: "web store orders button text",
	backHome: "web store back home link text",
	agreementLabel: "web store form agreement label text",
	readButton: "web store read button text",
	okButton: "web store ok button text",
	errUnknown: "web store unknown err text",
	errApi: "web store api err text",
	closedMessage: "web store closed message text",
	aboutButton: "web store about company button text",
	agreementButton: "web store public agreement button text",
	unknownDocument: "web store unknown document text",
	contactsHeader: "web store contacts header text",
	chatButton: "web store chat button text",
	workTimesHeader: "web store working times header text",
	socialHeader: "web store social networks header text",
	companyInfoHeader: "web store company info header text",
	callManagerButton: "web store call manager button text",
	callManagerSuccess: "web store call manager success text",
	sections: "web store sections text",
	deliveryTypeLabel: "web store delivery type label text",
	information: "web store information text",
	attributesChoseOne: "web store attributes chose one text",
	attributesChoseMin: "web store attributes chose min text",
	productDesc: "web store product desc text",
	toCart: "web store product to cart button text",
	inCart: "web store product in cart button text",
	attributesHeader: "web store attributes header text",
	backToStart: "web store back to start button text",
	makeOrder: "web store make order button text",
	requiredAttribute: "web store attributes required text",
	requiredOneAttribute: "web store attributes required one text",
	maxAttributes: "web store attributes max text",
	minAttributes: "web store attributes min text",
	errMaxAttributes: "web store attributes max err text",
	errMinAttributes: "web store attributes min err text",
	errMaxAttribute: "web store attribute max err text",
	errMinAttribute: "web store attribute min err text",
	errAttributesAlreadyMax: "web store attributes already max err text",
	errAttributesDelRequired: "web store attributes del required err text",
	menuHeader: "web store menu header text",
	clearButton: "web store clear button text",
	clearMessage: "web store clear message text",
	sumLabel: "web store sum label text",
	deleteText: "web store delete text",
	confirmProductDelMsg: "web store confirm product del message text",
	orderDetailedButton: "web store order detailed button text",
	saveAndClose: "web store save and close button text",
	unknownStatus: "store brand order status default text",
	onlyRegisteredTitle: "web store only registered header text",
	onlyRegisteredSubTitle: "web store only registered sub header text",
	errBackHome: "web store back home err text",
	notSelected: "web store not selected text",
	productsHeader: "web store products header text",
	continue: "web store continue text",
	overall: "web store overall text",
	weekend: "store brand custom field work times weekend text",
	minOrderAmount: "web store min order amount text",
	autoTranslateMsg: "store brand auto translate message",
	langSelect: "web store lang select header",
	langSearch: "web store lang search label",
	weightWarning: "web store weight warning text",
	collapseText: "web store collapse button text",
	showMore: "web store show more button",
	categoryAllButton: "store category all button",
	notConfirmedEmail: "web store not confirmed email alert text",
	emailConfirmResent: "web store email confirm resent text",
	reloadPage: "web store reload page text",
	emailExistMsg: "web store account email exist message",
	toCartLink: "web store go to cart link text",
	favoritesButton: "web store favorites button",
	change: "web store change data text",
	tryAgain: "web store try again button",
	close: "web store close",
	yes: "yes button",
	no: "no button",
	send: "web store send",
	notify: "web store notification header",
	skip: "skip",
	cookiesBannerText: "web store cookies banner text",
	cookiesBannerPolicy: "web store cookies banner policy text",
	cookiesAgreeButton: "web store cookies banner agree button",
	maxOrderAmount: "web store max order amount text",
	shopWeekend: "working times closed weekend text",
	copiedText: "web store copied text",
	addFriendWebText: "web store add friend web text",
	addFriendBotText: "web store add friend bot text",
	msgAddFriendWa: "web add friend whatsapp text",
	msgAddFriendDescription: "web store add friend description text",
	msgAddFriendConfirm: "client bot add friend confirm text",
	msgAddFriendTitle: "web store add friend title text",
	msgFriendConfirmed: "client bot add friend confirmed text",
	msgAddFriendNoMessanger: "web add friend no messanger text",
	friendHaveNoMessangerText: "web friend have no messanger text",
	friendHaveNoMessangerModalTitle: "web friend have no messanger modal title",
	noPayments: "no payment methods available",
	goBack: "web app error callback link text",
	backToPaymentChoose: "web app back to payment method choose button",
	chooseCategoryButton: "web choose category button",
	continueWithoutLoyalty: "continue without loyalty button",
	continueWithLoyalty: "continue with loyalty button",
	forceRedirect: "web app force redirect link text",
	redeemMaxBonuses: "web app redeem max bonuses text",
	connectedLoyalty: "connected loyalty",
	shareHeader: "web app share header",
	shareButton: "web app share link button",
	profileSidebarHeader: "web app profile sidebar header",
	valueNotExist: "value not exist",
	dataSaved: "web app data saved",
	fullPaidByBonuses: "loyalty order full paid by bonuses text",
	open: "web app open button",
	save: "save",
	chooseStore: "web app choose store button",
	downloadByFormat: "web app download file by format button",
	showCoupon: "incust loyalty show coupon button",
	stepperAuthorisationStepHelperText: "web stepper authorisation step helper text",
	helloAuthUser: "web app sidebar header hello auth user text",
	helloAnonUser: "web app sidebar header hello anon user text",
	home: "web app home",
	signIn: "web app sign in header text",
	showMoreButton: "web show more button",
	hideButton: "web hide button",
	emailSent: "web app email sent",
	back: "web app back",
	tips: "tips_header",
	round: "round header",
	customValue: "custom value header",
	continueAnyway: "web app continue anyway",
	loyaltyNotConnectedText: "loyalty not connected text",
	brandNotLoadedText: "web brand not loaded text",
	termsOfUseText: "terms of use text",
	privacyPolicyText: "privacy policy text",
	incustAddBonusesOnlyErrorTitle: "incust add bonuses only error title",
	incustAddBonusesOnlyErrorDescription: "incust add bonuses only error description",
	telegramMessangerName: "telegram messanger name",
	whatsappMessangerName: "whatsapp messanger name",
	loyaltyConnectMessangerButton: "loyalty connect messanger button",
	loyaltyCancelBonusesButton: "loyalty cancel bonuses button",
	loyaltyConnectMessangerBotNotConnectedError:
		"loyalty connect messanger bot not connected error",
	loyaltyConnectMessangerInstructionsText: "loyalty connect messanger instructions text",
	loyaltyConnectMessangerModalTitle: "loyalty connect messanger modal title",
	loadingText: "loading text",
	createGuestUserWithEmailExistError: "create guest user with email exist error",
	all: "all items header",
	searchResults: "search result text",
	addressEntered: "web app address input current entered value",
	update: "web app update",
	specialAccountBaseTitle: "special account base title",
	botWaitAlertErrorText: "error occurred during this operation",
	botWaitAlertWaitText: "wait action webview text",
	buy: "buy button",
	purchase: "web app buy text",
	showWallet: "web app show wallet button",
	gift: "web app product gift order note",
	bonuses: "web app bonuses",
	notFoundPageMsg: "web store 404 message text",
	notFoundPageHeader: "web store 404 header text",
	notFoundBrandContent: "brand 404 content text",
	notFoundBrandTitle: "brand 404 page title",
	notFoundBrandLink: "brand 404 link text",
	notFoundBrandSubContent: "brand 404 content subtext",
	billingServiceSuspendedError: "billing service suspended error",
	billingServiceSuspendedErrorMessage: "billing service suspended error message",
	usedCoupon: "used coupon",
	copyCouponCode: "copy coupon code",
	copiedCouponCode: "copied coupon code",
	defaultNeedAuthHeader: "web app need auth default header",
	usedVoucherNotify: "used voucher notify",
	yourCodeForUse: "your code for use",
	cantShare: "web app can not share error",
};

import useAppContext from "../../../../useAppContext";
import { useSelectedStoreOptional } from "../../../SelectedStore/context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar, Box, Grid, Tab, Tabs } from "@mui/material";
import { IIncustBonusItem, IIncustCoupon, IncustTransaction, ModalData } from "../../types";
import api from "../../../../api";
// import IncustPay from "../../../payments/PaymentButtons/IncustPay/IncustPay";
// import { IncustPayConfiguration, IncustPayPaymentData } from "../../../../api/shop/payments/types";
import formatCurrency from "../../../../helpers/formatCurrency";
import { Stack } from "@mui/joy";
import { CardGiftcard, LocalOffer, MonetizationOn } from "@mui/icons-material";
import { IncustWallet } from "../../../../api/shop/loyalty/types";
import ProfileIdItem from "../../../profile/ProfileIdItem";
import useCurrency from "../../../../services/useCurrencyService";
// import IncustPayCardButton from "../../../payments/PaymentButtons/IncustPay/IncustPayCardButton";
import Divider from "@mui/material/Divider";
import { CouponCard } from "./Coupon/CouponCard";
import { WalletPageModals } from "./WalletPageModals";
import { IncustWalletCard } from "./WalletCard";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ width: "100%" }}
		>
			{value === index && <>{children}</>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function WalletPage() {
	const {
		showError,
		setPageTitle,
		brandInfo,
		localisation: { profile },
		authService: { user },
		lang,
		toastService: { showToast },
	} = useAppContext();

	const selectedStore = useSelectedStoreOptional();

	const { customerData } = useUserIncustCustomerData();

	const [coupon, setCoupon] = useState<IIncustCoupon | null>(null);
	const [shareCode, setShareCode] = useState<string | null>(null);
	const [showShareCode, setShowShareCode] = useState<boolean>(false);
	const [redeemedCouponCheck, setRedeemedCouponCheck] = useState<IncustTransaction | null>(null);
	const [showResultApply, setShowResultApply] = useState<boolean>(false);
	const [showExternalCode, setShowExternalCode] = useState<boolean>(false);
	const [showQrCode, setShowQrCode] = useState<boolean>(false);
	const [startScan, setStartScan] = useState(false);
	const [couponError, setCouponError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [walletData, setWalletData] = useState<IncustWallet[] | null>(null);
	const [addCouponResultText] = useState<string | null>(null);
	const [showCouponMessage, setShowCouponMessage] = useState(false);
	const [showAddCoupon, setShowAddCoupon] = useState(false);
	const [modalData, setModalData] = useState<ModalData | null>(null);
	const [value, setValue] = React.useState(0);
	const [isNeedUpdateWallet, setIsNeedUpdateWallet] = useState<boolean>(true);
	const [isDeleting, setIsDeleting] = useState<boolean>(false);
	const [isSharing, setIsSharing] = useState<boolean>(false);
	const [isApplying, setIsApplying] = useState<boolean>(false);
	const [currentCouponId, setCurrentCouponId] = useState<string | null>(null);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	const [addCouponValue, setAddCouponValue] = useState("");

	const onNewScanResult = (decodedText: string) => {
		setAddCouponValue(decodedText);
		setStartScan(false);
		setCouponError(null);
	};

	const currency = useCurrency();

	setPageTitle("7Loc");

	useEffect(() => {
		if (isNeedUpdateWallet && user) {
			const fetchData = async () => {
				const { data: walletData } = await api.shop.loyalty.getWallet();
				setWalletData(walletData);
			};
			fetchData();
			setIsNeedUpdateWallet(false);
			setIsLoading(false);
		}
	}, [isNeedUpdateWallet, user]);

	const computedIncustCustomerId = useMemo(() => {
		return customerData?.external_id || "unknown";
	}, [customerData?.external_id]);

	const handlerDeleteButton = useCallback(
		async (coupon_id: string) => {
			if (!brandInfo?.id) return;
			if (!coupon_id) return;
			try {
				setCurrentCouponId(coupon_id);
				setIsDeleting(true);
				const { data: deleteResult } = await api.shop.loyalty.deleteCoupon(
					brandInfo.id,
					coupon_id
				);
				if (!deleteResult?.message) return;
				setIsNeedUpdateWallet(true);
				showToast({
					severity: "success",
					message: profile.successDeletedCoupon,
				});
			} catch (ex: any) {
				if (ex.response?.status === 404) {
					showError(profile.couponNotFound);
				} else {
					showError(ex.response?.data?.detail || ex.message);
				}
			} finally {
				setCurrentCouponId(null);
				setIsDeleting(false);
			}
		},
		[brandInfo, profile.couponNotFound, showError, showToast, profile.successDeletedCoupon]
	);

	const handlerApplyButton = useCallback(
		async (coupon: IIncustCoupon) => {
			setRedeemedCouponCheck(null);
			if (!brandInfo?.id) return;
			if (!coupon.id) return;
			try {
				setCurrentCouponId(coupon.id);
				setIsApplying(true);
				setCoupon(coupon);
				const { data: couponExt } = await api.shop.loyalty.processCoupon(
					coupon.id,
					selectedStore?.id
				);
				setCoupon(couponExt.coupon);
				if (
					couponExt.coupon.type === "certificate" &&
					couponExt.coupon.redeem_result_transaction_id
				) {
					setRedeemedCouponCheck(couponExt.transaction);
					setShowResultApply(true);
				} else if (couponExt.coupon.type === "external" && couponExt.coupon.external_code) {
					setShowExternalCode(true);
				}
				setIsNeedUpdateWallet(true);
			} catch (ex: any) {
				if (ex.response?.status === 404) {
					showError(profile.couponNotFound);
				} else {
					showError(ex.response?.data?.detail || ex.message);
				}
			} finally {
				setCurrentCouponId(null);
				setIsApplying(false);
			}
		},
		[brandInfo?.id, profile.couponNotFound, selectedStore?.id, showError]
	);

	const handlerShareButton = useCallback(
		async (coupon: IIncustCoupon) => {
			setShareCode("");
			if (!brandInfo?.id) return;
			if (!coupon) return;
			if (!coupon.id) return;
			if (!coupon?.batch?.share_allowed) return;
			try {
				setCurrentCouponId(coupon.id);
				setIsSharing(true);
				setCoupon(coupon);
				const { data: shareResult } = await api.shop.loyalty.shareCoupon(
					brandInfo.id,
					coupon.id
				);
				if (!shareResult?.message) return;
				setShareCode(shareResult.message);
				setShowShareCode(true);
			} catch (ex: any) {
				if (ex.response?.status === 404) {
					showError(profile.couponNotFound);
				} else {
					showError(ex.response?.data?.detail || ex.message);
				}
			} finally {
				setCurrentCouponId(null);
				setIsSharing(false);
			}
		},
		[brandInfo, profile.couponNotFound, showError, setCurrentCouponId]
	);

	const addCouponButtonHandler = useCallback(async () => {
		if (!brandInfo?.id || !addCouponValue) {
			return;
		}
		setCouponError(null);
		setRedeemedCouponCheck(null);
		setIsLoading(true);

		try {
			const { data: couponExt } = await api.shop.loyalty.processCoupon(
				addCouponValue,
				selectedStore?.id
			);
			if (couponExt.message) {
				showToast({
					severity: "success",
					message: couponExt.message,
				});
			}
			setIsNeedUpdateWallet(true);
			setShowAddCoupon(false);

			if (
				couponExt.coupon.status === "redeemed" &&
				couponExt.coupon.type === "certificate" &&
				couponExt.coupon.redeem_result_transaction_id
			) {
				setRedeemedCouponCheck(couponExt.transaction);
				setShowResultApply(true);
			}
			setAddCouponValue("");
		} catch (ex: any) {
			setCouponError(
				ex.response?.data?.detail || ex.message || profile.loyaltyAddCouponFailedText
			);
		} finally {
			setIsLoading(false);
		}
	}, [
		brandInfo?.id,
		addCouponValue,
		selectedStore?.id,
		showToast,
		profile.loyaltyAddCouponFailedText,
	]);

	return (
		<Box>
			<Box
				sx={{
					padding: "2px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
					"@media (min-width: 900px)": { width: "70%" },
					marginX: "auto",
				}}
			>
				<Stack spacing={1} width={"100%"}>
					<IncustWalletCard
						headerText={user?.name || ""}
						children={<ProfileIdItem id={computedIncustCustomerId} iconSize={18} />}
						isLoading={false}
					/>

					<Divider />

					<IncustWalletCard
						headerText={profile.accountsTitle}
						headerIcon={<MonetizationOn />}
						isLoading={false}
						isCollapsable={true}
						children={
							<>
								{/*{payments && payments?.incust_pay_list.length > 0 && (*/}
								{/*	<IncustPay*/}
								{/*		amountToPay={0}*/}
								{/*		incustPay={payments}*/}
								{/*		type={"invoice"}*/}
								{/*		selectedStoreId={null}*/}
								{/*		orderId={null}*/}
								{/*		orderToken={null}*/}
								{/*		invoiceToken={null}*/}
								{/*		toShow={true}*/}
								{/*	/>*/}
								{/*)}*/}
								{/*{payments &&*/}
								{/*	payments?.incust_pay_list.length > 0 &&*/}
								{/*	payments?.incust_pay_list[0].specials.length < 1 && (*/}
								{/*		<Box margin={2}>{profile.loyaltyNoAccounts}</Box>*/}
								{/*	)}*/}
							</>
						}
						marginX={0}
					/>

					<Divider />

					{/*{payments?.incust_pay_list?.map(incust_pay => {*/}
					{/*	if (!incust_pay.incust_pay_configuration.card_payment_enabled) return null;*/}
					{/*	return (*/}
					{/*		<Box sx={{ width: "100%" }}>*/}
					{/*			<IncustPayCardButton*/}
					{/*				sx={{ borderBottom: "none" }}*/}
					{/*				key={incust_pay.incust_pay_configuration.id}*/}
					{/*				data={incust_pay.incust_pay_configuration}*/}
					{/*				setData={(data: IncustPayConfiguration | null) => {*/}
					{/*					if (data) {*/}
					{/*						setModalData({*/}
					{/*							type: "card",*/}
					{/*							cardData: data,*/}
					{/*						});*/}
					{/*					} else {*/}
					{/*						setModalData(null);*/}
					{/*					}*/}
					{/*				}}*/}
					{/*			/>*/}
					{/*		</Box>*/}
					{/*	);*/}
					{/*})}*/}

					<Divider />

					<Box
						sx={{
							width: "100%",
						}}
					>
						<Tabs value={value} onChange={handleChange} aria-label="change loyalty">
							{walletData?.map((data, idx) => (
								<Tab
									label={
										<Box sx={{ display: "flex", alignItems: "center" }}>
											<Avatar
												src={data.photos ? data.photos[0]?.url || "" : ""}
												sx={{
													width: "24px",
													height: "24px",
													marginRight: "8px",
												}}
											/>
											{data.title}
										</Box>
									}
									{...a11yProps(idx)}
								/>
							))}
						</Tabs>
						{walletData?.map((data, idx) => (
							<CustomTabPanel value={value} index={idx}>
								<Stack spacing={"12px"} sx={{ width: "100%" }} mb={2}>
									{
										<IncustWalletCard
											headerText={
												data?.bonuses &&
												currency &&
												(brandInfo?.default_lang || lang)
													? profile.loyaltyBonusesHeader +
														": " +
														getBonus(
															data.bonuses,
															currency,
															brandInfo?.default_lang || lang || "en"
														)
													: profile.loyaltyNoBonuses
											}
											headerIcon={<LocalOffer />}
											isLoading={walletData === null}
											children={null}
										/>
									}

									<Divider />

									<IncustWalletCard
										headerText={
											data?.coupons
												? profile.loyaltyCouponsTitle
												: profile.loyaltyNoVouchers
										}
										headerIcon={<CardGiftcard />}
										isLoading={isLoading}
										marginX={1}
										handler={setShowAddCoupon}
										children={
											data?.coupons ? (
												<Box mt={2} width={"100%"} id={"list-container"}>
													<Grid
														container
														columnSpacing={2}
														rowSpacing={1}
														px={2}
													>
														{data?.coupons?.map((coupon, index) => (
															<Grid
																item
																key={coupon.id}
																xs={12}
																lg={12}
															>
																<CouponCard
																	coupon={coupon}
																	setCoupon={setCoupon}
																	setShowQrCode={setShowQrCode}
																	handlerDeleteButton={
																		handlerDeleteButton
																	}
																	handlerApplyButton={
																		handlerApplyButton
																	}
																	handlerShareButton={
																		handlerShareButton
																	}
																	index={index}
																	isDeleting={isDeleting}
																	isSharing={isSharing}
																	isApplying={isApplying}
																	currentCouponId={
																		currentCouponId
																	}
																/>
															</Grid>
														))}
													</Grid>
												</Box>
											) : null
										}
									/>
								</Stack>
							</CustomTabPanel>
						))}
					</Box>
				</Stack>
			</Box>

			<WalletPageModals
				coupon={coupon}
				setShowQrCode={setShowQrCode}
				showExternalCode={showExternalCode}
				setShowExternalCode={setShowExternalCode}
				showShareCode={showShareCode}
				setShowShareCode={setShowShareCode}
				showQrCode={showQrCode}
				showResultApply={showResultApply}
				setShowResultApply={setShowResultApply}
				showAddCoupon={showAddCoupon}
				setShowAddCoupon={setShowAddCoupon}
				startScan={startScan}
				setStartScan={setStartScan}
				showCouponMessage={showCouponMessage}
				setShowCouponMessage={setShowCouponMessage}
				addCouponValue={addCouponValue}
				setAddCouponValue={setAddCouponValue}
				couponError={couponError}
				addCouponResultText={addCouponResultText}
				modalData={modalData}
				setModalData={setModalData}
				isLoading={isLoading}
				addCouponButtonHandler={addCouponButtonHandler}
				redeemedCouponCheck={redeemedCouponCheck}
				onNewScanResult={onNewScanResult}
				shareCode={shareCode}
			/>
		</Box>
	);
}

function getBonus(bonuses: IIncustBonusItem[], currency: string, lang: string) {
	const bonus = bonuses.find(bonus => bonus.currency === currency);
	if (bonus) {
		return formatCurrency(bonus.bonuses_amount, lang, currency);
	}
}

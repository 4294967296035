import useMinMaxPrices from "./useMinMaxPrices";
import RangeFilter from "./RangeFilter";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import {useSelectedStoreContext} from "../../SelectedStore/context";


export default function PriceFilter() {
    const {filtersService} = useSelectedStoreContext()
    const minMaxPrices = useMinMaxPrices()

    const {menu} = useLocalisation()

    return (
        <RangeFilter
            min={filtersService.minPrice}
            max={filtersService.maxPrice}
            minAllowed={minMaxPrices.min}
            maxAllowed={minMaxPrices.max}
            setMin={filtersService.setMinPrice}
            setMax={filtersService.setMaxPrice}
            disabled={minMaxPrices.isLoading}
            label={menu.priceFilterLabel}
        />
    )
}

import {ReactNode, useMemo} from "react";
import CustomShipping, {CustomShippingHeader} from "../CustomShipping";
import BaseShippingForm from "../BaseShippingForm";
import {IShippingFormProps} from "./ShippingForm";
import ShippingCourierComment from "./ShippingCourierComment";

interface IShippingFormWrapperProps extends IShippingFormProps {
    children: ReactNode
}

export default function ShippingFormWrapper(props: IShippingFormWrapperProps) {
    const computedNeedCourierComment = useMemo(() => {
        if(!props.selectedShipmentService.selectedShipment) return false

        else if(props.selectedShipmentService.selectedShipment.custom_type === "shipment" &&
            props.selectedShipmentService.selectedShipment.base_type === "delivery"
        ){
            return true
        }

        else return props.selectedShipmentService.selectedShipment.custom_type === "custom_shipment" &&
                props.selectedShipmentService.selectedShipment.base_type === "delivery" &&
                !props.selectedShipmentService.selectedShipment.need_comment
    }, [props.selectedShipmentService.selectedShipment])

    return (
        <>
            <CustomShippingHeader selectedShipmentService={props.selectedShipmentService} />
            {props.children}

            {computedNeedCourierComment && (
                <ShippingCourierComment
                    selectedShipmentService={props.selectedShipmentService}
                    setForm={props.setForm}
                    form={props.form}
                />
            )}

            <CustomShipping
                form={props.form}
                setForm={props.setForm}
                showInvalidError={props.showInvalidError}
                selectedShipmentService={props.selectedShipmentService}
                getShipmentPricesById={props.getShipmentPricesById}
                computedSelectedShipmentHasZones={props.computedSelectedShipmentHasZones}

                polygons={props.polygons}
                radius={props.radius}
                showMap={props.showMap}
                setShowMap={props.setShowMap}
                setValidAddress={props.setValidAddress}
            />

            <BaseShippingForm
                form={props.form}
                setForm={props.setForm}
                selectedShipmentService={props.selectedShipmentService}
                showInvalidError={props.showInvalidError}
                computedSelectedShipmentHasZones={props.computedSelectedShipmentHasZones}

                polygons={props.polygons}
                radius={props.radius}
                showMap={props.showMap}
                setShowMap={props.setShowMap}
                setValidAddress={props.setValidAddress}
            >
                {props.children}
            </BaseShippingForm>
        </>
    )
}

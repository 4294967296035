import {AxiosInstance} from "axios";
import {MakeReviewData, Review} from "./types";


export default function ReviewsAPIModule(instance: AxiosInstance) {
    const getPath = (path: string) => `reviews/${path}`

    return {
        makeReview(data: MakeReviewData) {
            return instance.post<Review>(getPath('make'), data)
        }
    }
}

import { useCallback, useEffect } from "react";
import useAppContext from "../../../useAppContext";
import { MessageListenerObject, ShareObject } from "./types";
import { IAppearanceSettings } from "../useAppearanceSettings/types";
import useShare from "../../../features/hooks/useShare";

export default function useMessageListener() {
	const { appearance, showError, localisation } = useAppContext();

	const { isShareAvailable, tryShare } = useShare();

	const setAppearanceState = appearance.setAppearanceState;
	const handleMessage = useCallback(
		(event: MessageEvent) => {
			if (event.data) {
				const message = event.data as MessageListenerObject;
				if (message.command && message.data) {
					switch (message.command) {
						case "appearance":
							setAppearanceState(message.data as IAppearanceSettings);
							break;
					}
				} else if (message.action && message.data) {
					switch (message.action) {
						case "share":
							try {
								const obj = message.data as ShareObject;
								if (isShareAvailable) {
									tryShare({ url: obj.url }).then();
								} else {
									showError(localisation.global.cantShare);
								}
							} catch (ex: any) {
								console.log(ex);
							}
							break;
					}
				}
			}
		},
		[isShareAvailable, localisation.global.cantShare, setAppearanceState, showError, tryShare]
	);

	useEffect(() => {
		window.addEventListener("message", handleMessage);

		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, [handleMessage]);
}

import api from "../api";
import {getErrorText} from "../helpers/errors";
import {useCallback} from "react";
import useAppContext from "../useAppContext";


export default function useCheckEmail(): (email: string) => Promise<CheckEmailResult> {
    const {
        localisation,
        authService: {user},
    } = useAppContext()

    return useCallback (async (email: string) => {
        try {
            const response = await api.auth.checkIsEmailExists(email)
            if (
                response.data.is_exists &&
                !response.data.is_guest_user &&
                response.data.email !== user?.email
            ) {
                return {
                    ok: false,
                    error: localisation.global.createGuestUserWithEmailExistError
                }
            } else {
                return {
                    ok: true,
                    error: null
                }
            }
        } catch (err) {
            console.log('check email error')
            console.log(err)
            return {
                ok: false,
                error: getErrorText(err, localisation.global.errUnknown),
            }
        }
    }, [
        user?.email, localisation.global.errUnknown,
        localisation.global.createGuestUserWithEmailExistError,
    ])
}


export interface CheckEmailResult {
    ok: boolean
    error: string | null
}

import { ApiUrl } from "../config";
import StubImage from "../static/stubs/shop-image-stub.webp";
import NotFoundBase from "./NotFoundBase";
import BaseShopFooter from "../shop/ShopFooter/BaseShopFooter";
import useAppContext from "../useAppContext";
import changePage from "../helpers/changePage";

export default function ShopNotFound() {
	const { setPageTitle, brandInfo } = useAppContext();

	const backHome = () => {
		if (brandInfo) {
			return changePage(`/shop/select`);
		}
		changePage(`/`);
	};

	const computedImage = () => {
		if (brandInfo) {
			return brandInfo.image_url || StubImage;
		}
		return `${ApiUrl}/static/images/default-404-image.png`;
	};

	return (
		<>
			<NotFoundBase
				brandInfo={brandInfo}
				setPageTitle={setPageTitle}
				computedImage={computedImage}
				backHome={backHome}
			/>
			{brandInfo && <BaseShopFooter brandInfo={brandInfo} />}
		</>
	);
}

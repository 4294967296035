import useShopContextMaker from "./useShopContextMaker";
import ShopContext from "./context";
import {SetState} from "../../types";
import {BrandInfo} from "../../api/shop/basic/types";
import {ShortTokenDataType} from "../../api/auth/types";
import {LoadingManager} from "../../features/hooks/useLoadingManager";


interface Props {
    children: any
    brandInfo: BrandInfo
    setPageTitle: SetState<string | null>
    shortTokenData: ShortTokenDataType | null
    botId?: number | null | undefined
    loadingManger: LoadingManager
}


export default function ShopContextProvider(props: Props) {
    const context = useShopContextMaker(
        props.brandInfo,
        props.setPageTitle,
        props.botId,
        props.loadingManger,
    )

    return (
        <ShopContext.Provider value={context}>
            {props.children}
        </ShopContext.Provider>
    )
}

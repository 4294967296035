import {FormEvent, useState, useCallback, useEffect} from "react";

import {ChangeEmailData} from "../api/auth/types";
import api from "../api";
import useSearchParamsFixed from "../features/hooks/useSearchParamsFixed";
import {Alert, Box, Button, CircularProgress, Container, Paper, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import useAppContext from "../useAppContext";
import Interweave from "../features/Interweave";

export default function ChangeEmailPage() {
    const {
        loadingManager: {isSomethingLoading},
        localisation: {auth},
        setPageTitle,
    } = useAppContext()

    const [searchParams] = useSearchParamsFixed()
    const isExternalUser = searchParams.get('is_external_user') === 'True'
    const token = searchParams.get('token')
    const email = searchParams.get('email')

    const [apiError, setApiError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [success, setSuccess] = useState<boolean>(false)

    const confirmEmail = useCallback(async() => {
        try {
            await api.auth.confirmEmail(token || "")
        }
        catch(ex: any) {
            setApiError(ex.response?.data?.detail || ex.message || "unknown error")
        }
        finally {
            setIsLoading(false)
        }

    }, [token])

    useEffect(() => {
        confirmEmail().then()
    }, [confirmEmail])

    useEffect(() => {
        setPageTitle(auth.changeEmailHeader)
    }, [auth.changeEmailHeader, setPageTitle])

    if(!token || !email) return <></>

    return (
        <Container
            maxWidth={'sm'}
            sx={{
                p: 0,
                mt: 3,
                flex: 1,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Paper elevation={3} sx={{width: '100%', p: 3}}>
                <Typography variant={'h3'} className={'border-bottom mb-2 pb-2'}>
                    {auth.changeEmailHeader}
                </Typography>

                {isLoading
                    ?
                        <Box sx={{width: '100%', textAlign: 'center'}}>
                            {!isSomethingLoading && (
                                <CircularProgress style={{width: '38px', height: '38px'}} />
                            )}
                        </Box>
                    :
                        apiError
                            ?
                                <Alert
                                    severity={'error'}
                                    variant={'outlined'}
                                    className={'small p-3 w-100 mt-2'}>
                                    <Interweave content={apiError} />
                                </Alert>
                            :
                                <>
                                    <Box sx={{mb: 3}}>
                                        {success
                                            ? auth.emailChangedSuccess
                                            :
                                                isExternalUser
                                                    ? auth.emailSetSubHeader
                                                    : auth.emailChangeSubHeader
                                        }
                                    </Box>

                                    {!success && (
                                        <ChangeEmailForm
                                            email={email}
                                            setSuccess={setSuccess}
                                            token={token}
                                            isExternalUser={isExternalUser}
                                        />
                                    )}
                                </>
                }
            </Paper>
        </Container>
    )
}

interface IChangeEmailFormProps {
    email: string
    setSuccess: (success: boolean) => void
    token: string
    isExternalUser: boolean
}

function ChangeEmailForm(props: IChangeEmailFormProps) {
    const {localisation: {auth, global}} = useAppContext()

    const [password, setPassword] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [apiError, setApiError] = useState<string | null>(null)

    const submit = async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setApiError(null)

        try {
            setIsLoading(true)
            const payload: ChangeEmailData = {
                new_email: props.email,
                password: password,
            }

            await api.auth.changeEmail(payload, props.token)
            props.setSuccess(true)
        }
        catch(ex: any) {
            setApiError(ex.response?.data?.detail || ex.message || "unknown error")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <form onSubmit={submit}>
            <TextField
                sx={{mb: 2}}
                required
                size={'medium'} fullWidth
                id={'email'}
                type={'email'}
                value={props.email}
                inputProps={{readOnly: true}}
            />

            {!props.isExternalUser && (
                <TextField
                    required
                    size={'medium'} fullWidth
                    id={'password'} type={'password'}
                    label={auth.loginPasswordLabel}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            )}

            {!!apiError && (
                <Alert
                    severity={'error'}
                    variant={'outlined'}
                    className={'small p-3 w-100 mt-2'}>
                    <Interweave content={apiError} />
                </Alert>
            )}

            <Button
                variant={'contained'}
                sx={{width: '100%', mt: 3}}
                size={'large'}
                type={'submit'}
                disabled={isLoading}
            >
                {isLoading && (
                    <CircularProgress className={'me-2'} style={{width: '18px', height: '18px'}} />
                )}
                {global.confirmButton}
            </Button>
        </form>
    )
}

import { ReactNode, useEffect, useMemo, useCallback } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import ProfileSidebar from "./ProfileSidebar";
import { TgLink } from "../../helpers/ThemeComponents";
import { useSelectedStoreOptional } from "../SelectedStore/context";
import { ProfileTab } from "./types";
import changePage from "../../helpers/changePage";
import useAppContext from "../../useAppContext";
import useScreenService from "../../services/useScreenService";

interface IProfileLayoutProps {
	tab: ProfileTab | undefined | null;
	children: ReactNode;
}

export default function ProfileLayout(props: IProfileLayoutProps) {
	const { setPageTitle, localisation, brandInfo, profileService, groupService } = useAppContext();
	const selectedStore = useSelectedStoreOptional();
	const { isMobile } = useScreenService();

	const goHome = () => {
		if (selectedStore) {
			changePage(`/shop/${selectedStore.id}/menu`);
		} else {
			changePage("/shop/select");
		}
	};

	const computedSubTitle = useMemo(() => {
		switch (props.tab) {
			case ProfileTab.overall:
				return localisation.profile.accountHeader;
			case ProfileTab.favorites:
				return localisation.profile.favoritesHeader;
			case ProfileTab.orders:
				return localisation.orders.myOrders;
			case ProfileTab.loyalty:
				return localisation.profile.hiddenIncustWalletHeader;
			case ProfileTab.myCard:
				return localisation.profile.myCard;
			case ProfileTab.shareAndEarn:
				return (
					groupService.group?.texts.web.loyalty.referral_system.info_button ||
					localisation.profile.shareAndEarn
				);
			case ProfileTab.friends:
				return localisation.profile.friendsHeader;
			default:
				return "";
		}
	}, [
		props.tab,
		localisation.profile.accountHeader,
		localisation.profile.favoritesHeader,
		localisation.profile.hiddenIncustWalletHeader,
		localisation.profile.myCard,
		localisation.profile.shareAndEarn,
		localisation.orders.myOrders,
		groupService.group?.texts.web.loyalty.referral_system.info_button,
		localisation.profile.friendsHeader,
	]);

	const onUnAuthCallback = useCallback(() => {
		if (
			profileService.activeTab !== ProfileTab.overall &&
			profileService.activeTab !== ProfileTab.favorites &&
			profileService.activeTab !== ProfileTab.shareAndEarn
		) {
			if (selectedStore) {
				changePage(`/shop/${selectedStore.id}/profile`);
			} else {
				changePage("/shop/profile");
			}
		}
	}, [profileService.activeTab, selectedStore]);

	useEffect(() => {
		setPageTitle(computedSubTitle);
	}, [setPageTitle, computedSubTitle]);

	const setActiveTab = profileService.setActiveTab;
	useEffect(() => {
		setActiveTab(props.tab);
	}, [setActiveTab, props.tab]);

	useEffect(() => {
		document.addEventListener("onUnAuth", onUnAuthCallback);

		return () => {
			document.removeEventListener("onUnAuth", onUnAuthCallback);
		};
	}, [onUnAuthCallback]);

	return (
		<>
			<MDBRow className={"m-0"}>
				<MDBCol size={"12"} className={"ms-md-0 border-bottom mb-md-4 mb-2"}>
					<div className={"fs-3 fw-bold theme-text"}>
						{localisation.profile.profile}
						{computedSubTitle && (
							<>
								{" | "}
								{computedSubTitle}
							</>
						)}
					</div>

					{!!brandInfo?.stores_count && (
						<div className={"d-flex mb-2"}>
							<div>
								<TgLink className={"cursor-pointer"} onClick={goHome}>
									{localisation.global.backHome}
								</TgLink>
							</div>
						</div>
					)}
				</MDBCol>

				{!isMobile && (
					<MDBCol size={"12"} lg={4} xl={"3"} className={"my-2 p-0"}>
						<ProfileSidebar withWrapper={true} needLogout={true} />
					</MDBCol>
				)}

				<MDBCol
					size={"12"}
					lg={8}
					xl={"9"}
					className={"my-2"}
					style={{ minHeight: "100vh" }}
				>
					{props.children}
				</MDBCol>
			</MDBRow>
		</>
	);
}

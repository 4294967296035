import { ChatMessageType } from "./types";
import ProductCard from "../../shop/menu/product/ProductCard";
import classnames from "classnames";
import Interweave from "../Interweave";

interface ChatMessageProps {
	message: ChatMessageType;
	recommendedProductsText?: string;
	needNote?: boolean;
}

export default function ChatMessage({
	message,
	recommendedProductsText,
	needNote,
}: ChatMessageProps) {
	return (
		<div
			className={classnames(`message ${message.role}`, {
				products: message.type === "recommended_products",
			})}
		>
			{message.type === "text" ? (
				<ChatMessageText text={message.text} />
			) : (
				<ChatMessageProducts
					message={message}
					recommendedProductsText={recommendedProductsText}
					needNote={needNote}
				/>
			)}
		</div>
	);
}

function ChatMessageProducts({ message, recommendedProductsText, needNote }: ChatMessageProps) {
	if (message.type !== "recommended_products") {
		return null;
	}

	return (
		<>
			<div className={"products"}>
				{message.recommended_products.products.map(product => (
					<div className={"product"} key={product.product.id}>
						<ProductCard view={"grid"} product={product.product} />
						{needNote && <div className={"mt-1"}>{product.note}</div>}
					</div>
				))}
			</div>
			{recommendedProductsText && (
				<ChatMessageText
					text={recommendedProductsText
						.replace("{count}", message.recommended_products.products.length.toString())
						.replace(
							"{recommend_request}",
							message.recommended_products.recommend_request
						)}
				/>
			)}
		</>
	);
}

function ChatMessageText({ text }: { text: string }) {
	return (
		<div className={"message-text"}>
			<Interweave content={text} />
		</div>
	);
}

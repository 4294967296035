import useAppContext from "../../useAppContext";
import { ThemeModal } from "../../helpers/ThemeComponents";
import { Box, Button } from "@mui/material";
import Interweave from "../../features/Interweave";

export default function Dialog() {
	const { dialogService } = useAppContext();

	const handleClose = () => {
		dialogService.setShowDialog(false);
		if (dialogService.callback) dialogService.callback();
	};

	return (
		<ThemeModal
			open={dialogService.showDialog}
			title={dialogService.dialogTitle}
			onClose={handleClose}
		>
			<Interweave content={dialogService.dialogText} />
			<Box sx={{ mt: 3, width: "100%", textAlign: "right" }}>
				<Button onClick={handleClose}>OK</Button>
			</Box>
		</ThemeModal>
	);
}

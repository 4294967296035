import { AppBar, Box, Button, CircularProgress, Grid, Tab, Tabs, Typography } from "@mui/material";
import { OrangePaymentData } from "../../../../../api/shop/payments/types";
import useAppContext from "../../../../../useAppContext";
import React, { ReactNode, useCallback, useState } from "react";
import QRCode from "qrcode.react";
import { IUsePaymentsService } from "../../../hooks/useNewPaymentsService";
import { useQuery } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";

interface OrangePayModalContentProps {
	paymentsService: IUsePaymentsService;
	additionalFieldsNode?: ReactNode | null;
}

type TabType = "orangemoney" | "maxit";

type LoadingButtonType = "maxit" | "orangemoney" | "qr" | null;

export default function OrangePayModalContent(props: OrangePayModalContentProps) {
	const { localisation } = useAppContext();

	const [isQr, setIsQr] = useState(false);
	const [activeTab, setActiveTab] = useState<TabType>("orangemoney");
	const [loadingButton, setLoadingButton] = useState<LoadingButtonType>(null);

	const { data: paymentData, isFetching } = useQuery({
		queryKey: ["orange-payment", props.paymentsService.methods],
		enabled: props.paymentsService.methods.some(x => x.provider === "orange"),
		initialData: null,
		queryFn: async () => {
			const data = await props.paymentsService.makePrePayment(true);
			if (data) {
				return data.data as Object as OrangePaymentData;
			}
			return null;
		},
	});

	const handleButtonClick = useCallback(
		async (button: LoadingButtonType, url?: string | null) => {
			if (
				props.paymentsService.selectedProvider?.need_comment === "required" &&
				!props.paymentsService.comment
			) {
				return props.paymentsService.setError(localisation.payment.commentRequired);
			}
			props.paymentsService.setError("");
			setLoadingButton(button);
			await props.paymentsService.makePrePayment();
			setLoadingButton(null);
			if (button === "qr") {
				return setIsQr(true);
			}
			if (url) {
				// Use setTimeout to add a small delay
				setTimeout(() => {
					// Try to open the URL in a new tab
					const newWindow = window.open(url, "_blank", "noopener,noreferrer");

					// If opening in a new tab fails, redirect in the same tab
					if (!newWindow || newWindow.closed || typeof newWindow.closed === "undefined") {
						window.location.href = url;
					}
				}, 100);
			}
		},
		[localisation.payment.commentRequired, props.paymentsService]
	);

	if (!paymentData || isFetching) {
		if (isFetching) {
			return (
				<Box sx={{ width: "100%" }} textAlign={"center"}>
					<CircularProgress style={{ width: "38px", height: "38px" }} />
				</Box>
			);
		}
		return null;
	}

	return (
		<Box>
			{isQr ? (
				<Box>
					<Box
						sx={{
							mb: 3,
						}}
					>
						<AppBar position={"static"}>
							<Tabs
								textColor={"inherit"}
								indicatorColor={"secondary"}
								variant={"fullWidth"}
								value={activeTab}
								onChange={(_, value) => {
									setActiveTab(value);
								}}
							>
								<Tab label="Orange Money" value={"orangemoney"} />
								<Tab label="Maxit" value={"maxit"} />
							</Tabs>
						</AppBar>
					</Box>

					<Box textAlign={"center"}>
						<Typography mb={3} fontWeight={"bold"}>
							{localisation.payment.orangeScanQrText}
						</Typography>

						<TabPanel tab={"orangemoney"} activeTab={activeTab}>
							<QRCode size={256} value={paymentData.orange_money_url || ""} />
						</TabPanel>
						<TabPanel tab={"maxit"} activeTab={activeTab}>
							<QRCode size={256} value={paymentData.orange_maxit_url || ""} />
						</TabPanel>
					</Box>

					<Box mt={2} textAlign={"center"}>
						<Button onClick={() => setIsQr(false)}>
							{localisation.payment.orangePayThisDeviceButton}
						</Button>
					</Box>
				</Box>
			) : (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography pb={2} fontWeight={"bold"} textAlign={"center"}>
							{localisation.payment.orangeChooseAppText}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						{paymentData.orange_money_url && (
							<LoadingButton
								onClick={() =>
									handleButtonClick("orangemoney", paymentData.orange_money_url)
								}
								loading={loadingButton === "orangemoney"}
								disabled={loadingButton !== null}
								fullWidth
								size={"large"}
								variant={"contained"}
								// href={paymentData.orange_money_url}
								// target={"_blank"}
								// rel={"noopener noreferrer"}
							>
								Orange Money
							</LoadingButton>
						)}
					</Grid>

					<Grid item xs={12} sm={6}>
						{paymentData.orange_maxit_url && (
							<LoadingButton
								onClick={() =>
									handleButtonClick("maxit", paymentData.orange_maxit_url)
								}
								loading={loadingButton === "maxit"}
								disabled={loadingButton !== null}
								fullWidth
								size={"large"}
								variant={"contained"}
								// href={paymentData.orange_maxit_url}
								// target={"_blank"}
								// rel={"noopener noreferrer"}
							>
								Maxit
							</LoadingButton>
						)}
					</Grid>

					<Grid item xs={12} textAlign={"center"}>
						<LoadingButton
							onClick={() => handleButtonClick("qr")}
							loading={loadingButton === "qr"}
							disabled={loadingButton !== null}
						>
							{localisation.payment.orangePayOtherDeviceButton}
						</LoadingButton>
					</Grid>

					{props.additionalFieldsNode && props.additionalFieldsNode}
				</Grid>
			)}
		</Box>
	);
}

interface TabPanelProps {
	tab: TabType;
	activeTab: TabType;
	children?: React.ReactNode;
}

function TabPanel({ tab, activeTab, children }: TabPanelProps) {
	return (
		<div role={"tabpanel"} hidden={tab !== activeTab}>
			{tab === activeTab && children}
		</div>
	);
}

import {IQrMenuButtonProps} from "../types";
import QrMenuButtonWrapper from "./QrMenuButtonWrapper";
import {useShopContext} from "../../context";
import {useSelectedStoreOptional} from "../../SelectedStore/context";
import changePage from "../../../helpers/changePage";

export default function DeliveryPickupButton(props: IQrMenuButtonProps){
    const {
        menuInStoreService,
    } = useShopContext()
    const selectedStore = useSelectedStoreOptional()

    const onClick = () => {
        menuInStoreService.forgetMenuInStore()
        if (selectedStore?.id) {
            changePage(`/shop/${selectedStore?.id}/menu`)
        } else {
            changePage('/shop/select')
        }
    }

    if(!props.buttonName) return null

    return (
        <QrMenuButtonWrapper
            onClick={onClick}
        >
            <span className={'fs-6'}>
                {props.buttonName}
            </span>
        </QrMenuButtonWrapper>
    )
}

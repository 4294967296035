import {
	PhotonSearchArgs,
	PhotonCollection,
	IAutocompleteParams,
	IPlaceDetailsParams,
	IPredictionsResponse,
	IPlaceDetails,
	IGeocodingResult,
	IReverseGeocodingParams,
	IIMapsKey,
	IGeocodingParams,
	IAddressComponent,
} from "./types";
import { AxiosInstance } from "axios";
import { IAddressValid } from "../../features/AddressInput/AddressInput";

const availablePhotonLangs = ["en", "de", "fr"];

export function MapModulePhoton(apiInstance: AxiosInstance) {
	return {
		getCoordsByAddress(args: PhotonSearchArgs) {
			if (args.lang && !availablePhotonLangs.includes(args.lang)) {
				args.lang = "default";
			}
			return apiInstance.get<PhotonCollection>("/api", {
				params: args,
			});
		},
	};
}

export function MapModuleGoogle(apiInstance: AxiosInstance) {
	return {
		autocomplete(args: IAutocompleteParams) {
			return apiInstance.get<IPredictionsResponse>("/maps/autocomplete", { params: args });
		},

		details(args: IPlaceDetailsParams) {
			return apiInstance.get<IPlaceDetails>("/maps/details", { params: args });
		},

		reverseGeocoding(args: IReverseGeocodingParams) {
			return apiInstance.get<IGeocodingResult>("/maps/reverse_geocoding", { params: args });
		},

		geocoding(args: IGeocodingParams) {
			return apiInstance.get<IGeocodingResult>("/maps/geocoding", { params: args });
		},

		getMapsKey() {
			return apiInstance.get<IIMapsKey>("/maps/maps_key");
		},

		validateAddress(payload: IAddressComponent[]) {
			return apiInstance.post<IAddressValid>("/maps/validate_address", payload);
		},
	};
}

import {useState, useEffect} from "react";

import {CartProduct} from "../../../../api/shop/cart/types";

export default function useFloatingSum(cartProduct?: CartProduct | null): IUseFloatingSumType {
    const [floatingSumAmount, setFloatingSumAmount] = useState(0)
    const [errorText, setErrorText] = useState<string | null>(null)

    useEffect(() => {
        if(cartProduct && cartProduct.floating_sum){
            setFloatingSumAmount(cartProduct.floating_sum)
        }
    }, [cartProduct])

    return {
        floatingSumAmount,
        setFloatingSumAmount,
        errorText,
        setErrorText,
    }
}

export interface IUseFloatingSumType {
    floatingSumAmount: number
    setFloatingSumAmount: (amount: number) => void
    errorText: string | null
    setErrorText: (text: string | null) => void
}

import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";

import {ThemeModal} from "../../../helpers/ThemeComponents";
import useAppContext from "../../../useAppContext";

interface IScanReceiptExitPopConfirmProps {
    show: boolean
    setShow: (value: boolean) => void
    confirmCallback: () => void
}

export default function ScanReceiptExitPopConfirm(props: IScanReceiptExitPopConfirmProps) {
    const {localisation: {global, receipt}} = useAppContext()

    return (
        <ThemeModal
            open={props.show}
            setOpen={props.setShow}
            title={receipt.scanReceiptExitHeader}
            fullWidth
            maxWidth={'xs'}
        >
            {receipt.scanReceiptExitWarning}

            <DialogActions>
                <Button
                    variant={'text'}
                    onClick={() => props.setShow(false)}
                >
                    {global.cancelButton}
                </Button>
                <Button
                    variant={'contained'}
                    onClick={props.confirmCallback}
                >
                    {global.confirmButton}
                </Button>
            </DialogActions>
        </ThemeModal>
    )
}

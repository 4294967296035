import {TgLink} from "../../../helpers/ThemeComponents";
import useLocalisation from "../../../hooks/localisation/useLocalisation";

interface IInvoiceErrorProps {
    error: string
    redirectClient: () => any
    clientRedirectUrl: string | null
}

export default function InvoiceError(props: IInvoiceErrorProps) {
    const localisation = useLocalisation()

    return (
        <div className={'text-center'}>
            <div className={'mb-2 fw-bold'}>{props.error}</div>
            {props.clientRedirectUrl &&
                <div>
                    <TgLink onClick={props.redirectClient}>
                        {localisation.global.goBack}
                    </TgLink>
                </div>
            }
            <div>
                <TgLink onClick={() => window.location.reload()}>
                    {localisation.global.reloadPage}
                </TgLink>
            </div>
        </div>
    )
}

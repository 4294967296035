import {useState, useMemo, useCallback} from "react";
import {DialogServiceType} from "./types";

export default function useDialogService(): DialogServiceType {
    const [dialogText, setDialogText] = useState<string>('')
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [callback, setCallback] = useState<Function | null>(null)
    const [dialogTitle, setDialogTitle] = useState<string>('')

    const show = useCallback((
        text: string,
        title: string,
        callback: Function | null = null
    ) => {
        setDialogTitle(title)
        setDialogText(text)
        setShowDialog(true)
        setCallback(() => callback)
    }, [])

    return useMemo(() => ({
        show, showDialog, dialogText, callback, setShowDialog, dialogTitle,
    }), [show, showDialog, dialogText, callback, setShowDialog, dialogTitle])
}

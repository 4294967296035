import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import useLocalisation from "../../hooks/localisation/useLocalisation";
import DetailedProductModal from "../menu/product/DetailedProductModal";
import { TgButton } from "../../helpers/ThemeComponents";
import { useSelectedStoreContext } from "../SelectedStore/context";
import changePage from "../../helpers/changePage";
import Products from "../menu/product/Products";
import ShopFilters from "../menu/ShopFilters";
import { CircularProgress } from "@mui/material";
import useAppContext from "../../useAppContext";
import useViewService from "../../services/useViewService";
import useFiltersBarSettings from "../menu/hooks/useFiltersBarSettings";

export default function Favorites() {
	const localisation = useLocalisation();
	const {
		loadingManager: { isSomethingLoading },
	} = useAppContext();
	const { selectedStore, favoritesService } = useSelectedStoreContext();

	const viewService = useViewService();
	const filtersSettings = useFiltersBarSettings(viewService);

	return (
		<>
			<MDBRow>
				{favoritesService.isEmpty &&
				!favoritesService.search &&
				!favoritesService.isLoading ? (
					<MDBCol size={"12"} className={"text-center theme-text"}>
						<div className={"mb-2"}>{localisation.profile.noFavoritesHeader}</div>
						<TgButton onClick={() => changePage(`/shop/${selectedStore.id}/menu`)}>
							{localisation.cart.cartAddMore}
						</TgButton>
					</MDBCol>
				) : (
					<>
						<div className={"w-100 mt-0 px-1 px-md-2 mb-4"}>
							<ShopFilters
								filtersSettings={filtersSettings}
								disabledFilters={true}
								disabledSort={true}
								isFavorites={true}
							/>
						</div>
						{favoritesService.isLoading && !isSomethingLoading && (
							<div className={"w-100 text-center"}>
								<CircularProgress color={"primary"} />
							</div>
						)}
						{favoritesService.favorites?.favorite_products?.length && (
							<Products isFavorites={true} view={viewService.view} />
						)}
					</>
				)}
			</MDBRow>

			<DetailedProductModal />
		</>
	);
}

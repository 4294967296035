import { useCallback, useMemo, useState, useRef } from "react";
import { AttributeError, AttributesViewType, SelectedAttribute } from "../types";
import { Attribute, AttributeGroup, Product } from "../../../../../api/shop/basic/types";
import useLocalisation from "../../../../../hooks/localisation/useLocalisation";
import { AttributeComponent } from "../index";
import { CartProduct, CreateCartAttributeData } from "../../../../../api/shop/cart/types";
import { SetState } from "../../../../../types";
import AttributeErrorPopover from "./AttributeErrorPopover";
import { Box, Typography } from "@mui/material";
import Interweave from "../../../../../features/Interweave";

interface IAttributeGroupProps {
	attrGroup: AttributeGroup;
	product: Product;
	cartProduct: CartProduct | null;
	attrError: AttributeError[] | null;
	setAttrError: SetState<AttributeError[] | null>;
	selectedAttributes: CreateCartAttributeData[];
	attributeGroups: AttributeGroup[];
	selectAttribute: (attributeId: number, quantity?: number | undefined) => any;
	unselectAttribute: (attributeId: number, needSelectAnotherAttrId: number | null) => any;
	setAttributeQuantity: (attributeId: number, quantity: number) => any;
}

export default function AttributeGroupComponent(props: IAttributeGroupProps) {
	const localisation = useLocalisation();
	const groupTitleRef = useRef<HTMLDivElement>(null);
	const [popoverAnchor, setPopoverAnchor] = useState<any>(null);
	const [popoverText, setPopoverText] = useState<string | null>(null);

	const computedGroupSubTitle = (attrGroup: AttributeGroup) => {
		let text = "";

		// Если поле обязательное
		if (attrGroup.min > 0) {
			// text += `${localisation.global.requiredAttribute} `
			if (attrGroup.min > 1)
				text += `${localisation.global.minAttributes.replace("{qty}", attrGroup.min.toString())} `;
		}

		if (attrGroup.max && attrGroup.max > 1) {
			if (attrGroup.max > attrGroup.min) {
				text += ` ${localisation.global.maxAttributes.replace("{qty}", attrGroup.max.toString())}`;
			}
		} else if (attrGroup.min > 0) {
			text += ` ${localisation.global.requiredOneAttribute}`;
		}

		return text;
	};

	const getAttributeById = useCallback(
		(attrId: number): Attribute | null => {
			return (
				props.attributeGroups
					?.find(ag => ag.attributes.some(attr => attr.id === attrId))
					?.attributes?.find(attr => attr.id === attrId) ?? null
			);
		},
		[props.attributeGroups]
	);

	const getSelectedAttribute = useCallback(
		(attrId: number): SelectedAttribute | null => {
			const selectedAttr = props.selectedAttributes.find(x => x.attribute_id === attrId);
			console.log("*** SELECTED ATTR", selectedAttr);
			if (!props.product?.attribute_groups || !selectedAttr) return null;

			const attribute = getAttributeById(attrId);

			if (!attribute) return null;

			return {
				...selectedAttr,
				attribute: attribute,
			};
		},
		[getAttributeById, props.product?.attribute_groups, props.selectedAttributes]
	);

	const computedViewType = useMemo(() => {
		if (props.attrGroup.min === 1 && props.attrGroup.max === 1) {
			return AttributesViewType.Radio;
		} else if (props.attrGroup.min === 0 && props.attrGroup.max === 1) {
			return AttributesViewType.RadioWithDefault;
		} else if (props.attrGroup.min >= 1 && props.attrGroup.max && props.attrGroup.max > 1) {
			return AttributesViewType.CheckboxWithQuantity;
		} else if (props.attrGroup.max && props.attrGroup.max > 1) {
			return AttributesViewType.Checkbox;
		} else {
			return AttributesViewType.Default;
		}
	}, [props.attrGroup.max, props.attrGroup.min]);

	return (
		<Box pl={1}>
			<div className={"pb-1 border-bottom"}>
				<Typography fontWeight={"bold"} variant={"body1"}>
					{props.attrGroup.name}
					{props.attrGroup.min >= 1 && "*"}
				</Typography>
				{props.attrGroup.min >= 1 && (
					<Typography variant={"body2"} color={"var(--mui-palette-warning-main)"}>
						{localisation.global.requiredAttribute}
					</Typography>
				)}
				<Typography ref={groupTitleRef} variant={"body2"} color={"text.secondary"}>
					<Interweave content={computedGroupSubTitle(props.attrGroup)} />
				</Typography>
			</div>
			{computedViewType === AttributesViewType.RadioWithDefault && (
				<AttributeComponent
					key={null}
					product={props.product}
					attr={null}
					attrGroup={props.attrGroup}
					getSelectedAttribute={getSelectedAttribute}
					attrError={props.attrError}
					setAttrError={props.setAttrError}
					selectedAttributes={props.selectedAttributes}
					selectAttribute={props.selectAttribute}
					unselectAttribute={props.unselectAttribute}
					setAttributeQuantity={props.setAttributeQuantity}
					viewType={computedViewType}
					setErrorAnchor={setPopoverAnchor}
					setPopoverText={setPopoverText}
					popoverText={popoverText}
				/>
			)}
			{props.attrGroup.attributes
				.sort((a, b) => a.id - b.id)
				.map(attr => (
					<AttributeComponent
						key={attr.id}
						product={props.product}
						attr={attr}
						attrGroup={props.attrGroup}
						getSelectedAttribute={getSelectedAttribute}
						attrError={props.attrError}
						setAttrError={props.setAttrError}
						selectedAttributes={props.selectedAttributes}
						selectAttribute={props.selectAttribute}
						unselectAttribute={props.unselectAttribute}
						setAttributeQuantity={props.setAttributeQuantity}
						viewType={computedViewType}
						setErrorAnchor={setPopoverAnchor}
						setPopoverText={setPopoverText}
						popoverText={popoverText}
					/>
				))}

			<AttributeErrorPopover
				text={popoverText}
				attrGroup={props.attrGroup}
				anchor={popoverAnchor}
			/>
		</Box>
	);
}

import { useCallback, useMemo, useState } from "react";

import { ExternalLogin, ExternalLoginPurpose, ExternalLoginType } from "../../../api/auth/types";
import { Button } from "@mui/material";
import useAppContext from "../../../useAppContext";
import { ThemeModal } from "../../../helpers/ThemeComponents";
import ExternalLoginWidget from "../../../auth/Autorisation/external/ExternalLoginWidget";
import { IScanReceiptBase } from "../types";
import f from "../../../helpers/formatText";
import { getErrorText } from "../../../helpers/errors";

interface IScanReceiptLandingExternalLoginProps extends IScanReceiptBase {
	type: ExternalLoginType;
	purpose: ExternalLoginPurpose;
	extraData: Object;
	botId: number;
	setLoggedInByMessanger: (value: boolean) => void;
	setExternalLoginError: (value: string | null) => void;
	setShowAuthModal: (value: boolean) => void;
}

export default function ScanReceiptExternalLogin(props: IScanReceiptLandingExternalLoginProps) {
	const {
		localisation: { global, receipt, auth },
		authService: { onLogin },
	} = useAppContext();

	const [showModal, setShowModal] = useState(false);

	const setExternalLoginError = props.setExternalLoginError;
	const setLoggedInByMessanger = props.setLoggedInByMessanger;
	const setShowAuthModal = props.setShowAuthModal;
	const onChange = useCallback(
		(data: ExternalLogin) => {
			if (data.status === "success") {
				if (data.logged_in_token_data) {
					onLogin(data.logged_in_token_data);
					setLoggedInByMessanger(true);
					setExternalLoginError(null);
				}
			}
			if (data.status === "error") {
				setExternalLoginError(receipt.scanReceiptExternalLoginError);
				setShowAuthModal(false);
				setShowModal(false);
			}
		},
		[
			onLogin,
			setLoggedInByMessanger,
			setExternalLoginError,
			setShowAuthModal,
			receipt.scanReceiptExternalLoginError,
		]
	);

	const onError = useCallback(
		(err: any) => {
			const errText = err === null ? null : getErrorText(err, auth.unknownError);
			if (!!errText) return setExternalLoginError(errText);
		},
		[auth.unknownError, setExternalLoginError]
	);

	const computedContinueUrl = useMemo(() => {
		let url = "";
		if (!window.location.pathname.includes("scan_receipt")) {
			if (props.service.storeId)
				url = window.location.origin + `/shop/${props.service.storeId}/menu`;
			else url = window.location.origin + `/shop/menu`;
		}

		return url;
	}, [props.service.storeId]);

	return (
		<>
			<Button variant={"contained"} onClick={() => setShowModal(true)} fullWidth={true}>
				{f(receipt.scanReceiptContinueWith, {
					messanger: global[`${props.type}MessangerName`],
				})}
			</Button>

			<ThemeModal
				open={showModal}
				setOpen={setShowModal}
				title={f(receipt.scanReceiptContinueWith, {
					messanger: global[`${props.type}MessangerName`],
				})}
			>
				<ExternalLoginWidget
					type={props.type}
					purpose={props.purpose}
					onChange={onChange}
					extra_data={props.extraData}
					bot_id={props.botId}
					onError={onError}
					continue_url={computedContinueUrl}
				/>
			</ThemeModal>
		</>
	);
}

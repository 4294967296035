import { useShopContext } from "./context";
import OpenAIChatButton from "../components/AIChat/AIChatWindow/OpenAIChatButton";
import { useSelectedStoreContext } from "./SelectedStore/context";
import { Box, Button } from "@mui/material";

import useScreenService from "../services/useScreenService";
import CartButton from "./cart/CartButton";
import classnames from "classnames";
import DeleteIcon from "@mui/icons-material/Delete";
import { ClearCartButton } from "./cart/ShopCart";
import ScanReceiptButton from "../features/Receipt/scan/ScanReceiptButton";
import useAppContext from "../useAppContext";
import { useMemo } from "react";

export default function BottomButtons() {
	const { scanReceiptService, appearance } = useAppContext();
	const { brandInfo, isEMenu } = useShopContext();
	const { isMobile } = useScreenService();
	const { cartService } = useSelectedStoreContext();

	const computedButtonMode = useMemo(() => {
		return (
			appearance.appearanceState?.mobile_cart_button_mode ||
			appearance.appearanceQuery?.mobile_cart_button_mode
		);
	}, [
		appearance.appearanceQuery?.mobile_cart_button_mode,
		appearance.appearanceState?.mobile_cart_button_mode,
	]);

	const computedIsCartEnabled = useMemo(() => {
		return isMobile && computedButtonMode === "bottom" && !isEMenu;
	}, [computedButtonMode, isEMenu, isMobile]);

	const computedNeedCartButton = useMemo(() => {
		return computedIsCartEnabled && !cartService.isEmpty;
	}, [cartService.isEmpty, computedIsCartEnabled]);

	return (
		<>
			<Box
				className={classnames("shop-bottom-buttons", {
					"cart-enabled": computedIsCartEnabled,
				})}
				display={"flex"}
				alignItems={"center"}
				gap={2}
			>
				{computedIsCartEnabled && (
					<Box
						ref={cartService.cartRef}
						flex={1}
						display={"flex"}
						justifyContent={"center"}
						alignItems={"stretch"}
						className={classnames("bottom-cart-wrapper", {
							"d-none": !computedNeedCartButton,
						})}
					>
						<CartButton />
						<ClearCartButton>
							<Button
								color={"primary"}
								variant={"contained"}
								aria-label={"delete"}
								sx={{ minWidth: "auto", p: 1, ml: 1, height: "100%" }}
							>
								<DeleteIcon />
							</Button>
						</ClearCartButton>
					</Box>
				)}

				{brandInfo.scan_receipts_settings?.enabled && (
					<ScanReceiptButton service={scanReceiptService} />
				)}
				{brandInfo.is_enabled_ai && <OpenAIChatButton noFixed={true} />}
			</Box>
		</>
	);
}

import {
	Experimental_CssVarsProvider as MaterialCssVarsProvider,
	THEME_ID as MATERIAL_THEME_ID,
	useColorScheme as useMaterialColorScheme,
	CssBaseline,
} from "@mui/material";
import { useColorScheme as useJoyColorScheme } from "@mui/joy/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import { useEffect, useState } from "react";
import useAppContext from "../useAppContext";

type groupColorSchemaProviderProps = {
	children: any;
};

export default function GroupColorSchemaProvider({ children }: groupColorSchemaProviderProps) {
	const [mounted, setMounted] = useState(false);
	const { appearance } = useAppContext();

	useEffect(() => {
		// fix for theme not working in safari
		setMounted(true);
	}, []);

	return (
		<MaterialCssVarsProvider
			colorSchemeNode={document.documentElement}
			defaultMode={appearance.appearanceState?.theme_mode || appearance.computedTheme.mode}
			theme={{ [MATERIAL_THEME_ID]: appearance.computedMuiTheme }}
			disableStyleSheetGeneration={!mounted}
		>
			<JoyCssVarsProvider
				defaultMode={
					appearance.appearanceState?.theme_mode || appearance.computedTheme.mode
				}
			>
				<SetDefaultMode
					defaultMode={
						appearance.appearanceState?.theme_mode || appearance.computedTheme.mode
					}
				/>
				<CssBaseline enableColorScheme />
				{children}
			</JoyCssVarsProvider>
		</MaterialCssVarsProvider>
	);
}

function SetDefaultMode({ defaultMode }: { defaultMode: "light" | "dark" }) {
	const { setMode: setMaterialMode } = useMaterialColorScheme();
	const { setMode: setJoyMode } = useJoyColorScheme();

	useEffect(() => {
		setMaterialMode(defaultMode);
		setJoyMode(defaultMode);
	}, [defaultMode, setMaterialMode, setJoyMode]);

	return null;
}

import { useEffect, useMemo, useState } from "react";
import useScreenService from "./useScreenService";
import { SetState } from "../types";
import useAppContext from "../useAppContext";

export default function useViewService(): ViewServiceType {
	const { isMobile } = useScreenService();
	const { appearance } = useAppContext();

	const computedBrandView = useMemo(() => {
		return isMobile
			? appearance.appearanceState?.mobile_view || appearance.appearanceQuery?.mobile_view
			: appearance.appearanceState?.desktop_view || appearance.appearanceQuery?.desktop_view;
	}, [
		appearance.appearanceQuery?.desktop_view,
		appearance.appearanceQuery?.mobile_view,
		appearance.appearanceState?.desktop_view,
		appearance.appearanceState?.mobile_view,
		isMobile,
	]);

	const computedDefaultView = useMemo(() => {
		return computedBrandView?.split("_")[1] as ViewType;
	}, [computedBrandView]);

	const computedToggleEnabled = useMemo(() => {
		return !computedBrandView?.startsWith("only");
	}, [computedBrandView]);

	const [view, setView] = useState<ViewType>(computedDefaultView);

	useEffect(() => {
		if (!view && computedDefaultView) {
			setView(computedDefaultView);
		} else {
			if (!computedToggleEnabled && view !== computedDefaultView) {
				if (computedDefaultView !== "grid" && computedDefaultView !== "list") {
					setView("grid");
				} else {
					setView(computedDefaultView);
				}
			}
		}
	}, [view, isMobile, computedToggleEnabled, computedDefaultView]);

	return { view, setView, toggleEnabled: computedToggleEnabled };
}

export type ViewType = "grid" | "list";

export type ViewServiceType = {
	view: ViewType;
	setView: SetState<ViewType>;
	toggleEnabled: boolean;
};

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useAppContext from "../../../useAppContext";
import api from "../../../api";
import { Store } from "../../../api/shop/basic/types";
import { useMemo } from "react";
import useUserIncustCustomerData from "../../../hooks/incust/useUserIncustCustmerData";
import { IUseIncustPayData } from "../types";

export default function useIncustPayDataTopup(
	selectedStore: Store | null,
	productId?: number | null
): IUseIncustPayData {
	const {
		lang,
		brandInfo,
		authService: { user },
	} = useAppContext();
	const { customerData } = useUserIncustCustomerData(selectedStore?.id);

	const incustPayData = useQuery({
		queryKey: ["incust_pay_data_topup", selectedStore?.id, lang, user, customerData, productId],
		queryFn: async () => {
			const response = await api.shop.payments.getIncustPayData(
				selectedStore?.id || null,
				productId,
				true
			);
			return response.data;
		},
		enabled: brandInfo?.loyalty_info?.loyalty_enabled && !!selectedStore,
		initialData: null,
		placeholderData: keepPreviousData,
		// refetchOnMount: false,
		// retryOnMount: false,
	});

	const computedIsFetching = useMemo(() => {
		return incustPayData.isFetching;
	}, [incustPayData.isFetching]);

	return {
		incustPayData: incustPayData.data,
		isLoading: computedIsFetching,
		refetchPayData: incustPayData.refetch,
	};
}

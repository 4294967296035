import TgRedirectButton from "./buttons/MessangerRedirectButton";
import DownloadTgButton from "./buttons/DownloadTgButton";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import { MenuInStore } from "../../api/menuInStore/types";
import { Box, Typography } from "@mui/material";
import Interweave from "../../features/Interweave";

interface QrMenuRedirectTgProps {
	menuInStore: MenuInStore;
}

export default function QrMenuRedirectTg({ menuInStore }: QrMenuRedirectTgProps) {
	const { qrMenu } = useLocalisation();

	return (
		<Box mt={2}>
			<Typography borderBottom={1} borderColor={"divider"} pl={4} pb={2} fontWeight={"bold"}>
				<Interweave content={menuInStore.texts.redirect.only_tg_text} />
			</Typography>
			<TgRedirectButton buttonName={qrMenu.openMenuInTg} byLink={true} />
			<DownloadTgButton buttonName={qrMenu.downloadTg} />
		</Box>
	);
}

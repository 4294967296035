import {useEffect, useRef} from "react";
import {Alert, Box, Button, Typography} from "@mui/material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import {ThemeModal} from "../../../../helpers/ThemeComponents";
import {IUseProductGiftType} from "../hooks/useProductGift";
import {LoadingButton} from "@mui/lab";
import useAppContext from "../../../../useAppContext";
import {ProductGiftAwards} from "./ProductGift";
import WalletIcon from "@mui/icons-material/Wallet";
import changePage from "../../../../helpers/changePage";
import {useSelectedStoreContext} from "../../../SelectedStore/context";
import { Markup } from "interweave";
import f from "../../../../helpers/formatText";
import {Product} from "../../../../api/shop/basic/types";
import ImageStub from "../../../../features/ImageStub";

interface IProductGiftModal {
    gift: IUseProductGiftType
    product: Product
}

export default function ProductGiftModal(props: IProductGiftModal) {
    const {localisation: {menu}} = useAppContext()
    const {productsService, selectedStore} = useSelectedStoreContext()

    const onClose = () => {
        if(props.gift.isSuccessBuy){
            productsService.selectProduct(null)
        }
        props.gift.setShowGiftModal(false)
    }

    useEffect(() => {
        if(props.gift.isSuccessBuy && props.gift.order){
            changePage(`/shop/${selectedStore.id}/orders/${props.gift.order.id}`, {product_id: null})
        }
    }, [props.gift.isSuccessBuy, props.gift.order, selectedStore.id])

    return (
        <ThemeModal
            open={props.gift.showGiftModal}
            setOpen={props.gift.setShowGiftModal}
            title={props.gift.computedModalTitle}
            onClose={onClose}
            maxWidth={'xs'}
        >
            <Box>
                {props.gift.isSuccessBuy && (
                    <>
                        {props.product.image_url ? (
                            <img
                                width={'100%'}
                                height={'auto'}
                                src={props.product.image_url}
                                alt={props.product.name}
                            />
                        ) : (
                            <ImageStub ratio={[1,1]} emptyStub={false} />
                        )}
                    </>
                )}

                <Box mt={props.gift.isSuccessBuy ? 2 : 0}>
                    {!props.gift.isSuccessBuy && (
                        <Typography>
                            {props.product.price > 0 ? (
                                <Markup
                                    tagName={'span'}
                                    content={f(menu.giftConfirmHeader, {"sum": props.gift.computedBonusesSumText})}
                                />
                            ) : (
                                menu.giftConfirmZeroPriceHeader
                            )}
                        </Typography>
                    )}

                    <ProductGiftModalAlerts {...props} />
                    {props.gift.isSuccessBuy && (
                        <ProductGiftAwards gift={props.gift} awarded={true} />
                    )}
                    <ProductGiftModalButtons onClose={onClose} {...props} />
                </Box>

            </Box>
        </ThemeModal>
    )
}

function ProductGiftModalAlerts(props: IProductGiftModal) {
    const {localisation: {menu}} = useAppContext()

    return (
        <>
            {!!props.gift.error && (
                <Alert
                    severity={'error'}
                    sx={{mt: 2}}
                >
                    {props.gift.error}
                </Alert>
            )}

            {props.gift.isSuccessBuy && (
                <Alert
                    severity={'success'}
                    sx={{mb: 2}}
                >
                    <Markup 
                        content={f(menu.giftSuccess, {"sum": props.gift.computedBonusesSumText})}
                    />
                </Alert>
            )}
        </>
    )
}

interface IProductGiftModalButtons extends IProductGiftModal {
    onClose: () => void
}

function ProductGiftModalButtons(props: IProductGiftModalButtons) {
    const {localisation: {global}} = useAppContext()

    const confirmBtnRef = useRef<HTMLButtonElement | null>(null)
    const walletBtnRef = useRef<HTMLButtonElement | null>(null)

    useEffect(() => {
        if(!!props.gift.error) confirmBtnRef.current?.scrollIntoView({behavior: "smooth"})
        if(props.gift.isSuccessBuy) walletBtnRef.current?.scrollIntoView({behavior: "smooth"})
    }, [props.gift.isSuccessBuy, props.gift.error])

    return (
        <Box width={'100%'} textAlign={'end'} sx={{mt: 2}}>
            <Button
                variant={'text'}
                color={"error"}
                sx={{mr: 2}}
                onClick={props.onClose}
            >
                {props.gift.isSuccessBuy ? global.close : global.cancelButton}
            </Button>

            {props.gift.isSuccessBuy ? (
                <Button
                    ref={walletBtnRef}
                    variant={'contained'}
                    onClick={() => changePage('/shop/profile/loyalty')}
                >
                    <WalletIcon sx={{mr: 2}} />
                    {props.gift.computedButtonText}
                </Button>
            ): (
                <LoadingButton
                    ref={confirmBtnRef}
                    variant={'contained'}
                    onClick={props.gift.handleBuy}
                    startIcon={<CheckOutlinedIcon />}
                    loading={props.gift.isLoading}
                    loadingPosition="start"
                    disabled={props.gift.isGiftSomethingLoading ||
                        !!props.gift.processedCheck?.amount_to_pay
                    }
                >
                    {global.confirmButton}
                </LoadingButton>
            )}
        </Box>
    )
}

import { MouseEvent } from "react";
import useLocalisation from "../../../../../hooks/localisation/useLocalisation";
import { AuthorisationComponentProps } from "../../../types";
import { Button } from "@mui/material";

interface TelegramLoginButtonProps extends AuthorisationComponentProps {
	onClick: (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => any;
}

export default function TelegramLoginButton(props: TelegramLoginButtonProps) {
	const localisation = useLocalisation();

	return (
		<Button variant={"outlined"} onClick={props.onClick} fullWidth>
			<div className={"w-100 d-flex external-login-container"}>
				<img
					className={"external-login-logo align-self-center"}
					alt={"telegram"}
					src={require("./TelegramLogo.png")}
				/>
				<span className={"ms-1 flex-1"} style={{ fontSize: "13px" }}>
					{localisation.auth.continueWithTelegram}
				</span>
			</div>
		</Button>
	);
}

import { EpayPaymentData } from "../../api/shop/payments/types";
import { Argument } from "./types";

export const manualSendEpay = (data: EpayPaymentData) => {
	console.log("*** HALYK DATA", data);
	//@ts-ignore
	console.log("*** HALYK", window.halyk);
	//@ts-ignore
	window.halyk.pay(data);
};

export const manualSendLiqpayForm = (data: string, signature: string) => {
	const form = document.createElement("form");
	form.style.display = "none"; // Приховування форми, щоб вона не відображалася на сторінці
	form.method = "POST"; // Встановлення методу запиту
	form.action = "https://www.liqpay.ua/api/3/checkout"; // Встановлення URL для відправлення форми

	// Створення інпутів для закодованих даних та підпису
	const dataInput = document.createElement("input");
	dataInput.type = "hidden";
	dataInput.name = "data";
	dataInput.value = data;

	const signatureInput = document.createElement("input");
	signatureInput.type = "hidden";
	signatureInput.name = "signature";
	signatureInput.value = signature;

	// Додавання інпутів до форми
	form.appendChild(dataInput);
	form.appendChild(signatureInput);

	// Додавання форми до документа
	document.body.appendChild(form);

	// Відправлення форми
	form.submit();
};

export const manualRedirectToPaymentMethod = (
	isExternal: boolean | undefined,
	url: string,
	args?: Argument[]
) => {
	if (args && args.length > 0) {
		const modal = document.createElement("div");
		modal.style.position = "fixed";
		modal.style.top = "0";
		modal.style.left = "0";
		modal.style.width = "100%";
		modal.style.height = "100%";
		modal.style.backgroundColor = "white";
		modal.style.display = "flex";
		modal.style.justifyContent = "center";
		modal.style.alignItems = "center";
		modal.style.padding = "20px 24px";
		modal.style.zIndex = "9999";

		const form = document.createElement("form");

		args.forEach(arg => {
			const label = document.createElement("label");
			label.textContent = arg.label;

			const input = document.createElement("input");
			input.name = arg.name;
			input.type = "text";
			input.style.display = "inline-flex";
			input.style.flexDirection = "column";
			input.style.position = "relative";
			input.style.minWidth = "0px";
			input.style.padding = "0px";
			input.style.margin = "0px 0px 8px";
			input.style.verticalAlign = "top";
			input.style.width = "100%";
			input.required = true;
			if (arg.value) {
				input.value = arg.value;
			}

			form.appendChild(label);
			form.appendChild(input);
			input.addEventListener("input", () => {
				const errorText = input.nextElementSibling;

				if (
					input.value.trim() !== "" &&
					errorText &&
					errorText.textContent === "Invalid value"
				) {
					errorText.remove();
					input.style.border = "1px solid #ccc";
				}
			});
		});

		const submitButton = document.createElement("button");
		submitButton.type = "button";
		submitButton.textContent = "Ok";

		submitButton.addEventListener("click", () => {
			let isValid = true;

			args.forEach(arg => {
				const input = document.querySelector(`[name=${arg.name}]`) as HTMLInputElement;
				const errorText = input.nextElementSibling;

				if (input.value.trim() === "") {
					isValid = false;

					if (!errorText || errorText.textContent !== "Invalid value") {
						input.style.border = "1px solid red";
						input.insertAdjacentHTML(
							"afterend",
							'<div style="color: red; font-size: 12px;">Invalid value</div>'
						);
					}
				} else {
					input.style.border = "1px solid #ccc";
					if (errorText && errorText.textContent === "Invalid value") {
						errorText.remove();
					}
				}
			});
			if (!isValid) return false;
			const queryParams = args
				.map(
					arg =>
						`${encodeURIComponent(arg.name)}=${encodeURIComponent((document.querySelector(`[name=${arg.name}]`) as HTMLInputElement).value)}`
				)
				.join("&");

			const finalUrl = url + "&" + queryParams;

			if (isExternal && window.Telegram?.WebApp.initDataUnsafe.user) {
				window.Telegram?.WebApp.openLink(finalUrl);
				setTimeout(() => {
					window.Telegram?.WebApp.close();
					modal.remove();
				}, 3000);
			} else {
				window.location.href = finalUrl;
				modal.remove();
			}
		});

		form.appendChild(submitButton);
		modal.appendChild(form);

		document.body.appendChild(modal);
	} else if (isExternal && window.Telegram?.WebApp.initDataUnsafe.user) {
		// External link logic
		window.Telegram.WebApp.openLink(url);
		setTimeout(() => {
			window.Telegram.WebApp.close();
		}, 3000);
	} else {
		// Regular redirection
		window.location.href = url;
	}
};

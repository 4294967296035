import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AppContextType, SetState } from "./types";
import useLoadingManager from "./features/hooks/useLoadingManager";
import useBrandService from "./services/useBrandService";
import useBotService from "./services/useBotService";
import useToastService from "./services/useToastService/useToastService";
import { getErrorText } from "./helpers/errors";
import { useLocalisationService } from "./services/useLocalisationService/useLocalisationService";
import AppLocales, { AppLocalesType } from "./AppLocales";
import useShortTokenService from "./services/useShortTokenService";
import useLangService from "./services/useLangService";
import useAuthService from "./services/useAuthService/useAuthService";
import { AuthServiceType } from "./services/useAuthService/types";
import { BrandInfo } from "./api/shop/basic/types";
import api from "./api";
import { convertLanguageCode } from "./helpers";
import { AvailableLanguage } from "./api/localisation/types";
import { DEFAULT_LANG } from "./config";
import useSidebarsService from "./shop/services/useSidebarsService";
import useProfileService from "./shop/services/useProfileService";
import useGroupService from "./services/useGroupService";
import useDialogService from "./services/useDialogService/useDialogService";
import { ShortTokenDataType } from "./api/auth/types";
import useSearchParamsFixed from "./features/hooks/useSearchParamsFixed";
import useFriendsService from "./shop/services/useFriendsService";
import useScanReceipt from "./features/Receipt/hooks/useScanReceipt";
import useOAuthIframe from "./auth/Autorisation/external/oauth/useOAuthIFrame";
import useAuthSettings from "./services/useAuthSettings";
import useAppearance from "./shop/services/useAppearanceSettings/useAppearance";

export default function AppContextProvider({ children }: { children: any }) {
	const context = useAppContentMaker();

	return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}

function useAppContentMaker(): AppContextType {
	const headerRef = useRef<HTMLDivElement>(null);
	const [headerHeight, setHeaderHeight] = useState<number | null>(null);

	const dialogService = useDialogService();
	const toastService = useToastService();
	const loadingManager = useLoadingManager({
		brand: true,
	});

	const [lang, setLang] = useLangService();

	const [pageTitle, setPageTitle] = useState<string | null>(null);

	const shortTokenData = useShortTokenService(loadingManager);
	const { brand: brandInfo, isSuspended } = useBrandService(loadingManager);
	const groupService = useGroupService(brandInfo?.group_id, lang);
	const { botId, bot } = useBotService(brandInfo, shortTokenData);

	const isBrandLoading = loadingManager.getIsLoading("brand");
	const isShortTokenLoading = loadingManager.getIsLoading("short-token");

	const localisation = useLocalisationService<AppLocalesType>(
		AppLocales,
		brandInfo?.id,
		botId,
		lang
	);
	const localisedRef = localisation.localisedRef;

	const showToast = toastService.showToast;
	const showDialog = dialogService.show;
	const showError = useCallback(
		(err: any, isDialog: boolean = false, callback: Function | null = null) => {
			if (isDialog) {
				showDialog(
					getErrorText(err, localisedRef.current.global.errUnknown),
					localisedRef.current.global.errApi,
					callback
				);
				return;
			}
			showToast({
				severity: "error",
				message: getErrorText(err, localisedRef.current.global.errUnknown),
			});
		},
		[showToast, localisedRef, showDialog]
	);

	const authService = useAuthService(
		loadingManager,
		showError,
		shortTokenData,
		isBrandLoading,
		botId,
		lang
	);

	useCheckLang(
		authService,
		setLang,
		brandInfo,
		isBrandLoading,
		isShortTokenLoading,
		localisation.availableLangs,
		shortTokenData
	);

	const isLoaded = !!lang && localisation.isLoaded;
	const { setIsLoading } = loadingManager;
	useEffect(() => {
		setIsLoading("lang", !isLoaded);
	}, [setIsLoading, isLoaded]);

	const sidebarsService = useSidebarsService();

	const telegram_user = window.Telegram?.WebApp.initDataUnsafe.user;
	const chatId = telegram_user?.id || null;

	const profileService = useProfileService(authService.user);
	const friendsService = useFriendsService(brandInfo, authService, showError);

	const scanReceiptService = useScanReceipt();

	const oauthIframe = useOAuthIframe(localisation);

	const appearance = useAppearance(brandInfo, loadingManager);

	useAuthSettings(brandInfo?.group_id);

	return useMemo(
		() => ({
			lang: lang,
			setLang: setLang,
			authService: authService,
			groupService: groupService,
			chat_id: chatId,
			bot_id: botId,
			botId: botId,
			bot: bot,
			brandInfo: brandInfo,
			pageTitle: pageTitle,
			setPageTitle: setPageTitle,
			shortTokenData: shortTokenData,
			loadingManager: loadingManager,
			toastService: toastService,
			showError: showError,
			localisation: localisation,
			sidebarsService: sidebarsService,
			profileService: profileService,
			dialogService: dialogService,
			headerRef: headerRef,
			headerHeight: headerHeight,
			setHeaderHeight: setHeaderHeight,
			friendsService: friendsService,
			scanReceiptService: scanReceiptService,
			oauthIframe: oauthIframe,
			appearance: appearance,
			isSuspended,
		}),
		[
			appearance,
			authService,
			bot,
			botId,
			brandInfo,
			chatId,
			dialogService,
			friendsService,
			groupService,
			headerHeight,
			lang,
			loadingManager,
			localisation,
			oauthIframe,
			pageTitle,
			profileService,
			scanReceiptService,
			setLang,
			shortTokenData,
			showError,
			sidebarsService,
			toastService,
			isSuspended,
		]
	);
}

function useCheckLang(
	authService: AuthServiceType,
	setLang: SetState<string | null>,
	brandInfo: BrandInfo | null,
	isBrandLoading: boolean,
	isShortTokenLoading: boolean,
	availableLangs: AvailableLanguage[],
	shortTokenData: ShortTokenDataType | null
) {
	const [searchParams, setSearchParams] = useSearchParamsFixed();
	const urlLang = searchParams.get("lang");

	const { logout } = authService;
	useEffect(() => {
		if (
			authService.isLoading ||
			isBrandLoading ||
			isShortTokenLoading ||
			(!authService.isAuthorised && !availableLangs.length)
		)
			return;

		console.log("fill lang");

		const defaultLangs: string[] = [];
		if (urlLang) {
			defaultLangs.push(urlLang);
		}
		if (shortTokenData?.lang) {
			defaultLangs.push(shortTokenData.lang);
		}
		const localLang = api.localisation.getLanguageFromLocalStorage();
		if (localLang) {
			defaultLangs.push(localLang);
		}
		defaultLangs.push(...navigator.languages.map(x => convertLanguageCode(x)));

		const getLangIfNotAuthorised = () => {
			return (
				defaultLangs.find(defaultLang =>
					availableLangs.some(availableLang => availableLang.code === defaultLang)
				) ||
				brandInfo?.default_lang ||
				DEFAULT_LANG
			);
		};

		new Promise<string>(resolve => {
			if (authService.isAuthorised) {
				console.log("fetching user lang");
				api.auth
					.getOrSetUserLang(defaultLangs)
					.then(response => {
						resolve(response.data.lang);
					})
					.catch(err => {
						console.error("An error occurred while getOrSetUserLang", err);
						if (err?.response?.status === 401) {
							logout();
						}
						resolve(getLangIfNotAuthorised());
					});
			} else {
				console.log("not authorised");
				resolve(getLangIfNotAuthorised());
			}
		}).then(newLang => {
			setLang(newLang);
			if (urlLang) {
				setSearchParams(prev => {
					prev.delete("lang");
					return prev;
				});
			}
		});
	}, [
		authService.isAuthorised,
		authService.isLoading,
		availableLangs,
		brandInfo?.default_lang,
		isBrandLoading,
		isShortTokenLoading,
		logout,
		setLang,
		setSearchParams,
		shortTokenData?.lang,
		urlLang,
	]);
}

export const AppContext = createContext<AppContextType | null>(null);

import { ITgRedirectButtonProps } from "../types";
import QrMenuButtonWrapper from "./QrMenuButtonWrapper";
import { useShopContext } from "../../context";
import useAppContext from "../../../useAppContext";

export default function MessangerRedirectButton(props: ITgRedirectButtonProps) {
	const { bot } = useAppContext();
	const { menuInStoreService } = useShopContext();

	const onClick = () => {
		if (props.byLink) {
			if (bot?.bot_type === "telegram")
				return window.open(menuInStoreService.computedTgLinkMe(), "_blank");
			if (bot?.bot_type === "whatsapp")
				return window.open(menuInStoreService.computedWhatsappLinkMe());
		}
		menuInStoreService.processRedirectMode(() => {}, true);
	};

	return <QrMenuButtonWrapper onClick={onClick}>{props.buttonName}</QrMenuButtonWrapper>;
}

import {useSearchParams as useSearchParamsOrig} from "react-router-dom";
import {useCallback, useEffect, useRef} from "react";
import {SetState} from "../../types";
import {router} from "../../index";


export default function useSearchParamsFixed (): [URLSearchParams, SetState<URLSearchParams>] {
    const [searchParamsState, setSearchParamsStateBroken] = useSearchParamsOrig()
    const setSearchParamsStateRef = useRef(setSearchParamsStateBroken)

    useEffect(() => {
        setSearchParamsStateRef.current = setSearchParamsStateBroken
    }, [setSearchParamsStateBroken]);

    const setSearchParams = useCallback(
        (value: URLSearchParams | ((prevState: URLSearchParams) => URLSearchParams)) => {
            if (typeof value === 'function') {
                setSearchParamsStateRef.current(prevState => {
                    const currentParams = new URLSearchParams(router.state.location.search)
                    const result = value(currentParams)

                    if (result.toString() === prevState.toString()) {
                        return prevState
                    }
                    return result
                })
            }  else {
                setSearchParamsStateRef.current(value)
            }
        },
        []
    )

    return [searchParamsState, setSearchParams]
}

import useLocalisation from "../../../hooks/localisation/useLocalisation";
import {IQrMenuNeedRegistrationAlertModalProps} from "../types";
import {TgButton, ThemeModal} from "../../../helpers/ThemeComponents";
import NeedRegistrationBase from "./NeedRegistrationBase";

export default function QrMenuNeedRegistrationAlertModal(props: IQrMenuNeedRegistrationAlertModalProps) {
    const localisation = useLocalisation()

    return (
        <ThemeModal
            open={props.show}
            setOpen={props.setShow}
            title={localisation.qrMenu.needRegistration}
        >
            <NeedRegistrationBase setShow={props.setShow} messageText={localisation.qrMenu.needRegistrationMsg} />
            <div className="text-end px-2 my-3">
                <TgButton onClick={() => props.setShow(false)}>
                    {localisation.global.close}
                </TgButton>
            </div>
        </ThemeModal>
    )
}

import OAuthConsent from "./OAuthConsent";
import { NonModalDialogPage } from "../../../../../features/NonModalDialog";
import { Alert, Box } from "@mui/material";
import { OAuthRegisterData, OAuthRegisterToken } from "../../../../../api/auth/types";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../../api";
import useAppContext from "../../../../../useAppContext";
import { useCallback, useEffect, useState } from "react";
import { oauthLoginState } from "../../options/state";
import { useSetRecoilState } from "recoil";
import useSearchParamsFixed from "../../../../../features/hooks/useSearchParamsFixed";
import f from "../../../../../helpers/formatText";

export default function OAuthConsentPage() {
	const [searchParams] = useSearchParamsFixed();
	const token = decodeURIComponent(searchParams.get("token") || "");

	const {
		loadingManager: { setIsLoading },
		localisation: { auth },
		setPageTitle,
	} = useAppContext();

	const setOAuthLogin = useSetRecoilState(oauthLoginState);

	const [isError, setIsError] = useState<string | null>(null);

	const { data: registerData } = useQuery<OAuthRegisterData | null>({
		queryKey: ["oauth-register-token", token],
		queryFn: async () => {
			setIsError(null);
			if (!token) return null;

			setIsLoading("oauth-register-token", true);
			try {
				const payload: OAuthRegisterToken = {
					oauth_token: token,
				};
				const response = await api.auth.userInfoByOauthToken(payload);
				setOAuthLogin({
					oauthToken: token || "",
					firstName: response.data.first_name || "",
					lastName: response.data.last_name || "",
					email: response.data.email || "",
				});

				return response.data;
			} catch (err: any) {
				setIsError(err.response?.data?.detail || "");
				return null;
			} finally {
				setIsLoading("oauth-register-token", false);
			}
		},
		initialData: null,
		enabled: !!token,
	});

	const onLoginCallback = useCallback(() => {
		window.location.href = registerData?.continue_url || registerData?.host || "/";
	}, [registerData?.continue_url, registerData?.host]);

	useEffect(() => {
		setPageTitle(auth.authorisationModalHeader);
	}, [auth.authorisationModalHeader, setPageTitle]);

	return (
		<Box sx={{ mt: 4 }}>
			<NonModalDialogPage header={auth.authorisationModalHeader}>
				<Box p={3}>
					{!!registerData && <OAuthConsent onLoginCallback={onLoginCallback} />}
					{isError && (
						<>
							{isError && (
								<Alert severity={"error"}>
									{f(auth.oauthConsentPageError, { error: isError })}
								</Alert>
							)}
						</>
					)}
				</Box>
			</NonModalDialogPage>
		</Box>
	);
}

import { RefObject, useMemo, useRef } from "react";
import { InternalWidgetUrl } from "../../../../../config";
import { LocalisationServiceType } from "../../../../../services/useLocalisationService/types";
import { AppLocalesType } from "../../../../../AppLocales";

export default function useOAuthIframe(
	localisation: LocalisationServiceType<AppLocalesType>
): IUseOAuthIframe {
	const computedParams = useMemo(() => {
		return `?unknownError=${localisation.auth.unknownError}&openFormError=${localisation.auth.openOAthFormError}&innerWidth=100%&innerHeight=47px`;
	}, [localisation.auth.openOAthFormError, localisation.auth.unknownError]);

	const computedIframeGoogleSource = useMemo(() => {
		if (!InternalWidgetUrl) return null;
		return `${InternalWidgetUrl}/oauth_google${computedParams}`;
	}, [computedParams]);

	const computedIframeAppleSource = useMemo(() => {
		if (!InternalWidgetUrl) return null;
		return `${InternalWidgetUrl}/oauth_apple${computedParams}`;
	}, [computedParams]);

	return useMemo(() => {
		return {
			computedIframeGoogleSource,
			computedIframeAppleSource,
		};
	}, [computedIframeAppleSource, computedIframeGoogleSource]);
}

export interface IUseOAuthIframe {
	computedIframeGoogleSource: string | null;
	computedIframeAppleSource: string | null;
}

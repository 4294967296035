import {ThemeModal} from "../../../helpers/ThemeComponents";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import {useShopContext} from "../../context";
import {IQrMenuTgAlertModalProps} from "../types";
import {Button} from "@mui/material";
import Interweave from "../../../features/Interweave";

export default function QrMenuRedirectModeModal(props: IQrMenuTgAlertModalProps){
    const localisation = useLocalisation()
    const {
        menuInStoreService,
    } = useShopContext()

    const tryGoToTg = () =>
        menuInStoreService.processRedirectMode(() => props.setShow(true), true)

    return (
        <ThemeModal
            open={props.show}
            setOpen={props.setShow}
            title={!props.isOnlyTg ? localisation.qrMenu.tryTgHeader : localisation.qrMenu.warning}
            disableClose={props.isOnlyTg}
        >
            <div className={'text-start px-2'}>
                {props.isOnlyTg
                    ?
                        localisation.qrMenu.redirectOnlyTgMsg
                    :
                        <div>
                            <Interweave
                                content={menuInStoreService.menuInStore?.texts?.redirect?.try_tg_text}
                            />
                        </div>
                }
            </div>

            <div className={'text-end px-2 mb-1 mt-3'}>
                {!props.isOnlyTg &&
                    <Button
                        onClick={() => props.setShow(false)}
                        sx={{mr: 2}}
                    >
                        {localisation.qrMenu.redirectWeb}
                    </Button>
                }
                <Button
                    variant={'contained'}
                    onClick={tryGoToTg}
                >
                    {localisation.qrMenu.redirectTg}
                </Button>
            </div>
        </ThemeModal>
    )
}

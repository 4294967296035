import {useState, useCallback} from "react";

export enum TokenType {
    UUID4 = 'uuid4',
}

export default function useToken(tokenType: TokenType = TokenType.UUID4): useTokenType {
    const [token, setToken] = useState<string | null>(null)

    const generate = useCallback((reset: boolean = false) => {
        if(reset) setToken(null)

        switch(tokenType){
            case TokenType.UUID4:
                setToken(generateUUID())
                break
            default:
                setToken(generateUUID())
        }
    }, [tokenType])

    const generateUUID = (): string => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
            const r: number = Math.random() * 16 | 0
            // eslint-disable-next-line no-mixed-operators
            const v: number = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    }

    return {token, generate}
}

export interface useTokenType {
    token: string | null
    generate: (reset?: boolean) => void
}

import { ThemeModal } from "../../../../helpers/ThemeComponents";
import { SetState } from "../../../../types";
import ExternalLoginWidget from "../../../../auth/Autorisation/external/ExternalLoginWidget";
import useAppContext from "../../../../useAppContext";
import { Alert } from "@mui/material";
import { useSelectedStoreOptional } from "../../../SelectedStore/context";
import { useLocation } from "react-router-dom";
import { IUseIncustServiceType } from "../../../../features/services/useIncustService/types";
import { useCallback, useMemo } from "react";
import { ExternalLogin } from "../../../../api/auth/types";
import useExternalLoginExtraData from "../../../../auth/Autorisation/external/useExternalLoginExtraData";
import f from "../../../../helpers/formatText";
import { useMessangerName } from "../../../../hooks/messanger";
import MessangerIcon from "../../../../features/MessangerIcon";

interface ConnectMessangerModalProps {
	open: boolean;
	setOpen: SetState<boolean>;
	incustService: IUseIncustServiceType;
}

export default function ConnectMessangerModal({
	open,
	setOpen,
	incustService,
}: ConnectMessangerModalProps) {
	const location = useLocation();
	const {
		bot,
		brandInfo,
		localisation,
		authService: { loadUser },
	} = useAppContext();

	const selectedStore = useSelectedStoreOptional();

	const extraData = useExternalLoginExtraData();

	const continueUrl = useMemo(() => {
		const searchParams = new URLSearchParams(location.search);
		if (incustService.bonusesRedeem) {
			searchParams.append("bonuses_redeem", incustService.bonusesRedeem.toString());
		}
		return window.location.origin + location.pathname + "?" + searchParams.toString();
	}, [
		brandInfo?.id,
		incustService.bonusesRedeem,
		location.pathname,
		location.search,
		selectedStore?.id,
	]);

	const onChange = useCallback(
		(data: ExternalLogin) => {
			if (data.status === "success") {
				loadUser().then(() => {
					setOpen(false);
				});
			} else if (data.status === "error") {
				setOpen(false);
			}
		},
		[loadUser, setOpen]
	);

	const messangerName = useMessangerName(bot?.bot_type);

	return (
		<ThemeModal
			title={
				!!bot ? (
					<>
						{f(localisation.global.loyaltyConnectMessangerModalTitle, {
							messanger_name: messangerName,
						})}
						<MessangerIcon botType={bot.bot_type} sx={{ mr: 0, ml: 1 }} />
					</>
				) : (
					""
				)
			}
			open={open}
			setOpen={setOpen}
		>
			{!bot ? (
				<Alert severity={"error"}>
					{localisation.global.loyaltyConnectMessangerBotNotConnectedError}
				</Alert>
			) : (
				<ExternalLoginWidget
					type={bot.bot_type}
					purpose={"link"}
					onChange={onChange}
					bot_id={bot.id}
					continue_url={continueUrl}
					extra_data={extraData}
				/>
			)}
		</ThemeModal>
	);
}

import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import { BotType } from "../api/bots/types";
import { SvgIconProps } from "@mui/material";

interface MessangerIconProps extends SvgIconProps {
	botType?: BotType | null | undefined;
}

export default function MessangerIcon({ botType, ...props }: MessangerIconProps) {
	switch (botType) {
		case "telegram":
			return (
				<TelegramIcon
					color={"telegram"}
					{...props}
					sx={{
						mr: 2,
						...props?.sx,
					}}
				/>
			);
		case "whatsapp":
			return (
				<WhatsappIcon
					color={"whatsapp"}
					{...props}
					sx={{
						mr: 2,
						...props?.sx,
					}}
				/>
			);
		default:
			return null;
	}
}

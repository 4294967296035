import { AuthorisationComponentProps } from "../../types";
import FormError from "../../FormError";
import { TgButton, TgLink } from "../../../../helpers/ThemeComponents";
import { useCallback } from "react";
import ExternalLoginWidget from "../ExternalLoginWidget";
import { ExternalLogin as ExternalLoginType } from "../../../../api/auth/types";
import { getErrorText } from "../../../../helpers/errors";
import useLocale from "../../../../hooks/localisation/useLocale";
import { useLocation } from "react-router-dom";
import useAppContext from "../../../../useAppContext";
import useExternalLoginExtraData from "../useExternalLoginExtraData";
import useAuthSettings from "../../../../services/useAuthSettings";
import { Box } from "@mui/material";

export default function ExternalLogin(
	props: AuthorisationComponentProps & { isOnlyAuthMethod?: boolean }
) {
	if (props.form.state !== "externalLogin") {
		throw new Error("ExternalLogin supported only for externalLogin");
	}

	const location = useLocation();

	const {
		authService: { user, onLogin, showAuthorisationModal, setShowAuthorisationModal },
		bot,
		brandInfo,
	} = useAppContext();

	const authSettings = useAuthSettings(brandInfo?.group_id);

	const locale = useLocale("auth");

	const extraData = useExternalLoginExtraData(props.externalLoginExtraData);

	const getComputedContinueUrl = () => {
		const searchParams = new URLSearchParams(location.search);
		return window.location.origin + location.pathname + "?" + searchParams.toString();
	};

	const setError = props.form.setError;

	const externalLoginCallback = props.externalLoginCallback;
	const onChange = useCallback(
		(data: ExternalLoginType) => {
			if (data.status === "success") {
				if (data.logged_in_token_data) {
					onLogin(data.logged_in_token_data);
					externalLoginCallback && externalLoginCallback();
				} else {
					setError("form", locale.unknownError);
				}
			}
		},
		[locale.unknownError, onLogin, setError, externalLoginCallback]
	);

	const onError = useCallback(
		(err: any) => {
			const errText = err === null ? null : getErrorText(err, locale.unknownError);
			setError("form", errText);
		},
		[locale.unknownError, setError]
	);

	const isVisible = props.customVisible ?? showAuthorisationModal;

	if (!bot || !isVisible) return null;

	if (user) {
		return (
			<div className={"pb-3"}>
				<div>{locale[`${bot.bot_type}LoginAuthorisedText`]}</div>
				<TgButton
					className={"mt-2 px-3"}
					onClick={() => {
						if (props.customSetVisible) {
							return props.customSetVisible(false);
						}
						setShowAuthorisationModal(false);
					}}
				>
					{locale.messangerLoginOkButton}
				</TgButton>
			</div>
		);
	}

	return (
		<Box>
			<div>
				<ExternalLoginWidget
					type={bot?.bot_type}
					purpose={props.externalLoginPurpose ? props.externalLoginPurpose : "auth"}
					onChange={onChange}
					bot_id={bot.id}
					onError={onError}
					continue_url={getComputedContinueUrl()}
					extra_data={extraData}
					disableAutoFollowMobile={props.isOnlyAuthMethod}
				/>
			</div>
			<FormError errText={props.form.errors.form} />
			{!authSettings.computedIsOnlyMessanger && (
				<div className={"form-text"}>
					<TgLink
						onClick={e => {
							e.preventDefault();
							props.form.chooseAuthMethod();
						}}
					>
						{locale.changeAuthMethodButton}
					</TgLink>
				</div>
			)}
		</Box>
	);
}

import { useState } from "react";
import { ThemeModal } from "../../helpers/ThemeComponents";
import { QrScanner } from "@yudiel/react-qr-scanner";
import InputWithEndButton from "../InputWithEndButton";
import f from "../../helpers/formatText";
import useAppContext from "../../useAppContext";
import { Alert, Box, Button, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SendIcon from "@mui/icons-material/Send";
import { TopupOptionsForm } from "./TopupOptions";
import { IUseTopupOptions } from "./useTopupOptions";
import Interweave from "../Interweave";

interface ITopupCardModalProps {
	service: IUseTopupOptions;
}

export default function TopupCardModal(props: ITopupCardModalProps) {
	const {
		localisation: { payment, global, topup },
	} = useAppContext();

	const [showScanModal, setShowScanModal] = useState<boolean>(false);

	return (
		<>
			<ThemeModal
				open={props.service.showCardInfoModal}
				setOpen={props.service.setShowCardInfoModal}
				title={f(payment.incustPayCardFoundText, {
					card_number: props.service.cardInfo?.card_number,
				})}
				onClose={() => {
					props.service.setShowCardInfoModal(false);
				}}
				sx={{
					".MuiDialogContent-root": {
						px: 0,
						py: 3,
					},
				}}
				maxWidth={"xs"}
				fullWidth
			>
				{!!props.service.cardNumber &&
				props.service.cardInfo?.card_number === props.service.cardNumber ? (
					<>
						<Link
							className={"cursor-pointer"}
							sx={{ px: 3 }}
							onClick={() => props.service.setCardNumber(null)}
						>
							{topup.anotherCard}
						</Link>

						<TopupOptionsForm
							options={props.service.computedCardOptions}
							handleChange={props.service.handleCardOptionChange}
							selectedOption={props.service.selectedCardOption}
							getBalanceString={props.service.getBalanceString}
						/>
						<Box px={3}>
							<Button
								variant={"contained"}
								fullWidth
								sx={{ mt: 2 }}
								disabled={!props.service.selectedOption}
								onClick={() => props.service.setShowCardInfoModal(false)}
							>
								{global.continue}
							</Button>
						</Box>
					</>
				) : (
					<form
						onSubmit={event => {
							event.preventDefault();
							event.stopPropagation();
							if (props.service.cardNumber) {
								props.service.setError(null);
								props.service.getCardInfo();
							}
						}}
					>
						<Box sx={{ px: 3 }}>
							<InputWithEndButton
								textFieldProps={{
									fullWidth: true,
									name: "incustPayCardNumber",
									label: f(payment.incustPayCardNumberInputLabel, {
										name: "",
									}),
									value: props.service.cardNumber,
									onChange: e => props.service.setCardNumber(e.target.value),
									InputProps: {
										endAdornment: (
											<>
												{props.service.cardNumber && (
													<IconButton
														aria-label={"clear input"}
														onClick={() =>
															props.service.setCardNumber("")
														}
														sx={{ p: 0.25 }}
													>
														<CloseIcon />
													</IconButton>
												)}
												<IconButton
													onClick={() => setShowScanModal(true)}
													sx={{ p: 0.75, mr: 0.25 }}
												>
													<QrCodeScannerIcon aria-label={"scan qr"} />
												</IconButton>
											</>
										),
									},
								}}
								isLoading={props.service.isLoading}
								endButtonProps={{
									type: "submit",
									disabled: !props.service.cardNumber,
								}}
								endButtonNode={<SendIcon />}
							/>

							{!!props.service.error && (
								<Alert sx={{ mt: 2 }} severity={"error"}>
									<Interweave content={props.service.error} />
								</Alert>
							)}
						</Box>
					</form>
				)}
			</ThemeModal>

			<ThemeModal
				open={showScanModal}
				setOpen={setShowScanModal}
				title={payment.incustPayScanCardNumberModalTitle}
			>
				<QrScanner
					containerStyle={{
						width: "min(350px, calc(100vw-16px)",
						height: "min(350px, calc(100vw-16px)",
						margin: 0,
					}}
					onDecode={result => {
						props.service.setCardNumber(result);
						setShowScanModal(false);
						props.service.getCardInfo();
					}}
					onError={error => console.log(error?.message)}
					scanDelay={1000}
				/>
			</ThemeModal>
		</>
	);
}

import { Box, Drawer } from "@mui/material";

import useAppContext from "../../../useAppContext";
import MainSidebarCategories from "./MainSidebarCategories";
import SidebarHeader from "../SidebarHeader";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SidebarButton from "../SidebarButton";
import changePage from "../../../helpers/changePage";
import {
	useSelectedStoreContextOptional,
	useSelectedStoreOptional,
} from "../../SelectedStore/context";
import ShopInfo from "../../menu/ShopInfo";

interface IMainSidebarDrawerProps {
	withStore?: boolean;
}

export default function MainSidebarDrawer(props: IMainSidebarDrawerProps) {
	const {
		sidebarsService,
		localisation: { global },
		brandInfo,
	} = useAppContext();
	const selectedStore = useSelectedStoreOptional();
	const { categoriesService } = useSelectedStoreContextOptional();

	const handleHomeClick = () => {
		if (!!selectedStore) {
			changePage(`/shop/${selectedStore.id}/menu`);
			categoriesService?.handleClick("top").then();
		}
		sidebarsService.setIsMainSidebarOpen(false);
	};

	const isShopInfo =
		!!selectedStore?.custom_fields.length && !selectedStore?.custom_fields.every(i => !i.value);

	return (
		<Drawer
			open={sidebarsService.isMainSidebarOpen}
			onClose={() => sidebarsService.setIsMainSidebarOpen(false)}
			classes={{
				paperAnchorLeft: "w-75",
			}}
			transitionDuration={300}
		>
			<SidebarHeader type={"main"} />

			{!!brandInfo?.stores_count && (
				<SidebarButton
					text={global.home}
					action={handleHomeClick}
					icon={<HomeOutlinedIcon fontSize={"medium"} />}
				/>
			)}

			{props.withStore && (
				<div className={"d-flex justify-content-between flex-column h-100"}>
					<MainSidebarCategories />
					{isShopInfo && (
						<div>
							<div
								className={"border-top sidebar-border"}
								style={{ marginTop: "5vh" }}
							></div>
							<Box className={"px-2"} sx={{ fontSize: "0.95rem" }}>
								<ShopInfo isSidebar={true} />
							</Box>
						</div>
					)}
				</div>
			)}
		</Drawer>
	);
}

import { Box, Typography } from "@mui/material";
import useAppContext from "../../../../../useAppContext";

export interface IExternalCouponCode {
	external_code: string | null | undefined;
	isCoupon?: boolean;
}

export function ExternalCouponCode(props: IExternalCouponCode) {
	const {
		localisation: { profile },
	} = useAppContext();

	const couponStyleContainer = {
		flexDirection: "column",
		gap: "5px",
	};

	const couponCodeStyle = {
		borderColor: "black",
		border: "1px dashed black",
		backgroundColor: "none",
	};

	return props.external_code ? (
		<Box
			my={2}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				...(props?.isCoupon && couponStyleContainer),
			}}
		>
			<Typography sx={{ mr: 1 }}>
				{profile.loyaltyExtCouponUseCode}:
			</Typography>
			<Typography
				px={4}
				sx={{
					fontSize: "1.2rem",
					fontWeight: 400,
					backgroundColor: "lightgrey",
					border: "1px dashed gray",
					borderRadius: "4px",
					...(props?.isCoupon && couponCodeStyle),
				}}
			>
				{props.external_code}
			</Typography>
		</Box>
	) : null;
}

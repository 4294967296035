import { Box, Typography, useColorScheme } from "@mui/material";
import { IProductTopupPropsBase } from "./types";
import CheckLine from "../../../../features/Check/CheckLine";
import useCurrency from "../../../../services/useCurrencyService";
import useAppContext from "../../../../useAppContext";
import formatCurrency from "../../../../helpers/formatCurrency";
import Interweave from "../../../../features/Interweave";
import f from "../../../../helpers/formatText";
import formatNumber from "../../../../helpers/formatNumber";

export default function ProductTopupConfirmationContent(props: IProductTopupPropsBase) {
	const {
		localisation: { check, topup },
		lang,
		brandInfo,
	} = useAppContext();
	const currency = useCurrency();
	const colorScheme = useColorScheme();

	return (
		<Box>
			<Box sx={{ mb: 2 }}>
				{props.service.product?.name && (
					<Typography fontWeight={"bold"}>{props.service.product?.name}</Typography>
				)}

				<Typography>
					<Interweave
						content={f(topup.topupAccountHeader, {
							account_name:
								props.service.optionsService.selectedOption?.specialData?.title,
						})}
					/>
				</Typography>

				{!!props.service.optionsService.selectedOption?.cardNumber && (
					<Typography>
						{topup.card}:
						<strong>
							{" "}
							"{props.service.optionsService.selectedOption?.cardNumber}"
						</strong>
					</Typography>
				)}
			</Box>

			<CheckLine>
				<span>{topup.willBeTopUpped}</span>
				<span>
					{currency
						? formatCurrency(
								props.service.optionsService.amount,
								brandInfo?.default_lang || lang,
								currency
							)
						: formatNumber(props.service.optionsService.amount)}
				</span>
			</CheckLine>

			{!!props.service.optionsService.computedChargeInfoString && (
				<CheckLine>
					<span>{props.service.optionsService.computedChargeInfoString}</span>
					<span>
						{currency
							? formatCurrency(
									props.service.optionsService.computedCharge,
									brandInfo?.default_lang || lang,
									currency
								)
							: formatNumber(props.service.optionsService.computedCharge)}
					</span>
				</CheckLine>
			)}

			<CheckLine
				fontWeight={"bold"}
				borderTop={1}
				borderColor={colorScheme.mode === "light" ? "grey.300" : "grey.600"}
			>
				<span>{check.paymentDueText}</span>
				<span>
					{currency
						? formatCurrency(
								props.service.optionsService.computedAmountWithCharge,
								brandInfo?.default_lang || lang,
								currency
							)
						: formatNumber(props.service.optionsService.computedAmountWithCharge)}
				</span>
			</CheckLine>
		</Box>
	);
}

import { useEffect, useMemo, useRef, useState } from "react";
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	CircularProgress,
	Grid,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import { Markup } from "interweave";

import useAppContext from "../../../../useAppContext";
import { IUseProductGiftType } from "../hooks/useProductGift";
import { TgLink } from "../../../../helpers/ThemeComponents";
import ProductGiftModal from "./ProductGiftModal";
import useCurrency from "../../../../services/useCurrencyService";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import f from "../../../../helpers/formatText";
import { IIncustCouponShowData } from "../../../../api/shop/loyalty/types";
import { VoucherCard } from "../../../../features/Check/CheckAwards";
import { IIncustCoupon } from "../../../loyalty/types";
import Card from "@mui/material/Card";
import CardMediaAspectRatio from "../../../../features/CardMediaAspectRatio";
import ImageStub from "../../../../features/ImageStub";
import CardHeader from "@mui/material/CardHeader";
import { Product } from "../../../../api/shop/basic/types";
import ProductFloatingSumRoot from "../ProductFloating/ProductFloatingSumRoot";
import { IUseFloatingSumType } from "../hooks/useFloatingSum";
import MessangerIconAndName from "../../../loyalty/components/IncustLoyalty/MessangerIconAndName";
import ConnectMessangerModal from "../../../loyalty/components/IncustLoyalty/ConnectMessangerModal";
import { IUseIncustServiceType } from "../../../../features/services/useIncustService/types";
import formatNumber from "../../../../helpers/formatNumber";
import CouponModal from "../../../loyalty/components/Coupon/CouponModal";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

interface IProductGift {
	gift: IUseProductGiftType;
	product: Product;
	floatingSum: IUseFloatingSumType;
}

export default function ProductGift(props: IProductGift) {
	const {
		localisation: { menu },
		authService: { user },
		brandInfo,
	} = useAppContext();
	const { cartService } = useSelectedStoreContext();

	const rootRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (rootRef.current && cartService.productVariationsState?.isAddToCart) {
			rootRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, [cartService.productVariationsState?.isAddToCart]);

	if (!brandInfo?.loyalty_info?.loyalty_enabled) {
		return (
			<Box mx={3}>
				<Alert severity={"error"} sx={{ mt: 2, mx: 1 }}>
					{menu.giftNeedLoyalty}
				</Alert>
			</Box>
		);
	}

	return (
		<Box>
			{props.product.price > 0 && !!user && <ProductGiftBalance {...props} />}

			<Box sx={{ px: 3, mt: 2 }} ref={rootRef}>
				<Box sx={{ px: 1 }}>
					{!!user && !props.gift.isOnlyAddBonuses ? (
						<>
							{props.product.floating_sum_settings?.is_enabled && (
								<Box mb={2}>
									<ProductFloatingSumRoot
										floatingSum={props.floatingSum}
										floatingSumSettings={props.product.floating_sum_settings}
										cartProduct={null}
										fromGiftOrTopup={true}
										product={props.product}
									/>
								</Box>
							)}

							{props.product.price > 0 && !props.gift.computedNotEnoughBonuses && (
								<Typography mb={2} fontWeight={"bold"}>
									<Markup
										tagName={"span"}
										content={f(menu.giftHeader, {
											sum: props.gift.computedBonusesSumText,
										})}
									/>
								</Typography>
							)}

							{!props.gift.computedNotEnoughBonuses && (
								<Box mb={2}>
									<ProductGiftAwards {...props} />
								</Box>
							)}
						</>
					) : (
						<ProductGiftErrorAlert incustService={props.gift.incustService} />
					)}
					<ProductGiftAlerts {...props} />
				</Box>
			</Box>

			<ProductGiftModal gift={props.gift} product={props.product} />
		</Box>
	);
}

interface IProductGiftAwards {
	gift: IUseProductGiftType;
	awarded?: boolean;
}

export function ProductGiftAwards(props: IProductGiftAwards) {
	const {
		localisation: { menu },
	} = useAppContext();

	const [showCouponModal, setShowCouponModal] = useState<boolean>(false);
	const [couponId, setCouponId] = useState<string | null>(null);

	const { isCustomerFetching } = useUserIncustCustomerData();

	return (
		<>
			<Box>
				{(props.gift.isGiftSomethingLoading && !props.gift.showGiftModal) ||
				isCustomerFetching ? (
					<Stack spacing={1}>
						<Skeleton variant="text" width={"40%"} />
						<Box display={"flex"}>
							<Skeleton
								variant="rectangular"
								width={"4rem"}
								height={"4rem"}
								sx={{ mr: 2 }}
							/>
							<Skeleton variant="rectangular" width={"100%"} height={"4rem"}>
								<Skeleton
									variant="text"
									sx={{ fontSize: "0.875rem" }}
									width={"100%"}
								/>
							</Skeleton>
						</Box>
					</Stack>
				) : (
					<>
						{((props.gift.processedCheck?.emitted_coupons &&
							props.gift.processedCheck?.emitted_coupons.length) ||
							props.gift.order?.incust_vouchers?.length) && (
							<Typography fontWeight={"bold"}>
								{props.awarded ? menu.giftAwardsAfterHeader : menu.giftAwardsHeader}
							</Typography>
						)}
						{!!(
							props.gift.order &&
							props.gift.order.incust_vouchers?.length &&
							props.awarded
						) ? (
							<Grid container spacing={2}>
								{props.gift.order.incust_vouchers.map(
									(voucher: IIncustCouponShowData) =>
										voucher.url &&
										voucher.title && (
											<Grid
												item
												sm={6}
												className={"w-100"}
												key={voucher.title}
											>
												<VoucherCard
													voucher={voucher}
													showCouponModal={showCouponModal}
													setShowCouponModal={setShowCouponModal}
													setCouponId={setCouponId}
												/>
											</Grid>
										)
								)}
							</Grid>
						) : (
							<>
								{props.gift.processedCheck?.emitted_coupons?.map(
									(coupon, index) => (
										<ProductGiftCouponView key={index} coupon={coupon} />
									)
								)}
							</>
						)}
					</>
				)}
			</Box>

			<CouponModal show={showCouponModal} setShow={setShowCouponModal} couponId={couponId} />
		</>
	);
}

function ProductGiftBalance(props: IProductGift) {
	const {
		lang,
		localisation: { menu },
		brandInfo,
	} = useAppContext();
	const currency = useCurrency();

	const { customerData, refetchCustomer } = useUserIncustCustomerData();

	const computedBonusItem = useMemo(() => {
		return customerData?.user_card?.bonuses?.find(b => b.currency === currency);
	}, [currency, customerData?.user_card?.bonuses]);

	useEffect(() => {
		refetchCustomer();
	}, [refetchCustomer]);

	if (props.gift.isOnlyAddBonuses) return null;

	return (
		<Grid
			container
			px={3}
			py={2}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"stretch"}
		>
			{props.gift.isGiftSomethingLoading && !props.gift.showGiftModal ? (
				<CircularProgress sx={{ width: "46px", height: "46px", my: 1 }} />
			) : (
				<>
					<Grid item xs={12}>
						<Typography mt={1} textAlign={"center"}>
							{menu.giftBonusBalance}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						textAlign={"center"}
					>
						<Typography variant={"h4"} fontWeight={"bold"}>
							{!!computedBonusItem
								? formatNumber(
										computedBonusItem.bonuses_amount,
										brandInfo?.default_lang || lang
									)
								: formatNumber(0, brandInfo?.default_lang || lang)}
						</Typography>
					</Grid>
				</>
			)}
		</Grid>
	);
}

function ProductGiftAlerts(props: IProductGift) {
	const {
		localisation: { menu },
		authService: { user },
	} = useAppContext();
	const { productsService, categoriesService } = useSelectedStoreContext();

	const handleMakePurchase = () => {
		productsService.selectProduct(null);
		categoriesService.setSelectedCategoryId(null);
	};

	if (props.gift.isOnlyAddBonuses) return null;

	return (
		<>
			{props.gift.computedNotEnoughBonuses && user && props.product.price && (
				<Alert severity={"error"} sx={{ mt: 2 }}>
					<Box>{menu.giftNotEnoughBonuses}</Box>
					<Box>
						<TgLink onClick={handleMakePurchase} color={"inherit"}>
							{menu.giftShopMore}
						</TgLink>
					</Box>
				</Alert>
			)}
			{props.gift.incustService.computedCurrencyError && (
				<Alert severity={"warning"} sx={{ mt: 2 }}>
					<Box>{props.gift.incustService.computedCurrencyError}</Box>
				</Alert>
			)}
		</>
	);
}

interface IProductGiftCouponView {
	coupon: IIncustCoupon;
}

function ProductGiftCouponView(props: IProductGiftCouponView) {
	const computedCouponImage = useMemo(() => {
		if (props.coupon.batch?.image) return props.coupon.batch.image;
		if (props.coupon.image) return props.coupon.image;
		return null;
	}, [props.coupon.batch?.image, props.coupon.image]);

	return (
		<Card sx={{ width: "100%", display: "flex", mt: 1 }} className={"no-shadow border"}>
			<Box width={"4rem"} height={"4rem"}>
				{computedCouponImage ? (
					<CardMediaAspectRatio
						aspectRatio={"1/1"}
						imageSrc={computedCouponImage}
						alt={props.coupon.title || ""}
						sx={{ flex: "1 0 auto", width: "4rem", height: "4rem" }}
					/>
				) : (
					<ImageStub emptyStub={false} ratio={[1, 1]} />
				)}
			</Box>

			<Box alignSelf={"center"}>
				<CardHeader sx={{ py: 2 }} title={<Markup content={props.coupon.title} />} />
			</Box>
		</Card>
	);
}

interface IProductGiftErrorAlert {
	incustService: IUseIncustServiceType;
}

function ProductGiftErrorAlert(props: IProductGiftErrorAlert) {
	const {
		localisation,
		authService: { user },
		bot,
	} = useAppContext();

	const [showConnectMessangerModal, setShowConnectMessangerModal] = useState(false);

	return (
		<Alert severity={"error"} sx={{ my: 2 }}>
			{!user ? (
				localisation.menu.giftNeedAuth
			) : (
				<>
					{!!bot ? (
						<>
							<AlertTitle>
								{localisation.global.incustAddBonusesOnlyErrorTitle}
							</AlertTitle>

							{localisation.global.incustAddBonusesOnlyErrorDescription}
							<MessangerIconAndName messanger={bot.bot_type} />
							<Box
								gap={1}
								mt={1}
								display={"flex"}
								alignItems={"stretch"}
								justifyContent={"end"}
							>
								<Button
									size={"small"}
									color={"inherit"}
									onClick={() => setShowConnectMessangerModal(true)}
								>
									{localisation.global.loyaltyConnectMessangerButton}
								</Button>
								<ConnectMessangerModal
									open={showConnectMessangerModal}
									setOpen={setShowConnectMessangerModal}
									incustService={props.incustService}
								/>
							</Box>
						</>
					) : (
						localisation.global.incustAddBonusesOnlyErrorTitle
					)}
				</>
			)}
		</Alert>
	);
}

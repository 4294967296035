import {HTMLAttributes, InputHTMLAttributes, useEffect, useRef} from "react";
import {ChatMessageType} from "./types";
import ChatMessageInput from "./ChatMessageInput";
import {CircularProgress} from "@mui/material";
import ChatDateMessages from "./ChatDateMessages";


export interface ChatProps extends HTMLAttributes<HTMLDivElement> {
    wrapperProps?: HTMLAttributes<HTMLDivElement>
    messages: ChatMessageType[]
    onSend: (text: string) => any
    header?: any
    emptyText?: string
    inputPlaceholder?: string
    isLoading?: boolean
    error?: string | null
    inputProps?: InputHTMLAttributes<HTMLInputElement>
    recommendedProductsText?: string
    needNote?: boolean
}


export default function Chat(
    {
        wrapperProps, className,
        children, messages, onSend,
        header,
        inputPlaceholder = 'Your message...',
        emptyText = 'No messages',
        recommendedProductsText,
        isLoading,
        error = null,
        inputProps,
        needNote,
        ...props
    }: ChatProps
) {
    const messagesContainerRef = useRef<HTMLDivElement | null>(null)

    if (!wrapperProps) {
        wrapperProps = {}
    }

    wrapperProps['className'] = `chat-wrapper ${wrapperProps.className || ''}`

    const messagesByDates: {[key: string]: ChatMessageType[]} = {}

    messages.sort((a, b) => {
        if (a.datetime > b.datetime) {
            return 1
        }
        if (b.datetime > a.datetime) {
            return  -1
        }
        return 0
    }).forEach((message) => {
        const dateStr = message.datetime.toLocaleDateString(undefined, {
            dateStyle: 'long'
        })

        if (!(dateStr in messagesByDates)) {
            messagesByDates[dateStr] = [message]
        } else {
            messagesByDates[dateStr].push(message)
        }
    })

    const scrollMessagesContainerToBottom = () => {
        const container = messagesContainerRef.current
        if(!container) return

        container.scrollTo({
            left: 0,
            top: container.scrollHeight,
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        scrollMessagesContainerToBottom()
    }, [messages])

    return (
        <div {...wrapperProps}>
            {typeof header === 'string' ? (
                <h3 className={'header'}>{header}</h3>
            ) : (
                !!header && header
            )}

            <div className={`chat ${className}`} {...props}>
                {children}

                {error ? (
                    <div className={'error'}>
                        <span>{error}</span>
                    </div>
                ) : (
                    messages.length ? (
                        <div className={'messages-container'} ref={messagesContainerRef}>
                            {Object.entries(messagesByDates).map(([dateStr, messages]) => {
                                return (
                                    <ChatDateMessages
                                        key={dateStr}
                                        dateStr={dateStr}
                                        messages={messages}
                                        recommendedProductsText={recommendedProductsText}
                                        needNote={needNote}
                                    />
                                )
                            })}
                        </div>
                    ) : (
                        <div className={'no-messages'}>
                            <CircularProgress color={'primary'}/>
                        </div>
                    )
                )}

                <ChatMessageInput
                    placeholder={inputPlaceholder}
                    onSend={onSend}
                    isLoading={isLoading}
                    focusEventHandler={scrollMessagesContainerToBottom}
                    {...inputProps && {...inputProps}}
                />
            </div>
        </div>
    )
}

import { Box } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

interface IBasePayButton extends LoadingButtonProps {
	isSelectedItem?: boolean;
	isCollapsed?: boolean;
}

export default function BasePayButton({
	children,
	isSelectedItem,
	isCollapsed,
	...props
}: IBasePayButton) {
	return (
		<Box color={"text.primary"}>
			<LoadingButton
				id={isSelectedItem ? "selected-base-pay-button" : "base-pay-button"}
				fullWidth
				color={"inherit"}
				disabled={props.disabled}
				loading={props.loading}
				loadingPosition={"start"}
				{...props}
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					paddingX: 2,
					paddingY: "12px",
					py: 1.3,
					borderRadius: 0,
					justifyContent: "space-between",
					textAlign: "start",
					...props.sx,
				}}
			>
				<Box flex={1} display={"flex"} alignItems={"center"} sx={{ width: "100%" }}>
					{children}
					{isSelectedItem ? (
						<Box ml={"auto"}>
							{!isCollapsed ? (
								<KeyboardArrowRightIcon
									sx={{
										color: "text.secondary",
										fontSize: "1.5rem!important",
									}}
								/>
							) : (
								<KeyboardArrowUpIcon
									sx={{
										color: "text.secondary",
										fontSize: "1.5rem!important",
									}}
								/>
							)}
						</Box>
					) : null}
				</Box>
			</LoadingButton>
		</Box>
	);
}

import { Outlet } from "react-router-dom";

import "./shop.scss";
import BotWaitAlert from "../helpers/botWaitAlert";
import ShopContextProvider, { useShopContext } from "./context";
import CookiesAgreement from "./alerts/CookiesAgreement";
import useAppContext from "../useAppContext";
import StorePlaceholder from "../components/StorePlaceholder";
import ServiceSuspendedError from "../components/ServiceSuspendedError";

export default function Shop() {
	const { botId, brandInfo, isSuspended, setPageTitle, shortTokenData, loadingManager } =
		useAppContext();

	if (isSuspended) {
		return <ServiceSuspendedError />;
	}

	if (!brandInfo) return <StorePlaceholder />;

	return (
		<ShopContextProvider
			botId={botId}
			brandInfo={brandInfo}
			setPageTitle={setPageTitle}
			shortTokenData={shortTokenData}
			loadingManger={loadingManager}
		>
			<ShopInner />
		</ShopContextProvider>
	);
}

function ShopInner() {
	const {
		brandInfo,
		authService,
		loadingManager: { getIsLoading },
	} = useAppContext();

	const { botWaitInfo, setBotWaitInfo } = useShopContext();

	const isLastStoreLoading = getIsLoading("last-store");

	if (isLastStoreLoading) return null;

	return (
		<>
			<div
				id={"shop-container"}
				style={{ minHeight: "100%" }}
				className={"px-0 d-flex flex-column"}
			>
				{brandInfo && !authService.isLoading && (
					<>
						<BotWaitAlert botWaitInfo={botWaitInfo} setBotWaitInfo={setBotWaitInfo} />
						<Outlet />
						<CookiesAgreement />
					</>
				)}
			</div>
		</>
	);
}

import {ThemeModal} from "../../helpers/ThemeComponents";
import useAppContext from "../../useAppContext";
import {SetState} from "../../types";
import ReviewComponent from "./ReviewComponent";


interface ReviewModalProps {
    show: boolean
    setShow: SetState<boolean>
}


export default function ReviewModal(props: ReviewModalProps){
    const {groupService: {group}} = useAppContext()

    return (
        <ThemeModal
            open={props.show}
            setOpen={props.setShow}
            title={group?.texts.review.leave_header}
            maxWidth={'xs'} fullWidth
        >
            <ReviewComponent
                needClose
                onClose={() => props.setShow(false)}
            />
        </ThemeModal>
    )
}

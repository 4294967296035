import {useMemo} from "react";

import ShippingFormWrapper from "./ShippingFormWrapper";
import AddressInput, {IAddressValid} from "../../../../../../features/AddressInput/AddressInput";
import {CreateOrderPayload} from "../../../../../../api/shop/order/types";
import {SetMergeState} from "../../../../../../helpers/useMergedState";
import {SelectedShipmentServiceType} from "../../../useSelectedShipmentService";
import {ShipmentPrice} from "../../../../../../api/shop/basic/types";
import {IPricesError, IZonePolygon, IZoneRadius} from "../../../../../services/useShipmentPrices";
import useAppContext from "../../../../../../useAppContext";
import {useSelectedStoreContext} from "../../../../../SelectedStore/context";
import {useShopContext} from "../../../../../context";

export interface IShippingFormProps {
    form: CreateOrderPayload
    setForm: SetMergeState<CreateOrderPayload>
    showInvalidError: boolean
    selectedShipmentService: SelectedShipmentServiceType
    getShipmentPricesById: (shipmentId: number) => ShipmentPrice[]
    computedSelectedShipmentHasZones: boolean
    polygons?: IZonePolygon[]
    radius?: IZoneRadius[]
    showMap: boolean
    setShowMap: (value: boolean) => void
    setValidAddress?: (value: IAddressValid | null) => void
    validAddress: IAddressValid | null
    pricesError: IPricesError | null
}

export default function ShippingForm(props: IShippingFormProps) {
    const {brandInfo, localisation: {orders}, lang} = useAppContext()
    const {computedCoordinates} = useSelectedStoreContext()
    const {
        addressValue, setAddressCoords,
        addressCoords, setAddressValue,
    } = useShopContext()

    const handleAddressChanged = (e: any) => setAddressValue(e.target.value)

    const computedCountries = useMemo(() => {
        return props.selectedShipmentService.selectedShipment?.map_countries
    }, [props.selectedShipmentService.selectedShipment?.map_countries])

    return (
        <ShippingFormWrapper {...props}>
            {!brandInfo?.is_get_order && props.selectedShipmentService.selectedShipment?.base_type === "delivery" &&
                (props.selectedShipmentService.selectedShipment.custom_type !== "custom_shipment" 
                || props.selectedShipmentService.selectedShipment?.need_address || 
                props.computedSelectedShipmentHasZones || props.showMap) &&
            (
                <AddressInput
                    value={addressValue}
                    onChange={handleAddressChanged}
                    label={orders.addressLabel}
                    size={'small'}
                    fullWidth={true}
                    id={'delivery_address'}
                    isError={props.showInvalidError && !addressValue}
                    helperText={orders.addressFeedback}
                    lang={lang || ""}
                    setSelectedOnLoad={!!addressValue}
                    setCoords={setAddressCoords}
                    priorityCoords={computedCoordinates}
                    needDefaultAddress={true}
                    currentCoords={addressCoords ? {lat: addressCoords[1], lon: addressCoords[0]} : null}

                    polygons={props.polygons}
                    radius={props.radius}
                    showMap={props.showMap}
                    setShowMap={props.setShowMap}

                    isGoogleEnabled={brandInfo?.is_enabled_google_maps_api || false}
                    setValidAddress={props.setValidAddress}
                    validAddress={props.validAddress}
                    pricesError={props.pricesError}
                    businessLanguage={brandInfo?.default_lang}

                    countries={computedCountries || null}
                    enabledAnyAddressFromMap={props.selectedShipmentService.selectedShipment?.enabled_any_address_from_map}
                />
            )}
        </ShippingFormWrapper>
    )
}

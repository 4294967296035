import {MDBIcon} from "mdb-react-ui-kit"

import useLocalisation from "../hooks/localisation/useLocalisation"
import ProfileButton from "../shop/Sidebars/ProfileSidebarDrawer/ProfileButton";
import MUIPopConfirm from "../features/MUIPopConfirm";
import useAppContext from "../useAppContext";
import {ReactNode} from "react";
import {BoxProps} from "@mui/material";

interface ILogoutButtonProps {
    text?: string
    customClassesText?: string
    customClassesIcon?: string
    onClosePopConfirm?: () => void
    node?: ReactNode
    nodeWrapperProps?: BoxProps
    onConfirm?: () => void
}

export const LogoutButton = (props: ILogoutButtonProps) => {
    const localisation = useLocalisation()
    const {authService: {logout}} = useAppContext()

    const handleLogout = () => {
        if(props.onConfirm) props.onConfirm()
        logout()
    }

    return (
        <>
            <MUIPopConfirm
                node={<>
                    {!!props.node
                        ? props.node
                        :
                        <>
                            <span style={{textDecoration: 'underline'}}>
                                <MDBIcon
                                    fas icon="sign-out-alt"
                                    color={'danger'}
                                    className={props.customClassesIcon ? props.customClassesIcon : ''}
                                />
                            </span>
                            {!!props.text &&
                                <span className={props.customClassesText ? props.customClassesText : 'ms-2'}>
                                    {props.text}
                                </span>
                            }
                        </>
                    }
                </>}
                nodeWrapperProps={props.nodeWrapperProps}
                cancelText={localisation.global.cancelButton}
                confirmText={localisation.global.confirmButton}
                onConfirm={handleLogout}
                message={localisation.auth.logoutMessage}
                onClose={() => {
                    if(props.onClosePopConfirm) props.onClosePopConfirm()
                }}
            />
        </>
    )
}

export default function ShopAuthButton() {
    const {
        authService: {user},
    } = useAppContext()

    if (user) {
        return (
            <div className={'d-flex cursor-pointer align-self-center'}>
                <ProfileButton />
            </div>
        )
    }

    return (
        <div className={'align-self-center'}>
            <ProfileButton isRegister={true} />
        </div>
    )
}

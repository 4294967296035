import { CheckProps } from "./types";
import {
	Box,
	Button,
	CardActions,
	CircularProgress,
	Grid,
	Typography,
	useColorScheme,
} from "@mui/material";
import useAppContext from "../../useAppContext";
import CheckInfoLine from "./CheckInfoLine";
import { IIncustCouponShowData } from "../../api/shop/loyalty/types";
import useScreenService from "../../services/useScreenService";
import { useState } from "react";
import { getPDFCouponBase64 } from "../services/useIncustService/functions";
import { downloadFromBase64 } from "../DownloadBase64";
import Card from "@mui/material/Card";
import CardMediaAspectRatio from "../CardMediaAspectRatio";
import DownloadIcon from "@mui/icons-material/Download";
import formatCurrency from "../../helpers/formatCurrency";
import useCalculatedSpecialAccountsCharges from "../../shop/loyalty/useCalculatedSpecialAccountsCharges";
import useCurrency from "../../services/useCurrencyService";
import SpecialAccountChargeTitle from "../../shop/loyalty/SpecialAccountChargeTitle";
import CouponModal from "../../shop/loyalty/components/Coupon/CouponModal";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import formatNumber from "../../helpers/formatNumber";

export default function CheckAwards({ data }: CheckProps) {
	const {
		lang,
		localisation: { check: locale, menu },
		brandInfo,
	} = useAppContext();
	const [showCouponModal, setShowCouponModal] = useState<boolean>(false);
	const [couponId, setCouponId] = useState<string | null>(null);

	const customerAccounts = useCalculatedSpecialAccountsCharges(data.customerAccounts);

	const currency = useCurrency();

	if (!data.isLoyaltyAwards) return null;

	return (
		<>
			<CheckInfoLine enabled={!!customerAccounts?.length} newLine={false}>
				<Typography variant={"subtitle2"} fontWeight={"bold"} mt={2}>
					{data.status_pay === "payed"
						? locale.loyaltyCustomerAccountsText
						: locale.loyaltyCustomerAccountsToBeReplenishedText}
					:
				</Typography>
				{customerAccounts?.map((special, idx) => (
					<Typography variant={"body2"} key={special.id}>
						{special.title || (
							<SpecialAccountChargeTitle specialAccountCharge={special} idx={idx} />
						)}
						{": "}
						<b>
							{formatCurrency(
								special.amount || 0,
								brandInfo?.default_lang || lang,
								special.currency || currency
							)}
						</b>
					</Typography>
				))}
			</CheckInfoLine>

			{data.type === "gift" ? (
				<Typography variant={"subtitle2"} fontWeight={"bold"} mt={2}>
					{menu.giftAwardsAfterHeader}
				</Typography>
			) : (
				<Typography variant={"subtitle2"} fontWeight={"bold"} mt={2}>
					{(!!data.bonuses || !!data.coupons?.length || !!data.couponsCount) &&
						(data.status_pay === "payed"
							? locale.loyaltyIssuedPerOrderText
							: locale.loyaltyToBeIssuedPerPaymentText)}
				</Typography>
			)}

			<Typography variant={"body2"}>
				<CheckInfoLine enabled={!!data.bonuses}>
					{locale.loyaltyBonusesText}{" "}
					{formatNumber(data.bonuses || 0, brandInfo?.default_lang || lang)}
				</CheckInfoLine>
				{data.type === "regular" && (
					<CheckInfoLine enabled={!!data.coupons?.length || !!data.couponsCount}>
						{locale.loyaltyVouchersText} {data.coupons?.length || data.couponsCount}
					</CheckInfoLine>
				)}
			</Typography>

			{!!data.coupons?.length && (
				<Grid container spacing={2}>
					{data.coupons.map(
						(voucher: IIncustCouponShowData) =>
							voucher.url &&
							voucher.title && (
								<Grid
									item
									sm={6}
									className={"w-100"}
									key={`${voucher.coupon_id}-${voucher.code}`}
								>
									<VoucherCard
										voucher={voucher}
										showCouponModal={showCouponModal}
										setShowCouponModal={setShowCouponModal}
										setCouponId={setCouponId}
									/>
								</Grid>
							)
					)}
				</Grid>
			)}

			<CouponModal
				show={showCouponModal}
				setShow={setShowCouponModal}
				couponId={couponId}
				orderToken={data.token}
			/>
		</>
	);
}

interface IVoucherCardProps {
	voucher: IIncustCouponShowData;
	showCouponModal: boolean;
	setShowCouponModal: any;
	setCouponId: any;
}

export function VoucherCard(props: IVoucherCardProps) {
	const colorScheme = useColorScheme();
	const { localisation, lang } = useAppContext();
	const { isMobile } = useScreenService();
	const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);

	const handleDownloadVoucher = async (voucher: IIncustCouponShowData) => {
		setIsPdfLoading(true);
		try {
			if (voucher.pdf_url && lang) {
				const fileName = `${voucher.code}.pdf`;
				const pdf_base64 = await getPDFCouponBase64(voucher.pdf_url, lang);
				if (pdf_base64) downloadFromBase64(pdf_base64, fileName);
			}
		} finally {
			setIsPdfLoading(false);
		}
	};

	const handleShow = (coupon_id: string) => {
		// window.open(url, '_blank')
		props.setCouponId(coupon_id);
		props.setShowCouponModal(true);
	};

	return (
		<Card
			className={"no-shadow border mt-1"}
			key={props.voucher.title}
			sx={{ display: "flex" }}
		>
			{props.voucher.image ? (
				<Box sx={{ width: "auto", minWidth: "4rem" }}>
					<CardMediaAspectRatio
						aspectRatio={"1/1"}
						imageSrc={props.voucher.image || ""}
						alt={props.voucher.title || ""}
						sx={{ flex: "1 0 auto" }}
					/>
				</Box>
			) : (
				<Box
					sx={{ width: "auto", minWidth: "4rem" }}
					alignSelf={"center"}
					textAlign={"center"}
				>
					<ConfirmationNumberIcon
						sx={{
							fontSize: "4rem",
							color: `primary.${colorScheme.mode}`,
						}}
					/>
				</Box>
			)}

			<div style={{ width: "100%" }}>
				<Box
					className={"white-space-nowrap"}
					component="div"
					sx={{
						textOverflow: "ellipsis",
						overflow: "hidden",
						fontSize: "1rem",
						fontWeight: "600",
						p: 2,
						pb: 0,
						pt: 1,
						maxWidth: isMobile ? "15rem" : "12rem",
					}}
				>
					{props.voucher.title}
				</Box>

				<CardActions disableSpacing={true} className={`d-flex m-0 p-0 text-start mt-2`}>
					{!!props.voucher.pdf_url && (
						<div className={"ps-1"}>
							{isPdfLoading ? (
								<div className={"mx-1"}>
									<CircularProgress
										style={{
											width: "14px",
											height: "14px",
										}}
									/>
								</div>
							) : (
								<DownloadIcon
									onClick={() => handleDownloadVoucher(props.voucher)}
									className={"cursor-pointer"}
									color={"primary"}
									style={{ width: "20px", height: "20px" }}
								/>
							)}
						</div>
					)}

					{!!props.voucher.url && (
						<Button
							className={"w-100 m-0 p-0 ps-2"}
							onClick={() => handleShow(props.voucher.coupon_id || "")}
							size={"small"}
							style={{
								justifyContent: "flex-start",
								fontSize: "0.675rem",
							}}
						>
							{localisation.global.showCoupon}
						</Button>
					)}
				</CardActions>
			</div>
		</Card>
	);
}

import {useParams} from "react-router-dom";
import RouteRedirect from "../../helpers/routeRedirect";


export default function ShopMenuRedirectToNew() {
    const {storeId} = useParams()

    console.log('in redirect menu')

    const getRedirectPath = () => {
        if (storeId) {
            console.log('here')
            return `shop/${storeId}/menu`
        } else {
            return 'shop/select'
        }
    }

    return <RouteRedirect to={getRedirectPath()}/>
}

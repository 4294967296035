import { TgButton, TgLink } from "../../../helpers/ThemeComponents";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import React, { FormEvent, useMemo, useState } from "react";
import { getErrorText } from "../../../helpers/errors";
import { AuthorisationComponentProps } from "../types";
import EmailInput from "../inputs/EmailInput";
import PasswordInput from "../inputs/PasswordInput";
import { MDBSpinner } from "mdb-react-ui-kit";
import FormError from "../FormError";
import useAppContext from "../../../useAppContext";
import AgreementCheckbox from "../inputs/AgreementCheckbox";

export default function EmailLogin(props: AuthorisationComponentProps) {
	const [acceptedAgreement, setAcceptedAgreement] = useState<boolean>(false);

	if (props.form.state !== "emailLogin") {
		throw new Error("EmailLogin must be called only when state is emailLogin");
	}

	const {
		authService: { login, setShowAuthorisationModal },
	} = useAppContext();
	const localisation = useLocalisation();

	console.log(props.form);

	const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (props.form.state !== "emailLogin") return null; // for typescript

		props.form.setIsLoading(true);
		try {
			try {
				await login(
					{
						login_method: "email",
						email_data: {
							email: props.form.email,
							password: props.form.password,
						},
						with_consent: !!props.form.with_consent,
					},
					props.onLoginCallback
				);
				setShowAuthorisationModal(false);
			} catch (err: any) {
				props.form.setError("form", getErrorText(err, localisation.auth.unknownError));
			}
		} finally {
			props.form.setIsLoading(false);
		}
	};

	const computedDisabled = useMemo(() => {
		//@ts-ignore
		if (props.form.with_consent && !acceptedAgreement) {
			return true;
		}
		return props.form.isLoading;
	}, [acceptedAgreement, props.form.isLoading, props.form.with_consent]);

	return (
		<div>
			<form onSubmit={onFormSubmit}>
				<EmailInput form={props.form} />
				<PasswordInput form={props.form} />
				{props.form.with_consent && (
					<AgreementCheckbox value={acceptedAgreement} setValue={setAcceptedAgreement} />
				)}
				<FormError errText={props.form.errors.form} />

				<TgButton
					type={"submit"}
					disabled={computedDisabled}
					className={"w-100 mt-2 shadow-none"}
				>
					{props.form.isLoading && (
						<MDBSpinner size={"sm"} role={"status"} className={"me-2"} />
					)}
					{localisation.auth.loginButton}
				</TgButton>

				<div className={"form-text"}>
					<TgLink
						onClick={e => {
							e.preventDefault();
							props.form.chooseAuthMethod();
						}}
					>
						{localisation.auth.changeEmailButton}
					</TgLink>
				</div>
			</form>
		</div>
	);
}




export default function interpolateNumber(
    min: number,
    max: number,
    steps: number,
    value: number,
    reverse: boolean = false
) {
    const diff = max - min
    const step = diff / steps
    let interpolated = value * step

    if (interpolated < min) {
        interpolated = min
    }
    if (interpolated > max) {
        interpolated = max
    }
    if (reverse) {
        interpolated = max - interpolated
    }

    return interpolated
}

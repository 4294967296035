import React from "react"
import {
    MDBRow, MDBCard, MDBCol,
    MDBCardBody,
} from "mdb-react-ui-kit"

import {TgLink} from "../../../helpers/ThemeComponents"
import {useSelectedStoreOptional} from "../../SelectedStore/context";
import useAppContext from "../../../useAppContext";
import changePage from "../../../helpers/changePage";

interface OnlyRegisteredProps {
    title?: string
    subTitle?: string
}

export default function OnlyRegistered(props: OnlyRegisteredProps){
    const {
        localisation,
        authService: {setShowAuthorisationModal}
    } = useAppContext()

    const selectedStore = useSelectedStoreOptional()

    const backToShop = () => {
        if (selectedStore) {
            changePage(`/shop/${selectedStore.id}/menu`)
        } else {
            changePage('/shop/select')
        }
    }

    return (
        <MDBRow>
            <MDBCol md="6" className="mx-auto">
                <MDBCard className={'tg-card'}>
                    <MDBCardBody className={'tg-card border-radius-05'}>
                        <div className="mb-3">
                            {props.title && (
                                 <div className="theme-text fw-bold">
                                    {props.title}
                                </div>
                            )}
                            {props.subTitle && (
                                 <div className="theme-text">
                                    {props.subTitle}
                                </div>
                            )}
                        </div>

                        <div>
                            <TgLink
                                className={'cursor-pointer'}
                                onClick={() => backToShop()}
                            >
                                {localisation.global.backHome}
                            </TgLink>
                        </div>
                        <div>
                            <TgLink
                                className={'cursor-pointer'}
                                onClick={() => setShowAuthorisationModal(true)}
                            >
                                {localisation.auth.loginRegisterButton}
                            </TgLink>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    )
}

import useAppContext from "../../../../../useAppContext";
import { ThemeModal } from "../../../../../helpers/ThemeComponents";
import InviteInterface from "../../../../../components/InviteInterface/InviteInterface";

export interface ShareModalProps {
	openShareCode: boolean;
	closeShareModel: () => unknown;
	botUrl: string | null;
	webUrl: string;
}

export function ShareModal(props: ShareModalProps) {
	const {
		localisation: { profile, global },
		bot,
	} = useAppContext();

	return (
		<ThemeModal
			open={props.openShareCode}
			onClose={props.closeShareModel}
			title={profile.shareCouponTitle}
			fullWidth
			contentProps={{ sx: { pt: 2 } }}
		>
			<InviteInterface
				bodyText={profile.shareCouponBody}
				botType={bot?.bot_type}
				linkDescWeb={profile.shareCouponWeb}
				linkDescBot={profile.shareCouponMessenger}
				botUrl={props.botUrl}
				webUrl={props.webUrl}
				noMessangerText={global.friendHaveNoMessangerText}
				noMessangerModalTitle={global.friendHaveNoMessangerModalTitle}
			/>
		</ThemeModal>
	);
}

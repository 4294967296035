import { Box, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { ImageBox } from "./ImageBox";
import { IIncustCoupon } from "../../../types";
import { CouponCode } from "./CouponCode";
import { DateOfUse } from "./DateOfUse";
import { User } from "../../../../../api/auth/types";
import { CanUseWithOrder } from "./CanUseWithOrder";
import QRCode from "qrcode.react";

interface Props {
	insideCoupon: boolean;
	coupon: IIncustCoupon;
	lang: string | null;
	user: User | null | undefined;
	isInWallet: boolean;
}

export const MediaContentSide = (props: Props): React.JSX.Element => {
	const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
	const isExternalType = props.coupon.type === "external";

	return (
		<Box
			order={props.insideCoupon ? 2 : 1}
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
			gap={1}
			p={{
				xs: "10px",
				sm: "20px",
				md: "20px",
			}}
			pt={{ xs: "50px", sm: "20px", md: "10px" }}
			minWidth={{
				xs: "140px",
				sm: "180px",
			}}
			maxWidth={{ sm: "230px" }}
			pl={{
				md: props.insideCoupon ? "20px" : "30px",
			}}
			pr={{
				md: props.insideCoupon ? "30px" : "20px",
			}}
			flex={{ xs: 1, sm: "none" }}
		>
			<ImageBox coupon={props.coupon} />

			{
				<Box display={"flex"} flexDirection={"column"} textAlign={"center"} gap={1}>
					{props.coupon.code && (
						<CouponCode
							coupon={props.coupon}
							variant="body1"
							textAlign={"center"}
							width={"100%"}
							fontSize={"medium"}
						/>
					)}

					<DateOfUse coupon={props.coupon} lang={props.lang} />

					{!props.coupon.redeem_dt &&
						props.coupon.type === "check-modifier" &&
						props.user &&
						props.isInWallet && <CanUseWithOrder />}
				</Box>
			}

			{!isTablet && props.coupon.code && isExternalType && (
				<QRCode
					value={
						props.coupon.external_code?.length
							? props.coupon.external_code.toString()
							: props.coupon.code?.toString()
					}
					size={80}
					style={{ margin: "10px auto 0 auto" }}
				/>
			)}
		</Box>
	);
};

import React, {useState, useMemo, useCallback} from "react";

import MenuButton from "./buttons/MenuButton";
import DeliveryPickupButton from "./buttons/DeliveryPickupButton";
import WaiterButton from "./buttons/WaiterButton";
import PaymentButton from "./buttons/PaymentButton";
import ReviewButton from "./buttons/ReviewButton";
import ChatButton from "./buttons/ChatButton";
import {MenuInStore} from "../../api/menuInStore/types";
import {SetState} from "../../types";
import {useShopContext} from "../context";
import {QrMenuPaymentState} from "./types";
import Payments from "./payments/Payments";
import {useSelectedStoreOptional} from "../SelectedStore/context";
import changePage from "../../helpers/changePage";
import useAppContext from "../../useAppContext";
import QrMenuButtonWrapper from "./buttons/QrMenuButtonWrapper";
import QrMenuText from "./QrMenuText";


interface QrMenuWebMenuProps {
    menuInStore: MenuInStore
    setAlertMessage: SetState<string>
    setShowNeedTelegramModal: SetState<boolean>
    setShowNeedRegisterModal: SetState<boolean>
    forgetMenuInStore: () => any
}


export default function QrMenuWebMenu(
    {
        menuInStore, setAlertMessage,
        setShowNeedTelegramModal,
        setShowNeedRegisterModal,
        forgetMenuInStore,
}: QrMenuWebMenuProps) {
    const [paymentState, setPaymentState] = useState<QrMenuPaymentState>(QrMenuPaymentState.CanPay)
    const [forceDelayedAction, setForceDelayedAction] = useState(false)

    const computedIsMenuGroupEnabled = useMemo(() => {
        return !!(
            menuInStore.texts.menu.order_button
            || menuInStore.texts.menu.delivery_pickup_button
        );

    }, [menuInStore])

    const computedIsMenuCallEnabled = useMemo(() => {
        return menuInStore.texts.waiter.call_button || menuInStore.texts.payments.pay_button
    }, [menuInStore])

    const computedIsMenuCommunicationEnabled = useMemo(() => {
        return !!(
            menuInStore.texts.contacts.reviews_button
            || menuInStore.texts.contacts.chat_button
        );
    }, [menuInStore])

    const computedIsOnlyPayment = useMemo(() => {
        if(!computedIsMenuCommunicationEnabled && !computedIsMenuGroupEnabled){
            if(computedIsMenuCallEnabled){
                if(!menuInStore.texts.waiter.call_button){
                    return true
                }
            }
        }
        return false
    }, [
        computedIsMenuCallEnabled, computedIsMenuCommunicationEnabled,
        computedIsMenuGroupEnabled, menuInStore.texts.waiter.call_button
    ])

    return (<>
        {computedIsMenuGroupEnabled && (
            <MenuGroup menuInStore={menuInStore}/>
        )}

        {computedIsMenuCallEnabled && !computedIsOnlyPayment && (
            <CallGroup
                menuInStore={menuInStore}
                setAlertMessage={setAlertMessage}
                setShowNeedTelegramModal={setShowNeedTelegramModal}
            />
        )}

        {computedIsMenuCommunicationEnabled && (
            <CommunicationGroup
                menuInStore={menuInStore}
                setAlertMessage={setAlertMessage}
                setShowNeedTelegramModal={setShowNeedTelegramModal}
                setShowNeedRegisterModal={setShowNeedRegisterModal}
            />
        )}

        {computedIsOnlyPayment &&
            <Payments
                paymentState={paymentState}
                setPaymentState={setPaymentState}
                isModal={false}
                setShow={undefined}
                cashButton={menuInStore.texts.payments.cash_button}
                cardButton={menuInStore.texts.payments.card_button}
                onlineButton={menuInStore.texts.payments.online_button}
                qrButton={menuInStore.texts.payments.qr_button}
                forceDelayedAction={forceDelayedAction}
                setForceDelayedAction={setForceDelayedAction}
            />
        }

        {menuInStore.texts.active_menu.no_more_button && (
            <NoMoreRow
                menuInStore={menuInStore}
                forgetMenuInStore={forgetMenuInStore}
            />
        )}
    </>)
}


interface GroupProps {
    menuInStore: MenuInStore
}


function MenuGroup({menuInStore}: GroupProps) {
    return (<>
        <MenuButton buttonName={menuInStore.texts.menu.order_button}/>
        <DeliveryPickupButton buttonName={menuInStore.texts.menu.delivery_pickup_button}/>
    </>)
}


interface CallGroupProps extends GroupProps {
    setAlertMessage: SetState<string>
    setShowNeedTelegramModal: SetState<boolean>
}


function CallGroup( {menuInStore, setAlertMessage, setShowNeedTelegramModal}: CallGroupProps ) {
    const [paymentState, setPaymentState] =
        useState<QrMenuPaymentState>(QrMenuPaymentState.CanPay)
    return (<>
        <WaiterButton buttonName={menuInStore.texts.waiter.call_button || ""}/>

        <PaymentButton
            buttonName={menuInStore.texts.payments.pay_button || ""}
            cashButton={menuInStore.texts.payments.cash_button}
            cardButton={menuInStore.texts.payments.card_button}
            onlineButton={menuInStore.texts.payments.online_button}
            qrButton={menuInStore.texts.payments.qr_button}
            setMessage={setAlertMessage}
            setShowAlertModal={setShowNeedTelegramModal}
            paymentState={paymentState}
            setPaymentState={setPaymentState}
        />
    </>)
}


interface CommunicationGroupProps extends CallGroupProps {
    setShowNeedRegisterModal: SetState<boolean>
}


function CommunicationGroup(
    {
        menuInStore,
        setAlertMessage,
        setShowNeedTelegramModal,
        setShowNeedRegisterModal,
    }: CommunicationGroupProps
) {
    return (<>
        <ReviewButton
            buttonName={menuInStore.texts.contacts.reviews_button}
            setShowNeedRegistrationModal={setShowNeedRegisterModal}
        />

        <ChatButton
            buttonName={menuInStore.texts.contacts.chat_button}
            setShowNeedTgModal={setShowNeedTelegramModal}
            setMessage={setAlertMessage}
        />
    </>)
}


interface NoMoreRowProps extends GroupProps {
    forgetMenuInStore: () => any
}


function NoMoreRow( {menuInStore, forgetMenuInStore}: NoMoreRowProps ) {
    const {toastService} = useAppContext()
    const {brandInfo} = useShopContext()
    const selectedStore = useSelectedStoreOptional()

    const inStoreName = selectedStore?.name || brandInfo.name

    const backToShop = useCallback(() => {
        if (selectedStore?.id) {
            changePage(`/shop/${selectedStore.id}/menu`)
        } else {
            changePage('/shop/select')
        }
    }, [selectedStore?.id])

    if (!menuInStore.texts.active_menu.no_more_button) return null

    return (
        <QrMenuButtonWrapper
            noBorder
            onClick={() => {
                forgetMenuInStore()
                backToShop()
                toastService.showToast({
                    severity: 'success',
                    message: menuInStore.texts.active_menu.left_text
                        .replace("{name}", inStoreName)
                        .replace("{comment}", menuInStore.comment),
                    autoHideDuration: 10000,
                })
            }}
        >
            {
                menuInStore.texts.active_menu.no_more_button
                    .replace("{name}", inStoreName)
                    .replace("{comment}", menuInStore.comment || "")
            }
        </QrMenuButtonWrapper>
    )
}

import { ReactNode } from "react";
import useAppContext from "../../../useAppContext";

export default function ConsentWrapper({ children }: { children: ReactNode }) {
	const {
		brandInfo,
		authService: { user },
	} = useAppContext();
	const consentMode = brandInfo?.consent_mode;

	if (
		consentMode === "info" ||
		(user?.is_accepted_agreement && consentMode === "one_time" && user?.marketing_consent)
	)
		return <></>;

	return <>{children}</>;
}

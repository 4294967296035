import { ChangeEvent, useMemo } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import useAppContext from "../../../useAppContext";
import Interweave from "../../../features/Interweave";
import f from "../../../helpers/formatText";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import { useSelectedStoreOptional } from "../../../shop/SelectedStore/context";

interface MarketingCheckboxProps {
	value: boolean;
	setValue: (newValue: boolean) => void;
}

export default function MarketingCheckbox(props: MarketingCheckboxProps) {
	const { localisation } = useAppContext();
	const [searchParams] = useSearchParamsFixed();
	const selectedStore = useSelectedStoreOptional();

	const handleIsAcceptMarketingChanged = (e: ChangeEvent<HTMLInputElement>) => {
		props.setValue(e.target.checked);
	};

	const computedMarketingLink = useMemo(() => {
		if (selectedStore) {
			return `/shop/${selectedStore.id}/marketing/?${searchParams.toString()}`;
		}
		return `/shop/marketing/?${searchParams.toString()}`;
	}, [searchParams, selectedStore]);

	return (
		<FormControlLabel
			sx={{ mt: 1 }}
			control={
				<Checkbox
					name={"Marketing"}
					checked={props.value}
					onChange={handleIsAcceptMarketingChanged}
				/>
			}
			label={
				<Interweave
					className={"user-select-none"}
					content={f(localisation.auth.marketingConsent, {
						link: computedMarketingLink,
					})}
				/>
			}
		/>
	);
}

import useInvoiceService from "../../services/useInvoiceService";
import InvoiceContent from "./InvoiceContent";
import InvoiceError from "./InvoiceError";
import useAppContext from "../../../useAppContext";

interface IInvoiceProps {
    id?: string | number | null
    token?: string | null
}

export default function Invoice(props: IInvoiceProps) {
    const {
        lang,
        authService: {user},
    } = useAppContext()
    const {
        invoice, computedInvoiceImage,
        error, invoiceToken,
        clientRedirectUrl,
        getRedirectClient,
    } = useInvoiceService(props.id, props.token, lang, user)

    if(!invoice && !error) return null

    return (
        <>
            {!!(invoice && !error) &&
                <InvoiceContent
                    invoice={invoice}
                    computedInvoiceImage={computedInvoiceImage}
                    invoiceToken={invoiceToken}
                />
            }

            {error &&
                <InvoiceError
                    error={error}
                    redirectClient={getRedirectClient(false)}
                    clientRedirectUrl={clientRedirectUrl}
                />
            }
        </>
    )
}

import { useEffect, useState } from "react";

import { ThemeModal } from "../../../helpers/ThemeComponents";
import { IQrMenuPaymentsModalProps } from "../types";
import Payments from "../payments/Payments";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { useShopContext } from "../../context";

export default function QrMenuPaymentsModal(props: IQrMenuPaymentsModalProps) {
	const localisation = useLocalisation();
	const { menuInStoreService } = useShopContext();

	const [title, setTitle] = useState("");
	const [forceDelayedAction, setForceDelayedAction] = useState(false);

	useEffect(() => {
		if (props.paymentState !== null) {
			setTitle(localisation.qrMenu.payment);
		}
	}, [localisation.qrMenu.payment, props.paymentState]);

	return (
		<ThemeModal
			open={props.show}
			setOpen={props.setShow}
			title={title}
			maxWidth={
				menuInStoreService.menuInStore?.payment_option === "amount_from_template"
					? "lg"
					: "sm"
			}
			fullWidth
			PaperProps={{
				sx: {
					mx: 2,
					width: "calc(100% - 16px)",
				},
			}}
			contentProps={{
				sx: {
					px: 0,
					pt: 0,
					pb: 0,
				},
			}}
		>
			<Payments
				paymentState={props.paymentState}
				isModal={true}
				setPaymentState={props.setPaymentState}
				setShow={props.setShow}
				cashButton={props.cashButton}
				cardButton={props.cardButton}
				onlineButton={props.onlineButton}
				qrButton={props.qrButton}
				show={props.show}
				forceDelayedAction={forceDelayedAction}
				setForceDelayedAction={setForceDelayedAction}
			/>
		</ThemeModal>
	);
}

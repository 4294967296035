import BaseShopHeader from "./BaseShopHeader/BaseShopHeader";
import {useSelectedStoreContextStoreOptional} from "../SelectedStore/context";
import CartButton from "../cart/CartButton";
import { Box } from "@mui/material";
import useScreenService from "../../services/useScreenService";
import BackToQrMenuButton from "../qr_menu/BackToQrMenuButton";
import classnames from "classnames";
import {useShopContext} from "../context";


export default function SelectedStoreShopHeader() {
    const {selectedStore, cartService} = useSelectedStoreContextStoreOptional()
    const {isMobile} = useScreenService()
    const {isEMenu} = useShopContext()

    return (
        <BaseShopHeader>
            {selectedStore && (
                <div
                    className={'w-auto m-0 ms-auto d-flex align-items-center'}
                >
                    <div className={'text-end ms-auto align-self-center'} >
                        <div className={"d-flex h-100 align-items-center"}>
                            {!window.location.pathname.includes('invoice') &&
                                <>
                                    {!isMobile && (
                                        <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                        >
                                            {!isEMenu && (
                                                <Box
                                                    mr={2}
                                                    ref={cartService.cartRef}
                                                    className={classnames({
                                                        'd-none': cartService.isEmpty,
                                                    })}
                                                >
                                                    <CartButton/>
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </>
                            }
                        </div>
                    </div>
                </div>
            )}
        </BaseShopHeader>
    )
}

import {BrandInfo} from "../api/shop/basic/types";


export function getTermsOfUseLink(brand: BrandInfo | null, lang: string | null) {
    const link = brand?.terms_of_use_link || `https://7loc.com/{lang}/termsofuse`
    return link.replace('{lang}', lang || 'en')
}


export function getPrivacyPolicyLink(lang: string | null) {
    return `https://7loc.com/${lang || 'en'}/privacy-policy`
}

import {useMemo} from "react";

interface IEmbedMapProps {
    centerLat?: number
    centerLng?: number
    address?: string
    apiKey: string
    lang: string
    height?: string
    width?: string
}

export default function EmbedMap(props: IEmbedMapProps) {
    const computedLink = useMemo(() => {
        if(props.apiKey){
            let link = "https://www.google.com/maps/embed/v1/place?"
            link += `key=${props.apiKey}&language=${props.lang}`
            if(props.centerLat && props.centerLng){
                link += `&q=${props.centerLat},${props.centerLng}`
            }
            return link
        }
        return undefined
    }, [props.apiKey, props.centerLat, props.centerLng, props.lang])

    return (
        <iframe
            key={computedLink}
            title={'embed-google-map-shop'}
            width={props.width ? props.width : "100%"}
            height={props.height ? props.height : "300"}
            src={computedLink}
        >
        </iframe>
    )
}

import {MDBTooltip} from "mdb-react-ui-kit";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {useEffect, useState} from "react";
import {WorkingTimes} from "./types";
import {loadWorkingTimes} from "./scripts/info";
import {useSelectedStore} from "./SelectedStore/context";
import useAppContext from "../useAppContext";

export default function ShopWorkTimes() {
    const {
        lang,
        localisation,
    } = useAppContext()
    const selectedStore = useSelectedStore()

    const [workingTimes, setWorkingTimes] =
        useState<WorkingTimes | null>(null)

    useEffect(() => {
        setWorkingTimes(loadWorkingTimes(selectedStore.working_days, lang))
    }, [selectedStore.working_days, lang])

    if (!workingTimes || !workingTimes.current || !workingTimes.all) {
        return null
    }

    return (
        <div className={'theme-text'}>
            <div className={'d-flex align-items-center'}>
                <MDBTooltip
                    tag="div"
                    placement="top-start"
                    title={workingTimes.all.map((x) => (
                        <div key={x.day}>
                            <span>{x.day}: </span>
                            <span>{
                                x.time.includes('pass')
                                    ? localisation.global.weekend
                                    : x.time
                            }
                        </span>
                        </div>
                    ))}
                >
                    <div className={'cursor-pointer'}>
                        {
                            workingTimes.current.includes('pass')
                                ? localisation.global.weekend
                                : workingTimes.current
                        }
                        <ArrowDropDownIcon fontSize={'small'}/>
                    </div>
            </MDBTooltip>
        </div>
    </div>
)}

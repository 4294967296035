import { useState } from "react";
import { useRecoilValue } from "recoil";
import { oauthLoginState } from "../../options/state";
import AgreementCheckbox from "../../../inputs/AgreementCheckbox";
import { Alert, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAppContext from "../../../../../useAppContext";
import api from "../../../../../api";

interface IOAuthConsentProps {
	onLoginCallback?: () => void;
}

export default function OAuthConsent(props: IOAuthConsentProps) {
	const {
		localisation: { global, auth, orders },
		authService: { setShowAuthorisationModal, onLogin },
	} = useAppContext();

	const [isAccept, setIsAccept] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const oauthLogin = useRecoilValue(oauthLoginState);

	const submit = async () => {
		try {
			setIsLoading(true);
			let response;

			if (oauthLogin.is_accept_profile_agreement === false) {
				response = (await api.auth.consentOAuth({ oauth_token: oauthLogin.oauthToken }))
					.data;
			} else {
				response = (await api.auth.registerByOAuth({ oauth_token: oauthLogin.oauthToken }))
					.data;
			}

			if (!!response) {
				if (response.token && response.token_type) {
					setShowAuthorisationModal(false);
					onLogin({
						token: response.token,
						token_type: response.token_type,
					});
					props.onLoginCallback && props.onLoginCallback();
					return;
				}
			}
			setError(auth.unknownError);
		} catch (ex: any) {
			setError(ex?.response?.data?.detail || auth.unknownError);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Typography variant={"h6"} sx={{ mb: 2 }} textAlign={"start"}>
				{auth.oauthConsentText}
			</Typography>

			<Box my={2} textAlign={"start"}>
				{!!oauthLogin.firstName && (
					<>
						{orders.firstNameLabel}: <strong>{oauthLogin.firstName}</strong>
						<br />
					</>
				)}
				{!!oauthLogin.lastName && (
					<>
						{orders.lastNameLabel}: <strong>{oauthLogin.lastName}</strong>
						<br />
					</>
				)}
				{!!oauthLogin.email && (
					<>
						{orders.emailLabel}: <strong>{oauthLogin.email}</strong>
						<br />
					</>
				)}
			</Box>

			<AgreementCheckbox value={isAccept} setValue={newValue => setIsAccept(newValue)} />

			{!!error && (
				<Alert severity={"error"} sx={{ my: 2 }}>
					{error}
				</Alert>
			)}

			<LoadingButton
				fullWidth={true}
				variant={"contained"}
				sx={{ mt: 2 }}
				disabled={!isAccept}
				loading={isLoading}
				onClick={submit}
			>
				{global.continue}
			</LoadingButton>
		</>
	);
}

import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { IApplyCouponInfo, IIncustCoupon, IncustTransaction } from "../../../types";
import { ImageBox } from "../shared/ImageBox";
import { CouponDesc } from "../shared/CouponDesc";
import { BottomButtonsBar } from "../shared/BottomButtonsBar";
import Share from "../shared/Share";
import { CanUseWithOrder } from "../shared/CanUseWithOrder";
import useAppContext from "../../../../../useAppContext";
import CouponAwards from "../shared/CouponAwards";

interface Props {
	coupon: IIncustCoupon;
	insideCoupon: boolean;
	handlePdf: () => void;
	isShowApplyButton: boolean;
	isApplyCouponError: boolean;
	handleWallet: () => void;
	handleApply: () => unknown;
	isAddCouponToWalletLoading: boolean;
	handleIsInside: () => void;
	couponBenefitAmount: string;
	handleShare: () => unknown;
	shareCode: string | null;
	openShareCode: boolean;
	closeShareModal: () => unknown;
	isShareLoading: boolean;
	botUrl: string | null;
	webUrl: string;
	isInWallet: boolean;
	applyCouponInfo: IApplyCouponInfo | null;
	redeemedCouponCheck: IncustTransaction | null;
	onCloseApplyPopup: () => unknown;
}

export const MobileFrontSide = ({
	applyCouponInfo,
	coupon,
	isApplyCouponError,
	onCloseApplyPopup,
	redeemedCouponCheck,
	insideCoupon,
	handlePdf,
	isShowApplyButton,
	handleApply,
	handleWallet,
	isAddCouponToWalletLoading,
	handleIsInside,
	couponBenefitAmount,
	handleShare,
	openShareCode,
	closeShareModal,
	isShareLoading,
	botUrl,
	webUrl,
	isInWallet,
}: Props): React.JSX.Element => {
	const {
		authService: { user },
	} = useAppContext();

	const [descNeedExpand, setDescNeedExpand] = useState<boolean>(false);

	const isExternalType = coupon.type === "external";

	return (
		<Box
			component={motion.div}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
			display={"flex"}
			flexDirection={"column"}
			sx={{
				scrollbarWidth: "none",
				overflowY: "hidden",
			}}
		>
			<ImageBox coupon={coupon} />

			<Box
				sx={{
					borderTop: 1,
					borderColor: "divider",
					pt: 3,
					display: "flex",
					flexDirection: "column",
					position: "relative",
				}}
			>
				<Box p={2} pb={0} display={"flex"} flexDirection={"column"} textAlign={"center"}>
					<Typography
						variant={"h5"}
						fontWeight={"700"}
						textAlign={{ xs: "center", sm: "end" }}
						px={{ xs: 3, sm: 0 }}
					>
						{coupon.title}
					</Typography>

					{!coupon.redeem_dt &&
						coupon.type === "check-modifier" &&
						user &&
						isInWallet && <CanUseWithOrder />}

					<CouponAwards
						isExternalType={isExternalType}
						coupon={coupon}
						applyCouponInfo={applyCouponInfo}
						redeemedCouponCheck={redeemedCouponCheck}
						onCloseApplyPopup={onCloseApplyPopup}
					/>

					{coupon.description && (
						<CouponDesc
							setNeedExpand={setDescNeedExpand}
							insideCoupon={insideCoupon}
							coupon={coupon}
							hideShowButton={true}
							isExternalType={isExternalType}
						/>
					)}

					<Share
						couponBenefitAmount={couponBenefitAmount}
						handleShare={handleShare}
						openShareCode={openShareCode}
						closeShareModel={closeShareModal}
						isShareLoading={isShareLoading}
						botUrl={botUrl}
						webUrl={webUrl}
					/>
				</Box>

				<Divider />

				<BottomButtonsBar
					isExternalType={isExternalType}
					handlePdf={handlePdf}
					coupon={coupon}
					isShowApplyButton={isShowApplyButton}
					isApplyCouponError={isApplyCouponError}
					handleWallet={handleWallet}
					handleApply={handleApply}
					isApplyDisabled={isAddCouponToWalletLoading}
					descNeedExpand={descNeedExpand}
					handleIsInside={handleIsInside}
					isInWallet={isInWallet}
				/>
			</Box>
		</Box>
	);
};

import {useMemo, useState} from "react";

import useDebounced from "../../hooks/useDebounced";
import autocompleteAdapter from "../adapter";
import {IAddressOptionItem} from "../types";
import useAppContext from "../../../useAppContext";
import {Coordinates} from "../AddressInput";

export default function useOptions(
    isGoogle: boolean, token: string | null | undefined, internalAddress: string | null | undefined,
    businessLanguage: string | null | undefined, userLang: string,
    priorityCoords: Coordinates | null | undefined, setInternalError: (value: string | null) => void,
    countries: string[] | null | undefined,
): IUseOptionsType {
    const {localisation: {maps}} = useAppContext()

    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] =
        useState<readonly IAddressOptionItem[]>([])

    const updateOptions = useDebounced(async(value: string) => {
        if(isGoogle && !token) return
        if(value && value.length >= 4){
            setIsLoading(true)
            const lang = businessLanguage || userLang
            const adapterRes = await autocompleteAdapter(
                lang, value, isGoogle, priorityCoords, token,
                maps.mapsError.replace("{status}", "unknown"),
                setInternalError, countries,
            )
            if(!!adapterRes){
                setOptions(adapterRes.items)
            }
            else setOptions([])

            setIsLoading(false)
        } else {setOptions([])}
    }, [
        isGoogle, token, businessLanguage, userLang,
        priorityCoords, maps.mapsError, setInternalError,
        countries,
    ], 1000)

    const computedOptions: readonly IAddressOptionItem[] = useMemo(() => {
        const defaultOption: IAddressOptionItem = {
            title: internalAddress || "", custom: true, id: "custom",
            fullTitle: internalAddress || "",
        }
        const computedObj: IAddressOptionItem[] = []
        if(options && options.length > 0){
            computedObj[0] = defaultOption
            options.forEach((item, index) => {
                computedObj[index + 1] = item
            })
        }
        else {
            if(!!internalAddress) computedObj[0] = defaultOption
        }

        if(isGoogle) {
            computedObj.push({
                title: maps.cantFindAddressHeader, fullTitle: maps.cantFindAddressHeader, custom: true, id: "map",
                subTitle: maps.cantFindAddressSubHeader,
            })
        }

        return computedObj
    }, [internalAddress, isGoogle, maps.cantFindAddressHeader, maps.cantFindAddressSubHeader, options])

    return {
        updateOptions, computedOptions, isLoading
    }


}

export interface IUseOptionsType {
    updateOptions: (value: string) => void
    computedOptions: readonly IAddressOptionItem[]
    isLoading: boolean
}

import {ReactNode} from "react";
import {Box, SwipeableDrawer} from "@mui/material";
import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import {styled} from "@mui/material/styles";
import {grey} from "@mui/material/colors";


interface ISwipeableDrawerWrapperProps {
    drawerBleeding?: number
    screenHeightPercent?: number
    children: ReactNode,
    open: boolean,
    setOpen: (open: boolean) => void
    keepMounted?: boolean
    onClose?: () => void
    displayNone?: boolean
}

export default function SwipeableDrawerWrapper({
    children,
    setOpen,
    open,
    drawerBleeding = 36,
    screenHeightPercent = 100,
    keepMounted = false,
    onClose = undefined,
    displayNone = false,
}: ISwipeableDrawerWrapperProps) {
    const container = window !== undefined ? () => window.document.body : undefined

    const toggleDrawer = (newOpen: boolean) => () => {
        onClose && onClose()
        setOpen(newOpen)
    }

    return (
        <Box>
            <CssBaseline />
            <Global
                styles={{
                    '.swipe-drawer > .MuiPaper-root': {
                        // height: `calc(${screenHeightPercent}% - ${drawerBleeding}px)`,
                        maxHeight: `calc(${screenHeightPercent}% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    },
                }}
            />
            <SwipeableDrawer
                sx={{display: displayNone ? 'none' : 'block'}}
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={true}
                transitionDuration={300}
                ModalProps={{
                    keepMounted: keepMounted,
                }}
                className={'swipe-drawer'}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: open ? 'visible' : 'hidden',
                        right: 0,
                        left: 0,
                        transition: 'all 0.3s ease-in-out',
                        // backgroundColor: "white",
                        zIndex: open ? 1 : 0,
                        height: '100%',
                    }}
                >
                    <Puller />
                </Box>
                <Box
                    id={"modal-or-swipeable-overflow-wrapper"}
                    sx={{
                        height: '100%',
                        overflow: 'auto',
                        zIndex: 2,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    }}
                >
                    {children}
                </Box>
            </SwipeableDrawer>
        </Box>
    )
}

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}))

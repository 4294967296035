import { Box, Button, Typography } from "@mui/material";
import React, { useCallback } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { visuallyHidden } from "@mui/utils";
import { MakeInvoiceFormBaseProps } from "../../types";

export default function CountInput(props: MakeInvoiceFormBaseProps) {
	const { setForm } = props;

	const decreaseQty = useCallback(() => {
		setForm(prevState => {
			const prev = prevState?.count || 1;

			let newValue: number;
			if (prev < 2) {
				newValue = 1;
			} else {
				newValue = prev - 1;
			}

			return {
				count: newValue,
			};
		});
	}, [setForm]);

	const increaseQty = useCallback(() => {
		setForm(prevState => {
			const prev = prevState?.count || 1;

			let newValue: number;
			if (prev < 1) {
				newValue = 1;
			} else {
				newValue = prev + 1;
			}

			return {
				count: newValue,
			};
		});
	}, [setForm]);

	return (
		<Box gap={2} display={"flex"} alignItems={"center"}>
			<Button
				size={"medium"}
				variant={"outlined"}
				sx={{ px: 2, minWidth: "auto" }}
				onClick={() => decreaseQty()}
			>
				<RemoveIcon fontSize={"small"} />
				<Box sx={visuallyHidden}>-</Box>
			</Button>

			<Typography variant={"body2"} minWidth={"20px"} textAlign={"center"}>
				{props.form.count || 1}
			</Typography>

			<Button
				size={"medium"}
				variant={"outlined"}
				sx={{ px: 2, minWidth: "auto" }}
				onClick={() => increaseQty()}
			>
				<AddIcon fontSize={"small"} />
				<Box sx={visuallyHidden}>+</Box>
			</Button>
		</Box>
	);
}

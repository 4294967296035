import {LocaleType} from "../services/useLocalisationService/types";

export interface AILocaleUI extends LocaleType {
    chatHeader: string
    chatMessagePlaceholder: string
    noMessagesText: string
    websocketConnectionError: string
    recommendedProductsText: string
}


export const AILocale: AILocaleUI = {
    chatHeader: 'web ai chat header',
    chatMessagePlaceholder: 'web ai chat message placeholder',
    noMessagesText: 'web ai not messages text',
    websocketConnectionError: 'web ai websocket connection error',
    recommendedProductsText: 'ai chat recommended products text',
}

import React, { useCallback, useEffect, useState } from "react";

import { useShopContext } from "../context";
import QrMenuRedirectModeModal from "./modals/QrMenuRedirectModeModal";
import QrMenuNeedRegistrationAlertModal from "./modals/QrMenuNeedRegistrationAlertModal";
import QrMenuNeedMessangerAlertModal from "./modals/QrMenuNeedMessangerAlertModal";
import QrMenuWebMenu from "./QrMenuWebMenu";
import QrMenuRedirectTg from "./QrMenuRedirectTg";
import { useLocation } from "react-router-dom";
import { useSelectedStoreContextOptional } from "../SelectedStore/context";
import changePage from "../../helpers/changePage";
import { Box } from "@mui/material";
import useAppContext from "../../useAppContext";
import { NonModalDialogPage } from "../../features/NonModalDialog";
import f from "../../helpers/formatText";
import QrMenuText from "./QrMenuText";

export default function QrMenu() {
	const { headerHeight } = useAppContext();
	const { brandInfo, menuInStoreService } = useShopContext();
	const { selectedStore, isLoading: isSelectedStoreLoading } = useSelectedStoreContextOptional();

	const location = useLocation();

	const menuInStore = menuInStoreService.menuInStore;

	const [showTgModal, setShowTgModal] = useState(false);
	const [showNeedRegisterModal, setShowNeedRegisterModal] = useState(false);
	const [showNeedTelegramModal, setShowNeedTelegramModal] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const processRedirectMode = menuInStoreService.processRedirectMode;
	useEffect(() => {
		if (menuInStoreService.redirectMode) {
			processRedirectMode(
				menuInStoreService.redirectMode === "try_tg" ? () => setShowTgModal(true) : () => {}
			);
		}
	}, [menuInStoreService.redirectMode, processRedirectMode]);

	useEffect(() => {
		if (menuInStore?.id) {
			window.scrollTo(0, 0);
		}
	}, [menuInStore?.id]);

	const serviceForgotMenuInStore = menuInStoreService.forgetMenuInStore;
	const forgetMenuInStore = useCallback(() => {
		serviceForgotMenuInStore();
	}, [serviceForgotMenuInStore]);

	useEffect(() => {
		if (menuInStore?.store_id) {
			if (location.pathname.startsWith("/shop/qr_menu")) {
				changePage(`/shop/${menuInStore.store_id}/qr_menu`);
			} else if (selectedStore?.id !== menuInStore.store_id && !isSelectedStoreLoading) {
				forgetMenuInStore();
			}
		}
	}, [
		menuInStore?.store_id,
		location.pathname,
		selectedStore?.id,
		isSelectedStoreLoading,
		forgetMenuInStore,
	]);

	useEffect(() => {
		if (!menuInStoreService.menuInStoreIdParam) {
			if (selectedStore?.id) {
				changePage(`/shop/${selectedStore.id}/menu`);
			} else {
				changePage("/shop/select");
			}
		}
	}, [menuInStoreService.menuInStoreIdParam, selectedStore?.id]);

	const content = (
		<Box width={"100%"}>
			{menuInStore &&
				(menuInStoreService.redirectMode !== "only_tg" ? (
					<QrMenuWebMenu
						menuInStore={menuInStore}
						setAlertMessage={setAlertMessage}
						setShowNeedTelegramModal={setShowNeedTelegramModal}
						setShowNeedRegisterModal={setShowNeedRegisterModal}
						forgetMenuInStore={forgetMenuInStore}
					/>
				) : (
					<QrMenuRedirectTg menuInStore={menuInStore} />
				))}
		</Box>
	);

	if (!menuInStore) return null;

	return (
		<NonModalDialogPage
			header={
				menuInStore.texts.header_text &&
				f(menuInStore.texts.header_text, {
					name: selectedStore?.name || brandInfo.name || "",
					comment: menuInStore.comment,
				})
			}
		>
			<QrMenuText menuInStore={menuInStore} />

			{menuInStore &&
				(menuInStoreService.redirectMode !== "only_tg" ? (
					<QrMenuWebMenu
						menuInStore={menuInStore}
						setAlertMessage={setAlertMessage}
						setShowNeedTelegramModal={setShowNeedTelegramModal}
						setShowNeedRegisterModal={setShowNeedRegisterModal}
						forgetMenuInStore={forgetMenuInStore}
					/>
				) : (
					<QrMenuRedirectTg menuInStore={menuInStore} />
				))}

			<QrMenuRedirectModeModal
				show={showTgModal}
				setShow={setShowTgModal}
				isOnlyTg={menuInStoreService.redirectMode === "only_tg"}
			/>

			<QrMenuNeedRegistrationAlertModal
				show={showNeedRegisterModal}
				setShow={setShowNeedRegisterModal}
			/>

			<QrMenuNeedMessangerAlertModal
				show={showNeedTelegramModal}
				setShow={setShowNeedTelegramModal}
				message={alertMessage}
			/>
		</NonModalDialogPage>
	);
}

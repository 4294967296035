import { NonOAuthError, TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { useCallback, useState, useEffect } from "react";
import { OAuthLoginToken } from "../../../../../api/auth/types";
import api from "../../../../../api";
import { IOAuthService, IOAuthServiceProps } from "../../../types";
import { getDeviceInfo } from "../../../../../helpers";

export default function useGoogleLoginService(props: IOAuthServiceProps): IOAuthService {
	const [loaded, setLoaded] = useState<boolean>(false);

	const login = useGoogleLogin({
		onSuccess: (token: TokenResponse) => handleSuccessLogin(token),
		onError: (error: any) => handleError(error),
		onNonOAuthError: (error: NonOAuthError) => handleNonOAuthError(error),
	});

	const onError = props.onError;
	const handleError = useCallback(
		(error: any) => {
			console.log(error);
			let err = props.unknownError;
			if (error.error) {
				err = error.error;
				if (error.error_description) {
					err += ` ${error.error_description}`;
				}
			}
			onError && onError(err || "");
		},
		[props.unknownError, onError]
	);

	const handleNonOAuthError = useCallback(
		(error: NonOAuthError) => {
			console.log(error);
			if (error.type !== "popup_closed") {
				onError && onError(props.openFormError || "");
			}
		},
		[props.openFormError, onError]
	);

	const onSuccess = props.onSuccess;
	const setIsLoading = props.setIsLoading;
	const handleSuccessLogin = useCallback(
		async (token: TokenResponse) => {
			try {
				setIsLoading && setIsLoading(true);
				let payload: OAuthLoginToken = {
					access_token: token.access_token || "",
				};
				if (props.isNewLogin) {
					payload.is_new_login = true;
					payload.device_info = getDeviceInfo();
				}
				if (props.authSource) {
					payload.auth_source = props.authSource;
				}
				const response = await api.auth.loginByGoogle(payload);
				onSuccess && onSuccess(response.data, "google");
			} catch (ex: any) {
				console.log(ex);
				onError && onError(ex?.response?.data?.detail || props.unknownError);
			} finally {
				setIsLoading && setIsLoading(false);
			}
		},
		[setIsLoading, props.isNewLogin, props.authSource, props.unknownError, onSuccess, onError]
	);

	useEffect(() => {
		const checkScript = () => {
			if (window.google && window.google.accounts) {
				setLoaded(true);
			}
		};

		checkScript();

		window.addEventListener("load", checkScript);

		return () => {
			window.removeEventListener("load", checkScript);
		};
	}, []);

	return { login, loaded };
}

export interface SelectLocalisationUi {
    choseCity: string
    allCities: string
    choseStore: string
    inputAddressHeader: string
    closed: string
    errFullAddress: string
    errNotFoundByAddress: string
    typeAddress: string
    notFoundAddress: string
    notFoundByAddress: string
    addressLabel: string
    notFoundStoresByAddress: string
    storesSearchHeader: string
    nearestStoresHeader: string
}


export const SelectLocale: SelectLocalisationUi = {
    choseCity: 'web store chose city text',
    allCities: 'web store all cities text',
    choseStore: 'web store chose store text',
    inputAddressHeader: 'web store input address header text',
    closed: 'web store closed text',
    errFullAddress: 'web store full address err text',
    errNotFoundByAddress: 'web store not found by address err text',
    typeAddress: 'web store type address search text',
    notFoundAddress: 'web store not found address text',
    notFoundByAddress: 'web store not found by address text',
    addressLabel: 'web store address label text',
    notFoundStoresByAddress: 'web store not found stores by address text',
    storesSearchHeader: 'web store stores search header text',
    nearestStoresHeader: 'web store nearest stores header text',
}

import { Dialog, DialogContent, IconButton, Theme, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";
import Coupon from "./Coupon";
import { SetState } from "../../../../types";

interface CouponModalProps {
	show: boolean;
	setShow: SetState<boolean>;
	couponId: string | null;
	orderToken?: string | null;
}

export default function CouponModal({ show, setShow, couponId, orderToken }: CouponModalProps) {
	const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.only("xs"));

	return (
		<Dialog
			scroll={"body"}
			maxWidth={"md"}
			fullWidth
			open={show}
			PaperProps={{
				sx: {
					mx: 2,
					width: isMobile ? "calc(100% - 16px)!important" : undefined,
					maxWidth: isMobile ? "calc(100% - 16px)!important" : undefined,
					height: "fit-content",
					overflow: { xs: "hidden", sm: "visible" },
				},
			}}
			onClose={() => setShow(false)}
		>
			<IconButton
				size="small"
				onClick={() => setShow(false)}
				sx={{
					position: "absolute",
					zIndex: 9,
					top: {
						xs: "5px",
						sm: "-15px",
					},
					right: {
						xs: "5px",
						sm: "-15px",
					},
					borderRadius: "100px",
					color: "text.primary",
					bgcolor: "background.paper",
					"&:hover": {
						bgcolor: "background.paper",
					},
				}}
			>
				<Close />
			</IconButton>
			<DialogContent sx={{ p: 0 }}>
				<Coupon codeOrId={couponId || ""} orderToken={orderToken} />
			</DialogContent>
		</Dialog>
	);
}

import React from "react";
import { ImageSkeleton } from "./ImageSkeleton";
import { ProductMediaProps } from "../DetailedProduct";
import { CustomSlideButton } from "./CustomSlideButton";
import { ProductGalleryItem } from "./ProductGalleryItem";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

interface ProductGalleryProps extends ProductMediaProps {}

const ProductGallery = ({ product }: ProductGalleryProps): React.JSX.Element => {
	const items = React.useMemo(() => {
		const mainItem = {
			id: product?.id || 0,
			media_type: "image",
			media_url: product?.image_url || "",
		};

		const existedItems =
			product?.gallery?.items?.map(item => ({
				id: item.id,
				media_type: item.media_type,
				media_url: item.media_url,
			})) || [];

		return mainItem ? [mainItem, ...existedItems] : existedItems;
	}, [product]);

	const swiperRef = React.useRef<SwiperRef | null>(null);

	const handleSlideChange = () => {
		const swiper = swiperRef.current?.swiper;
		if (!swiper) return;

		const videos = swiper?.slides
			?.map((slide: HTMLElement) => slide?.querySelector("video"))
			.filter(Boolean) as HTMLVideoElement[];

		videos.forEach((video, index) => {
			if (index !== swiper.activeIndex) {
				video.muted = true;
			}
		});
	};

	const handleImageLoad = (index: number) => {
		const swiper = swiperRef.current?.swiper;
		if (!swiper) return;

		const slide = swiper.el.querySelectorAll(".swiper-slide")[index];
		if (slide) {
			const loader = slide.querySelector(".image-skeleton") as HTMLElement;
			if (loader) {
				loader.style.display = "none";
			}
		}
	};

	return (
		<Swiper
			ref={swiperRef}
			pagination={{
				clickable: true,
			}}
			autoHeight={true}
			navigation={{
				prevEl: ".custom-prev",
				nextEl: ".custom-next",
			}}
			mousewheel={true}
			onSlideChange={handleSlideChange}
			keyboard={true}
			loop={true}
			modules={[Navigation, Pagination, Mousewheel, Keyboard]}
			slidesPerView={1}
			style={{ width: "100%", paddingBottom: "40px" }}
		>
			{items?.map((image, index) => (
				<SwiperSlide key={image.id}>
					<ImageSkeleton />

					<ProductGalleryItem
						key={image.id}
						item={image}
						index={index}
						productName={product.name}
						containerWidth={(swiperRef?.current as unknown as Element)?.["clientWidth"]}
						onLoad={() => handleImageLoad(index)}
						onLoadedMetadata={() => swiperRef.current?.swiper.update()}
					/>
				</SwiperSlide>
			))}

			<CustomSlideButton
				icon={<KeyboardArrowLeft fontSize="inherit" />}
				className="custom-prev"
				customStyle={{
					left: "10px",
				}}
			/>
			<CustomSlideButton
				icon={<KeyboardArrowRight fontSize="inherit" />}
				className="custom-next"
				customStyle={{
					right: "10px",
				}}
			/>
		</Swiper>
	);
};

export default ProductGallery;

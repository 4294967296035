import classnames from "classnames";
import {Box, Breadcrumbs} from "@mui/material";

import { TgLink } from '../../helpers/ThemeComponents'
import {Category} from "../../api/shop/basic/types";
import {useSelectedStoreContext} from "../SelectedStore/context";
import useScreenService from "../../services/useScreenService";
import useAppContext from '../../useAppContext';

export default function ShopBreadcrumb(){
    const {isMobile} = useScreenService()
    const {categoriesService} = useSelectedStoreContext()
    const {sidebarsService, groupService} = useAppContext()

    const buildHierarchy = (): Category[] => {
        const check = (categoryId: number): Category[] => {
            const category = categoriesService.getCategoryById(categoryId)
            if (!category) {
                return []
            }

            if (!category.father_category_id) {
                return [category]
            }

            return [...check(category.father_category_id), category]
        }
        if (categoriesService.selectedCategoryId) {
            return check(categoriesService.selectedCategoryId)
        } else {
            return []
        }
    }

    const handleCategoryClick = (categoryId: number | null) => {
        categoriesService.setSelectedCategoryId(categoryId)
    }

    const hierarchy = buildHierarchy()

    return (
        <Box className={classnames(
            'px-1',
            {
                'fs-4': !isMobile,
                'pb-2': isMobile,
            })}
        >
            <Breadcrumbs
                key={categoriesService.selectedCategoryId}
                maxItems={3}
                aria-label="breadcrumb"
                separator="›"
                className={'px-1 m-0 theme-text w-100 user-select-none shop-hidden-scrollbar'}
                sx={{
                    ol: {
                        fontSize: isMobile ? '0.875rem' : '1.5rem',
                        flexWrap: 'nowrap',
                        overflowX: 'auto',
                        li: {
                            whiteSpace: 'nowrap',
                        },
                    },
                }}
            >
                {(
                     !(isMobile && categoriesService.selectedCategoryId === null) ||
                     groupService.group?.texts.web.menu.categories.choose_text
                ) && (
                    <TgLink
                        className={'cursor-pointer fw-bold'}
                        onClick={() => {
                            if (isMobile && categoriesService.selectedCategoryId === null) {
                                sidebarsService.setIsMainSidebarOpen(true)
                            }
                            handleCategoryClick(null)
                        }}
                    >
                        {isMobile && categoriesService.selectedCategoryId === null
                            ? groupService.group?.texts.web.menu.categories.choose_text
                            : groupService.group?.texts.web.menu.categories.all_text
                        }
                    </TgLink>
                )}

                {hierarchy.map(category => (
                    <TgLink
                        key={'category-' + category.id}
                        className={'cursor-pointer fw-bold'}
                        onClick={() => handleCategoryClick(category.id)}
                    >
                        {category.name}
                    </TgLink>
                ))}
            </Breadcrumbs>
        </Box>
    )
}

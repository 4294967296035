import { CheckItemProps } from "./types";
import { Box, Link, Typography  } from "@mui/material";
import ImageStub from "../ImageStub";
import useAppContext from "../../useAppContext";
import CheckLine from "./CheckLine";
import Interweave from "../Interweave";
import formatNumber from "../../helpers/formatNumber";

export default function CheckItem({ item, currency }: CheckItemProps) {
	const {
		lang,
		localisation: { check: locale },
		brandInfo,
	} = useAppContext();

	return (
		<Box
			width={"100%"}
			className={"check-item"}
			display={"flex"}
			py={1}
			borderBottom={1}
			borderColor={"divider"}
		>
			{!item.hideImage && (
				<Box className={"check-item-image-wrapper"}>
					{item.thumbnail_url ? (
						<img
							className={"check-item-image"}
							src={item.thumbnail_url}
							alt={item.name}
						/>
					) : (
						<ImageStub ratio={[1, 1]} />
					)}
				</Box>
			)}
			<Box flex={1}>
				<CheckLine>
					<Box>
						{item.code && (
							<Typography mr={1} component={"span"}>
								[{item.code}]
							</Typography>
						)}
						{item.url || item.onClick ? (
							<Link
								href={item.url || undefined}
								onClick={e => {
									if (item.onClick) {
										e.preventDefault();
										e.stopPropagation();
										item.onClick(e);
									}
								}}
								target={"_blank"}
								sx={{ cursor: "pointer" }}
							>
								<Interweave content={item.name} />
							</Link>
						) : (
							<Typography component={"span"}>
								<Interweave content={item.name} />
							</Typography>
						)}
						<Typography component={"span"} ml={1}>
							{formatNumber(item.price, brandInfo?.default_lang || lang)}
							{!item.hideQuantity1 && ` x ${item.quantity}`}
						</Typography>
					</Box>
					<Typography component={"span"} ml={2}>
						{formatNumber(item.sum, brandInfo?.default_lang || lang)}
					</Typography>
				</CheckLine>
				{!!item.attributes?.length &&
					item.attributes.map(attribute => (
						<CheckLine>
							<Box>
								<span>+</span>
								{attribute.code && (
									<Typography component={"span"} ml={1}>
										[{attribute.code}]
									</Typography>
								)}
								<Typography component={"span"} ml={1}>
									<Interweave content={attribute.name} />
								</Typography>
								<Typography component={"span"} ml={1}>
									{formatNumber(
										attribute.price,
										brandInfo?.default_lang || lang,
										currency
									)}{" "}
									x {attribute.quantity * item.quantity}
								</Typography>
							</Box>
							<span>
								{formatNumber(
									attribute.sum * item.quantity,
									brandInfo?.default_lang || lang,
									currency
								)}
							</span>
						</CheckLine>
					))}
				{!!item.discount_sum && (
					<CheckLine color={"warning.main"}>
						<span>{locale.itemSaleText}</span>
						<span>
							-
							{formatNumber(
								item.discount_sum,
								brandInfo?.default_lang || lang,
								currency
							)}
						</span>
					</CheckLine>
				)}
			</Box>
		</Box>
	);
}

import { LocaleType } from "../services/useLocalisationService/types";

export interface FormsLocalisationUi extends LocaleType {
	formName: string;
	formLastName: string;
	formPassword: string;
	formRepeatPassword: string;
	showFromLabel: string;
	showByLabel: string;
	notRequiredField: string;
	billingCompany: string;
	billingVat: string;
	billingReg: string;
	billingCountry: string;
	billingState: string;
	billingCity: string;
	billingZip: string;
	billingAddressOne: string;
	billingAddressTwo: string;
	phone: string;
	formBirthday: string;
	formBirthdayHelper: string;
}

export const FormsLocale: FormsLocalisationUi = {
	formName: "web store form name label text",
	formLastName: "web store form lastname label text",
	formPassword: "web store form password label text",
	formRepeatPassword: "web store form password repeat label text",
	showFromLabel: "web store forms dt picker to label text",
	showByLabel: "web store forms dt picker from label text",
	notRequiredField: "web store not required field text",
	billingCompany: "web store billing form company name label",
	billingVat: "web store billing form vat number label",
	billingReg: "web store billing form reg number label",
	billingCountry: "web store billing form country label",
	billingState: "web store billing form state label",
	billingCity: "web store billing form city label",
	billingZip: "web store billing form zip label",
	billingAddressOne: "web store billing form address one label",
	billingAddressTwo: "web store billing form address two label",
	phone: "default phone var name",
	formBirthday: "user registration birthday invite text",
	formBirthdayHelper: "user registration birthday invite helper text",
};

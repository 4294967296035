import { useCallback, useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdb-react-ui-kit";

import DeliveryTypeCard from "./DeliveryTypeCard";
import SelectStoreCard from "./SelectStoreCard";
import GeoModal from "./GeoModal";
import { useShopContext } from "../context";
import { Shipment, Store } from "../../api/shop/basic/types";
import useStoresService from "../services/useStoresService";
import { SetState } from "../../types";
import ShopBanner from "../menu/ShopBanner";
import api from "../../api";
import changePage from "../../helpers/changePage";
import useAppContext from "../../useAppContext";
import useShipmentsService from "../context/data/useShipmentsService";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface IStoreSelectProps {
	setShowSelectModal?: null | SetState<boolean>;
}

export default function StoreSelect({ setShowSelectModal = null }: IStoreSelectProps) {
	const {
		setPageTitle,
		localisation,
		groupService: { group },
	} = useAppContext();
	const { setShowGeoModal, brandInfo, needTryLastStoreOnSelect } = useShopContext();

	const [selectedShipment, setSelectedShipment] = useState<Shipment | null>(null);

	const shipmentsService = useShipmentsService();
	const storesService = useStoresService(selectedShipment);

	const [isMoreThenOneStore, setIsMoreThenOneStore] = useState(false);

	const handleStoreSelected = useCallback(
		(store: Store) => {
			changePage(`/shop/${store.id}/menu`);
			setShowGeoModal(false);

			if (setShowSelectModal) setShowSelectModal(false);
		},
		[setShowGeoModal, setShowSelectModal]
	);

	const onCityFilterChange = (e: SelectChangeEvent) =>
		storesService.setSelectedCity(e.target.value);

	useEffect(() => {
		if (!isMoreThenOneStore) {
			if (storesService.stores.length > 1) setIsMoreThenOneStore(true);
			if (storesService.stores.length === 1) handleStoreSelected(storesService.stores[0]);
		}
	}, [handleStoreSelected, isMoreThenOneStore, storesService.stores]);

	useEffect(() => {
		if (!needTryLastStoreOnSelect && group?.texts.web.pages_headers.select) {
			api.shop.basic.removeLastStoreId();
			setPageTitle(group.texts.web.pages_headers.select.replace("{name}", brandInfo.name));
		}
	}, [
		needTryLastStoreOnSelect,
		brandInfo.name,
		group?.texts.web.pages_headers.select,
		setPageTitle,
	]);

	return (
		<MDBContainer fluid>
			{/* Shop image header */}
			<div className={"mb-3"}>
				<ShopBanner />
			</div>

			<MDBContainer className={"p-0"}>
				{/* Delivery options */}
				{shipmentsService.shipments.base.length > 1 && (
					<MDBRow
						key={"deliveries-select"}
						className={"justify-content-center shop-select"}
					>
						{shipmentsService.shipments.base.map(
							shipment =>
								shipment.base_type !== "no_delivery" && (
									<MDBCol
										key={"deliveries- " + shipment.id}
										size={12}
										sm={4}
										className={"my-lg-0 my-1 px-sm-1 px-md-2 px-lg-3"}
									>
										<DeliveryTypeCard
											shipment={shipment}
											activeShipment={selectedShipment}
											setActiveShipment={setSelectedShipment}
										/>
									</MDBCol>
								)
						)}
					</MDBRow>
				)}

				{/* City select */}
				{storesService.cities.length > 0 && (
					<>
						<FormControl fullWidth sx={{ mt: 2 }}>
							<InputLabel id={"stores-list-city-select"}>
								{localisation.select.allCities}
							</InputLabel>
							<Select
								labelId={"stores-list-city-select"}
								value={storesService.selectedCity || undefined}
								label={localisation.select.allCities}
								onChange={onCityFilterChange}
							>
								{storesService.cities.map(city => (
									<MenuItem key={city} value={city}>
										{city}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</>
				)}

				<div key={"margin"} className={"mb-3"} />

				{/* Search result, shop list */}
				{!storesService.isStoresLoading && storesService.stores.length === 0 && (
					<div key={"not-found"} className={"small theme-text"}>
						{localisation.global.notFoundResult}
					</div>
				)}

				{!storesService.isStoresLoading && storesService.stores.length !== 0 && (
					<>
						<div className={"small theme-text"}>{localisation.select.choseStore}</div>
						<MDBRow>
							{storesService.stores.map(store => (
								<MDBCol
									className={"my-lg-2 my-1"}
									size="12"
									key={"store-" + store.id}
								>
									<SelectStoreCard
										store={store}
										handleStoreChanged={handleStoreSelected}
									/>
								</MDBCol>
							))}
						</MDBRow>
					</>
				)}
			</MDBContainer>

			<GeoModal
				headerText={localisation.select.inputAddressHeader}
				handleStoreChanged={handleStoreSelected}
			/>
		</MDBContainer>
	);
}

import { IOrderState } from "./types";

export const saveOrderState = (object: Object | number, objectKey: string) => {
	const savedState = window.sessionStorage.getItem("order-state");
	let orderState: IOrderState = {} as IOrderState;
	if (savedState) {
		orderState = JSON.parse(savedState) as IOrderState;
	}

	//@ts-ignore
	orderState[objectKey] = object;
	window.sessionStorage.setItem("order-state", JSON.stringify(orderState));
};

export const getOrderState = () => {
	const savedState = window.sessionStorage.getItem("order-state");
	if (!savedState) return null;

	return JSON.parse(savedState) as IOrderState;
};

export const removeOrderState = () => {
	window.sessionStorage.removeItem("order-state");
};

import { MDBTypography } from "mdb-react-ui-kit";

import { ThemeModal } from "../../../helpers/ThemeComponents";
import { IQrMenuNeedMessangerAlertModalProps } from "../types";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import MessangerRedirectButton from "../buttons/MessangerRedirectButton";
import useAppContext from "../../../useAppContext";
import { useMemo } from "react";

export default function QrMenuNeedMessangerAlertModal(props: IQrMenuNeedMessangerAlertModalProps) {
	const { bot } = useAppContext();
	const localisation = useLocalisation();

	const computedHeader = useMemo(() => {
		if (bot?.bot_type === "telegram") {
			return localisation.qrMenu.needTg;
		} else if (bot?.bot_type === "whatsapp") {
			return localisation.qrMenu.needWa;
		} else {
			return "";
		}
	}, [bot?.bot_type, localisation.qrMenu.needTg, localisation.qrMenu.needWa]);

	const computedButtonText = useMemo(() => {
		if (bot?.bot_type === "telegram") {
			return localisation.qrMenu.openMenuInTg;
		} else if (bot?.bot_type === "whatsapp") {
			return localisation.qrMenu.openMenuInWa;
		} else {
			return "";
		}
	}, [bot?.bot_type, localisation.qrMenu.openMenuInTg, localisation.qrMenu.openMenuInWa]);

	return (
		<ThemeModal open={props.show} setOpen={props.setShow} title={computedHeader}>
			<div className={"text-start px-1"}>
				<MDBTypography tag="em" className="lead mb-0 fs-5">
					{props.message}
				</MDBTypography>
			</div>
			<div className={"my-2 justify-content-end px-2 w-100"}>
				<MessangerRedirectButton buttonName={computedButtonText} byLink={true} />
			</div>
		</ThemeModal>
	);
}

import { useMemo, useState } from "react";
import { MDBIcon, MDBTypography } from "mdb-react-ui-kit";
import VibrationOutlinedIcon from "@mui/icons-material/VibrationOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";

import { TgLink } from "../../helpers/ThemeComponents";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import useWorkingTimesService from "../services/useWorkingTimesService";
import { StoreCustomField } from "../../api/shop/basic/types";
import useLocale from "../../hooks/localisation/useLocale";
import ShopWorkTimes from "../ShopWorkTimes";
import SocialNetworksInfo from "./SocialNetworksInfo";
import { Box, Link, Typography } from "@mui/material";
import { useSelectedStore } from "../SelectedStore/context";
import { LocalisationServiceType } from "../../services/useLocalisationService/types";
import { AppLocalesType } from "../../AppLocales";
import useAppContext from "../../useAppContext";
import useScreenService from "../../services/useScreenService";
import { MainTitleAndChangeStoreItem } from "../Sidebars/SidebarHeader";
import CollapsableComponent from "../../features/CollapsableComponent";
import Interweave from "../../features/Interweave";
import { useRecoilValue } from "recoil";
import { filterBarVisibleState, productsViewTypeState } from "./state";
import EmbeddedShopMapModal from "../maps/EmbeddedShopMapModal";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

const getCustomFieldName = (
	localisation: LocalisationServiceType<AppLocalesType>,
	name: string
) => {
	// @ts-ignore
	const field = localisation.menu[`field_${name}`];

	if (field) return field;
	return "";
};

interface IShopInfoProps {
	isSidebar?: boolean;
}

export function ShopInfoHeader() {
	const selectedStore = useSelectedStore();
	const isFilterBarVisible = useRecoilValue(filterBarVisibleState);
	const view = useRecoilValue(productsViewTypeState);

	const computedNeedBorder = useMemo(() => {
		return !(!isFilterBarVisible && view === "list");
	}, [isFilterBarVisible, view]);

	return (
		<div className={`${computedNeedBorder ? "border-bottom" : ""} px-2`}>
			<MainTitleAndChangeStoreItem />
			{selectedStore.description && (
				<CollapsableComponent linesCount={4} overflowLinesCount={2} showHiddenOverlay>
					<Typography py={2} className={"px-1"}>
						<Interweave noWrap content={selectedStore.description} />
					</Typography>
				</CollapsableComponent>
			)}
		</div>
	);
}

export default function ShopInfo(props: IShopInfoProps) {
	const { isMobile } = useScreenService();
	const { groupService } = useAppContext();

	return (
		<div className={`w-100 pb-2 pb-md-4 ${props.isSidebar ? "px-1" : ""}`}>
			{!!(!isMobile || props.isSidebar) && (
				<>
					{isMobile ? (
						<Box
							className={`fw-bold pt-2 pb-1`}
							sx={{
								fontSize: "1.05rem",
								color: "text.secondary",
							}}
						>
							{groupService.group?.texts?.web?.menu?.shop_info_text}
						</Box>
					) : (
						<MDBTypography className={"lead mb-2 fw-bold theme-text"}>
							{groupService.group?.texts?.web?.menu?.shop_info_text}
						</MDBTypography>
					)}
					<ShopInfoComponent />
				</>
			)}
		</div>
	);
}

function ShopInfoComponent() {
	const { isMobile } = useScreenService();
	const selectedStore = useSelectedStore();

	const [showMap, setShowMap] = useState<boolean>(false);

	const workingTimes = useWorkingTimesService();

	if (
		!selectedStore.custom_fields.length &&
		!workingTimes.workingTimes?.current &&
		!selectedStore.description
	) {
		return null;
	}

	return (
		<div>
			<Box
				className={"d-flex " + (isMobile ? "flex-column" : "flex-row")}
				sx={{ fontWeight: "regular" }}
			>
				{workingTimes.workingTimes?.current && (
					<WorkingTimes
						field={{
							name: "work_times",
							id: "0",
							value: null,
						}}
					/>
				)}

				{selectedStore.custom_fields.map(field => {
					if (field.name === "work_times") return null;
					if (field.name === "address") return <Box key="dummy-address"></Box>;
					return <CustomField key={field.name} field={field} />;
				})}
				<Address
					key={"address"}
					field={
						selectedStore.custom_fields.find(x => x.name === "address") || {
							id: "",
							name: "address",
							value: "",
						}
					}
					setShowMap={setShowMap}
				/>
				<SocialsField />
			</Box>

			<EmbeddedShopMapModal show={showMap} setShow={setShowMap} />
		</div>
	);
}

type StoreCustomExtraField = "coordinates_without_address";

interface BaseCustomFieldProps {
	field: StoreCustomField;
	children?: any;
	customName?: string;
	extraField?: StoreCustomExtraField;
	forceDisableHeader?: boolean;
}

interface CustomFieldProps {
	field: StoreCustomField;
}

function BaseCustomField(props: BaseCustomFieldProps) {
	const localisation = useLocalisation();
	const { isMobile } = useScreenService();

	const imageToIcon = () => {
		if (props.extraField) {
			switch (props.extraField) {
				case "coordinates_without_address":
					return <MapOutlinedIcon fontSize={isMobile ? "medium" : "large"} />;
			}
		}
		switch (props.field.name) {
			case "phone_number":
				return <VibrationOutlinedIcon fontSize={isMobile ? "medium" : "large"} />;
			case "email":
				return <EmailOutlinedIcon fontSize={isMobile ? "medium" : "large"} />;
			case "wifi":
				return <WifiOutlinedIcon fontSize={isMobile ? "medium" : "large"} />;
			case "work_times":
				return <ScheduleOutlinedIcon fontSize={isMobile ? "medium" : "large"} />;
			case "address":
				return <RoomOutlinedIcon fontSize={isMobile ? "medium" : "large"} />;
			case "socials":
				return <AdsClickOutlinedIcon fontSize={isMobile ? "medium" : "large"} />;
		}
	};

	return (
		<div
			className={`theme-text d-flex w-100 py-2 ${isMobile && props.field.id !== "socials" ? "border-bottom" : ""}`}
		>
			{(props.field.id !== "socials" || !isMobile) && (
				<div
					className={
						"" +
						"d-flex align-items-center " +
						"user-select-none " +
						"me-2 rounded-5 ps-0"
					}
				>
					<Box sx={{ color: "primary.main" }}>{imageToIcon()}</Box>
				</div>
			)}
			<div
				className={`d-flex flex-column flex-1 ${props.field.id !== "socials" || !isMobile ? "ps-2" : "ps-1"}`}
			>
				{!isMobile && !props.forceDisableHeader && (
					<span className={"user-select-none"}>
						{props.customName
							? props.customName
							: getCustomFieldName(localisation, props.field.name)}
					</span>
				)}
				<div className={"flex-1"} style={isMobile ? { overflowWrap: "anywhere" } : {}}>
					{props.children}
				</div>
			</div>
		</div>
	);
}

function WorkingTimes(props: CustomFieldProps) {
	const { workingTimes } = useWorkingTimesService();

	if (!workingTimes || !workingTimes.current) return null;

	return (
		<BaseCustomField field={props.field}>
			<div className={"d-flex align-items-center h-100"}>
				<ShopWorkTimes />
			</div>
		</BaseCustomField>
	);
}

interface IAddressFieldProps extends CustomFieldProps {
	setShowMap: (val: boolean) => void;
}

function Address(props: IAddressFieldProps) {
	const localisation = useLocalisation();
	const selectedStore = useSelectedStore();

	const canBeDisplayed = !!(
		(selectedStore.latitude && selectedStore.longitude) ||
		props.field.value
	);

	if (!canBeDisplayed) return <></>;

	return (
		<BaseCustomField
			field={props.field}
			extraField={props.field.value ? undefined : "coordinates_without_address"}
			forceDisableHeader={!props.field.value}
		>
			<div className={"user-select-none d-flex h-100 align-items-center"}>
				{!!(selectedStore.latitude && selectedStore.longitude) ? (
					<Link
						key={"field-link-value"}
						className={"cursor-pointer"}
						onClick={() => props.setShowMap(true)}
					>
						{props.field.value ? props.field.value : localisation.maps.showEmbeddedMap}
					</Link>
				) : (
					<div key={"field-value-copy"}>
						<span key={"value"}>{props.field.value}</span>
						{typeof props.field.value === "string" && (
							<Copy key={"copy"} text={props.field.value} />
						)}
					</div>
				)}
			</div>
		</BaseCustomField>
	);
}

function CustomField(props: CustomFieldProps) {
	if (typeof props.field.value !== "string" || props.field.value.includes("http")) return null;

	return (
		<BaseCustomField field={props.field}>
			<div className={"d-flex align-items-center h-100"}>
				<div className={"user-select-none"}>
					{props.field.value.includes("@") ? (
						<TgLink
							href={`mailto:${props.field.value}`}
							className={"cursor-pointer word-break-all"}
						>
							{props.field.value}
						</TgLink>
					) : props.field.name === "phone_number" && props.field.value ? (
						<TgLink
							href={`tel:${props.field.value}`}
							className={"cursor-pointer word-break-all"}
						>
							{props.field.value}
						</TgLink>
					) : (
						<>
							<span className={"user-select-none word-break-all"}>
								{props.field.value}
							</span>
							<Copy text={props.field.value} />
						</>
					)}
				</div>
			</div>
		</BaseCustomField>
	);
}

function Copy({ text }: { text: string }) {
	const { toastService } = useAppContext();
	const locale = useLocale("global");

	return (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<a
			href={"#"}
			className={"cursor-pointer ms-2 theme-link"}
			onClick={() => {
				navigator.clipboard.writeText(text).then(() => {
					toastService.showToast({
						severity: "success",
						message: locale.copiedText,
					});
				});
			}}
		>
			<MDBIcon far icon="copy" />
		</a>
	);
}

function SocialsField() {
	const {
		localisation: { global },
	} = useAppContext();
	const { isMobile } = useScreenService();
	const selectedStore = useSelectedStore();

	const socials = selectedStore.custom_fields.filter(
		field =>
			typeof field.value === "string" &&
			(field.value.includes("http") || field.value.includes("www"))
	);

	if (!socials || !socials.length) return null;

	return (
		<Box>
			<Box sx={{ width: "fit-content" }}>
				<BaseCustomField
					customName={global.socialHeader}
					field={{ id: "socials", name: "socials", value: "Social networks" }}
				>
					<SocialNetworksInfo />
				</BaseCustomField>
			</Box>
			{isMobile && <Box className={"border-bottom"}></Box>}
		</Box>
	);
}

import { useEffect } from "react";
import { AppBar, Box, styled, Toolbar, useColorScheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import useScreenService from "../../../services/useScreenService";
import BaseShopHeaderMobile from "./BaseShopHeaderMobile";
import BaseShopHeaderDesktop from "./BaseShopHeaderDesktop";
import { useShopContext } from "../../context";
import useAppContext from "../../../useAppContext";
import useShadowInterpolation from "../../../features/hooks/useShadowInterpolation";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export default function BaseShopHeader({ children }: { children?: any }) {
	const { setHeaderHeight, headerRef } = useAppContext();
	const colorScheme = useColorScheme();
	const { isMobile } = useScreenService();

	const { isEMenu, menuInStoreService } = useShopContext();

	useShadowInterpolation({
		elementId: "header-bar",
		options: [
			{
				triggerElId: "shop-container-main",
				offset: 30,
				steps: 500,
			},
			{
				triggerElId: "shop-container-main",
				reverse: true,
				steps: 75,
				offset: 0,
				boxShadowValue: "inset 0 -1px 0 rgba(var(--mui-palette-primary-mainChannel) / 1)",
			},
			...(isMobile
				? [
						{
							triggerElId: "products-container",
							reverse: true,
							offset: -25,
							steps: 50,
						},
						{
							triggerElId: "sub-categories-bar",
							reverse: false,
							offset: 1,
						},
					]
				: []),
		],
	});

	useEffect(() => {
		const resizeObserver = new ResizeObserver(entries => {
			if (entries.length > 0) {
				const newHeight = entries[0].contentRect.height;
				setHeaderHeight(newHeight);
			}
		});

		const containerElement = headerRef.current;

		if (containerElement) {
			resizeObserver.observe(containerElement);
		}

		return () => {
			if (containerElement) {
				resizeObserver.unobserve(containerElement);
			}
		};
	}, [headerRef, setHeaderHeight]);

	return (
		<>
			<CssBaseline />
			<AppBar
				sx={{
					bgcolor: "background.paper",
					...((window.Telegram?.WebApp.initData || colorScheme.mode === "dark") && {
						backgroundImage: "none",
					}),
					transition: "box-shadow 0s",
					boxShadow: "inset 0 -1px 0 rgba(var(--mui-palette-primary-mainChannel) / 1)",
				}}
				ref={headerRef}
				id={"header-bar"}
			>
				<Toolbar id={"navbar"} className={"px-0"}>
					{isMobile ? (
						<BaseShopHeaderMobile />
					) : (
						<BaseShopHeaderDesktop>{children}</BaseShopHeaderDesktop>
					)}
				</Toolbar>
			</AppBar>
			{menuInStoreService.menuInStore &&
			isMobile &&
			!isEMenu &&
			!window.location.pathname.includes("qr_menu") ? (
				<Box sx={{ height: 92.5 }}></Box>
			) : (
				<Offset />
			)}
		</>
	);
}

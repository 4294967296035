import {Bot} from "../../api/bots/types";


export default function useMessangerLink(bot?: Bot | null | undefined): string {
    if (!bot) {
        return ''
    }

    switch (bot.bot_type) {
        case 'telegram':
            return `https://t.me/${bot.username}`
        case 'whatsapp':
            return `https://wa.me/${bot.whatsapp_from_phone_number}`
    }
}

import {AxiosInstance} from "axios";
import {Page} from "./types";


export default function PagesAPIModule(instance: AxiosInstance) {
    const getPath = (path: string) => `pages/${path}`

    return {
        getPageByName(name: string) {
            return instance.get<Page>(getPath(name))
        }
    }
}

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import {useSelectedStoreContextStoreOptional} from "../SelectedStore/context";
import {StyledHeaderBadge} from "./StyledHeaderBadge";
import changePage from "../../helpers/changePage";
import classnames from "classnames";

export default function CartHeaderButton() {
    const {cartService, selectedStore} = useSelectedStoreContextStoreOptional()

    return (
        <span ref={cartService.cartRef} className={classnames(
            "align-self-center",
            {
                'd-none': cartService.isEmpty,
            }
        )}>
            <StyledHeaderBadge
                badgeContent={cartService.productsCount || 0}
                color="error"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                right={8}
                sx={{userSelect: 'none'}}
            >
                <ShoppingCartOutlinedIcon
                    className={'me-1 cursor-pointer'}
                    sx={{
                        width: 35,
                        height: 35,
                    }}
                    onClick={() => changePage(`/shop/${selectedStore?.id}/cart`)}
                />
            </StyledHeaderBadge>
        </span>
    )
}

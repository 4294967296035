import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import useAppContext from "../../useAppContext";
import useLocale from "../../hooks/localisation/useLocale";

interface IProfileIdItemProps {
    classes?: string
    id: string | number
    iconSize: number
    idText?: string | null
}

export default function ProfileIdItem(props: IProfileIdItemProps) {
    const {toastService} = useAppContext()
    const locale = useLocale('global')

    const handleCopyClick = () => {
        navigator.clipboard.writeText(props.id.toString()).then(() => {
            toastService.showToast({
                severity: 'success',
                message: locale.copiedText,
            })
        })
    }

    return (
        <div className={`word-break-all text-secondary ${props.classes ? props.classes : ''}`}>
            {!!(props.idText === "" || props.idText) && (
                <>
                    {props.idText ? props.idText : 'ID'}{': '}
                </>
            )}
            {props.id}
            <ContentCopyIcon
                className={'ms-1 cursor-pointer text-secondary'}
                style={{width: `${props.iconSize}px`, height: `${props.iconSize}px`}}
                onClick={handleCopyClick}
                sx={{pe: 2}}
            />
        </div>
    )
}

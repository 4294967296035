import { useSelectedStoreContext } from "../../../SelectedStore/context";
import useAppContext from "../../../../useAppContext";
import TopupOptions from "../../../../features/TopupOptions/TopupOptions";
import { Box, Typography } from "@mui/material";
import { IProductTopupPropsBase } from "./types";
import ProductTopupConfirmModal from "./ProductTopupConfirmModal";
import { useEffect, useMemo, useRef } from "react";
import ProductFloatingSumRoot from "../ProductFloating/ProductFloatingSumRoot";
import { IUseFloatingSumType } from "../hooks/useFloatingSum";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";
import useIncustPayDataTopup from "../../../payments/hooks/useIncustPayDataTopup";

interface IProductTopupProps extends IProductTopupPropsBase {
	floatingSum: IUseFloatingSumType;
}

export default function ProductTopup(props: IProductTopupProps) {
	const {
		brandInfo,
		authService: { user },
	} = useAppContext();
	const {
		localisation: { receipt, topup },
	} = useAppContext();

	const { cartService, selectedStore } = useSelectedStoreContext();
	const incustPayData = useIncustPayDataTopup(selectedStore, props.service.product?.id);

	const rootRef = useRef<HTMLDivElement | null>(null);

	const { refetchCustomer } = useUserIncustCustomerData();

	const isOnlyOneAccount = useMemo(() => {
		if (
			props.service.optionsService.options &&
			props.service.optionsService.options.length === 1
		) {
			return true;
		}
	}, [props.service.optionsService.options]);

	useEffect(() => {
		if (rootRef.current && cartService.productVariationsState?.isAddToCart) {
			rootRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, [cartService.productVariationsState?.isAddToCart]);

	useEffect(() => {
		refetchCustomer();
	}, [refetchCustomer]);

	if (!user) {
		return (
			<Box sx={{ pl: 3, pb: 4 }}>
				<Typography color={"error"} variant={"h5"} fontWeight={"bold"} textAlign={"left"}>
					{topup.cantTopup}
				</Typography>
				<Typography textAlign={"left"}>{topup.noAuth}</Typography>
			</Box>
		);
	} else {
		if (!brandInfo?.loyalty_info?.loyalty_enabled) {
			return (
				<Typography color={"error"} variant={"h5"} fontWeight={"bold"} textAlign={"center"}>
					{receipt.scanReceiptNotConnectedLoyalty}
				</Typography>
			);
		}

		if (!incustPayData.incustPayData && !incustPayData.isLoading) {
			return (
				<Typography color={"error"} variant={"h5"} fontWeight={"bold"} textAlign={"center"}>
					{topup.loyaltyConfigError}
				</Typography>
			);
		}

		if (
			incustPayData.incustPayData &&
			!incustPayData.isLoading &&
			incustPayData.incustPayData &&
			incustPayData.incustPayData.incust_pay_list &&
			!incustPayData.incustPayData.incust_pay_list.length
		) {
			return (
				<Typography color={"error"} variant={"h5"} fontWeight={"bold"} textAlign={"center"}>
					{topup.emptyAccountsList}
				</Typography>
			);
		}

		if (
			incustPayData.incustPayData &&
			!incustPayData.isLoading &&
			!incustPayData.incustPayData?.incust_pay_list.length
		) {
			return (
				<Typography color={"error"} variant={"h5"} fontWeight={"bold"} textAlign={"center"}>
					{topup.accountsNotFound}
				</Typography>
			);
		}
	}

	return (
		<Box
			className={"border-top"}
			sx={{ backgroundColor: "rgba(0, 0, 0, 0.015)" }}
			ref={rootRef}
		>
			<Box sx={{ mb: 1, px: 3 }}>
				<Typography variant={"h6"} fontWeight={"bold"}>
					{isOnlyOneAccount ? topup.oneAccountHeader : topup.selectHeader}
				</Typography>
				{!isOnlyOneAccount && (
					<Typography variant={"body2"} color={"text.secondary"}>
						*{topup.corporateNote}
					</Typography>
				)}
			</Box>

			<TopupOptions service={props.service.optionsService} />

			{!!user && props.service.product?.floating_sum_settings?.is_enabled && (
				<Box mb={2} px={3}>
					<ProductFloatingSumRoot
						floatingSum={props.floatingSum}
						floatingSumSettings={props.service.product.floating_sum_settings}
						cartProduct={null}
						fromGiftOrTopup={true}
						product={props.service.product}
					/>
				</Box>
			)}

			<ProductTopupConfirmModal service={props.service} />
		</Box>
	);
}

import { useCallback, useEffect, useMemo, useState } from "react";
import "react-phone-number-input/style.css";

import { matchIsValidTel, MuiTelInput, MuiTelInputCountry, MuiTelInputInfo } from "mui-tel-input";
import useAppContext from "../useAppContext";
import { getCountryCallingCode, parsePhoneNumber } from "react-phone-number-input";
import { IconButton, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useCountryByIP from "../hooks/useCountryByIP";

interface PhoneInputProps {
	value: string | null | undefined;
	setValue: (value: string) => void;

	isValid: boolean;
	setIsValid: (value: boolean) => void;

	errorText?: string;
	required?: boolean;

	showInvalidError?: boolean;

	autoFocus?: boolean;
	preferredCountries?: MuiTelInputCountry[];
}

export default function PhoneInput(props: PhoneInputProps) {
	const { lang, localisation } = useAppContext();

	const [showFlag, setShowFlag] = useState(props.required);
	const [internalValue, setInternalValue] = useState(props.value);

	const countryByIpQuery = useCountryByIP();

	const setIsValid = props.setIsValid;
	const validatePhone = useCallback(
		(phone: string) => {
			const parsedPhone = parsePhoneNumber(phone);
			if (props.required || (parsedPhone?.country && parsedPhone.nationalNumber)) {
				setIsValid(matchIsValidTel(phone));
			}
		},
		[setIsValid, props.required]
	);

	const setValue = props.setValue;
	const setPhoneValue = useCallback(
		(value: string, info: MuiTelInputInfo) => {
			setInternalValue(value);
			if (info.nationalNumber) {
				setValue(value);
			} else {
				setValue("");
			}
			validatePhone(value);
		},
		[setValue, validatePhone]
	);

	useEffect(() => {
		if (props.value) {
			setInternalValue(props.value);
		}
		if (props.value || props.required) {
			validatePhone(props.value || "");
		}
	}, [props.value, props.required, validatePhone, setValue]);

	useEffect(() => {
		if (internalValue || props.required) {
			setShowFlag(true);
		}
	}, [internalValue, props.required]);

	const onlyCountryCode = useMemo(() => {
		if (countryByIpQuery.data) {
			const callingCode = getCountryCallingCode(countryByIpQuery.data);
			return `+${callingCode}`;
		} else {
			return "";
		}
	}, [countryByIpQuery.data]);

	return (
		<MuiTelInput
			fullWidth
			size={"small"}
			autoFocus={props.autoFocus}
			preferredCountries={props.preferredCountries}
			error={props.showInvalidError && !props.isValid}
			helperText={props.showInvalidError && !props.isValid && props.errorText}
			langOfCountryName={lang || "en"}
			label={localisation.orders.phoneLabel}
			value={internalValue || (showFlag || props.required ? onlyCountryCode : "")}
			onChange={setPhoneValue}
			required={props.required}
			InputProps={{
				...(!showFlag
					? {
							startAdornment: undefined,
						}
					: {
							endAdornment: (
								<InputAdornment position={"end"}>
									<IconButton
										onClick={() => {
											setInternalValue("");
											setShowFlag(props.required);
										}}
										sx={{ p: 1 }}
									>
										<CloseIcon sx={{ fontSize: "medium" }} />
									</IconButton>
								</InputAdornment>
							),
						}),
				sx: {
					pl: 2,
					pr: "6px",
				},
			}}
			inputProps={{
				onFocus: () => setShowFlag(true),
			}}
		/>
	);
}

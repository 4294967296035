export interface QrMenuLocalisationUi {
	openMenuInTg: string;
	needOpenMenuInTg: string;
	needRegistration: string;
	needRegistrationMsg: string;
	needTg: string;
	tgOnlinePaymentMsg: string;
	sendMessageError: string;
	sendMessageSuccess: string;
	payment: string;
	paymentMsg: string;
	warning: string;
	redirectOnlyTgMsg: string;
	redirectTryTgMsg: string;
	redirectWeb: string;
	redirectTg: string;
	review: string;
	reviewSuccess: string;
	reviewSuccessMsg: string;
	loyaltyBonusesMessage: string;
	reviewLabel: string;
	downloadTg: string;
	tryTgHeader: string;
	stars: string;
	loyaltyMsg: string;
	registerForLoyaltyMsg: string;
	enterSum: string;
}

export const QrMenuLocale = {
	openMenuInTg: "web store open menu in tg text",
	openMenuInWa: "web store open menu in wa text",
	needOpenMenuInTg: "web store need open menu in tg message",
	needOpenMenuInWa: "web store need open menu in wa message",
	needRegistration: "web store need registration header",
	needRegistrationMsg: "web store need registration message",
	needTg: "web store need tg header",
	needWa: "web store need wa header",
	tgOnlinePaymentMsg: "web store tg payment message",
	sendMessageError: "web store message send error text",
	sendMessageSuccess: "web store message send success text",
	payment: "web store payment header",
	paymentMsg: "web store payment message",
	warning: "web store warning alert header",
	redirectOnlyTgMsg: "web store qr menu redirect only tg message",
	redirectTryTgMsg: "web store qr menu redirect try tg message",
	redirectWeb: "web store qr menu redirect web button",
	redirectTg: "web store qr menu redirect try tg button",
	review: "web store review header",
	reviewSuccess: "web store review sent success header",
	reviewSuccessMsg: "web store review sent success message",
	loyaltyBonusesMessage: "loyalty wish use bonuses message",
	reviewLabel: "review text header",
	downloadTg: "web store download tg button",
	tryTgHeader: "web store qr menu try tg header",
	stars: "review stars header",
	loyaltyMsg: "in store payment menu loyalty registration message",
	registerForLoyaltyMsg: "menu in store register for loyalty text",
};

import {styled} from "@mui/material/styles";
import Badge, {BadgeProps} from "@mui/material/Badge";

interface IStyledHeaderBadgeProps extends BadgeProps {
    right?: number
}

export const StyledHeaderBadge = styled(Badge)<IStyledHeaderBadgeProps>(
        ({ theme, right }) => ({
    '& .MuiBadge-badge': {
        right: right || 1,
        top: 1,
        border: `1px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        minWidth: '16px',
        height: '16px',
        borderRadius: '8px',
        fontSize: '0.75rem',
    },
}));

import {AxiosInstance} from "axios";
import {MenuInStore} from "./types";


export default function MenuInStoreApiModule(instance: AxiosInstance) {
    const getPath = (path: string) => 'menuInStore/' + path;
    return {
        getMenuInStore(settingsId: number, brandId: number) {
            return instance.get<MenuInStore>(getPath(settingsId + '/'), {params: {brand_id: brandId}})
        },
    }
}

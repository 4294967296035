import {ReactNode, useState} from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {Box, BoxProps, Typography} from "@mui/material";
import {TgLink} from "../helpers/ThemeComponents";

interface IMUIPopConfirmProps {
    onConfirm: () => void
    confirmText: string
    cancelText: string
    additionalText?: string | null
    additionalTextColor?: string | null
    message: ReactNode
    title?: string | null
    node: ReactNode
    onClose?: () => void
    onCancel?: () => void
    onAdditional?: () => void
    nodeWrapperProps?: BoxProps
    reverseButtonColors?: boolean
    additionalNode?: ReactNode
    primaryText?: boolean
    childrenHeightAuto?: boolean
}

export default function MUIPopConfirm(props: IMUIPopConfirmProps) {
    const [show, setShow] = useState<boolean>(false)

    const handleConfirm = () => {
        setShow(false)
        props.onConfirm()
        if(props.onClose) props.onClose()
    }

    const handleCancel = (e: any) => {
        e.stopPropagation()
        setShow(false)
        if(props.onCancel) props.onCancel()
        if(props.onClose) props.onClose()
    }

    const handleClose = () => {
        setShow(false)
        if(props.onClose) props.onClose()
    }

    const handleAdditional = () => {
        props.onAdditional && props.onAdditional()
    }

    return (
        <>
            <Box
                height={props.childrenHeightAuto ? 'auto' : '100%'}
                onClick={() => setShow(true)}
                {...(props.nodeWrapperProps && props.nodeWrapperProps)}
            >
                {props.node}
            </Box>

            <Dialog
                maxWidth={'xs'}
                open={show}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    '.MuiPaper-root': {
                        borderRadius: {xs: '.5rem', md: '1rem'},
                    },
                }}
            >
                {!!(props.title) && (
                    <Box
                        bgcolor={'rgba(0, 0, 0, 0.06)'}
                        display={'flex'}
                        alignItems={'center'}
                        border={1}
                        px={4}
                        py={3}
                        sx={{
                            borderTopLeftRadius: {xs: '.5rem', md: '1rem'},
                            borderTopRightRadius: {xs: '.5rem', md: '1rem'},
                            borderColor: 'divider'
                        }}
                    >
                        <Typography variant={'h6'} flex={1} fontWeight={'bold'}>
                            {props.title}
                        </Typography>
                    </Box>
                )}

                <DialogContent
                    sx={{pb: 2}}
                >
                    <DialogContentText
                        id="alert-dialog-description"
                        sx={{
                            color: props.primaryText ?
                                'var(--mui-palette-text-primary)' :
                                'var(--mui-palette-text-secondary)',
                        }}
                    >
                        {props.message}
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{px: 4, pb: props.additionalText || props.additionalNode ? 0 : 3}}>
                    <Button onClick={(e: any) => handleCancel(e)} color={props.reverseButtonColors ? 'primary' : 'error'}>
                        {props.cancelText}
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={() => handleConfirm()}
                        color={props.reverseButtonColors ? 'error' : 'primary'}
                    >
                        {props.confirmText}
                    </Button>
                </DialogActions>

                {(props.additionalText || props.additionalNode) && (
                    <Box px={4} pb={3} pt={1} width={'100%'} textAlign={'end'}>
                        {props.additionalNode ? (
                            props.additionalNode
                        ): (
                            <Typography sx={{'a': {
                                color: props.additionalTextColor || 'primary.main',
                                textDecorationColor: props.additionalTextColor === 'error.main' ?
                                    'var(--mui-palette-error-main)' : 'var(--mui-palette-primary-main)',
                                mb: 1, pl: 1,
                            }}}>
                                <TgLink className={'cursor-pointer'} onClick={handleAdditional}>
                                    {props.additionalText}
                                </TgLink>
                            </Typography>
                        )}
                    </Box>
                )}
            </Dialog>
        </>
    )
}

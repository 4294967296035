import {Box, Paper, PaperProps, Typography} from "@mui/material";
import {NonModalDialogProps} from "./types";
import {useMemo} from "react";


export default function NonModalDialog(props: NonModalDialogProps) {
    const paperProps = useMemo((): PaperProps => {
        const defaultProps: PaperProps = {
            elevation: 2,
        }
        const defaultSx: PaperProps['sx'] = {
            width: '100%',
            borderRadius: {xs: '.5rem', md: '1rem'},
            overflow: 'hidden',
            border: 1,
            borderColor: 'divider',
        }

        if (!props.paperProps) {
            return {
                ...defaultProps,
                sx: defaultSx,
            }
        }

        const {sx: paperSx, ...otherPaperProps} = props.paperProps
        return {
            sx: {
                ...defaultSx,
                ...paperSx
            },
            ...defaultProps,
            ...otherPaperProps,
        }

    }, [props.paperProps])

    return (
        <Paper {...paperProps} >
            {props.replaceHeader ? props.header : (
                !!props.header && (
                    <Box
                        py={2}
                        bgcolor={'rgba(0, 0, 0, 0.05)'}
                        borderBottom={1}
                        borderColor={'divider'}
                    >
                        <Typography
                            variant={'h6'}
                            fontWeight={'bold'}
                            textAlign={'center'}
                        >
                            {props.header}
                        </Typography>
                    </Box>
                )
            )}
            {props.children}
        </Paper>
    )
}

import { ThemeModal } from "../../../../helpers/ThemeComponents";
import Loader from "../../../../loader/loader";
import { useShopContext } from "../../../context";
import useAppContext from "../../../../useAppContext";
import { IIncustCoupon, IIncustSettings } from "../../types";
import CouponsModalContent from "./CouponsModalContent";
import { IUseIncustServiceType } from "../../../../features/services/useIncustService/types";
import { SetState } from "../../../../types";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";
import { useMemo } from "react";

interface ICouponsModalPropsNew {
	isCouponsOpen: boolean;
	setIsCouponsOpen: SetState<boolean>;
	isLoading: boolean;
	incustSettings: IIncustSettings | null | undefined;
	checkedCoupons: IIncustCoupon[];
	incustService: IUseIncustServiceType;
}

export default function CouponsModal(props: ICouponsModalPropsNew) {
	const { brandInfo } = useShopContext();
	const {
		localisation,
		authService: { user },
	} = useAppContext();

	const { customerData } = useUserIncustCustomerData();

	const isLoyaltyAvailable = useMemo(() => {
		return (
			customerData?.token ||
			brandInfo.incust_data?.loyalty_applicable_type !== "for_participants"
		);
	}, [customerData?.token, brandInfo.incust_data?.loyalty_applicable_type]);

	return (
		<>
			<ThemeModal
				fullWidth
				maxWidth={"sm"}
				open={props.isCouponsOpen}
				setOpen={props.setIsCouponsOpen}
				title={localisation.profile.loyaltyCoupons}
				contentProps={{ className: "p-0" }}
			>
				{!props.isLoading && isLoyaltyAvailable ? (
					<div>
						{user && customerData && props.incustSettings && customerData.token && (
							<CouponsModalContent
								incustSettings={props.incustSettings}
								userIncustToken={customerData.token}
								brandInfo={brandInfo}
								open={props.isCouponsOpen}
								setOpen={props.setIsCouponsOpen}
								checkedCoupons={props.checkedCoupons}
								incustService={props.incustService}
							/>
						)}
					</div>
				) : (
					<Loader key={"loader"} show={props.isLoading} />
				)}
			</ThemeModal>
		</>
	);
}

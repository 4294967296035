import {useQuery} from "@tanstack/react-query";
import api from "../../api";
import useAppContext from "../../useAppContext";
import {useCallback} from "react";
import {SpecialAccount} from "./types";
import { useSelectedStoreOptional } from "../SelectedStore/context";


export default function useSpecialAccountsService() {
    const {lang, brandInfo} = useAppContext()

    const store = useSelectedStoreOptional()

    const {
        data: specialAccountsMapping,
    } = useQuery({
        queryKey: ['special-accounts-mapping', lang, brandInfo, store?.id],
        queryFn: async () => {
            const response = await api.shop.loyalty.getSpecialAccountsMapping(undefined, store?.id)
            return response.data
        },
        enabled: !!lang && !!brandInfo,
    })

    const getAccountById = useCallback((id: string): SpecialAccount | null => {
        if (!specialAccountsMapping) return null
        return specialAccountsMapping[id] || null
    }, [specialAccountsMapping])

    return {
        specialAccountsMapping,
        getAccountById
    }
}

import { SetStateAction, useCallback, useState } from "react";
import api from "../api";
import { SetState } from "../types";
import useSearchParamsFixed from "../features/hooks/useSearchParamsFixed";

export default function useLangService(): LangServiceType {
	const [, setSearchParams] = useSearchParamsFixed();
	const [lang, _setLang] = useState<string | null>(null);
	const setLang = useCallback(
		(value: SetStateAction<string | null>) => {
			_setLang(prevState => {
				let newValue: string | null;

				if (value instanceof Function) {
					newValue = value(prevState);
				} else {
					newValue = value;
				}

				if (newValue) {
					api.localisation.saveLanguageToLocalStorage(newValue);
				}

				setSearchParams(prev => {
					if (newValue && prev.get("lang") && prev.get("lang") !== newValue) {
						prev.delete("lang");
					}
					return prev;
				});
				document.documentElement.lang = newValue || "en";
				api.instance.defaults.headers.common["Accept-Language"] = newValue || "";

				return newValue;
			});
		},
		[setSearchParams]
	);

	return [lang, setLang];
}

export type LangServiceType = [lang: string | null, setLang: SetState<string | null>];

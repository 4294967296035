import { Box } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IIncustCoupon } from "../../../types";
import useAppContext from "../../../../../useAppContext";
import CollapsableComponent from "../../../../../features/CollapsableComponent";
import classnames from "classnames";
import { CouponCardActions } from "./CouponCardActions";
import {
	CouponCanUseWithOrder,
	CouponDescription,
	CouponExpireDate,
	CouponImage,
	CouponTitle,
} from "./CouponComponents";
import { ExternalCouponCode } from "./ExternalCouponCode";

interface ICouponCardProps {
	coupon: IIncustCoupon,
	setCoupon: (coupon: IIncustCoupon | null) => void,
	setShowQrCode: (_: boolean) => void,
	handlerDeleteButton: (coupon_id: string) => void,
	handlerApplyButton: (coupon: IIncustCoupon) => void,
	handlerShareButton: (coupon: IIncustCoupon) => void,
	index: number,
	isDeleting: boolean | false,
	isSharing: boolean | false,
	isApplying: boolean | false,
	currentCouponId: string | null,
}

export function CouponCard(props: ICouponCardProps) {
	const { authService: { user } } = useAppContext();
	const [imageBoxHeight, setImageBoxHeight] = useState<number | null>(null);
	const [namePriceBoxHeight, setNamePriceBoxHeight] = useState<number | null>(null);
	const [descriptionBoxHeight, setDescriptionBoxHeight] = useState<number | null>(null);

	const nameSecondRowRef = useRef<HTMLDivElement | null>(null);
	const descriptionRef = useRef<HTMLParagraphElement | null>(null);
	const productImageRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		let elementsToObserve: HTMLElement[] = [];

		const imageBoxEl = productImageRef.current;
		if (imageBoxEl) {
			elementsToObserve.push(imageBoxEl);
		}

		const namePriceBoxEl = nameSecondRowRef.current;
		if (namePriceBoxEl) {
			elementsToObserve.push(namePriceBoxEl);
		}

		const descriptionEl = descriptionRef.current;
		if (descriptionEl) {
			elementsToObserve.push(descriptionEl);
		}

		if (elementsToObserve.length) {
			const observer = new ResizeObserver((entries) => {
				entries.forEach(entry => {
					const height = entry.target.getBoundingClientRect().height;
					if (entry.target.classList.contains("image-box")) {
						setImageBoxHeight(height);
					} else if (entry.target.classList.contains("name-price-box")) {
						setNamePriceBoxHeight(height);
					} else if (entry.target.classList.contains("description-box")) {
						setDescriptionBoxHeight(height);
					}
				});
			});

			elementsToObserve.forEach((el) => observer.observe(el));

			return () => {
				elementsToObserve.forEach((el) => observer.unobserve(el));
				observer.disconnect();
			};
		}
	}, [productImageRef]);

	const collapsedSize = useMemo(() => {
		if (
			imageBoxHeight &&
			namePriceBoxHeight &&
			descriptionBoxHeight &&
			imageBoxHeight > (namePriceBoxHeight + descriptionBoxHeight)
		) {
			return null;
		}

		let lineHeight;

		const descriptionEl = descriptionRef.current;
		if (descriptionEl) {
			lineHeight = parseFloat(getComputedStyle(descriptionEl).lineHeight.slice(0, 2));
		} else {
			lineHeight = 0;
		}

		let byLeftSize = 0;

		if (namePriceBoxHeight) {
			byLeftSize = namePriceBoxHeight + lineHeight * 4;
		}

		if (imageBoxHeight && imageBoxHeight > byLeftSize) {
			if (!lineHeight) {
				return null;
			}
			return Math.floor(imageBoxHeight - imageBoxHeight % lineHeight);
		}

		return byLeftSize || namePriceBoxHeight || 24 * 4;
	}, [imageBoxHeight, namePriceBoxHeight, descriptionBoxHeight]);


	if (!props.coupon) return null;

	if (!user) {
		return null;
	}

	return (
		<Box
			px={1}
			pt={2}
			my={2}
			width={"100%"}
			className={props.index === 0 ? "shop-product-card m-0 cursor-pointer" : "shop-product-card m-0 border-top cursor-pointer"}
			sx={{ backgroundImage: "var(--mui-overlays-1)" }}
		>
			<CollapsableComponent
				collapsedSize={collapsedSize}
				sx={{ width: "100%" }}
			>
				<CouponImage
					coupon={props.coupon}
					ratio={[16, 9]}
					productImageRef={productImageRef}
				/>

				<Box>
					<div className={classnames({
						"opacity-65": props.coupon.status === "redeemed",
					})}>
						<Box ref={nameSecondRowRef} className={"name-price-box"}>
							<CouponTitle coupon={props.coupon} />

							<CouponExpireDate coupon={props.coupon} />

							{!props.coupon.redeem_dt
								&& props.coupon.type === "check-modifier"
								&& user && (
									<CouponCanUseWithOrder />
								)}

							<div>
								{props.coupon.type === "external" && (
									<ExternalCouponCode
										external_code={props.coupon?.external_code}
										// justifyLeft={true}
									/>
								)}
							</div>
						</Box>

						{props.coupon.description && (
							<CouponDescription descriptionRef={descriptionRef} coupon={props.coupon} />
						)}

					</div>
				</Box>

			</CollapsableComponent>

			<CouponCardActions
				{...props}
			/>

		</Box>
	);
}

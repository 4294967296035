import ShopBanner from "./ShopBanner";
import ShopClosedAlert from "../ShopClosedAlert";
import {ShopInfoHeader} from "./ShopInfo";


export default function ShopBannerAndInfo() {
    return (
        <div id="shop-info-bar">
            <ShopBanner/>
            <div className={'mt-1 px-2'}>
                <ShopClosedAlert/>
            </div>
            <ShopInfoHeader />
        </div>
    )
}

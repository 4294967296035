import { useMemo } from "react";
import { Box, CircularProgress, Link, Typography } from "@mui/material";

import useAppContext from "../useAppContext";
import changePage from "../helpers/changePage";
import { useSelectedStoreOptional } from "./SelectedStore/context";
import useCurrency from "../services/useCurrencyService";
import useIncustService from "../features/services/useIncustService";

type Align = "start" | "center" | "end";

interface IShopReferralProps {
	marginTop?: number;
	align?: Align;
}

export default function ShopReferral(props: IShopReferralProps) {
	const {
		brandInfo,
		loadingManager: { isSomethingLoading },
		lang,
		localisation: { profile },
	} = useAppContext();

	const selectedStore = useSelectedStoreOptional();

	const currency = useCurrency();
	const incustService = useIncustService(
		brandInfo?.id,
		selectedStore?.id,
		brandInfo?.incust_data,
		lang,
		currency || ""
	);

	const computedShareLink = useMemo(() => {
		if (selectedStore) {
			return `/shop/${selectedStore.id}/profile/share_and_earn`;
		}
		return "/shop/profile/share_and_earn";
	}, [selectedStore]);

	return (
		<Box mt={props.marginTop ? props.marginTop : 2} pt={props.marginTop ? props.marginTop : 2}>
			{incustService.referralInfoLoading && !isSomethingLoading && (
				<Box sx={{ width: "100%" }} textAlign={props.align ? props.align : "center"}>
					<CircularProgress style={{ width: "38px", height: "38px" }} />
				</Box>
			)}

			{!incustService.referralInfoLoading &&
				!!incustService.loyaltyReferralSettings?.referral_program?.active && (
					<Box textAlign={props.align ? props.align : "center"}>
						{!!incustService.loyaltyReferralSettings.referral_program?.title && (
							<Typography variant={"h6"} fontWeight={"bold"}>
								{incustService.loyaltyReferralSettings.referral_program?.title}
							</Typography>
						)}

						<Box>
							<Link role={"button"} onClick={() => changePage(computedShareLink)}>
								{profile.referralLearnMore}
							</Link>
						</Box>
					</Box>
				)}
		</Box>
	);
}

import React from "react";
import './styles.scss'
import {ViewServiceType, ViewType} from "../../../../services/useViewService";
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';


interface ViewToggleProps {
    viewService: ViewServiceType
}

export default function ViewToggle({viewService: {view, setView}}: ViewToggleProps) {
    const getTogglerClassName = (activeIfView: ViewType) => {
        return 'grid-toggler' + (view === activeIfView ? ' active' : '')
    }

    return (
        <div className={'grid-toggle-container'}>
            <div className={getTogglerClassName('list')} onClick={() => setView('list')}>
                <FormatListBulletedIcon/>
            </div>
            <div className={getTogglerClassName('grid')} onClick={() => setView('grid')}>
                <GridViewIcon/>
            </div>
        </div>
    )
}

import { IQrMenuChatButtonProps } from "../types";
import QrMenuButtonWrapper from "./QrMenuButtonWrapper";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import useAppContext from "../../../useAppContext";

export default function ChatButton(props: IQrMenuChatButtonProps) {
	const { bot } = useAppContext();
	const localisation = useLocalisation();

	const onClick = () => {
		if (bot?.bot_type === "telegram") {
			props.setMessage(localisation.qrMenu.needOpenMenuInTg);
		} else if (bot?.bot_type === "whatsapp") {
			props.setMessage(localisation.qrMenu.needOpenMenuInWa);
		}
		props.setShowNeedTgModal(true);
	};

	return <QrMenuButtonWrapper onClick={onClick}>{props.buttonName}</QrMenuButtonWrapper>;
}

import React from "react";
import { motion } from "framer-motion";
import { IApplyCouponInfo, IIncustCoupon, IncustTransaction } from "../../../types";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import { CouponInfoContent } from "../CouponInfoContent";
import { MediaContentSide } from "../shared/MediaContentSide";
import useAppContext from "../../../../../useAppContext";
import CouponAwards from "../shared/CouponAwards";

export function CouponDesktopBackSide(props: {
	showOnPage: null | undefined | boolean;
	setIsInsideCoupon: () => void;
	containerRef: React.MutableRefObject<HTMLDivElement | null>;
	insideCoupon: boolean;
	coupon: IIncustCoupon;
	contentRef: React.MutableRefObject<HTMLDivElement | null>;
	setIsInsideCouponTrue: () => void;
	handleDownload: () => Promise<void>;
	isLoadingPdf: boolean;
	isInWallet: boolean;
	handleApplyCoupon: () => unknown;
	isAddCouponToWalletLoading: boolean;
	isShowApplyButton: boolean;
	applyCouponInfo: IApplyCouponInfo | null;
	redeemedCouponCheck: IncustTransaction | null;
	onCloseApplyPopup: () => unknown;
}): React.JSX.Element {
	const {
		lang,
		authService: { user },
	} = useAppContext();

	const isExternalType = props.coupon.type === "external";

	return (
		<Box
			component={motion.div}
			sx={{
				...(props.showOnPage && { position: "relative" }),
				scrollbarWidth: "none",
				overflowY: "hidden",
			}}
			initial={{ x: "-100%", opacity: 0 }}
			animate={{ x: 0, opacity: 1 }}
			exit={{ x: "-100%", opacity: 0 }}
			transition={{ duration: 0.5 }}
		>
			<Box color={"primary.contrastText"}>
				<IconButton
					onClick={props.setIsInsideCoupon}
					color="inherit"
					size="small"
					sx={{
						position: "absolute",
						top: "5px",
						left: 0,
					}}
				>
					<ArrowBackIosNew />
				</IconButton>
			</Box>

			<Box
				ref={props.containerRef}
				display={"flex"}
				flexDirection={{
					xs: "column",
					sm: "row",
				}}
				gap={{
					xs: "30px",
					sm: "20px",
				}}
				minHeight={{
					xs: "100vh",
					sm: "250px",
				}}
				height={"auto"}
				justifyContent={"center"}
				flexWrap={"wrap"}
			>
				<MediaContentSide
					insideCoupon={props.insideCoupon}
					coupon={props.coupon}
					lang={lang}
					user={user}
					isInWallet={props.isInWallet}
				/>

				<Box
					order={props.insideCoupon ? 1 : 2}
					flex={1}
					minWidth={"min(300px, 100%)"}
					width={"100%"}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					className={"content"}
					ref={props.contentRef}
					borderRadius={{
						xs: "0px 0px 40px 40px",
						sm: "0px 150px 150px 0px",
						md: "0px 180px 180px 0px",
					}}
					// boxShadow={"-2px 0 8px rgba(0, 0, 0, 0.25)"}
					p={{
						xs: "40px 20px 20px",
						sm: "30px 60px 20px 20px",
						md: "20px 100px 20px 30px",
					}}
					sx={{
						width: "100%",
						textAlign: "center",
						bgcolor: `primary.main`,
					}}
				>
					<Box
						display={"flex"}
						flexDirection={"column"}
						height={"100%"}
						justifyContent={"flex-start"}
						gap={"10px"}
						mt={{ md: "20px" }}
						color={"primary.contrastText"}
					>
						<Typography variant={"h5"} fontWeight={"700"} textAlign={"start"}>
							{props.coupon.title}
						</Typography>

						<CouponAwards
							isExternalType={isExternalType}
							coupon={props.coupon}
							applyCouponInfo={props.applyCouponInfo}
							redeemedCouponCheck={props.redeemedCouponCheck}
							onCloseApplyPopup={props.onCloseApplyPopup}
							isInsideCoupon
						/>

						<CouponInfoContent
							coupon={props.coupon}
							insideCoupon={props.insideCoupon}
							setIsInsideCouponTrue={props.setIsInsideCouponTrue}
							handleDownload={props.handleDownload}
							isLoadingPdf={props.isLoadingPdf}
							inWallet={props.isInWallet}
							handleApplyCoupon={props.handleApplyCoupon}
							isAddCouponToWalletLoading={props.isAddCouponToWalletLoading}
							isShowApplyButton={props.isShowApplyButton}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

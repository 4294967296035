import {AuthorisationComponentProps} from "../types";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import {ChangeEvent} from "react";
import FormError from "../FormError";
import { TextField } from "@mui/material";


export default function EmailInput(props: AuthorisationComponentProps) {
    if (
        props.form.state !== 'authMethods' &&
        props.form.state !== 'emailLogin' &&
        props.form.state !== 'emailRegistration'
    ) {
        throw new Error('EmailInput supported only for authMethods, emailLogin and emailRegistration')
    }

    const localisation = useLocalisation()

    const onEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.form.input('email', e.target.value)
    }

    return (<>
        <TextField
            size={'small'}
            fullWidth
            required={true}
            name={'email'}
            type={'email'}
            value={props.form.email}
            label={localisation.auth.emailLabel}
            onChange={onEmailInputChange}
            inputProps={{
                readOnly: props.form.state !== 'authMethods'
            }}
        />
        <FormError errText={props.form.errors.email}/>
    </>)
}

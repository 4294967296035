import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import RatioBox, {RatioBoxProps} from "./RatioBox";
import {ReactNode, useMemo} from "react";


export type ImageStubProps = Omit<RatioBoxProps, 'children'> & {
    emptyStub?: boolean
    node?: ReactNode
}

export default function ImageStub({boxProps, ...props}: ImageStubProps) {
    const calculatedBoxProps = useMemo(() => {
        const internalSx = {
            bgcolor: props.emptyStub ? 'transparent'
                : 'rgba(var(--mui-palette-text-primaryChannel) / 0.02)',
            fontSize: {xs: '2.5rem', md: '3rem', xl: '3.5rem'},
            color: props.emptyStub ? 'transparent'
                : 'rgba(var(--mui-palette-text-primaryChannel) / 0.05)',
        }

        if (!boxProps) {
            return {sx: internalSx}
        }

        const {sx, ...rest} = boxProps
        return {
            sx: {...internalSx, ...sx},
            ...rest,
        }

    }, [boxProps, props.emptyStub])

    return (
        <RatioBox {...props} boxProps={calculatedBoxProps}>
            {!props.emptyStub && (
                props.node || (
                    <ImageNotSupportedOutlinedIcon color={'inherit'} fontSize={'inherit'}/>
                )
            )}
        </RatioBox>
    )
}

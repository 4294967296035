import { router } from "../index";

export default function changePage(
	page: string,
	params: { [key: string]: string | null } | null = null,
	hash: string | null = null
) {
	if (page.startsWith("http")) {
		const url = new URL(page);
		const host = `${url.protocol}//${url.hostname}`;
		page = page.replace(host, "");
		if (!page.startsWith("/")) {
			page = "/" + page;
		}
	}

	let pageParams: URLSearchParams | null = null;
	if (page.includes("?")) {
		pageParams = new URL("https://dummy.dummy" + page).searchParams;
		page = page.split("?")[0];
	}

	let searchParams: URLSearchParams;
	searchParams = new URLSearchParams(router.state.location.search);

	if (!!pageParams) {
		for (const [key, value] of pageParams.entries()) {
			if (!searchParams.has(key) || searchParams.get(key) !== value) {
				searchParams.set(key, value);
			}
		}
	}

	if (params) {
		Object.keys(params).forEach(key => {
			if (searchParams.has(key)) searchParams.delete(key);
			if (params[key]) {
				searchParams.set(key, params[key] as string);
			}
		});
	}
	let navigateTo = `${page}?${searchParams.toString()}`;
	if (hash) {
		navigateTo += `#${hash}`;
	}

	console.log("change page", {
		page,
		params,
		navigateTo,
		searchParams,
	});

	router.navigate(navigateTo).then();
}

import { AlertTitle, Box, CircularProgress, Typography } from "@mui/material";
import api from "../../../../api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import changePage from "../../../../helpers/changePage";
import useLocalisation from "../../../../hooks/localisation/useLocalisation";
import { LoadingButton } from "@mui/lab";
import Alert from "@mui/material/Alert";
import SelectFriend from "./SelectFriend";
import { IFriend } from "../../../../api/shop/friends/types";
import f from "../../../../helpers/formatText";
import { IUsePaymentsService } from "../../../payments/hooks/useNewPaymentsService";

interface ISelectFriendProps {
	orderId: number;
	orderToken: string | null;
	selectedStoreId: number;
	paymentService: IUsePaymentsService;
}

let confirmPayInterval: any = null;
const WaitForRedirectSeconds = 5;

export default function SelectFriendEnd(props: ISelectFriendProps) {
	const localisation = useLocalisation();
	const [paymentStatus, setPaymentStatus] = useState<string>("must_pay");
	const [selectFriendEnd, setSelectFriendEnd] = useState<IFriend | null>(null);
	const [timeToRedirect, setTimeToRedirect] = useState<number | null>(null);

	const handleGoToOrderButtonClick = useCallback(() => {
		if (selectFriendEnd) {
			changePage(`/shop/${props.selectedStoreId}/orders/${props.orderId}`, {
				is_order_created: "true",
				...(props.orderToken && {
					order_token: props.orderToken,
				}),
			});
			return;
		}
	}, [selectFriendEnd, props.selectedStoreId, props.orderId, props.orderToken]);

	const waitConfirmPay = useCallback(() => {
		confirmPayInterval = setInterval(async () => {
			const response = await api.shop.basic.checkPaymentStatus(
				props.orderToken,
				props.orderId
			);
			setPaymentStatus(response.data.payment_status);
			if (response.data.payment_status === "payed") {
				clearInterval(confirmPayInterval);
			}
		}, 5000);
	}, [props.orderId, props.orderToken]);

	useEffect(() => {
		if (paymentStatus !== "payed") {
			waitConfirmPay();
		}
		return () => {
			clearInterval(confirmPayInterval);
		};
	}, [paymentStatus, waitConfirmPay]);

	const computedFriendPayStatus = useMemo(() => {
		return (
			<Alert
				severity={paymentStatus === "payed" ? "success" : "warning"}
				sx={{
					".MuiAlert-action": {
						alignItems: "center",
					},
				}}
				action={
					paymentStatus !== "payed" && (
						<Box mr={2}>
							<CircularProgress color={"inherit"} size={"1rem"} />
						</Box>
					)
				}
			>
				<AlertTitle>
					{paymentStatus === "payed"
						? localisation.orders.paySuccess
						: localisation.orders.payWaitToPayFriend}
				</AlertTitle>
				{paymentStatus === "payed"
					? localisation.orders.paySuccess
					: f(localisation.payment.orderSentToFriendText, {
							name: selectFriendEnd?.name,
						})}
				{paymentStatus === "payed" && (
					<Typography fontSize={"inherit"} mt={1}>
						{f(localisation.payment.friendPayedRedirectWaitText, {
							seconds: timeToRedirect || WaitForRedirectSeconds,
						})}
					</Typography>
				)}
			</Alert>
		);
	}, [
		paymentStatus,
		localisation.orders.payWaitToPayFriend,
		localisation.orders.paySuccess,
		selectFriendEnd,
		timeToRedirect,
		localisation.payment.friendPayedRedirectWaitText,
		localisation.payment.orderSentToFriendText,
	]);

	const handleSelectFriendEnd = (friend: IFriend) => {
		setSelectFriendEnd(friend);
	};

	useEffect(() => {
		if (paymentStatus === "payed") {
			let counter = WaitForRedirectSeconds;
			const timer = setInterval(() => {
				counter -= 1;
				setTimeToRedirect(counter);
				if (counter === 0) {
					clearInterval(timer);
					handleGoToOrderButtonClick();
				}
			}, 1000);

			return () => {
				clearInterval(timer);
			};
		} else {
			setTimeToRedirect(null);
		}
	}, [paymentStatus, handleGoToOrderButtonClick]);

	return (
		<>
			{!selectFriendEnd && (
				<SelectFriend
					orderId={props.orderId}
					onSelectFriendEnd={handleSelectFriendEnd}
					paymentService={props.paymentService}
				/>
			)}

			{selectFriendEnd && (
				<Box mt={2} gap={1}>
					<Box>{computedFriendPayStatus}</Box>
					<Box gap={1} mt={2}>
						<LoadingButton
							onClick={handleGoToOrderButtonClick}
							disabled={!selectFriendEnd}
							variant={"contained"}
						>
							{localisation.orders.viewOrderButton}
						</LoadingButton>
					</Box>
				</Box>
			)}
		</>
	);
}

import { IInvoiceCreatedResult, InvoiceStatus } from "../../../api/invoice/types";
import useAppContext from "../../../useAppContext";
import React, { useCallback, useEffect, useState } from "react";
import { IFriend } from "../../../api/shop/friends/types";
import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import changePage from "../../../helpers/changePage";
import SelectFriend from "../../order/MakeOrder/OrderStepper/SelectFriend";
import { AlertTitle, Box, Button, CircularProgress, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import f from "../../../helpers/formatText";
import { IUsePaymentsService } from "../hooks/useNewPaymentsService";

interface FriendPaymentProps {
	invoiceCreated: IInvoiceCreatedResult | null;
	paymentsService: IUsePaymentsService;
}

const WaitForRedirectSeconds = 5;

export default function FriendPayment(props: FriendPaymentProps) {
	const { localisation } = useAppContext();

	const [timeToRedirect, setTimeToRedirect] = useState<number | null>(null);

	const [sentToFriend, setSentToFriend] = useState<IFriend | null>(null);
	const [paymentStatus, setPaymentStatus] = useState<InvoiceStatus>("not_payed");
	const { data: statusData } = useQuery({
		queryKey: ["invoice-payment-status", props.invoiceCreated?.token_data.token],
		queryFn: () =>
			api.shop.basic
				.checkPaymentStatus(
					props.invoiceCreated?.token_data.token,
					null,
					props.invoiceCreated?.invoice.id || null
				)
				.then(response => response.data),
		enabled: paymentStatus !== "payed",
		refetchInterval: 5000,
	});

	useEffect(() => {
		if (statusData?.payment_status) {
			if (statusData.payment_status === "payed") {
				setPaymentStatus(statusData.payment_status);
			} else {
				setPaymentStatus("not_payed");
			}
		}
	}, [statusData?.payment_status]);

	const redirectToSuccessPayment = useCallback(() => {
		if (sentToFriend && props.invoiceCreated?.invoice.id) {
			changePage(`/success_payment`, {
				...(props.invoiceCreated.token_data && {
					invoice_token: props.invoiceCreated.token_data.token,
				}),
			});
			return;
		}
	}, [props.invoiceCreated?.invoice.id, props.invoiceCreated?.token_data, sentToFriend]);

	useEffect(() => {
		if (paymentStatus === "payed") {
			let counter = WaitForRedirectSeconds;
			const timer = setInterval(() => {
				counter -= 1;
				setTimeToRedirect(counter);
				if (counter === 0) {
					clearInterval(timer);
					redirectToSuccessPayment();
				}
			}, 1000);

			return () => {
				clearInterval(timer);
			};
		} else {
			setTimeToRedirect(null);
		}
	}, [paymentStatus, redirectToSuccessPayment]);

	const handleSelectFriendEnd = (friend: IFriend) => {
		setSentToFriend(friend);
	};

	return (
		<>
			{!sentToFriend ? (
				<SelectFriend
					invoiceId={props.invoiceCreated?.invoice.id}
					onSelectFriendEnd={handleSelectFriendEnd}
					paymentService={props.paymentsService}
				/>
			) : (
				<Box mt={2} gap={1}>
					<Alert
						severity={paymentStatus === "payed" ? "success" : "warning"}
						sx={{
							".MuiAlert-action": {
								alignItems: "center",
							},
						}}
						action={
							paymentStatus !== "payed" && (
								<Box mr={2}>
									<CircularProgress color={"inherit"} size={"1rem"} />
								</Box>
							)
						}
					>
						<AlertTitle>
							{paymentStatus === "payed"
								? f(localisation.orders.payedInvoice, {
										invoice_number: props.invoiceCreated?.invoice.id,
									})
								: localisation.orders.payWaitToPayFriend}
						</AlertTitle>
						{f(
							paymentStatus === "payed"
								? localisation.payment.friendPaidInvoiceText
								: localisation.payment.invoiceSentToFriendText,
							{
								name: sentToFriend.name,
							}
						)}
						{paymentStatus === "payed" && (
							<Typography fontSize={"inherit"} mt={1}>
								{f(localisation.payment.friendPayedRedirectWaitText, {
									seconds: timeToRedirect || WaitForRedirectSeconds,
								})}
							</Typography>
						)}
					</Alert>
					<Box gap={1} mt={2}>
						<Button
							onClick={redirectToSuccessPayment}
							disabled={!sentToFriend}
							variant={"contained"}
						>
							{localisation.orders.viewInvoiceButton}
						</Button>
					</Box>
				</Box>
			)}
		</>
	);
}

import React, { Component, ErrorInfo } from "react";
import { MDBRow } from "mdb-react-ui-kit";

import api from "../api";
import { TgButton } from "../helpers/ThemeComponents";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import Interweave from "./Interweave";
import Card from "@mui/material/Card";

interface Props {
	children: React.ReactNode;
}

interface ErrorBoundaryLocale {
	header: string;
	update: string;
	clear: string;
	details: string;
	copy: string;
}

interface State {
	hasError: boolean;
	error: Error | null;
	localizations: ErrorBoundaryLocale | null;
	isLoading: boolean;
	showDetailedError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			localizations: null,
			isLoading: false,
			showDetailedError: false,
		};
	}

	// componentDidMount() {
	// 	window.onerror = (message, source, lineno, colno, error) => {
	// 		console.error("*** Global error:", error);
	// 		this.setState({ globalErrors: JSON.stringify(error?.message, null, 2) });
	// 		// Handle the error or log it to a monitoring service
	// 	};
	// }

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({ error: error, hasError: true });

		const getLocalization = async () => {
			this.setState({ isLoading: true });

			let lang = localStorage.getItem("lang");
			if (!lang) {
				lang = navigator.language;
			}

			try {
				const payload = {
					variables: {
						header: "error boundary header",
						update: "error boundary update button",
						clear: "error boundary clear session data button",
						details: "error boundary show error details button",
						copy: "error boundary copy error",
					},
					lang,
				};
				const response = await api.localisation.getLocalisationList(payload);

				this.setState({
					localizations: {
						header: response.data.header,
						update: response.data.update,
						clear: response.data.clear,
						details: response.data.details,
						copy: response.data.copy,
					},
				});
			} catch (err) {
				console.log(err);
				this.setState({
					localizations: {
						header: "Something went wrong.",
						update: "Try again",
						clear: "Clear session data",
						details: "Show error details",
						copy: "Copy error",
					},
				});
			} finally {
				this.setState({ isLoading: false });
			}
		};

		getLocalization().then();
	}

	render() {
		if (this.state.hasError) {
			if (this.state.isLoading) {
				return (
					<Box
						height={"100%"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<CircularProgress size={"5rem"} />
					</Box>
				);
			}

			return (
				<MDBRow className={"p-2 justify-content-center text-center m-0"}>
					<h3 className={"text-bold"}>{this.state.localizations?.header}</h3>

					<Box>
						<TgButton className={"me-2 mt-2"} onClick={() => window.location.reload()}>
							{this.state.localizations?.update}
						</TgButton>

						<TgButton
							className={"me-2 mt-2"}
							onClick={() => {
								localStorage.clear();
								window.location.reload();
							}}
						>
							{this.state.localizations?.clear}
						</TgButton>

						<TgButton
							className={"mt-2"}
							onClick={() =>
								this.setState({ showDetailedError: !this.state.showDetailedError })
							}
						>
							{this.state.localizations?.details}
						</TgButton>
					</Box>

					{this.state.showDetailedError && this.state.error && (
						<Box textAlign={"center"} width={"auto"} mt={3}>
							<Card sx={{ p: 3, maxHeight: 600, overflowY: "auto" }}>
								<Box textAlign={"start"}>
									<Link
										sx={{ mb: 3, cursor: "pointer" }}
										onClick={() => {
											navigator.clipboard
												.writeText(
													JSON.stringify(
														this.state.error?.name +
															"\n" +
															this.state.error?.message +
															"\n" +
															this.state.error?.cause +
															"\n" +
															this.state.error?.stack
													)
												)
												.then();
										}}
									>
										{this.state.localizations?.copy}
									</Link>

									<Typography variant={"h5"} fontWeight={"bold"}>
										Error:
									</Typography>
									<Box>
										<Interweave content={this.state.error.name} />
									</Box>

									<Typography variant={"h5"} fontWeight={"bold"}>
										Message:
									</Typography>
									<Box>
										<Interweave content={this.state.error.message} />
									</Box>
								</Box>

								<Box textAlign={"start"}>
									<>
										{this.state.error.cause && (
											<Box mt={2}>
												<Typography variant={"h5"} fontWeight={"bold"}>
													Cause:
												</Typography>
												<Box>
													<Interweave
														content={JSON.stringify(
															this.state.error.cause
														).replace(/\\n/g, "<br>")}
													/>
												</Box>
											</Box>
										)}

										{this.state.error.stack && (
											<Box mt={2}>
												<Typography variant={"h5"} fontWeight={"bold"}>
													Stack:
												</Typography>
												<Box>
													<Interweave
														content={this.state.error.stack?.replace(
															/\\n/g,
															"<br>"
														)}
													/>
												</Box>
											</Box>
										)}
									</>
								</Box>
							</Card>
						</Box>
					)}
				</MDBRow>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;

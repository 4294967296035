import { useState, useMemo, useCallback, FormEvent, useEffect } from "react";

import useAppContext from "../../../../useAppContext";
import { Alert, Box, Button, CircularProgress, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import useSearchParamsFixed from "../../../../features/hooks/useSearchParamsFixed";
import { useLocation } from "react-router-dom";
import { ExternalLogin, ExternalLoginType } from "../../../../api/auth/types";
import ExternalLoginWidget from "../../../../auth/Autorisation/external/ExternalLoginWidget";
import api from "../../../../api";
import TextField from "@mui/material/TextField";
import { useMessangerName, useUserMessangerIdString } from "../../../../hooks/messanger";
import Interweave from "../../../../features/Interweave";
import useExternalLoginExtraData from "../../../../auth/Autorisation/external/useExternalLoginExtraData";

enum LinkState {
	link,
	unlink,
	default,
}

interface IEditMessangerProps {
	externalLoginType: ExternalLoginType;
}

export default function EditMessanger(props: IEditMessangerProps) {
	const {
		showError,
		localisation: { auth, global },
	} = useAppContext();

	const [linkState, setLinkState] = useState<LinkState>(LinkState.default);
	const [linkNewMessanger, setLinkNewMessanger] = useState<boolean>(false);

	const [skipManagerOrAdmin, setSkipManagerOrAdmin] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const messangerName = useMessangerName(props.externalLoginType);

	const hasMessanger = useHasMessanger(props.externalLoginType);

	useEffect(() => {
		api.auth
			.checkIsManagerOrAdmin()
			.then(response => {
				if (response.status === 200) {
					if (!response.data.is_manager_or_admin) setSkipManagerOrAdmin(true);
					setIsLoading(false);
				}
			})
			.catch(() => {
				showError(auth.cantCheckAdminOrManager.replace("{messanger}", messangerName));
			});
	}, [auth.cantCheckAdminOrManager, messangerName, showError]);

	if (isLoading) {
		return (
			<Box sx={{ width: "100%" }} textAlign={"center"}>
				<CircularProgress style={{ width: "38px", height: "38px" }} />
			</Box>
		);
	}

	if (!skipManagerOrAdmin) {
		return (
			<>
				<Alert severity={"warning"} variant={"outlined"} className={"small p-3 mb-2"}>
					<Interweave
						content={auth.isManagerOrAdmin.replace("{messanger}", messangerName)}
					/>
				</Alert>

				<Box sx={{ width: "100%" }}>
					<Button
						variant={"contained"}
						onClick={() => setSkipManagerOrAdmin(true)}
						sx={{ width: "100%" }}
					>
						{global.continueAnyway}
					</Button>
				</Box>
			</>
		);
	}

	if (!hasMessanger) {
		if (linkNewMessanger) {
			return (
				<LinkMessanger
					externalLoginType={props.externalLoginType}
					setLinkNewMessanger={setLinkNewMessanger}
				/>
			);
		} else {
			return (
				<LinkNewMessanger
					externalLoginType={props.externalLoginType}
					setLinkNewMessanger={setLinkNewMessanger}
				/>
			);
		}
	} else {
		if (linkState === LinkState.default) {
			return (
				<LinkUnlinkButtons
					externalLoginType={props.externalLoginType}
					setLinkState={setLinkState}
				/>
			);
		} else if (linkState === LinkState.link) {
			return (
				<LinkUnlinkButtons
					externalLoginType={props.externalLoginType}
					setLinkState={setLinkState}
				/>
			);
		} else if (linkState === LinkState.unlink) {
			return (
				<UnlinkMessanger
					externalLoginType={props.externalLoginType}
					setLinkState={setLinkState}
				/>
			);
		} else {
			return null;
		}
	}
}

function useHasMessanger(externalLoginType: ExternalLoginType) {
	const {
		authService: { user },
	} = useAppContext();

	switch (externalLoginType) {
		case "telegram":
			return !!user?.chat_id;
		case "whatsapp":
			return !!user?.wa_phone;
		default:
			return false;
	}
}

interface ILinkNewMessanger extends IEditMessangerProps {
	setLinkNewMessanger: (value: boolean) => void;
}

function LinkNewMessanger(props: ILinkNewMessanger) {
	const {
		localisation: { auth },
	} = useAppContext();
	const messangerName = useMessangerName(props.externalLoginType);

	return (
		<>
			<Typography variant={"body2"} sx={{ mb: 2 }}>
				{auth.linkNewMessangerSubHeader.replace("{messanger}", messangerName)}
			</Typography>

			<Button
				sx={{ width: "100%" }}
				variant={"contained"}
				onClick={() => props.setLinkNewMessanger(true)}
			>
				{auth.linkMessanger.replace("{messanger}", messangerName)}
			</Button>
		</>
	);
}

interface ILinkUnlinkMessangerProps extends IEditMessangerProps {
	setLinkState?: (value: LinkState) => void;
	setLinkNewMessanger?: (value: boolean) => void;
}

function LinkMessanger(props: ILinkUnlinkMessangerProps) {
	const {
		bot,
		authService: { loadUser },
		showError,
		localisation: { auth },
	} = useAppContext();
	const [searchParams] = useSearchParamsFixed();
	const location = useLocation();

	const messangerName = useMessangerName(props.externalLoginType);

	const extraData = useExternalLoginExtraData();

	const setLinkState = props.setLinkState;
	const setLinkNewMessanger = props.setLinkNewMessanger;
	const externalLoginOnChange = useCallback(
		(data: ExternalLogin) => {
			if (data.status === "success") {
				if (data.logged_in_token_data) {
					loadUser().then(() => {
						if (setLinkState) setLinkState(LinkState.default);
						if (setLinkNewMessanger) setLinkNewMessanger(false);
					});
				}
			}
			if (data.status === "error") {
				setLinkState && setLinkState(LinkState.default);
				showError(auth.linkErr.replace("{messanger}", messangerName));
			}
		},
		[auth.linkErr, loadUser, messangerName, setLinkNewMessanger, setLinkState, showError]
	);

	const computedLinkAvailable = useMemo(() => {
		return bot?.bot_type === props.externalLoginType || false;
	}, [bot?.bot_type, props.externalLoginType]);

	if (!computedLinkAvailable || !props.externalLoginType || !bot) return null;

	return (
		<Box textAlign={"center"} sx={{ mb: 3 }}>
			<ExternalLoginWidget
				type={props.externalLoginType}
				purpose={"link"}
				onChange={externalLoginOnChange}
				bot_id={bot.id}
				continue_url={
					window.location.origin + location.pathname + "?" + searchParams.toString()
				}
				extra_data={extraData}
			/>
			{!!props.setLinkState && <BackButton setLinkState={props.setLinkState} />}
		</Box>
	);
}

function UnlinkMessanger(props: ILinkUnlinkMessangerProps) {
	const {
		showError,
		localisation: { auth, global },
		authService: { user, updateUserData },
	} = useAppContext();

	const messangerName = useMessangerName(props.externalLoginType);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [password, setPassword] = useState<string>("");

	const setLinkState = props.setLinkState;
	const unlinkMessanger = useCallback(
		async (e?: FormEvent<HTMLFormElement>) => {
			if (e) e.preventDefault();

			try {
				setIsLoading(true);
				const response = await api.auth.unlinkMessanger(props.externalLoginType, password);
				updateUserData(response.data);
				if (setLinkState) setLinkState(LinkState.default);
			} catch (ex: any) {
				if (!user?.is_password && setLinkState) setLinkState(LinkState.default);
				showError(ex);
			} finally {
				setIsLoading(false);
			}
		},
		[
			props.externalLoginType,
			updateUserData,
			password,
			showError,
			user?.is_password,
			setLinkState,
		]
	);

	useEffect(() => {
		if (!user?.is_password && !isLoading) {
			unlinkMessanger().then();
		}
	}, [isLoading, unlinkMessanger, user?.is_password]);

	if (!user?.is_password) {
		return (
			<>
				{isLoading && (
					<Box sx={{ width: "100%" }} textAlign={"center"}>
						<CircularProgress style={{ width: "38px", height: "38px" }} />
					</Box>
				)}
			</>
		);
	}

	return (
		<>
			<Typography variant={"body2"} sx={{ mb: 2 }}>
				{auth.unlinkConfirmPassword.replace("{messanger}", messangerName)}
			</Typography>

			<form onSubmit={unlinkMessanger} className={"mb-2"}>
				<TextField
					sx={{ mb: 2 }}
					required
					size={"medium"}
					fullWidth
					id={"password"}
					type={"password"}
					label={auth.loginPasswordLabel}
					value={password}
					onChange={e => setPassword(e.target.value)}
					disabled={isLoading}
				/>

				<Button
					variant={"contained"}
					type={"submit"}
					disabled={isLoading}
					sx={{ width: "100%" }}
				>
					{isLoading && (
						<CircularProgress
							className={"me-2"}
							style={{ width: "18px", height: "18px" }}
						/>
					)}
					{global.confirmButton}
				</Button>
			</form>

			{!!props.setLinkState && (
				<BackButton isLoading={isLoading} setLinkState={props.setLinkState} />
			)}
		</>
	);
}

interface IBackButtonProps {
	isLoading?: boolean;
	setLinkState: (value: LinkState) => void;
}

function BackButton(props: IBackButtonProps) {
	const {
		localisation: { global },
	} = useAppContext();

	return (
		<Button
			variant={"text"}
			disabled={props.isLoading}
			sx={{ width: "100%" }}
			onClick={() => props.setLinkState(LinkState.default)}
		>
			{global.back}
		</Button>
	);
}

function LinkUnlinkButtons(props: ILinkUnlinkMessangerProps) {
	const {
		localisation: { auth },
		authService: { user },
	} = useAppContext();
	const messangerName = useMessangerName(props.externalLoginType);
	const hasMessanger = useHasMessanger(props.externalLoginType);

	const computedLinkButtonText = useMemo(() => {
		return hasMessanger
			? auth.changeMessanger.replace("{messanger}", messangerName)
			: auth.linkMessanger.replace("{messanger}", messangerName);
	}, [auth.changeMessanger, auth.linkMessanger, hasMessanger, messangerName]);

	const userId = useUserMessangerIdString(props.externalLoginType);

	const computedHeaderText = useMemo(() => {
		return auth.alreadyLinked.replace("{messanger}", messangerName).replace("{id}", userId);
	}, [auth.alreadyLinked, messangerName, userId]);

	return (
		<Box sx={{ width: "100%" }} textAlign={"center"}>
			<Typography variant={"body2"} sx={{ mb: 3 }}>
				<Interweave content={computedHeaderText} />
			</Typography>

			<Box>
				<Button
					sx={{ width: "100%" }}
					variant={"contained"}
					onClick={() => props.setLinkState && props.setLinkState(LinkState.link)}
				>
					<>
						<LinkIcon sx={{ mr: 1 }} />
						{computedLinkButtonText}
					</>
				</Button>
			</Box>

			{!!user?.has_multiple_ids && (
				<Box sx={{ mt: 2 }}>
					<Button
						sx={{ width: "100%" }}
						variant={"contained"}
						onClick={() => props.setLinkState && props.setLinkState(LinkState.unlink)}
					>
						<>
							<LinkOffIcon sx={{ mr: 1 }} />
							{auth.unlinkMessanger.replace("{messanger}", messangerName)}
						</>
					</Button>
				</Box>
			)}
		</Box>
	);
}

import { useCallback, useMemo, useState } from "react";
import {
	Shipment,
	CustomShipment,
	ShipmentsData,
	CustomShipmentGroup,
} from "../../../api/shop/basic/types";
import api from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { ShipmentBaseTypeLiteral } from "../../types";
import useShopContext from "../useShopContext";

export default function useShipmentsService(
	storeId?: number | null,
	lang?: string | null
): ShipmentsServiceType {
	const { menuInStoreService } = useShopContext();

	const [noDeliveryTypeShipment, setNoDeliveryTypeShipment] = useState<Shipment | null>(null);

	const query = useQuery<ShipmentsData, any>({
		queryKey: ["shipments", storeId, lang],
		queryFn: async () => {
			const data = await api.shop.basic.getShipments(storeId).then(response => response.data);
			const noDelivery = data.base.find(shipment => shipment.base_type === "no_delivery");
			if (noDelivery) setNoDeliveryTypeShipment(noDelivery);
			data.base = data.base.filter(shipment => shipment.base_type !== "no_delivery");

			return data;
		},
		enabled: !!lang,
		initialData: {
			base: [],
			groups: [],
			rest: [],
		},
	});
	const shipments = query.data;

	const getShipmentById = useCallback(
		(id: number): Shipment | CustomShipment | CustomShipmentGroup | null => {
			const baseShipment = shipments.base.find(shipment => shipment.id === id);
			if (baseShipment) return baseShipment;

			const restShipment = shipments.rest.find(shipment => shipment.id === id);
			if (restShipment) return restShipment;

			for (const group of shipments.groups) {
				if (group.id === id) {
					return group;
				}
				const groupShipment = group.shipments.find(shipment => shipment.id === id);
				if (groupShipment) return groupShipment;
			}
			return null;
		},
		[shipments.base, shipments.groups, shipments.rest]
	);

	const getBaseShipment = useCallback(
		(baseType: ShipmentBaseTypeLiteral) => {
			return shipments.base.find(shipment => shipment.base_type === baseType) || null;
		},
		[shipments]
	);

	const computedStoreEmptyShipments = useMemo(() => {
		if (query.isFetching) return false;

		if (menuInStoreService.menuInStoreIdParam) {
			return !getBaseShipment("in_store");
		}

		let allShipmentsCount = 0;
		allShipmentsCount += shipments.base.length;
		allShipmentsCount += shipments.rest.length;
		allShipmentsCount += shipments.groups.reduce((accumulator, group) => {
			return accumulator + group.shipments.length;
		}, 0);

		if (allShipmentsCount === 0) return true;
		if (allShipmentsCount === 1) {
			if (shipments.base.length === 1) {
				if (shipments.base[0].base_type === "in_store") return true;
			}
		}
		return false;
	}, [
		shipments.base,
		shipments.groups,
		shipments.rest.length,
		getBaseShipment,
		menuInStoreService.menuInStoreIdParam,
	]);

	const computedStoreEmptyPaymentsMethods = useMemo((): ShipmentsPaymentsAvailabilityType => {
		let isAnyPaymentCashMethod = false;
		let isAnyPaymentOnlineMethod = false;
		let isAnyCustomPaymentMethod = false;

		shipments.base.forEach(shipment => {
			if (shipment.base_type !== "in_store") {
				if (shipment.allow_cash_payment) isAnyPaymentCashMethod = true;
				if (shipment.allow_online_payment) isAnyPaymentOnlineMethod = true;
				if (shipment.payments_ids.length) isAnyCustomPaymentMethod = true;
			}
		});

		if (noDeliveryTypeShipment) {
			if (noDeliveryTypeShipment.allow_cash_payment) isAnyPaymentCashMethod = true;
			if (noDeliveryTypeShipment.allow_online_payment) isAnyPaymentOnlineMethod = true;
			if (noDeliveryTypeShipment.payments_ids.length) isAnyCustomPaymentMethod = true;
		}

		shipments.rest.forEach(shipment => {
			if (shipment.allow_cash_payment) isAnyPaymentCashMethod = true;
			if (shipment.allow_online_payment) isAnyPaymentOnlineMethod = true;
			if (shipment.payments_ids.length) isAnyCustomPaymentMethod = true;
		});

		shipments.groups.forEach(group => {
			group.shipments.forEach(shipment => {
				if (shipment.allow_cash_payment) isAnyPaymentCashMethod = true;
				if (shipment.allow_online_payment) isAnyPaymentOnlineMethod = true;
				if (shipment.payments_ids.length) isAnyCustomPaymentMethod = true;
			});
		});

		return {
			notAvailableCash: !isAnyPaymentCashMethod,
			notAvailableOnline: !isAnyPaymentOnlineMethod,
			notAvailableCustomPayments: !isAnyCustomPaymentMethod,
		};
	}, [noDeliveryTypeShipment, shipments.base, shipments.groups, shipments.rest]);

	return useMemo((): ShipmentsServiceType => {
		const { data: shipments, ...rest } = query;

		let allShipmentsCount = 0;
		allShipmentsCount += shipments.base.length;
		allShipmentsCount += shipments.rest.length;
		allShipmentsCount += shipments.groups.reduce((accumulator, group) => {
			return accumulator + group.shipments.length;
		}, 0);

		let shipmentsCount = allShipmentsCount;
		let baseShipmentsCount = shipments.base.length;

		if (getBaseShipment("in_store")) {
			shipmentsCount -= 1;
			baseShipmentsCount -= 1;
		}

		return {
			shipments,
			allShipmentsCount,
			shipmentsCount,
			baseShipmentsCount,
			getShipmentById,
			getBaseShipment,
			computedStoreEmptyShipments,
			computedStoreEmptyPaymentsMethods,
			noDeliveryTypeShipment,
			refetchShipments: query.refetch,
			...rest,
		};
	}, [
		noDeliveryTypeShipment,
		query,
		getShipmentById,
		getBaseShipment,
		computedStoreEmptyShipments,
		computedStoreEmptyPaymentsMethods,
	]);
}

export type ShipmentsServiceType = Omit<UseQueryResult<ShipmentsData, any>, "data"> & {
	shipments: ShipmentsData;
	allShipmentsCount: number;
	shipmentsCount: number; // without in_store
	baseShipmentsCount: number; // without in_store
	getShipmentById: (id: number) => Shipment | CustomShipment | CustomShipmentGroup | null;
	getBaseShipment: (baseType: ShipmentBaseTypeLiteral) => Shipment | null;
	computedStoreEmptyShipments: boolean;
	computedStoreEmptyPaymentsMethods: ShipmentsPaymentsAvailabilityType;
	noDeliveryTypeShipment: Shipment | null;
	refetchShipments: () => void;
};

export type ShipmentsPaymentsAvailabilityType = {
	notAvailableOnline: boolean;
	notAvailableCash: boolean;
	notAvailableCustomPayments: boolean;
};

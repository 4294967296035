import {MenuInStore} from "../../api/menuInStore/types";
import {useShopContext} from "../context";
import {useSelectedStoreOptional} from "../SelectedStore/context";
import {Box, Typography} from "@mui/material";
import React from "react";

interface QrMenuTextProps {
    menuInStore: MenuInStore
}


export default function QrMenuText({menuInStore}: QrMenuTextProps) {
    const {brandInfo} = useShopContext()
    const selectedStore = useSelectedStoreOptional()

    return (<>
        {(menuInStore.texts.greeting_text || menuInStore.texts.comment_view_text) && (
            <Box
                py={2} px={{xs: 3, md: 4}}
                borderBottom={1}
                borderColor={'divider'}
            >
                {menuInStore.texts.greeting_text && (
                    <Typography fontSize={'large'}>
                        {
                            menuInStore.texts.greeting_text
                                .replace("{name}", selectedStore?.name || brandInfo.name || '')
                                .replace("{comment}", menuInStore.comment)
                        }
                    </Typography>
                )}
                {menuInStore.texts.comment_view_text && (
                    <Typography variant={'body2'} color={'text.secondary'}>
                        {
                            menuInStore.texts.comment_view_text
                                .replace("{name}", selectedStore?.name || brandInfo.name || '')
                                .replace("{comment}", menuInStore.comment)
                        }
                    </Typography>
                )}
            </Box>
        )}
    </>)
}

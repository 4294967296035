import formatCurrency from "../../helpers/formatCurrency";
import { useSelectedStoreContext } from "../SelectedStore/context";
import { MDBIcon } from "mdb-react-ui-kit";
import { Button } from "@mui/material";
import changePage from "../../helpers/changePage";
import useAppContext from "../../useAppContext";
import useScreenService from "../../services/useScreenService";
import { useMemo } from "react";

export default function CartButton() {
	const { isMobile } = useScreenService();
	const { lang, localisation, appearance, brandInfo } = useAppContext();

	const { cartService, selectedStore } = useSelectedStoreContext();

	const onClick = () => {
		changePage(`/shop/${selectedStore.id}/cart`);
	};

	const computedButtonMode = useMemo(() => {
		return (
			appearance.appearanceState?.mobile_cart_button_mode ||
			appearance.appearanceQuery?.mobile_cart_button_mode
		);
	}, [
		appearance.appearanceQuery?.mobile_cart_button_mode,
		appearance.appearanceState?.mobile_cart_button_mode,
	]);

	const computedText = useMemo(() => {
		return isMobile && computedButtonMode === "bottom"
			? localisation.cart.bottomCartButton
			: localisation.cart.headerCartButton;
	}, [
		computedButtonMode,
		isMobile,
		localisation.cart.bottomCartButton,
		localisation.cart.headerCartButton,
	]);

	return (
		<Button variant={"contained"} onClick={onClick}>
			<MDBIcon fas icon={"shopping-cart"} className={"me-1"} />

			{computedText
				.replace("{qty}", cartService.productsCount.toLocaleString())
				.replace(
					"{sum}",
					formatCurrency(
						cartService.totalSum,
						brandInfo?.default_lang || lang,
						selectedStore.currency
					)
				)}
		</Button>
	);
}

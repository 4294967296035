import { MakeInvoiceProps } from "./types";
import useCurrency from "../../../services/useCurrencyService";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import useAppContext from "../../../useAppContext";
import InvoiceTemplateInfo from "./InvoiceTemplateInfo";
import MakeInvoiceForm from "./MakeInvoiceForm/MakeInvoiceForm";

export default function MakeInvoice(props: MakeInvoiceProps) {
	const {
		lang,
		authService: { user, token: userToken },
		loadingManager: { isSomethingLoading },
	} = useAppContext();

	const currency = useCurrency();

	if (!lang) return null; // for typescript

	if (!currency) {
		return <Typography color={"error"}>No currency</Typography>;
	}

	if (props.isLoading || (userToken && !user)) {
		return (
			<Box width={"100%"} display={"flex"} justifyContent={"center"} mt={4} mb={3}>
				{!isSomethingLoading && <CircularProgress size={"3rem"} />}
			</Box>
		);
	}

	if (props.paymentMode === "template" && props.invoiceTemplate) {
		return (
			<Grid container justifyContent={"center"} alignItems={"stretch"}>
				<Grid
					item
					xs={12}
					md={5}
					display={"flex"}
					alignItems={"center"}
					sx={{
						borderRight: { xs: 0, md: 1 },
						borderRightColor: { xs: "none", md: "divider" },
						pr: { xs: 0, md: 2 },
					}}
				>
					<Box
						sx={{
							width: "100%",
						}}
					>
						<InvoiceTemplateInfo
							currency={currency}
							invoiceTemplate={props.invoiceTemplate}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={7}>
					<MakeInvoiceForm {...props} />
				</Grid>
			</Grid>
		);
	}

	return (
		<Box width={"100%"}>
			<MakeInvoiceForm {...props} />
		</Box>
	);
}

import {TgLink, ThemeModal} from "../../helpers/ThemeComponents";
import {SetState} from "../../types";
import {Box} from "@mui/material";
import useAppContext from "../../useAppContext";
import useLocale from "../../hooks/localisation/useLocale";
import {useMemo} from "react";
import {InviteInterface} from "../../components/InviteInterface";


interface ShowAddFriendProps {
    show: boolean
    setShow: SetState<boolean>
}

export default function ShowAddFriendModal(props: ShowAddFriendProps) {
    const {localisation: {profile}} = useAppContext()
    const {authService: {user, setShowAuthorisationModal}} = useAppContext()

    const {
        brandInfo,
        bot,
    } = useAppContext()
    const locale = useLocale('global')

    const baseUrl = window.location.origin
    const qrWebUrl = `${baseUrl}/shop/add_friend/${user?.id}`;

    const qrBotUrl = useMemo(() => {
        if (bot?.bot_type === 'telegram' && user?.messangers?.includes(bot.bot_type)) {
            return `https://t.me/${brandInfo?.bot_name}?start=friend-${user.id}`;
        }

        if (bot?.bot_type === 'whatsapp' && user?.messangers?.includes(bot.bot_type)) {
            return `https://wa.me/${bot.whatsapp_from_phone_number}?text=friend-${user.id}`;
        }

        return null

    }, [bot, brandInfo?.bot_name, user]);

    return (
        <ThemeModal
            open={props.show} setOpen={props.setShow}
            title={locale.msgAddFriendTitle}
            fullWidth
            contentProps={{
                sx: {
                    pt: 2,
                }
            }}
        >
            <>
                {!!user
                    ? (
                        <>
                            <InviteInterface
                                bodyText={locale.msgAddFriendDescription}
                                botType={bot?.bot_type}
                                linkDescWeb={locale.addFriendWebText}
                                linkDescBot={locale.addFriendBotText}
                                botUrl={qrBotUrl}
                                webUrl={qrWebUrl}
                                noMessangerText={locale.friendHaveNoMessangerText}
                                noMessangerModalTitle={locale.friendHaveNoMessangerModalTitle}
                            />

                        </>
                    )
                    : (
                        <Box sx={{mt: 3}}>
                            <TgLink className={'cursor-pointer'} onClick={() => setShowAuthorisationModal(true)}>
                                {profile.needAuthForReferral}
                            </TgLink>
                        </Box>
                    )
                }
            </>
        </ThemeModal>
    )
}

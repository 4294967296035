


export default function BlurFilter() {
    return (
        <div className={'d-none'}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="0">
                <defs>
                    <filter id={"blur-filter"}>
                        <feGaussianBlur stdDeviation={"3"}/>
                    </filter>
                </defs>
            </svg>
        </div>
    )
}

import {CreateOrderPayload} from "../../../../../api/shop/order/types";
import {SetMergeState} from "../../../../../helpers/useMergedState";
import {useShopContext} from "../../../../context";
import {Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import useLocale from "../../../../../hooks/localisation/useLocale";
import {SelectedShipmentServiceType} from "../../useSelectedShipmentService";
import AppLocales from "../../../../../AppLocales";
import {IAddressValid} from "../../../../../features/AddressInput/AddressInput";
import {IZonePolygon, IZoneRadius} from "../../../../services/useShipmentPrices";
import {ReactNode} from "react";


interface IBaseShippingFormProps {
    form: CreateOrderPayload
    setForm: SetMergeState<CreateOrderPayload>

    selectedShipmentService: SelectedShipmentServiceType

    showInvalidError?: boolean
    computedSelectedShipmentHasZones: boolean

    polygons?: IZonePolygon[]
    radius?: IZoneRadius[]
    showMap: boolean
    setShowMap: (value: boolean) => void

    setValidAddress?: (value: IAddressValid | null) => void
    children: ReactNode
}

export default function BaseShippingForm(props: IBaseShippingFormProps){
    const {brandInfo} = useShopContext()
    const locale = useLocale('orders')

    const handleAddressStreetChanged = (e: any) =>
        props.setForm({address_street: e.target.value})
    const handleAddressHouseChanged = (e: any) =>
        props.setForm({address_house: e.target.value})
    const handleAddressFlatChanged = (e: any) =>
        props.setForm({address_flat: e.target.value})
    const handleAddressFloorChanged = (e: any) =>
        props.setForm({address_floor: e.target.value})
    const handleAddressEntranceChanged = (e: any) =>
        props.setForm({address_entrance: e.target.value})

    const {selectedShipment} = props.selectedShipmentService
    if (selectedShipment?.custom_type !== 'shipment' || selectedShipment?.base_type !== 'delivery') {
        return null
    }

    const isError = (key: keyof CreateOrderPayload) => {
        return props.showInvalidError && !props.form[key]
    }

    const getError = (key: keyof CreateOrderPayload, helperTextKey: keyof (typeof AppLocales)['orders']) => {
        if (isError(key)) {
            return {
                error: true,
                helperText: locale[helperTextKey]
            }
        } else {
            return {
                error: false,
                helperText: undefined,
            }
        }
    }

    return (
        <Grid container spacing={2}>
            {brandInfo.is_get_order && (<>
                <Grid item xs={12}>
                    <TextField
                        required
                        size={'small'} fullWidth
                        id={'address_street'} type={'text'}
                        label={locale.streetLabel}
                        {...getError('address_street', 'streetFeedback')}
                        value={props.form.address_street ? props.form.address_street : ''}
                        onChange={(e) => handleAddressStreetChanged(e)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        required
                        size={'small'} fullWidth
                        id={'address_house'} type={'text'}
                        label={locale.houseLabel}
                        {...getError('address_house', 'houseFeedback')}
                        value={props.form.address_house ? props.form.address_house : ''}
                        onChange={(e) => handleAddressHouseChanged(e)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        required
                        size={'small'} fullWidth
                        id={'address_flat'} type={'text'}
                        label={locale.flatLabel}
                        {...getError('address_flat', 'flatFeedback')}
                        value={props.form.address_flat ? props.form.address_flat : ''}
                        onChange={(e) => handleAddressFlatChanged(e)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        required
                        size={'small'} fullWidth
                        id={'address_floor'} type={'number'}
                        label={locale.floorLabel}
                        {...getError('address_floor', 'floorFeedback')}
                        value={props.form.address_floor ? props.form.address_floor : ''}
                        onChange={(e) => handleAddressFloorChanged(e)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        required
                        size={'small'} fullWidth
                        id={'address_entrance'} type={'number'}
                        label={locale.entranceLabel}
                        {...getError('address_entrance', 'entranceFeedback')}
                        value={props.form.address_entrance ? props.form.address_entrance : ''}
                        onChange={handleAddressEntranceChanged}
                    />
                </Grid>
            </> )}
        </Grid>
    )
}

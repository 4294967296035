import { ThemeModal } from "../../../../../helpers/ThemeComponents";
import { IncustPayAccountData, IncustPayModalProps } from "../types";
import useUnifiedSpecialAccountData from "../useUnifiedSpecialAccountData";
import f from "../../../../../helpers/formatText";
import useAppContext from "../../../../../useAppContext";
import { useEffect, useState } from "react";
import IncustPayAccount from "../IncustPayAccount";
import useIncustPayDataService from "../useIncustPayDataService";
import { incustPayRedirect } from "../helpers";

export default function IncustPayModal({
	amountToPay,
	modalData: propsModalData,
	setModalData,
	...props
}: IncustPayModalProps) {
	const { localisation } = useAppContext();

	const [lastModalData, setLastModalData] = useState<IncustPayAccountData | null>(propsModalData);

	const modalData = propsModalData || lastModalData;

	useEffect(() => {
		if (propsModalData) {
			setLastModalData(propsModalData);
		}
	}, [propsModalData]);

	const specialAccountData = useUnifiedSpecialAccountData(modalData);

	const incustPayDataService = useIncustPayDataService();

	return (
		<ThemeModal
			maxWidth={"xs"}
			open={!!propsModalData}
			onClose={() => {
				if (incustPayDataService.isPayed) {
					incustPayRedirect(props.type, {
						selectedStoreId: props.selectedStoreId,
						orderId: props.orderId,
						orderToken: props.orderToken,
						invoiceToken: props.invoiceToken,
					});
				} else {
					setModalData(null);
				}
			}}
			title={
				props.orderToken || props.invoiceToken
					? f(localisation.payment.incustPayModalTitle, {
							account_title: specialAccountData?.title,
						})
					: specialAccountData?.title
			}
			contentProps={{
				sx: {
					p: 0,
				},
			}}
		>
			{modalData && (
				<IncustPayAccount
					accountData={modalData}
					amountToPay={amountToPay}
					incustPayDataService={incustPayDataService}
					onClose={() => setModalData(null)}
					id_type={"user"}
					{...props}
				/>
			)}
		</ThemeModal>
	);
}

import React, {useCallback, useState} from "react";

import {Box} from "@mui/material";
import QrMenuButtonWrapper from "../../buttons/QrMenuButtonWrapper";
import PaymentIcon from '@mui/icons-material/Payment';


interface ICardButtonProps {
    cardButton: string | null
    bonusesAmount: number
    isLoading: boolean
    sendPaymentMessage: (skip: boolean) => Promise<void>
}

export default function CardButton(props: ICardButtonProps){
    const [isInternalLoading, setIsInternalLoading] = useState(false)

    const {
        sendPaymentMessage,
    } = props

    const handleCardClick = useCallback(async() => {
        if (props.isLoading) return
        try {
            setIsInternalLoading(true)
            await sendPaymentMessage(false)
        } finally {
            setIsInternalLoading(false)
        }
    }, [sendPaymentMessage, props.isLoading])

    if (!props.cardButton) return null

    return (
         <QrMenuButtonWrapper
            onClick={() => handleCardClick()}
            loading={isInternalLoading}
            loadingPosition={'end'}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
            >
                <PaymentIcon/>
                {props.cardButton}
            </Box>
        </QrMenuButtonWrapper>
    )
}

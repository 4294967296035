import useAppContext from "../../../../../useAppContext";
import { Button, Theme, useMediaQuery } from "@mui/material";
import { Wallet } from "@mui/icons-material";

export function CheckModifierContent(props: { handleWallet: () => void }) {
	const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

	const {
		localisation: { profile },
	} = useAppContext();

	return (
		<Button
			variant={"outlined"}
			size="small"
			color={isTablet ? "inherit" : "primary"}
			endIcon={<Wallet />}
			onClick={props.handleWallet}
		>
			{profile.showInWalletButton}
		</Button>
	);
}

import {MutableRefObject, useEffect} from "react";


export default function useOutsideClickHandler(
    ref: MutableRefObject<HTMLElement | null>,
    callback: (event: MouseEvent) => any,
    enabled: boolean = true,
    deps: Array<any> = [],
){
    useEffect(() => {
        const outsideClickHandler = (event: MouseEvent) => {
            // @ts-ignore
            if (ref?.current && !ref.current.contains(event.target)) {
                // @ts-ignore
                console.log('handled click', ref.current.contains(event.target))
                callback(event)
            }
        }

        if (enabled) {
            document.addEventListener('click', outsideClickHandler)
        }

        return () => {
            document.removeEventListener('click', outsideClickHandler)
        }
    }, [ref, enabled, ...deps])
}

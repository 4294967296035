import {AuthorisationComponentProps} from "../types";
import FormError from "../FormError";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import {ChangeEvent} from "react";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import {Visibility, VisibilityOff } from "@mui/icons-material";
import {TgLink} from "../../../helpers/ThemeComponents";


export default function PasswordInput(props: AuthorisationComponentProps) {
    if (
        props.form.state !== 'emailLogin' &&
        props.form.state !== 'emailRegistration'
    ) {
        throw new Error('PasswordInput is supported only for emailLogin and emailRegistration')
    }

    const localisation = useLocalisation()

    const onPasswordInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        props.form.resetErrors()
        props.form.input('password', e.target.value)
    }

    return (
        <div className={'w-100 position-relative mt-2'}>
            <TextField
                fullWidth
                size={'small'}
                required={true}
                name={'password'}
                type={props.form.isPasswordVisible ? 'text' : 'password'}
                value={props.form.password}
                label={props.form.state === 'emailLogin'
                    ? localisation.auth.loginPasswordLabel
                    : localisation.auth.registerPasswordLabel
                }
                onChange={onPasswordInputChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={"end"}>
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => props.form.togglePasswordVisible()}
                                onMouseDown={(event) => event.preventDefault()}
                                edge={"end"}
                            >
                                {props.form.isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                      </InputAdornment>
                    )
                }}
            />

            {props.form.state === 'emailLogin' && (
                <div className={'form-text text-end'}>
                    <TgLink onClick={(e) => {
                        e.preventDefault()
                        props.form.startResetPassword()
                    }}>
                        {localisation.auth.forgotPass}
                    </TgLink>
                </div>
            )}

            <FormError errText={props.form.errors.password}/>
        </div>
    )
}

import {Fab, Tooltip} from "@mui/material";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import {IScanReceiptBase} from "../types";
import useAppContext from "../../../useAppContext";

interface IScanReceiptButton extends IScanReceiptBase {
    orderId?: number | null
}

export default function ScanReceiptButton(props: IScanReceiptButton) {
    const {localisation: {receipt}} = useAppContext()

    return (
        <>
            {props.orderId ? (
                <></>
            ) : (
                <Tooltip title={receipt.scanButton} enterTouchDelay={2000}>
                    <Fab
                        color={'secondary'}
                        size="medium"
                        aria-label={receipt.scanButton}
                        onClick={() => props.service.setShowModal(true)}
                    >
                        <QrCodeScannerIcon />
                    </Fab>
                </Tooltip>
            )}
        </>
    )
}

import useAppContext from "../useAppContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Collapse, CollapseProps, Link, LinkProps } from "@mui/material";
import { SetState } from "../types";

export interface CollapsableComponentProps extends Omit<CollapseProps, "in" | "collapsedSize"> {
	children?: any;
	collapsedSize?: number | null;
	linesCount?: number | null;
	overflowLinesCount?: number | null; // allow maximum lines overflow
	collapsedFitContent?: boolean;
	log?: boolean;
	hideShowButton?: boolean;
	linkProps?: Partial<Omit<LinkProps<"button">, "onClick" | "component">>;
	onOpen?: (textContainer: HTMLDivElement) => unknown;
	onHide?: (textContainer: HTMLDivElement) => unknown;
	onOpenFinish?: (textContainer: HTMLDivElement) => unknown; // when animation finished
	onCloseFinish?: (textContainer: HTMLDivElement) => unknown; // when animation finished

	open?: boolean;
	setOpen?: SetState<boolean>;

	onNeedExpandChange?: (needExpand: boolean) => unknown;

	showHiddenOverlay?: boolean;
	hiddenOverlayColorChannelVar?: string;
}

export default function CollapsableComponent({
	collapsedSize: propsCollapsedSize = null,
	linesCount = null,
	overflowLinesCount,
	collapsedFitContent = true,
	children,
	log,
	hideShowButton,
	linkProps,
	onOpen,
	onHide,
	onTransitionEnd,
	onOpenFinish,
	onCloseFinish,
	open: propsOpen,
	setOpen: propsSetOpen,
	onNeedExpandChange,
	showHiddenOverlay,
	hiddenOverlayColorChannelVar = "--mui-palette-background-defaultChannel",
	...props
}: CollapsableComponentProps) {
	const { localisation } = useAppContext();

	const textContainerRef = useRef<HTMLDivElement | null>(null);

	const [internalOpen, setInternalOpen] = useState(false);
	const [contentHeight, setContentHeight] = useState(0);
	const [contentLineHeight, setContentLineHeight] = useState<number | null>(null);

	const open = propsOpen !== undefined ? propsOpen : internalOpen;
	const setOpen = propsSetOpen !== undefined ? propsSetOpen : setInternalOpen;

	useEffect(() => {
		const textContainer = textContainerRef.current;
		if (!textContainer) return;

		const calculate = () => {
			if (textContainer) {
				const heights = [textContainer.getBoundingClientRect().height];
				const lineHeights: number[] = [];

				for (let i = 0; i < textContainer.children.length; i++) {
					const child = textContainer.children[i];
					if (child.classList.contains("collapse-children-ignore")) {
						continue;
					}

					heights.push(child.getBoundingClientRect().height);

					const lineHeight = parseFloat(
						getComputedStyle(child).lineHeight.replace("px", "")
					);
					if (!lineHeights.includes(lineHeight)) {
						lineHeights.push(lineHeight);
					}
				}

				setContentHeight(Math.max(...heights));

				if (lineHeights.length === 1) {
					setContentLineHeight(lineHeights[0]);
				} else {
					setContentLineHeight(null);
				}
			}
		};

		calculate();
		const resizeObserver = new ResizeObserver(() => {
			calculate();
		});
		resizeObserver.observe(textContainer);

		return () => {
			resizeObserver.disconnect();
		};
	}, [children]);

	const collapsedSize = useMemo(() => {
		if (propsCollapsedSize) {
			return propsCollapsedSize;
		}
		if (!linesCount || !contentLineHeight) {
			return 0;
		}

		const collapsedSizeByLines = linesCount * contentLineHeight;

		if (overflowLinesCount) {
			const collapsedSizeWithOverflow = (linesCount + overflowLinesCount) * contentLineHeight;
			if (collapsedSizeWithOverflow >= contentHeight) {
				return collapsedSizeWithOverflow;
			}
		}
		return collapsedSizeByLines;
	}, [propsCollapsedSize, linesCount, contentLineHeight, overflowLinesCount, contentHeight]);

	const needExpand =
		(propsCollapsedSize !== null || linesCount !== null) && contentHeight > collapsedSize;
	const calculatedCollapsedSize = useMemo(() => {
		if (propsCollapsedSize === null && linesCount === null) return 0;

		if (collapsedFitContent && contentHeight && contentHeight < collapsedSize) {
			return contentHeight;
		}

		return collapsedSize;
	}, [propsCollapsedSize, linesCount, collapsedFitContent, contentHeight, collapsedSize]);

	const calculatedOpen = open || (propsCollapsedSize === null && linesCount === null);

	useEffect(() => {
		setOpen(calculatedOpen);
	}, [calculatedOpen, setOpen]);

	useEffect(() => {
		onNeedExpandChange && onNeedExpandChange(needExpand);
	}, [needExpand, onNeedExpandChange]);

	return (
		<>
			<Collapse
				in={calculatedOpen}
				collapsedSize={calculatedCollapsedSize}
				onTransitionEnd={event => {
					onTransitionEnd && onTransitionEnd(event);

					const textContainer = textContainerRef.current;

					if (textContainer) {
						if (calculatedOpen) {
							onOpenFinish && onOpenFinish(textContainer);
						} else {
							onCloseFinish && onCloseFinish(textContainer);
						}
					}
				}}
				{...props}
			>
				<Box sx={{ height: "fit-content", position: "relative" }} ref={textContainerRef}>
					<Box
						className={"collapse-children-ignore"}
						sx={{
							position: "absolute",
							left: 0,
							right: 0,
							top: 1,
							height: collapsedSize,
							pointerEvents: "none",
							opacity: showHiddenOverlay && !calculatedOpen && needExpand ? 1 : 0,
							transition: "opacity .3s",
							backgroundImage:
								`linear-gradient(180deg, ` +
								`rgba(var(${hiddenOverlayColorChannelVar}) / 0) ` +
								`${100 - ((contentLineHeight || 0) * 2 * 100) / collapsedSize}%,` +
								`rgba(var(${hiddenOverlayColorChannelVar}) / 0.25) ` +
								`${100 - ((contentLineHeight || 0) * 100) / collapsedSize}%, ` +
								`rgba(var(${hiddenOverlayColorChannelVar}) / 1) 100%)`,
						}}
					/>
					{children}
				</Box>
			</Collapse>
			{needExpand && !hideShowButton && (
				<Link
					component={"button"}
					textAlign={"right"}
					fontSize={"inherit"}
					fontWeight={"bold"}
					onClick={e => {
						e.stopPropagation();
						setOpen(prevState => {
							const textContainer = textContainerRef.current;
							if (textContainer) {
								if (prevState) {
									onHide && onHide(textContainer);
								} else {
									onOpen && onOpen(textContainer);
								}
							}
							return !prevState;
						});
					}}
					{...linkProps}
				>
					{open ? localisation.global.hideButton : localisation.global.showMoreButton}
				</Link>
			)}
		</>
	);
}

import OrdersList from "../../order/OrderList/OrdersList";
import ProfileLayout from "../ProfileLayout";
import { ProfileTab } from "../types";
import { useSelectedStoreOptional } from "../../SelectedStore/context";
import { useShopContext } from "../../context";
import useAppContext from "../../../useAppContext";
import changePage from "../../../helpers/changePage";
import api from "../../../api";
import { TgButton } from "../../../helpers/ThemeComponents";

export default function ProfileOrders() {
	const tab = ProfileTab.orders;
	const selectedStore = useSelectedStoreOptional();
	const { isSingleShop } = useShopContext();
	const { localisation } = useAppContext();

	const backHome = () => {
		const storeId = selectedStore?.id;
		if (isSingleShop && storeId) {
			changePage(`/shop/${storeId}/menu`);
		} else {
			api.shop.basic.removeLastStoreId();
			changePage("/shop/select");
		}
	};

	return (
		<ProfileLayout tab={tab}>
			{!!selectedStore ? (
				<OrdersList />
			) : (
				<div className={"text-center w-100"}>
					<div className={"mb-2"}>{localisation.orders.orderNotFound}</div>
					<TgButton onClick={backHome}>{localisation.global.chooseStore}</TgButton>
				</div>
			)}
		</ProfileLayout>
	);
}

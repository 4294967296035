import { BotType } from "../../api/bots/types";
import useAppContext from "../../useAppContext";

export default function useUserMessangerIdString(externalLoginType?: BotType) {
	const {
		authService: { user },
	} = useAppContext();

	switch (externalLoginType) {
		case "telegram":
			return user?.username ? `@${user.username}` : "";
		case "whatsapp":
			return user?.wa_phone ? `+${user.wa_phone}` : "";
		default:
			return "";
	}
}

import { TgLink } from "../../../../helpers/ThemeComponents";
import useLocalisation from "../../../../hooks/localisation/useLocalisation";
import { IIncustCoupon } from "../../types";
import { useMemo } from "react";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

interface ICouponsLinkProps {
	usedCoupons: IIncustCoupon[] | null;
	setIsCouponsOpen: (value: boolean) => void;
}

export default function CouponsLink(props: ICouponsLinkProps) {
	const localisation = useLocalisation();

	const { customerData } = useUserIncustCustomerData();

	const userCouponsTotalCount = useMemo(() => {
		const coupons = customerData?.user_card?.coupons?.filter((coupon: IIncustCoupon) => {
			if (coupon && coupon.batch) {
				if (coupon.batch.type && coupon.batch.type === "check-modifier") return !!coupon;
			}
			return false;
		});

		let cnt = 0;
		if (coupons) cnt += coupons.length;

		return cnt;
	}, [customerData?.user_card?.coupons]);

	if (!userCouponsTotalCount) return <></>;

	return (
		<TgLink className={"cursor-pointer"} onClick={() => props.setIsCouponsOpen(true)}>
			{localisation.profile.loyaltyCoupons} {props.usedCoupons ? props.usedCoupons.length : 0}
			/{userCouponsTotalCount}
		</TgLink>
	);
}

import CollapsableComponent from "../../../../../features/CollapsableComponent";
import { Theme, useMediaQuery } from "@mui/material";
import Interweave from "../../../../../features/Interweave";
import { IIncustCoupon } from "../../../types";
import { SetState } from "../../../../../types";

export interface BaseCouponDescProps {
	insideCoupon: boolean;
	coupon: IIncustCoupon;
	hideShowButton?: boolean;
	setNeedExpand?: SetState<boolean>;
}

export default function BaseCouponDesc(props: BaseCouponDescProps) {
	const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

	const text = (
		<Interweave
			noWrap
			content={props.coupon.description}
			defaultLinkColor={isTablet ? "primary.contrastText" : "primary"}
		/>
	);

	return (
		<>
			{props.insideCoupon ? (
				text
			) : (
				<CollapsableComponent
					onNeedExpandChange={props.setNeedExpand}
					linesCount={4}
					overflowLinesCount={2}
					hideShowButton={props.hideShowButton}
					linkProps={{ color: isTablet ? "inherit" : "primary" }}
					showHiddenOverlay
					hiddenOverlayColorChannelVar={
						isTablet
							? "--mui-palette-primary-mainChannel"
							: "--mui-palette-background-defaultChannel"
					}
				>
					{text}
				</CollapsableComponent>
			)}
		</>
	);
}

import { ViewType } from "../../../services/useViewService";
import { Box, Skeleton, Typography } from "@mui/material";
import RatioBox from "../../../features/RatioBox";
import { useShopContext } from "../../context";
import { DefaultImageAspectRatio } from "../../../config";
import React from "react";
import useAppContext from "../../../useAppContext";

interface ProductCardSkeletonProps {
	view: ViewType;
}

export default function ProductCardSkeleton({ view }: ProductCardSkeletonProps) {
	if (view === "grid") {
		return <GridProductCardSkeleton />;
	}
	return <ListProductCardSkeleton />;
}

function GridProductCardSkeleton() {
	const { appearance } = useAppContext();

	const ratio =
		appearance.appearanceState?.product_image_aspect_ratio_converted ||
		appearance.appearanceQuery?.product_image_aspect_ratio_converted ||
		DefaultImageAspectRatio;

	return (
		<Box
			sx={{
				borderRadius: ".5rem",
				height: "100%",
				boxShadow: 2,
				"&:hover": {
					boxShadow: 5,
				},
				overflow: "hidden",
				backgroundImage: "var(--mui-overlays-1)",
			}}
		>
			<RatioBox ratio={ratio}>
				<Skeleton width={"100%"} height={"100%"} variant={"rectangular"} />
			</RatioBox>

			<Box px={2} pb={2}>
				<Typography
					variant={"h6"}
					lineHeight={"normal"}
					fontWeight={"bold"}
					display={"flex"}
					alignItems={"center"}
					mt={2}
					sx={{
						wordBreak: "break-word",
					}}
				>
					<Skeleton sx={{ flex: 1 }} />

					<Skeleton sx={{ ml: 2 }} variant={"circular"} width={20} height={20} />
				</Typography>

				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					flexWrap={"wrap"}
					mt={{ xs: 2, sm: 4 }}
					gap={2}
					mb={0}
				>
					<Typography
						sx={{
							fontWeight: "bold",
						}}
					>
						<Skeleton width={"50px"} />
					</Typography>

					<CartButtonSkeletons />
				</Box>
			</Box>
		</Box>
	);
}

function ListProductCardSkeleton() {
	const { appearance } = useAppContext();

	const ratio =
		appearance.appearanceState?.product_image_aspect_ratio_converted ||
		appearance.appearanceQuery?.product_image_aspect_ratio_converted ||
		DefaultImageAspectRatio;

	return (
		<Box
			px={1}
			pt={2}
			pb={3}
			my={2}
			width={"100%"}
			className={"shop-product-card m-0 border-top cursor-pointer"}
			sx={{ backgroundImage: "var(--mui-overlays-1)" }}
		>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box flex={1} pr={2}>
					<Typography
						variant={"h6"}
						lineHeight={"normal"}
						fontWeight={"bold"}
						mb={1}
						sx={{
							wordBreak: "break-word",
						}}
						width={"50%"}
					>
						<Skeleton />
					</Typography>

					<Typography
						sx={{
							fontWeight: "bold",
						}}
					>
						<Skeleton width={"50px"} />
					</Typography>

					<Typography>
						<Skeleton />
					</Typography>
				</Box>
				<Box
					className={"media"}
					sx={{
						width: { xs: "35%", sm: "30%", lg: "25%" },
						pt: 1,
						pl: 2,
						pb: 1,
						float: "right",
					}}
				>
					<RatioBox ratio={ratio}>
						<Skeleton
							width={"100%"}
							height={"100%"}
							variant={"rectangular"}
							sx={{
								borderRadius: "5px",
							}}
						/>
					</RatioBox>
				</Box>
			</Box>

			<Box
				display={"flex"}
				gap={{ xs: 1, sm: 2 }}
				alignItems={"center"}
				mt={2}
				flexWrap={"wrap"}
			>
				<Skeleton variant={"circular"} width={35} height={35} />

				<CartButtonSkeletons />
			</Box>
		</Box>
	);
}

function CartButtonSkeletons() {
	return (
		<Box gap={2} display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
			<Skeleton variant={"circular"} width={35} height={35} />

			<Skeleton
				variant={"rectangular"}
				width={100}
				height={40}
				sx={{
					borderRadius: 1,
				}}
			/>
		</Box>
	);
}

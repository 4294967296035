import {
	getDayNames,
	isFirstDayMonday,
	timeToInt,
	weekdaySunday,
	weekdayMonday,
} from "../../helpers";
import { WorkingTimes } from "../types";
import { WorkingDay, WorkingSlot } from "../../api/shop/basic/types";

export const loadWorkingTimes = (
	workingDays: WorkingDay[] | null = null,
	lang: string | null = null
): WorkingTimes | null => {
	if (!workingDays || workingDays.length === 0) return null;

	let localeLang = lang;
	if (!localeLang) localeLang = "uk";

	const firstDay = isFirstDayMonday(localeLang) ? "monday" : "sunday";
	const res: { day: string; time: string }[] = [];

	const currentDate = new Date();
	let currentDay = currentDate.getDay();
	currentDay = isFirstDayMonday(localeLang) ? currentDay - 1 : currentDay;
	if (currentDay === -1) {
		currentDay = 6;
	}

	const localisedDays = getDayNames(localeLang, "short", firstDay);

	let weekdayList = weekdaySunday;
	if (firstDay === "monday") {
		weekdayList = weekdayMonday;
	}

	let currentTimes = "";
	let currentWorkingDay = null;

	weekdayList.forEach((day, index) => {
		let workingDay = workingDays.find(x => x.day.toLowerCase() === day.toLowerCase());
		if ((!workingDay || !workingDay.slots || workingDay.slots.length < 1) && !workingDay?.is_weekend) {
			workingDay = workingDays.find(x => x.day.toLowerCase() === "week");
		}
		if (workingDay) {
			let timesStr = "";
			if (workingDay.slots && !workingDay.is_weekend) {
				for (let j = 0; j < workingDay.slots.length; ++j) {
					const slot = workingDay.slots[j];
					timesStr += `${slot.start_time_text}-${slot.end_time_text}`;
					if (j !== workingDay.slots.length - 1) timesStr += ", ";
				}
			}
			if (workingDay.is_weekend) {
				timesStr = "pass";
			}

			if (day.toLowerCase() === weekdayList[currentDay].toLowerCase()) {
				currentTimes = `${localisedDays[index]} ${timesStr ? timesStr : "00:00-24:00"}`;
				currentWorkingDay = workingDay;
			}

			res.push({
				day: localisedDays[index],
				time: timesStr ? timesStr : "00:00-24:00",
			});
		}
	});

	return {
		current: currentTimes,
		currentWorkingDay: currentWorkingDay,
		allWorkingDays: workingDays,
		all: res,
	};
};

export const isShopClosed = (workingDay: WorkingDay | null, date: Date | null = null, timezone: string | null = null): string => {
	if (!workingDay) return "";

	if (workingDay.is_weekend) return "pass";

	let currentDate = new Date();

	if (date) {
		currentDate = date;
	} 
	else {
		if(timezone){
			const options = { timeZone: timezone };
			const groupTime = currentDate.toLocaleString('en-US', options);
			currentDate = new Date(groupTime)
		}
	}

	const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();

	let willOpenString = "";
	let isWorking = false;
	if (workingDay?.slots) {
		workingDay.slots.forEach((slot: WorkingSlot) => {
			const openTime = timeToInt(slot.start_time_text);
			const closeTime = timeToInt(slot.end_time_text);

			if (openTime <= closeTime) {
				if (currentTime >= openTime && currentTime < closeTime) {
					isWorking = true;
				} else {
					willOpenString += `${slot.start_time_text}-${slot.end_time_text}, `;
				}
			} else {
				// Handle time ranges that span midnight
				if (currentTime >= openTime || currentTime < closeTime) {
					isWorking = true;
				} else {
					willOpenString += `${slot.start_time_text}-${slot.end_time_text}, `;
				}
			}
		});
	}

	return isWorking ? "" : willOpenString.substring(0, willOpenString.length - 2);
};

import { useState } from "react";
import classnames from "classnames";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

import Sort from "./Sort";
import FiltersModal from "./FiltersModal";
import ViewToggle from "./ViewToggle";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import StoreSearchInput from "../StoreSearchInput";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import useScreenService from "../../../services/useScreenService";
import { IUseFiltersBarSettings } from "../hooks/useFiltersBarSettings";
import useAppContext from "../../../useAppContext";

interface ShopFiltersProps {
	filtersSettings: IUseFiltersBarSettings;
	disabledFilters?: boolean;
	disabledSort?: boolean;
	isFavorites?: boolean;
}

export default function ShopFilters({
	filtersSettings,
	disabledFilters = false,
	disabledSort = false,
	isFavorites = false,
}: ShopFiltersProps) {
	const { appearance } = useAppContext();
	const { productsService, selectedStore, filtersService } = useSelectedStoreContext();
	const { isMobile } = useScreenService();

	const [showFiltersModal, setShowFiltersModal] = useState(false);
	const localisation = useLocalisation();

	return (
		<>
			<div
				className={classnames(
					"w-100 d-flex",
					"align-items-center",
					"user-select-none fs-6 ps-2 pe-2",
					`${
						!appearance.computedNeedFilterByPrice &&
						!appearance.computedNeedFilterSearch &&
						!appearance.computedNeedFilterSort &&
						isMobile
							? ""
							: "input-bottom-border-container"
					}`,
					"w-100",
					`${!appearance.computedNeedFilterSearch ? " justify-content-end" : ""}`
				)}
			>
				<StoreSearchInput isFavorites={isFavorites} />

				{filtersSettings.needFiltersButton && !disabledFilters && (
					<div className={"me-1 me-sm-2"}>
						<span
							className={"primary-color cursor-pointer"}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								setShowFiltersModal(true);
							}}
						>
							<StyledFiltersBadge
								badgeContent={filtersService.computedFiltersCount}
								color="primary"
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
							>
								{localisation.menu.filtersButton}
							</StyledFiltersBadge>
						</span>
					</div>
				)}

				{!disabledSort && !productsService.search && <Sort />}

				{filtersSettings.viewService.toggleEnabled && (
					<div
						className={classnames("py-1", {
							"ms-2": !productsService.search,
						})}
					>
						<ViewToggle viewService={filtersSettings.viewService} />
					</div>
				)}
			</div>

			<FiltersModal show={showFiltersModal} setShow={setShowFiltersModal} />
		</>
	);
}

const StyledFiltersBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: -1,
		top: 3,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: "0 4px",
	},
}));

import QuantityInput from "../ProductQty/QuantityInput";
import useProductQuantity from "../../../services/useCartService/hooks/useProductQuantity";
import { Product } from "../../../../api/shop/basic/types";
import { CartProduct } from "../../../../api/shop/cart/types";
import { SetState } from "../../../../types";
import { useCallback, useEffect, useRef, useState } from "react";
import { SxProps } from "@mui/system";
import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import useAppContext from "../../../../useAppContext";

interface IProductFloatingQty {
	cartProduct: CartProduct | null;
	productQty: number;
	setProductQty: SetState<number>;
	product: Product | null;
	sx?: SxProps;
}

export default function ProductFloatingQty(props: IProductFloatingQty) {
	const {
		localisation: { menu },
	} = useAppContext();

	const { computedQuantity, updateQty } = useProductQuantity(
		props.cartProduct,
		false,
		props.productQty,
		props.product,
		props.setProductQty,
		false,
		props.product
	);

	const [localQty, setLocalQty] = useState<number | null>(null);

	const rootRef = useRef<HTMLDivElement>(null);

	const onChange = useCallback(
		(qty: number) => {
			if (props.product && qty && qty < props.product?.buy_min_quantity) {
				qty = props.product?.buy_min_quantity || 1;
			}
			if (Number.isNaN(qty)) {
				qty = props.product?.buy_min_quantity || 1;
			}
			updateQty(qty);
			setLocalQty(qty);
		},
		[props.product, updateQty]
	);

	useEffect(() => {
		if (rootRef.current) {
			rootRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<Box ref={rootRef}>
			<Divider />
			<Typography sx={{ px: "20px", my: 2 }} fontWeight={"bold"}>
				{menu.productQtyHeader}
			</Typography>
			<QuantityInput
				defaultValue={
					computedQuantity ? computedQuantity : props.product?.buy_min_quantity || 1
				}
				onChange={onChange}
				sx={{ width: "100%", ...props.sx }}
				size={"medium"}
				needEndAdornment={false}
				placeholder={menu.productQtyPlaceholder}
				textFieldProps={{
					defaultValue: localQty
						? localQty
						: computedQuantity
							? computedQuantity
							: props.product?.buy_min_quantity || 1,
				}}
			/>
		</Box>
	);
}

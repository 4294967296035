import React from "react";
import { Box } from "@mui/material";
import { GalleryImage } from "./types";
import { isIOS } from "../../../../helpers";
import useAppContext from "../../../../useAppContext";
import { ApiUrl, availableNoImageLanguages } from "../../../../config";

interface ProductGalleryItemProps {
	item: GalleryImage;
	index: number;
	productName: string;
	containerWidth: number;
	onLoad: () => void;
	onLoadedMetadata: () => void;
}

export const ProductGalleryItem = React.memo((props: ProductGalleryItemProps) => {
	const { item, productName, index, containerWidth, onLoad, onLoadedMetadata } = props;

	const { lang } = useAppContext();
	const videoRef = React.useRef<HTMLVideoElement | null>(null);

	const computedProductImageStub = React.useMemo(() => {
		if (availableNoImageLanguages.includes(lang ?? ""))
			return `${ApiUrl}/static/images/store/no-image-${lang}.png`;
		return `${ApiUrl}/static/images/store/no-image.png`;
	}, [lang]);

	React.useEffect(() => {
		const videoElement = videoRef.current;
		if (videoElement) {
			const handleVolumeChange = () => {
				if (videoElement.muted) {
					localStorage.removeItem("videoSound");
				} else {
					localStorage.setItem("videoSound", "1");
				}
			};

			videoElement.addEventListener("volumechange", handleVolumeChange);

			return () => {
				videoElement.removeEventListener("volumechange", handleVolumeChange);
			};
		}
	}, []);

	if (!["image", "video"].includes(item.media_type)) {
		return null;
	}

	const isiOSDevice = isIOS();

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				maxHeight: "calc(60vh - 40px)",
				minHeight: { xs: "150px", sm: "200px" },
				overflow: "hidden",
				...(isiOSDevice && item?.media_type === "video" && { pt: "58px" }),
			}}
		>
			{!item?.media_url || item?.media_type === "image" ? (
				<img
					onLoad={onLoad}
					onLoadedMetadata={onLoadedMetadata}
					loading={"eager"}
					src={item?.media_url || computedProductImageStub}
					alt={productName}
					style={{
						objectFit: "contain",
						minWidth: "100%",
					}}
				/>
			) : (
				<video
					onLoad={onLoad}
					onLoadedMetadata={onLoadedMetadata}
					id={`video${index || index === 0 ? index : item?.media_url}`}
					ref={videoRef}
					controls
					autoPlay
					muted={!(localStorage.getItem("videoSound") && index === 0)}
					loop
					playsInline
					src={item.media_url}
					style={{
						maxWidth: `${containerWidth - 20}px`,
					}}
				/>
			)}
		</Box>
	);
});

import { useEffect, useMemo } from "react";
import "../styles.scss";
import AuthMethods from "./AuthMethods";
import EmailLogin from "./email/EmailLogin";
import EmailRegistration from "./email/EmailRegistration";
import { AuthorisationComponentProps } from "./types";
import ExternalLogin from "./external/login/ExternalLogin";
import ResetPassword from "./ResetPassword";
import { Box } from "@mui/material";
import OAuthConsent from "./external/oauth/OAuthConsent/OAuthConsent";
import { useSetRecoilState } from "recoil";
import { initialOAuthLoginState, oauthLoginState } from "./external/options/state";
import ExternalLoginOptions from "./external/options";
import useAuthSettings from "../../services/useAuthSettings";
import useAppContext from "../../useAppContext";

export default function Authorisation(props: AuthorisationComponentProps) {
	return (
		<Box textAlign={"center"}>
			<AuthorisationComponent {...props} />
		</Box>
	);
}

function AuthorisationComponent(props: AuthorisationComponentProps) {
	const authorisationForm = props.form;
	const onLoginCallback = props.onLoginCallback;

	const setOAuthLogin = useSetRecoilState(oauthLoginState);

	const { bot, brandInfo } = useAppContext();
	const authSettings = useAuthSettings(brandInfo?.group_id);
	const { startExternalLogin } = props.form;
	useEffect(() => {
		if (authSettings.computedIsOnlyMessanger && bot?.bot_type) {
			startExternalLogin(bot.bot_type);
		}
	}, [authSettings.computedIsOnlyMessanger, bot?.bot_type, startExternalLogin]);

	const computedComponent = useMemo(() => {
		switch (authorisationForm.state) {
			case "authMethods":
				return <AuthMethods form={authorisationForm} />;
			case "emailLogin":
				return <EmailLogin form={authorisationForm} onLoginCallback={onLoginCallback} />;
			case "emailRegistration":
				return (
					<EmailRegistration form={authorisationForm} onLoginCallback={onLoginCallback} />
				);
			case "externalLogin":
				return (
					<ExternalLogin
						form={authorisationForm}
						externalLoginCallback={props.externalLoginCallback}
						externalLoginExtraData={props.externalLoginExtraData}
						externalLoginPurpose={props.externalLoginPurpose}
						customVisible={props.customVisible}
						customSetVisible={props.customSetVisible}
						isOnlyAuthMethod={authSettings.computedIsOnlyMessanger}
					/>
				);
			case "resetPassword":
				return (
					<ResetPassword
						form={authorisationForm}
						resetPasswordBackCallback={props.resetPasswordBackCallback}
					/>
				);
			case "oauthConsent":
				return <OAuthConsent onLoginCallback={onLoginCallback} />;
			default:
				return <></>;
		}
	}, [
		authorisationForm,
		onLoginCallback,
		props.resetPasswordBackCallback,
		props.customVisible,
		authSettings.computedIsOnlyMessanger,
	]);

	useEffect(() => {
		return () => {
			setOAuthLogin(initialOAuthLoginState);
		};
	}, [setOAuthLogin]);

	const needExternalOptions =
		authorisationForm.state === "authMethods" ||
		authorisationForm.state === "emailLogin" ||
		authorisationForm.state === "emailRegistration";

	if (authSettings.isPending) return null;

	return (
		<>
			{computedComponent}
			<Box sx={{ display: needExternalOptions ? "block" : "none" }}>
				<ExternalLoginOptions
					form={props.form}
					disabledMessengerExternalLogin={props.disabledMessengerExternalLogin}
					onLoginCallback={onLoginCallback}
				/>
			</Box>
		</>
	);
}

import { useMemo } from "react";
import { getPrivacyPolicyLink, getTermsOfUseLink } from "../../../helpers/consents";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import useAppContext from "../../../useAppContext";
import { useSelectedStoreOptional } from "../../../shop/SelectedStore/context";

export default function useConsentInfo(isOfferEnabled: boolean) {
	const [searchParams] = useSearchParamsFixed();
	const {
		brandInfo,
		lang,
		authService: { user },
	} = useAppContext();
	const localisation = useLocalisation();
	const selectedStore = useSelectedStoreOptional();

	return useMemo(() => {
		if (!user) {
			if (isOfferEnabled) {
				return localisation.auth.agreementAndOfferInfoUnauthText
					.replace("{terms_of_use_link}", getTermsOfUseLink(brandInfo, lang))
					.replace("{privacy_policy_link}", getPrivacyPolicyLink(lang))
					.replace(
						"{offer_link}",
						`/shop/document/agreement/?${searchParams.toString()}`
					);
			}
			return localisation.auth.agreementInfoUnauthText
				.replace("{terms_of_use_link}", getTermsOfUseLink(brandInfo, lang))
				.replace("{privacy_policy_link}", getPrivacyPolicyLink(lang));
		}

		let marketingLink;
		if (selectedStore) {
			marketingLink = `/shop/${selectedStore.id}/marketing/?${searchParams.toString()}`;
		} else {
			marketingLink = `/shop/marketing/?${searchParams.toString()}`;
		}

		if (isOfferEnabled) {
			return localisation.auth.agreementAndOfferInfoText
				.replace("{terms_of_use_link}", getTermsOfUseLink(brandInfo, lang))
				.replace("{privacy_policy_link}", getPrivacyPolicyLink(lang))
				.replace("{offer_link}", `/shop/document/agreement/?${searchParams.toString()}`)
				.replace("{marketing_link}", marketingLink);
		}
		return localisation.auth.agreementInfoText
			.replace("{terms_of_use_link}", getTermsOfUseLink(brandInfo, lang))
			.replace("{privacy_policy_link}", getPrivacyPolicyLink(lang))
			.replace("{marketing_link}", marketingLink);
	}, [
		brandInfo,
		isOfferEnabled,
		lang,
		localisation.auth.agreementAndOfferInfoText,
		localisation.auth.agreementAndOfferInfoUnauthText,
		localisation.auth.agreementInfoText,
		localisation.auth.agreementInfoUnauthText,
		searchParams,
		selectedStore,
		user,
	]);
}

import { useState } from "react";
import { motion } from "framer-motion";
import { ContentCopy, Done } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, TypographyProps } from "@mui/material";
import useAppContext from "../../../../../useAppContext";

interface CouponCodeProps extends TypographyProps {
	code: string | undefined | null;
	animate?: boolean;
	borderColor?: string;
	isInsideCoupon?: boolean;
}

export function CouponCodeExternal({ code, animate, isInsideCoupon, ...props }: CouponCodeProps) {
	const [isCopied, setIsCopied] = useState<boolean>(false);
	const { toastService, localisation } = useAppContext();

	const handleCopyToClipboard = () => {
		if (!!code) {
			try {
				navigator.clipboard.writeText(code);
				setIsCopied(true);
				setTimeout(() => setIsCopied(false), 5000);
				toastService.showToast({
					severity: "success",
					message: localisation.global.copiedCouponCode,
				});
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		<Box
			component={motion.div}
			initial={false}
			animate={
				animate
					? {
							opacity: 0,
							height: 0,
							transition: { duration: 0.3 },
						}
					: {
							opacity: 1,
							height: "fit-content",
							transition: { duration: 0.3 },
						}
			}
			display={"flex"}
			alignItems={"center"}
			mb={"5px"}
			mx={{ xs: "auto", sm: "unset" }}
			ml={isInsideCoupon ? { xs: "unset" } : { sm: "auto" }}
			mr={isInsideCoupon ? { xs: "auto" } : {}}
			mt={{ xs: 2, sm: "unset" }}
			px={2}
			py={1}
			justifyContent={{ xs: "center", sm: "flex-end" }}
			width={"fit-content"}
			flexDirection={"column"}
			sx={{
				bgcolor: { xs: "primary.main", sm: "primary.contrastText" },
				color: { xs: "primary.contrastText", sm: "primary.main" },
				borderRadius: "5px",
			}}
		>
			<Typography
				fontSize={"x-small"}
				textAlign={{ xs: "center", sm: "end" }}
				ml={{ sm: "auto" }}
				pr={{ sm: "8.5px" }}
			>
				{localisation.profile.loyaltyExtCouponUseCode}
			</Typography>
			<Box display={"flex"} width={"100%"} justifyContent={{ xs: "center", sm: "flex-end" }}>
				<Typography
					fontSize={"large"}
					fontWeight={"700"}
					px={"5px"}
					variant={"body1"}
					color={"inherit"}
					{...props}
				>
					{code}
				</Typography>
				<Tooltip title={localisation.global.copyCouponCode}>
					<IconButton size="small" color="inherit" onClick={handleCopyToClipboard}>
						{isCopied ? (
							<Done sx={{ fontSize: "18px" }} />
						) : (
							<ContentCopy sx={{ fontSize: "18px" }} />
						)}
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	);
}

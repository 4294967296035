import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import {TgLink} from "../helpers/ThemeComponents";
import {BrandInfo} from "../api/shop/basic/types";
import StubImage from "../static/stubs/shop-image-stub.webp";
import useAppContext from "../useAppContext";
import {useEffect} from "react";
import {redirect} from "react-router";

interface IPageFourOFourProps {
    brandInfo?: BrandInfo | null;
    setPageTitle?: (title: string | null) => void | null;
    computedImage?: () => string | null;
    backHome?: () => void | null;
}

export default function NotFoundBase(props: IPageFourOFourProps) {
    const {loadingManager, localisation} = useAppContext();

    const computedImage = () => {
        if (props.computedImage) return props.computedImage();
        return StubImage;
    };

    const setPageTitle = props.setPageTitle;

    useEffect(() => {
        const titleBase = localisation.global.notFoundPageHeader;
        if (setPageTitle) {
            if (props.brandInfo?.name) {
                setPageTitle(`${props.brandInfo.name} - ${titleBase}`);
            } else {
                setPageTitle(titleBase);
            }
        }
    }, [
        localisation.global.notFoundPageHeader,
        props.brandInfo?.name,
        setPageTitle,
    ]);

    if (loadingManager.isSomethingLoading || !localisation.isLoaded)
        return null;

    return (
        <>
            <MDBContainer
                className={"d-flex justify-content-center align-items-center"}
                style={{
                    height: `calc(100vh - ${props.brandInfo ? "81px" : "0px"})`,
                }}
            >
                <MDBRow>
                    <MDBCol md={"6"} className={"text-center my-2"}>
                        <img
                            src={computedImage() || ""}
                            alt="404 Not Found"
                            className={"img-fluid"}
                            style={{width: "20rem", borderRadius: "0.5rem"}}
                        />
                    </MDBCol>
                    <MDBCol
                        md={"6"}
                        className={
                            "d-flex flex-column justify-content-center " +
                            "align-items-center my-2 text-center text-md-start"
                        }
                    >
                        <div className={"caption theme-text"}>
                            <div className={"fw-bold fs-5"}>
                                {localisation.global.notFoundPageMsg}
                            </div>
                            {props.backHome && (
                                <div className={"mt-2"}>
                                    <TgLink
                                        className={"cursor-pointer"}
                                        onClick={() => {
                                            if (props.backHome)
                                                return props.backHome();
                                            redirect("/");
                                        }}
                                    >
                                        {localisation.global.backHome}
                                    </TgLink>
                                </div>
                            )}
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
}

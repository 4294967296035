import { useMemo } from "react";
import { Button, Typography } from "@mui/material";
import useScreenService, { ScreenSize } from "../services/useScreenService";
import MessangerIcon from "./MessangerIcon";
import f from "../helpers/formatText";
import { Bot } from "../api/bots/types";
import useMessangerLink from "../hooks/messanger/useMessangerLink";
import { useMessangerName } from "../hooks/messanger";
import useLocalisation from "../hooks/localisation/useLocalisation";

interface IMessangerButtonProps {
	bot?: Bot | null;
}

export default function MessangerButton(props: IMessangerButtonProps) {
	const localisation = useLocalisation();
	const { screenSize } = useScreenService();
	const messangerLink = useMessangerLink(props.bot);
	const messangerName = useMessangerName(props.bot?.bot_type);

	const messangerColor = useMemo(() => {
		switch (props.bot?.bot_type) {
			case "telegram":
				return "#0088cc";
			case "whatsapp":
				return "#25D366";
			default:
				return "primary";
		}
	}, [props.bot?.bot_type]);

	return (
		<Button
			size={screenSize === ScreenSize.XS ? "medium" : "large"}
			variant={"contained"}
			href={messangerLink}
			target={"_blank"}
			sx={{
				borderRadius: "2rem",
				backgroundColor: messangerColor,
				"&:hover": {
					backgroundColor: messangerColor,
				},
			}}
		>
			<MessangerIcon botType={props.bot?.bot_type} color={"inherit"} />
			<Typography component={"span"} textAlign={"center"} fontSize={"inherit"}>
				{f(localisation.payment.viewInBotButton, {
					messanger: messangerName,
				})}
			</Typography>
		</Button>
	);
}

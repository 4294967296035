import { useEffect, useMemo } from "react";
import { QRCodeSVG } from "qrcode.react";
import { Alert, Box, Button, Typography } from "@mui/material";

import useLocale from "../../../hooks/localisation/useLocale";
import useExternalLogin from "../../../shop/services/useExternalLoginService";
import { ExternalLogin, ExternalLoginPurpose, ExternalLoginType } from "../../../api/auth/types";
import Loader from "../../../loader/loader";
import { TgLink } from "../../../helpers/ThemeComponents";
import useScreenService from "../../../services/useScreenService";
import useAppContext from "../../../useAppContext";
import Interweave from "../../../features/Interweave";
import Divider from "@mui/material/Divider";
import f from "../../../helpers/formatText";

interface ExternalLoginWidgetProps {
	type: ExternalLoginType;
	purpose: ExternalLoginPurpose;
	onChange?: (data: ExternalLogin) => any;
	bot_id: number;
	continue_url?: string | null | undefined;
	onError?: (err: any) => any;
	extra_data?: {};
	instructionText?: string;
	children?: any;
	disableAutoFollowMobile?: boolean;
}

export default function ExternalLoginWidget(props: ExternalLoginWidgetProps) {
	const { isMobile } = useScreenService();
	const locale = useLocale("externalLoginWidget");
	const authLocale = useLocale("auth");
	const {
		bot,
		groupService: { group },
	} = useAppContext();

	const externalLoginService = useExternalLogin({
		type: props.type,
		purpose: props.purpose,
		extra_data: props.extra_data,
		bot_id: props.bot_id,
		continue_url: props.continue_url,
		onChange: props.onChange,
		onError: props.onError,
	});
	const externalLogin = externalLoginService.externalLogin;

	const messangerName = useMemo(() => {
		if (bot?.bot_type) {
			return bot?.bot_type.charAt(0).toUpperCase() + bot?.bot_type.slice(1);
		}
		return "";
	}, [bot?.bot_type]);

	useEffect(() => {
		if (isMobile && externalLogin?.messanger_link && !props.disableAutoFollowMobile) {
			window.open(externalLogin.messanger_link, "_blank");
		}
	}, [externalLogin?.messanger_link, isMobile, props.disableAutoFollowMobile]);

	const desktopInstruction =
		props.instructionText ?? group?.texts.web.auth.external_login.desktop_instruction;

	return (
		<Box textAlign={"center"}>
			{externalLoginService.isLoading ? (
				<Loader />
			) : externalLogin && !externalLoginService.error && externalLogin.status !== "error" ? (
				externalLogin.status === "created" ? (
					<>
						{!isMobile && !!desktopInstruction && (
							<Typography mb={3} fontWeight={"500"}>
								<Interweave
									className={"mb-2 auth-link-header"}
									content={f(desktopInstruction, {
										messanger: messangerName,
										messanger_name: messangerName,
										link: externalLogin.messanger_link,
									})}
								/>
							</Typography>
						)}

						<Button
							color={bot?.bot_type}
							size={"large"}
							component={"a"}
							href={externalLogin.messanger_link}
							target={"_blank"}
							variant={"contained"}
						>
							{f(group?.texts.web.auth.external_login.open_in_messanger_button, {
								messanger_name: messangerName,
							})}
						</Button>

						{props.children}

						{!isMobile && (
							<>
								<Divider sx={{ my: 3 }}>{authLocale.orText}</Divider>
								<Box bgcolor={"white"} width={"fit-content"} mx={"auto"} mb={2}>
									<QRCodeSVG
										size={150}
										style={{
											maxWidth: "100%",
											maxHeight: "100%",
										}}
										value={externalLogin.messanger_link}
									/>
								</Box>
								{!!group?.texts.web.auth.external_login.qr_instruction && (
									<Typography fontSize={"normal"}>
										{group?.texts.web.auth.external_login.qr_instruction}
									</Typography>
								)}
							</>
						)}
					</>
				) : (
					<Typography>
						{externalLogin.status === "canceled" && locale.canceledText}
						{externalLogin.status === "success" && locale.finishProcessText}
					</Typography>
				)
			) : (
				<Alert severity={"error"} variant={"filled"}>
					{externalLoginService.error || locale.unableLoadLinkError}
				</Alert>
			)}
			<Divider
				sx={{
					mt: {
						xs: 3,
						sm: 2,
					},
				}}
			/>
			<Box mt={{ xs: 2, sm: 1 }}>
				<TgLink onClick={externalLoginService.reload}>
					{!externalLogin || externalLogin.status === "created"
						? locale.refreshLinkButton
						: locale.getNewLinkButton}
				</TgLink>
			</Box>
		</Box>
	);
}

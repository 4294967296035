import {useEffect, useMemo, useState} from "react";
import * as locales from "@mui/x-date-pickers/locales";

import useAppContext from "../useAppContext";

export default function useDatetimeService() {
    const {lang} = useAppContext()
    const [localesStrList, setLocalesStrList] = useState<string[]>([])

    const computedLocaleText = useMemo(() => {
        let localeMUICode = "enUS"
        localesStrList.forEach((localeStr: string) => {
            if(localeStr.substring(0, localeStr.length - 2) === lang){
                localeMUICode = localeStr
            }
        })
        // @ts-ignore
        return locales[localeMUICode].components.MuiLocalizationProvider.defaultProps.localeText
    }, [lang, localesStrList])

    useEffect(() => {
        if(locales){
            const list = []
            for(let key in locales){
                list.push(key)
            }
            setLocalesStrList(list)
        }
    }, [])

    return {computedLocaleText}
}

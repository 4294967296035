import { Box, Button, IconButton, Theme, Tooltip, useMediaQuery } from "@mui/material";
import React from "react";
import useAppContext from "../../../../../useAppContext";
import { PictureAsPdf } from "@mui/icons-material";
import { IIncustCoupon } from "../../../types";
import { CheckModifierContent } from "./CheckModifierContent";
import { ApplyButton } from "./ApplyButton";
import f from "../../../../../helpers/formatText";

interface Props {
	isExternalType: boolean;
	handlePdf: () => void;
	coupon: IIncustCoupon;
	isShowApplyButton: boolean;
	isApplyCouponError: boolean;
	handleWallet: () => void;
	handleApply: () => unknown;
	isApplyDisabled: boolean;
	descNeedExpand: boolean;
	needHideButton?: boolean;
	handleIsInside: () => void;
	isInWallet: boolean;
}

export const BottomButtonsBar = ({
	isShowApplyButton,
	isExternalType,
	handlePdf,
	coupon,
	isApplyCouponError,
	handleWallet,
	handleApply,
	isApplyDisabled,
	descNeedExpand,
	needHideButton,
	handleIsInside,
	isInWallet,
}: Props): React.JSX.Element => {
	const { localisation } = useAppContext();
	const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

	return (
		<Box
			width={"100%"}
			color={{ sm: "primary.contrastText" }}
			display={"flex"}
			justifyContent={"flex-end"}
			alignItems={"center"}
			gap={{ xs: 1, sm: 2 }}
			position={{ sm: "static" }}
			bottom={0}
			mt={{ sm: "10px" }}
			pt={{ xs: 2, sm: 0 }}
			pb={{ xs: 3, sm: 0 }}
			pl={{ xs: 3 }}
			pr={{ xs: 3, sm: 2 }}
			bgcolor={{ sm: "primary.main" }}
			flexWrap={"wrap"}
		>
			{isExternalType && (
				<Tooltip
					title={f(localisation.global.downloadByFormat, {
						file_format: "PDF",
					})}
				>
					<IconButton
						color={isTablet ? "inherit" : "primary"}
						size={"small"}
						onClick={handlePdf}
					>
						<PictureAsPdf />
					</IconButton>
				</Tooltip>
			)}

			{!isShowApplyButton &&
				!isApplyCouponError &&
				(coupon.type === "check-modifier" || isInWallet) && (
					<CheckModifierContent handleWallet={handleWallet} />
				)}
			{isShowApplyButton && (
				<ApplyButton handleApply={handleApply} loading={isApplyDisabled} coupon={coupon} />
			)}

			{(descNeedExpand || needHideButton || !isTablet) && (
				<Button
					variant="contained"
					color={isTablet ? "inherit" : undefined}
					sx={{
						color: { sm: "primary.main" },
						bgcolor: { sm: "primary.contrastText" },
					}}
					size="small"
					onClick={handleIsInside}
				>
					{needHideButton
						? localisation.global.hideButton
						: localisation.global.showMoreButton}
				</Button>
			)}
		</Box>
	);
};

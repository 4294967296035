import {useShopContext} from "../../context";
import {useEffect, useRef, useState} from "react";
import api from "../../../api";
import {CharacteristicValue} from "../../../api/shop/basic/types";
import {useSelectedStoreContext} from "../../SelectedStore/context";
import useAppContext from "../../../useAppContext";

export default function useFilterValues(characteristicId: number) {
    const {showError} = useAppContext()
    const {brandInfo} = useShopContext()
    const {
        selectedStore, categoriesService,
        storeFilters,
    } = useSelectedStoreContext()

    const [values, setValues] = useState<CharacteristicValue[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const lastRequestStoreIdRef = useRef<number | null>(null)

    useEffect(() => {
        if (categoriesService.selectedCategoryId || storeFilters?.length) {
            lastRequestStoreIdRef.current = selectedStore.id

            setValues([])
            setIsLoading(true)
            api.shop.basic.getCharacteristicValues(
                selectedStore.id,
                characteristicId,
                {
                    brand_id: brandInfo.id,
                    category_id: categoriesService.selectedCategoryId || null,
                },
            ).then(response => {
                setValues(response.data.values)
            }).catch(err => {
                showError(err)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [selectedStore.id, characteristicId, categoriesService.selectedCategoryId, brandInfo.id, showError])

    return {
        isLoading,
        values,
        setValues,
    }
}

import useLocalisation from "../../../../../hooks/localisation/useLocalisation";
import { Button, useColorScheme } from "@mui/material";

interface IAppleLoginButtonProps {
	onClick?: () => void;
	disabled?: boolean;
}

export default function AppleLoginButton(props: IAppleLoginButtonProps) {
	const localisation = useLocalisation();
	const colorSchema = useColorScheme();

	return (
		<>
			<Button variant={"outlined"} onClick={props.onClick} fullWidth sx={{ mt: 2 }}>
				<div className={"w-100 d-flex external-login-container"}>
					<img
						className={"external-login-logo align-self-center"}
						alt={"telegram"}
						src={
							colorSchema.mode === "dark"
								? require("./appleLogoWhite.png")
								: require("./appleLogoBlack.png")
						}
					/>
					<span className={"ms-1 flex-1"} style={{ fontSize: "13px" }}>
						{localisation.auth.continueWithApple}
					</span>
				</div>
			</Button>
		</>
	);
}

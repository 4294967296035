import {Box} from "@mui/material";
import React, {useState} from "react";
import QrMenuButtonWrapper from "../../buttons/QrMenuButtonWrapper";
import PaymentIcon from "@mui/icons-material/Payment";


interface ICashButtonProps {
    cashButton: string | null
    bonusesAmount: number
    isLoading: boolean
    sendPaymentMessage: (skip: boolean) => Promise<void>
}

export default function CashButton(props: ICashButtonProps) {
    const [isInternalLoading, setIsInternalLoading] = useState(false)
    const handleCashClick = async() => {
        if (props.isLoading) return

        try {
            setIsInternalLoading(true)
            await props.sendPaymentMessage(true)
        } finally {
            setIsInternalLoading(false)
        }
    }

    if (!props.cashButton) return null

    return (
         <QrMenuButtonWrapper
            onClick={() => handleCashClick()}
            loading={isInternalLoading}
            loadingPosition={'end'}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
            >
                <PaymentIcon/>
                {props.cashButton}
            </Box>
        </QrMenuButtonWrapper>
    )
}

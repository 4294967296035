import { AxiosInstance } from "axios";

import { SendTextNotificationData } from "./types";

export default function ShopMessagesModule(instance: AxiosInstance) {
	const getPath = (path: string) => `store/text_notifications/${path}`;

	const getHeaders = (token: string | null | undefined) =>
		token ? { Authorization: `Bearer ${token}` } : undefined;

	return {
		send(data: SendTextNotificationData, token: string | null = null) {
			if (token) {
				return instance.post(getPath("send"), data, { headers: getHeaders(token) });
			}
			return instance.post(getPath("send"), data);
		},
	};
}

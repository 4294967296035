import { IIncustCoupon } from "../../../types";
import useAppContext from "../../../../../useAppContext";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export function ApplyButton(props: {
	handleApply: () => unknown;
	loading: boolean;
	coupon: IIncustCoupon;
}) {
	const {
		localisation: { profile },
	} = useAppContext();

	return (
		<Box color={{ xs: "primary.main", sm: "primary.contrastText" }}>
			<LoadingButton
				onClick={props.handleApply}
				color={"inherit"}
				variant={"outlined"}
				size={"small"}
				sx={{ border: 1 }}
				loading={props.loading}
			>
				{props.coupon.type === "certificate"
					? profile.loyaltyApplyCertificate
					: profile.loyaltyApplyCoupon}
			</LoadingButton>
		</Box>
	);
}

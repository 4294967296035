import useSpecialAccountsService from "./useSpecialAccountsService";
import useAppContext from "../../useAppContext";
import f from "../../helpers/formatText";
import {SpecialAccountCharge} from "./types";



interface SpecialAccountChargeTitleProps {
    specialAccountCharge: SpecialAccountCharge
    idx?: number
}


export default function SpecialAccountChargeTitle({specialAccountCharge, idx = 0}: SpecialAccountChargeTitleProps) {
    return (
        <>
            {specialAccountCharge.title || (
                <LoadedSpecialAccountChargeTitle
                    specialAccountId={specialAccountCharge.id}
                    idx={idx}
                />
            )}
        </>
    )
}

interface LoadedSpecialAccountNameProps {
    specialAccountId: string
    idx?: number
}

function LoadedSpecialAccountChargeTitle({specialAccountId, idx = 0}: LoadedSpecialAccountNameProps) {
    const {localisation} = useAppContext()

    const specialAccountsService = useSpecialAccountsService()

    return (
        <>
            {
                specialAccountsService.getAccountById(specialAccountId)?.public_title ||
                specialAccountsService.getAccountById(specialAccountId)?.title ||
                f(localisation.global.specialAccountBaseTitle, {idx: idx + 1})
            }
        </>
    )
}

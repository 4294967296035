import { ChangeEvent, useEffect, useRef } from "react";
import { MDBIcon } from "mdb-react-ui-kit";

import useLocalisation from "../../hooks/localisation/useLocalisation";
import { useSelectedStoreContext } from "../SelectedStore/context";
import useAppContext from "../../useAppContext";

export default function StoreSearchInput({ isFavorites = false }: { isFavorites?: boolean }) {
	const { appearance } = useAppContext();
	const localisation = useLocalisation();

	const { productsService, selectedStore, favoritesService } = useSelectedStoreContext();

	const inputRef = useRef<HTMLInputElement | null>(null);
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = productsService.search ?? "";
		}
	}, [productsService.search]);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		timerRef.current = setTimeout(() => {
			if (isFavorites) {
				return favoritesService.setSearch(e.target.value);
			}
			productsService.setSearch(e.target.value);
		}, 2000);
	};

	if (!appearance.computedNeedFilterSearch) return null;

	return (
		<>
			<div className={"input-bottom-border-search-icon"}>
				<MDBIcon fas icon={"search"} />
			</div>
			<input
				size={1}
				ref={inputRef}
				className={"input-bottom-border"}
				placeholder={localisation.menu.searchProducts}
				type="text"
				onChange={onChange}
			/>
		</>
	);
}

import { ReactNode, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";

import ReferralOverall from "./ReferralOverall";
import ReferralList from "./ReferralList";
import useAppContext from "../../../../useAppContext";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../api";
import { IReferralProgramChain, IReferralProgramSummary } from "../../../../api/shop/loyalty/types";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

interface IReferralWrapperProps {
	children: ReactNode;
	isChildLoading: boolean;
}

interface IReferralInfo {
	summary: IReferralProgramSummary | null;
	chain: IReferralProgramChain | null;
}

export default function ReferralWrapper(props: IReferralWrapperProps) {
	const {
		brandInfo,
		loadingManager: { isSomethingLoading },
	} = useAppContext();

	const { customerData } = useUserIncustCustomerData();

	const [internalLoading, setInternalLoading] = useState<boolean>(false);

	const query = useQuery<IReferralInfo | null | any>({
		queryKey: ["referral-data", customerData, brandInfo?.id],
		enabled: !!customerData?.token,
		initialData: null,
		queryFn: () => {
			if (!customerData?.token || !brandInfo?.id) return null;
			const summary = api.shop.loyalty
				.getReferralSummary(brandInfo.id)
				.then(response => response.data);
			const chain = api.shop.loyalty
				.getReferralChain(brandInfo.id, true)
				.then(response => response.data);
			return Promise.all([summary, chain]).then(([summary, chain]) => ({ summary, chain }));
		},
	});

	const referralInfo = useMemo(() => {
		const { data: info } = query;
		return info;
	}, [query]);

	const computedNeedReferralInfo = useMemo(() => {
		if (!!referralInfo) {
			if (referralInfo.summary.referrals_count > 0) return true;
		}
		return false;
	}, [referralInfo]);

	useEffect(() => {
		setInternalLoading(query.isFetching);
	}, [query.isFetching, setInternalLoading]);

	return (
		<>
			{props.children}

			{(internalLoading || props.isChildLoading) && (
				<Box sx={{ width: "100%", textAlign: "center" }}>
					{!isSomethingLoading && (
						<CircularProgress style={{ width: "38px", height: "38px" }} />
					)}
				</Box>
			)}

			{!internalLoading && !!referralInfo && (
				<Grid container sx={{ mt: 2 }}>
					<Grid item xs={12}>
						<ReferralOverall summary={referralInfo.summary} />
					</Grid>
					{computedNeedReferralInfo && (
						<Grid item xs={12} sx={{ mt: 3 }}>
							<ReferralList
								chain={referralInfo.chain}
								summary={referralInfo.summary}
							/>
						</Grid>
					)}
				</Grid>
			)}
		</>
	);
}

import useLocalisation from "../../hooks/localisation/useLocalisation";
import {Box, Button, IconButton, InputAdornment, Typography, useColorScheme} from "@mui/material";
import FriendsList from "./FriendsList"
import {ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import ShowAddFriendModal from "./ShowAddFriendModal";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import useAppContext from "../../useAppContext";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import {ThemeModal} from "../../helpers/ThemeComponents";
import {Stack} from "@mui/joy";
import {Pagination} from "@mui/lab";
import {IFriend} from "../../api/shop/friends/types";

let checkFriendInterval: any = null

interface IFriendsInterface {
    mode: 'profile' | 'select',
    checkedFriendUserId?: number | null
    handleSelectFriendClick?: any,
    header?: ReactNode
    disableEmptyText?: boolean
}


export default function Friends(props: IFriendsInterface) {
    const colorScheme = useColorScheme()

    const localisation = useLocalisation()
    const {friendsService} = useAppContext()
    const [showAddFriend, setShowAddFriend] = useState(false)
    const [showDelFriend, setShowDelFriend] = useState(false)
    const [friendToDel, setFriendToDel] = useState<IFriend | null>(null)
    const [searchValue, setSearchValue] = useState<string>('')

    const [page, setPage] = useState(1);
    const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
    };

    const itemsPerPage = 5;

    const rows = useMemo(() => {
        return friendsService.friends.filter((row) => {
            return (
                row.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                row.email.toLowerCase().includes(searchValue.toLowerCase())
            )
        })
    }, [friendsService.friends, searchValue])

    const filteredAndPaginatedRows = useMemo(() => {

      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      if (rows.length > itemsPerPage) return rows.slice(startIndex, endIndex);
      return rows;
    }, [rows, page, itemsPerPage]);

    const handleDelFriendClick = (friend: IFriend) => {
        console.log("handleDelFriendClickL => friend_id=", friend.id)
        setFriendToDel(friend)
        setShowDelFriend(true)
    }

    const handleConfirmDelFriendClick = () => {
        if (!friendToDel) return
        console.log("handleConfirmDelFriendClick => friendIdToDel = ", friendToDel.id)
        friendsService.deleteFriend(friendToDel.id).then(() => {
            friendsService.loadFriends().then(() => {
                setShowDelFriend(false)
            })
        })
    }

    const handleAddFriendClick = (showAddFriend: boolean) => {
        console.log("handleAddFriendClick")
        setShowAddFriend(!showAddFriend)
    }

    const refreshFriends = useCallback(() => {
        checkFriendInterval = setInterval(async () => {
            await friendsService.loadFriends()
        }, 10000)
    }, [friendsService])

    useEffect(() => {
        refreshFriends();
        return () => {
            clearInterval(checkFriendInterval)
        }
    }, [refreshFriends])

    const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    return (
        <>
            <Box
                borderColor={'divider'}
                sx={{
                    borderRadius: '.5rem',
                }}
            >
                {!!props.header && (
                    <Box
                        p={2}
                        border={1}
                        borderColor={'divider'}
                        sx={{
                            borderTopLeftRadius: '.5rem',
                            borderTopRightRadius: '.5rem',
                        }}
                    >
                        {props.header}
                    </Box>
                )}
                {!!friendsService.friends.length && (
                    <TextField
                        fullWidth size={'small'} variant={'filled'}
                        label={localisation.menu.searchProducts}
                        value={searchValue}
                        onChange={onSearchInputChange}
                        sx={{
                            borderLeft: 1,
                            borderRight: 1,
                            borderColor: 'divider',
                        }}
                        InputProps={{
                            sx: {
                                '::before': {
                                    borderBottom: 1,
                                    borderBottomColor: 'divider',
                                },
                                borderRadius: 0,
                            },
                            endAdornment: searchValue && (
                                <InputAdornment position={'end'}>
                                    <IconButton onClick={() => setSearchValue('')}>
                                        <ClearIcon
                                            fontSize={'small'}
                                            color={'inherit'}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                <Box
                    overflow={'hidden'}
                >
                    {!filteredAndPaginatedRows.length ? (
                        !props.disableEmptyText && (
                            <Typography
                                p={2}
                                textAlign={'center'}
                                color={'error'}
                                borderBottom={1}
                                borderColor={'divider'}
                            >
                                {searchValue
                                    ? localisation.profile.noFriendsSearchHeader
                                    : localisation.profile.noFriendsHeader
                                }
                            </Typography>
                        )
                    ) : (<>
                        <FriendsList
                            friends={filteredAndPaginatedRows}
                            handleDelFriendClick={handleDelFriendClick}
                            handleSelectFriendClick={props.handleSelectFriendClick}
                            mode={props.mode}
                            checkedFriendUserId={props.checkedFriendUserId}
                        />
                        {rows.length > itemsPerPage &&
                            <Stack spacing={2}>
                                <Pagination  page={page} onChange={handleChange} count={Math.ceil(rows.length / itemsPerPage)} shape="rounded" />
                            </Stack>
                        }
                    </>)}
                    <Button
                        fullWidth
                        size={'large'}
                        onClick={() => handleAddFriendClick(showAddFriend)}
                        sx={{
                            borderRadius: 0,
                            borderBottomLeftRadius: '.5rem',
                            borderBottomRightRadius: '.5rem',
                            borderLeft: 1,
                            borderRight: 1,
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}
                    >
                        {localisation.profile.friendAddMore}
                    </Button>
                </Box>
            </Box>

            <ShowAddFriendModal
                show={showAddFriend}
                setShow={setShowAddFriend}
            />
            <>
                <ThemeModal
                    open={showDelFriend}
                    onClose={() => setShowDelFriend(false)}
                    title={localisation.orders.friendDeleteHeaderMsg}
                >
                    <DialogContentText>
                        {localisation.orders.friendDeleteMsg.replace("{username}", friendToDel?.name || '')}
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => handleConfirmDelFriendClick()} color={'error'}>
                            {localisation.global.confirmButton}
                        </Button>
                        <Button onClick={() => setShowDelFriend(false)}>
                            {localisation.global.cancelButton}
                        </Button>
                    </DialogActions>
                </ThemeModal>
            </>
        </>
    )
}

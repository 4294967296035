import {Attribute, Product} from "../../../../api/shop/basic/types";
import {CartProduct, CreateCartAttributeData} from "../../../../api/shop/cart/types";
import {SetState} from "../../../../types";


export type SelectedAttribute = {
    attribute: Attribute
} & CreateCartAttributeData


export interface ProductAttributeGroupsProps {
    product: Product | null
    cartProduct: CartProduct | null
    isProductInCart: boolean
    selectedAttributes: CreateCartAttributeData[]
    setSelectedAttributes: SetState<CreateCartAttributeData[]>
    selectAttribute: (attributeId: number, quantity?: number) => any
    unselectAttribute: (attributeId: number, needSelectAnotherAttrId: number | null) => any
    setAttributeQuantity: (attributeId: number, quantity: number) => any
    customClass?: string
}


export type AttributeError = {
    attr: Attribute
    message: string
    isGroupError?: boolean
    isMinus?: boolean
}

export enum AttributesViewType {
    Radio = "radio",
    RadioWithDefault = "radioWithDefault",
    Checkbox = "checkbox",
    CheckboxWithQuantity = "checkboxWithQuantity",
    Default = "default",
}

import useAppContext from "../../../useAppContext";
import MenuIcon from '@mui/icons-material/Menu';
import useScreenService from "../../../services/useScreenService";

export default function MainSidebarDrawerButton() {
    const {sidebarsService} = useAppContext()
    const {isMobile} = useScreenService()

    if(!isMobile) return null

    return (
        <div
            className={"d-lg-none cursor-pointer me-1 align-self-center"}
            onClick={() => {sidebarsService.setIsMainSidebarOpen(true)}}
        >
            <MenuIcon
                sx={{
                    width: 38,
                    height: 38,
                }}
            />
        </div>
    )
}
import { Product } from "../../../api/shop/basic/types";
import { CartProduct, CreateCartAttributeData } from "../../../api/shop/cart/types";
import { Box, Typography, TypographyVariant, useTheme } from "@mui/material";
import useProductPrice from "../../services/useCartService/hooks/useProductPrice";
import { IUseFloatingSumType } from "./hooks/useFloatingSum";
import formatCurrency from "../../../helpers/formatCurrency";
import useAppContext from "../../../useAppContext";
import { useSelectedStoreContext } from "../../SelectedStore/context";

export type ProductPriceProps = {
	product: Product;
	cartProduct: CartProduct | null;
	isInCart: boolean;
	variant?: TypographyVariant;
	selectedAttributes?: CreateCartAttributeData[];
	isCard?: boolean;
	floatingSum?: IUseFloatingSumType;
	forcedPrice?: number;
	note?: string;
	productQty?: number;
};

export default function ProductPrice(props: ProductPriceProps) {
	const { lang, brandInfo } = useAppContext();
	const { selectedStore } = useSelectedStoreContext();
	const theme = useTheme();

	const { computedProductPriceWithQty, computedProductOldPriceWithQty } = useProductPrice(
		props.product,
		props.selectedAttributes,
		props.productQty || 0,
		0,
		props.isCard,
		props.floatingSum
	);

	if (props.product.type === "info") return <></>;

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				opacity: props.product.is_available ? 100 : 65,
				color: theme.palette.mode === "dark" ? "primary.light" : "primary.dark",
			}}
		>
			{props.isInCart &&
				props.cartProduct &&
				!props.product.floating_sum_settings?.is_enabled && (
					<Typography
						variant={props.variant || "body1"}
						sx={{ mr: 1 }}
						fontWeight={"bold"}
					>
						{props.productQty || props.cartProduct.quantity}x
					</Typography>
				)}

			<div>
				<div className={"d-flex flex-wrap"}>
					{props.product.old_price > 0 && (
						<div
							className={
								"d-inline-block me-1 red-cross-out text-start small align-self-center"
							}
						>
							<Typography variant={props.variant || "body1"} fontWeight={"bold"}>
								{props.isInCart
									? formatCurrency(
											props.product.old_price,
											brandInfo?.default_lang || lang,
											selectedStore.currency
										)
									: computedProductOldPriceWithQty}
							</Typography>
						</div>
					)}
					<div className={"d-inline-block "}>
						{props.forcedPrice ? (
							<Typography variant={props.variant || "body1"} fontWeight={"bold"}>
								{formatCurrency(
									props.forcedPrice,
									brandInfo?.default_lang || lang,
									selectedStore.currency
								)}
							</Typography>
						) : (
							<Typography variant={props.variant || "body1"} fontWeight={"bold"}>
								{props.isInCart
									? formatCurrency(
											props.product.price,
											brandInfo?.default_lang || lang,
											selectedStore.currency
										)
									: computedProductPriceWithQty}
							</Typography>
						)}
					</div>
					{props.product.is_weight && (
						<span className={"fw-light small text-end ms-2"}>{props.product.unit}</span>
					)}
				</div>
				{!!props.note && (
					<Typography variant={"body2"} color={"text.secondary"} fontWeight={"bold"}>
						{props.note}
					</Typography>
				)}
			</div>
		</Box>
	);
}

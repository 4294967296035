import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/joy";
import CouponSkeletonDesktop from "./desktop";
import CouponSkeletonMobile from "./mobile";

export default function CouponSkeleton() {
	const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

	if (!isTablet) return <CouponSkeletonMobile />;

	return <CouponSkeletonDesktop />;
}

import { LocaleType } from "../services/useLocalisationService/types";

export interface IMapsLocale extends LocaleType {
	poweredBy: string;
	selectLocation: string;
	zones: string;
	cantFindAddressHeader: string;
	cantFindAddressSubHeader: string;
	mapsError: string;
	geoLocationSuccessMessage: string;
	geoLocationServiceError: string;
	geoLocationBrowserError: string;
	addressErrorInstructions: string;
	checkOnMap: string;
	tryOnMap: string;
	determineLocation: string;
	showPointOfSaleOnMap: string;
	zeroResultsError: string;
	similarButton: string;
	similarHeader: string;
	selectOnMap: string;
	showEmbeddedMap: string;
	embeddedMapTitle: string;
}

export const MapsLocale: IMapsLocale = {
	poweredBy: "powered by",
	selectLocation: "web app shipments choose location header",
	zones: "web app shipments zones header",
	cantFindAddressHeader: "web app shipments cant find address header",
	cantFindAddressSubHeader: "web app shipments cant find address subheader",
	mapsError: "maps api status error",
	geoLocationSuccessMessage: "maps api location found message",
	geoLocationServiceError: "maps api geolocation service error",
	geoLocationBrowserError: "maps api geolocation browser error",
	addressErrorInstructions: "shipment prices address error instruction text",
	checkOnMap: "shipment prices address error map button text",
	tryOnMap: "shipment prices address error try map button text",
	determineLocation: "address determine location tooltip",
	showPointOfSaleOnMap: "address show store location tooltip",
	zeroResultsError: "maps api geocoding zero results error",
	similarButton: "web app similar predictions map button",
	similarHeader: "web app similar predictions map header",
	selectOnMap: "web app shipments select on map button",
	showEmbeddedMap: "web app store embedded map button",
	embeddedMapTitle: "web app store embedded map title",
};

import { GroupColorSchema } from "./types";
import tinycolor from "tinycolor2";

export const setFont = (fontUrl?: string | null | undefined) => {
	const defaultFonts = "Roboto, Helvetica, Arial, sans-serif";

	let fontFamily: string | null = null;

	if (fontUrl) {
		const url = new URL(fontUrl);
		let fontFamilyParam = url.searchParams.get("family");

		if (fontFamilyParam) {
			if (fontFamilyParam.includes(":")) {
				const separatorIDX = fontFamilyParam.lastIndexOf(":");
				fontFamily = fontFamilyParam.slice(0, separatorIDX);
			} else {
				fontFamily = fontFamilyParam;
			}

			const fontFamilyLink = fontFamily + ":wght@300;400;500;700;900";
			const fontLink = `https://fonts.googleapis.com/css2?family=${fontFamilyLink}`;

			if (!document.getElementById(`imported-font-family-${fontFamily}`)) {
				const fontLinkEl = document.createElement("link");

				fontLinkEl.href = fontLink;
				fontLinkEl.rel = "stylesheet";
				fontLinkEl.id = `imported-font-family-${fontFamily}`;

				document.head.appendChild(fontLinkEl);
			}

			fontFamily = `${fontFamily}, ${defaultFonts}`;
		}
	} else {
		fontFamily = defaultFonts;
	}

	if (fontFamily) {
		document.body.style.fontFamily = fontFamily;
	}

	return {
		url: fontUrl,
		family: fontFamily,
	};
};
export type CalculatedTheme = {
	mode: "light" | "dark";

	bg_color: string | null;
	secondary_bg_color: string | null;

	primary_color: string | null;
	secondary_color: string | null;
	error_color: string | null;
	warning_color: string | null;

	text_color: string | null;
	hint_color: string;
	font: string | null;
};
export const calculateTheme = (theme: GroupColorSchema | null | undefined): CalculatedTheme => {
	const isTg = !!window.Telegram?.WebApp.initData;

	const calculateValue = (
		tgVariable: string,
		themeVariable: keyof GroupColorSchema
	): string | null => {
		if (themeVariable === "use_telegram_theme" || themeVariable === "font") {
			throw new Error("unsupported");
		}

		const themeValue: string | null = theme ? theme[themeVariable] || null : null;
		if (!isTg) {
			return themeValue;
		}

		const tgValue: string = window.Telegram?.WebApp.themeParams[tgVariable];

		console.log({
			tgVariable,
			themeVariable,
			tgValue,
			theme,
			useTgTheme: !theme || theme?.use_telegram_theme,
		});

		if (tgValue && (!theme || theme?.use_telegram_theme)) {
			return tgValue;
		} else {
			return themeValue;
		}
	};

	let mode: "light" | "dark";
	if (isTg && (!theme || theme?.use_telegram_theme)) {
		if (tinycolor(window.Telegram?.WebApp?.themeParams?.bg_color).isDark()) {
			mode = "dark";
		} else {
			mode = "light";
		}
	} else {
		mode = theme?.theme_mode || "light";
	}

	return {
		mode: mode,

		// Swapped colours in the telegram is not a mistake. In telegram, it is swapped
		bg_color: calculateValue("secondary_bg_color", "bg_color"),
		secondary_bg_color: calculateValue("bg_color", "secondary_bg_color"),

		primary_color: calculateValue("button_color", "primary_color"),
		secondary_color: theme?.secondary_color || null,
		error_color: theme?.error_color || null,
		warning_color: theme?.warning_color || null,

		text_color: calculateValue("text_color", "text_color"),
		hint_color: isTg ? window.Telegram?.WebApp.themeParams.hint_color : "#cccccc",
		font: theme?.font || null,
	};
};

import {Box} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {IQrMenuButtonWrapperProps} from "../types";
import LoadingButton from "@mui/lab/LoadingButton";

export default function QrMenuButtonWrapper(props: IQrMenuButtonWrapperProps) {
    if (!props.children) return null

    return (
        <Box sx={{
            borderBottom: +(!props.noBorder),
            borderColor: 'divider',
            color: 'text.secondary'
        }}>
            <LoadingButton
                fullWidth
                size={'large'}
                onClick={props.onClick}
                color={'inherit'}
                sx={{
                    paddingX: {xs: 3, md: 4},
                    paddingY: '12px',
                    fontSize: 'large'
                }}
                disabled={props.disabled}
                loading={props.loading}
                loadingPosition={props.loadingPosition}
                startIcon={props.startIcon}
                endIcon={props.endIcon || <ArrowForwardIosIcon color={'inherit'}/>}
            >
                <Box
                    flex={1}
                    display={'flex'}
                    alignItems={'center'}
                >
                    {props.children}
                </Box>
            </LoadingButton>
        </Box>
    )
}

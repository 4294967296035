import changePage from "../../../../helpers/changePage";

export function incustPayRedirect(
	type: "order" | "invoice",
	data: {
		selectedStoreId?: number | null;
		orderId?: number | null;
		orderToken?: string | null;
		invoiceToken?: string | null;
	}
) {
	if (type === "order") {
		changePage(`/shop/${data.selectedStoreId}/orders/${data.orderId}`, {
			is_order_created: "true",
			...(data.orderToken && {
				order_token: data.orderToken,
			}),
		});
	} else {
		changePage(`/success_payment`, {
			...(data.invoiceToken && {
				invoice_token: data.invoiceToken,
			}),
		});
	}
}

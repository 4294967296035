import {useEffect} from "react";
import {MDBContainer} from "mdb-react-ui-kit";

import {TgLink} from "../../helpers/ThemeComponents";
import Favorites from "./Favorites";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import {useSelectedStore} from "../SelectedStore/context";
import useAppContext from "../../useAppContext";
import changePage from "../../helpers/changePage";

export default function FavoritesPage(){
    const {
        setPageTitle,
    } = useAppContext()
    const selectedStore = useSelectedStore()

    const localisation = useLocalisation()

    useEffect(() => {
        setPageTitle(`${selectedStore.name} - ${localisation.profile.favoritesHeader}`)
    }, [localisation.profile.favoritesHeader, selectedStore.name, setPageTitle])

    return (
        <MDBContainer className={'px-0'}>
            <div className={'fs-3 fw-bold theme-text'}>
                {localisation.profile.favoritesHeader}
            </div>
            <div>
                 <TgLink
                    className={'cursor-pointer'}
                    onClick={() => changePage(`/shop/${selectedStore.id}/menu`)}
                >
                    {localisation.global.backHome}
                </TgLink>
            </div>

            <Favorites />

        </MDBContainer>
    )
}

import './loader.scss'
import {CircularProgress} from "@mui/material";


export default function Loader( { show = true }) {
    return (
        <div className={'text-center theme-text loader-container w-100' + (show ? ' show' : '')}>
            <CircularProgress/>
        </div>
    )
}

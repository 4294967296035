import { ThemeModal } from "../../../../helpers/ThemeComponents";
import { IProductTopupPropsBase } from "./types";
import MakeOrderLite from "../../../order/MakeOrderLite/MakeOrderLite";
import ProductTopupConfirmationContent from "./ProductTopupConfirmationContent";
import useAppContext from "../../../../useAppContext";
import useScreenService from "../../../../services/useScreenService";

export default function ProductTopupConfirmModal(props: IProductTopupPropsBase) {
	const {
		localisation: { topup },
	} = useAppContext();
	const { isMobile } = useScreenService();

	return (
		<ThemeModal
			open={props.service.showConfirmModal}
			setOpen={props.service.setShowConfirmModal}
			title={topup.topupHeader}
			fullWidth={true}
			fullScreen={isMobile}
			maxWidth={"md"}
		>
			<MakeOrderLite
				product={props.service.computedOrderProduct}
				amount={props.service.optionsService.computedAmountWithCharge}
				confirmationContent={<ProductTopupConfirmationContent service={props.service} />}
				submit={props.service.handleConfirm}
				backCallback={() => props.service.setShowConfirmModal(false)}
				service={props.service}
			/>
		</ThemeModal>
	);
}

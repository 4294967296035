import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea, CardActions } from "@mui/material";

import { Order } from "../../../../api/shop/order/types";
import { SetState } from "../../../../types";
import OrderCardMedia from "./OrderCardMedia";
import OrderCardContent from "./OrderCardContent";
import OrderCardActions from "./OrderCardActions";
import { IUsePaymentsService } from "../../../payments/hooks/useNewPaymentsService";

interface OrderCardProps {
	order: Order;
	setOrders: SetState<Order[]>;
	setSelectedOrder: (order: Order | null) => void;
	paymentsService: IUsePaymentsService;
	setShowPayments: (show: boolean) => void;
}

export default function OrderCard({
	order,
	setOrders,
	setSelectedOrder,
	paymentsService,
	setShowPayments,
}: OrderCardProps) {
	const [orderState, setOrderState] = useState(order);

	useEffect(() => {
		if (order) setOrderState(order);
	}, [order]);

	return (
		<Card className={"h-100 d-flex flex-column"}>
			<CardActionArea onClick={() => setSelectedOrder(orderState)}>
				<OrderCardMedia orderProducts={orderState.order_products} />
				<CardContent className={"p-2"}>
					<OrderCardContent order={orderState} />
				</CardContent>
			</CardActionArea>
			<div className={"flex-1"}></div>
			<CardActions className={"d-flex justify-content-between px-2 pt-0 mx-1"}>
				<OrderCardActions
					order={orderState}
					setOrders={setOrders}
					setOrderState={setOrderState}
					setSelectedOrder={setSelectedOrder}
					paymentsService={paymentsService}
					setShowPayments={setShowPayments}
				/>
			</CardActions>
		</Card>
	);
}

import {Box, Button, Container, Link, Paper, Typography} from "@mui/material";
import ReviewComponent from "./ReviewComponent";
import useAppContext from "../../useAppContext";
import {useSelectedStoreOptional} from "../SelectedStore/context";
import changePage from "../../helpers/changePage";
import Divider from "@mui/material/Divider";


export default function ReviewPage() {
    const {localisation, groupService: {group}} = useAppContext()
    const selectedStore = useSelectedStoreOptional()

    return (
        <Container maxWidth={'sm'}>
            <Box
                mt={{xs: 3, md: 4}}
            >
                <Box mb={3}>
                    <Typography
                        variant={'h4'}
                        fontWeight={'bold'}
                    >
                        {group?.texts.review.leave_header}
                    </Typography>
                    <Link
                        role={'button'}
                        textAlign={'start'}
                        onClick={() => changePage(`/shop/${selectedStore ? selectedStore.id + '/' : ''}menu`)}
                    >
                        {localisation.global.backHome}
                    </Link>
                </Box>
                <Paper>
                    <Box p={2}>
                        <ReviewComponent/>
                    </Box>
                </Paper>
            </Box>
        </Container>
    )
}

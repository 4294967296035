import { useCallback, useEffect, useState } from "react";
import { ExternalLogin } from "../../../api/auth/types";
import api from "../../../api";
import { ExternalLoginService, ExternalLoginServiceConfig } from "./types";
import { getErrorText } from "../../../helpers/errors";

const TIMEOUT = 3000;

export default function useExternalLogin({
	type,
	purpose,
	extra_data,
	bot_id,
	continue_url,
	onChange,
	onError,
}: ExternalLoginServiceConfig): ExternalLoginService {
	const [externalLogin, setExternalLogin] = useState<ExternalLogin | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const [error, setError] = useState<string | null>(null);

	const createExternalLogin = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await api.auth.createExternalLogin({
				type,
				purpose,
				extra_data,
				continue_url,
				bot_id,
			});
			onError && onError(null);
			setError(null);
			setExternalLogin(response.data);
			return response;
		} catch (err: any) {
			onError && onError(err);
			setError(getErrorText(err, "") || null);
		} finally {
			setIsLoading(false);
		}
		return null;
	}, [continue_url, bot_id, onError, purpose, type, extra_data]);

	useEffect(() => {
		createExternalLogin().then();
	}, [createExternalLogin]);

	useEffect(() => {
		if (externalLogin?.uuid) {
			const intervalID = setInterval(async () => {
				api.auth.getExternalLogin(externalLogin.uuid).then(response => {
					console.log("checking external login", response.data);
					setExternalLogin(prevState => {
						const newData = response.data;
						if (prevState?.status !== newData.status) {
							onChange && onChange(newData);
							return newData;
						} else {
							return prevState;
						}
					});
				});
			}, TIMEOUT);

			return () => {
				clearInterval(intervalID);
				if (externalLogin.status === "created" || externalLogin.status === "started") {
					api.auth.cancelExternalLogin(externalLogin.uuid).then(() => {});
				}
			};
		}
	}, [externalLogin?.status, externalLogin?.uuid, onChange]);

	const reload = () => createExternalLogin();

	return { externalLogin, error, isLoading, reload };
}

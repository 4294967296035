import useLocalisation from "../../../hooks/localisation/useLocalisation";
import Select, {
	components,
	ControlProps,
	DropdownIndicatorProps,
	SelectInstance,
	SingleValue,
	ValueContainerProps,
} from "react-select";
import React, { useRef, useState } from "react";
import useOutsideClickHandler from "../../../features/hooks/useOutsideClickHandler";
import { MDBIcon } from "mdb-react-ui-kit";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import useScreenService from "../../../services/useScreenService";
import useAppContext from "../../../useAppContext";

interface OptionType {
	label: string;
	value: "low_price" | "high_price" | "categories";
}

export default function Sort() {
	const { isMobile } = useScreenService();
	const { categoriesService, productsService, selectedStore } = useSelectedStoreContext();
	const { appearance } = useAppContext();

	const localisation = useLocalisation();

	const [isOpen, setIsOpen] = useState(false);

	const ref = useRef<HTMLDivElement | null>(null);
	const selectRef = useRef<SelectInstance<SingleValue<OptionType>>>(null);

	const options: OptionType[] = [
		{
			label: categoriesService.areActiveChildCategories()
				? localisation.menu.categorySort
				: localisation.menu.defaultSort,
			value: "categories",
		},
		{
			label: localisation.menu.lowHighFilter,
			value: "low_price",
		},
		{
			label: localisation.menu.highLowFilter,
			value: "high_price",
		},
	];

	function ValueContainer({ children, getValue, ...props }: ValueContainerProps) {
		if (isMobile) {
			return null;
		}

		return (
			<components.ValueContainer getValue={getValue} {...props}>
				<div>{localisation.menu.sortButton}</div>
			</components.ValueContainer>
		);
	}

	function Control({ children, ...props }: ControlProps) {
		return (
			<div
				onClick={e => {
					e.stopPropagation();
					setIsOpen(prevState => !prevState);
				}}
			>
				<components.Control {...props}>{children}</components.Control>
			</div>
		);
	}

	function DropdownIndicator(props: DropdownIndicatorProps) {
		return (
			<components.DropdownIndicator {...props} className={"no-rotate"}>
				<MDBIcon fas icon={"sort"} className={"primary-color"} />
			</components.DropdownIndicator>
		);
	}

	useOutsideClickHandler(
		ref,
		() => {
			setIsOpen(false);
		},
		isOpen
	);

	if (!appearance.computedNeedFilterSort) return null;

	return (
		<div ref={ref}>
			<Select
				ref={selectRef}
				className={"shop-select minimalistic full-width-option h-100"}
				classNamePrefix={"shop-select"}
				options={options}
				isMulti={false}
				menuIsOpen={isOpen}
				onMenuOpen={() => setIsOpen(true)}
				onMenuClose={() => setIsOpen(false)}
				placeholder={localisation.menu.sortButton}
				value={options.filter(x => x.value === productsService.sort)}
				onChange={newValue => {
					productsService.setSort(newValue?.value || "categories");
				}}
				// @ts-ignore
				components={{ ValueContainer, Control, DropdownIndicator }}
			/>
		</div>
	);
}

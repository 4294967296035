import { useEffect, useMemo, useState } from "react";

import { IQrMenuPaymentsProps, QrMenuPaymentState } from "../types";
import { useShopContext } from "../../context";
import api from "../../../api";
import CashButton from "./buttons/CashButton";
import CardButton from "./buttons/CardButton";
import OnlineButton from "./buttons/OnlineButton";
import { useSelectedStoreOptional } from "../../SelectedStore/context";
import useAppContext from "../../../useAppContext";
import { getBonusesItemByCurrency } from "../../../features/services/useIncustService/functions";
import useCurrency from "../../../services/useCurrencyService";
import MakeInvoice from "../../payments/MakeInvoice";
import { Box, Typography } from "@mui/material";
import useUserIncustCustomerData from "../../../hooks/incust/useUserIncustCustmerData";

export default function Payments(props: IQrMenuPaymentsProps) {
	const {
		localisation,
		authService: { loadUser },
		toastService,
		showError,
	} = useAppContext();

	const { brandInfo, menuInStoreService } = useShopContext();
	const selectedStore = useSelectedStoreOptional();
	const currency = useCurrency();

	const [isSendMessageLoading, setIsSendMessageLoading] = useState(false);
	const [isUserLoading, setIsUserLoading] = useState(false);

	const setPaymentState = props.setPaymentState;

	const { customerData } = useUserIncustCustomerData();

	const sendPaymentMessage = async (isCash: boolean) => {
		if (!menuInStoreService.menuInStore) return;

		setIsSendMessageLoading(true);
		try {
			await api.shop.text_notifications.send({
				target: "crm",
				type: isCash ? "payment_cash" : "payment_card",
				brand_id: brandInfo.id,
				store_id: selectedStore?.id,
				menu_in_store_id: menuInStoreService.menuInStore.id,
			});
			toastService.showToast({
				severity: "success",
				message: localisation.qrMenu.sendMessageSuccess,
			});
			if (props.setShow) props.setShow(false);
		} catch {
			showError(localisation.qrMenu.sendMessageError);
		} finally {
			setIsSendMessageLoading(false);
			setPaymentState(QrMenuPaymentState.CanPay);
		}
	};

	const computedBonusesAmount = useMemo(() => {
		if (customerData?.user_card && currency) {
			const bonusItem = getBonusesItemByCurrency(customerData.user_card, currency);
			if (bonusItem) return bonusItem.bonuses_amount;
		}
		return 0;
	}, [customerData?.user_card, currency]);

	const isOnlyOnline =
		!props.cashButton &&
		!props.cardButton &&
		props.onlineButton &&
		menuInStoreService.menuInStore?.payment_option !== "disabled";

	useEffect(() => {
		if (props.show) {
			if (isOnlyOnline) {
				setPaymentState(QrMenuPaymentState.UserSum);
				setIsUserLoading(true);
				loadUser().finally(() => {
					setIsUserLoading(false);
				});
			} else {
				setPaymentState(QrMenuPaymentState.CanPay);
			}
		}
	}, [props.show, setPaymentState, isOnlyOnline, loadUser]);

	const makeInvoiceGoBack = isOnlyOnline
		? props.setShow
			? () => props.setShow && props.setShow(false)
			: undefined
		: () => setPaymentState(QrMenuPaymentState.CanPay);

	return (
		<>
			{props.paymentState === QrMenuPaymentState.UserSum && (
				<Box width={"100%"}>
					<MakeInvoice
						isLoading={isUserLoading}
						goBack={makeInvoiceGoBack}
						invoiceTemplate={menuInStoreService.menuInStore?.invoice_template}
						paymentMode={
							menuInStoreService.menuInStore?.invoice_template_id
								? "template"
								: "entered_amount"
						}
						enteredAmount={parseFloat(menuInStoreService.enteredAmount || "0")}
						menuInStoreId={menuInStoreService.menuInStore?.id}
					/>
				</Box>
			)}

			{props.paymentState === QrMenuPaymentState.CanPay && (
				<Box width={"100%"}>
					<Typography
						px={3}
						fontSize={"large"}
						fontStyle={"italic"}
						borderBottom={1}
						py={3}
						borderColor={"divider"}
					>
						{localisation.qrMenu.paymentMsg}
					</Typography>

					<Box display={"flex"} flexDirection={"column"}>
						{menuInStoreService.menuInStore?.payment_option !== "disabled" && (
							<OnlineButton
								onlineButton={props.onlineButton}
								menuInStoreService={menuInStoreService}
								setPaymentState={setPaymentState}
							/>
						)}

						<CardButton
							cardButton={props.cardButton}
							bonusesAmount={computedBonusesAmount}
							isLoading={isSendMessageLoading}
							sendPaymentMessage={sendPaymentMessage}
						/>

						<CashButton
							cashButton={props.cashButton}
							bonusesAmount={computedBonusesAmount}
							isLoading={isSendMessageLoading}
							sendPaymentMessage={sendPaymentMessage}
						/>
					</Box>
				</Box>
			)}
		</>
	);
}

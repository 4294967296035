import {MDBRow, MDBCol} from "mdb-react-ui-kit";

import Favorites from "../../favorites/Favorites";
import {ProfileTab} from "../types";
import ProfileLayout from "../ProfileLayout";
import {useSelectedStoreOptional} from "../../SelectedStore/context";
import {useShopContext} from "../../context";
import useAppContext from "../../../useAppContext";
import api from "../../../api";
import {TgButton} from "../../../helpers/ThemeComponents";
import changePage from "../../../helpers/changePage";
import BottomButtons from "../../BottomButtons";
import useScreenService from "../../../services/useScreenService";

export default function ProfileFavorites(){
    const tab = ProfileTab.favorites
    const selectedStore = useSelectedStoreOptional()
    const {isSingleShop} = useShopContext()
    const {localisation} = useAppContext()
    const {isMobile} = useScreenService()

    const backHome = () => {
        const storeId = selectedStore?.id
        if (isSingleShop && storeId) {
            changePage(`/shop/${storeId}/menu`)
        } else {
            api.shop.basic.removeLastStoreId()
            changePage('/shop/select')
        }
    }

    return (
        <ProfileLayout tab={tab}>
            <MDBRow>
                <MDBCol size={12}>
                    {!!selectedStore
                        ?
                            <Favorites/>
                        :
                            <div className={'text-center w-100'}>
                                <div className={'mb-2'}>{localisation.profile.noFavoritesHeader}</div>
                                <TgButton onClick={backHome}>
                                    {localisation.global.chooseStore}
                                </TgButton>
                            </div>
                    }
                </MDBCol>
            </MDBRow>

            {isMobile && (
                <BottomButtons />
            )}
        </ProfileLayout>
    )
}

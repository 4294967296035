// BirthdayInput.tsx
import { AuthorisationComponentProps } from "../types";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import { dateToISOFormat } from "../../../helpers";
import { ProfileDateBirth } from "../../../shop/profile/pages/ProfileDateBirth";

export default function BirthdayInput(props: AuthorisationComponentProps) {
	if (props.form.state !== "emailRegistration") {
		throw new Error("BirthdayInput is not supported for emailRegistration");
	}

	const localisation = useLocalisation();

	const getOnChange = (field: "birth_date") => (value: string | Dayjs | null) => {
		if (!value || typeof value === "string") return null;
		props.form.input(field, dateToISOFormat(value.toDate()) || "");
	};

	return (
		<Box sx={{ mt: 2 }}>
			<ProfileDateBirth
				header={localisation.auth.birthLabel}
				value={props.form.birth_date ? props.form.birth_date : null}
				onChange={getOnChange("birth_date")}
				fullWidth
				helperText={localisation.forms.formBirthdayHelper}
				tooltipText={localisation.forms.formBirthday}
				tooltipZIndex={9999}
			/>
		</Box>
	);
}

import {Grid} from "@mui/material";

import {useSelectedStoreContext} from "../../../../SelectedStore/context";
import ShippingCard from "./ShippingCard";
import {SelectedShipmentServiceType} from "../../useSelectedShipmentService";
import {IShipmentPricesService} from "../../../../services/useShipmentPrices";
import {ShipmentPrice} from "../../../../../api/shop/basic/types";

interface IShippingMethodsProps {
    showError: boolean
    selectedShipmentService: SelectedShipmentServiceType
    shipmentPricesService: IShipmentPricesService
    getPricesString: (prices: ShipmentPrice[], currency?: string) => string
}

export default function ShippingMethods(props: IShippingMethodsProps){
    const {shipmentsService} = useSelectedStoreContext()

    return (
        <Grid container spacing={2} mb={2}>
            {shipmentsService.shipments.base.map((shipment) => (
                shipment.base_type !== 'in_store' && (
                    <Grid item xs={12} key={shipment.id}>
                        <ShippingCard
                            shipment={shipment}
                            isError={props.showError}
                            selectedShipmentService={props.selectedShipmentService}
                            getShipmentPricesById={props.shipmentPricesService.getShipmentPricesById}
                            isLoading={
                                (props.shipmentPricesService.selectedPriceLoading &&
                                    props.selectedShipmentService?.selectedShipment?.id === shipment.id
                                )
                            }
                            allLoading={props.shipmentPricesService.allPricesLoading}
                            selectedShipmentPrice={props.shipmentPricesService.computedSelectedShipmentPrice}
                            getPricesString={props.getPricesString}
                        />
                    </Grid>
                )
            ))}

            {shipmentsService.shipments.rest.map((shipment) => (
                <Grid item xs={12} key={shipment.id}>
                    <ShippingCard
                        shipment={shipment}
                        isError={props.showError}
                        selectedShipmentService={props.selectedShipmentService}
                        getShipmentPricesById={props.shipmentPricesService.getShipmentPricesById}
                        isLoading={
                            (props.shipmentPricesService.selectedPriceLoading &&
                                props.selectedShipmentService?.selectedShipment?.id === shipment.id
                            )
                        }
                        allLoading={props.shipmentPricesService.allPricesLoading}
                        selectedShipmentPrice={props.shipmentPricesService.computedSelectedShipmentPrice}
                        getPricesString={props.getPricesString}
                    />
                </Grid>
            ))}

            {shipmentsService.shipments.groups.map((shipmentGroup) => (
                    shipmentGroup.shipments.length > 0 && (
                        <Grid item xs={12} key={shipmentGroup.id}>
                            <ShippingCard
                                shipment={shipmentGroup}
                                isError={props.showError}
                                selectedShipmentService={props.selectedShipmentService}
                                getShipmentPricesById={props.shipmentPricesService.getShipmentPricesById}
                                isLoading={
                                    (props.shipmentPricesService.selectedPriceLoading &&
                                        props.selectedShipmentService?.selectedShipment?.id === shipmentGroup.id //TODO: check
                                    )
                                }
                                allLoading={props.shipmentPricesService.allPricesLoading}
                                selectedShipmentPrice={props.shipmentPricesService.computedSelectedShipmentPrice}
                                isGroup={true}
                                getPricesString={props.getPricesString}
                            />
                        </Grid>
                    )
                ))
            }
        </Grid>
    )
}

import useMergeState from "../../../../helpers/useMergedState";
import {IncustPayData, IncustPayDataService, IncustPayDataServiceMethods} from "./types";
import {useMemo} from "react";


const DefaultData: IncustPayData = {
    isLoading: false,
    isPayed: false,
    error: null,
    pin: '',
    odometer: null,
    vehicleId: '',
}

export default function useIncustPayDataService(): IncustPayDataService {
    const [data, setData] = useMergeState<IncustPayData>(DefaultData)

    const methods: IncustPayDataServiceMethods = useMemo(() => {
        return {
            setData,
            setIsLoading: (value: boolean) => setData({isLoading: value}),
            setIsPayed: (value: boolean) => setData({isPayed: value}),
            setError: (value: string | null) => setData({error: value}),
            setPin: (value: string) => setData({pin: value}),
            setOdometer: (value: number | null) => setData({odometer: value}),
            setVehicleId: (value: string) => setData({vehicleId: value}),
            reset: () => setData(DefaultData)
        }
    }, [setData])

    return {
        ...data,
        ...methods,
    }
}

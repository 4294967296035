import React from "react";
import { motion } from "framer-motion";
import QRCode from "qrcode.react";
import { Box, Divider, Typography } from "@mui/material";
import useAppContext from "../../../../../useAppContext";
import { IApplyCouponInfo, IIncustCoupon, IncustTransaction } from "../../../types";
import { CouponCode } from "../shared/CouponCode";
import { DateOfUse } from "../shared/DateOfUse";
import { CanUseWithOrder } from "../shared/CanUseWithOrder";
import { CouponCodeExternal } from "../shared/CouponCodeExternal";
import { BottomButtonsBar } from "../shared/BottomButtonsBar";
import BaseCouponDesc from "../shared/BaseCouponDesc";
import CouponAwards from "../shared/CouponAwards";

interface Props {
	coupon: IIncustCoupon;
	isInWallet: boolean;
	isApplyCouponError: boolean;
	insideCoupon: boolean;
	handleIsInside: () => void;
	handleDownload: () => Promise<void>;
	isAddCouponToWalletLoading: boolean;
	isShowApplyButton: boolean;
	handleWallet: () => void;
	handleApply: () => unknown;
	applyCouponInfo: IApplyCouponInfo | null;
	onCloseApplyPopup: () => unknown;
	redeemedCouponCheck: IncustTransaction | null;
}

export const MobileBackSide = ({
	coupon,
	isInWallet,
	insideCoupon,
	handleIsInside,
	handleDownload,
	isAddCouponToWalletLoading,
	isShowApplyButton,
	isApplyCouponError,
	handleWallet,
	handleApply,
	onCloseApplyPopup,
	applyCouponInfo,
	redeemedCouponCheck,
}: Props): React.JSX.Element => {
	const isExternalType = coupon.type === "external";
	const {
		lang,
		authService: { user },
	} = useAppContext();

	return (
		<Box
			component={motion.div}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
			sx={{
				scrollbarWidth: "none",
				overflowY: "hidden",
			}}
		>
			<Box
				display={"flex"}
				flexDirection={"column"}
				gap={1}
				pt={4}
				pb={2}
				textAlign={"center"}
			>
				{(!!coupon.code?.length || !!coupon.external_code?.length) && (
					<QRCode
						value={
							coupon.external_code?.length
								? coupon.external_code.toString()
								: coupon.code?.toString() ?? ""
						}
						size={120}
						style={{ margin: "10px auto 10px auto" }}
					/>
				)}

				<Box>
					{isExternalType &&
					!!coupon?.external_code?.length &&
					coupon.status !== "redeemed" ? (
						<CouponCodeExternal code={coupon.external_code} />
					) : (
						coupon.code && (
							<CouponCode
								coupon={coupon}
								variant="body1"
								textAlign={"center"}
								width={"100%"}
								fontSize={"medium"}
							/>
						)
					)}
					<DateOfUse coupon={coupon} lang={lang} />
				</Box>

				{!coupon.redeem_dt && coupon.type === "check-modifier" && user && isInWallet && (
					<CanUseWithOrder />
				)}
			</Box>

			<Box position={"relative"} px={4} pb={2} textAlign={"center"}>
				<Typography variant={"h6"} fontWeight={"600"} px={{ xs: 3, sm: 0 }}>
					{coupon.title}
				</Typography>

				<CouponAwards
					isExternalType={isExternalType}
					coupon={coupon}
					applyCouponInfo={applyCouponInfo}
					redeemedCouponCheck={redeemedCouponCheck}
					onCloseApplyPopup={onCloseApplyPopup}
				/>

				{coupon.description && (
					<BaseCouponDesc insideCoupon={insideCoupon} coupon={coupon} />
				)}
			</Box>

			<Divider />

			<BottomButtonsBar
				isExternalType={isExternalType}
				handlePdf={handleDownload}
				coupon={coupon}
				isShowApplyButton={isShowApplyButton}
				isApplyCouponError={isApplyCouponError}
				handleWallet={handleWallet}
				handleApply={handleApply}
				isApplyDisabled={isAddCouponToWalletLoading}
				descNeedExpand={false}
				needHideButton={true}
				handleIsInside={handleIsInside}
				isInWallet={isInWallet}
			/>
		</Box>
	);
};

import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { SelectedStoreContextStoreLoadedType, SelectedStoreContextType } from "./types";
import { useParams } from "react-router-dom";
import { Characteristic, Store } from "../../api/shop/basic/types";
import api from "../../api";
import { useShopContext } from "../context";
import useCategoriesService from "../services/useCategoriesService";
import useProductsService from "../services/useProductsService";
import useOrdersCountService from "../services/useOrdersCountService";
import useFiltersService from "../services/useFiltersService";
import useCartService from "../services/useCartService";
import useFavoritesService from "../services/useFavoritesService";
import useShipmentsService from "../context/data/useShipmentsService";
import ShopImage from "../../static/stubs/shop-image-stub.webp";
import useSearchParamsFixed from "../../features/hooks/useSearchParamsFixed";
import changePage from "../../helpers/changePage";
import useAppContext from "../../useAppContext";
import { Coordinates } from "../../features/AddressInput/AddressInput";
import useIncustPayData from "../payments/hooks/useIncustPayData";
import { ApiUrl } from "../../config";

export function SelectedStoreContextProvider({ children }: { children: any }) {
	const {
		lang,
		loadingManager: { getIsLoading, setIsLoading },
		scanReceiptService,
	} = useAppContext();

	const { brandInfo } = useShopContext();

	const { storeId } = useParams();

	const [selectedStore, setSelectedStore] = useState<Store | null>(null);
	const [productsSearch, setProductsSearch] = useState<string | null>(null);

	const filtersService = useFiltersService();
	const [storeFilters, setStoreFilters] = useState<Characteristic[] | null>(null);

	const categoriesService = useCategoriesService(selectedStore, filtersService, productsSearch);
	const productsService = useProductsService(
		selectedStore,
		filtersService.filtersSetId,
		categoriesService,
		productsSearch,
		setProductsSearch
	);

	// const { resetProducts } = productsService;
	// const { setResetProductsFunc } = categoriesService;
	// useEffect(() => {
	// 	console.log("resetProducts", resetProducts);
	// 	setResetProductsFunc(resetProducts);
	// }, [resetProducts, setResetProductsFunc]);

	const favoritesService = useFavoritesService(selectedStore);
	// const friendsService = useFriendsService()

	const cartService = useCartService(selectedStore, categoriesService);
	const ordersCountService = useOrdersCountService(selectedStore);

	const shipmentsService = useShipmentsService(selectedStore?.id || null, lang);

	const computedShopImage = useMemo(() => {
		if (selectedStore?.banners && selectedStore?.banners.length > 0) {
			return `${ApiUrl}/${selectedStore?.banners[0].image_path}`;
		}
		return selectedStore?.image_url || brandInfo.image_url || ShopImage;
	}, [selectedStore?.image_url, brandInfo.image_url]);

	const computedCoordinates: Coordinates | null = useMemo(() => {
		if (selectedStore?.latitude && selectedStore?.longitude) {
			return {
				lat: parseFloat(selectedStore.latitude),
				lon: parseFloat(selectedStore.longitude),
			};
		}
		return null;
	}, [selectedStore?.latitude, selectedStore?.longitude]);

	useEffect(() => {
		if (!lang) return;

		const storeIdNum = storeId ? parseInt(storeId) : null;

		if (!storeIdNum) {
			changePage("/shop/select");
		} else {
			setIsLoading("selectedStore", true);
			api.shop.basic
				.getStore(storeIdNum, {
					brand_id: brandInfo.id,
				})
				.then(response => {
					setSelectedStore(response.data);
					api.shop.basic.saveLastStoreId(response.data.id);
				})
				.catch(err => {
					console.log(err);
					changePage("/shop/select");
				})
				.finally(() => {
					setIsLoading("selectedStore", false);
				});

			api.shop.basic.getFilters(storeIdNum, brandInfo.id).then(response => {
				if (response.status === 200 && response.data) {
					setStoreFilters(response.data);
				}
			});
		}
	}, [brandInfo.id, storeId, lang, setIsLoading]);

	const setStoreId = scanReceiptService.setStoreId;
	useEffect(() => {
		if (selectedStore?.id) {
			setStoreId(selectedStore.id);
		} else {
			setStoreId(null);
		}
	}, [setStoreId, selectedStore?.id]);

	useRemoveStoreIdParam();

	const isLoading = getIsLoading("selectedStore");

	const context: SelectedStoreContextType = {
		selectedStore,
		categoriesService,
		productsService,
		ordersCountService,
		filtersService,
		cartService,
		favoritesService,
		shipmentsService,
		computedShopImage,
		isLoading,
		storeFilters,
		// friendsService,
		computedCoordinates,
	};

	return (
		<SelectedStoreContext.Provider value={context}>{children}</SelectedStoreContext.Provider>
	);
}

function useRemoveStoreIdParam() {
	const [searchParams, setSearchParams] = useSearchParamsFixed();

	const storeIdParam = searchParams.get("store_id");
	useEffect(() => {
		setSearchParams(prev => {
			prev.delete("store_id");
			return prev;
		});
	}, [setSearchParams, storeIdParam]);
}

export function useSelectedStoreContextOptional(): Partial<SelectedStoreContextType> {
	return useContext(SelectedStoreContext) || {};
}

export function useSelectedStoreContextStoreOptional(): SelectedStoreContextType {
	const context = useContext(SelectedStoreContext);
	if (!context) {
		throw new Error("Use this method only inside SelectedStoreContextProvider");
	}
	return context;
}

export function useSelectedStoreContext(): SelectedStoreContextStoreLoadedType {
	const context = useSelectedStoreContextStoreOptional();
	const selectedStore = context.selectedStore;
	if (!selectedStore) {
		throw new Error(
			"Store is not specified. Please, be sure to use this method only after checking if store loaded"
		);
	}
	return {
		...context,
		selectedStore,
	};
}

export function useSelectedStoreOptional(): Store | null {
	const context = useSelectedStoreContextOptional();
	return context?.selectedStore || null;
}

export function useSelectedStore(): Store {
	const context = useSelectedStoreContext();
	return context.selectedStore;
}

export const SelectedStoreContext = createContext<SelectedStoreContextType | null>(null);

import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import {
	Box,
	FormControl,
	FormControlLabel,
	InputAdornment,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

import { IUseFloatingSumType } from "../hooks/useFloatingSum";
import { FloatingSumSettings, Product } from "../../../../api/shop/basic/types";
import TextField from "@mui/material/TextField";
import useAppContext from "../../../../useAppContext";
import useCurrency from "../../../../services/useCurrencyService";
import useCurrencyInfo from "../../../../hooks/useCurrencyInfo";
import { CartProduct } from "../../../../api/shop/cart/types";
import formatCurrency from "../../../../helpers/formatCurrency";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import Interweave from "../../../../features/Interweave";
import formatNumber from "../../../../helpers/formatNumber";

interface IProductFloatingSumRoot {
	floatingSum: IUseFloatingSumType;
	floatingSumSettings: FloatingSumSettings;
	cartProduct?: CartProduct | null;
	fromGiftOrTopup?: boolean;
	product: Product;
}

export default function ProductFloatingSumRoot(props: IProductFloatingSumRoot) {
	const background = "rgba(0, 0, 0, 0.015)";

	const {
		lang,
		localisation: { menu },
		brandInfo,
	} = useAppContext();
	const currency = useCurrency();
	const rootRef = useRef<HTMLDivElement | null>(null);
	const { cartService } = useSelectedStoreContext();

	const computedNeedOptions = useMemo(() => {
		return !!(
			props.floatingSumSettings.options && props.floatingSumSettings.options.length > 0
		);
	}, [props.floatingSumSettings.options]);

	const computedMinMaxText = useMemo(() => {
		if (props.floatingSumSettings.min && props.floatingSumSettings.max) {
			const minFormatted =
				props.product.type === "gift"
					? formatNumber(props.floatingSumSettings.min, brandInfo?.default_lang || lang)
					: formatCurrency(
							props.floatingSumSettings.min,
							brandInfo?.default_lang || lang,
							currency || ""
						);
			const maxFormatted =
				props.product.type === "gift"
					? formatNumber(props.floatingSumSettings.max, brandInfo?.default_lang || lang)
					: formatCurrency(
							props.floatingSumSettings.max,
							brandInfo?.default_lang || lang,
							currency || ""
						);

			return menu.floatingSumMinMaxText
				.replace("{min_sum}", minFormatted)
				.replace("{max_sum}", maxFormatted);
		}
		return "";
	}, [
		brandInfo?.default_lang,
		currency,
		lang,
		menu.floatingSumMinMaxText,
		props.floatingSumSettings.max,
		props.floatingSumSettings.min,
		props.product.type,
	]);

	const computedBackground = useMemo(() => {
		if (props.fromGiftOrTopup) return "transparent";
		return background;
	}, [props.fromGiftOrTopup]);

	const computedHeaderText = useMemo(() => {
		if (computedNeedOptions) {
			return menu.floatingSumOptionsHeader;
		} else if (computedNeedOptions && props.floatingSumSettings.user_sum_enabled) {
			return menu.floatingSumOptionsOrSumHeader;
		} else {
			return menu.floatingSumInputLabel + ":";
		}
	}, [
		computedNeedOptions,
		menu.floatingSumInputLabel,
		menu.floatingSumOptionsHeader,
		menu.floatingSumOptionsOrSumHeader,
		props.floatingSumSettings.user_sum_enabled,
	]);

	useEffect(() => {
		if (rootRef.current && cartService.productVariationsState?.isAddToCart) {
			rootRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, [props.floatingSumSettings, cartService.productVariationsState?.isAddToCart]);

	return (
		<Box
			sx={{ backgroundColor: computedBackground }}
			className={!props.fromGiftOrTopup ? "border-top" : "border-bottom"}
			ref={rootRef}
		>
			<Box px={props.fromGiftOrTopup ? 0 : 3}>
				<Typography variant={"h6"} fontWeight={"bold"} mb={1} pl={1}>
					{computedHeaderText}
				</Typography>
				{computedMinMaxText && !computedNeedOptions && (
					<Box mb={2}>
						<Typography
							variant={"body2"}
							sx={{ color: "var(--mui-palette-warning-light)", pl: 1 }}
						>
							<Interweave content={computedMinMaxText} />
						</Typography>
					</Box>
				)}
				{computedNeedOptions ? (
					<ProductFloatingSumOptions {...props} />
				) : (
					<ProductFloatingSumInput {...props} />
				)}
			</Box>
		</Box>
	);
}

interface IProductFloatingSumInput extends IProductFloatingSumRoot {
	fromOptions?: boolean;
}

function ProductFloatingSumInput(props: IProductFloatingSumInput) {
	const {
		lang,
		localisation: { menu },
		brandInfo,
	} = useAppContext();
	const currency = useCurrency();
	const { symbol, position } = useCurrencyInfo(brandInfo?.default_lang || lang, currency || "");
	const [internalInputValue, setInternalInputValue] = useState<number | null>(null);

	const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
		props.floatingSum.setErrorText(null);
		setInternalInputValue(+e.target.value || null);
		if (!e.target.value) return props.floatingSum.setFloatingSumAmount(0);

		if (props.floatingSumSettings.min && +e.target.value < props.floatingSumSettings.min) {
			props.floatingSum.setErrorText(menu.floatingSumMinError);
			props.floatingSum.setFloatingSumAmount(0);
		} else if (
			props.floatingSumSettings.max &&
			+e.target.value > props.floatingSumSettings.max
		) {
			props.floatingSum.setErrorText(menu.floatingSumMaxError);
			props.floatingSum.setFloatingSumAmount(0);
		} else {
			props.floatingSum.setFloatingSumAmount(+e.target.value);
		}
	};

	useEffect(() => {
		if (!internalInputValue && props.floatingSum.floatingSumAmount) {
			setInternalInputValue(props.floatingSum.floatingSumAmount);
		}
	}, [internalInputValue, props.floatingSum.floatingSumAmount]);

	return (
		<TextField
			fullWidth={true}
			required
			type={"number"}
			size={props.fromOptions ? "small" : "medium"}
			variant={props.fromOptions ? "standard" : "outlined"}
			sx={{
				mb: 2,
				flex: 1,
				px: props.fromOptions ? 0 : 1,
				".MuiFormHelperText-root": {
					ml: 1,
				},
			}}
			helperText={
				<Typography variant={"body2"} color={"error"}>
					{props.floatingSum.errorText}
				</Typography>
			}
			autoFocus={true}
			placeholder={menu.floatingSumInputLabel}
			value={internalInputValue || undefined}
			onChange={(e: ChangeEvent<HTMLInputElement>) => {
				e.preventDefault();
				handlePriceChange(e);
			}}
			InputProps={{
				inputProps: {
					min: 0.01,
					step: "0.01",
				},
				[`${position}Adornment`]:
					props.product.type !== "gift" ? (
						<InputAdornment
							position={position}
							sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
						>
							{symbol}
						</InputAdornment>
					) : (
						<></>
					),
			}}
		/>
	);
}

function ProductFloatingSumOptions(props: IProductFloatingSumRoot) {
	const {
		lang,
		localisation: { menu },
		brandInfo,
	} = useAppContext();
	const currency = useCurrency();
	const { symbol, position } = useCurrencyInfo(brandInfo?.default_lang || lang, currency || "");

	const [userInputOpen, setUserInputOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState<string>("");
	const inputRef = useRef<HTMLDivElement | null>(null);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const val = (event.target as HTMLInputElement).value;
		setSelectedOption(val);
		if (val === "user_input") {
			if (!props.cartProduct) {
				props.floatingSum.setFloatingSumAmount(0);
			}
			setUserInputOpen(true);
			return;
		}
		setUserInputOpen(false);
		props.floatingSum.setFloatingSumAmount(+val);
	};

	const setFloatingSumAmount = props.floatingSum.setFloatingSumAmount;
	useEffect(() => {
		if (!selectedOption) {
			if (props.cartProduct) {
				if (
					props.floatingSumSettings.options?.some(
						option => option === props.cartProduct?.floating_sum
					)
				) {
					setSelectedOption(props.cartProduct?.floating_sum?.toString() || "user_input");
				} else {
					setUserInputOpen(true);
					setSelectedOption("user_input");
				}
			} else {
				const value = props.floatingSumSettings.options
					? props.floatingSumSettings.options[0].toString()
					: "user_input";
				if (value === "user_input") {
					setUserInputOpen(true);
				} else {
					setFloatingSumAmount(+value);
				}
				setSelectedOption(value);
			}
		}
	}, [
		setFloatingSumAmount,
		props.floatingSum.floatingSumAmount,
		props.floatingSumSettings.options,
		props.cartProduct,
		selectedOption,
	]);

	useEffect(() => {
		if (userInputOpen && inputRef.current) {
			inputRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [userInputOpen]);

	return (
		<FormControl sx={{ pr: 1, pl: "8px" }}>
			<RadioGroup
				aria-labelledby="floating-price-radio-buttons-group-label"
				defaultValue="female"
				name="floatin-price-radio-buttons-group"
				onChange={handleChange}
				value={selectedOption}
				sx={{
					".MuiButtonBase-root": {
						p: "4px",
					},
				}}
			>
				{props.floatingSumSettings?.options?.map(option => (
					<FormControlLabel
						key={option}
						value={option.toString()}
						control={<Radio />}
						label={
							<Box>
								{props.product.type === "gift" ? (
									<>{option}</>
								) : (
									<>
										{position === "start" ? (
											<>
												{symbol} {option}
											</>
										) : (
											<>
												{option} {symbol}
											</>
										)}
									</>
								)}
							</Box>
						}
					/>
				))}
				{props.floatingSumSettings.user_sum_enabled && (
					<>
						<FormControlLabel
							value={"user_input"}
							control={<Radio />}
							sx={{
								".MuiFormControl-root": {
									mb: 0,
								},
								input: {
									p: 0,
								},
							}}
							label={
								<>
									{userInputOpen ? (
										<Box ref={inputRef}>
											<ProductFloatingSumInput
												{...props}
												fromOptions={true}
											/>
										</Box>
									) : (
										menu.floatingSumUserInputOptionHeader
									)}
								</>
							}
						/>
					</>
				)}
			</RadioGroup>
		</FormControl>
	);
}

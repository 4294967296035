import useLocalisation from "../../../../hooks/localisation/useLocalisation";
import { CartProduct } from "../../../../api/shop/cart/types";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import { Box, Button, ButtonOwnProps, IconOwnProps } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useProductQuantity from "../../../services/useCartService/hooks/useProductQuantity";
import DeleteProductsPopConfirmWrapper from "../DeleteProductsPopConfirm";
import { Product } from "../../../../api/shop/basic/types";
import useScreenService from "../../../../services/useScreenService";
import ProductQuantityInput from "./ProductQuantityInput";
import { useMemo } from "react";
import useAppContext from "../../../../useAppContext";

interface ProductQtyProps {
	cartProduct: CartProduct | null;
	iconSize?: IconOwnProps["fontSize"];
	textSize?: string;
	buttonSize?: ButtonOwnProps["size"];
	customClass?: string;
	productQty?: number;
	setProductQty?: (qty: number) => any;
	isSingleCartProduct?: boolean;
	isCard?: boolean;
	onlyDelete?: boolean;
	selectedProduct?: Product | null;
	setIsQtyInputVisible?: (isVisible: boolean) => void;
	isQtyInputVisible?: boolean;
	isCart?: boolean;
}

export default function ProductQty({
	cartProduct,
	textSize = "",
	iconSize,
	customClass = "",
	buttonSize,
	productQty,
	setProductQty,
	isSingleCartProduct = true,
	isCard = false,
	onlyDelete = false,
	selectedProduct = null,
	setIsQtyInputVisible = undefined,
	isQtyInputVisible = false,
	isCart = false,
}: ProductQtyProps) {
	const product = cartProduct?.product || null;

	const { cartService } = useSelectedStoreContext();
	const localisation = useLocalisation();
	const { isMobile } = useScreenService();

	const { computedQuantity, increaseQty, decreaseQty, updateQty } = useProductQuantity(
		cartProduct,
		isSingleCartProduct,
		productQty,
		product,
		setProductQty,
		isCard,
		selectedProduct
	);

	const needDelWithPopConfirmOrMinusButton = !!(
		cartProduct || cartService.addingProductWithNewAttributesId
	);

	const computedNeedDefaultMinusButton = useMemo(() => {
		return !!(
			cartService.addingProductWithNewAttributesId &&
			computedQuantity > 1 &&
			selectedProduct &&
			computedQuantity > selectedProduct.buy_min_quantity &&
			(!isQtyInputVisible || !isMobile)
		);
	}, [
		cartService.addingProductWithNewAttributesId,
		computedQuantity,
		isMobile,
		isQtyInputVisible,
		selectedProduct,
	]);

	const computedNeedDelWithPopConfirmButton = useMemo(() => {
		return !!(
			(computedQuantity === 1 ||
				computedQuantity === product?.buy_min_quantity ||
				(selectedProduct && computedQuantity === selectedProduct.buy_min_quantity)) &&
			(!isQtyInputVisible || !isMobile)
		);
	}, [computedQuantity, isMobile, isQtyInputVisible, product?.buy_min_quantity, selectedProduct]);

	return (
		<Box display={"flex"} height={"100%"} alignItems={"center"} className={customClass}>
			{needDelWithPopConfirmOrMinusButton ? (
				<DeletePopConfirmOrMinusButton
					computedNeedDefaultMinusButton={computedNeedDefaultMinusButton}
					computedNeedDelWithPopConfirmButton={computedNeedDelWithPopConfirmButton}
					cartProduct={cartProduct}
					product={product}
					decreaseQty={decreaseQty}
					buttonSize={buttonSize}
					iconSize={iconSize}
					localisation={localisation}
					isQtyInputVisible={isQtyInputVisible}
				/>
			) : (
				<DefaultDeleteOrMinusButton
					computedQuantity={computedQuantity}
					selectedProduct={selectedProduct}
					decreaseQty={decreaseQty}
					buttonSize={buttonSize}
					iconSize={iconSize}
					isQtyInputVisible={isQtyInputVisible}
				/>
			)}
			{!onlyDelete && (
				<>
					<ProductQuantityInput
						textSize={textSize}
						qty={computedQuantity}
						minValue={
							product?.buy_min_quantity || selectedProduct?.buy_min_quantity || 1
						}
						updateQty={updateQty}
						setIsQtyInputVisible={setIsQtyInputVisible}
						isQtyInputVisible={isQtyInputVisible}
						isCard={!isCart && isCard}
					/>
					{(!isQtyInputVisible || !isMobile) && (
						<Button
							size={buttonSize}
							variant={"outlined"}
							className={"px-1 mw-auto"}
							onClick={increaseQty}
						>
							<AddIcon fontSize={iconSize} aria-label={"plus"} />
						</Button>
					)}
				</>
			)}
		</Box>
	);
}

interface IMinusButton extends ProductQtyProps {
	decreaseQty: () => void;
}

function MinusButton(props: IMinusButton) {
	return (
		<Button
			size={props.buttonSize}
			variant={"outlined"}
			className={"px-1 mw-auto"}
			onClick={() => props.decreaseQty()}
		>
			<RemoveIcon fontSize={props.iconSize} aria-label={"minus"} />
		</Button>
	);
}

interface IDeletePopConfirmOrMinusButton {
	computedNeedDefaultMinusButton: boolean;
	computedNeedDelWithPopConfirmButton: boolean;
	cartProduct: CartProduct | null;
	product: Product | null;
	decreaseQty: () => void;
	buttonSize: ButtonOwnProps["size"];
	iconSize: IconOwnProps["fontSize"];
	localisation: ReturnType<typeof useLocalisation>;
	isQtyInputVisible: boolean;
}

function DeletePopConfirmOrMinusButton(props: IDeletePopConfirmOrMinusButton) {
	const { localisation } = useAppContext();
	const { isMobile } = useScreenService();

	return (
		<>
			{props.computedNeedDefaultMinusButton ? (
				<MinusButton {...props} decreaseQty={props.decreaseQty} />
			) : props.computedNeedDelWithPopConfirmButton ? (
				<DeleteProductsPopConfirmWrapper
					deleteSingle={true}
					cartProductId={props.cartProduct?.id}
					productId={props.product?.id}
				>
					<Button
						size={props.buttonSize}
						variant={"outlined"}
						className={"px-1 mw-auto h-100"}
					>
						<DeleteForeverIcon
							color={"error"}
							fontSize={props.iconSize}
							aria-label={localisation.cart.deleteFromCartButton}
						/>
					</Button>
				</DeleteProductsPopConfirmWrapper>
			) : (
				<>
					{(!props.isQtyInputVisible || !isMobile) && (
						<MinusButton {...props} decreaseQty={props.decreaseQty} />
					)}
				</>
			)}
		</>
	);
}

interface IDefaultDeleteOrMinusButton {
	computedQuantity: number;
	selectedProduct: Product | null;
	decreaseQty: () => void;
	buttonSize: ButtonOwnProps["size"];
	iconSize: IconOwnProps["fontSize"];
	isQtyInputVisible: boolean;
}

function DefaultDeleteOrMinusButton(props: IDefaultDeleteOrMinusButton) {
	const { localisation } = useAppContext();
	const { isMobile } = useScreenService();

	return (
		<Button
			size={props.buttonSize}
			variant={"outlined"}
			className={"px-1 mw-auto"}
			onClick={() => props.decreaseQty()}
		>
			{props.computedQuantity === 1 ||
			props.computedQuantity === props.selectedProduct?.buy_min_quantity ? (
				<DeleteForeverIcon
					color={"error"}
					fontSize={props.iconSize}
					aria-label={localisation.cart.deleteFromCartButton}
				/>
			) : (
				<>
					{(!props.isQtyInputVisible || !isMobile) && (
						<RemoveIcon fontSize={props.iconSize} aria-label={"minus"} />
					)}
				</>
			)}
		</Button>
	);
}

import { IIncustCoupon } from "../../../types";
import { AspectRatioType } from "../../../../../types";
import React, { RefObject } from "react";
import Typography from "@mui/material/Typography";
import { Markup } from "interweave";
import { Box, useColorScheme } from "@mui/material";
import { DefaultImageAspectRatio } from "../../../../../config";
import CardMediaAspectRatio from "../../../../../features/CardMediaAspectRatio";
import ImageStub from "../../../../../features/ImageStub";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import useAppContext from "../../../../../useAppContext";

export function CouponTitle(props: { coupon: IIncustCoupon }) {
	return (
		<Typography
			variant={"h6"}
			lineHeight={"normal"}
			fontWeight={"bold"}
			mb={1}
			sx={{
				opacity: props.coupon.status !== "redeemed" ? 100 : 65,
				wordBreak: "break-word",
			}}
		>
			<Markup className={"me-1 flex-1"} content={props.coupon.title} />
		</Typography>
	);
}

export function CouponExpireDate(props: { coupon: IIncustCoupon }) {
	const {
		localisation: { profile },
		lang,
	} = useAppContext();
	return (
		<Box>
			{props.coupon?.redeem_dt ? (
				<Typography color={"warning.main"} fontSize={"0.9rem"}>
					{profile.loyaltyCouponRedeemDate + ": "}
					{new Date(props.coupon.redeem_dt).toLocaleDateString(lang || "en", {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					})}
				</Typography>
			) : !!props.coupon.expire_date?.length ? (
				<Typography fontSize={"0.9rem"}>
					{profile.loyaltyCouponExpiredDate + ": "}
					{new Date(props.coupon.expire_date).toLocaleDateString(lang || "en", {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					})}
				</Typography>
			) : (
				<Typography fontSize={"0.9rem"}>{profile.loyaltyCouponPeriodNoLimit}</Typography>
			)}
		</Box>
	);
}

export function CouponCanUseWithOrder() {
	const {
		localisation: { profile },
	} = useAppContext();
	return (
		<Box>
			<Typography fontSize={"0.9rem"} sx={{ fontStyle: "italic" }}>
				* {profile.loyaltyCouponCanUseWithOrder}
			</Typography>
		</Box>
	);
}

export function CouponDescription(props: {
	descriptionRef: React.MutableRefObject<HTMLParagraphElement | null>;
	coupon: IIncustCoupon;
}) {
	return (
		<Box mt={2}>
			<Typography variant={"body2"} ref={props.descriptionRef} className={"description-box"}>
				<Markup content={props.coupon.description} />
			</Typography>
		</Box>
	);
}

type CouponImageProps = {
	coupon: IIncustCoupon;
	ratio?: AspectRatioType | null | undefined;
	productImageRef: RefObject<any>;
};

export function CouponImage(props: CouponImageProps) {
	const colorScheme = useColorScheme();
	const ratio = props.ratio || DefaultImageAspectRatio;

	return (
		<Box
			ref={props.productImageRef}
			className={"media"}
			sx={{
				width: { xs: "35%", sm: "30%", lg: "25%" },
				opacity: props.coupon.status === "redeemed" ? 100 : 65,
				pt: 1,
				pl: 2,
				pb: 1,
				float: "right",
			}}
		>
			{props.coupon.image ? (
				<CardMediaAspectRatio
					aspectRatio={"16/9"}
					imageSrc={props.coupon.image}
					alt={props.coupon.title || ""}
					sx={{ flex: "1 0 auto" }}
					className={"shop-product-card-image"}
				/>
			) : (
				<ImageStub
					emptyStub={false}
					ratio={ratio}
					node={
						<ConfirmationNumberIcon
							className={"shop-product-card-image"}
							sx={{
								fontSize: "7rem",
								color: `primary.${colorScheme.mode}`,
							}}
						/>
					}
				/>
			)}
		</Box>
	);
}

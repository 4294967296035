import { API } from "../api/base";
import { useQuery } from "@tanstack/react-query";
import { Country } from "react-phone-number-input";

interface CountryResponse {
	country: Country;
}

export default function useCountryByIP() {
	return useQuery({
		queryKey: ["country-by-ip"],
		queryFn: () => {
			return API.get<CountryResponse>("https://api.country.is");
		},
		select: result => result.data.country,
	});
}

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { IIncustCoupon } from "../../../types";
import { ContentCopy, Done } from "@mui/icons-material";
import useAppContext from "../../../../../useAppContext";

interface Props {
	codeForUseLabel: string;
	copyCouponLabel: string;
	coupon: IIncustCoupon;
}

export const CouponCodExternalPopup = (props: Props): React.JSX.Element => {
	const [isCopied, setIsCopied] = useState<boolean>(false);
	const { toastService, localisation } = useAppContext();

	const handleCopyToClipboard = () => {
		if (!!props.coupon.code) {
			try {
				navigator.clipboard.writeText(props.coupon.code);
				setIsCopied(true);
				setTimeout(() => setIsCopied(false), 5000);
				toastService.showToast({
					severity: "success",
					message: localisation.global.copiedCouponCode,
				});
			} catch (error) {
				console.log(error);
			}
		}
	};
	return (
		<Box
			component={motion.div}
			initial={{ opacity: 0, scale: 0 }}
			animate={{ opacity: 1, scale: 1 }}
			exit={{ opacity: 0, scale: 0 }}
			transition={{ delay: 0.8, duration: 0.3 }}
			display={"flex"}
			alignItems={"center"}
			mb={"5px"}
			mx={{ xs: "auto", sm: "unset" }}
			ml={{ sm: "auto" }}
			mt={{ xs: 2, sm: "unset" }}
			py={1}
			justifyContent={{ xs: "center", sm: "flex-end" }}
			width={"fit-content"}
			flexDirection={"column"}
		>
			<Typography
				fontSize={"m-large"}
				textAlign={{ xs: "center", sm: "end" }}
				ml={{ sm: "auto" }}
			>
				{props.codeForUseLabel}
			</Typography>
			<Box
				display={"flex"}
				width={"fit-content%"}
				ml={"auto"}
				justifyContent={{ xs: "center", sm: "flex-end" }}
				border={2}
				borderColor={"inherit"}
				borderRadius={"5px"}
			>
				<Typography
					fontSize={"x-large"}
					fontWeight={"700"}
					px={"5px"}
					variant={"body1"}
					color={"inherit"}
				>
					{props.coupon.external_code}
				</Typography>
				<Tooltip title={props.copyCouponLabel}>
					<IconButton size="small" color="inherit" onClick={handleCopyToClipboard}>
						{isCopied ? (
							<Done sx={{ fontSize: "18px" }} />
						) : (
							<ContentCopy sx={{ fontSize: "18px" }} />
						)}
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	);
};

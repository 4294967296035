import {useShopContext} from "../context"
import IncustLoyalty from "./components/IncustLoyalty/IncustLoyalty"
import {IUseIncustServiceType} from "../../features/services/useIncustService/types";


export interface ILoyaltyProps {
    needFullPageLoading?: boolean
    disableLogin?: boolean
    hidden?: boolean // If need to process loyalty data without view
    incustService: IUseIncustServiceType
}

export default function Loyalty({needFullPageLoading, disableLogin, hidden = false, incustService}: ILoyaltyProps){
    const {brandInfo} = useShopContext()

    if(!brandInfo.loyalty_info?.loyalty_enabled) return null

    return (<>
        {brandInfo.loyalty_info.loyalty_type === 'incust' && (
            <IncustLoyalty
                needFullPageLoading={needFullPageLoading}
                disableLogin={disableLogin}
                incustService={incustService}
                hidden={hidden}
            />
        )}
    </>)
}

import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import ProductQty from "../../menu/product/ProductQty/ProductQty";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import formatCurrency from "../../../helpers/formatCurrency";
import { CartItemProps } from "./types";
import { ApiUrl, availableNoImageLanguages } from "../../../config";
import classnames from "classnames";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import useAppContext from "../../../useAppContext";
import useScreenService from "../../../services/useScreenService";
import React, { useMemo } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Interweave from "../../../features/Interweave";
import { ProductControls } from "../../menu/product/ProductCardButtons";

export default function CartItem({ cartProduct, isEditable = true }: CartItemProps) {
	const {
		lang,
		groupService: { group },
		brandInfo,
	} = useAppContext();
	const { productsService, selectedStore, cartService } = useSelectedStoreContext();
	const { isMobile } = useScreenService();

	const product = cartProduct.product;
	const isAvailable = product.is_available;

	const localisation = useLocalisation();

	const handleEditClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		cartService.setSelectedVariationCartProductId(cartProduct.id);
		productsService.selectProduct(product, "attributes");
	};

	const attributesInfo = useMemo(() => {
		const attributes = cartProduct.cart_attributes;
		if (!attributes) {
			return null;
		}
		let count = 0;
		let sum = 0;

		for (const attr of attributes) {
			count += attr.quantity;
			sum += attr.attribute.price_impact * attr.quantity;
		}

		if (!count) return null;

		const attributesString = attributes
			.map(cpa => cpa.attribute.name + ` (${cpa.quantity * cartProduct.quantity})`)
			.join("<br/>");

		return {
			count: count * cartProduct.quantity,
			sum: sum * cartProduct.quantity,
			attrsString: attributesString,
		};
	}, [cartProduct.cart_attributes, cartProduct.quantity]);

	if (!selectedStore) return null;

	const weightText = `${cartProduct.quantity > 1 ? cartProduct.quantity + "x" : ""}${product.unit}`;
	const Price = () => {
		let baseClass = "";
		baseClass += "fw-bold fs-5";
		if (!isMobile) {
			baseClass += " text-end ";
		}

		return (
			<>
				<div className={baseClass + (isAvailable ? "" : "shop-cart-item-not-in-stock")}>
					{totalPrice}
				</div>

				{product.is_weight && (
					<div className={"fw-light small" + (isMobile ? " text-start" : " text-end")}>
						{weightText}
					</div>
				)}

				{!isEditable && !product.is_weight && (
					<div className={"fw-light small text-end ms-1 ms-sm-2"}>
						x{cartProduct.quantity}
					</div>
				)}
			</>
		);
	};

	const totalPrice = formatCurrency(
		cartService.calculateProductTotalPrice(cartProduct),
		brandInfo?.default_lang || lang,
		selectedStore.currency
	);

	const computedProductImageStub = () => {
		if (product.image_url) return product.image_url;
		if (availableNoImageLanguages.includes(lang ?? ""))
			return `${ApiUrl}/static/images/store/no-image-${lang}.png`;
		return `${ApiUrl}/static/images/store/no-image.png`;
	};

	return (
		<div className={"my-2 theme-text"}>
			<MDBRow className={"w-100 m-0 pb-2 shop-cart-item border-bottom"}>
				<MDBCol
					size={12}
					sm={"auto"}
					className={classnames(
						"p-0 flex-1 cursor-pointer d-flex",
						"align-items-center",
						{
							"shop-cart-item-not-in-stock": !isAvailable,
						}
					)}
					onClick={handleEditClicked}
				>
					<div className={"media"}>
						<img src={computedProductImageStub()} alt={product.name} />
					</div>
					<div className={"ms-2"}>
						<div className={"fw-bold fs-5"}>
							<Interweave content={product.name} />
							{isEditable && (
								<IconButton
									color={"primary"}
									sx={{ p: 1, fontSize: "inherit" }}
									onClick={handleEditClicked}
								>
									<EditIcon fontSize={"inherit"} />
								</IconButton>
							)}
						</div>
						{attributesInfo && group && lang && (
							<>
								<Typography
									variant={"body2"}
									display={"flex"}
									alignItems={"center"}
									color={"text.secondary"}
								>
									{group.texts.web.menu.cart.additions_text
										.replace("{count}", attributesInfo.count.toString())
										.replace(
											"{sum}",
											formatCurrency(
												attributesInfo.sum,
												brandInfo?.default_lang || lang,
												selectedStore.currency
											)
										)}
								</Typography>
								<Box>
									<Typography variant={"body2"} color={"text.secondary"}>
										<Interweave content={attributesInfo.attrsString} />
									</Typography>
								</Box>
							</>
						)}
					</div>
				</MDBCol>
				<MDBCol
					size={12}
					sm={"auto"}
					className={classnames(
						"p-0 d-flex align-items-center",
						"justify-content-end",
						"mt-2 mt-lg-none"
					)}
				>
					<Price />
					<div className={"ms-2"}>
						{isAvailable &&
							isEditable &&
							(product.floating_sum_settings?.is_enabled ||
							product.floating_qty_enabled ? (
								<ProductControls product={product} />
							) : (
								<ProductQty
									cartProduct={cartProduct}
									isCard={true}
									isCart={true}
									onlyDelete={!!cartProduct.floating_sum}
								/>
							))}
						<div className={"text-end"}>
							{!isAvailable && (
								<div className={"text-danger"}>
									{localisation.cart.notAvailable}
								</div>
							)}
						</div>
					</div>
				</MDBCol>
			</MDBRow>
		</div>
	);
}

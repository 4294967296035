import { MDBIcon } from "mdb-react-ui-kit";

import { TgLink } from "../../helpers/ThemeComponents";
import { useSelectedStore } from "../SelectedStore/context";
import { Box } from "@mui/material";
import useScreenService from "../../services/useScreenService";
import splitArray from "../../features/splitArray";
import { StoreCustomField } from "../../api/shop/basic/types";

interface SocialNetworksInfoProps {
	customClass?: string;
}

export default function SocialNetworksInfo({ customClass = "" }: SocialNetworksInfoProps) {
	const selectedStore = useSelectedStore();

	if (!Array.isArray(selectedStore.custom_fields)) return null;

	const socials = selectedStore.custom_fields.filter(
		field =>
			typeof field.value === "string" &&
			(field.value.includes("http") || field.value.includes("www"))
	);

	const { firstHalf, secondHalf } = splitArray(socials);

	return (
		<>
			<div>
				{socials.length <= 4 ? (
					<Box
						display={"flex"}
						sx={socials.length > 2 ? { justifyContent: "space-between" } : {}}
					>
						<SocialNetworksList list={socials} />
					</Box>
				) : (
					<>
						<div className={"d-inline-block"}>
							<div className={`d-flex flex-wrap ${customClass} align-items-center`}>
								<SocialNetworksList list={firstHalf} />
							</div>
						</div>
						<div className={"d-inline-block"}>
							<div className={`d-flex flex-wrap ${customClass} align-items-center`}>
								<SocialNetworksList list={secondHalf} />
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

interface ISocialNetworksListProps {
	list: StoreCustomField[];
}

function SocialNetworksList(props: ISocialNetworksListProps) {
	const { isMobile } = useScreenService();

	return (
		<>
			{props.list.map(
				field =>
					typeof field.value === "string" && (
						<Box key={field.name} sx={{ fontSize: isMobile ? "23px" : "22px" }}>
							<div className={"me-2"}>
								<TgLink
									href={field.value}
									target={"_blank"}
									rel={"noopener noreferrer"}
								>
									<MDBIcon fab icon={field.name} />
								</TgLink>
							</div>
						</Box>
					)
			)}
		</>
	);
}

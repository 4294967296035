import { useEffect, useMemo, useState } from "react";

import useAppContext from "../../../../useAppContext";
import { Product } from "../../../../api/shop/basic/types";
import useIncustService, {
	useIncustCheckItemsFromProducts,
} from "../../../../features/services/useIncustService";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import useCurrency from "../../../../services/useCurrencyService";
import { IIncustCheck } from "../../../loyalty/types";
import api from "../../../../api";
import { CreateOrderPayload, CreateOrderProduct, Order } from "../../../../api/shop/order/types";
import { IUseFloatingSumType } from "./useFloatingSum";
import { IUseIncustServiceType } from "../../../../features/services/useIncustService/types";
import formatNumber from "../../../../helpers/formatNumber";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

export default function useProductGift(
	product: Product | null,
	count: number,
	floatingSum: IUseFloatingSumType
): IUseProductGiftType {
	const {
		localisation: { global, menu },
		authService: { user, loadUser },
		brandInfo,
		lang,
		bot,
	} = useAppContext();
	const { shipmentsService } = useSelectedStoreContext();

	const [showGiftModal, setShowGiftModal] = useState(false);
	const [isSuccessBuy, setIsSuccessBuy] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [order, setOrder] = useState<Order | null>(null);
	const [userIsLoading, setUserIsLoading] = useState(false);

	const { categoriesService, selectedStore, productsService } = useSelectedStoreContext();

	const { customerData, isCustomerFetching } = useUserIncustCustomerData(selectedStore.id);

	const incustProducts = useMemo(() => {
		return product ? [product] : null;
	}, [product]);
	const getCategoryById = categoriesService.getCategoryById;
	const checkItems = useIncustCheckItemsFromProducts(
		incustProducts,
		count,
		getCategoryById,
		floatingSum?.floatingSumAmount || 0
	);

	const computedBonusesSum = useMemo(() => {
		if (product?.floating_sum_settings?.is_enabled) return floatingSum.floatingSumAmount;
		return product ? product.price * count : 0;
	}, [count, floatingSum.floatingSumAmount, product]);

	const currency = useCurrency();
	const incustService = useIncustService(
		brandInfo?.id,
		selectedStore.id,
		brandInfo?.incust_data,
		lang,
		currency || "",
		checkItems,
		null,
		true,
		true,
		"by-charge-only-rules",
		computedBonusesSum
	);
	const processedCheck = incustService.processedCheck;
	const isOnlyAddBonuses = incustService.isAddBonusesOnlyError && !!bot;

	const handleBuy = async () => {
		try {
			setIsLoading(true);
			const create_product: CreateOrderProduct = {
				quantity: count,
				product_id: product?.id || 0,
				attributes: [],
				floating_sum: product?.floating_sum_settings?.is_enabled
					? floatingSum.floatingSumAmount * 100
					: null,
			};

			const shipment = shipmentsService.noDeliveryTypeShipment;

			if (!shipment) {
				return setError("No delivery shipment not found");
			}

			const payload: CreateOrderPayload = {
				store_id: selectedStore.id,
				is_accepted_agreement: true,
				products: [create_product],
				shipment_method_id: shipment.id,
				loyalty_type: "incust",
				incust_check: processedCheck,
				price_after_loyalty: processedCheck?.amount_to_pay,
				bonuses_redeemed: processedCheck?.bonuses_redeemed_amount,
				discount: processedCheck?.discount_amount,
				incust_terminal_id: incustService.settings?.id,
				free_shipment: true,
				first_name: user?.first_name || "",
				last_name: user?.last_name || "",
				phone: "",
				price: null,
				type: "gift",
			};

			const response = await api.shop.order.createOrder(payload);
			setOrder(response.data);
			setIsSuccessBuy(true);
			setError(null);
			productsService.setHideDetailedProductModal(true);
		} catch (ex: any) {
			setError(ex?.response?.data?.detail || ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const computedButtonText = useMemo(() => {
		if (isSuccessBuy) return global.showWallet;
		return menu.giftActionButton;
	}, [menu.giftActionButton, global.showWallet, isSuccessBuy]);

	const computedModalTitle = useMemo(() => {
		return `${product?.name} (${menu.giftReceiving})`;
	}, [menu.giftReceiving, product?.name]);

	const computedBonusesSumText = useMemo(() => {
		return formatNumber(computedBonusesSum, brandInfo?.default_lang || lang);
	}, [brandInfo?.default_lang, computedBonusesSum, lang]);

	const computedNotEnoughBonuses = useMemo(() => {
		if (isCustomerFetching) return false;
		const bonuses = customerData?.user_card?.bonuses?.find(b => b.currency === currency);
		if (!bonuses) {
			return !!(product?.price && product?.price > 0);
		}
		return !!(computedBonusesSum && bonuses && bonuses.bonuses_amount < computedBonusesSum);
	}, [
		computedBonusesSum,
		currency,
		customerData?.user_card?.bonuses,
		isCustomerFetching,
		product?.price,
	]);

	const computedNeedAuth = useMemo(() => {
		return !user;
	}, [user]);

	const isGiftSomethingLoading = useMemo(() => {
		if (incustService.internalLoading) return true;
		if (isLoading) return true;
		if (incustService.processCheckLoading) return true;
		if (incustService.isCustomerFetching) return true;
		return userIsLoading;
	}, [
		incustService.internalLoading,
		incustService.processCheckLoading,
		isLoading,
		userIsLoading,
		incustService.isCustomerFetching,
	]);

	useEffect(() => {
		loadUser().then(() => setUserIsLoading(false));
	}, [loadUser]);

	return {
		showGiftModal,
		setShowGiftModal,
		isSuccessBuy,
		handleBuy,
		computedButtonText,
		error,
		isLoading,
		computedModalTitle,
		processedCheck,
		order,
		computedBonusesSumText,
		computedNotEnoughBonuses,
		computedNeedAuth,
		isOnlyAddBonuses,
		incustService,
		isGiftSomethingLoading,
	};
}

export interface IUseProductGiftType {
	showGiftModal: boolean;
	setShowGiftModal: (value: boolean) => void;
	isSuccessBuy: boolean;
	handleBuy: () => void;
	computedButtonText: string;
	error: string | null;
	isLoading: boolean;
	computedModalTitle: string;
	processedCheck: IIncustCheck | null | undefined;
	order: Order | null;
	computedBonusesSumText: string;
	computedNotEnoughBonuses: boolean;
	computedNeedAuth: boolean;
	isOnlyAddBonuses: boolean;
	incustService: IUseIncustServiceType;
	isGiftSomethingLoading: boolean;
}

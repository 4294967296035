import { useEffect, useMemo, useRef } from "react";

import api from "../../api";
import { LocalesType, LocaleType, LocalisationServiceType } from "./types";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { DEFAULT_LANG } from "../../config";

const getLocaleDefault = <T extends LocaleType>(locale: T): T => {
	return Object.fromEntries(Object.keys(locale).map(key => [key, ""])) as T;
};

const getLocalesDefault = <T extends LocalesType>(locales: T): T => {
	return Object.fromEntries(
		Object.entries(locales).map(([name, locale]) => [
			name,
			getLocaleDefault(locale as LocaleType),
		])
	) as T;
};

export function useLocalisationService<T extends LocalesType = LocalesType>(
	locales: T,
	brandId?: number | null | undefined,
	botId?: number | null | undefined,
	lang?: string | null | undefined,
	isBrandLoading?: boolean,
	isShortTokenLoading?: boolean
): LocalisationServiceType<T> {
	const defaultLocales = useMemo(() => {
		return getLocalesDefault(locales);
	}, [locales]);

	const { data: availableLangs } = useQuery({
		queryKey: ["availableLangs", brandId, botId],
		initialData: [],
		queryFn: () =>
			api.localisation
				.getAvailableLanguages({
					bot_id: botId,
					brand_id: brandId,
				})
				.then(response => response.data)
				.catch(err => {
					console.error("An error occurred while loading availableLangs", err);
					return [
						{
							code: DEFAULT_LANG,
							english_name: "English",
							original_name: "English",
							current_name: "English",
							is_auto_translate: false,
						},
					];
				}),
		enabled: !isBrandLoading && !isShortTokenLoading,
	});

	const { data: localised, isPending: isLocalisationPending } = useQuery<T>({
		queryKey: ["localised-texts", locales, lang],
		placeholderData: keepPreviousData,
		queryFn: async () => {
			const response = await api.localisation.getLocalisationDataSet<T>({
				data: locales,
				lang: lang,
			});
			return response.data;
		},
		enabled: !!lang,
	});

	const localisedRef = useRef<T>(localised || defaultLocales);

	useEffect(() => {
		localisedRef.current = localised || defaultLocales;
	}, [defaultLocales, localised]);

	return useMemo(
		() => ({
			...(localised || defaultLocales),
			availableLangs,
			isLoaded: !isLocalisationPending,
			localisedRef,
		}),
		[localised, defaultLocales, availableLangs, isLocalisationPending]
	);
}

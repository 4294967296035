export const AuthLocale = {
	logoutMessage: "web store logout message text",
	registerButton: "web auth register button",
	loginButton: "web auth login button",
	loginRegisterButton: "web auth login register button",
	newUserHeader: "web store registration header text",
	errAgreement: "web store reg agreement err text",
	errPassword: "web store reg password err text",
	errPasswords: "web store reg passwords err text",
	successRegistration: "web store success registration text",
	registrationFormHeader: "web store registration form header text",
	alreadyRegistered: "web store already registered text",
	emailAlreadySent: "web store email already sent text",
	errEmailConfirm: "web store email confirm err text",
	resendEmail: "web store send email again text",
	confirmEmailHeader: "web store confirm email header text",
	sentEmailHeader: "web store sent email header text",
	emailConfirmMessage: "web store email confirm message text",
	errEmailNoToken: "web store email confirm no token err text",
	emailConfirmHeader: "web store email confirm header text",
	emailConfirmSuccess: "web store email confirm success text",
	continueRegistration: "web store continue registration text",
	loginHeader: "web store login header text",
	loginWith: "web store login with text",
	noAccount: "web store no account text",
	tokenExpired: "web store token expired msg text",
	changeAccountHeader: "web store auth change account header",
	changeAccountMessage: "web store auth change account message",
	incustLoyaltyAgreement: "web store registration incust loyalty agreement",
	existingAccount: "web store auth existing account msg",
	getTgSuccess: "web store auth success get tg id msg",
	needEmail: "web store auth need email msg",
	needEmailErr: "web store auth need email msg err",
	needTgMsg: "web store auth need tg id msg",
	needTgErr: "web store auth need tg msg err",
	existingAccountIncust: "web store auth exists incust account msg",
	loginWithIncust: "web store auth login with incust",
	registerWithIncust: "web store auth register with incust",
	registerViaIncustHeader: "web store auth register with incust header",
	loginWithIncustHeader: "web store auth login with incust header",
	loginWithExternalService: "web login with external service text",
	loginWithEmailAndPassword: "web login with email and password text",
	emailLabel: "web auth email label",
	loginPasswordLabel: "web auth login password label",
	registerPasswordLabel: "web auth register password label",
	registerRepeatPasswordLabel: "web auth register repeat password label",
	registerFirstNameLabel: "web auth register first name label",
	registerLastNameLabel: "web auth register last name label",
	continueButton: "web auth continue button",
	orText: "web auth or text",
	authorisationModalHeader: "web authorisation modal header",
	loginModalHeader: "web auth login modal header",
	registerModalHeader: "web auth register modal header",
	continueWithTelegram: "web auth continue with telegram text",
	continueWithWhatsapp: "web auth continue with whatsapp text",
	continueWithGoogle: "web auth continue with google text",
	continueWithApple: "web auth continue with apple text",
	continueWithInCust: "web auth continue with incust text",
	changeEmailButton: "web auth change email button",
	changeAuthMethodButton: "web change auth method button",
	unknownError: "web auth unknown error",
	telegramRegisterModalHeader: "web auth telegram register modal header",
	incustRegisterModalHeader: "web auth incust register modal header",
	messangerNeededForLoyaltyError: "web auth messanger needed for loyalty error",
	passwordsDoNotMatchError: "web auth passwords do not match error",
	continueWithoutLoyaltyButton: "continue without loyalty button",
	telegramLoginAuthorisedText: "auth telegram login authorised text",
	whatsappLoginAuthorisedText: "auth whatsapp login authorised text",
	messangerLoginOkButton: "auth external login ok button",
	resetPasswordHeader: "web app auth reset password header",
	resetPasswordInfo: "web app auth reset password info sub header",
	resetPassEmailOrTokenErr: "web app auth reset password no email or token error",
	resetPassSuccess: "web app auth reset password success",
	resetPassSubHeader: "web app auth reset password sub header",
	newPasswordLabel: "web auth new password label",
	repeatNewPasswordLabel: "web auth repeat new password label",
	forgotPass: "web app auth forgot password button",
	resetPasswordInfoSent: "web app auth reset password info sub after header",
	changeEmailHeader: "web app auth change email header",
	emailChangedSuccess: "web app auth change email success",
	emailChangeSubHeader: "web app auth change email sub header",
	emailSetSubHeader: "web app auth set email sub header",
	changePasswordHeader: "web app auth change password header",
	changeEmailExistErr: "web app auth change email exist error",
	changeEmailConfirmSentTitle: "web app auth change email confirm email sent success title",
	changeEmailConfirmSent: "web app auth change email confirm email sent success",
	newEmailLabel: "web auth new email label",
	changePasswordSuccess: "web app auth change password success",
	agreementText: "web auth agreement text",
	agreementAndOfferText: "web auth agreement and offer text",
	linkUnlinkHeader: "auth link unlink messanger header",
	linkMessanger: "auth link messanger header",
	unlinkMessanger: "auth unlink messanger header",
	linkNewMessangerSubHeader: "auth link new messanger sub header",
	unlinkConfirmPassword: "auth confirm password to unlink header",
	changeMessanger: "auth change messanger header",
	alreadyLinked: "auth messanger already linked header",
	cantCheckAdminOrManager: "auth link unlink manager or admin request error",
	isManagerOrAdmin: "auth link unlink manager or admin warning",
	linkErr: "auth link error",
	birthLabel: "auth birth date label",
	openOAthFormError: "auth google non oauth error",
	oauthLoadingText: "auth oauth loading text",
	oauthConsentText: "auth oauth consent header text",
	chooseAnotherAuth: "auth choose another auth method button",
	oauthConsentPageError: "web app oauth consent page error",
	marketingConsent: "web app marketing checkbox text",
	marketingDecline: "web app marketing decline text",
	marketingDeclineMessage: "web app marketing decline message",
	agreementAndOfferInfoText: "web app agreement and offer info text",
	agreementInfoText: "web app agreement info text",
	agreementAndOfferInfoUnauthText: "web app agreement and offer info unauth text",
	agreementInfoUnauthText: "web app agreement info unauth text",
};

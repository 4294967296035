import {Box, Grid, InputAdornment, Tooltip} from "@mui/material";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import useAppContext from "../../../../../../useAppContext";
import {TgLink} from "../../../../../../helpers/ThemeComponents";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import useLocale from "../../../../../../hooks/localisation/useLocale";
import {SelectedShipmentServiceType} from "../../../useSelectedShipmentService";
import {SetMergeState} from "../../../../../../helpers/useMergedState";
import {CreateOrderPayload} from "../../../../../../api/shop/order/types";
import Interweave from "../../../../../../features/Interweave";

interface IShippingCourierCommentProps {
    selectedShipmentService: SelectedShipmentServiceType
    setForm: SetMergeState<CreateOrderPayload>
    form: CreateOrderPayload
}

export default function ShippingCourierComment(props: IShippingCourierCommentProps){
    const locale = useLocale('orders')

    const handleAddressCommentChanged = (e: any) =>
        props.setForm({address_comment: e.target.value})

    return (
        <Grid item xs={12}>
            {!props.selectedShipmentService.showAddressCommentInput && (
                <AddCourierCommentButton
                    setShowAddressInput={props.selectedShipmentService.setShowAddressCommentInput}
                />
            )}
            {props.selectedShipmentService.showAddressCommentInput && (
                <TextField
                    sx={{mt: 2}}
                    maxRows={10}
                    multiline
                    size={'small'} fullWidth
                    id={'address_comment'}
                    label={locale.courierLabel}
                    value={props.form.address_comment || ""}
                    onChange={(e) => handleAddressCommentChanged(e)}
                    InputProps={{endAdornment:
                        <InputAdornment
                            className={'cursor-pointer'}
                            position={'end'}
                            onClick={() => {
                                props.setForm({address_comment: ''})
                                props.selectedShipmentService.setShowAddressCommentInput(false)
                            }}
                        >
                            <ClearIcon />
                        </InputAdornment>,
                        "aria-valuemin": 0,
                        }}
                />
            )}
        </Grid>
    )
}

interface IAddressCommentButtonProps {
    setShowAddressInput: (value: boolean) => void
}

function AddCourierCommentButton(props: IAddressCommentButtonProps){
    const {localisation: {orders}} = useAppContext()

    return (
        <Box display={'flex'} pl={1} mt={1} fontSize={'0.85rem'} alignItems={'center'}>
            <TgLink
                className={'cursor-pointer me-1'}
                onClick={() => props.setShowAddressInput(true)}
            >
                {orders.courierButton}
            </TgLink>
            <Tooltip
                enterTouchDelay={0}
                title={<Interweave content={orders.courierTooltip} />}
            >
                <HelpOutlineOutlinedIcon sx={{color: 'primary.main'}} fontSize={'small'} className={'cursor-pointer'} />
            </Tooltip>
        </Box>
    )
}

import { KeyboardEvent, MouseEvent } from "react";
import { BaseTextFieldProps, IconButton, InputAdornment } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/system";
import { ChangeEvent } from "react";

interface IQuantityInputProps {
	sx?: SxProps;
	onBlur?: () => void;
	onChange: (value: number) => void;
	defaultValue: number;
	needEndAdornment: boolean;
	onEndAdornmentClick?: (e: MouseEvent<HTMLButtonElement>) => void;
	onKeyUp?: (e: KeyboardEvent<HTMLDivElement>) => void;
	size?: "small" | "medium" | undefined;
	placeholder?: string;
	textFieldProps?: BaseTextFieldProps;
}

export default function QuantityInput(props: IQuantityInputProps) {
	return (
		<TextField
			{...(props.textFieldProps ? props.textFieldProps : {})}
			placeholder={props.placeholder ? props.placeholder : props.defaultValue.toString()}
			sx={props.sx ? props.sx : {}}
			size={props.size}
			onBlur={() => props.onBlur && props.onBlur()}
			autoFocus={true}
			defaultValue={props.defaultValue}
			onChange={(e: ChangeEvent<HTMLInputElement>) => {
				props.onChange(parseInt(e.target.value));
			}}
			type={"number"}
			InputProps={
				props.needEndAdornment
					? {
							endAdornment: (
								<InputAdornment position={"end"}>
									<IconButton
										onClick={e =>
											props.onEndAdornmentClick &&
											props.onEndAdornmentClick(e)
										}
										color={"primary"}
										aria-label={"toggle password visibility"}
										onMouseDown={event => event.preventDefault()}
									>
										<CheckIcon />
									</IconButton>
								</InputAdornment>
							),
						}
					: undefined
			}
			onKeyUp={e => {
				props.onKeyUp && props.onKeyUp(e);
			}}
		/>
	);
}

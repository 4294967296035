import formatCurrency from "../../../../helpers/formatCurrency";
import useAppContext from "../../../../useAppContext";
import useCurrency from "../../../../services/useCurrencyService";

interface IDiscountAmountProps {
	discount: number;
}

export default function DiscountAmount(props: IDiscountAmountProps) {
	const { lang, localisation, brandInfo } = useAppContext();
	const currency = useCurrency();

	if (!currency) return null;

	return (
		<div className={"d-flex"}>
			<div>{localisation.profile.loyaltyDiscount}:</div>
			<div className={"ms-auto"}>
				-
				{formatCurrency(
					props.discount.toFixed(2),
					brandInfo?.default_lang || lang,
					currency
				)}
			</div>
		</div>
	);
}

import { useCallback, useState } from "react";
import { ThemeModal } from "../../../../helpers/ThemeComponents";
import { Alert, Button, Typography } from "@mui/material";
import f from "../../../../helpers/formatText";
import ExternalLoginWidget from "../../../../auth/Autorisation/external/ExternalLoginWidget";
import Divider from "@mui/material/Divider";
import useAppContext from "../../../../useAppContext";
import Authorisation from "../../../../auth/Autorisation/Authorisation";
import useAuthorisationForm from "../../../../auth/Autorisation/useAuthorisationForm";
import { ExternalLogin } from "../../../../api/auth/types";
import { getErrorText } from "../../../../helpers/errors";
import useExternalLoginExtraData from "../../../../auth/Autorisation/external/useExternalLoginExtraData";
import useAuthSettings from "../../../../services/useAuthSettings";
import useAuthorisationModalTitle from "../../../../auth/Autorisation/useAuthorisationModalTitle";

interface ICouponAuthProps {
	show: boolean;
	setShow: (value: boolean) => void;
	showEmail: boolean;
	setShowEmail: (value: boolean) => void;
}

export default function CouponAuth(props: ICouponAuthProps) {
	const {
		localisation: { receipt, global, auth },
		bot,
		authService: { onLogin, user },
		brandInfo,
	} = useAppContext();
	const authorisationForm = useAuthorisationForm();

	const [showExternal, setShowExternal] = useState(false);
	const [authError, setAuthError] = useState<string | null>(null);

	const extraData = useExternalLoginExtraData();

	const authSettings = useAuthSettings(brandInfo?.group_id);

	const onChange = useCallback(
		(data: ExternalLogin) => {
			if (data.status === "success") {
				if (data.logged_in_token_data) {
					onLogin(data.logged_in_token_data);
				}
			}
			if (data.status === "error") {
				setAuthError(receipt.scanReceiptExternalLoginError);
				setShowExternal(false);
			}
		},
		[onLogin, receipt.scanReceiptExternalLoginError]
	);

	const onError = useCallback(
		(err: any) => {
			const errText = err === null ? null : getErrorText(err, auth.unknownError);
			if (!!errText) return setAuthError(errText);
		},
		[auth.unknownError]
	);

	const authModalTitle = useAuthorisationModalTitle(authorisationForm.state);

	return (
		<>
			<ThemeModal
				maxWidth={"xs"}
				open={props.show}
				setOpen={props.setShow}
				title={auth.authorisationModalHeader}
				onClose={() => {
					props.setShowEmail(false);
					setShowExternal(false);
					setAuthError(null);
				}}
			>
				<Typography variant={"body2"} fontWeight={"bold"} sx={{ mb: 2 }}>
					{global.defaultNeedAuthHeader}
				</Typography>

				{authError && (
					<Alert severity={"error"} sx={{ mb: 2 }}>
						{authError}
					</Alert>
				)}

				{!!bot && authSettings.settings?.is_auth_messanger_enabled && (
					<>
						<Button
							variant={"contained"}
							onClick={() => setShowExternal(true)}
							fullWidth={true}
						>
							{f(receipt.scanReceiptContinueWith, {
								messanger: global[`${bot.bot_type}MessangerName`],
							})}
						</Button>
						{(authSettings.settings?.is_auth_email_enabled ||
							authSettings.computedIsAnyOAuthEnabled) && (
							<Divider sx={{ my: 2 }}>{auth.orText}</Divider>
						)}
					</>
				)}
				{(authSettings.computedIsAnyOAuthEnabled ||
					authSettings.settings?.is_auth_email_enabled) && (
					<Button
						fullWidth={true}
						variant={"outlined"}
						onClick={() => props.setShowEmail(true)}
					>
						{(authSettings.settings?.is_auth_email_enabled &&
							!authSettings.computedIsAnyOAuthEnabled) ||
						!bot
							? f(receipt.scanReceiptContinueWith, { messanger: "Email" })
							: auth.chooseAnotherAuth}
					</Button>
				)}
			</ThemeModal>

			<ThemeModal
				open={showExternal}
				setOpen={setShowExternal}
				title={
					!!bot
						? f(receipt.scanReceiptContinueWith, {
								messanger: global[`${bot?.bot_type}MessangerName`],
							})
						: ""
				}
			>
				{!!bot && authSettings.settings?.is_auth_messanger_enabled && (
					<ExternalLoginWidget
						type={bot.bot_type}
						purpose={"auth"}
						onChange={onChange}
						bot_id={bot.id}
						onError={onError}
						continue_url={window.location.href}
						extra_data={extraData}
					/>
				)}
			</ThemeModal>

			<ThemeModal
				open={props.showEmail}
				setOpen={props.setShowEmail}
				title={authModalTitle}
				fullWidth
				keepMounted={!user}
				maxWidth={authorisationForm.state === "emailRegistration" ? "sm" : "xs"}
				sx={{ zIndex: 999999 }}
			>
				{(!user || props.showEmail) && (
					<Authorisation
						form={authorisationForm}
						disabledMessengerExternalLogin={!!bot}
						onLoginCallback={() => {
							props.setShowEmail(false);
							props.setShow(false);
						}}
					/>
				)}
			</ThemeModal>
		</>
	);
}

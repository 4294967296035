import { useParams } from "react-router-dom";

import Invoice from "./Invoice";
import { Container, Paper } from "@mui/material";

export default function InvoicePage() {
	const { id } = useParams();

	return (
		<Container maxWidth={"sm"}>
			<Paper elevation={3} sx={{ p: 3 }}>
				<Invoice id={id} />
			</Paper>
		</Container>
	);
}

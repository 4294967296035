export const ExternalLoginWidgetLocale = {
	instructionText: "auth external login widget instruction text",
	refreshLinkButton: "auth external login refresh link button",
	getNewLinkButton: "auth external login get new link button",
	unableLoadLinkError: "auth external login unable load link error",
	finishProcessText: "auth external login finish process text",
	canceledText: "auth external login canceled text",
	instructionQrText: "auth external login widget instruction qr text",
	refreshQrButton: "auth external login refresh qr button",
};

import { Interweave as BaseInterweave, InterweaveProps as BaseInterweaveProps } from "interweave";
import {
	EmailMatcher,
	HashtagMatcher,
	HashtagProps,
	UrlMatcher,
	UrlProps,
} from "interweave-autolink";
import { Link } from "@mui/material";
import { useCallback } from "react";

interface InterweaveProps extends BaseInterweaveProps {
	defaultLinkColor?: string;
	defaultNewWindow?: boolean;
	openLinkInExtBrowser?: boolean;
}

export default function Interweave({
	allowAttributes = true,
	allowElements = true,
	matchers = [],
	transform,
	tagName,
	noWrap,
	openLinkInExtBrowser = false,
	defaultNewWindow = false,
	defaultLinkColor = "primary.main",
	...props
}: InterweaveProps) {
	const urlFactory = useCallback(
		({
			children,
			newWindow,
			...props
		}: Omit<URLMatcherFactoryProps, "openLinkInExtBrowser">) => (
			<URLMatcherFactory
				openLinkInExtBrowser={openLinkInExtBrowser}
				defaultLinkColor={defaultLinkColor}
				newWindow={defaultNewWindow || newWindow}
				{...props}
			>
				{children}
			</URLMatcherFactory>
		),
		[defaultLinkColor, defaultNewWindow, openLinkInExtBrowser]
	);

	return (
		<BaseInterweave
			noWrap={noWrap || (noWrap === undefined && !!tagName)}
			allowAttributes={allowAttributes}
			allowElements={allowElements}
			matchers={[
				new UrlMatcher("url", { validateTLD: false }, urlFactory),
				new HashtagMatcher("hashtag", undefined, urlFactory),
				new EmailMatcher("email", undefined),
				...matchers,
			]}
			transform={(node, children, config) => {
				const result = transform ? transform(node, children, config) : undefined;

				if (result !== undefined) {
					return result;
				}

				if (node.tagName === "A") {
					let linkColor: string = defaultLinkColor;
					const classListArray = [...node.classList.values()];

					classListArray.forEach(className => {
						if (className.startsWith("color-")) {
							linkColor = className.slice(6);
							node.classList.remove(className);
						}
					});

					const props = Object.fromEntries(
						Object.values(node.attributes).map(attr => [attr.name, attr.value])
					);
					if ("class" in props) {
						props.className = props.class;
						delete props.class;
					}

					if (props.target === undefined && defaultNewWindow) {
						props.target = "_blank";
					}

					return (
						<Link
							color={linkColor}
							{...props}
							onClick={e => {
								if (openLinkInExtBrowser && !!window.Telegram?.WebApp.openLink) {
									e.preventDefault();
									window.Telegram.WebApp.openLink(props.href);
								}
							}}
						>
							{children}
						</Link>
					);
				}
			}}
			{...props}
		/>
	);
}

interface URLMatcherFactoryProps
	extends Omit<UrlProps, "urlParts" | "url">,
		Omit<HashtagProps, "hashtag"> {
	url?: string;
	email?: string;
	hashtag?: string;
	urlParts?: UrlProps["urlParts"];
	defaultLinkColor?: string;
	openLinkInExtBrowser: boolean;
}

function URLMatcherFactory({
	children,
	url,
	hashtag,
	email,
	href: hrefProps,
	newWindow,
	onClick,
	openLinkInExtBrowser,
	defaultLinkColor = "primary",
}: URLMatcherFactoryProps) {
	const href = hrefProps || url || hashtag || `${email}`;

	return (
		<Link
			color={defaultLinkColor}
			href={href}
			target={newWindow ? "_blank" : undefined}
			onClick={e => {
				onClick && onClick();
				if (openLinkInExtBrowser && !!window.Telegram?.WebApp.openLink) {
					e.preventDefault();
					window.Telegram.WebApp.openLink(href || url);
				}
			}}
		>
			{children}
		</Link>
	);
}

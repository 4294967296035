import {HTMLAttributes} from "react";


export default function ChatMessageDate({children, className, ...props}: HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={`date ${className}`} {...props}>
            <span>{children}</span>
        </div>
    )
}

import {useEffect, useRef, useState} from "react";
import {TgButton} from "../../helpers/ThemeComponents";
import {useShopContext} from "../context";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import useScreenService from "../../services/useScreenService";
import Interweave from "../../features/Interweave";

export default function CookiesAgreement() {
    const localisation = useLocalisation()
    const {
        webAppData,
    } = useShopContext()
    const {isMobile} = useScreenService()

    const [show, setShow] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement | null>(null)

    const handleAgreeClick = () => {
        updateMiniCartWrapperStyle( null)
        localStorage.setItem('cookies-agreement', "1")
        setShow(false)
    }

    const AgreeButton = () => {
        return (
            <TgButton size={'small'} onClick={handleAgreeClick}>
                {localisation.global.cookiesAgreeButton}
            </TgButton>
        )
    }

    const updateMiniCartWrapperStyle = (bottomValue: string | null) => {
        let styleElement: HTMLStyleElement | null

        styleElement = document.getElementById('cookies-agreement-bottom-buttons') as HTMLStyleElement | null
        if (!styleElement) {
            styleElement = document.createElement("style");
            styleElement.id = 'cookies-agreement-bottom-buttons'
            document.head.appendChild(styleElement)
        }

        if (bottomValue) {
            styleElement.textContent = `
              .shop-bottom-buttons {
                bottom: ${bottomValue} !important;
              }
            `
        } else {
            styleElement.remove()
        }
    }

    useEffect(() => {
        const isAgreed = localStorage.getItem('cookies-agreement')
        if(!isAgreed && !webAppData){
            setShow(true)
        }
    }, [])

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                if (ref.current) {
                    updateMiniCartWrapperStyle((ref.current.clientHeight) + 'px')
                }
            }, 100)
        }
    });

    if (!show) return null

    return (
        <div
            ref={ref}
            className={
                `cookies-banner show small
                 d-flex justify-content-center 
                 align-items-center theme-bg-paper p-2`
            }
        >
            <div>
                <div className={'text-center theme-text'}>
                    {localisation.global.cookiesBannerText}
                        {' '}
                        <Interweave
                            content={localisation.global.cookiesBannerPolicy}
                        />
                </div>
                {isMobile &&
                    <div className={'text-center mt-2'}>
                        <AgreeButton/>
                    </div>
                }
            </div>
            {!isMobile &&
                <div className={'ms-2'}>
                    <AgreeButton/>
                </div>
            }
        </div>
    )
}

import { useCallback, useState } from "react";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import { IconButton } from "@mui/material";

import useAppContext from "../../useAppContext";
import { Coordinates } from "./AddressInput";

interface IFindLocationProps {
	service: IUseFindLocationType;
}

export default function FindLocation(props: IFindLocationProps) {
	return (
		<IconButton size={"small"} onClick={props.service.getAndSetLocation}>
			<MyLocationOutlinedIcon fontSize={"small"} />
		</IconButton>
	);
}

export function useFindLocation(): IUseFindLocationType {
	const {
		showError,
		localisation: { maps },
	} = useAppContext();
	const [userLocationCoords, setUserLocationCoords] = useState<Coordinates | null>(null);

	const handleLocationError = useCallback(
		(browserHasGeolocation: boolean) => {
			const msg = browserHasGeolocation
				? maps.geoLocationServiceError
				: maps.geoLocationBrowserError;
			showError(msg);
		},
		[showError, maps.geoLocationBrowserError, maps.geoLocationServiceError]
	);

	const getAndSetLocation = useCallback(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position: GeolocationPosition) => {
					setUserLocationCoords({
						lat: position.coords.latitude,
						lon: position.coords.longitude,
					});
				},
				() => {
					// @ts-ignore
					handleLocationError(true, infoWindow, map?.getCenter()!);
				}
			);
		} else {
			handleLocationError(false);
		}
	}, [handleLocationError]);

	return { userLocationCoords, setUserLocationCoords, getAndSetLocation };
}

export interface IUseFindLocationType {
	userLocationCoords: Coordinates | null;
	setUserLocationCoords: (value: Coordinates | null) => void;
	getAndSetLocation: () => void;
}

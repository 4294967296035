import { Alert, Box, Button, TextField } from "@mui/material";
import ConsentWrapper from "../../../../../../auth/Autorisation/inputs/ConsentWrapper";
import AgreementCheckbox from "../../../../../../auth/Autorisation/inputs/AgreementCheckbox";
import { ConfirmationComponentProps } from "../../types";
import MarketingCheckbox from "../../../../../../auth/Autorisation/inputs/MarketingCheckbox";
import useAppContext from "../../../../../../useAppContext";
import ConsentInfo from "../../../../../../auth/Autorisation/inputs/ConsentInfo";
import { useEffect, useMemo } from "react";
import { LoadingButton } from "@mui/lab";
import Interweave from "../../../../../../features/Interweave";

export default function ConfirmationComponent(props: ConfirmationComponentProps) {
	const {
		brandInfo,
		localisation,
		authService: { user },
	} = useAppContext();

	const isPayDisabled = useMemo(() => {
		const baseIsDisabled =
			!(props.invoiceTemplate?.items.length || props.form.entered_amount) ||
			props.paymentService.isLoading ||
			props.incustService.processCheckLoading;

		if (brandInfo?.consent_mode === "info") {
			return baseIsDisabled;
		} else if (brandInfo?.consent_mode === "one_time" && user?.marketing_consent) {
			if (user && user.is_accepted_agreement) {
				return baseIsDisabled;
			}
		}
		return !props.form.is_accepted_agreement || baseIsDisabled;
	}, [
		brandInfo?.consent_mode,
		props.form.entered_amount,
		props.form.is_accepted_agreement,
		props.incustService.processCheckLoading,
		props.invoiceTemplate?.items.length,
		props.paymentService.isLoading,
		user,
	]);

	const setForceSingleMethod = props.paymentService.setForceSingleMethod;
	useEffect(() => {
		let methods = props.paymentService.methods.filter(method => method.is_online);
		if (!user) {
			methods = methods.filter(method => method.provider !== "friend");
		}
		if (methods.length === 1) {
			if (methods[0].is_online) setForceSingleMethod(methods[0]);
		}
	}, [props.paymentService.methods, setForceSingleMethod, user]);

	return (
		<Box sx={{ p: 3, pt: 2 }}>
			{(!props.invoiceTemplate || props.invoiceTemplate.comment_mode !== "disabled") && (
				<TextField
					multiline
					maxRows={10}
					size={"small"}
					fullWidth
					required={props.invoiceTemplate?.comment_mode === "required"}
					label={
						props.invoiceTemplate?.comment_label || localisation.payment.commentLabel
					}
					value={props.form.user_comment || ""}
					onChange={e => props.setForm({ user_comment: e.target.value })}
					sx={{ mb: 2 }}
				/>
			)}

			<ConsentWrapper>
				<>
					<AgreementCheckbox
						value={props.form.is_accepted_agreement || false}
						setValue={newValue => props.setForm({ is_accepted_agreement: newValue })}
						isOfferEnabled={brandInfo?.is_offer_doc_exist}
					/>
					{!!user &&
						(!user.marketing_consent || brandInfo?.consent_mode === "per_order") && (
							<MarketingCheckbox
								value={!!props.form.marketing_consent}
								setValue={newValue =>
									props.setForm({ marketing_consent: newValue })
								}
							/>
						)}
				</>
			</ConsentWrapper>

			{props.errorText && (
				<Alert severity={"error"} variant={"filled"}>
					<Interweave content={props.errorText} />
				</Alert>
			)}

			<Box
				sx={{
					mt: 2,
					display: "flex",
					gap: 1,
					"& > button": {
						flex: 1,
					},
				}}
			>
				{!!props.goBack && (
					<Button size={"small"} variant={"outlined"} onClick={props.goBack}>
						{localisation.global.back}
					</Button>
				)}
				<LoadingButton
					loading={props.isLoading}
					disabled={isPayDisabled}
					variant={"contained"}
					type={"submit"}
				>
					{localisation.orders.payButton}
				</LoadingButton>
			</Box>

			<ConsentInfo isOfferEnabled={!!brandInfo?.is_offer_doc_exist} />
		</Box>
	);
}

import {ReactNode, useState} from "react"
import {MDBIcon, MDBInputGroup} from "mdb-react-ui-kit";
import ShareIcon from '@mui/icons-material/Share';
import {
    EmailShareButton, EmailIcon,
    FacebookShareButton, FacebookIcon,
    PinterestShareButton, PinterestIcon,
    RedditShareButton, RedditIcon,
    TelegramShareButton, TelegramIcon,
    TwitterShareButton, TwitterIcon,
    ViberShareButton, ViberIcon,
    WhatsappShareButton, WhatsappIcon,
    HatenaShareButton, HatenaIcon,
    InstapaperShareButton, InstapaperIcon,
    LineShareButton, LineIcon,
    LinkedinShareButton, LinkedinIcon,
    PocketShareButton, PocketIcon,
    TumblrShareButton, TumblrIcon,
    WorkplaceShareButton, WorkplaceIcon,
} from "react-share"

import useShare from "./hooks/useShare";
import {TgButton, ThemeModal} from "../helpers/ThemeComponents";
import useLocale from "../hooks/localisation/useLocale";
import useAppContext from "../useAppContext";

interface IShareProps {
    url: string
    copyDisabled: boolean
    title: string
    description?: string | null
    node?: ReactNode
    customClass?: string
}

export default function Share(props: IShareProps) {
    const {toastService} = useAppContext()

    const locale = useLocale('global')
    const {isShareAvailable, tryShare} = useShare()

    const [show, setShow] = useState<boolean>(false)

    const handleShareClick = async(e: any) => {
        e.stopPropagation()
        if(isShareAvailable){
            return await tryShare({url: props.url})
        }
        setShow(true)
    }

    const handleCopyClick = (e: any) => {
        e.stopPropagation()
        navigator.clipboard.writeText(props.url).then(() => {
            toastService.showToast({
                severity: 'success',
                message: locale.copiedText,
            })
            setShow(false)
        })
    }

    return (
        <>
            {props.node
                ?
                    <div onClick={(e: any) => handleShareClick(e)}>
                        {props.node}
                    </div>
                :
                    <ShareIcon
                        className={`cursor-pointer theme-link text-decoration-none ${props.customClass || ''}`}
                        onClick={(e: any) => handleShareClick(e)}
                        fontSize={'inherit'}
                    />
            }

            <ThemeModal
                open={show}
                setOpen={setShow}
                title={locale.shareHeader}
                onClose={(e: any) => e.stopPropagation()}
            >
                <div>
                    {!props.copyDisabled && (
                        <MDBInputGroup className='mb-3'>
                            <input disabled className='form-control' value={props.url} type='text' />
                            <TgButton onClick={(e: any) => handleCopyClick(e)}>
                                <MDBIcon far icon="copy" />
                            </TgButton>
                        </MDBInputGroup>
                    )}
                    <div>
                        <EmailShareButton
                            subject={props.title}
                            body={props.description ? props.description : props.title}
                            url={props.url}
                            className={'m-1'}
                        >
                            <EmailIcon size={42} round={true} />
                        </EmailShareButton>

                        <FacebookShareButton url={props.url} quote={props.title} className={'m-1'}>
                            <FacebookIcon size={42} round={true} />
                        </FacebookShareButton>

                        <PinterestShareButton
                            url={props.url}
                            description={props.title}
                            media={''}
                            className={'m-1'}
                        >
                            <PinterestIcon size={42} round={true} />
                        </PinterestShareButton>

                        <RedditShareButton url={props.url} title={props.title} className={'m-1'}>
                            <RedditIcon size={42} round={true} />
                        </RedditShareButton>

                        <TelegramShareButton url={props.url} title={props.title} className={'m-1'}>
                            <TelegramIcon size={42} round={true} />
                        </TelegramShareButton>

                        <TwitterShareButton url={props.url} title={props.title} className={'m-1'}>
                            <TwitterIcon size={42} round={true} />
                        </TwitterShareButton>

                        <ViberShareButton url={props.url} title={props.title} className={'m-1'}>
                            <ViberIcon size={42} round={true} />
                        </ViberShareButton>

                        <WhatsappShareButton url={props.url} title={props.title} className={'m-1'}>
                            <WhatsappIcon size={42} round={true} />
                        </WhatsappShareButton>

                        <HatenaShareButton url={props.url} title={props.title} className={'m-1'}>
                            <HatenaIcon size={42} round={true} />
                        </HatenaShareButton>

                        <InstapaperShareButton url={props.url} title={props.title} className={'m-1'}>
                            <InstapaperIcon size={42} round={true} />
                        </InstapaperShareButton>

                        <LineShareButton url={props.url} title={props.url} className={'m-1'}>
                            <LineIcon size={42} round={true} />
                        </LineShareButton>

                        <LinkedinShareButton url={props.url} title={props.title} className={'m-1'}>
                            <LinkedinIcon size={42} round={true} />
                        </LinkedinShareButton>

                        <PocketShareButton url={props.url} title={props.title} className={'m-1'}>
                            <PocketIcon size={42} round={true} />
                        </PocketShareButton>

                        <TumblrShareButton url={props.url} title={props.title} className={'m-1'}>
                            <TumblrIcon size={42} round={true} />
                        </TumblrShareButton>

                        <WorkplaceShareButton url={props.url} quote={props.title} className={'m-1'}>
                            <WorkplaceIcon size={42} round={true} />
                        </WorkplaceShareButton>

                    </div>
                </div>
            </ThemeModal>
        </>
    )
}

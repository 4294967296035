import { useMemo, useState } from "react";

import { AvatarObject, ProfileServiceType } from "./types";
import { ProfileTab } from "../../profile/types";
import { ApiUrl } from "../../../config";
import { User } from "../../../api/auth/types";

export default function useProfileService(user: User | null | undefined): ProfileServiceType {
	const [activeTab, setActiveTab] = useState<ProfileTab | undefined | null>(null);

	const stringAvatar: AvatarObject = useMemo(() => {
		let obj = {};

		if (user) {
			const firstChar = user.first_name ? user.first_name[0] : null;
			const avatarText = firstChar ? firstChar : "";
			if (!user.photo) {
				obj = {
					src: `${ApiUrl}/${user.photo}`,
					alt: avatarText,
				};
			} else {
				obj = {
					children: avatarText,
				};
			}
		}

		return obj;
	}, [user]);

	return useMemo(
		() => ({
			activeTab,
			setActiveTab,
			stringAvatar,
		}),
		[activeTab, setActiveTab, stringAvatar]
	);
}

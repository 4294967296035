import f from "../../../../helpers/formatText";
import {Alert} from "@mui/material";
import useAppContext from "../../../../useAppContext";
import {IncustPayDataError} from "../../../../api/shop/payments/types";


interface IncustPayErrorProps {
    incust_pay_error: IncustPayDataError
    noBorder?: boolean
}

export default function IncustPayError({incust_pay_error, noBorder}: IncustPayErrorProps) {
    const {
        localisation
    } = useAppContext()

    return (
        <Alert
            sx={noBorder ? {
                borderBottom: 1,
                borderColor: 'divider',
                borderRadius: 0,
            } : undefined}
            severity={'error'}
        >
            {f(
                localisation.payment[`incust_pay_error_${incust_pay_error.error_type}`], {
                    incust_pay_name: incust_pay_error.incust_pay_configuration.name,
                    error_detail: incust_pay_error.error_detail || 'no-detail'
                }
            )}
        </Alert>
    )
}

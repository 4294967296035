import React, {useState} from "react";

import {QrMenuPaymentState} from "../../types";
import {MenuInStoreServiceType} from "../../../services/useMenuInStoreService/types";
import useAppContext from "../../../../useAppContext";
import QrMenuButtonWrapper from "../../buttons/QrMenuButtonWrapper";
import LanguageIcon from '@mui/icons-material/Language';
import { Box } from "@mui/material";
import {SetState} from "../../../../types";

interface IOnlineButtonProps {
    onlineButton: string | null
    menuInStoreService: MenuInStoreServiceType
    setPaymentState: SetState<QrMenuPaymentState>
}

export default function OnlineButton(props: IOnlineButtonProps) {
    const {authService: {user, loadUser}} = useAppContext()

    const [isLoading, setIsLoading] = useState(false)

    const handleOnlineSumOrTemplateClick = async() => {
        if (user) {
            setIsLoading(true)
            try {
                await loadUser()
            } finally {
                setIsLoading(false)
            }
        }
        props.setPaymentState(QrMenuPaymentState.UserSum)
    }

    if(!props.onlineButton) return null

    return (
        <QrMenuButtonWrapper
            onClick={handleOnlineSumOrTemplateClick}
            loading={isLoading}
            loadingPosition={'end'}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
            >
                <LanguageIcon/>
                {props.onlineButton}
            </Box>
        </QrMenuButtonWrapper>
    )
}

import {useEffect} from "react";
import {useParams} from "react-router-dom";
import changePage from "../helpers/changePage";


export default function ShopDocumentRedirect({documentType}: {documentType: 'agreement' | 'about'}) {
    const {storeId} = useParams()

    useEffect(() => {
        if (storeId) {
            changePage(`/shop/${storeId}/document/${documentType}`)
        } else {
            changePage(`/shop/document/${documentType}`)
        }
    }, [storeId, documentType]);

    return null
}

import {useCallback, useEffect, useState} from "react"
import {useLocation, useParams} from "react-router-dom"
import {
    MDBRow, MDBContainer, MDBCol,
} from "mdb-react-ui-kit"

import api from "../api"
import {encodeStringCharSet} from "../helpers"
import useLocalisation from "../hooks/localisation/useLocalisation"
import {useShopContext} from "./context";
import {useSelectedStoreOptional} from "./SelectedStore/context";
import changePage from "../helpers/changePage";
import useAppContext from "../useAppContext";

export default function ShopDocument() {
    const {documentType} = useParams()

    const {
        setPageTitle,
        loadingManager: {getIsLoading, setIsLoading}
    } = useAppContext()
    const {brandInfo} = useShopContext()
    const selectedStore = useSelectedStoreOptional()

    const location = useLocation()

    const [document, setDocument]: any = useState(null)

    const localisation = useLocalisation()

    useEffect(() => {
        setIsLoading(`document-${documentType}`, true)

        return () => {
            setIsLoading(`document-${documentType}`, false)
        }
    }, [setIsLoading, documentType]);

    const getDocument = useCallback(async() => {
        if (documentType !== 'agreement' && documentType !== 'about') {
            return
        }

        try{
            setIsLoading(`document-${documentType}`, true)
            try {
                const response = await api.shop.basic.getDocument(brandInfo.id, {
                    document_type: documentType,
                    store_id: selectedStore?.id,
                })
                return setDocument(encodeStringCharSet(response.data.text))
            } catch (err) {
                console.log(err)
            }
        }
        finally {
            setIsLoading(`document-${documentType}`, false)
        }
    }, [brandInfo.id, documentType, selectedStore?.id, setIsLoading])

    const computedPageName = useCallback(() => {
        switch (documentType) {
            case 'about':
                return localisation.global.aboutButton
            case 'agreement':
                return localisation.global.agreementButton
            default:
                return localisation.global.unknownDocument
        }
    }, [
        documentType,
        localisation.global.aboutButton,
        localisation.global.agreementButton,
        localisation.global.unknownDocument,
    ])

    useEffect(() => {
        getDocument().then()
    }, [getDocument])

    useEffect(() => {
        setTimeout(() => {
            setPageTitle(`${brandInfo.name} - ${computedPageName()}`)
        }, 500)
    }, [brandInfo.name, computedPageName, setPageTitle])

    useEffect(() => {
        if (selectedStore?.id && location.pathname.startsWith(`/shop/document/${documentType}`)) {
            changePage(`/shop/${selectedStore.id}/document/${documentType}`)
        }
    }, [selectedStore?.id, location.pathname, documentType]);

    const isLoading = getIsLoading(`document-${documentType}`)

    return (
        <MDBContainer>
            {!isLoading && document && (
                <MDBRow>
                    <MDBCol size={'12'} className={'theme-text'}>
                        <div dangerouslySetInnerHTML={{__html: document}} />
                    </MDBCol>
                </MDBRow>
            )}

            {!isLoading && !document && (
                <MDBRow>
                    <MDBCol size={'12'} className={'theme-text'}>
                        <div className={'fw-bold fs-4'}>
                            {brandInfo.name}
                        </div>
                    </MDBCol>
                </MDBRow>
            )}
        </MDBContainer>
    )
}

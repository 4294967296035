import { Typography } from "@mui/material";
import BaseCouponDesc, { BaseCouponDescProps } from "./BaseCouponDesc";

interface CouponDescProps extends BaseCouponDescProps {
	isExternalType: boolean;
}

export function CouponDesc({ isExternalType, ...props }: CouponDescProps) {
	return (
		<Typography
			component={"div"}
			my={{ xs: 2, sm: 0 }}
			color={{ sm: "primary.contrastText" }}
			textAlign={{ xs: "center", sm: "end" }}
		>
			<BaseCouponDesc {...props} />
		</Typography>
	);
}

import { useEffect } from 'react'
import useDeviceInfo from "./useDeviceInfo";

export default function useAnimationTimeline(
    scrollElementId: string,
    animationItems: IAnimationTimelineItem[],
) {
    const deviceInfo = useDeviceInfo()

    useEffect(() => {
        if(scrollElementId && deviceInfo){
            const overflowEl = window.document
                .getElementById('modal-or-swipeable-overflow-wrapper')
            if(overflowEl){
                animationItems.forEach(item => {
                    const el = window.document.getElementById(item.id)
                    if(el){
                        let rangeEnd = item.rangeEnd
                        if(item.offsetPlus && rangeEnd){
                            rangeEnd += item.offsetPlus
                        }
                        if(deviceInfo.info.browser === 'Safari' || deviceInfo.info.browser === 'Firefox'){
                            if(rangeEnd){
                                el.animate(
                                    item.keyframes,
                                    {
                                        fill: 'both',
                                        //@ts-ignore
                                        timeline: new ScrollTimeline({
                                            source: overflowEl,
                                        }),
                                    //@ts-ignore
                                    rangeStart: new CSSUnitValue(item.rangeStart, 'px'),
                                    //@ts-ignore
                                    rangeEnd: new CSSUnitValue(rangeEnd, 'px'),
                                })
                            }
                            else {
                                el.animate(
                                    item.keyframes,
                                    {
                                        fill: 'both',
                                        // @ts-ignore
                                        timeline: new ViewTimeline({
                                            subject: el,
                                        }),
                                })
                            }
                        }
                        else {
                            el.style.setProperty(
                                "animation",
                                item.animationName,
                            )
                            if(rangeEnd){
                                el.style.setProperty(
                                    "animation-range",
                                    `${item.rangeStart}px ${rangeEnd}px`
                                )
                            }
                            el.style.setProperty(
                                "animation-timeline",
                                item.scrollTimelineValue,
                            )
                        }
                    }
                })
            }

        }
    }, [scrollElementId, animationItems, deviceInfo])
}

export interface IAnimationTimelineItem {
    id: string
    keyframes: any[]
    rangeStart?: number
    rangeEnd?: number
    offsetPlus?: number
    animationName: string
    scrollTimelineValue: string
}

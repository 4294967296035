import {useEffect, useState} from "react";

import {IQrMenuPaymentButtonProps, QrMenuPaymentState} from "../types";
import QrMenuButtonWrapper from "./QrMenuButtonWrapper";
import QrMenuPaymentsModal from "../modals/QrMenuPaymentsModal";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import useAppContext from "../../../useAppContext";

export default function PaymentButton(props: IQrMenuPaymentButtonProps){
    const {loadingManager: {isSomethingLoading}} = useAppContext()
    const {setPaymentState} = props

    const [searchParams, setSearchParams] = useSearchParamsFixed()

    const [show, setShow] = useState(false)
    const onClick = () => {
        setPaymentState(QrMenuPaymentState.CanPay)
        setShow(true)
    }

    const isActionPayOnline = searchParams.get("action") === 'payonline'
    useEffect(() => {
        if (isActionPayOnline && !isSomethingLoading) {
            setShow(true)
            setPaymentState(QrMenuPaymentState.UserSum)
            console.log('in payment button')
            setSearchParams(prev => {
                prev.delete("action")
                return prev
            })
        }
    }, [isActionPayOnline, setPaymentState, setSearchParams, isSomethingLoading]);

    useEffect(() => {
        console.log('rerendering setSearchParams')
    }, [setSearchParams]);

    return (
        <>
            <QrMenuButtonWrapper
                onClick={onClick}
            >
                {props.buttonName}
            </QrMenuButtonWrapper>

            <QrMenuPaymentsModal
                show={show}
                setShow={setShow}
                cardButton={props.cardButton}
                cashButton={props.cashButton}
                onlineButton={props.onlineButton}
                qrButton={props.qrButton}
                setMessage={props.setMessage}
                setShowAlertModal={props.setShowAlertModal}
                paymentState={props.paymentState}
                setPaymentState={props.setPaymentState}
            />
        </>
    )
}

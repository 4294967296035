import {AxiosInstance} from "axios";
import {IFriend} from "./types";

export default function FriendsModule(
    instance: AxiosInstance
) {
    const getPath = (path: string) => `/auth/user/${path}`
    const getHeaders = (token: string | null | undefined) =>
        token ? {"Authorization": `Bearer ${token}`} : undefined

    return {
        getFriends(
            token?: string | null | undefined
        ) {
            let config: {headers?: any} = {}
            if(token) {
                config.headers = getHeaders(token)
            }

            return instance.get<IFriend[]>(getPath('friends'), config)
        },

        deleteFriend(
            friendId: number, token?: string | null | undefined
        ){
            let config: {headers?: any} = {}
            if(token)
                config.headers = getHeaders(token)

            return instance.delete(getPath(`friends/${friendId}`), config)
        },

        clearFriends(
            token?: string | null | undefined
        ){
            let config: {headers?: any} = {}
            if(token)
                config.headers = getHeaders(token)

            return instance.delete(getPath(`friends/clear`), config)
        },

        addFriend(
            req_user_id: number, token?: string | null | undefined
        ){
            let config: {headers?: any} = {}
            if(token)
                config.headers = getHeaders(token)

            return instance.post(getPath(`friends/${req_user_id}`), config)
        },

        getFriend(
            reqUserId: number, token?: string | null | undefined
        ){
            let config: {headers?: any} = {}
            if(token)
                config.headers = getHeaders(token)

            return instance.get(getPath(`friends/${reqUserId}`), config)
        },

    }
}

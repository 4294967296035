import { ConsentMode } from "../api/shop/basic/types";
import { User } from "../api/auth/types";

interface IConsentValues {
	consent: boolean;
	market_consent: boolean | null | undefined;
}

export const calcConsentValues = (
	formAgreement: boolean | null | undefined,
	formMarketing: boolean | null | undefined,
	consentMode: ConsentMode | null | undefined,
	user: User | null | undefined
): IConsentValues => {
	let consent = formAgreement;
	let market_consent = formMarketing;

	if (consentMode === "info") {
		consent = true;
		market_consent = true;
	} else if (
		user?.is_accepted_agreement &&
		consentMode !== "per_order" &&
		user?.marketing_consent
	) {
		consent = true;
		market_consent = user?.marketing_consent || null;
	}

	return {
		consent: !!consent,
		market_consent: market_consent,
	};
};

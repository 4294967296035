import {AuthorisationComponentProps} from "../types";
import FormError from "../FormError";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import {ChangeEvent} from "react";
import TextField from "@mui/material/TextField";


export default function RepeatPasswordInput(props: AuthorisationComponentProps) {
    if (props.form.state !== 'emailRegistration') {
        throw new Error('RepeatPasswordInput supports only emailRegistration')
    }

    const localisation = useLocalisation()

    const onRepeatPasswordInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        props.form.resetErrors()
        props.form.input('repeatPassword', e.target.value)
    }

    return (<>
        <TextField
            sx={{mt: 2}}
            fullWidth
            size={'small'}
            required={true}
            name={'password'}
            type={props.form.isPasswordVisible ? 'text' : 'password'}
            value={props.form.repeatPassword}
            label={localisation.auth.registerRepeatPasswordLabel}
            onChange={onRepeatPasswordInputChange}
        />
        <FormError errText={props.form.errors.repeatPassword}/>
    </>)
}

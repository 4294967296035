import { atom } from "recoil";

export const filterBarVisibleState = atom({
	key: "filterBarVisibleState",
	default: true,
});

export const productsViewTypeState = atom({
	key: "productsViewState",
	default: "",
});

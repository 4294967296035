import api from "../api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useAppContext from "../useAppContext";
import Interweave from "../features/Interweave";

export default function MarketingPage() {
	const { lang } = useAppContext();

	const query = useQuery({
		queryKey: ["marketingInfo", lang],
		placeholderData: keepPreviousData,
		queryFn: async () => {
			const { data } = await api.auth.getMarketingInfo();

			return data;
		},
	});

	if (query.isFetching) {
		return <>Loading...</>;
	}

	return (
		<>
			{!!query.data && (
				<>
					<Interweave className={"user-select-none"} content={query.data.consent_text} />
				</>
			)}
		</>
	);
}

import {useEffect, useRef, useState} from "react";
import AIChat from "../index";
import {ChatMessageType} from "../../../features/Chat/types";
import useOutsideClickHandler from "../../../features/hooks/useOutsideClickHandler";
import {useShopContext} from "../../../shop/context";
import {Drawer} from "@mui/material";


export default function AIChatWindow() {
    const {
        showAIChat,
        setShowAIChat,
    } = useShopContext()

    const [isOpenedOneTime, setIsOpenedOneTime] = useState(false)

    const ref = useRef<HTMLDivElement | null>(null)
    const [history, setHistory] = useState<ChatMessageType[]>([])

    useOutsideClickHandler(ref, () => {
        setShowAIChat(false)
    }, showAIChat)

    useEffect(() => {
        if (showAIChat) {
            setIsOpenedOneTime(true)
        }
    }, [showAIChat])

    return (
        <Drawer
            anchor={'right'}
            open={showAIChat}
            onClose={() => setShowAIChat(false)}
            classes={{
                paper: 'ai-chat-window'
            }}
            keepMounted
        >
            <AIChat
                history={history}
                setHistory={setHistory}
                needConnect={isOpenedOneTime}
            />
        </Drawer>
    )
}


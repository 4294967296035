import {useMemo} from "react";

import {useSelectedStoreContext} from "../../../SelectedStore/context";
import {CartProduct} from "../../../../api/shop/cart/types";
import {Product} from "../../../../api/shop/basic/types";

export default function useCartProduct(): IUseCartProduct {
    const {
        productsService,
        cartService,
    } = useSelectedStoreContext()

    const product = productsService.selectedProduct || null

    const getCartProductByCartProductId = cartService.getCartProductByCartProductId
    const getCartProduct = cartService.getCartProduct
    const cartProduct = useMemo(() => {
        if(cartService.selectedVariationCartProductId){
            return getCartProductByCartProductId(cartService.selectedVariationCartProductId)
        }
        return product ? getCartProduct(product.id) : null
    }, [cartService.selectedVariationCartProductId, getCartProduct, getCartProductByCartProductId, product])

    const isProductInCart = useMemo(() => {
        return !!cartProduct && cartService.addingProductWithNewAttributesId !== product?.id
    }, [cartProduct, cartService.addingProductWithNewAttributesId, product?.id])

    return {
        product,
        cartProduct,
        isProductInCart,
    }
}

interface IUseCartProduct {
    product: Product | null,
    cartProduct: CartProduct | null,
    isProductInCart: boolean,
}

import { useSelectedStore } from "../SelectedStore/context";
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { TgLink } from "../../helpers/ThemeComponents";
import { useShopContext } from "../context";
import BaseShopFooter from "./BaseShopFooter";
import classnames from "classnames";
import { getLinkOnMaps } from "../../helpers";
import ShopWorkTimes from "../ShopWorkTimes";
import SocialNetworksInfo from "../menu/SocialNetworksInfo";
import EmbeddedShopMap from "../maps/EmbeddedShopMap";
import { Box, Link } from "@mui/material";
import useAppContext from "../../useAppContext";
import MessangerIcon from "../../features/MessangerIcon";

export default function SelectedStoreShopFooter() {
	const { localisation, bot } = useAppContext();
	const { brandInfo } = useShopContext();

	const selectedStore = useSelectedStore();

	const computedContactValue = (name: string): any => {
		if (!selectedStore.custom_fields) return "";

		const field = selectedStore.custom_fields.find((x: any) => x.name === name);

		if (!field) return "";
		if (field.name !== "phone_number" || typeof field.value !== "string") return field.value;
		if (!field.value) return "";

		if (field.value.includes(";")) {
			const phones = field.value.replace(/\s/g, "");

			return phones
				.split(";")
				.filter((x: string) => x.length > 1)
				.map(x => {
					return (
						<p key={x}>
							<MDBIcon
								size={"lg"}
								icon="mobile-alt"
								className={"me-3 text-secondary"}
							/>
							<TgLink href={`tel:${x}`} className={"cursor-pointer me-2"}>
								{x}
							</TgLink>
						</p>
					);
				});
		}

		return (
			<p>
				<MDBIcon size={"lg"} icon="mobile-alt" className={"me-3 text-secondary"} />
				<TgLink href={`tel:${field.value}`} className={"cursor-pointer"}>
					{field.value}
				</TgLink>
			</p>
		);
	};

	const addressValue = computedContactValue("address");
	const emailValue = computedContactValue("email");
	const phoneNumberValue = computedContactValue("phone_number");

	const computedIsContacts =
		selectedStore &&
		brandInfo &&
		(brandInfo.bot_name || addressValue || emailValue || phoneNumberValue);

	return (
		<BaseShopFooter>
			<MDBContainer breakpoint={"xxl"} className={"px-0"}>
				<MDBRow className={"mt-3 w-100 mx-0"}>
					<MDBCol size={6} className="mx-auto mb-4">
						{computedIsContacts && (
							<h6 className="text-uppercase fw-bold mb-3 theme-text">
								{localisation.global.contactsHeader}
							</h6>
						)}

						{bot && (
							<>
								<p className={classnames("mw-100", "one-line")}>
									<MessangerIcon botType={bot.bot_type} />
									<Link
										href={bot.link}
										target={"_blank"}
										rel={"noopener noreferrer"}
									>
										{localisation.global.chatButton}
									</Link>
								</p>
							</>
						)}

						{addressValue && (
							<p className={classnames("mw-100", "one-line")}>
								<MDBIcon icon="home" className={"me-3 text-secondary"} />
								<TgLink
									href={getLinkOnMaps(
										selectedStore.latitude,
										selectedStore.longitude
									)}
									target={"_blank"}
								>
									{addressValue}
								</TgLink>
							</p>
						)}

						{emailValue && (
							<p className={classnames("mw-100", "one-line")}>
								<MDBIcon icon="envelope" className={"me-3 text-secondary"} />
								<TgLink href={`mailto:${emailValue}`}>{emailValue}</TgLink>
							</p>
						)}

						{phoneNumberValue}
					</MDBCol>

					<MDBCol size={6} className="mx-auto mb-4">
						{selectedStore.working_days && selectedStore.working_days.length > 0 && (
							<>
								<h6 className="text-uppercase fw-bold mb-3 theme-text">
									{localisation.global.workTimesHeader}
								</h6>
								<ShopWorkTimes />
							</>
						)}

						<Box sx={{ width: "fit-content" }}>
							<div
								className={
									selectedStore.working_days &&
									selectedStore.working_days.length > 0
										? "mt-3"
										: ""
								}
							>
								{selectedStore.custom_fields.some(
									(i: any) => i.value && i.value.includes("http")
								) && (
									<>
										<h6 className="text-uppercase fw-bold mb-1 theme-text">
											{localisation.global.socialHeader}
										</h6>
										<SocialNetworksInfo />
									</>
								)}
							</div>
						</Box>

						{(selectedStore.is_about_doc_exist || brandInfo.is_about_doc_exist) && (
							<Box mt={3}>
								<Link
									href={
										window.location.origin +
										`/shop/${selectedStore.id}/document/about`
									}
									target={"_blank"}
								>
									{localisation.global.aboutButton}
								</Link>
							</Box>
						)}
						{(selectedStore.is_offer_doc_exist || brandInfo.is_offer_doc_exist) && (
							<Box mt={3}>
								<TgLink
									href={
										window.location.origin +
										`/shop/${selectedStore.id}/document/agreement`
									}
									target={"_blank"}
								>
									{localisation.global.agreementButton}
								</TgLink>
							</Box>
						)}
					</MDBCol>

					<MDBCol size={"12 mb-3"}>
						<EmbeddedShopMap className={"border-radius-05"} />
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</BaseShopFooter>
	);
}

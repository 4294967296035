import { useCallback, useEffect, useMemo, useReducer } from "react";
import {
	AuthorisationForm,
	AuthorisationFormExternLoginType,
	AuthorisationFormInputFieldType,
	AuthorisationFormReducer,
	AuthorisationFormState,
	AuthorisationFormStateErrorsField,
	AuthorisationFormStateMethods,
} from "./types";
import useAppContext from "../../../useAppContext";
import useAuthSettings from "../../../services/useAuthSettings";

export default function useAuthorisationForm(visible?: boolean): AuthorisationForm {
	const [state, dispatch] = useReducer<AuthorisationFormReducer>(
		(prevState, action): AuthorisationFormState => {
			console.log("*** ACTION", action);
			switch (action.type) {
				case "chooseAuthMethod":
					return {
						state: "authMethods",
						email: action.email || "",
						errors: prevState.state === "authMethods" ? prevState.errors : {},
						isLoading: false,
					};
				case "oauthConsent":
					return {
						state: "oauthConsent",
						errors: prevState.state === "authMethods" ? prevState.errors : {},
						isLoading: false,
					};
				case "startEmailLogin":
					console.log("*** EMAIL LOGIN", prevState.state);
					switch (prevState.state) {
						case "authMethods":
						case "emailRegistration":
							return {
								state: "emailLogin",
								email: action.email || prevState.email,
								password: "",
								errors: {},
								isLoading: false,
								with_consent: action.with_consent,
							};
						case "emailLogin":
							return {
								...prevState,
								email: action.email || prevState.email,
								with_consent: action.with_consent,
							};
						case "resetPassword":
							return {
								state: "emailLogin",
								email: action.email || prevState.email,
								password: "",
								errors: {},
								isLoading: false,
							};
						default:
							return {
								state: "emailLogin",
								email: action.email || "",
								password: "",
								errors: {},
								isLoading: false,
								with_consent: action.with_consent,
							};
					}
				case "startEmailRegistration":
					switch (prevState.state) {
						case "authMethods":
						case "emailLogin":
							return {
								state: "emailRegistration",
								email: action.email || prevState.email,
								firstName: "",
								lastName: "",
								birth_date: "",
								password: "",
								repeatPassword: "",
								isAcceptedAgreement: false,
								needRegisterLoyalty: true,
								errors: {},
								isLoading: false,
							};
						case "emailRegistration":
							return {
								...prevState,
								email: action.email || prevState.email,
							};
						default:
							return {
								state: "emailRegistration",
								email: action.email || "",
								firstName: "",
								lastName: "",
								birth_date: "",
								password: "",
								repeatPassword: "",
								isAcceptedAgreement: false,
								needRegisterLoyalty: true,
								errors: {},
								isLoading: false,
							};
					}
				case "input":
					return {
						...prevState,
						[action.field]: action.value,
					};
				case "startExternalLogin":
					if (
						prevState.state === "externalLogin" &&
						prevState.loginType === action.loginType
					) {
						return prevState;
					}
					return {
						state: "externalLogin",
						loginType: action.loginType,
						errors: {},
						isLoading: false,
					};
				case "setIsAcceptedAgreement":
					if (prevState.state !== "emailRegistration") {
						throw new Error(
							`setIsAcceptedAgreement is not supported for ${prevState.state}`
						);
					}

					if (action.isAcceptedAgreement) {
						return {
							...prevState,
							isAcceptedAgreement: true,
							errors: {},
						};
					} else {
						return {
							...prevState,
							isAcceptedAgreement: false,
							errors: {},
						};
					}
				case "setNeedRegisterLoyalty":
					if (prevState.state !== "emailRegistration") {
						throw new Error("needRegisterLoyalty supported only for emailRegistration");
					}
					return {
						...prevState,
						needRegisterLoyalty: action.needRegisterLoyalty,
					};
				case "setError":
					if (action.field === "email" && prevState.state === "externalLogin") {
						throw new Error("email is not supported for externalLogin");
					}

					if (
						action.field === "password" &&
						prevState.state !== "emailLogin" &&
						prevState.state !== "emailRegistration"
					) {
						throw new Error(
							"password supported only for emailLogin and emailRegistration"
						);
					}

					if (
						action.field === "repeatPassword" &&
						prevState.state !== "emailRegistration"
					) {
						throw new Error("repeatPassword supported only for emailRegistration");
					}

					return {
						...prevState,
						errors: {
							...prevState.errors,
							[action.field]: action.value,
						},
					};
				case "resetErrors":
					return {
						...prevState,
						errors: {},
					};
				case "setIsLoading":
					return {
						...prevState,
						isLoading: action.isLoading,
					};
				case "togglePasswordVisible":
					if (
						prevState.state !== "emailLogin" &&
						prevState.state !== "emailRegistration"
					) {
						throw new Error(
							"togglePasswordVisible is supported only for emailLogin and emailRegistration"
						);
					}

					return {
						...prevState,
						isPasswordVisible: !prevState.isPasswordVisible,
					};
				case "startResetPassword":
					const email =
						action.email ||
						(prevState.state === "emailLogin" ? prevState.email : undefined);
					if (!email) {
						throw new Error(
							"startResetPassword must be called only after emailLogin or email has to be provided in action"
						);
					}

					return {
						state: "resetPassword",
						email: email,
						isLoading: false,
						errors: {},
					};
			}
		},
		{
			state: "authMethods",
			email: "",
			errors: {},
			isLoading: false,
		}
	);

	const methods: AuthorisationFormStateMethods = useMemo(
		(): AuthorisationFormStateMethods => ({
			chooseAuthMethod(email?: string) {
				dispatch({ type: "chooseAuthMethod", email });
			},
			startEmailLogin(with_consent?: boolean) {
				console.log("*** WITH CONSENT", with_consent);
				dispatch({ type: "startEmailLogin", with_consent: with_consent });
			},
			startEmailRegistration() {
				dispatch({ type: "startEmailRegistration" });
			},
			startExternalLogin(loginType: AuthorisationFormExternLoginType) {
				dispatch({ type: "startExternalLogin", loginType });
			},
			input(field: AuthorisationFormInputFieldType, value: string) {
				dispatch({ type: "input", field, value });
			},
			setIsAcceptedAgreement(isAcceptedAgreement: boolean) {
				dispatch({ type: "setIsAcceptedAgreement", isAcceptedAgreement });
			},
			setNeedRegisterLoyalty(needRegisterLoyalty: boolean) {
				dispatch({ type: "setNeedRegisterLoyalty", needRegisterLoyalty });
			},
			setError(field: AuthorisationFormStateErrorsField, value?: string | null | undefined) {
				dispatch({ type: "setError", field, value });
			},
			resetErrors() {
				dispatch({ type: "resetErrors" });
			},
			setIsLoading(isLoading: boolean) {
				dispatch({ type: "setIsLoading", isLoading });
			},
			togglePasswordVisible() {
				dispatch({ type: "togglePasswordVisible" });
			},
			startResetPassword(email?: string) {
				dispatch({ type: "startResetPassword", email });
			},
			startOAuthConsent() {
				dispatch({ type: "oauthConsent" });
			},
		}),
		[]
	);

	const { bot, brandInfo } = useAppContext();
	const authSettings = useAuthSettings(brandInfo?.group_id);

	const resetState = useCallback(
		(isOnlyMessanger: boolean) => {
			if (!bot?.bot_type) return;
			if (isOnlyMessanger) {
				methods.startExternalLogin(bot.bot_type);
			} else {
				methods.chooseAuthMethod();
			}
		},
		[bot?.bot_type, methods]
	);

	useEffect(() => {
		resetState(authSettings.computedIsOnlyMessanger);
	}, [authSettings.computedIsOnlyMessanger, resetState]);

	useEffect(() => {
		if (visible === false) {
			resetState(authSettings.computedIsOnlyMessanger);
		}
	}, [authSettings.computedIsOnlyMessanger, methods, resetState, visible]);

	return { ...state, ...methods };
}

import { IIncustCoupon } from "../../../types";
import { Typography, TypographyProps } from "@mui/material";
import { formatCouponCode } from "../../../../../helpers/formatText";

interface CouponCodeProps extends TypographyProps {
	coupon: IIncustCoupon;
	borderColor?: string;
	isCentered?: boolean;
}

export function CouponCode({ coupon, isCentered, ...props }: CouponCodeProps) {
	return (
		<Typography
			fontSize={"small"}
			fontWeight={"500"}
			borderColor={"divider"}
			width={"fit-content"}
			px={"5px"}
			mx={isCentered ? "auto" : "none"}
			{...props}
		>
			{formatCouponCode(coupon.code || "")}
		</Typography>
	);
}

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import {useSelectedStoreContext} from "../SelectedStore/context";
import changePage from "../../helpers/changePage";
import {StyledHeaderBadge} from "../ShopHeader/StyledHeaderBadge";

export default function FavoritesButton(){
    const {selectedStore, favoritesService} = useSelectedStoreContext()

    const handleFavoritesClick = () => {
        changePage(`/shop/${selectedStore.id}/profile/favorites`)
    }

    return (
        <>
            <span className={'cursor-pointer me-2 align-self-center'} onClick={() => handleFavoritesClick()}>
                <StyledHeaderBadge
                    badgeContent={favoritesService.favorites?.favorite_products?.length || 0}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    right={4}
                    sx={{userSelect: 'none'}}
                >
                    <FavoriteBorderOutlinedIcon
                        color={'inherit'}
                        sx={{
                            width: 35,
                            height: 35,
                        }}
                    />
                </StyledHeaderBadge>
            </span>
        </>
    )
}

export const CheckLocale = {
	storeNameText: "check store name text",
	dateText: "check date text",
	toText: "check to text",

	statusText: "check status text",
	statusPayText: "check status pay text",
	statusPayPayedText: "check status pay payed text",
	statusPayNotPayedText: "check status pay not payed text",
	status_open_unconfirmed_text: "check status open unconfirmed text",
	status_open_confirmed_text: "check status open confirmed text",
	status_closed_text: "check status closed text",
	status_canceled_text: "check status canceled text",
	status_new_text: "status new text",
	status_terminated_by_user_text: "status terminated by user text",

	paymentMethodText: "check payment method text",
	paymentMethodCommentText: "check payment method comment text",

	deliveryMethodText: "check delivery method text",
	deliveryMethodPickupPointText: "check delivery method pickup point text",
	deliveryMethodAddressText: "check delivery method address text",
	deliveryMethodCommentText: "check delivery method comment text",
	desiredDeliveryDateText: "check desired delivery date text",
	desiredPickupDateText: "check desired pickup date text",

	commentText: "check comment text",

	itemsText: "check items text",
	itemSaleText: "check item sale text",

	subtotalText: "check subtotal text",
	totalDiscountText: "check total discount text",
	paymentPriceText: "check payment costs text",
	deliveryPriceText: "check delivery costs text",
	deliveryPaymentSeparatelyText: "check delivery payment separately text",
	totalAmountText: "check total amount text",
	tipsText: "check tips text",
	totalAmountWithTipsText: "check total amount with tips text",
	paymentDueText: "check payment due text",
	paidText: "check paid text",

	loyaltyIssuedPerOrderText: "check loyalty issued per order text",
	loyaltyToBeIssuedPerPaymentText: "check loyalty to be issued per payment text",
	loyaltyBonusesText: "check loyalty bonuses text",
	loyaltyCustomerAccountsText: "check loyalty customer accounts text",
	loyaltyCustomerAccountsToBeReplenishedText:
		"check loyalty customer accounts to be replenished text",
	loyaltyVouchersText: "check loyalty vouchers text",
	payerFeeListOrderText: "payer fee receipt list order text",
	payerFeeToolTipText: "payer fee tooltip text",
	serviceFeeText: "payment service fee text",
};

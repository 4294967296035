import { AspectRatioType } from "../types";

let config;

try {
	config = require("./dev-config.js");
} catch {
	if (process.env.REACT_APP_TEST === "true") {
		config = require("./test-config.js");
	} else {
		config = require("./prod-config.js");
	}
}

export const DEFAULT_LANG = "en";

export const availableNoImageLanguages = ["uk"];

export const SevenLocDomain = "https://7loc.com";
export const ApiUrl = config ? config.ApiUrl : "http://localhost:1914";
export const WsUrl = config ? config.WsUrl : "http://localhost:1914";
export const PhotonApiUrl = config ? config.PhotonApiUrl : "https://photon.komoot.io";

export const LoadObjectsLimit = 5;

export const AnonymousImgPath = "static/images/anonymous.jpg";

export const DefaultImageAspectRatio: AspectRatioType = [5, 4];

export const GoogleOAuthClientId = config ? config.GoogleOAuthClientId : "";

export const InternalWidgetUrl = config ? config.InternalWidgetUrl : "http://localhost:3000";
export const AppleAuthClientId = config ? config.AppleAuthClientId : "com.7loc.authid";
export const AppleAuthRedirectUri = config
	? config.AppleAuthRedirectUri
	: "https://webapp.7loc.com";
export const AppleAuthApiRedirectUri = config
	? config.AppleAuthApiRedirectUri
	: "https://api.payforsay.com/auth/apple_oauth";

export const SseUrl = config ? config.SseUrl : "http://localhost:1914";
export default function f(text: string | null | undefined, data: { [key: string]: any }) {
	if (!text) {
		text = "";
	}
	for (const [key, value] of Object.entries(data)) {
		text = text.replaceAll(`{${key}}`, value?.toString() || "");
	}
	return text;
}

export function formatCouponCode(code: string) {
	return code.match(/.{1,3}/g)?.join("-") || code;
}

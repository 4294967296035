import { ErrorDetail } from "../api/types";
import { AxiosError } from "axios";

export function getErrorText(err: any, default_?: string, useMessage = false): string {
	if (typeof err === "string" || err instanceof String) return err.toString();

	if (err instanceof AxiosError) {
		const detail: ErrorDetail | undefined = err.response?.data?.detail;
		if (detail) {
			if (typeof detail === "string") {
				return detail;
			}
			return detail.message;
		}
	}

	if (useMessage && err.message) return err.message;

	return default_ || "Unknown error";
}

import { ShortTokenDataType } from "../api/auth/types";
import { BrandInfo } from "../api/shop/basic/types";
import { useEffect, useMemo } from "react";
import api from "../api";
import { Bot } from "../api/bots/types";
import { useQuery } from "@tanstack/react-query";
import useSearchParamsFixed from "../features/hooks/useSearchParamsFixed";

export default function useBotService(
	brandInfo: BrandInfo | null,
	shortTokenData: ShortTokenDataType | null
): BotServiceType {
	const [searchParams, setSearchParams] = useSearchParamsFixed();

	const queryBotId = searchParams.get("bot_id");
	const botId = useMemo(() => {
		const queryBotIdNum = queryBotId ? parseInt(queryBotId) : null;

		let botId: number | null = null;

		if (queryBotIdNum) {
			botId = queryBotIdNum;
		} else if (shortTokenData?.bot_id) {
			botId = shortTokenData.bot_id;
		} else if (brandInfo?.bot_id) {
			botId = brandInfo.bot_id;
		}

		const paramsBotId = botId?.toString() || queryBotId;
		console.log("paramsBotId", paramsBotId);
		if (paramsBotId) {
			api.instance.defaults.params["bot_id"] = paramsBotId;
		}

		return botId;
	}, [queryBotId, brandInfo?.bot_id, shortTokenData?.bot_id]);

	useEffect(() => {
		if (botId && brandInfo?.bot_id !== botId) {
			setSearchParams(prev => {
				prev.set("bot_id", botId.toString());
				return prev;
			});
		}
	}, [botId, brandInfo?.bot_id, setSearchParams]);

	const { data: bot } = useQuery({
		queryKey: ["bot-service-bot", botId],
		enabled: !!botId,
		initialData: null,
		queryFn: () => {
			if (!botId) return null;
			return api.bot.getBot(botId).then(response => response.data);
		},
	});

	return {
		botId,
		bot,
	};
}

interface BotServiceType {
	botId: number | null;
	bot: Bot | null;
}

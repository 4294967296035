import {Box} from "@mui/material";
import {BotType} from "../../../../api/bots/types";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import useAppContext from "../../../../useAppContext";


interface MessangerIconAndNameProps {
    messanger?: BotType
}


export default function MessangerIconAndName(props: MessangerIconAndNameProps) {
    const {
        localisation
    } = useAppContext()

    return (
        <Box component={'span'} ml={1}>
            <MessangerIcon messanger={props.messanger}/>
            {props.messanger && (
                <Box component={'span'} ml={1}>
                    {localisation.global[`${props.messanger}MessangerName`]}
                </Box>
            )}
        </Box>
    )
}


function MessangerIcon({messanger}: MessangerIconAndNameProps) {
    switch (messanger) {
        case "telegram":
            return (
                <TelegramIcon sx={{color: '#229ED9'}}/>
            )
        case "whatsapp":
            return (
                <WhatsappIcon sx={{color: '#25d366'}}/>
            )
        default:
            return null
    }
}

import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import useAppContext from "../../../../../useAppContext";
import React, { ReactNode, useMemo } from "react";
import { QRCodeSVG } from "qrcode.react";
import { IUsePaymentsService } from "../../../hooks/useNewPaymentsService";
import { useQuery } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { PaymentData } from "../../../../../api/shop/payments/types";
import { useSSEPaymentStatus } from "./useSSEPaymentStatus";
import f from "../../../../../helpers/formatText";
import useScreenService from "../../../../../services/useScreenService";
import Divider from "@mui/material/Divider";

import api from "../../../../../api";

interface EWalletPayModalContentProps {
	paymentsService: IUsePaymentsService;
	additionalFieldsNode?: ReactNode | null;
}

export default function EWalletPayModalContent({
												   paymentsService,
												   additionalFieldsNode,
											   }: EWalletPayModalContentProps) {
	const { localisation, bot, groupService } = useAppContext();
	const { isMobile } = useScreenService();

	const botName = useMemo(() => {
		if (bot?.bot_type) {
			return bot?.bot_type.charAt(0).toUpperCase() + bot?.bot_type.slice(1);
		}
		return "";
	}, [bot?.bot_type]);

	const {
		data: paymentData,
		isLoading,
		error,
		refetch,
	} = useQuery({
		queryKey: ["ewallet-payment", paymentsService.methods],
		enabled: paymentsService.methods.some(x => x.provider === "ewallet"),
		queryFn: async () => {
			try {
				const response = await paymentsService.makePrePayment(true);
				return response?.data as unknown as PaymentData | null;
			} catch (err) {
				console.error("Payment data fetch error:", err);
				return null;
			}
		},
	});

	const userSessionId = React.useMemo(() => {
		let sessionId = sessionStorage.getItem("session-id");

		if (!sessionId) {
			sessionId = new Date().getTime().toString();
			sessionStorage.setItem("session-id", sessionId);
		}

		return sessionId;
	}, []);

	const {
		externalPaymentStatus,
		externalPaymentError,
		setExternalPaymentStatus,
	} = useSSEPaymentStatus(
		paymentData?.payment_id,
		userSessionId,
		groupService.group?.id,
	);

	React.useEffect(() => {
		if (externalPaymentStatus === "payed") {
			paymentsService.setSuccessExternalOnlinePayment();
		}
	}, [externalPaymentStatus]);

	// Retry payment handler
	const handleRetryPayment = async () => {
		if (!paymentData?.payment_id) return;

		try {
			await api.invoice.cancelEWalletPayment(paymentData.payment_id);
			refetch();
			setExternalPaymentStatus(null);
		} catch (error) {
			console.error("Error try again payment:", error);
		}
	};

	// Loading state
	if (isLoading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
				<CircularProgress size={38} />
			</Box>
		);
	}

	// Error or no payment data handling
	if (error || !paymentData) {
		return (
			<Box textAlign="center" p={2}>
				<Typography color="error">
					{localisation.payment.providerErrorShort}
				</Typography>
			</Box>
		);
	}

	const retryButton = (
		<Link
			component={"button"}
			onClick={handleRetryPayment}
			sx={{ mt: 4 }}
			underline="hover"
		>
			{localisation.payment.tryAgain}
		</Link>
	);

	// Default state (null or undefined status)
	return (
		<Box textAlign="center">
			{externalPaymentStatus === null && paymentData.url && (
				<>
					<Box mx="auto" mb={2}>
						{f(localisation.global.gotoBotNameToPay, {
							"bot_name": botName,
							"amount_to_pay": paymentsService.calcSumToPay,
						})}
					</Box>
					<LoadingButton
						color={bot?.bot_type}
						size="large"
						component="a"
						href={paymentData.url}
						target="_blank"
						rel="noopener noreferrer"
						variant="contained"
					>
						{f(localisation.global.gotoBotNameToPayShort, { "bot_name": botName })}{" "}
					</LoadingButton>

					{!isMobile && (
						<>
							<Divider sx={{ my: 3 }}>{localisation.auth.orText}</Divider>
							<Box bgcolor="white" width="fit-content" mx="auto" mb={2}>
								<QRCodeSVG
									size={150}
									style={{
										maxWidth: "100%",
										maxHeight: "100%",
									}}
									value={paymentData.url || ""}
								/>
							</Box>
							<Typography fontSize="normal">
								{localisation.payment.eWalletPaymentQrText}
							</Typography>
						</>
					)}

					{additionalFieldsNode && (
						<Grid item xs={12}>
							{additionalFieldsNode}
						</Grid>
					)}
				</>
			)}

			{externalPaymentStatus === "pending" && (
				<Box textAlign="center" p={2}>
					<CircularProgress size={38} sx={{ mb: 2 }} />
					<Typography>
						{localisation.payment.waitingForPayment}
					</Typography>
					{retryButton}
				</Box>
			)}

			{externalPaymentError && externalPaymentStatus === "failed" && (
				<Box textAlign="center" p={2}>
					<Typography color="error" sx={{ mb: 2 }}>
						{externalPaymentError}
					</Typography>
					{retryButton}
				</Box>
			)}
		</Box>
	);
}
import {FormEvent, useState, useEffect} from "react";
import {Alert, Box, Button, CircularProgress, Container, Paper, Typography} from "@mui/material";

import useSearchParamsFixed from "../features/hooks/useSearchParamsFixed";
import api from "../api";
import {ResetPasswordData} from "../api/auth/types";
import TextField from "@mui/material/TextField";
import useAppContext from "../useAppContext";
import Interweave from "../features/Interweave";

export default function ResetPasswordPage() {
    const {localisation: {auth}, setPageTitle} = useAppContext()

    const [searchParams] = useSearchParamsFixed()
    const token = searchParams.get('token')
    const email = searchParams.get('email')

    const [success, setSuccess] = useState<boolean>(false)

    useEffect(() => {
        setPageTitle(auth.resetPasswordHeader)
    }, [auth.resetPasswordHeader, setPageTitle])

    if(!token || !email) return <>{auth.resetPassEmailOrTokenErr}</>

    return (
        <Container
            maxWidth={'sm'}
            sx={{
                p: 0,
                mt: 3,
                flex: 1,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Paper elevation={3} sx={{width: '100%', p: 3}}>
                <Typography variant={'h3'} className={'border-bottom mb-2 pb-2'}>
                    {auth.resetPasswordHeader}
                </Typography>

                <Box sx={{mb: 3}}>
                    {success
                        ? <>{auth.resetPassSuccess}</>
                        :
                            <Interweave
                                content={auth.resetPassSubHeader.replace(
                                    '{email}', email
                                )}
                            />
                    }
                </Box>

                {!success && (
                    <ResetPasswordForm email={email} token={token} setSuccess={setSuccess} />
                )}
            </Paper>
        </Container>
    )
}

interface IResetPasswordFormProps {
    token: string
    email: string
    setSuccess: (success: boolean) => void
}

function ResetPasswordForm(props: IResetPasswordFormProps) {
    const {localisation: {auth, global}} = useAppContext()

    const [password, setPassword] = useState<string>('')
    const [passwordConfirm, setPasswordConfirm] = useState<string>('')
    const [apiError, setApiError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const submit = async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(password !== passwordConfirm) {
            setApiError(auth.passwordsDoNotMatchError)
            return
        }

        try{
            setApiError(null)
            setIsLoading(true)
            const payload: ResetPasswordData = {
                current_email: props.email,
                new_password: password,
            }
            const response = await api.auth.resetPassword(payload, props.token)
            if(response.status === 200){
                props.setSuccess(true)
            }
        }
        catch(ex: any) {
            setApiError(ex.response?.data?.detail || ex.message || "unknown error")
            props.setSuccess(false)
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <form onSubmit={submit}>
            <TextField
                sx={{mb: 2}}
                required
                size={'medium'} fullWidth
                id={'password'}
                type={'password'}
                label={auth.newPasswordLabel}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <TextField
                required
                size={'medium'} fullWidth
                id={'passwordConfirm'} type={'password'}
                label={auth.repeatNewPasswordLabel}
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
            />

            {!!apiError && (
                <Alert
                    severity={'error'}
                    variant={'outlined'}
                    className={'small p-3 w-100 mt-2'}>
                    <Interweave content={apiError} />
                </Alert>
            )}

            <Button
                variant={'contained'}
                sx={{width: '100%', mt: 3}}
                size={'large'}
                type={'submit'}
                disabled={isLoading}
            >
                {isLoading && (
                    <CircularProgress className={'me-2'} style={{width: '18px', height: '18px'}} />
                )}
                {global.confirmButton}
            </Button>
        </form>
    )
}

import {useMemo} from "react";

interface IDeviceInfo {
    userAgent: string
    browser: string
    os: string
}


const userAgent = navigator.userAgent;


export default function useDeviceInfo(): {info: IDeviceInfo} {
    return useMemo(() => {
        // Function to extract browser information
        const getBrowserInfo = () => {
            const browsers: { [key: string]: RegExp } = {
                Chrome: /Chrome\/([\d.]+)/,
                Edge: /Edge\/([\d.]+)/,
                Firefox: /Firefox\/([\d.]+)/,
                Opera: /OPR\/([\d.]+)/,
                Safari: /Safari\/([\d.]+)/,
                InternetExplorer: /(Trident\/[\d.]+)|(MSIE\s([\d.]+))/
            }

            for (const browser in browsers) {
                if (browsers[browser].test(userAgent)) {
                  return browser
                }
            }
            return "Unknown"
        }

        // Function to extract OS information
        const getOSInfo = () => {
            const osRegex = /(Windows\s[\w.\s]+)|(Mac\sOS\sX\s[\w.\s]+)|(Linux)|(Android\s[\d.]+)|(iOS\s[\d.]+)/
            const osMatch = userAgent.match(osRegex)

            if (osMatch) {
                return osMatch[0].trim()
            }
            return "Unknown"
        }

        return {
            info: {
                userAgent: userAgent,
                browser: getBrowserInfo(),
                os: getOSInfo(),
            }
        }
    }, [])
}

import { atom } from "recoil";

export interface IOAuthLoginState {
	oauthToken: string;
	firstName: string;
	lastName: string;
	email: string;
	is_accept_profile_agreement?: boolean | null;
}

export const initialOAuthLoginState = {
	oauthToken: "",
	firstName: "",
	lastName: "",
	email: "",
	is_accept_profile_agreement: null,
};

export const oauthLoginState = atom<IOAuthLoginState>({
	key: "oauthLoginState",
	default: initialOAuthLoginState,
});

import { ThemeModalProps } from "./types";
import { Box, Dialog, DialogContent, DialogProps, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";
import { useMemo } from "react";
import useScreenService, { ScreenSize } from "../../services/useScreenService";
import SwipeableDrawerWrapper from "../../features/SwipeableDrawerWrapper";

export default function ThemeModal({
	setOpen,
	title,
	disableClose = false,
	children,
	onClose,
	contentProps: { className: contentClassName, ...contentProps } = {},
	maxWidth = "sm",
	swipeable = false,
	...props
}: ThemeModalProps) {
	const { screenSize } = useScreenService();

	const dialogProps: DialogProps = useMemo(() => {
		const { PaperProps, ...otherDialogProps } = props;

		if (!PaperProps) {
			return {
				PaperProps: {
					sx: {
						borderRadius: props.fullScreen
							? 0
							: screenSize < ScreenSize.MD
								? ".5rem"
								: "1rem",
					},
				},
				...otherDialogProps,
			};
		}

		const { sx: PaperSx, ...otherPaperProps } = PaperProps;

		return {
			PaperProps: {
				sx: {
					borderRadius: props.fullScreen
						? 0
						: screenSize < ScreenSize.MD
							? ".5rem"
							: "1rem",
					...PaperSx,
				},
				...otherPaperProps,
			},
			...otherDialogProps,
		};
	}, [props, screenSize]);

	return (
		<>
			{swipeable ? (
				<SwipeableDrawerWrapper
					open={props.open}
					setOpen={(val: boolean) => setOpen && setOpen(val)}
				>
					<ModalContent
						setOpen={setOpen}
						title={title}
						disableClose={disableClose}
						children={children}
						onClose={onClose}
						contentProps={contentProps}
						maxWidth={maxWidth}
						{...props}
					/>
				</SwipeableDrawerWrapper>
			) : (
				<Dialog
					maxWidth={maxWidth}
					onClose={(event, reason) => {
						if (onClose) {
							onClose(event, reason);
						}
						setOpen && setOpen(false);
					}}
					{...dialogProps}
				>
					<ModalContent
						setOpen={setOpen}
						title={title}
						disableClose={disableClose}
						children={children}
						onClose={onClose}
						contentProps={contentProps}
						maxWidth={maxWidth}
						{...props}
					/>
				</Dialog>
			)}
		</>
	);
}

function ModalContent({
	setOpen,
	title,
	replaceTitle,
	disableClose = false,
	children,
	onClose,
	contentProps: { className: contentClassName, ...contentProps } = {},
	fullScreen,
	additionalCloseNode,
}: ThemeModalProps) {
	return (
		<>
			{(!!title || !disableClose) && (
				<Box
					bgcolor={"rgba(0, 0, 0, 0.06)"}
					display={"flex"}
					alignItems={"center"}
					border={1}
					pl={3}
					pr={2}
					py={1}
					sx={{
						borderTopLeftRadius: fullScreen ? 0 : { xs: ".5rem", md: "1rem" },
						borderTopRightRadius: fullScreen ? 0 : { xs: ".5rem", md: "1rem" },
						borderColor: "divider",
					}}
				>
					{replaceTitle ? (
						title
					) : (
						<Typography variant={"h6"} flex={1} fontWeight={"bold"}>
							{title}
						</Typography>
					)}
					{!disableClose && (
						<>
							{additionalCloseNode && additionalCloseNode}
							<IconButton
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									setOpen && setOpen(false);
									if (onClose) {
										onClose(e, "closePressed");
									}
								}}
							>
								<CloseIcon fontSize={"large"} />
							</IconButton>
						</>
					)}
				</Box>
			)}
			<DialogContent
				className={classnames("overflow-x-hidden mw-100", contentClassName)}
				{...contentProps}
			>
				{children}
			</DialogContent>
		</>
	);
}

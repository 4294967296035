import { useState, useEffect, useMemo } from "react";
import { MapContainer, TileLayer, Polygon, Marker, Popup, Circle, Tooltip } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import { useMap } from "react-leaflet";

import DefaultIcon from "../../static/stubs/shop-marker-stub.webp";
import { getImageSize } from "../../helpers";
import { useShopContext } from "../context";
import { useSelectedStore } from "../SelectedStore/context";
import { IZonePolygon, IZoneRadius } from "../services/useShipmentPrices";
import useAppContext from "../../useAppContext";
import EmbedMap from "../../features/Maps/EmbedMap";

interface IMapProps {
	className?: string;
	polygons?: IZonePolygon[];
	radius?: IZoneRadius[];
	height?: string;
}

export default function EmbeddedShopMap(props: IMapProps) {
	const { lang } = useAppContext();
	const { brandInfo } = useShopContext();
	const selectedStore = useSelectedStore();

	const [needShowMap, setNeedShowMap] = useState(false);
	const [icon, setIcon] = useState<L.Icon | null>(null);

	const checkIsNeedShowMap = () => {
		if (!selectedStore.latitude || !selectedStore.longitude) return false;

		if (/\d/.test(selectedStore.latitude) && /\d/.test(selectedStore.longitude)) {
			setNeedShowMap(true);
			return true;
		}
		setNeedShowMap(false);
		return false;
	};

	const buildIcon = async () => {
		let size: [number, number] = [25, 41];
		if (brandInfo.logo_url) {
			const sizeImg = await getImageSize(brandInfo.logo_url, 50);
			size = [sizeImg.width, sizeImg.height];
		}

		setIcon(
			new L.Icon({
				iconUrl: brandInfo.logo_url || DefaultIcon,
				iconSize: size,
				iconAnchor: size,
			})
		);
	};

	const computedPolygon = (): LatLngExpression[] | null => {
		if (!selectedStore.polygon) return null;

		const polygonObj = selectedStore.polygon;

		return Object.keys(polygonObj).map((k: string) => {
			return [parseFloat(k), parseFloat(polygonObj[k])];
		});
	};

	const MapComponent = () => {
		const map = useMap();

		useEffect(() => {
			if (selectedStore.latitude && selectedStore.longitude)
				map.setView(
					[parseFloat(selectedStore.latitude), parseFloat(selectedStore.longitude)],
					13
				);
		}, [selectedStore.latitude, selectedStore.longitude]);

		return null;
	};

	const computedPolygons: IMapWithAttributesPolygon[] | null = useMemo(() => {
		if (props.polygons) {
			const polygons: IMapWithAttributesPolygon[] = [];
			props.polygons.forEach(polygon => {
				const formattedPoly: number[][] = polygon.polygon;
				const poly: LatLngExpression[] = formattedPoly.map(poly => {
					const item: LatLngExpression = {
						lat: poly[0],
						lng: poly[1],
					};
					return item;
				});
				const obj: IMapWithAttributesPolygon = {
					polygon: poly,
					title: polygon.pricesString,
					color: polygon.color || "",
				};
				polygons.push(obj);
			});
			return polygons;
		}
		return [];
	}, [props.polygons]);

	useEffect(() => {
		const needShow = checkIsNeedShowMap();
		if (needShow) buildIcon().then();
	}, []);

	if (!(needShowMap && brandInfo && icon && selectedStore.latitude && selectedStore.longitude))
		return null;

	const latitude = parseFloat(selectedStore.latitude);
	const longitude = parseFloat(selectedStore.longitude);
	const address = selectedStore.custom_fields.find(x => x.name === "address")?.value || "";
	const polygon = computedPolygon();

	return (
		<div style={{ width: "auto", height: props.height ? props.height : "300px" }}>
			{!!brandInfo?.google_maps_public_api_key ? (
				<EmbedMap
					lang={lang || ""}
					apiKey={brandInfo.google_maps_public_api_key}
					centerLat={latitude}
					centerLng={longitude}
					height={props.height || "300px"}
				/>
			) : (
				<MapContainer
					center={[latitude, longitude]}
					zoom={13}
					style={{ width: "auto", height: "100%" }}
					className={props.className}
				>
					<MapComponent />
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
                            OpenStreetMap</a> contributors'
						url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
					/>
					<Marker position={[latitude, longitude]} icon={icon}>
						<Popup>
							<div className={"fw-bold"}>{selectedStore.name}</div>
							<div>{address}</div>
						</Popup>
					</Marker>
					{polygon && (
						<Polygon
							pathOptions={{ color: "lime", fillColor: "green" }}
							positions={polygon}
						/>
					)}

					{!!computedPolygons.length &&
						computedPolygons.map((polygon, index) => (
							<Polygon
								pathOptions={{ color: "lime", fillColor: "green" }}
								positions={polygon.polygon}
								key={index}
							>
								<Tooltip>{polygon.title}</Tooltip>
							</Polygon>
						))}

					{selectedStore.distance && (
						<Circle
							center={[latitude, longitude]}
							pathOptions={{ color: "lime", fillColor: "green" }}
							radius={selectedStore.distance}
						/>
					)}

					{!!props.radius &&
						props.radius.map((rad: IZoneRadius) => (
							<Circle
								center={[latitude, longitude]}
								pathOptions={{ color: "lime", fillColor: "green" }}
								radius={rad.radius}
								key={rad.radius}
							>
								<Tooltip>{rad.pricesString}</Tooltip>
							</Circle>
						))}
				</MapContainer>
			)}
		</div>
	);
}

interface IMapWithAttributesPolygon {
	polygon: LatLngExpression[];
	title: string;
	color?: string;
}

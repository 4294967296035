import ChatMessageDate from "./ChatMessageDate";
import ChatMessage from "./ChatMessage";
import {ChatMessageType} from "./types";


interface ChatDateMessagesProps {
    dateStr: string
    messages: ChatMessageType[]
    recommendedProductsText?: string
    needNote?: boolean
}

export default function ChatDateMessages({dateStr, messages, recommendedProductsText, needNote}: ChatDateMessagesProps) {
    return (<>
        <ChatMessageDate>
            {dateStr}
        </ChatMessageDate>

        {messages.map(message => (
            <ChatMessage
                key={message.id}
                message={message}
                recommendedProductsText={recommendedProductsText}
                needNote={needNote}
            />
        ))}
    </>)
}

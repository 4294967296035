import formatCurrency from "../helpers/formatCurrency";

export type CurrencyInfo = {
	symbol: string;
	position: "start" | "end";
};

export default function useCurrencyInfo(locales: string | null, currency: string): CurrencyInfo {
	const currencyString = formatCurrency(1, locales, currency);

	// get currency symbol
	const symbol = currencyString.replace(/[0-9\s.,]/g, "");

	// get currency position
	const position = currencyString.indexOf(symbol) === 0 ? "start" : "end";

	return {
		symbol,
		position,
	};
}

import { useMemo, useState, useEffect } from "react";

import { Invoice } from "../../../api/invoice/types";
import { ApiUrl } from "../../../config";
import StubImage from "../../../static/stubs/shop-image-stub.webp";
import api from "../../../api";
import { IInvoiceService } from "./types";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import { User } from "../../../api/auth/types";
import useAppContext from "../../../useAppContext";
import changePage from "../../../helpers/changePage";

export default function useInvoiceService(
	id: string | number | null = null,
	invoice_token: string | null = null,
	lang: string | null = null,
	user: User | null = null
): IInvoiceService {
	const {
		loadingManager: { setIsLoading },
	} = useAppContext();

	const [invoice, setInvoice] = useState<Invoice | null>(null);
	const [searchParams] = useSearchParamsFixed();
	const [error, setError] = useState("");
	const [invoiceToken, setInvoiceToken] = useState<string | null>(null);
	const [clientRedirectUrl, setClientRedirectUrl] = useState<string | null>(null);

	const computedInvoiceImage = useMemo(() => {
		if (invoice && invoice.photo) {
			let processedImagePath = invoice.photo;
			if (processedImagePath.startsWith("http")) {
				return processedImagePath;
			}
			if (!processedImagePath.startsWith("static")) {
				processedImagePath = `static/${processedImagePath}`;
			}
			return `${ApiUrl}/${processedImagePath}`;
		}

		return StubImage;
	}, [invoice]);

	const redirectToInvoicePage = () => {
		if (id) {
			return changePage(`/shop/invoice/${id}`);
		}
		changePage(`/shop/invoice`, {
			invoice_token: invoiceToken,
		});
	};

	useEffect(() => {
		if (!lang) return;
		setClientRedirectUrl(searchParams.get("client_redirect_url"));

		if (id && user) {
			const invoiceId = typeof id === "string" ? parseInt(id) : id;

			setIsLoading("invoice", true);
			api.invoice
				.getInvoice(invoiceId)
				.then((response: any) => {
					if (response.status === 200 && response.data) return setInvoice(response.data);
				})
				.catch((err: any) => {
					if (err.response.data) return setError(err.response.data.detail);
					setError(err.message);
				})
				.finally(() => setIsLoading("invoice", false));
		} else {
			let token = searchParams.get("invoice_token");
			if (!token) token = invoice_token;

			if (token) {
				setInvoiceToken(token);
				setIsLoading("invoice", true);
				api.invoice
					.getInvoiceByToken(token)
					.then((response: any) => {
						if (response.status === 200 && response.data)
							return setInvoice(response.data);
					})
					.catch((err: any) => {
						if (err.response.data) return setError(err.response.data.detail);
						setError(err.message);
					})
					.finally(() => setIsLoading("invoice", false));
			}
		}
	}, [id, invoice_token, lang, searchParams, setIsLoading, user]);

	const getClientRedirectLink = (is_success: boolean) => {
		if (!invoice?.client_redirect_url) return null;

		const url = new URL(invoice.client_redirect_url);

		if (url.hostname !== window.location.hostname) {
			url.searchParams.set("is_success", (+is_success).toString());
			url.searchParams.set("invoice_id", invoice.id.toString());
			if (invoice.external_transaction_id) {
				url.searchParams.set("external_transaction_id", invoice.external_transaction_id);
			}
		}

		return url.toString();
	};

	const getRedirectClient = (is_success: boolean) => () => {
		const link = getClientRedirectLink(is_success);
		if (link) {
			window.location.href = link;
		}
	};

	useEffect(() => {
		if (searchParams.get("invoice_token") && !invoiceToken) {
			setInvoiceToken(searchParams.get("invoice_token"));
		}
	}, [invoiceToken, searchParams]);

	return {
		invoice,
		computedInvoiceImage,
		error,
		invoiceToken,
		redirectToInvoicePage,
		clientRedirectUrl,
		getRedirectClient,
	};
}

import {useSelectedStoreContext} from "../../../../SelectedStore/context";
import useAppContext from "../../../../../useAppContext";
import { Typography } from "@mui/material";
import {SelectedShipmentServiceType} from "../../useSelectedShipmentService";


interface ShippingNoteProps {
    selectedShipmentService: SelectedShipmentServiceType
}


export default function ShippingNote({selectedShipmentService: {selectedShipment}}: ShippingNoteProps) {
    const {localisation} = useAppContext()
    const {shipmentsService: {shipmentsCount}} = useSelectedStoreContext()

    return (
        <div className="d-flex theme-text">
            {!selectedShipment && (
                <Typography fontWeight={'bold'} sx={{mt: 2}}>
                    {shipmentsCount === 0 ? (
                        localisation.orders.notAvailableDeliveries
                    ) : (
                        localisation.orders.needSelectDelivery
                    )}
                </Typography>
            )}
        </div>
    )
}

import {CustomShipment, CustomShipmentGroup, Shipment, ShipmentPrice} from "../../../api/shop/basic/types";
import {SetState} from "../../../types";
import {useMemo, useState} from "react";
import {ShipmentBaseTypeLiteral} from "../../types";
import {useShopContext} from "../../context";
import {useSelectedStoreContext} from "../../SelectedStore/context";


export default function useSelectedShipmentService(
    totalSum: number,
): SelectedShipmentServiceType {
    const {menuInStoreService} = useShopContext()
    const {shipmentsService} = useSelectedStoreContext()

    const [selectedShipment, setSelectedShipment] = useState<SelectedShipmentType>(null)
    const [selectedCustomShipmentGroup, setSelectedCustomShipmentGroup] = useState<CustomShipmentGroup | null>(null)

    const [showAddressCommentInput, setShowAddressCommentInput] = useState(false)
    const [selectedShipmentPrice, setSelectedShipmentPrice] = useState<ShipmentPrice | null>(null)

    const errors = useMemo((): SelectedShipmentServiceErrorsType => {
        const isMinAmountError = !!selectedShipment?.min_price &&  totalSum < selectedShipment.min_price
        const isMaxAmountError = !!selectedShipment?.max_price && totalSum > selectedShipment.max_price
        const isCriticalError = isMaxAmountError || isMaxAmountError

        return {
            isMinAmountError,
            isMaxAmountError,
            isCriticalError,
        }
    }, [selectedShipment?.max_price, selectedShipment?.min_price, totalSum])

    const showShipments = useMemo(() => {
        if (menuInStoreService.menuInStore?.id) return false

        if (shipmentsService.shipmentsCount > 1) return true

        if (selectedShipment?.delivery_datetime_mode !== "disabled") return true

        if (selectedShipment?.custom_type === 'shipment') {
            if(selectedShipment.prices.length > 0 &&
                selectedShipment.prices
                    .some(x => x.maximum_order_amount > 0 || x.minimum_order_amount > 0)) return true
            return selectedShipment.base_type === 'delivery'
        }

        if (selectedShipment?.custom_type === 'custom_shipment') {
            if(selectedShipment.prices.length > 0 &&
                selectedShipment.prices
                    .some(x => x.maximum_order_amount > 0 || x.minimum_order_amount > 0)) return true
            return selectedShipment.need_comment
        }

        return true
    }, [
        selectedShipment,
        shipmentsService.shipmentsCount,
        menuInStoreService.menuInStore?.id,
    ])

    return useMemo((): SelectedShipmentServiceType => ({
        showShipments,
        selectedShipment,
        setSelectedShipment,
        is_custom_shipment: selectedShipment?.custom_type === 'custom_shipment',
        is_base_shipment: selectedShipment?.custom_type === 'shipment',
        base_type: selectedShipment?.base_type || null,
        selectedCustomShipmentGroup,
        setSelectedCustomShipmentGroup,
        errors,
        showAddressCommentInput,
        setShowAddressCommentInput,
        setSelectedShipmentPrice,
        selectedShipmentPrice,
    }), [
        errors,
        showShipments,
        selectedShipment,
        setSelectedShipment,
        selectedCustomShipmentGroup,
        setSelectedCustomShipmentGroup,
        showAddressCommentInput,
        setShowAddressCommentInput,
        setSelectedShipmentPrice,
        selectedShipmentPrice,
    ])
}


export type SelectedShipmentServiceType = {
    selectedShipment: SelectedShipmentType
    setSelectedShipment: SetState<SelectedShipmentType>

    is_base_shipment: boolean
    is_custom_shipment: boolean
    base_type: ShipmentBaseTypeLiteral | null

    showShipments: boolean

    selectedCustomShipmentGroup: CustomShipmentGroup | null
    setSelectedCustomShipmentGroup: SetState<CustomShipmentGroup | null>

    errors: SelectedShipmentServiceErrorsType
    showAddressCommentInput: boolean,
    setShowAddressCommentInput: SetState<boolean>,
    setSelectedShipmentPrice: SetState<ShipmentPrice | null>,
    selectedShipmentPrice: ShipmentPrice | null,
}


export type SelectedShipmentServiceErrorsType = {
    isMinAmountError: boolean
    isMaxAmountError: boolean
    isCriticalError: boolean
}


export type SelectedShipmentType = Shipment | CustomShipment | null

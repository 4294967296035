import {ThemeModal} from "../../../helpers/ThemeComponents";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import {useShopContext} from "../../context";
import {Button} from "@mui/material";

interface ICallManagerSuccessModalProps {
    show: boolean
    setShow: (show: boolean) => void
}

export default function QrMenuCallManagerModal(props: ICallManagerSuccessModalProps){
    const {menuInStoreService: {menuInStore}} = useShopContext()

    const localisation = useLocalisation()

    if (!menuInStore) return null

    return (
        <ThemeModal
            open={props.show}
            setOpen={props.setShow}
            title={menuInStore.texts.waiter.call_button || ''}
        >
            <div className={'text-center w-100'}>
                <div className={'fw-bold mb-3 fs-4'}>
                    {menuInStore.texts.waiter.called_text}
                </div>
                <Button
                    variant={'contained'}
                    onClick={() => props.setShow(false)}
                >
                    {localisation.global.close}
                </Button>
            </div>
        </ThemeModal>
    )
}

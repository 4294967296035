import { useEffect, useMemo, useState } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import {
	AttributeError,
	AttributesViewType,
	ProductAttributeGroupsProps,
	SelectedAttribute,
} from "./types";
import { Attribute, AttributeGroup, Product } from "../../../../api/shop/basic/types";
import formatCurrency from "../../../../helpers/formatCurrency";
import { useSelectedStore, useSelectedStoreContext } from "../../../SelectedStore/context";
import useAppContext from "../../../../useAppContext";
import { SetState } from "../../../../types";
import { CreateCartAttributeData } from "../../../../api/shop/cart/types";
import AttributeGroupComponent from "./components/AttributeGroup";
import AttributeItem from "./components/AttributeItem";
import { AttributeGroups } from "../DetailedProduct";
import useCartProduct from "../../../services/useCartService/hooks/useCartProduct";

export default function ProductAttributeGroups({
	product,
	cartProduct,
	isProductInCart,
	selectedAttributes,
	setSelectedAttributes,
	selectAttribute,
	unselectAttribute,
	setAttributeQuantity,
	customClass = "",
}: ProductAttributeGroupsProps) {
	const selectedStore = useSelectedStore();

	const [attrError, setAttrError] = useState<AttributeError[] | null>(null);

	const attributeGroups = useMemo(() => {
		return product?.attribute_groups || [];
	}, [product?.attribute_groups]);

	useEffect(() => {
		if (isProductInCart) return;

		setTimeout(() => {
			setSelectedAttributes(prevState => {
				let isChanged = false;
				const defaultAttributes: CreateCartAttributeData[] = [];
				attributeGroups.forEach(attrGroup =>
					attrGroup.attributes.forEach(attr => {
						if (
							attr.selected_by_default ||
							(attrGroup.min === 1 &&
								attrGroup.max === 1 &&
								attrGroup.attributes &&
								attrGroup.attributes.length > 0)
						) {
							let defaultAttr: CreateCartAttributeData | null = null;
							if (attr.selected_by_default) {
								defaultAttr = {
									attribute_id: attr.id,
									quantity: attr.min || 1,
								};
								defaultAttributes.push(defaultAttr);
								if (
									!prevState.some(prevAttr => {
										return (
											prevAttr.attribute_id === defaultAttr?.attribute_id &&
											prevAttr.quantity === defaultAttr?.quantity
										);
									})
								) {
									isChanged = true;
								}
							} else {
								if (
									!attributeGroups.some(x =>
										x.attributes.some(a => a.selected_by_default)
									)
								) {
									defaultAttr = {
										attribute_id: attrGroup.attributes[0].id,
										quantity: attrGroup.attributes[0].min || 1,
									};
								}
							}
							if (
								defaultAttr &&
								!defaultAttributes.some(
									x => x.attribute_id === defaultAttr?.attribute_id
								)
							) {
								defaultAttributes.push(defaultAttr);
								if (
									!prevState.some(prevAttr => {
										return (
											prevAttr.attribute_id === defaultAttr?.attribute_id &&
											prevAttr.quantity === defaultAttr?.quantity
										);
									})
								) {
									isChanged = true;
								}
							}
						}
					})
				);

				console.log("set default attributes", {
					prevState,
					isChanged,
					defaultAttributes,
				});

				if (!isChanged) return prevState;

				if (!prevState.length) {
					return defaultAttributes;
				}

				return prevState.map(prevAttr => {
					const defaultAttr = defaultAttributes.find(defaultAttr => {
						return defaultAttr.attribute_id === prevAttr.attribute_id;
					});
					if (defaultAttr) {
						return defaultAttr;
					}
					return prevAttr;
				});
			});
		}, 100);
	}, [attributeGroups, isProductInCart, setSelectedAttributes]);

	if (!product || !selectedStore) return null;

	return (
		<>
			{!!product && (
				<MDBRow className={`${customClass}`}>
					{attributeGroups.map(attrGroup => (
						<MDBCol size="12" key={attrGroup.id} className={"mb-2"}>
							<AttributeGroupComponent
								attrGroup={attrGroup}
								product={product}
								cartProduct={cartProduct}
								attrError={attrError}
								setAttrError={setAttrError}
								selectedAttributes={selectedAttributes}
								attributeGroups={attributeGroups}
								selectAttribute={selectAttribute}
								unselectAttribute={unselectAttribute}
								setAttributeQuantity={setAttributeQuantity}
							/>
						</MDBCol>
					))}
				</MDBRow>
			)}
		</>
	);
}

interface AttributeComponentProps {
	product: Product;

	attr: Attribute | null;
	attrGroup: AttributeGroup;
	getSelectedAttribute: (attrId: number) => SelectedAttribute | null;

	attrError: AttributeError[] | null;
	setAttrError: SetState<AttributeError[] | null>;

	selectedAttributes: CreateCartAttributeData[];

	selectAttribute: (attributeId: number, quantity?: number) => any;
	unselectAttribute: (attributeId: number, needSelectAnotherAttrId: number | null) => any;
	setAttributeQuantity: (attributeId: number, quantity: number) => any;
	viewType: AttributesViewType;

	setErrorAnchor: SetState<any>;
	setPopoverText: SetState<string | null>;
	popoverText: string | null;
}

export function AttributeComponent({
	product,
	attr,
	attrGroup,
	getSelectedAttribute,
	attrError,
	setAttrError,
	selectedAttributes,
	selectAttribute,
	unselectAttribute,
	setAttributeQuantity,
	viewType,
	setErrorAnchor,
	setPopoverText,
	popoverText,
}: AttributeComponentProps) {
	const { lang, localisation, brandInfo } = useAppContext();
	const selectedStore = useSelectedStore();

	const selectedAttr = attr ? getSelectedAttribute(attr.id) : null;
	const isSelected = !!selectedAttr;

	const getAttrById = (attrId: number) => {
		let attribute: Attribute | null = null;

		for (const attrGroup of product.attribute_groups) {
			const tempAttr = attrGroup.attributes.find(x => x.id === attrId);
			if (tempAttr) {
				attribute = tempAttr;
				break;
			}
		}
		return attribute;
	};

	const calculateAttributeGroupCount = (attrGroupId: number) => {
		return selectedAttributes.reduce((acc, sAttr) => {
			const attr = getAttrById(sAttr.attribute_id);
			if (attr?.attribute_group_id !== attrGroupId) {
				return acc;
			}

			return acc + sAttr.quantity;
		}, 0);
	};

	const processAttributeChange = (
		attrId: number | null,
		quantity: number | "+1" | "-1" | "check",
		radio: boolean = false,
		forceUnselect: boolean = false
	) => {
		if (forceUnselect && attrId) {
			unselectAttribute(attrId, null);
		}

		if (!attrId) {
			if (radio) {
				attrGroup.attributes.forEach(x => {
					if (selectedAttributes.some(sAttr => sAttr.attribute_id === x.id)) {
						unselectAttribute(x.id, null);
					}
				});
			}
			return;
		}
		let selectedAttr = getSelectedAttribute(attrId);
		if (radio) selectedAttr = null;

		let newQuantity = selectedAttr?.quantity || 0;
		let diff = 1;

		switch (quantity) {
			case "-1":
				if (newQuantity < 1) {
					throw new Error("Cannot set quantity less then 0");
				}
				if (attr?.min && attr.min > 1 && selectedAttr?.quantity === attr.min) {
					newQuantity -= attr.min;
					diff = -attr.min;
				} else {
					newQuantity -= 1;
					diff = -1;
				}
				break;
			case "+1":
				if (
					attr?.min &&
					attr.min > 1 &&
					(!selectedAttr?.quantity || (selectedAttr && selectedAttr.quantity < attr.min))
				) {
					newQuantity += attr.min;
					diff = attr.min;
				} else {
					newQuantity += 1;
					diff = 1;
				}
				break;
			case "check":
				if (newQuantity >= 0) {
					newQuantity = 1;
					diff += 1;
				} else {
					newQuantity = 0;
				}
				break;
			default:
				diff = quantity - newQuantity;
				newQuantity = quantity;
		}
		if (newQuantity < 1 && !selectedAttr) {
			throw new Error(`Cannot remove attribute. Attribute ${attrId} is not selected`);
		}

		if (!attr) return null;
		if (!attrGroup) return;

		const selectedGroupAttributesCount = calculateAttributeGroupCount(attrGroup.id);

		// exit if more than max attributes count for an attribute group
		if (
			!radio &&
			attrGroup.max &&
			selectedGroupAttributesCount + diff >= attrGroup.max &&
			attrGroup.attributes.length !== attrGroup.max
		) {
			console.log("max attr group error", {
				attr,
				attrGroup,
				selectedGroupAttributesCount,
				diff,
			});
			setAttrError(prevState => {
				const data = {
					attr: attr,
					isGroupError: true,
					message: localisation.global.errMaxAttributes
						.replace("{qty}", (attrGroup.max ? attrGroup.max : 1).toString())
						.replace("{attr_group_name}", attrGroup.name),
					isMinus: false,
				};
				if (!prevState) {
					return [data];
				}
				return [...prevState, data];
			});
		} else {
			setAttrError(prevState => {
				if (prevState) {
					return (
						prevState
							// eslint-disable-next-line array-callback-return
							.filter(x => {
								if (x.attr.attribute_group_id === attrGroup.id && x.isGroupError) {
									if (x.isMinus) return x;
								} else {
									return x;
								}
							})
					);
				}
				return prevState;
			});
		}

		// exit if more than max attributes count for an attribute
		if (!radio && selectedAttr && attr.max && selectedAttr.quantity + diff >= attr.max) {
			setAttrError(prevState => {
				const data = {
					attr: attr,
					message: localisation.global.errMaxAttribute
						.replace("{qty}", (attr.max ? attr.max : 1).toString())
						.replace("{group_name}", attrGroup.name)
						.replace("{name}", attr.name),
					isMinus: false,
				};
				if (!prevState) {
					return [data];
				}
				return [...prevState, data];
			});
		} else {
			setAttrError(prevState => {
				if (prevState) {
					return (
						prevState
							// eslint-disable-next-line array-callback-return
							.filter(x => {
								if (x.attr.attribute_id === attr.attribute_id) {
									if (x.isMinus || x.isGroupError) return x;
								} else {
									return x;
								}
							})
					);
				}
				return prevState;
			});
		}

		// show error if attributes count less than attribute's min count, but not exit
		if (
			!radio &&
			selectedAttr &&
			attr.min &&
			selectedAttr.quantity + diff < attr.min &&
			newQuantity !== 0
		) {
			setAttrError(prevState => {
				const data = {
					attr: attr,
					message: localisation.global.errMinAttribute
						.replace("{qty}", (attr.min ? attr.min : 1).toString())
						.replace("{group_name}", attrGroup.name)
						.replace("{name}", attr.name),
					isMinus: true,
				};
				if (!prevState) {
					return [data];
				}
				return [...prevState, data];
			});
		} else {
			setAttrError(prevState => {
				if (prevState) {
					return (
						prevState
							// eslint-disable-next-line array-callback-return
							.filter(x => {
								if (x.attr.attribute_id === attr.attribute_id) {
									if (!x.isMinus || x.isGroupError) return x;
								} else {
									return x;
								}
							})
					);
				}
				return prevState;
			});
		}

		// show error if total group's selected attributes less than group's min count, but not exit
		if (!radio && selectedGroupAttributesCount + diff < attrGroup.min && newQuantity !== 0) {
			setAttrError(prevState => {
				const data = {
					attr: attr,
					isGroupError: true,
					message: localisation.global.errMinAttributes
						.replace("{qty}", attrGroup.min.toString())
						.replace("{attr_group_name}", attrGroup.name),
					isMinus: true,
				};
				if (!prevState) {
					return [data];
				}
				return [...prevState, data];
			});
		} else {
			setAttrError(prevState => {
				if (prevState) {
					return (
						prevState
							// eslint-disable-next-line array-callback-return
							.filter(x => {
								if (x.attr.attribute_group_id === attrGroup.id && x.isGroupError) {
									if (!x.isMinus) return x;
								} else {
									return x;
								}
							})
					);
				}
				return prevState;
			});
		}

		if (newQuantity === 0) {
			unselectAttribute(attrId, null);
		} else if (!selectedAttr) {
			if (radio) {
				const attrsForUnselect = attrGroup.attributes.filter(x => x.id !== attrId);
				attrsForUnselect.forEach(x => {
					if (selectedAttributes.some(sAttr => sAttr.attribute_id === x.id)) {
						unselectAttribute(x.id, null);
					}
				});
			}
			selectAttribute(attrId, newQuantity);
		} else {
			setAttributeQuantity(attrId, newQuantity);
		}
	};

	const calculateAttributePrice = useMemo(() => {
		let price = attr?.price_impact;
		if (selectedAttr) {
			price = selectedAttr.attribute.price_impact * selectedAttr.quantity;
		}

		return formatCurrency(price || 0, brandInfo?.default_lang || lang, selectedStore.currency);
	}, [attr?.price_impact, brandInfo?.default_lang, lang, selectedAttr, selectedStore.currency]);

	const calculateAttributeQuantity = useMemo(() => {
		if (!selectedAttr) {
			return 0;
		}
		return selectedAttr.quantity;
	}, [selectedAttr]);

	return (
		<div key={attr?.id} className={"my-1"}>
			<AttributeItem
				type={viewType}
				selected={isSelected}
				changeCallback={processAttributeChange}
				attribute={attr}
				price={calculateAttributePrice}
				quantity={calculateAttributeQuantity}
				attributeGroup={attrGroup}
				selectedAttributes={selectedAttributes}
				attrError={attrError}
				setAttrError={setAttrError}
				setErrorAnchor={setErrorAnchor}
				setPopoverText={setPopoverText}
				popoverText={popoverText}
			/>
		</div>
	);
}

interface IAttributesRootProps {
	isModal?: boolean;
	selectedAttributes: CreateCartAttributeData[];
	setSelectedAttributes: SetState<CreateCartAttributeData[]>;
}

export function AttributesRoot(props: IAttributesRootProps) {
	const { cartService } = useSelectedStoreContext();

	const { product, cartProduct, isProductInCart } = useCartProduct();

	const getCartProductByCartProductId = cartService.getCartProductByCartProductId;
	const setSelectedAttributes = props.setSelectedAttributes;
	useEffect(() => {
		let attributes = cartProduct?.cart_attributes;
		if (cartService.selectedVariationCartProductId) {
			const cartProduct = getCartProductByCartProductId(
				cartService.selectedVariationCartProductId
			);
			if (cartProduct) {
				attributes = cartProduct.cart_attributes;
			}
		}
		if (attributes) {
			setSelectedAttributes(
				attributes.map(cartAttr => ({
					attribute_id: cartAttr.attribute.id,
					quantity: cartAttr.quantity,
				}))
			);
		}
	}, [
		cartProduct?.cart_attributes,
		setSelectedAttributes,
		cartService.selectedVariationCartProductId,
		getCartProductByCartProductId,
	]);

	return (
		<AttributeGroups
			selectedAttributes={props.selectedAttributes}
			product={product}
			cartProduct={cartProduct}
			isModal={!!props.isModal}
			isProductInCart={isProductInCart}
			setSelectedAttributes={props.setSelectedAttributes}
			productId={product?.id || null}
		/>
	);
}

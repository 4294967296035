import {useCallback} from "react";
import {debounce} from "lodash"


type debounceParams = Parameters<typeof debounce>


export default function useDebounced<T extends (...args: any) => any>(
    func: T,
    deps?: any[],
    wait?: debounceParams[1],
    options?: debounceParams[2],
): (...args: Parameters<T>) => ReturnType<typeof debounce<T>> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounced = useCallback(debounce((funcToCall: T, args: any) => {
        return funcToCall(...args)
    }, wait, options), [wait, JSON.stringify(options)])

    return useCallback((...args: Parameters<T>) => {
        return debounced(func, args)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced, ...(deps || [])])
}

import useScreenService from "../../../../services/useScreenService";
import { useCallback, useState } from "react";
import QuantityInput from "./QuantityInput";

interface IQuantityInputProps {
	textSize?: string;
	qty: number;
	minValue?: number;
	updateQty: (qty: number) => void;
	setIsQtyInputVisible?: (isVisible: boolean) => void;
	isQtyInputVisible?: boolean;
	isCard?: boolean;
	isFloatingQty?: boolean;
}

export default function ProductQuantityInput(props: IQuantityInputProps) {
	const { isMobile } = useScreenService();

	const [localValue, setLocalValue] = useState<number>(props.qty);
	const [isLocalQtyVisible, setIsLocalQtyVisible] = useState<boolean>(false);

	const needEndAdornment = (isMobile && props.isCard) || props.isFloatingQty;
	const fieldWith = needEndAdornment ? "100%" : isMobile ? "3rem" : "4rem";

	const setIsVisible = (isVisible: boolean) => {
		setIsLocalQtyVisible(isVisible);
		props.setIsQtyInputVisible && props.setIsQtyInputVisible(isVisible);
	};

	const inputValueChanged = useCallback(
		(qtyValue: number) => {
			if (props.minValue && qtyValue < props.minValue) {
				qtyValue = props.minValue;
			}
			if (Number.isNaN(qtyValue)) {
				qtyValue = props.minValue || 1;
			}
			setLocalValue(qtyValue);
		},
		[props.minValue]
	);

	return (
		<div
			className={`${isLocalQtyVisible && isMobile && props.isCard ? "mx-0" : "mx-2"} fw-500 user-select-none ${props.textSize ? props.textSize : ""} border border-transparent`}
		>
			<div
				className={
					"d-flex align-items-center justify-content-center h-100 btn-padding px-0"
				}
				style={{
					lineHeight: 1.5,
					minWidth: "15px",
					padding: "0px",
				}}
			>
				{!isLocalQtyVisible && !props.isFloatingQty ? (
					<span className={"cursor-pointer"} onClick={() => setIsVisible(true)}>
						{props.qty}
					</span>
				) : (
					<QuantityInput
						sx={{
							width: fieldWith,
							input: { py: "6px", px: "8px" },
						}}
						onBlur={() => {
							props.updateQty(localValue);
							setIsVisible(false);
						}}
						defaultValue={props.qty}
						onChange={inputValueChanged}
						needEndAdornment={needEndAdornment || false}
						onEndAdornmentClick={e => {
							e.preventDefault();
							props.updateQty(localValue);
							setIsVisible(false);
						}}
						onKeyUp={e => {
							if (e.key === "Enter") {
								e.preventDefault();
								props.updateQty(localValue);
								setIsVisible(false);
							}
						}}
						size={"small"}
					/>
				)}
			</div>
		</div>
	);
}

import {useEffect} from "react";

export default function useEpayLibraries(isEnabled: boolean = false, isTest: boolean = false) {
    useEffect(() => {
        if(isEnabled){
            const script = document.createElement('script')
            script.src = isTest ? "https://test-epay.homebank.kz/payform/payment-api.js" :
                "https://epay.homebank.kz/payform/payment-api.js"
            script.async = true
            document.body.appendChild(script)
            console.log("*** ADDED HALYK")
        }
    }, [isEnabled, isTest])
}

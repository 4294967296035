import { Box, Typography } from "@mui/material";
import { CheckItemsProps } from "./types";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import { CheckItem } from "./index";
import useCurrency from "../../services/useCurrencyService";

export default function CheckItems({ items, headerProps }: CheckItemsProps) {
	const { check: locale } = useLocalisation();
	const currency = useCurrency();

	return (
		<Box>
			<Typography variant={"subtitle1"} fontWeight={"600"} mt={2} {...headerProps}>
				{locale.itemsText}
			</Typography>
			<Box>
				{items.map(item => (
					<CheckItem
						item={item}
						currency={currency}
						key={
							item.code ||
							item.name + item.quantity + item.attributes?.toString() ||
							""
						}
					/>
				))}
			</Box>
		</Box>
	);
}

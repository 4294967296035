import { Box, Grid, Typography } from "@mui/material";
import { Receipt } from "../../api/shop/basic/types";
import f from "../../helpers/formatText";
import useAppContext from "../../useAppContext";
import { useSelectedStoreOptional } from "../../shop/SelectedStore/context";
import { IIncustCouponShowData } from "../../api/shop/loyalty/types";
import { VoucherCard } from "../Check/CheckAwards";
import { TgLink } from "../../helpers/ThemeComponents";
import { useState } from "react";
import changePage from "../../helpers/changePage";
import WalletIcon from "@mui/icons-material/Wallet";
import useCalculatedSpecialAccountsCharges from "../../shop/loyalty/useCalculatedSpecialAccountsCharges";
import SpecialAccountChargeTitle from "../../shop/loyalty/SpecialAccountChargeTitle";
import formatCurrency from "../../helpers/formatCurrency";
import useCurrency from "../../services/useCurrencyService";
import Interweave from "../Interweave";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CouponModal from "../../shop/loyalty/components/Coupon/CouponModal";
import formatNumber from "../../helpers/formatNumber";

interface IScanReceiptContentProps {
	receipt: Receipt;
}

export default function ReceiptContent(props: IScanReceiptContentProps) {
	const {
		localisation: { check, menu, global, receipt },
		lang,
		toastService,
		brandInfo,
	} = useAppContext();
	const selectedStore = useSelectedStoreOptional();
	const customerAccounts = useCalculatedSpecialAccountsCharges(
		props.receipt.incust_check?.special_accounts_charges
	);
	const currency = useCurrency();

	const [showCouponModal, setShowCouponModal] = useState<boolean>(false);
	const [couponId, setCouponId] = useState<string | null>(null);

	const handleWalletClick = () => {
		if (!!selectedStore) {
			return changePage(`/shop/${selectedStore.id}/profile/loyalty`);
		}
		changePage(`/shop/profile/loyalty`);
	};

	const isAwards =
		!!props.receipt.incust_check?.bonuses_added_amount ||
		!!props.receipt.vouchers?.length ||
		!!customerAccounts?.length;

	return (
		<>
			<Box p={3}>
				{!!selectedStore && (
					<Typography variant={"h6"} fontWeight={"bold"}>
						{f(check.storeNameText, { name: selectedStore.name })}
					</Typography>
				)}

				<Typography
					variant={"subtitle2"}
					mb={2}
					color={"text.secondary"}
					sx={{ wordBreak: "break-all" }}
				>
					<Interweave
						content={f(receipt.successProcessHeader, {
							receipt_id: props.receipt.receipt_id,
							receipt_sum: formatCurrency(
								props.receipt.total_price,
								brandInfo?.default_lang || lang,
								currency
							),
						})}
					/>
				</Typography>

				{isAwards && (
					<Typography variant={"h6"} mt={2}>
						{menu.giftAwardsAfterHeader}
					</Typography>
				)}

				{customerAccounts?.map((special, idx) => (
					<Typography>
						{special.title || (
							<SpecialAccountChargeTitle specialAccountCharge={special} idx={idx} />
						)}
						{": "}
						<strong>
							{formatNumber(special.amount || 0, brandInfo?.default_lang || lang)}
						</strong>
					</Typography>
				))}

				{!!props.receipt.incust_check?.bonuses_added_amount && (
					<Typography sx={{ mt: 1 }}>
						{check.loyaltyBonusesText}{" "}
						<strong>
							{formatNumber(
								props.receipt.incust_check?.bonuses_added_amount || 0,
								brandInfo?.default_lang || lang
							)}
						</strong>
					</Typography>
				)}

				{!!props.receipt.vouchers?.length && (
					<Grid container spacing={2} sx={{ mt: 1 }}>
						{props.receipt.vouchers.map(
							(voucher: IIncustCouponShowData) =>
								voucher.url &&
								voucher.title && (
									<Grid item sm={6} className={"w-100"} key={voucher.title}>
										<VoucherCard
											voucher={voucher}
											showCouponModal={showCouponModal}
											setShowCouponModal={setShowCouponModal}
											setCouponId={setCouponId}
										/>
									</Grid>
								)
						)}
					</Grid>
				)}

				{isAwards && (
					<Box sx={{ mt: 2 }}>
						<TgLink onClick={handleWalletClick}>
							<WalletIcon sx={{ mr: 2 }} />
							{global.showWallet}
						</TgLink>
					</Box>
				)}
				{!!props.receipt.bin_code && (
					<Typography variant={"body2"} color={"text.secondary"} sx={{ mt: 2 }}>
						<Interweave
							content={f(receipt.receiptBinCode, { code: props.receipt.bin_code })}
						/>
						<ContentCopyOutlinedIcon
							fontSize={"inherit"}
							sx={{ ml: 1 }}
							onClick={() => {
								if (props.receipt.bin_code) {
									navigator.clipboard
										.writeText(props.receipt.bin_code)
										.then(() => {
											toastService.showToast({
												severity: "success",
												message: global.copiedText,
											});
										});
								}
							}}
						></ContentCopyOutlinedIcon>
					</Typography>
				)}
			</Box>

			<CouponModal show={showCouponModal} setShow={setShowCouponModal} couponId={couponId} />
		</>
	);
}

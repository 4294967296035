import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert, Container, useColorScheme } from "@mui/material";

import useAppContext from "../../useAppContext";
import useSearchParamsFixed from "../../features/hooks/useSearchParamsFixed";
import useScreenService from "../../services/useScreenService";
import Interweave from "../../features/Interweave";

export default function UserCouponPage() {
	const {
		brandInfo,
		lang,
		localisation: { profile },
		setPageTitle,
	} = useAppContext();
	const { isMobile } = useScreenService();

	const colorScheme = useColorScheme();

	const [searchParams, setSearchParams] = useSearchParamsFixed();

	const [showSuccessAlert, setShowSuccessAlert] = useState(false);

	const { couponId } = useParams();
	const iFrameBase = `${brandInfo?.incust_data?.client_url}/widget/coupon-show/`;
	const iFrameSrc = `${iFrameBase}${couponId}?no-redeem=1&lang=${lang}`;

	const computedIframeSrc = useMemo(() => {
		const element = document.querySelector("body");
		if (element) {
			const computedStyle = getComputedStyle(element);

			const bgColorValue = computedStyle.getPropertyValue("--mui-palette-background-default");
			const bgCardColorValue = computedStyle.getPropertyValue(
				"--mui-palette-background-paper"
			);
			const textCardColorValue = computedStyle.getPropertyValue("--mui-palette-text-primary");
			const primaryColorValue = computedStyle.getPropertyValue("--mui-palette-primary-main");
			const primaryColorContrastValue = computedStyle.getPropertyValue(
				"--mui-palette-primary-contrastText"
			);

			const bgColor = encodeURIComponent(bgColorValue || "");
			const bgCardColor = encodeURIComponent(bgCardColorValue || "");
			const textCardColor = encodeURIComponent(textCardColorValue || "");
			const primaryColor = encodeURIComponent(primaryColorValue || "");

			const primaryColorContrast = encodeURIComponent(primaryColorContrastValue || "");

			let url = iFrameSrc;
			url += `&primaryColor=${primaryColor}&secondaryColor=''`;
			url += `&bgColor=${bgColor}&textCardColor=${textCardColor}&bgCardColor=${bgCardColor}`;
			url += `&primaryColorContrast=${primaryColorContrast}&secondaryColorContrast=''`;

			return url;
		}

		return iFrameSrc;
	}, [iFrameSrc, colorScheme]);

	useEffect(() => {
		const success = searchParams.get("success");
		if (success) {
			setShowSuccessAlert(true);
		}

		setSearchParams(prev => {
			prev.delete("success");
			prev.delete("id");
			return prev;
		});
	}, [searchParams, setSearchParams]);

	useEffect(() => {
		setPageTitle(profile.voucherTitle);
	}, [profile.voucherTitle, setPageTitle]);

	return (
		<Container
			maxWidth={"md"}
			sx={{
				p: 0,
				mt: 3,
				flex: 1,
				width: "100%",
			}}
		>
			{showSuccessAlert && (
				<Alert severity={"success"} variant={"outlined"} className={"small p-3 mb-2"}>
					<Interweave
						content={profile.successAddedCouponToWallet.replace("{coupon_title}", "")}
					/>
				</Alert>
			)}
			<iframe
				allow={"clipboard-write"}
				src={computedIframeSrc}
				title={"incust-coupon"}
				height={isMobile ? "1000px" : "600px"}
				width={"100%"}
			/>
		</Container>
	);
}

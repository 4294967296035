import { Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";
import useAppContext from "../../../../../useAppContext";

export function CanUseWithOrder() {
	const {
		localisation: { profile },
	} = useAppContext();
	return (
		<Typography
			sx={{
				fontSize: "small",
				color: "warning.main",
				width: { xs: "100%", sm: "fit-content" },
				fontWeight: "bold",
				px: 2,
				display: "flex",
				justifyContent: "center",
				gap: 1,
				alignItems: "center",
			}}
		>
			<Warning fontSize="inherit" />
			{profile.loyaltyCouponCanUseWithOrder}
		</Typography>
	);
}

import {useEffect, useMemo, useState} from "react";


export type Dimensions = {
    width: number
    height: number
}

export enum ScreenSize {
    XS = 0,
    SM = 1,
    MD = 2,
    LG = 3,
    XL = 4,
    XXL = 5,
}

export type ScreenServiceType = {
    dimensions: Dimensions
    screenSize: ScreenSize,
    isMobile: boolean
}


export default function useScreenService(): ScreenServiceType {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener("resize", handleResize, false)
        return () => {
            window.removeEventListener("resize", handleResize, false)
        }
    }, [])

    const screenSize = useMemo(() => {
        if (dimensions.width < 576) {
            return ScreenSize.XS
        } else if (dimensions.width < 768) {
            return ScreenSize.SM
        } else if (dimensions.width < 992) {
            return ScreenSize.MD
        } else if (dimensions.width < 1200) {
            return ScreenSize.LG
        } else if (dimensions.width < 1400) {
            return ScreenSize.XL
        } else {
            return ScreenSize.XXL
        }
    }, [dimensions.width])

    return useMemo(() => ({
        screenSize: screenSize,
        dimensions: dimensions,
        isMobile: screenSize <= ScreenSize.MD,
    }), [screenSize, dimensions])
}

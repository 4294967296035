import Logo from "../static/stubs/shop-logo-stub.webp";
import { useShopContext } from "./context";
import changePage from "../helpers/changePage";
import api from "../api";
import { useSelectedStoreContextOptional, useSelectedStoreOptional } from "./SelectedStore/context";
import SVG from "react-inlinesvg";
import { Box } from "@mui/material";
import useScreenService from "../services/useScreenService";

interface Props {
	customClass?: string;
	backHome?: () => void;
}

export default function BrandInfo({ customClass = "", backHome }: Props) {
	const { isMobile } = useScreenService();
	const { brandInfo } = useShopContext();
	const selectedStore = useSelectedStoreOptional();
	const { categoriesService } = useSelectedStoreContextOptional();

	const handleLogoClick = () => {
		if (brandInfo.stores_count > 0) {
			if (backHome) return backHome();

			const storeId = selectedStore?.id;
			if (storeId) {
				categoriesService?.handleClick("top");
				changePage(`/shop/${storeId}/menu`);
			} else {
				api.shop.basic.removeLastStoreId();
				changePage("/shop/select");
			}
		}
	};

	const logo = brandInfo.logo_url;
	const isSvg = logo?.endsWith(".svg");

	return (
		<Box
			className={customClass + ` ${brandInfo.stores_count > 0 ? "cursor-pointer" : ""}`}
			onClick={handleLogoClick}
		>
			<Box color={isMobile ? "inherit" : "text.primary"}>
				{logo && isSvg ? (
					// @ts-ignore
					<SVG
						src={logo}
						width={"auto"}
						height={"45px"}
						title={"Logo"}
						style={{ fill: "currentcolor" }}
					/>
				) : (
					<img
						className={"img-fluid"}
						src={brandInfo.logo_url || Logo}
						alt="Logo"
						style={{ width: "auto", height: "3rem" }}
					/>
				)}
			</Box>
		</Box>
	);
}

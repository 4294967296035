import {Box, useColorScheme} from "@mui/material";
import {MDBIcon} from "mdb-react-ui-kit";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import useAppContext from "../../useAppContext";
import {useSelectedStoreOptional} from "../SelectedStore/context";
import changePage from "../../helpers/changePage";
import api from "../../api";
import {LogoutButton} from "../../auth/buttons";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {useShopContext} from "../context";

interface ISidebarHeaderProps {
    type: 'main' | 'profile'
}

export default function SidebarHeader(props: ISidebarHeaderProps) {
    const colorScheme = useColorScheme()

    return (
        <Box sx={colorScheme.mode === 'light' ? {
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
        } : {
            bgcolor: 'background.paper',
            color: 'text.default',
        }}>
            <div className="d-flex align-items-center justify-content-end px-3 py-4">
                {props.type === "main" && (
                    <MainHeaderItem />
                )}
                {props.type === "profile" && (
                    <ProfileHeaderItem />
                )}
            </div>

            <div className={'px-3 mb-2'}>
                <SidebarHeaderSubContent type={props.type} />
            </div>
        </Box>
    )
}

function SidebarHeaderSubContent(props: ISidebarHeaderProps) {
    return (
        <>
            {props.type === "main" && (
                <MainTitleAndChangeStoreItem titleClasses={'pt-3'} isSidebar={true}/>
            )}

            {props.type === "profile" && (
                <ProfileTitleItem />
            )}
        </>
    )
}

interface IMainTitleAndChangeStoreItemProps {
    titleClasses?: string
    isSidebar?: boolean
}

export function MainTitleAndChangeStoreItem(props: IMainTitleAndChangeStoreItemProps) {
    const {
        sidebarsService,
        brandInfo,
        localisation: {global},
    } = useAppContext()
    const selectedStore = useSelectedStoreOptional()

    const handleChangeStoreClick = () => {
        sidebarsService.setIsMainSidebarOpen(false)
        api.shop.basic.removeLastStoreId()
        changePage('/shop/select')
    }

    return (
        <>
            <Box
                className={`white-space-nowrap fw-bold px-1 ${
                    props.titleClasses ? props.titleClasses : ''}`}
                component="div"
                sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: props.isSidebar ? '15rem' : 'auto',
                    fontSize: '1.5rem',
                }}
            >
                {!!selectedStore
                    ? selectedStore.name
                    : brandInfo?.name
                }
            </Box>

            {(!!brandInfo?.stores_count && brandInfo?.stores_count > 1) && (
                <div className={'small cursor-pointer px-1'} onClick={handleChangeStoreClick}>
                    {global.chooseStore}
                    <ChevronRightIcon fontSize={'medium'} className={'ms-1'} />
                </div>
            )}
        </>
    )
}

function ProfileTitleItem() {
    const {
        authService: {user},
        sidebarsService,
        localisation: {global, profile},
    } = useAppContext()
    const {webAppData} = useShopContext()
    const selectedStore = useSelectedStoreOptional()

    const handleProfileClick = () => {
        try {
            if (selectedStore?.id) {
                return changePage(`/shop/${selectedStore.id}/profile`)
            }
            changePage('/shop/profile')
        }
        finally {
            sidebarsService.setIsProfileSidebarOpen(false)
        }
    }

    return (
        <>
            <Box
                className={'white-space-nowrap fw-bold cursor-pointer pt-3'}
                component="div"
                sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '15rem',
                    fontSize: '1.5rem',
                }}
                onClick={handleProfileClick}
            >
                {!!user
                    ? global.helloAuthUser.replace("{name}", user?.first_name || "")
                    : global.helloAnonUser
                }
            </Box>
            {!user ? (
                <LoginOrRegisterItem />
            )
                : (
                    !webAppData && (
                        <LogoutButton
                            node={
                                <div className={'small cursor-pointer'}>
                                    {profile.logout}
                                    <LogoutOutlinedIcon fontSize={'inherit'} className={'ms-1'} />
                                </div>
                            }
                            onConfirm={() => {sidebarsService.setIsProfileSidebarOpen(false)}}
                        />
                    )
                )
            }
        </>
    )
}

function ProfileHeaderItem() {
    const {sidebarsService, localisation: {global}} = useAppContext()

    return (
        <div
            onClick={sidebarsService.switchToMainSidebar}
            className={'small d-flex cursor-pointer'}
        >
            <div>
                {global.menuHeader}
            </div>
            <div className={'align-self-center'}>
                <MDBIcon
                    fas
                    icon={"bars"}
                    className={"ms-2"}
                />
            </div>
        </div>
    )
}

function MainHeaderItem() {
    const {
        sidebarsService,
        authService: {user},
        localisation: {profile},
    } = useAppContext()

    return (
        <div className={'d-flex cursor-pointer'}>
            {!!user
                ?
                    <div
                        className={'d-flex small'}
                        onClick={() => sidebarsService.switchToProfileSidebar()}
                    >
                        <div className={'me-1'}>
                            {profile.profile}
                        </div>
                        <PersonOutlinedIcon
                            className={'primary-text'}
                            sx={{
                                width: 22,
                                height: 22,
                            }}
                        />
                    </div>
                :
                    <LoginOrRegisterItem />
            }
        </div>
    )
}

function LoginOrRegisterItem() {
    const {
        localisation: {global},
        sidebarsService,
        authService: {setShowAuthorisationModal},
    } = useAppContext()

    const handleClick = () => {
        setShowAuthorisationModal(true)
        sidebarsService.setIsProfileSidebarOpen(false)
        sidebarsService.setIsMainSidebarOpen(false)
    }

    return (
        <div onClick={handleClick} className={'small d-flex'}>
            <div className={'me-1 cursor-pointer'}>
                {global.signIn}
            </div>
            <PersonOutlinedIcon
                className={'primary-text'}
                sx={{
                    width: 22,
                    height: 22,
                }}
            />
        </div>
    )
}

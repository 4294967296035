import { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Typography,
} from "@mui/material";
import { AspectRatio } from "@mui/joy";
import { styled } from "@mui/material/styles";

import api from "../../api";
import useAppContext from "../../useAppContext";
import useScreenService from "../../services/useScreenService";
import { ShareAndEarnContentItem } from "../profile/pages/ProfileShareAndEarn";
import changePage from "../../helpers/changePage";
import CollapsableComponent from "../../features/CollapsableComponent";
import { User } from "../../api/auth/types";
import Avatar from "@mui/material/Avatar";
import Interweave from "../../features/Interweave";
import StubImage from "../../static/stubs/shop-image-stub.webp";

const BrandImage = styled("img")({
	borderRadius: 4,
	borderBottomLeftRadius: 0,
	borderBottomRightRadius: 0,
	width: "100%",
	height: "auto",
});

export default function AddFriendPage() {
	const {
		setPageTitle,
		localisation: { profile },
	} = useAppContext();

	const { req_user_id } = useParams();

	useEffect(() => {
		setPageTitle(profile.acceptInvitation);
	}, [setPageTitle, profile.acceptInvitation]);

	return (
		<Container
			maxWidth={"lg"}
			sx={{
				p: 0,
				mt: 3,
				flex: 1,
				width: "100%",
				display: "flex",
				alignItems: "center",
			}}
		>
			<Paper elevation={3} sx={{ width: "100%" }}>
				{!req_user_id ? (
					<Box p={3}>
						<Typography variant={"h6"} color={"error"}>
							<Interweave content={profile.noReqUserIdErr} />
						</Typography>
					</Box>
				) : (
					<Invitation req_user_id={Number(req_user_id)} />
				)}
			</Paper>
		</Container>
	);
}

interface IInvitationProps {
	req_user_id: number;
	photo?: string;
}

function Invitation(props: IInvitationProps) {
	const { brandInfo } = useAppContext();

	return (
		<Grid container columnSpacing={3} justifyContent={"center"} alignItems={"stretch"}>
			<Grid
				item
				xs={12}
				md={5}
				py={3}
				display={"flex"}
				alignItems={"center"}
				sx={{
					borderRight: { xs: 0, md: 1 },
					borderRightColor: { xs: "none", md: "text.primary" },
					pr: { xs: 0, md: 2 },
				}}
			>
				<Box sx={{ width: "100%" }}>
					<AspectRatio ratio={"6/4"} objectFit={"contain"}>
						{
							<BrandImage
								src={brandInfo?.logo_url || StubImage}
								alt={brandInfo?.name || ""}
							/>
						}
					</AspectRatio>
				</Box>
			</Grid>

			<Grid item xs={12} md={7} alignSelf={"center"} textAlign={"center"} p={3}>
				{!!props.req_user_id && (
					<InvitationContent
						req_user_id={props.req_user_id}
						photo={brandInfo?.logo_url || StubImage}
					/>
				)}
			</Grid>
		</Grid>
	);
}

function InvitationContent(props: IInvitationProps) {
	const {
		brandInfo,
		authService: { user, setShowAuthorisationModal },
		localisation: { profile, global },
	} = useAppContext();

	const { isMobile } = useScreenService();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);

	const [friendData, setFriendData] = useState<User>();

	useEffect(() => {
		if (brandInfo && props.req_user_id) {
			try {
				api.shop.friends
					.getFriend(props.req_user_id)
					.then(response => setFriendData(response.data));
			} catch (ex: any) {
				setError(ex.message);
			}
		}
	}, [brandInfo, props.req_user_id]);

	const ReqUserName = friendData?.name || "";
	const ReqUserInfo = useMemo(() => {
		let friendInfo = "";
		if (!friendData) return "";
		if (friendData.name) friendInfo = friendData.name;
		if (friendData.email) friendInfo += ` [${friendData.email}]`;
		if (friendData.wa_phone) friendInfo += ", +" + friendData.wa_phone;
		return friendInfo;
	}, [friendData]);

	const computedTitle = brandInfo?.name;

	const computedIsSameUser = useMemo(() => {
		return user && user.id === props.req_user_id;
	}, [user, props.req_user_id]);

	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	const acceptInvitation = useCallback(async () => {
		if (!brandInfo || !props.req_user_id) return;

		if (!user) return setShowAuthorisationModal(true);

		setIsLoading(true);
		try {
			const response = await api.shop.friends.addFriend(props.req_user_id);
			if (response.status === 200 && response.data.message === "Ok") {
				setSuccess(true);
				setError("");
			}
		} catch (ex: any) {
			setError(ex.response?.data?.detail || ex.message);
		} finally {
			setIsLoading(false);
			setIsLoggedIn(false);
		}
	}, [brandInfo, props.req_user_id, setShowAuthorisationModal, user]);

	const onAuthCallback = useCallback(() => {
		setIsLoggedIn(true);
	}, []);

	useEffect(() => {
		if (!user) setError(null);
	}, [user]);

	useEffect(() => {
		if (isLoggedIn && user && !success && !isLoading) acceptInvitation().then();
	}, [acceptInvitation, isLoading, isLoggedIn, success, user]);

	useEffect(() => {
		document.addEventListener("onAuth", onAuthCallback);

		return () => {
			document.removeEventListener("onAuth", onAuthCallback);
		};
	}, [onAuthCallback]);

	return (
		<Box ml={isMobile ? 3 : 0} sx={{ wordBreak: "break-word" }}>
			{!!computedTitle && (
				<>
					<Typography variant={"h4"} sx={{ my: 2 }}>
						{computedTitle}
					</Typography>
					<div style={{ justifyContent: "center", display: "flex" }}>
						<Avatar
							src={friendData?.photo || ""}
							alt={friendData?.name || ""}
							sx={{ width: 56, height: 56 }}
						></Avatar>
					</div>
				</>
			)}

			{isMobile ? (
				<CollapsableComponent collapsedSize={47} sx={{ width: "100%" }}>
					<Typography variant={"body1"} sx={{ mb: 4, pb: 2 }} className={"border-bottom"}>
						<Interweave
							content={global.msgAddFriendConfirm
								.replace("{req_name}", ReqUserName)
								.replaceAll("\n", "<br/>")}
						/>
					</Typography>
				</CollapsableComponent>
			) : (
				<Typography variant={"body1"} sx={{ mb: 4, pb: 2 }} className={"border-bottom"}>
					<Interweave
						content={global.msgAddFriendConfirm
							.replace("{req_name}", ReqUserName)
							.replaceAll("\n", "<br/>")}
					/>
				</Typography>
			)}

			{!computedIsSameUser && (
				<Typography variant={"h6"} sx={{ mb: 3 }}>
					{profile.invitedFriendHeader.replace("{req_name}", ReqUserInfo)}
				</Typography>
			)}

			{!computedIsSameUser &&
				(success ? (
					<>
						<Alert
							severity={"success"}
							variant={"outlined"}
							className={"small p-3 w-100 mt-2 text-start"}
						>
							<Interweave
								content={global.msgFriendConfirmed.replace(
									"{req_name}",
									ReqUserName
								)}
							/>
						</Alert>
					</>
				) : (
					<Button variant={"contained"} disabled={isLoading} onClick={acceptInvitation}>
						{isLoading && (
							<CircularProgress
								style={{ width: "18px", height: "18px" }}
								className={"me-2"}
							/>
						)}
						{profile.acceptInvitation}
					</Button>
				))}

			{computedIsSameUser && (
				<SameUserContent req_user_id={props.req_user_id} photo={props.photo || ""} />
			)}

			{!!error && (
				<Alert
					severity={"error"}
					variant={"outlined"}
					className={"small p-3 w-100 mt-2 text-start"}
				>
					<Interweave content={error} />
				</Alert>
			)}

			{user && (
				<Box sx={{ width: "100%", mt: 2 }} textAlign={"center"}>
					<Button variant={"text"} onClick={() => changePage("/")}>
						{global.backHome}
					</Button>
				</Box>
			)}
		</Box>
	);
}

interface ISameUserContentProps {
	req_user_id: number;
	photo: string;
}

function SameUserContent(props: ISameUserContentProps) {
	const {
		localisation: { profile },
	} = useAppContext();

	const baseUrl = window.location.origin;
	const qrWebUrl = `${baseUrl}/shop/add_friend/${props.req_user_id}`;

	return (
		<Box textAlign={"start"}>
			<ShareAndEarnContentItem
				linkText={profile.sameUserFriendHeader}
				qrUrl={qrWebUrl}
				qrText={profile.shareQrHeader}
				photo={props.photo}
			/>
		</Box>
	);
}

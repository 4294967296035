import PersonalDataStepBase from "../../../../../../components/steppers/steps/PersonalDataStepBase/PersonalDataStepBase";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { PersonalDataComponentProps } from "../../types";
import useAppContext from "../../../../../../useAppContext";
import { useShopContext } from "../../../../../context";
import {
	OrderFieldSettingMode,
	OrderNameFieldSettings,
} from "../../../../../../api/shop/basic/types";
import api from "../../../../../../api";
import { matchIsValidTel } from "mui-tel-input";

export default function PersonalDataComponent(props: PersonalDataComponentProps) {
	const {
		groupService: { group },
		authService: { user, client, isAuthorised, setShowAuthorisationModal },
	} = useAppContext();
	const { brandInfo } = useShopContext();

	const [isValidPhone, setIsValidPhone] = useState(true);

	const fieldsSettings = useMemo(() => {
		let email: OrderFieldSettingMode = brandInfo.order_fields_settings[client].email;
		let phone: OrderFieldSettingMode = brandInfo.order_fields_settings[client].phone;
		let order_name_mode: OrderNameFieldSettings =
			brandInfo.order_fields_settings[client].order_name_mode;
		let first_name: OrderFieldSettingMode;
		let last_name: OrderFieldSettingMode;

		if (order_name_mode === "only_first_name") {
			first_name = "required";
			last_name = "disabled";
		} else if (order_name_mode === "optional") {
			last_name = "optional";
			first_name = "optional";
		} else {
			first_name = "required";
			last_name = "required";
		}

		if (props.invoiceTemplate) {
			if (!props.invoiceTemplate.need_email) {
				email = "disabled";
			} else if (email !== "required") {
				email = "optional";
			}

			if (!props.invoiceTemplate.need_phone) {
				phone = "disabled";
			} else if (phone !== "required") {
				phone = "optional";
			}

			if (!props.invoiceTemplate.need_name) {
				first_name = "disabled";
				last_name = "disabled";
			} else {
				first_name = "required";
				last_name = "required";
			}
		}

		if (props.isEmailRequiredForPayment) {
			email = "required";
		}

		return { email, phone, first_name, last_name };
	}, [
		brandInfo.order_fields_settings,
		client,
		props.invoiceTemplate,
		props.isEmailRequiredForPayment,
	]);

	const { setForm } = props;
	useEffect(() => {
		const lastPersonalData = api.shop.order.getLastPersonalData();

		setForm(prevState => ({
			first_name:
				fieldsSettings.first_name === "disabled"
					? user?.first_name
					: user?.first_name || lastPersonalData?.first_name || prevState.first_name,
			last_name:
				fieldsSettings.last_name === "disabled"
					? user?.last_name
					: user?.last_name || lastPersonalData?.last_name || prevState.last_name,
			email:
				fieldsSettings.email === "disabled"
					? user?.email
					: user?.email || lastPersonalData?.email || prevState.email,
			phone:
				fieldsSettings.phone === "disabled"
					? user?.wa_phone && `+${user.wa_phone}`
					: (user?.wa_phone && `+${user.wa_phone}`) ||
						(lastPersonalData?.phone &&
							matchIsValidTel(lastPersonalData.phone) &&
							lastPersonalData.phone) ||
						prevState.phone,
		}));
	}, [
		fieldsSettings.email,
		fieldsSettings.first_name,
		fieldsSettings.last_name,
		fieldsSettings.phone,
		setForm,
		user,
	]);

	if (
		fieldsSettings.first_name === "disabled" &&
		fieldsSettings.last_name === "disabled" &&
		fieldsSettings.email === "disabled" &&
		fieldsSettings.phone === "disabled"
	) {
		return null;
	}

	return (
		<Box sx={{ px: 3, py: 3, borderBottom: 1, borderColor: "divider" }}>
			{!!group?.texts.web.order.sign_in_button && !isAuthorised && (
				<Box
					sx={{
						textAlign: "center",
						mb: 2,
					}}
				>
					<Button
						sx={{ mt: 1, mb: 2 }}
						variant={"contained"}
						fullWidth
						onClick={() => setShowAuthorisationModal(true)}
					>
						{group.texts.web.order.sign_in_button}
					</Button>
					{!!group.texts.web.order.or_text && (
						<Typography
							component={"span"}
							sx={{
								color: "primary.main",
								fontWeight: "500",
								textAlign: "center",
								fontSize: "large",
							}}
						>
							{group.texts.web.order.or_text}
						</Typography>
					)}
				</Box>
			)}
			<PersonalDataStepBase
				form={props.form}
				setForm={props.setForm}
				isValidPhone={isValidPhone}
				setIsValidPhone={setIsValidPhone}
				isPhoneRequired={fieldsSettings.phone === "required"}
				isEmailRequired={fieldsSettings.email === "required"}
				isFirstNameRequired={fieldsSettings.first_name === "required"}
				isLastNameRequired={fieldsSettings.last_name === "required"}
				fields={{
					email: fieldsSettings.email !== "disabled",
					phone: fieldsSettings.phone !== "disabled",
					first_name: fieldsSettings.first_name !== "disabled",
					last_name: fieldsSettings.last_name !== "disabled",
				}}
			/>
		</Box>
	);
}

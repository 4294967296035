import { API } from "../base";
import CartModule from "./cart";
import OrderAPIModule from "./order";
import ShopTextNotificationsModule from "./messages";
import BasicShopModule from "./basic";
import PaymentsApiModule from "./payments";
import ShopLoyaltyModule from "./loyalty";
import FavoritesModule from "./favorites";
import FriendsModule from "./friends";

const shop = {
	basic: BasicShopModule(API),
	cart: CartModule(API),
	order: OrderAPIModule(API),
	payments: PaymentsApiModule(API),
	text_notifications: ShopTextNotificationsModule(API),
	loyalty: ShopLoyaltyModule(API),
	favorites: FavoritesModule(API),
	friends: FriendsModule(API),
};

export default shop;

import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import api from "../api";
import {useEffect} from "react";
import useAppContext from "../useAppContext";


export default function CustomHTMLPage() {
    const {
        loadingManager: {setIsLoading}
    } = useAppContext()
    const {pageName} = useParams()

    const {
        data: page,
        isFetching,
        isLoading,
    } = useQuery({
        queryKey: ['page', pageName],
        queryFn: async () => {
            if (!pageName) return null
            const response =  await api.pages.getPageByName(pageName)
            return response.data
        },
        initialData: null
    })

    useEffect(() => {
        setIsLoading('custom-html-page', isFetching || isLoading)
        return () => {
            setIsLoading('custom-html-page', false)
        }
    }, [isFetching, isLoading, setIsLoading]);

    useEffect(() => {
        if (page?.head) {
            document.head.innerHTML += page.head
        }
    }, [page?.head]);

    if (!page) return null

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
            }}
            dangerouslySetInnerHTML={{__html: page.body}}
        >
        </div>
    )
}

import { useCallback, useState } from "react";
import { scrollToBottom } from "../../../../helpers";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import useAppContext from "../../../../useAppContext";
import { CartProduct, CreateCartAttributeData } from "../../../../api/shop/cart/types";
import { Product } from "../../../../api/shop/basic/types";
import { SetState } from "../../../../types";

export default function useProductAttributes(
	product: Product | null,
	setErrorText: (text: string) => void,
	cartProduct: CartProduct | null = null
): IUseProductAttributes {
	const { localisation } = useAppContext();
	const { cartService } = useSelectedStoreContext();

	const [selectedAttributes, setSelectedAttributes] = useState<CreateCartAttributeData[]>([]);

	const computedRequiredAttributesWarning = useCallback(() => {
		if (
			!product ||
			(cartService.productVariationsState?.needToChooseMode &&
				cartProduct &&
				product.attribute_groups &&
				product.attribute_groups.length > 0)
		) {
			return false;
		}

		let warningText = "";
		if (product.attribute_groups) {
			product.attribute_groups.forEach(attrGroup => {
				if (attrGroup.min > 0) {
					const attributesIDs = attrGroup.attributes.map(attr => attr.id);
					const groupSelectedAttributes = selectedAttributes.filter(attr =>
						attributesIDs.includes(attr.attribute_id)
					);

					if (!groupSelectedAttributes) {
						let text = localisation.global.attributesChoseOne;
						text = text.replace("{attr_group_name}", attrGroup.name);
						warningText += `${text} <br />`;
					} else if (groupSelectedAttributes.length < attrGroup.min) {
						let text = localisation.global.attributesChoseMin;
						text = text
							.replace("{attr_group_name}", attrGroup.name)
							.replace("{qty}", attrGroup.min.toString());
						warningText += `${text} <br />`;
					}
				}
			});
		}
		setErrorText(warningText);
		setTimeout(() => {
			scrollToBottom(`detailed-product-modal-${product.id}`);
		}, 300);
		return warningText === "";
	}, [
		cartProduct,
		cartService.productVariationsState?.needToChooseMode,
		localisation.global.attributesChoseMin,
		localisation.global.attributesChoseOne,
		product,
		selectedAttributes,
		setErrorText,
	]);

	return {
		selectedAttributes,
		setSelectedAttributes,
		computedRequiredAttributesWarning,
	};
}

export interface IUseProductAttributes {
	selectedAttributes: CreateCartAttributeData[];
	setSelectedAttributes: SetState<CreateCartAttributeData[]>;
	computedRequiredAttributesWarning: () => boolean;
}

import useAppContext from "./useAppContext";
import {useEffect} from "react";
import changePage from "./helpers/changePage";
import useSearchParamsFixed from "./features/hooks/useSearchParamsFixed";


export default function ShortTokenPage() {
    const {brandInfo, shortTokenData, loadingManager} = useAppContext()
    const [searchParams] = useSearchParamsFixed()

    const isBrandLoading = loadingManager.getIsLoading('brand')
    useEffect(() => {
        if (shortTokenData && !shortTokenData.url_path) {
            if (!isBrandLoading) {
                if (brandInfo) {
                    changePage(`/shop/select${searchParams.toString()}`)
                } else {
                    changePage('/')
                }
            }
        }
    }, [brandInfo, isBrandLoading, searchParams, shortTokenData])

    return null
}

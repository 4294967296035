import {CardMediaProps} from "@mui/material";
import {AspectRatio} from "@mui/joy";
import {ImgHTMLAttributes} from "react";
import CardMedia from "@mui/material/CardMedia";


export interface CardMediaAspectRatioProps extends Omit<CardMediaProps<typeof ImageComponent>, 'src' | 'image' | 'component'> {
}


export default function CardMediaAspectRatio(props: CardMediaAspectRatioProps) {
    return (
        <CardMedia
            component={ImageComponent}
            {...props}
        />
    )
}


interface ImageComponentProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> {
    aspectRatio?: string
    imageSrc: string
}


export function ImageComponent({alt, imageSrc, style, aspectRatio, ...props}: ImageComponentProps) {
    return (
        <AspectRatio objectFit={'contain'} ratio={aspectRatio}>
            <img {...props} alt={alt || ''} src={imageSrc} style={{
                ...style && style,
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
            }}/>
        </AspectRatio>
    )
}

import React from "react";
import { Box, Skeleton } from "@mui/material";

export default function CouponSkeletonDesktop() {
	return (
		<Box
			display={"flex"}
			flexDirection={"row"}
			gap={"10px"}
			minHeight={"250px"}
			height={"auto"}
			justifyContent={"center"}
		>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
				gap={"20px"}
				pt={{ sm: "20px", md: "40px" }}
				pb={{ sm: "20px", md: "40px" }}
				pl={{ md: "40px" }}
				pr={{ md: "20px" }}
				minWidth={"180px"}
			>
				<Skeleton
					variant="rounded"
					sx={{
						width: "160px",
						aspectRatio: "1 / 1",
					}}
					height={"auto"}
				/>

				<Skeleton width={"60%"} variant="text" sx={{ fontSize: "2rem" }} />
			</Box>

			<Box
				flex={1}
				minWidth={"min(300px, 100%)"}
				width={"100%"}
				display={"flex"}
				flexDirection={"row"}
				gap={{ sm: 6 }}
				alignItems={"center"}
				className={"content"}
				justifyContent={"flex-end"}
				borderRadius={{
					sm: "150px 0px 0px 150px",
					md: "180px 0px 0px 180px",
				}}
				p={{
					sm: "30px 20px 20px 50px",
					md: "20px 30px 20px 50px",
				}}
				sx={{
					width: "100%",
					textAlign: "center",
					bgcolor: `primary.main`,
				}}
			>
				<Skeleton variant="rectangular" width={150} height={150} />
				<Box
					flex={1}
					display={"flex"}
					flexDirection={"column"}
					alignSelf={"stretch"}
					alignItems={"flex-end"}
				>
					<Box
						display={"flex"}
						flexDirection={"column"}
						gap={1}
						width={"100%"}
						justifyContent={"space-between"}
					>
						<Skeleton width={"100%"} variant="text" sx={{ fontSize: "3rem" }} />
						{[...Array(3).keys()].map((index: number) => (
							<Skeleton
								key={index}
								width={"100%"}
								variant="text"
								sx={{ fontSize: "0.5rem" }}
							/>
						))}
					</Box>

					<Box
						width={"100%"}
						color={"primary.contrastText"}
						display={"flex"}
						justifyContent={"flex-end"}
						alignItems={"flex-end"}
						flexDirection={"column"}
						gap={2}
						bottom={0}
						mt={"10px"}
						pl={3}
						bgcolor={{ sm: "primary.main" }}
						flexWrap={"wrap"}
						marginTop={"auto"}
					>
						<Skeleton
							width={"100%"}
							variant="text"
							sx={{ fontSize: "3px", mt: "auto" }}
						/>
						<Skeleton
							width={"30%"}
							variant="text"
							sx={{ fontSize: "2rem", mt: "auto" }}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

import axios, { AxiosResponse } from "axios";

import { ApiUrl, PhotonApiUrl } from "../config";

const handleError = (err: any) => {
	if (err.response.data) {
		if (err.response.data.detail) return err.response.data.detail;
	}
	if (err.message) {
		return err.message;
	}
	return "api error";
};

const handleResponse = (response: AxiosResponse, isWriteOperation = false) => {
	if (isWriteOperation) {
		if (!response.data) {
			if (response.status === 200) {
				return { data: true };
			}
		}
	}
	if (!response.data && response.status === 200) return {};
	return response.data || null;
};

export const photonMapApi = axios.create({
	baseURL: PhotonApiUrl,
});

photonMapApi.interceptors.response.use(
	function (response) {
		return response;
	},
	error => {
		console.log(error);
		return { error: handleError(error) };
	}
);

//TODO: add request headers to openstreetmap and basic api

const catalogBasePath = "/catalog";
const crmBasePath = "/crm";
const messagesBasePath = "/messages";
const profileBasePath = "/profile";
const usersBasePath = "/users";
const groupsBasePath = "/groups";
const localeBasePath = "/locale";
const vmBasePath = "/vm";
const storeBasePath = "/store";

export const API = axios.create({
	baseURL: ApiUrl,
});
API.defaults.params = {};

export {
	catalogBasePath,
	crmBasePath,
	messagesBasePath,
	profileBasePath,
	usersBasePath,
	groupsBasePath,
	localeBasePath,
	vmBasePath,
	storeBasePath,
	handleResponse,
};

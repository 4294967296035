import { useCallback, useEffect, useMemo, useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { SelectedShipmentServiceType } from "../order/MakeOrder/useSelectedShipmentService";
import { ShipmentsServiceType } from "../context/data/useShipmentsService";
import {
	CustomShipment,
	CustomShipmentGroup,
	Shipment,
	ShipmentIdObj,
	ShipmentPrice,
	ShipmentPriceInputData,
	ShipmentPriceResultData,
	ShipmentZone,
} from "../../api/shop/basic/types";
import api from "../../api";
import useAppContext from "../../useAppContext";
import formatCurrency from "../../helpers/formatCurrency";
import useCurrency from "../../services/useCurrencyService";
import { useShopContext } from "../context";

export interface IPricesError {
	text: string;
	internalCode?: string;
}

export default function useShipmentPrices(
	shipmentsService: ShipmentsServiceType,
	selectedShipmentService: SelectedShipmentServiceType,
	storeId: number | null = null,
	productsAmount: () => number,
	address: string | null = null,
	addressCoords: [number, number] | null = null,
	productsCount: number = 0
): IShipmentPricesService {
	const currency = useCurrency();
	const {
		lang,
		localisation: { orders },
		brandInfo,
	} = useAppContext();
	const { menuInStoreService } = useShopContext();

	const [allPricesLoading, setAllPricesLoading] = useState(false);
	const [selectedPriceLoading, setSelectedPriceLoading] = useState(false);
	const [error, setError] = useState<IPricesError | null>(null);

	const getShipmentById = shipmentsService.getShipmentById;
	const fetchAllPrices = useCallback(async () => {
		const shipmentIds: number[] = [];
		if (shipmentsService.shipments) {
			shipmentsService.shipments.base.forEach((shipment: Shipment) => {
				shipmentIds.push(shipment.id);
			});
			shipmentsService.shipments.groups.forEach((group: CustomShipmentGroup) => {
				group.shipments.forEach((shipment: CustomShipment) => {
					shipmentIds.push(shipment.id);
				});
			});
			shipmentsService.shipments.rest.forEach((shipment: CustomShipment) => {
				shipmentIds.push(shipment.id);
			});
		}

		const args: ShipmentPriceInputData = {
			store_id: storeId,
			order_sum: productsAmount() || 0,
			address: address,
			is_next_price_cheaper: true,
		};
		if (addressCoords) {
			args.address_lat = addressCoords[1];
			args.address_lng = addressCoords[0];
		}

		const shipmentPrices: IShipmentPricesItem[] = [];

		const shipmentIdObjects: ShipmentIdObj[] = [];
		for (const shipmentId of shipmentIds) {
			const shipmentObj: ShipmentIdObj = {};
			shipmentObj.shipment_id = shipmentId;
			const shipment = getShipmentById(shipmentId);
			if (shipment) {
				shipmentObj.store_settings_id = shipment.store_settings_id;
			}
			shipmentIdObjects.push(shipmentObj);
		}
		args.shipment_ids = shipmentIdObjects;
		const response = await api.shop.basic
			.getShipmentPrices(args)
			.then(response => response.data);

		if (response) {
			response.forEach(item => {
				shipmentPrices.push({
					shipmentId: item.shipment_id || 0,
					shipmentPrices: item,
				});
			});
		}

		return shipmentPrices;
	}, [
		shipmentsService.shipments,
		storeId,
		productsAmount,
		address,
		addressCoords,
		getShipmentById,
	]);

	const queryAllPrices = useQuery<IShipmentPricesItem[] | null, any>({
		queryKey: [
			"shipment-all-prices",
			address,
			productsAmount,
			shipmentsService.shipments,
			storeId,
			lang,
			addressCoords,
		],
		queryFn: fetchAllPrices,
		enabled: !!shipmentsService.shipments && !menuInStoreService.menuInStore?.id,
		initialData: [],
		placeholderData: keepPreviousData,
	});

	const computedShipmentsPrices = useMemo(() => {
		const { data: prices } = queryAllPrices;
		return prices;
	}, [queryAllPrices]);

	const computedSelectedShipmentHasZones = useMemo(() => {
		if (allPricesLoading || selectedPriceLoading) return true;
		if (selectedShipmentService.selectedShipment) {
			const shipment = computedShipmentsPrices?.find(
				(shipmentPrices: IShipmentPricesItem) => {
					return (
						shipmentPrices.shipmentId === selectedShipmentService.selectedShipment?.id
					);
				}
			);
			if (shipment) {
				if (shipment.shipmentPrices.has_zones) return true;
			}
		}
		return false;
	}, [
		computedShipmentsPrices,
		selectedShipmentService.selectedShipment,
		allPricesLoading,
		selectedPriceLoading,
	]);

	const setSelectedShipmentPrice = selectedShipmentService.setSelectedShipmentPrice;
	const fetchSelectedPrice = useCallback(async () => {
		if (selectedShipmentService.selectedShipment) {
			const shipment = getShipmentById(selectedShipmentService.selectedShipment.id);
			if (shipment && !computedSelectedShipmentHasZones) {
				if (!shipment.prices || shipment.prices.length === 0) {
					setSelectedShipmentPrice(null);
					setError(null);
					return null;
				}
			}

			const shipmentIdObj: ShipmentIdObj = {
				shipment_id: selectedShipmentService.selectedShipment.id,
				store_settings_id: selectedShipmentService.selectedShipment.store_settings_id,
			};
			const args: ShipmentPriceInputData = {
				store_id: storeId,
				order_sum: productsAmount() || 0,
				address: address,
				shipment_id: shipmentIdObj,
			};
			if (addressCoords) {
				args.address_lat = addressCoords[1];
				args.address_lng = addressCoords[0];
			}
			const response: ShipmentPrice | null = await api.shop.basic
				.getShipmentPrice(args)
				.then(response => response.data)
				.catch(error => {
					if (
						error?.response?.data?.internal_code &&
						error?.response?.data?.internal_code === "prices_not_exist"
					) {
						setError(null);
						setSelectedShipmentPrice(null);
						return null;
					}
					setError({
						text: error?.response?.data?.detail || orders.unknownShipmentPricesError,
						internalCode: error?.response?.data?.internal_code || undefined,
					});
					return null;
				});
			if (response) {
				setSelectedShipmentPrice(response);
				setError(null);
				return response;
			}

			return null;
		}
		return null;
	}, [
		selectedShipmentService.selectedShipment,
		getShipmentById,
		computedSelectedShipmentHasZones,
		storeId,
		productsAmount,
		address,
		addressCoords,
		setSelectedShipmentPrice,
		orders.unknownShipmentPricesError,
	]);

	const querySelectedPrice = useQuery<ShipmentPrice | null, any>({
		queryKey: [
			"shipment-selected-price",
			address,
			productsAmount,
			selectedShipmentService.selectedShipment,
			storeId,
			lang,
			computedShipmentsPrices,
			addressCoords,
			productsCount,
		],
		queryFn: fetchSelectedPrice,
		enabled: !!selectedShipmentService.selectedShipment && !menuInStoreService.menuInStore?.id,
		initialData: null,
		placeholderData: keepPreviousData,
	});

	const computedSelectedShipmentPrice = useMemo(() => {
		const { data: price } = querySelectedPrice;
		return price;
	}, [querySelectedPrice]);

	const isAvailablePricesEqualShipmentPrices = useCallback(
		(pricesItem: IShipmentPricesItem | undefined) => {
			if (pricesItem) {
				if (!pricesItem.shipmentPrices.has_zones) return false;
				const shipment = getShipmentById(pricesItem.shipmentId);
				if (shipment) {
					const allExist = pricesItem.shipmentPrices.available_prices?.every(item1 =>
						shipment.prices?.some(item2 => item1.id === item2.id)
					);
					if (allExist) return true;
				}
			}
			return false;
		},
		[getShipmentById]
	);

	const getShipmentPricesById = useCallback(
		(shipmentId: number): ShipmentPrice[] => {
			if (computedShipmentsPrices) {
				const pricesItem = computedShipmentsPrices.find(
					(shipmentPrices: IShipmentPricesItem) => {
						return shipmentPrices.shipmentId === shipmentId;
					}
				);

				if (pricesItem?.shipmentPrices.select_price)
					return [pricesItem?.shipmentPrices.select_price];

				const cantUseAvailable = isAvailablePricesEqualShipmentPrices(pricesItem);
				if (
					pricesItem &&
					pricesItem.shipmentPrices.available_prices &&
					pricesItem.shipmentPrices.available_prices.length > 0 &&
					!cantUseAvailable
				) {
					return pricesItem.shipmentPrices.available_prices;
				} else {
					let zones: ShipmentZone[] = [];
					if (
						pricesItem &&
						pricesItem.shipmentPrices.available_zones &&
						pricesItem.shipmentPrices.available_zones?.length > 0 &&
						pricesItem.shipmentPrices.available_zones.some((zone: ShipmentZone) => {
							return zone.prices.length > 0;
						})
					) {
						zones = pricesItem.shipmentPrices.available_zones;
					} else {
						if (
							pricesItem &&
							pricesItem.shipmentPrices.all_zones &&
							pricesItem.shipmentPrices.all_zones?.length > 0
						) {
							zones = pricesItem.shipmentPrices.all_zones;
						}
					}

					const prices: ShipmentPrice[] = [];
					zones.forEach((zone: ShipmentZone) => {
						if (zone.prices.length) {
							zone.prices.forEach((price: ShipmentPrice) => {
								prices.push(price);
							});
						}
					});
					if (prices && prices.length > 0) return prices;
				}
			}

			const shipment = getShipmentById(shipmentId);
			if (shipment) {
				if (shipment.prices) return shipment.prices;
			}
			return [];
		},
		[computedShipmentsPrices, getShipmentById, isAvailablePricesEqualShipmentPrices]
	);

	const computedDisabledShipping: boolean = useMemo(() => {
		if (!!error) return true;
		return allPricesLoading || selectedPriceLoading;
	}, [allPricesLoading, error, selectedPriceLoading]);

	const getPricesString = useCallback(
		(prices: ShipmentPrice[], currency: string = ""): string => {
			if (prices.length === 0) {
				return orders.free;
			}
			const result = prices.reduce(
				(acc, obj) => {
					acc.lowest = Math.min(acc.lowest, obj.cost_delivery);
					acc.highest = Math.max(acc.highest, obj.cost_delivery);

					return acc;
				},
				{
					lowest: Number.POSITIVE_INFINITY,
					highest: Number.NEGATIVE_INFINITY,
				}
			);

			if (result.lowest === result.highest) {
				if (result.lowest === 0) {
					return orders.free;
				}
				return formatCurrency(
					result.lowest.toFixed(2),
					brandInfo?.default_lang || lang,
					currency || ""
				);
			}

			const low = `${formatCurrency(result.lowest.toFixed(2), brandInfo?.default_lang || lang, currency || "")}`;
			const high = `${formatCurrency(result.highest.toFixed(2), brandInfo?.default_lang || lang, currency || "")}`;

			return `${low} - ${high}`;
		},
		[brandInfo?.default_lang, lang, orders.free]
	);

	const computedSelectedShipmentPolygons: IZonePolygon[] = useMemo(() => {
		if (selectedShipmentService.selectedShipment) {
			const shipment = computedShipmentsPrices?.find(
				(shipmentPrices: IShipmentPricesItem) => {
					return (
						shipmentPrices.shipmentId === selectedShipmentService.selectedShipment?.id
					);
				}
			);
			if (shipment) {
				const polygons: IZonePolygon[] = [];
				if (shipment.shipmentPrices.all_zones && shipment.shipmentPrices.all_zones.length) {
					shipment.shipmentPrices.all_zones.forEach((zone: ShipmentZone) => {
						if (zone.polygon) {
							const obj: IZonePolygon = {
								polygon: zone.polygon,
								pricesString: `${zone.name}, ${getPricesString(zone.prices, currency || "")}`,
							};
							polygons.push(obj);
						}
					});
				}
				return polygons;
			}
		}
		return [];
	}, [
		computedShipmentsPrices,
		currency,
		getPricesString,
		selectedShipmentService.selectedShipment,
	]);

	const computedSelectedShipmentRadius: IZoneRadius[] = useMemo(() => {
		if (selectedShipmentService.selectedShipment) {
			const shipment = computedShipmentsPrices?.find(
				(shipmentPrices: IShipmentPricesItem) => {
					return (
						shipmentPrices.shipmentId === selectedShipmentService.selectedShipment?.id
					);
				}
			);
			if (shipment) {
				const radius: IZoneRadius[] = [];
				if (shipment.shipmentPrices.all_zones && shipment.shipmentPrices.all_zones.length) {
					shipment.shipmentPrices.all_zones.forEach((zone: ShipmentZone) => {
						if (zone.distance) {
							const obj: IZoneRadius = {
								radius: zone.distance,
								pricesString: `${zone.name}, ${getPricesString(zone.prices, currency || "")}`,
							};
							radius.push(obj);
						}
					});
				}
				return radius;
			}
		}
		return [];
	}, [
		computedShipmentsPrices,
		currency,
		getPricesString,
		selectedShipmentService.selectedShipment,
	]);

	const getNextPriceByShipmentId = useCallback(
		(shipmentId: number) => {
			if (computedShipmentsPrices) {
				const shipment = computedShipmentsPrices.find(
					(shipmentPrices: IShipmentPricesItem) => {
						return shipmentPrices.shipmentId === shipmentId;
					}
				);
				if (shipment && shipment.shipmentPrices.next_price) {
					return shipment.shipmentPrices.next_price;
				}
			}
			return null;
		},
		[computedShipmentsPrices]
	);

	useEffect(() => {
		setAllPricesLoading(queryAllPrices.isFetching);
	}, [queryAllPrices.isFetching]);

	useEffect(() => {
		setSelectedPriceLoading(querySelectedPrice.isFetching);
	}, [querySelectedPrice.isFetching]);

	return useMemo(() => {
		return {
			computedShipmentsPrices,
			computedSelectedShipmentPrice,
			getShipmentPricesById,
			allPricesLoading,
			selectedPriceLoading,
			computedDisabledShipping,
			error,
			computedSelectedShipmentHasZones,
			getNextPriceByShipmentId,
			computedSelectedShipmentPolygons,
			computedSelectedShipmentRadius,
			getPricesString,
			setError,
		};
	}, [
		computedShipmentsPrices,
		computedSelectedShipmentPrice,
		getShipmentPricesById,
		allPricesLoading,
		selectedPriceLoading,
		computedDisabledShipping,
		error,
		computedSelectedShipmentHasZones,
		getNextPriceByShipmentId,
		computedSelectedShipmentPolygons,
		computedSelectedShipmentRadius,
		getPricesString,
		setError,
	]);
}

export interface IShipmentPricesService {
	computedShipmentsPrices: IShipmentPricesItem[] | null | undefined;
	computedSelectedShipmentPrice: ShipmentPrice | null;
	getShipmentPricesById: (shipmentId: number) => ShipmentPrice[];
	allPricesLoading: boolean;
	selectedPriceLoading: boolean;
	computedDisabledShipping: boolean;
	error: IPricesError | null;
	computedSelectedShipmentHasZones: boolean;
	getNextPriceByShipmentId: (shipmentId: number) => ShipmentPrice | null;
	computedSelectedShipmentPolygons: IZonePolygon[];
	computedSelectedShipmentRadius: IZoneRadius[];
	getPricesString: (prices: ShipmentPrice[], currency?: string) => string;
	setError: (error: IPricesError | null) => void;
}

export interface IShipmentPricesItem {
	shipmentId: number;
	shipmentPrices: ShipmentPriceResultData;
}

export interface IZoneMapBase {
	pricesString: string;
	color?: string | null;
}

export interface IZonePolygon extends IZoneMapBase {
	polygon: number[][];
}

export interface IZoneRadius extends IZoneMapBase {
	radius: number;
}

import { ThemeModal } from "../../../helpers/ThemeComponents";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import Filter from "./Filter";
import { SetState } from "../../../types";
import PriceFilter from "./PriceFilter";
import { Box, Button, Grid } from "@mui/material";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import useAppContext from "../../../useAppContext";

interface FilterModalProps {
	show: boolean;
	setShow: SetState<boolean>;
}

const ColumnsCount = 2;

export default function FiltersModal({ show, setShow }: FilterModalProps) {
	const { menu } = useLocalisation();
	const { appearance } = useAppContext();

	const { categoriesService, selectedStore, storeFilters } = useSelectedStoreContext();

	const activeCategory = categoriesService.getActiveCategory();
	const filters = activeCategory?.filters || storeFilters || [];

	return (
		<ThemeModal
			title={menu.filtersModalTitle}
			open={show}
			setOpen={setShow}
			keepMounted
			maxWidth={"sm"}
			classes={{
				paper: "w-100",
			}}
		>
			<Grid container spacing={2}>
				{appearance.computedNeedFilterByPrice && (
					<Grid item key={"price"} xs={12} sm={12 / ColumnsCount}>
						<PriceFilter />
					</Grid>
				)}
				{filters &&
					filters.map(filter => (
						<Grid item key={filter.id} xs={12} sm={12 / ColumnsCount}>
							<div>
								<Filter filter={filter} />
							</div>
						</Grid>
					))}
			</Grid>
			<Box textAlign={"end"} width={"100%"} mt={2}>
				<Button onClick={() => setShow(false)}>{menu.closeFiltersModalButton}</Button>
			</Box>
		</ThemeModal>
	);
}

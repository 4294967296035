import { OrderProduct } from "../../../../../api/shop/order/types";
import useAppContext from "../../../../../useAppContext";
import ImageStub from "../../../../../features/ImageStub";
import { Box, Link, Typography } from "@mui/material";
import { ReactNode } from "react";
import f from "../../../../../helpers/formatText";
import changePage from "../../../../../helpers/changePage";
import Interweave from "../../../../../features/Interweave";
import formatNumber from "../../../../../helpers/formatNumber";
import { useSelectedStoreContext } from "../../../../SelectedStore/context";
import formatCurrency from "../../../../../helpers/formatCurrency";
import useCurrency from "../../../../../services/useCurrencyService";

interface IOrderContentWithTypeWrapperProps {
	createDate: string;
	product: OrderProduct;
	children: ReactNode;
	isCurrency?: boolean;
	disabledBold?: boolean;
}

export default function OrderContentWithTypeWrapper(props: IOrderContentWithTypeWrapperProps) {
	const {
		lang,
		groupService: { group },
		localisation: { check },
		brandInfo,
	} = useAppContext();
	const { selectedStore } = useSelectedStoreContext();
	const currency = useCurrency();

	const date = new Date(props.createDate).toLocaleString(lang || "en", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		timeZone: group?.timezone,
	});
	const sum =
		Math.round((props.product.price * props.product.quantity + Number.EPSILON) * 100) / 100;

	return (
		<>
			{props.product.product.image_url ? (
				<img
					width={"100%"}
					height={"auto"}
					src={props.product.product.image_url}
					alt={props.product.name}
				/>
			) : (
				<ImageStub ratio={[1, 1]} emptyStub={false} />
			)}

			<Box sx={{ px: 3 }}>
				<Box mt={2}>
					<Typography variant={"h6"} fontWeight={"bold"}>
						{f(check.storeNameText, { name: selectedStore.name })}
					</Typography>
					<Typography>{f(check.dateText, { datetime: date })}</Typography>
					<Box
						display={"flex"}
						flexWrap={"wrap"}
						fontWeight={props.disabledBold ? "" : "bold"}
						mt={2}
					>
						<Box>
							<Link
								href={undefined}
								onClick={() => {
									changePage(
										`/shop/${selectedStore.id}/menu?product_id=${props.product.product.id}`
									);
								}}
								target={"_blank"}
								sx={{ cursor: "pointer" }}
							>
								<Interweave content={props.product.name} />
							</Link>
							{props.product.price > 0 && (
								<>
									{" "}
									{props.isCurrency
										? formatCurrency(
												props.product.price,
												brandInfo?.default_lang || lang,
												currency
											)
										: formatNumber(
												props.product.price,
												brandInfo?.default_lang || lang
											)}
								</>
							)}
							{" x "}
							{props.product.quantity}
						</Box>
						{props.product.price > 0 && (
							<Box ml={"auto"}>
								{props.isCurrency
									? formatCurrency(sum, brandInfo?.default_lang || lang, currency)
									: formatNumber(sum, brandInfo?.default_lang || lang)}
							</Box>
						)}
					</Box>
					{props.children}
				</Box>
			</Box>
		</>
	);
}

import { useEffect, useState } from "react";
import { AppleLoginProps } from "./types";
import useScript from "../../useScript";

export default function useAppleLogin(props: AppleLoginProps) {
	const {
		clientId,
		redirectURI,
		state = "",
		responseMode = "query",
		responseType = "code id_token",
		nonce,
		callback,
		scope,
		usePopup = false,
	} = props;

	const [isInited, setIsInited] = useState<boolean>(false);

	const [loaded] = useScript(
		"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
	);

	const [loggedIn, setLoggedIn] = useState<any>(null);

	const generateQueryString = (q: any) => {
		let queryString = "";
		if (q) {
			const queryKeys = Object.keys(q);
			queryKeys.forEach(key => {
				if (q[key]) {
					if (q[key].toString().length) {
						queryString += `${key}=${q[key]}&`;
					}
				}
			});
			if (queryKeys.length > 0 && queryString[queryString.length - 1] === "&") {
				queryString = queryString.slice(0, -1);
			}
		}
		return queryString;
	};

	const login = async (e: any = null) => {
		if (e) {
			e.preventDefault();
		}
		if (!usePopup) {
			const host = `${window.location.protocol}//${window.location.host}`;
			let appleState = `host:${host}_`;
			appleState += `continue:${host}${window.location.pathname}${window.location.search}_`;
			appleState += `service:client`;

			window.location.href = `https://appleid.apple.com/auth/authorize?${generateQueryString({
				response_type: responseType,
				response_mode: responseMode,
				client_id: clientId,
				redirect_uri: encodeURIComponent(redirectURI),
				state: appleState,
				nonce,
				scope: responseMode === "query" ? "" : scope,
			})}`;
		} else {
			try {
				const options = {
					clientId: clientId,
					scope: scope,
					redirectURI: `${window.location.protocol}//${window.location.host}`,
					usePopup: true,
				};
				const data = await window.AppleID.auth.signIn(options);
				if (typeof callback === "function" && data) {
					callback(data);
				}
			} catch (err) {
				console.log("*** APPLE ERROR", err);
				if (typeof callback === "function") {
					callback({ error: err });
				}
			}
		}
	};

	useEffect(() => {
		if (loaded) {
			if (usePopup && !isInited) {
				const options = {
					clientId: clientId,
					scope: scope,
					redirectURI:
						redirectURI || `${window.location.protocol}//${window.location.host}`,
					usePopup: true,
				};
				window.AppleID.auth.init(options);
				setIsInited(true);
			} else {
				if (
					typeof callback === "function" &&
					responseMode === "query" &&
					responseType === "code" &&
					window &&
					window.location
				) {
					let match;
					const pl = /\+/g, // Regex for replacing addition symbol with a space
						search = /([^&=]+)=?([^&]*)/g,
						decode = (s: any) => {
							return decodeURIComponent(s.replace(pl, " "));
						},
						query = window.location.search.substring(1);

					let urlParams: any = {};
					while ((match = search.exec(query))) {
						urlParams[decode(match[1])] = decode(match[2]);
					}
					if (urlParams["code"]) {
						callback({
							code: urlParams["code"],
						});
					}
				}
			}
		}
	}, [
		callback,
		clientId,
		loaded,
		nonce,
		redirectURI,
		responseMode,
		responseType,
		scope,
		state,
		usePopup,
		isInited,
	]);

	useEffect(() => {
		if (loggedIn) {
			if (typeof callback === "function") {
				callback(loggedIn);
			}
			setLoggedIn(null);
		}
	}, [callback, loggedIn]);

	useEffect(() => {
		// Listen for authorization success.
		document.addEventListener("AppleIDSignInOnSuccess", (event: any) => {
			// Handle successful response.
			console.log("*** LISTENER SUCCESS", event.detail);
			setLoggedIn(event.detail);
		});

		// Listen for authorization failures.
		document.addEventListener("AppleIDSignInOnFailure", (event: any) => {
			// Handle error.
			console.log("LISTENER", event?.detail?.error);
		});

		return () => {
			document.removeEventListener("AppleIDSignInOnSuccess", () => {});
			document.removeEventListener("AppleIDSignInOnFailure", () => {});
		};
	}, []);

	return { login, loaded };
}

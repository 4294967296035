import useAppContext from "../../../../../useAppContext";
import { Box, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { Share } from "@mui/icons-material";

export interface ShareInfoProps {
	couponBenefitAmount: string;
	handleShare: () => unknown;
	isShareLoading: boolean;
}

export function ShareInfo(props: ShareInfoProps) {
	const {
		localisation: { profile },
	} = useAppContext();

	return (
		<Box
			display={"flex"}
			flexDirection={"row"}
			alignItems={"center"}
			mt={"auto"}
			ml={"auto"}
			mb={{ sm: 1 }}
			gap={"10px"}
			position={"relative"}
		>
			<Box
				ml={"auto"}
				display={"inline-flex"}
				justifyContent={"flex-end"}
				textAlign={"end"}
				maxWidth={"100%"}
				flexWrap={"wrap"}
				columnGap={"5px"}
				fontStyle={"italic"}
			>
				<Typography fontWeight={400} variant="body2">
					{profile.shareCouponBenefits}
				</Typography>
				<Typography fontWeight={700} variant="body2">
					{props.couponBenefitAmount}
				</Typography>
			</Box>
			<Tooltip title={profile.shareCouponTitle}>
				<IconButton
					size="small"
					onClick={props.handleShare}
					color="inherit"
					sx={{
						my: "auto",
					}}
				>
					{props.isShareLoading ? (
						<CircularProgress size={20} color={"inherit"} />
					) : (
						<Share fontSize={"small"} />
					)}
				</IconButton>
			</Tooltip>
		</Box>
	);
}

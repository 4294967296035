import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useAppContext from "../../../useAppContext";
import api from "../../../api";
import { Store } from "../../../api/shop/basic/types";
import { useMemo } from "react";
import useUserIncustCustomerData from "../../../hooks/incust/useUserIncustCustmerData";
import { IUseIncustPayData } from "../types";

export default function useIncustPayData(
	selectedStore: Store | null,
	isIncustPayMethod?: boolean,
): IUseIncustPayData {
	const {
		lang,
		brandInfo,
		authService: { user },
	} = useAppContext();
	const { customerData } = useUserIncustCustomerData(selectedStore?.id);

	const incustPayData = useQuery({
		queryKey: ["incust_pay_data", selectedStore?.id, lang, user, customerData],
		queryFn: async () => {
			const response = await api.shop.payments.getIncustPayData(selectedStore?.id || null);
			return response.data;
		},
		enabled: isIncustPayMethod || brandInfo?.loyalty_info?.loyalty_enabled,
		initialData: null,
		placeholderData: keepPreviousData,
	});

	const computedIsFetching = useMemo(() => {
		return incustPayData.isFetching;
	}, [incustPayData.isFetching]);

	return {
		incustPayData: incustPayData.data,
		isLoading: computedIsFetching,
		refetchPayData: incustPayData.refetch,
	};
}

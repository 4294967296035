import { useEffect } from "react";

import { CreateOrderPayload } from "../../../../../api/shop/order/types";
import { SetMergeState } from "../../../../../helpers/useMergedState";
import useLocalisation from "../../../../../hooks/localisation/useLocalisation";
import { MenuItem, Select } from "@mui/material";
import { useSelectedStoreContext } from "../../../../SelectedStore/context";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import TextField from "@mui/material/TextField";
import { SelectedShipmentServiceType } from "../../useSelectedShipmentService";
import { ShipmentPrice } from "../../../../../api/shop/basic/types";
import { IAddressValid } from "../../../../../features/AddressInput/AddressInput";
import { IZonePolygon, IZoneRadius } from "../../../../services/useShipmentPrices";

interface ICustomShippingProps {
	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;
	selectedShipmentService: SelectedShipmentServiceType;
	showInvalidError: boolean;
	getShipmentPricesById: (shipmentId: number) => ShipmentPrice[];
	computedSelectedShipmentHasZones: boolean;

	polygons?: IZonePolygon[];
	radius?: IZoneRadius[];
	showMap: boolean;
	setShowMap: (value: boolean) => void;

	setValidAddress?: (value: IAddressValid | null) => void;
}

export default function CustomShipping(props: ICustomShippingProps) {
	const localisation = useLocalisation();

	const handleCustomShipmentCommentChanged = (e: any) =>
		props.setForm({ custom_shipment_comment: e.target.value });

	const { selectedShipment, setSelectedShipment, selectedCustomShipmentGroup } =
		props.selectedShipmentService;

	useEffect(() => {
		if (
			selectedShipment?.id &&
			selectedCustomShipmentGroup?.shipments.length &&
			!selectedCustomShipmentGroup.shipments.some(
				shipment => shipment.id === selectedShipment.id
			)
		) {
			setSelectedShipment(selectedCustomShipmentGroup.shipments[0]);
		}
	}, [selectedShipment, selectedCustomShipmentGroup, setSelectedShipment]);

	if (selectedShipment?.custom_type !== "custom_shipment") return null;

	return (
		<>
			{selectedShipment.need_comment && (
				<TextField
					size={"small"}
					required
					maxRows={10}
					fullWidth
					error={props.showInvalidError && !props.form.custom_shipment_comment}
					helperText={
						props.showInvalidError &&
						!props.form.custom_shipment_comment &&
						localisation.orders.customFeedback.replace(
							"{field_name}",
							selectedShipment.label_comment
						)
					}
					id={"custom_shipment_comment"}
					label={selectedShipment.label_comment}
					value={props.form.custom_shipment_comment || ""}
					onChange={e => handleCustomShipmentCommentChanged(e)}
					sx={{
						mt: 2,
					}}
				/>
			)}
		</>
	);
}

interface ICustomShippingHeaderProps {
	selectedShipmentService: SelectedShipmentServiceType;
}

export function CustomShippingHeader(props: ICustomShippingHeaderProps) {
	const { selectedShipment, setSelectedShipment, selectedCustomShipmentGroup } =
		props.selectedShipmentService;
	const { shipmentsService } = useSelectedStoreContext();

	const handleCustomShipmentChange = (event: SelectChangeEvent<number>) => {
		const newValue =
			typeof event.target.value === "string"
				? parseInt(event.target.value)
				: event.target.value;

		if (newValue && newValue !== selectedShipment?.id) {
			const shipment = shipmentsService.getShipmentById(newValue);
			if (shipment && shipment.custom_type !== "custom_shipment_group") {
				setSelectedShipment(shipment);
			}
		}
	};

	return (
		selectedCustomShipmentGroup && (
			<>
				<Select
					fullWidth
					value={selectedShipment?.id}
					onChange={handleCustomShipmentChange}
					size={"small"}
					sx={{
						marginBottom: 2,
					}}
				>
					{selectedCustomShipmentGroup.shipments.map(shipment => (
						<MenuItem value={shipment.id} key={shipment.id}>
							{shipment.name}
						</MenuItem>
					))}
				</Select>
			</>
		)
	);
}

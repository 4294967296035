import { useMemo } from "react";
import { Order } from "../../../../../api/shop/order/types";
import OrderContentWithTypeWrapper from "./OrderContentWithTypeBase";
import { Box } from "@mui/material";
import { buildChargeString } from "../../../../../features/TopupOptions/helper";
import useAppContext from "../../../../../useAppContext";
import useCurrencyInfo from "../../../../../hooks/useCurrencyInfo";
import useCurrency from "../../../../../services/useCurrencyService";
import Alert from "@mui/material/Alert";
import formatNumber from "../../../../../helpers/formatNumber";

interface IOrderContentTopupProps {
	order: Order;
}

export default function OrderContentTopup(props: IOrderContentTopupProps) {
	const {
		lang,
		localisation: { check, topup },
		brandInfo,
	} = useAppContext();
	const currency = useCurrency();
	const { symbol, position } = useCurrencyInfo(brandInfo?.default_lang || lang, currency || "");

	const product = props.order.order_products[0];

	const computedChargeTitle = useMemo(() => {
		return buildChargeString(
			`${topup.charge}:`,
			product.charge_percent,
			product.charge_fixed,
			brandInfo?.default_lang || lang || "",
			position,
			symbol
		);
	}, [
		brandInfo?.default_lang,
		lang,
		position,
		product.charge_fixed,
		product.charge_percent,
		symbol,
		topup.charge,
	]);

	return (
		<OrderContentWithTypeWrapper
			product={product}
			createDate={props.order.create_date}
			disabledBold={true}
			isCurrency={true}
		>
			{!!computedChargeTitle && (
				<Box display={"flex"} flexWrap={"wrap"}>
					<Box>{computedChargeTitle}</Box>
					<Box ml={"auto"}>
						{position === "start"
							? `${symbol} ${formatNumber(product.topup_charge || 0, brandInfo?.default_lang || lang)}`
							: `${formatNumber(product.topup_charge || 0, brandInfo?.default_lang || lang)} ${symbol}`}
					</Box>
				</Box>
			)}

			<Box display={"flex"} flexWrap={"wrap"} fontWeight={"bold"}>
				<Box>{check.paidText}</Box>
				<Box ml={"auto"}>
					{position === "start"
						? `${symbol} ${formatNumber(product.total_sum || 0, brandInfo?.default_lang || lang)}`
						: `${formatNumber(product.total_sum || 0, brandInfo?.default_lang || lang)} ${symbol}`}
				</Box>
			</Box>

			<Box mt={2}>
				{/*@ts-ignore*/}
				{topup.account}: <strong>{product.incust_account?.title}</strong>
			</Box>

			{!!product.incust_card && (
				<Box>
					{topup.card}: <strong>{product.incust_card}</strong>
				</Box>
			)}

			{props.order.status === "closed" && !product.is_topup_error && (
				<Alert severity={"success"} sx={{ mt: 2 }}>
					{topup.successMsg}
				</Alert>
			)}
			{product.is_topup_error && (
				<Alert severity={"error"} sx={{ mt: 2 }}>
					{topup.notificationError}
				</Alert>
			)}
		</OrderContentWithTypeWrapper>
	);
}

import { IIncustCoupon } from "../../../types";
import useAppContext from "../../../../../useAppContext";
import { Box, Typography } from "@mui/material";

export function DateOfUse(props: { coupon: IIncustCoupon; lang: string | null }) {
	const {
		localisation: { profile },
	} = useAppContext();
	return (
		<Box textAlign={"center"}>
			{props.coupon?.redeem_dt ? (
				<Typography
					color={"warning.main"}
					fontSize={"12px"}
					sx={{ whiteSpace: "pre-wrap" }}
				>
					{profile.loyaltyCouponRedeemDate + ": "}
					{new Date(props.coupon.redeem_dt).toLocaleDateString(props.lang || "en", {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					})}
				</Typography>
			) : !!props.coupon.expire_date?.length ? (
				<Typography fontSize={"12px"} sx={{ whiteSpace: "pre-wrap" }}>
					{profile.loyaltyCouponExpiredDate + ": "}
					{new Date(props.coupon.expire_date).toLocaleDateString(props.lang || "en", {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					})}
				</Typography>
			) : (
				<Typography fontSize={"11.5px"}>{profile.loyaltyCouponPeriodNoLimit}</Typography>
			)}
		</Box>
	);
}

import {useCallback, useEffect, useRef, useState} from "react";
import {GetCitiesArgs, GetStoresArgs, Shipment, Store} from "../../../api/shop/basic/types";
import {useShopContext} from "../../context";
import api from "../../../api";
import {StoresServiceType} from "./types";
import useAppContext from "../../../useAppContext";


export default function useStoresService(shipment: Shipment | null): StoresServiceType {
    const {
        showError,
        lang,
        loadingManager: {getIsLoading, setIsLoading}
    } = useAppContext()
    const {brandInfo} = useShopContext()

    const [cities, setCities] = useState<string[]>([])
    const [stores, setStores] = useState<Store[]>([])

    const [selectedCity, setSelectedCity] = useState<string | null>(null)

    const lastCityRequestShipmentIdRef = useRef<number | null>(null)

    const lastStoreRequestCityRef = useRef<string | null>(null)
    const lastStoreRequestShipmentIdRef = useRef<number | null>(null)
    const lastStoreRequestLangRef = useRef<string | null>(null)

    const isLang = !!lang

    const setIsStoresLoading = useCallback((isLoading: boolean) => {
        setIsLoading('stores', isLoading)
    }, [setIsLoading])

    const setIsCitiesLoading = useCallback((isLoading: boolean) => {
        setIsLoading('cities', isLoading)
    }, [setIsLoading])

    useEffect(() => {
        const loadCities = async() => {
            const args: GetCitiesArgs = {
                brand_id: brandInfo.id
            }

            if (!isLang || shipment?.id === lastCityRequestShipmentIdRef.current) return

            lastCityRequestShipmentIdRef.current = shipment?.id || null

            if (shipment && shipment.base_type !== "no_delivery") {
                args[`is_${shipment.base_type}`] = true
            }

            setIsCitiesLoading(true)
            try {
                const response = await api.shop.basic.getCities(args)
                if (lastCityRequestShipmentIdRef.current !== shipment?.id || null) {
                    return null
                }

                setCities(response.data.cities)
            } catch (err: any) {
                console.log(err)
                showError(err)
            } finally {
                setIsCitiesLoading(false)
            }
        }
        loadCities().then()
    }, [
        shipment,
        brandInfo.id, isLang,
        showError,
        setIsCitiesLoading,
    ])

    useEffect(() => {
        const loadStores = async() => {
            if (
                !lang ||
                (
                    shipment && lastStoreRequestShipmentIdRef.current &&
                    shipment.id === lastStoreRequestShipmentIdRef.current &&
                    lastStoreRequestCityRef.current === selectedCity &&
                    lastStoreRequestLangRef.current === lang
                )
            ) {
                return
            }

            const args: GetStoresArgs = {
                brand_id: brandInfo.id,
            }
            if (selectedCity) {
                args.city = selectedCity
            }
            if (shipment && shipment.base_type !== "no_delivery") {
                args[`is_${shipment.base_type}`] = true
            }

            lastStoreRequestShipmentIdRef.current = shipment?.id || null
            lastStoreRequestCityRef.current = selectedCity
            lastStoreRequestLangRef.current = lang

            setIsStoresLoading(true)
            try {
                const response = await api.shop.basic.getStores(args)
                if (
                    lastStoreRequestCityRef.current !== selectedCity ||
                    lastStoreRequestShipmentIdRef.current !== (shipment?.id || null) ||
                    lastStoreRequestLangRef.current !== lang
                ) {
                    return
                }

                setStores(response.data)
            } catch (err: any) {
                console.log(err)
                showError(err)
            } finally {
                setIsStoresLoading(false)
            }
        }
        loadStores().then()
    }, [
        shipment,
        brandInfo.id,
        selectedCity,
        showError,
        lang,
        setIsStoresLoading,
    ])

    const isStoresLoading = getIsLoading('stores')
    const isCitiesLoading = getIsLoading('cities')

    return {
        cities,
        setCities,
        stores,
        setStores,
        selectedCity,
        setSelectedCity,
        isStoresLoading,
        isCitiesLoading,
    }
}

import {Drawer} from "@mui/material";

import ProfileSidebar from "../../profile/ProfileSidebar";
import useAppContext from "../../../useAppContext";
import SidebarHeader from "../SidebarHeader";
import SidebarButton from "../SidebarButton";
import {useSelectedStoreOptional} from "../../SelectedStore/context";
import changePage from "../../../helpers/changePage";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

export default function ProfileSidebarDrawer(){
    const {sidebarsService, localisation: {profile}} = useAppContext()
    const selectedStore = useSelectedStoreOptional()

    const handleProfileClick = () => {
        try{
            if(!!selectedStore){
                return changePage(`/shop/${selectedStore.id}/profile`)
            }
            changePage(`/shop/profile`)
        }
        finally {
            sidebarsService.setIsProfileSidebarOpen(false)
        }
    }

    return (
        <Drawer
            open={sidebarsService.isProfileSidebarOpen}
            onClose={() => sidebarsService.setIsProfileSidebarOpen(false)}
            classes={{
                paperAnchorRight: 'w-75'
            }}
            transitionDuration={300}
            anchor={'right'}
        >
            <SidebarHeader type={'profile'} />

            <SidebarButton
                text={profile.profile}
                action={handleProfileClick}
                icon={<PersonOutlinedIcon fontSize={'medium'} />}
            />

            <div className="py-2 h-100">
                <ProfileSidebar withWrapper={false} isMobileSidebar={true} />
            </div>
        </Drawer>
    )
}

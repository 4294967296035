import { AuthorisationFormState } from "./useAuthorisationForm/types";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import useAppContext from "../../useAppContext";
import { useMessangerName } from "../../hooks/messanger";
import f from "../../helpers/formatText";
import { ReactNode } from "react";
import MessangerIcon from "../../features/MessangerIcon";

export default function useAuthorisationModalTitle(
	state: AuthorisationFormState["state"]
): ReactNode {
	const {
		groupService: { group },
		bot,
	} = useAppContext();
	const localisation = useLocalisation();

	const messangerName = useMessangerName(bot?.bot_type);

	if (state === "emailLogin") {
		return localisation.auth.loginModalHeader;
	} else if (state === "emailRegistration") {
		return localisation.auth.registerModalHeader;
	} else if (state === "externalLogin") {
		return (
			<>
				{f(
					group?.texts.web.auth.external_login.title ||
						localisation.auth.authorisationModalHeader,
					{
						messanger_name: messangerName,
					}
				)}
				<MessangerIcon botType={bot?.bot_type} sx={{ mr: 0, ml: 1 }} />
			</>
		);
	} else {
		return localisation.auth.authorisationModalHeader;
	}
}

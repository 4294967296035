import { IAppearanceSettings, IUseAppearance } from "./types";
import { useEffect, useMemo, useState } from "react";
import { BrandInfo } from "../../../api/shop/basic/types";
import { LoadingManager } from "../../../features/hooks/useLoadingManager";
import { calculateTheme, setFont } from "../../../theme/theme";
import { experimental_extendTheme as materialExtendTheme } from "@mui/material";
import api from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { GroupColorSchema } from "../../../theme/types";

declare module "@mui/material/styles" {
	interface Palette {
		telegram: Palette["primary"];
		whatsapp: Palette["primary"];
	}

	interface PaletteOptions {
		telegram?: PaletteOptions["primary"];
		whatsapp?: PaletteOptions["primary"];
	}
}

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		telegram: true;
		whatsapp: true;
	}
}

declare module "@mui/material/IconButton" {
	interface IconButtonPropsColorOverrides {
		telegram: true;
		whatsapp: true;
	}
}

declare module "@mui/material/SvgIcon" {
	interface SvgIconPropsColorOverrides {
		telegram: true;
		whatsapp: true;
	}
}

export default function useAppearance(
	brandInfo: BrandInfo | null,
	loadingManager: LoadingManager
): IUseAppearance {
	const [appearanceState, setAppearanceState] = useState<IAppearanceSettings | null>(null);

	const spacingMap = useMemo(() => {
		return {
			1: 4,
			2: 8,
			3: 16,
			4: 24,
			5: 48,
		};
	}, []);

	const {
		data: appearanceQuery,
		isPending,
		isError,
	} = useQuery({
		queryKey: ["group-appearance", brandInfo?.group_id],
		enabled: !!brandInfo?.group_id,
		initialData: null,
		queryFn: async () => {
			if (brandInfo?.group_id) {
				console.log("*** getting appearance");
				const response = await api.groups.getGroupAppearanceSettings(brandInfo.group_id);
				if (response.data) {
					console.log("*** getting appearance", response.data);
					return response.data;
				}
				console.log("*** getting appearance null");
				return null;
			}
		},
	});

	const { setIsLoading } = loadingManager;
	useEffect(() => {
		setIsLoading("appearance", isPending && !isError);
		return () => {
			setIsLoading("appearance", false);
		};
	}, [isError, isPending, setIsLoading]);

	const computedColorSchema: GroupColorSchema | null = useMemo(() => {
		if (!appearanceQuery) return null;
		return {
			theme_mode: appearanceState?.theme_mode || appearanceQuery.theme_mode || undefined,
			bg_color: appearanceState?.bg_color || appearanceQuery.bg_color,
			secondary_bg_color:
				appearanceState?.secondary_bg_color || appearanceQuery.secondary_bg_color,
			primary_color: appearanceState?.primary_color || appearanceQuery.primary_color,
			secondary_color: appearanceState?.secondary_color || appearanceQuery.secondary_color,
			error_color: appearanceState?.error_color || appearanceQuery.error_color,
			warning_color: appearanceState?.warning_color || appearanceQuery.warning_color,
			text_color: appearanceState?.text_color || appearanceQuery.text_color,
			font: appearanceState?.font || appearanceQuery.font,
			use_telegram_theme:
				appearanceState?.use_telegram_theme || appearanceQuery.use_telegram_theme,
		};
	}, [
		appearanceQuery,
		appearanceState?.bg_color,
		appearanceState?.error_color,
		appearanceState?.font,
		appearanceState?.primary_color,
		appearanceState?.secondary_bg_color,
		appearanceState?.secondary_color,
		appearanceState?.text_color,
		appearanceState?.theme_mode,
		appearanceState?.use_telegram_theme,
		appearanceState?.warning_color,
	]);

	const computedTheme = useMemo(() => {
		return calculateTheme(computedColorSchema);
	}, [computedColorSchema]);

	const computedFontFamily = useMemo(() => {
		const fontData = setFont(appearanceState?.font || appearanceQuery?.font);
		return fontData.family;
	}, [appearanceQuery?.font, appearanceState?.font]);

	const computedMuiTheme = useMemo(() => {
		const theme = materialExtendTheme();
		console.log("theme", theme);
		return materialExtendTheme({
			colorSchemes: {
				[computedTheme.mode]: {
					palette: {
						...(computedTheme?.primary_color && {
							primary: {
								main: computedTheme?.primary_color,
							},
						}),
						...(computedTheme?.secondary_color && {
							secondary: {
								main: computedTheme.secondary_color,
							},
						}),
						...(computedTheme?.error_color && {
							error: {
								main: computedTheme.error_color,
							},
						}),
						...(computedTheme?.warning_color && {
							warning: {
								main: computedTheme.warning_color,
							},
						}),
						...(computedTheme?.text_color && {
							text: {
								primary: computedTheme.text_color,
							},
						}),
						...((computedTheme?.bg_color || computedTheme?.secondary_bg_color) && {
							background: {
								...(computedTheme?.bg_color && {
									default: computedTheme.bg_color,
								}),
								...(computedTheme?.secondary_bg_color && {
									paper: computedTheme.secondary_bg_color,
								}),
							},
						}),
						telegram: theme.colorSchemes.light.palette.augmentColor({
							color: {
								main: "#229ED9",
							},
							name: "telegram",
						}),
						whatsapp: theme.colorSchemes.light.palette.augmentColor({
							color: {
								main: "#25d366",
							},
							name: "whatsapp",
						}),
						hint: computedTheme.hint_color,
						borderColor: "#e0e0e0",
					},
				},
			},
			typography: {
				...(computedFontFamily && { fontFamily: computedFontFamily }),
			},
			components: {
				MuiLink: {
					styleOverrides: {
						root: ({ ownerState, theme }) =>
							theme.unstable_sx({
								color: ownerState.color,
								"&:hover": {
									color: ownerState.color,
								},
							}),
					},
				},
			},
			spacing: (abs: number) => {
				if (abs in spacingMap) {
					// @ts-ignore
					return spacingMap[abs];
				}
				return abs * 8;
			},
		});
	}, [
		computedFontFamily,
		computedTheme?.bg_color,
		computedTheme?.error_color,
		computedTheme?.hint_color,
		computedTheme?.mode,
		computedTheme?.primary_color,
		computedTheme?.secondary_bg_color,
		computedTheme?.secondary_color,
		computedTheme?.text_color,
		computedTheme?.warning_color,
		spacingMap,
	]);

	const getBooleanValue = (
		value: boolean | null | undefined,
		queryValue: boolean | null | undefined,
		defaultValue: boolean
	) => {
		if (value !== null && value !== undefined) {
			return value;
		} else if (queryValue !== null && queryValue !== undefined) {
			return queryValue;
		} else {
			return defaultValue;
		}
	};

	const computedNeedFilterSearch = useMemo(() => {
		return getBooleanValue(
			appearanceState?.is_filter_search,
			appearanceQuery?.is_filter_search,
			true
		);
	}, [appearanceQuery, appearanceState]);

	const computedNeedFilterByPrice = useMemo(() => {
		return getBooleanValue(
			appearanceState?.is_filter_by_price,
			appearanceQuery?.is_filter_by_price,
			true
		);
	}, [appearanceQuery, appearanceState]);

	const computedNeedFilterSort = useMemo(() => {
		return getBooleanValue(
			appearanceState?.is_filter_sort,
			appearanceQuery?.is_filter_sort,
			true
		);
	}, [appearanceQuery, appearanceState]);

	const computedCategoriesCountView = useMemo(() => {
		return getBooleanValue(
			appearanceState?.is_categories_count_view,
			appearanceQuery?.is_categories_count_view,
			true
		);
	}, [appearanceQuery, appearanceState]);

	return {
		computedMuiTheme,
		setAppearanceState,
		appearanceState,
		computedTheme,
		appearanceQuery,
		computedNeedFilterSearch,
		computedNeedFilterByPrice,
		computedNeedFilterSort,
		computedCategoriesCountView,
	};
}

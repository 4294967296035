import 'react-range-slider-input/dist/style.css';
import {useEffect, useState} from "react";
import './RangeFilter.scss'
import {Box, Grid, Slider, Typography} from "@mui/material";


interface RangeFilterProps {
    label?: string
    minAllowed: number
    maxAllowed: number
    min: number | null
    max: number | null
    setMin: (min: number | null) => any
    setMax: (min: number | null) => any
    disabled?: boolean
    step?: number
}


export default function RangeFilter(
    {
        label,
        step,
        minAllowed,
        maxAllowed,
        min, max,
        setMin, setMax,
        disabled = false,
    }: RangeFilterProps
) {
    const [values, setValues] = useState([0, 100])

    useEffect(() => {
        setValues(prevState => [min || minAllowed, prevState[1]])
    }, [min, minAllowed]);

    useEffect(() => {
        setValues(prevState => [prevState[0], max || maxAllowed])
    }, [max, maxAllowed]);


    return (<>
        <Box sx={{ width: '100%'}}>
            <Typography id="input-slider" gutterBottom>
                {label}
            </Typography>
            <Grid container columnSpacing={3} alignItems={'center'}>
                <Grid item xs={3} textAlign={'center'}>
                    {values[0]}
                </Grid>
                <Grid item xs={6} display={'flex'} alignItems={'center'}>
                    <Slider
                        min={minAllowed}
                        max={maxAllowed}
                        step={step}
                        disabled={disabled || minAllowed === maxAllowed}
                        disableSwap
                        value={values}
                        onChange={(event, value) => {
                            event.preventDefault()
                            setValues(value as number[])
                        }}
                        onChangeCommitted={() => {
                            if (values[0] === minAllowed) {
                                setMin(null)
                            } else {
                                setMin(values[0])
                            }
                            if (values[1] === maxAllowed) {
                                setMax(null)
                            } else {
                                setMax(values[1])
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={3} textAlign={'center'}>
                    {values[1]}
                </Grid>
            </Grid>
        </Box>
    </>)
}

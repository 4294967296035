// various functions that can be useful in any part of the code

const weekdaySunday = [
	"sunday",
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
];
const weekdayMonday = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

const timeToInt = text => {
	//"00:00:00"
	if (!text) return 0;

	const hour = parseInt(text.substring(0, 2));
	const minute = parseInt(text.substring(3));
	return hour * 60 + minute;
};

function getDayNames(locale = "en", format = "long", firstDay = "sunday") {
	const formatter = new Intl.DateTimeFormat(locale, { weekday: format, timeZone: "UTC" });
	const days = [0, 1, 2, 3, 4, 5, 6];

	if (firstDay === "monday") {
		days.push(days.shift());
	}

	return days
		.map(day => {
			const dd = day < 9 ? `0${day + 1}` : day + 1;
			return new Date(`2017-01-${dd}T00:00:00+00:00`);
		})
		.map(date => formatter.format(date));
}

function isFirstDayMonday(locale = "en-US") {
	// not supported in firefox
	try {
		const firstDay = new Intl.Locale(locale).weekInfo.firstDay;
		return firstDay === 1;
	} catch {
		return true;
	}
}

function getMonthNames(locale = "en", format = "long") {
	const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: "UTC" });
	const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
		const mm = month < 10 ? `0${month}` : month;
		return new Date(`2017-${mm}-01T00:00:00+00:00`);
	});
	return months.map(date => formatter.format(date));
}

const getLinkOnMaps = (lat, lng, placeId = "") => {
	let q = `maps.google.com/maps?q=${lat},${lng}&zoom=15`;
	if (placeId) {
		q = `google.com/maps/place/?q=place_id:${placeId}`;
	}

	if (
		navigator.platform.indexOf("iPhone") !== -1 ||
		navigator.platform.indexOf("iPad") !== -1 ||
		navigator.platform.indexOf("iPod") !== -1
	) {
		return `maps://${q}`;
	}

	return `https://${q}`;
};

const scrollToBottom = (id = "", offset = 0) => {
	let el = document.body;
	if (id) {
		el = document.getElementById(id);
		if (!el) return;

		if (!offset) {
			el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
			return;
		}
		const offsetTop = el.getBoundingClientRect().top + window.pageYOffset;
		window.scrollTo({
			top: offsetTop - offset,
			behavior: "smooth",
		});
		return;
	}
	window.scrollTo({
		top: el.scrollHeight,
		behavior: "smooth",
	});
};

const encodeStringCharSet = (str, charset = "UTF-8") => {
	const textEncoder = new TextEncoder();
	const originalBytes = textEncoder.encode(str);
	const textDecoder = new TextDecoder(charset);

	return textDecoder.decode(originalBytes);
};

const getImageSize = async (url, maxWidth = 0) => {
	return new Promise(resolve => {
		const img = new Image();
		img.onload = function () {
			const width = img.width;
			const height = img.height;
			if (maxWidth > 0) {
				if (width <= maxWidth) resolve({ width, height });
				else {
					const ratio = maxWidth / width;
					resolve({ width: maxWidth, height: height * ratio });
				}
			}
			resolve({ width, height });
		};
		img.onerror = function () {
			resolve({ width: 50, height: 50 });
		};
		img.src = url;
	});
};

const convertLanguageCode = code => {
	let result = code;
	if (code.toString().length > 2 && code.indexOf("-") !== -1) result = code.split("-")[0];

	return result;
};

const convertToTimeString = date => {
	const hours = date.getHours();
	const minutes = date.getMinutes();

	// Ensure that the hours and minutes are formatted with leading zeros if needed
	const formattedHours = String(hours).padStart(2, "0");
	const formattedMinutes = String(minutes).padStart(2, "0");

	return `${formattedHours}:${formattedMinutes}`;
};

const isAvailableOsAndBrowserForTgRedirect = deviceInfo => {
	const os = deviceInfo.info?.os.toLowerCase();
	const browser = deviceInfo.info?.browser.toLowerCase();

	if (!os || !browser) return false;
	if (os === "unknown" || browser === "unknown") return false;
	if (os && os.includes("ios")) return false;

	return !(browser && browser.includes("firefox"));
};

function readFile(file) {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.addEventListener("load", () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
}

const isLocaleClockType24h = language => {
	// "In basic use without specifying a locale, DateTimeFormat
	// uses the default locale and default options."
	// Ref: developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_datetimeformat
	// To be sure of the browser's language (set by the user
	// and may be different than the operating system's default language)
	// set the 'languages' parameter to 'navigator.language'.
	// E.g. isBrowserLocaleClockType24h(navigator.language);
	if (!language) {
		language = "en";
	}

	try {
		// developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some#checking_whether_a_value_exists_in_an_array
		const hourCycles = new Intl.Locale(language).hourCycles;
		return ["h23", "h24"].some(hourCycle => hourCycles.includes(hourCycle));
	} catch {}

	// The value of 'hr' will be in the format '0', '1', ... up to '24'
	// for a 24-hour clock type (depending on a clock type of
	// 'h23' or 'h24'. See:
	// developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale
	// Intl.Locale.prototype.hourCycles
	// Returns an Array of hour cycle identifiers, indicating either
	// the 12-hour format ("h11", "h12") or
	// the 24-hour format ("h23", "h24").

	// A 12-hour clock type value has the format '7 AM', '10 PM', or
	// '7 π.μ.' (if the locale language is Greek as specified
	// by the two letter ISO 639-1 code "el" or
	// three letter ISO 639-2 code "ell").

	const hr = new Intl.DateTimeFormat(language, { hour: "numeric" }).format();

	// If there's no space in the value of the 'hr' variable then
	// the value is a string representing a number between and
	// can include '0' and '24'. See comment above regarding "hourCycles".
	// Return 'true' if a space exists.
	//if (!hr.match(/\s/)) { return true; }
	// Or simply:
	// return !hr.match(/\s/);

	// Alternatively, check if the value of 'hr' is an integer.
	// E.g. Number.isInteger(Number('10 AM')) returns 'false'
	// E.g. Number.isInteger(Number('7 π.μ.')) returns 'false'
	// E.g. Number.isInteger(Number('10')) returns 'true'
	return Number.isInteger(Number(hr));
};

const dateToISOFormat = date => {
	try {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		const seconds = String(date.getSeconds()).padStart(2, "0");
		const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
		const offsetMinutes = date.getTimezoneOffset();

		const offsetSign = offsetMinutes > 0 ? "-" : "+";
		const offsetHours = String(Math.abs(offsetMinutes / 60)).padStart(2, "0");
		const offsetMinutesPart = String(Math.abs(offsetMinutes % 60)).padStart(2, "0");

		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutesPart}`;
	} catch (ex) {
		console.log("dateToISOFormat error", ex);
		return null;
	}
};

const isIOS = () => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	return /iphone|ipad|ipod/.test(userAgent);
};

const isSafariAndMac = () => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
	const isMac = /mac/.test(userAgent);

	return isSafari && isMac;
};

function getDeviceInfo() {
	const userAgent = navigator.userAgent;

	let os = "Unknown OS";
	let osVersion = "";
	let browser = "Unknown Browser";

	const windowsVersion = userAgent.match(/Windows NT (\d+\.\d+)/);
	const macVersion = userAgent.match(/Mac OS X (\d+[._\d]+)/);
	const androidVersion = userAgent.match(/Android (\d+[._\d]+)/);
	const iosVersion = userAgent.match(/iPhone OS (\d+[._\d]+)/);

	const chrome = userAgent.match(/Chrome\/(\d+\.\d+)/);
	const firefox = userAgent.match(/Firefox\/(\d+\.\d+)/);
	const safari = userAgent.match(/Safari\/(\d+\.\d+)/);
	const edge = userAgent.match(/Edg\/(\d+\.\d+)/);

	if (windowsVersion) {
		os = "Windows";
		osVersion = windowsVersion[1];
	} else if (macVersion) {
		os = "Mac OS";
		osVersion = macVersion[1].replace(/_/g, ".");
	} else if (androidVersion) {
		os = "Android";
		osVersion = androidVersion[1];
	} else if (iosVersion) {
		os = "iOS";
		osVersion = iosVersion[1].replace(/_/g, ".");
	}

	if (chrome) {
		browser = `Chrome ${chrome[1]}`;
	} else if (firefox) {
		browser = `Firefox ${firefox[1]}`;
	} else if (safari) {
		browser = `Safari ${safari[1]}`;
	} else if (edge) {
		browser = `Edge ${edge[1]}`;
	}

	return [os, osVersion, browser]
		.map(x => (x ? x.trim() : x))
		.filter(x => !!x)
		.join(" ");
}

export {
	weekdaySunday,
	weekdayMonday,
	timeToInt,
	getLinkOnMaps,
	getDayNames,
	getMonthNames,
	scrollToBottom,
	encodeStringCharSet,
	getImageSize,
	convertLanguageCode,
	convertToTimeString,
	isAvailableOsAndBrowserForTgRedirect,
	isFirstDayMonday,
	readFile,
	isLocaleClockType24h,
	dateToISOFormat,
	isIOS,
	isSafariAndMac,
	getDeviceInfo,
};

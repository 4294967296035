import { useEffect, useState } from "react";
import useSearchParamsFixed from "../hooks/useSearchParamsFixed";
import { AuthorisedResponse, NewOAuthToken, OAuthToken } from "../../api/auth/types";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleOAuthClientId } from "../../config";
import { Box, CssBaseline, useColorScheme } from "@mui/material";
import { Experimental_CssVarsProvider as MaterialCssVarsProvider } from "@mui/material/styles/CssVarsProvider";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles/CssVarsProvider";
import { useColorScheme as useJoyColorScheme } from "@mui/joy/styles";
import useOAuthWidgetProvider from "./useOAuthWidgetProvider";
import AppleLoginButton from "../../auth/Autorisation/external/oauth/apple";

interface OAuthWidgetProps {
	provider: OAuthType;
	native?: boolean;
}

export default function OAuthWidget(props: OAuthWidgetProps) {
	return (
		<MaterialCssVarsProvider defaultMode={"light"} disableStyleSheetGeneration={true}>
			<JoyCssVarsProvider defaultMode={"light"}>
				<CssBaseline enableColorScheme />
				{props.provider === "google" ? (
					<GoogleOAuthProvider clientId={GoogleOAuthClientId}>
						<OAuthWidgetContent {...props} />
					</GoogleOAuthProvider>
				) : (
					<OAuthWidgetContent {...props} />
				)}
			</JoyCssVarsProvider>
		</MaterialCssVarsProvider>
	);
}

function OAuthWidgetContent(props: OAuthWidgetProps) {
	const [searchParams] = useSearchParamsFixed();
	const colorSchema = useColorScheme();
	const { setMode: setJoyMode } = useJoyColorScheme();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [unknownError, setUnknownError] = useState<string | null>(null);
	const [openFormError, setOpenFormError] = useState<string | null>(null);

	const [innerHeight, setInnerHeight] = useState<string>("37px");
	const [innerWidth, setInnerWidth] = useState<string>("auto");

	const [isNewLogin, setIsNewLogin] = useState<boolean>(false);
	const [authSource, setAuthSource] = useState<string | null>(null);

	const onSuccess = (token: OAuthToken | NewOAuthToken) => {
		let responseMessage: OAuthWidgetMessage = {
			fromOAuth: true,
			isNewUser: token.is_new_user,
			firstName: token.first_name,
			lastName: token.last_name,
			email: token.email,
			is_accept_profile_agreement: token.is_accept_profile_agreement,
		};
		if ("new_login_data" in token) {
			responseMessage.new_login_data = token.new_login_data;
		} else {
			responseMessage.token = token.token;
			responseMessage.token_type = token.token_type;
		}
		window.parent.postMessage(responseMessage, "*");
	};

	const onError = (message: string) => {
		const responseMessage: OAuthWidgetMessage = {
			fromOAuth: true,
			error: message,
		};
		window.parent.postMessage(responseMessage, "*");
	};

	const useProvider = useOAuthWidgetProvider(props.provider);
	const { login, loaded } = useProvider({
		onSuccess,
		onError,
		setIsLoading,
		unknownError,
		openFormError,
		native: props.native,
		isNewLogin: isNewLogin,
		authSource: authSource,
	});

	const setColorScheme = colorSchema.setColorScheme;
	useEffect(() => {
		if (searchParams.get("unknownError")) {
			setUnknownError(searchParams.get("unknownError"));
		}
		if (searchParams.get("openFormError")) {
			setOpenFormError(searchParams.get("openFormError"));
		}
		if (searchParams.get("innerHeight")) {
			setInnerHeight(searchParams.get("innerHeight") || "37px");
		}
		if (searchParams.get("innerWidth")) {
			setInnerWidth(searchParams.get("innerWidth") || "auto");
		}
		if (searchParams.get("theme")) {
			const themeValue = searchParams.get("theme") === "dark" ? "dark" : "light";
			setColorScheme(themeValue);
			setJoyMode(themeValue);
		}
		if (searchParams.get("new_login")) {
			setIsNewLogin(true);
		}
		if (searchParams.get("auth_source")) {
			setAuthSource(searchParams.get("auth_source"));
		}
	}, [searchParams, setColorScheme, setJoyMode]);

	useEffect(() => {
		if (isLoading) {
			const responseMessage: OAuthWidgetMessage = {
				fromOAuth: true,
				isLoading: true,
				noData: true,
			};
			window.parent.postMessage(responseMessage, "*");
		} else {
			const responseMessage: OAuthWidgetMessage = {
				fromOAuth: true,
				isLoading: false,
				noData: true,
			};
			window.parent.postMessage(responseMessage, "*");
		}
	}, [isLoading]);

	useEffect(() => {
		if (loaded) {
			const responseMessage: OAuthWidgetMessage = {
				fromOAuth: true,
				scriptsLoading: false,
				noData: true,
			};
			window.parent.postMessage(responseMessage, "*");
		}
	}, [loaded]);

	useEffect(() => {
		if (window.location.pathname.includes("oauth")) {
			document.body.style.backgroundColor = "transparent";
		}
	}, []);

	return (
		<>
			{props.provider === "apple" && props.native ? (
				<AppleLoginButton onClick={login} />
			) : (
				<Box
					sx={{
						height: innerHeight,
						width: innerWidth,
						cursor: "pointer",
					}}
					onClick={login}
				></Box>
			)}
		</>
	);
}

export interface OAuthWidgetMessage {
	fromOAuth: boolean;
	isLoading?: boolean;
	token_type?: string;
	token?: string;
	error?: string;
	noData?: boolean;
	isNewUser?: boolean | null;
	firstName?: string | null;
	lastName?: string | null;
	email?: string | null;
	scriptsLoading?: boolean;
	new_login_data?: AuthorisedResponse | null;
	is_accept_profile_agreement?: boolean | null;
}

export type OAuthType = "google" | "apple";

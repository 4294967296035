import {Alert, AlertTitle, IconButton, Snackbar} from "@mui/material";
import useAppContext from "../useAppContext";
import useLocalisation from "../hooks/localisation/useLocalisation";
import {useEffect, useState} from "react";
import {Toast as ToastType} from "../services/useToastService/types";
import CloseIcon from "@mui/icons-material/Close";
import Interweave from "../features/Interweave";

export default function Toast(){
    const {toastService} = useAppContext()
    const [toast, setToast] = useState<ToastType | null>(null)

    useEffect(() => {
        setToast(toastService.toast)
    }, [toastService.toast]);

    const localisation = useLocalisation()

    return (
        <Snackbar
            open={!!toastService.toast}
            onClose={() => toastService.hide()}
            autoHideDuration={toast?.autoHideDuration || 5000}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Alert
                severity={toast?.severity}
                variant={'filled'}
                action={(
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => toastService.hide()}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            >
                <AlertTitle>
                    {toast?.severity === "error"
                        ? localisation.global.errApi
                        : localisation.global.notify
                    }
                </AlertTitle>
                <Interweave content={toast?.message}/>
            </Alert>
        </Snackbar>
    )
}

import TextField, {TextFieldProps} from "@mui/material/TextField";
import {InputAdornment, InputAdornmentProps} from "@mui/material";
import {LoadingButton, LoadingButtonProps} from "@mui/lab";
import React, {ReactNode} from "react";


export interface InputWithEndButtonProps {
    textFieldProps?: TextFieldProps
    inputEndAdornmentProps?: Omit<InputAdornmentProps, 'position'>

    isLoading?: boolean
    onEndButtonClick?: React.MouseEventHandler<HTMLButtonElement>
    endButtonProps?: Omit<LoadingButtonProps, 'loading' | 'variant' | 'onClick'>
    endButtonNode?: ReactNode
    error?: boolean | undefined
    helperText?: string | null
}

export default function InputWithEndButton(props: InputWithEndButtonProps) {
    return (
         <TextField
             error={props.error}
             helperText={props.helperText}
             size={'small'}
             {...props.textFieldProps}
             InputProps={{
                 ...props.textFieldProps?.InputProps,
                 sx: {pr: 0, ...props.textFieldProps?.InputProps?.sx},
                 endAdornment: (
                     <InputAdornment
                         {...props.inputEndAdornmentProps}
                         position={"end"}
                         sx={{
                             alignSelf: 'stretch',
                             height: 'auto',
                             maxHeight: 'unset',
                             ...props.inputEndAdornmentProps?.sx,
                         }}
                     >
                         {props.textFieldProps?.InputProps?.endAdornment}

                         <LoadingButton
                             disableElevation
                             {...props.endButtonProps}
                             loading={props.isLoading}
                             variant={'contained'}
                             sx={{
                                 minWidth: 'auto',
                                 height: '100%',
                                 px: 2,
                                 display: 'flex',
                                 alignItems: 'center',
                                 borderRadius: 0,
                                 borderLeft: 1,
                                 borderColor: 'divider',
                                 borderTopRightRadius: '4px',
                                 borderBottomRightRadius: '4px',
                                 ...props.endButtonProps?.sx,
                             }}
                             onClick={props.onEndButtonClick}
                         >
                             {props.endButtonNode}
                         </LoadingButton>
                     </InputAdornment>
                 ),
             }}
         />
    )
}

import { useState, useCallback, useRef, useEffect } from "react";
import { Backdrop, Box, Button, CircularProgress, Skeleton, useTheme } from "@mui/material";

import { ProfileTab } from "../types";
import ProfileLayout from "../ProfileLayout";
import api from "../../../api";
import useAppContext from "../../../useAppContext";
import { ICardImage, IIncustUserCardQr } from "../../../api/shop/loyalty/types";
import useScreenService from "../../../services/useScreenService";
import { TgButton } from "../../../helpers/ThemeComponents";
import { getPkpass, getCardId } from "../../../features/services/useIncustService/functions";
import { IIncustCard } from "../../../features/services/useIncustService/types";
import { ApiUrl } from "../../../config";
import { useQuery } from "@tanstack/react-query";
import Interweave from "../../../features/Interweave";
import { useSelectedStoreContextOptional } from "../../SelectedStore/context";
import useUserIncustCustomerData from "../../../hooks/incust/useUserIncustCustmerData";

const defaultTimerValue = 600;

export default function ProfileMyCard() {
	const {
		brandInfo,
		showError,
		localisation: { profile },
		loadingManager: { isSomethingLoading },
		lang,
	} = useAppContext();
	const { isMobile } = useScreenService();
	const theme = useTheme();

	const store = useSelectedStoreContextOptional();
	const { customerData } = useUserIncustCustomerData();

	const [userQrCard, setUserQrCard] = useState<IIncustUserCardQr | null>(null);
	const [card, setCard] = useState<IIncustCard | null>(null);
	const [internalLoading, setInternalLoading] = useState<boolean>(false);
	const [timerExpired, setTimerExpired] = useState<boolean>(false);
	const [timer, setTimer] = useState<number>(defaultTimerValue);

	const tab = ProfileTab.myCard;

	const qrRef = useRef<HTMLDivElement>(null);

	const getCardQrAndTemporaryCode = useCallback(
		async (cardId: IIncustCard) => {
			if (brandInfo && cardId) {
				const response = await api.shop.loyalty.getIncustUserCardQr(
					brandInfo.id,
					store && store.selectedStore ? store.selectedStore.id : null
				);
				if (response.status === 200) setUserQrCard(response.data);
			}
		},
		[brandInfo, store]
	);

	const getUserCardId = useCallback(async (): Promise<IIncustCard | null | undefined> => {
		if (!lang || !customerData || !brandInfo?.incust_data) return null;

		const cardId = await getCardId(
			brandInfo?.incust_data?.server_api || "",
			customerData?.token || "",
			brandInfo?.incust_data?.white_label_id || "",
			lang || "",
			brandInfo?.incust_data?.loyalty_id || "",
			customerData?.user_card?.customer?.id || "",
			customerData?.external_id || ""
		);

		if (cardId) {
			await getCardQrAndTemporaryCode(cardId);
			setCard(cardId);
			return cardId;
		}

		return null;
	}, [brandInfo?.incust_data, getCardQrAndTemporaryCode, lang, customerData]);

	const onLoad = useCallback(async () => {
		setInternalLoading(true);
		try {
			await getUserCardId();
		} catch (ex) {
			showError(ex);
		} finally {
			setInternalLoading(false);
		}
		return true;
	}, [getUserCardId, showError]);

	const query = useQuery<any>({
		queryKey: ["incust-my-card", onLoad],
		queryFn: onLoad,
		enabled: !!brandInfo?.incust_data && !!customerData,
		initialData: null,
	});

	useEffect(() => {
		const timerId = setTimeout(() => {
			setTimerExpired(true);
		}, timer * 1000);

		return () => clearTimeout(timerId);
	}, [timer]);

	return (
		<ProfileLayout tab={tab}>
			<Box
				display={"flex"}
				justifyContent={"center"}
				sx={{ flexDirection: "column" }}
				alignItems={"center"}
				position={"relative"}
				textAlign={"center"}
			>
				{internalLoading && !isSomethingLoading && (
					<>
						<Skeleton
							variant={"rectangular"}
							sx={{
								width: isMobile ? "50%" : "25%",
								height: isMobile ? 168 : 217,
							}}
						/>
						<Skeleton variant={"text"} width={isMobile ? "100%" : "50%"} />
						<Skeleton variant={"text"} width={isMobile ? "100%" : "50%"} />
						<Skeleton variant={"text"} width={isMobile ? "100%" : "50%"} />
						<Skeleton variant={"text"} width={isMobile ? "100%" : "50%"} />

						<Skeleton
							variant={"rectangular"}
							sx={{
								mt: 2,
								width: isMobile ? "100%" : "50%",
								height: 42,
							}}
						/>
						<Skeleton
							variant={"rectangular"}
							sx={{
								mt: 1,
								width: isMobile ? "100%" : "50%",
								height: 42,
							}}
						/>
					</>
				)}

				{!!userQrCard && !internalLoading && (
					<>
						<Box ref={qrRef} sx={{ width: isMobile ? "50%" : "25%", mb: 1 }}>
							<img
								src={"data:image/png;base64," + userQrCard.qr}
								alt={userQrCard.temp_code}
								className={"img-fluid"}
							/>
						</Box>

						{timerExpired && (
							<Backdrop
								sx={{
									color: "white",
									zIndex: theme => theme.zIndex.drawer - 1,
									backdropFilter: "blur(5px)",
									position: "absolute",
									width: qrRef.current?.clientWidth || 217,
									height: qrRef.current?.clientHeight || 217,
									left: `calc(50% - ${qrRef.current?.clientWidth || 217}px / 2)`,
									backgroundColor:
										theme.palette.mode === "light"
											? "rgb(255 255 255 / 70%)"
											: "rgb(16 16 16 / 70%)",
								}}
								open={true}
							>
								<Button
									variant={"contained"}
									onClick={() => {
										query.refetch().then();
										setTimerExpired(false);
										if (timer > defaultTimerValue) {
											setTimer(defaultTimerValue - 1);
										} else {
											setTimer(defaultTimerValue + 1);
										}
									}}
								>
									{profile.showCardTimeout}
								</Button>
							</Backdrop>
						)}

						<div className={`border-bottom mb-3 ${isMobile ? "w-100" : "w-50"}`}>
							<Interweave
								content={profile.tempCodeText.replace(
									"{temp_code}",
									`<strong>${timerExpired ? "******" : userQrCard.temp_code}</strong>`
								)}
							/>
						</div>

						{!!card && (
							<>
								<div className={`my-2 small ${isMobile ? "w-100" : "w-50"}`}>
									{profile.digitalCardDesc}
								</div>
								<PkpassButton card={card} disabled={timerExpired} />
								<ImageButton
									card={card}
									qrCard={userQrCard}
									disabled={timerExpired}
								/>
							</>
						)}
					</>
				)}
			</Box>
		</ProfileLayout>
	);
}

interface IMyCardButtonProps {
	card: IIncustCard | null;
	qrCard?: IIncustUserCardQr | null;
	disabled: boolean;
}

function PkpassButton(props: IMyCardButtonProps) {
	const { isMobile } = useScreenService();
	const {
		localisation: { profile },
		brandInfo,
		lang,
	} = useAppContext();

	const { customerData } = useUserIncustCustomerData();

	const [pkpassLoading, setPkpassLoading] = useState<boolean>(false);

	const downloadPkpass = useCallback(async () => {
		try {
			setPkpassLoading(true);
			const pkpass = await getPkpass(
				brandInfo?.incust_data?.server_api || "",
				brandInfo?.incust_data?.loyalty_id || "",
				props.card?.id || "",
				customerData?.token || "",
				brandInfo?.incust_data?.white_label_id || "",
				lang || ""
			);
			if (pkpass) {
				// Create a blob from the response data
				const blob = new Blob([pkpass], {
					type: "application/vnd.apple.pkpass;charset=utf-8;",
				});

				// Create a hidden link
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.target = "_blank";
				link.download = `${
					props.card?.title ||
					customerData?.external_id ||
					customerData?.user_card?.customer?.id
				}.pkpass`; // Specify the desired file name

				// Add the link to the DOM (but keep it hidden)
				document.body.appendChild(link);

				// Trigger a click event on the link
				link.click();

				// Remove the link from the DOM
				document.body.removeChild(link);
			}
		} finally {
			setPkpassLoading(false);
		}
	}, [
		brandInfo?.incust_data?.loyalty_id,
		brandInfo?.incust_data?.server_api,
		brandInfo?.incust_data?.white_label_id,
		customerData?.external_id,
		customerData?.token,
		customerData?.user_card?.customer?.id,
		lang,
		props.card?.id,
		props.card?.title,
	]);

	return (
		<TgButton
			onClick={downloadPkpass}
			disabled={pkpassLoading || props.disabled}
			className={`mb-2 ${isMobile ? "w-100" : "w-50"}`}
		>
			{pkpassLoading && (
				<CircularProgress style={{ width: "14px", height: "14px" }} className={"me-2"} />
			)}
			{profile.downloadCard}
		</TgButton>
	);
}

function ImageButton(props: IMyCardButtonProps) {
	const { isMobile } = useScreenService();
	const {
		brandInfo,
		localisation: { profile },
		showError,
	} = useAppContext();

	const { customerData } = useUserIncustCustomerData();

	const [imageLoading, setImageLoading] = useState<boolean>(false);

	const downloadCardImage = useCallback(async () => {
		setImageLoading(true);
		try {
			const args: ICardImage = {
				qr: props.qrCard?.qr || "",
				photo: props.card?.photo,
				title: props.card?.title || "",
				username: customerData?.user_card?.customer?.user_name || "",
				card_code: props.card?.id || "",
			};
			const responseLink = await api.shop.loyalty.getUserCardImageLink(
				brandInfo?.group_id || 0,
				args,
				true
			);
			const a = document.createElement("a");
			a.href = ApiUrl + responseLink.toString();
			a.target = "_blank";
			a.addEventListener("click", () => {
				setTimeout(() => {
					a.remove();
				}, 1000);
			});
			a.click();
		} catch (ex: any) {
			if (ex?.name === "AbortError") return;
			showError(ex?.message || ex);
		} finally {
			setImageLoading(false);
		}
	}, [
		brandInfo?.group_id,
		customerData?.user_card?.customer?.user_name,
		props.card?.id,
		props.card?.photo,
		props.card?.title,
		props.qrCard?.qr,
		showError,
	]);

	return (
		<>
			<TgButton
				onClick={downloadCardImage}
				className={isMobile ? "w-100" : "w-50"}
				disabled={imageLoading || props.disabled}
				variant={"text"}
			>
				{imageLoading && (
					<CircularProgress
						style={{ width: "14px", height: "14px" }}
						className={"me-2"}
					/>
				)}
				{profile.downloadImgCard}
			</TgButton>
		</>
	);
}

import {useState, useEffect, ChangeEvent} from 'react'
import {
    MDBRow,
    MDBCol,
    MDBIcon,
} from 'mdb-react-ui-kit'

import {ThemeModal} from '../../helpers/ThemeComponents'
import api from '../../api'
import SelectStoreCard from './SelectStoreCard'
import useLocalisation from "../../hooks/localisation/useLocalisation"
import {useShopContext} from "../context";
import {Store} from "../../api/shop/basic/types";
import useAppContext from "../../useAppContext";


interface GeoModalProps {
    headerText: string
    handleStoreChanged: (store: Store) => any
}

export default function GeoModal({
    headerText,
    handleStoreChanged,
}: GeoModalProps){
    const {
        showGeoModal,
        setShowGeoModal,
        brandInfo,
        addressValue,
        setAddressValue,
    } = useShopContext()
    const {lang} = useAppContext()

    const initialNominatimDuplicates = {
        query: "",
        count: 0,
    }

    const [addressErr, setAddressErr] = useState("")

    const [foundStores, setFoundStores] = useState<Store[]>([])

    const [notFound, setNotFound] = useState(false)

    const localisationData = useLocalisation()

    const [availableForDeliveryStores, setAvailableForDeliveryStores] =
        useState<Store[]>([])

    //TODO: needed for new AddressInput component callback, temporary
    // const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => setAddressValue(e.target.value)

    // const onAutoCompleteSelect = async(obj: GeoAddress) => {
    //     if (!lang) return

    //     setAddressValue(obj.name)
    //     const response = await api.shop.basic.getStores(
    //         {
    //             brand_id: brandInfo.id,
    //             latitude: obj.lat,
    //             longitude: obj.lng,
    //         }
    //     )
    //     if (response.data.length === 0) {
    //         setNotFound(true)
    //     } else {
    //         setNotFound(false)
    //         setFoundStores(response.data)
    //     }
    // }

    const loadAvailableForDeliveryStores = async() => {
        if (!lang) return

        const response = await api.shop.basic.getStores(
            {
                brand_id: brandInfo.id,
                is_delivery: true,
            }
        )
        if (response.data.length > 0)
            setAvailableForDeliveryStores(response.data)
    }

    const handleModalClose = () => {
        setShowGeoModal(false)
    }

    useEffect(() => {
        if(showGeoModal){
            setNotFound(false)
            setFoundStores([])
            setAddressValue("")
            setAddressErr("")
        }
    }, [showGeoModal])

    useEffect(() => {
        loadAvailableForDeliveryStores().then()
    }, [lang]);

    return (
        <ThemeModal
            open={showGeoModal}
            setOpen={setShowGeoModal}
            title={headerText}
            onClose={() => handleModalClose()}
            maxWidth={'sm'}
        >
            {showGeoModal && (<>
                {addressValue.length > 0 && (<>
                    <span
                        tabIndex={0}
                        className={'select-clear-btn d-block mt-3'}
                        onClick={() => {setAddressValue(""); setFoundStores([]);}}
                    >
                        <MDBIcon fas icon="times" />
                    </span>
                </>)}
            </>)}

            <div className='small text-danger' id='address-field'>
                {addressErr || null}
            </div>

            <MDBRow className={'text-start'}>
                {notFound
                    ?
                        <div className={'mt-2 theme-text'}>
                            {localisationData.select.notFoundStoresByAddress}
                        </div>
                    : null
                }
                {!notFound && foundStores.length === 0
                    ?
                        <div className={'mt-2 theme-text'}>
                            {localisationData.select.storesSearchHeader}
                        </div>
                    : null
                }

                {foundStores.length > 0 && (<>
                    <div className={'mt-2 theme-text'}>
                        {localisationData.select.nearestStoresHeader}
                    </div>
                    {foundStores.map(store => (
                            <MDBCol size="12" key={store.id} className={'my-2'}>
                                <SelectStoreCard
                                    store={store}
                                    handleStoreChanged={handleStoreChanged}
                                    isSmallWidthCard={true}
                                />
                            </MDBCol>
                        ))
                    }
                </>)}

                {(foundStores.length === 0 && !addressValue) &&
                    <>
                        {availableForDeliveryStores.map(store => (
                                <MDBCol size="12" key={store.id} className={'my-2'}>
                                    <SelectStoreCard
                                        store={store}
                                        handleStoreChanged={handleStoreChanged}
                                        isSmallWidthCard={true}
                                    />
                                </MDBCol>
                            ))
                        }
                    </>
                }
            </MDBRow>
        </ThemeModal>
    )
}

import {useEffect, useState} from "react";
import api from "../../api";
import {SetState} from "../../types";
import {Store} from "../../api/shop/basic/types";
import useAppContext from "../../useAppContext";


export default function useOrdersCountService(store: Store | null): OrdersCountServiceType {
    const {
        authService: {user}
    } = useAppContext()

    const [ordersCount, setOrdersCount] = useState(0)

    useEffect(() => {
        const load = async () => {
            if(user?.id && store?.id){
                const response = await api.shop.order.getOrders({
                    count: true,
                    store_id: store.id,
                })
                setOrdersCount(response.data.count)
            }
        }

        load().then()
    }, [store?.id, user?.id])

    return {ordersCount, setOrdersCount}
}


export type OrdersCountServiceType = {
    ordersCount: number
    setOrdersCount: SetState<number>
}

import {
    MDBCardBody,
    MDBCard,
    MDBIcon,
    MDBTypography,
} from "mdb-react-ui-kit";

import {SetState} from "../../types";
import {Shipment} from "../../api/shop/basic/types";
import BaseShippingIcons from "../shipping";


interface DeliveryTypeCardProps {
    shipment: Shipment
    activeShipment: Shipment | null
    setActiveShipment: SetState<Shipment | null>
}

export default function DeliveryTypeCard({
    shipment,
    activeShipment,
    setActiveShipment,
}: DeliveryTypeCardProps){
    const handleDeliveryTypeClick = () => {
        if (shipment.id === activeShipment?.id) {
            setActiveShipment(null)
        } else {
            setActiveShipment(shipment)
        }
    }

    if(shipment.base_type === "no_delivery") return <></>

    return (
        <MDBCard
            className={'square border cursor-pointer hover-shadow h-100 tg-card'}
            onClick={() => handleDeliveryTypeClick()}
        >
            <MDBCardBody>
                <MDBTypography className="m-0">
                    <strong>
                        <span className={'me-2'}>{BaseShippingIcons[shipment.base_type]}</span>
                        {shipment.name}
                    </strong>
                    {shipment.id === activeShipment?.id && (
                        <MDBIcon fas icon={"check-circle ms-2"} size={"lg"} color={'success'} />
                    )}
                </MDBTypography>
            </MDBCardBody>
        </MDBCard>
    )
}

export interface CartLocalisationUi {
	cart: string;
	cartAttrEdit: string;
	cartNotFound: string;
	cartEmpty: string;
	cartMakeOrder: string;
	cartCallManager: string;
	cartAddMore: string;
	cartRequiredAttrMsg: string;
	cartConfirmAttrDelMsg: string;
	miniCartHeader: string;
	miniCartEmpty: string;
	cartCallManagerSuccess: string;
	notAvailable: string;
	headerCartButton: string;
	bottomCartButton: string;
	deleteFromCartButton: string;
	needAuthProductsInCartAlert: string;
	needAuthCartAlert: string;
}

export const CartLocale: CartLocalisationUi = {
	cart: "web store cart text",
	cartAttrEdit: "web store cart attributes edit text",
	cartNotFound: "web store cart not found text",
	cartEmpty: "web store cart empty text",
	cartMakeOrder: "web store cart make order button text",
	cartCallManager: "web store cart call manager button text",
	cartAddMore: "web store cart add more button text",
	cartRequiredAttrMsg: "web store cart required attr message text",
	cartConfirmAttrDelMsg: "web store cart confirm attr del message text",
	miniCartHeader: "web store mini cart header text",
	miniCartEmpty: "web store mini cart empty text",
	cartCallManagerSuccess: "web store cart call manager success text",
	notAvailable: "web store cart not available text",
	headerCartButton: "web store header cart button",
	bottomCartButton: "web store bottom cart button",
	deleteFromCartButton: "web store cart delete from cart button",
	needAuthProductsInCartAlert: "web app need auth products in cart alert",
	needAuthCartAlert: "web app need auth cart alert",
};

import { ChangeEventHandler, useState, ChangeEvent, useMemo, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteRenderGroupParams } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
	Box,
	IconButton,
	Link,
	Paper,
	Popper,
	PopperProps,
	Tooltip,
	Typography,
	useColorScheme,
} from "@mui/material";

import useAppContext from "../../useAppContext";
import { IAddressOptionItem } from "./types";
import { TgLink, ThemeModal } from "../../helpers/ThemeComponents";
import SelectAddressMap from "../Maps/SelectAddressMap";
import { IAddressComponent, IGeocodingResultItem } from "../../api/map/types";
import GoogleLight from "../../static/stubs/google_on_white_hdpi.png";
import GoogleDark from "../../static/stubs/google_on_non_white.png";
import useOptions from "./hooks/useOptions";
import useAddressValue from "./hooks/useAddressValue";
import { IPricesError, IZonePolygon, IZoneRadius } from "../../shop/services/useShipmentPrices";
import useScreenService from "../../services/useScreenService";
import FindLocation, { IUseFindLocationType, useFindLocation } from "./FindLocation";
import Interweave from "../Interweave";

export interface Coordinates {
	lat: number;
	lon: number;
}

export type AddressValidPurpose = "street" | "city" | "country" | "street_number" | "default";
export interface IAddressValid {
	valid: boolean;
	purpose: AddressValidPurpose;
	text: string;
}

interface IAddressInputProps {
	value: string | null | undefined;
	onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	label?: string;
	size?: "small" | "medium" | undefined;
	fullWidth?: boolean;
	id?: string;
	isError?: boolean;
	helperText?: string;
	lang: string;
	setSelectedOnLoad?: boolean;
	selectCallback?: (value: IAddressOptionItem | null | undefined) => void;
	setCoords?: (value: [number, number] | null) => void;
	currentCoords?: Coordinates | null;
	priorityCoords?: Coordinates | null;
	needDefaultAddress?: boolean;
	businessLanguage?: string;

	polygons?: IZonePolygon[];
	radius?: IZoneRadius[];
	showMap: boolean;
	setShowMap: (value: boolean) => void;

	isGoogleEnabled: boolean;

	validAddress?: IAddressValid | null;
	setValidAddress?: (value: IAddressValid | null) => void;
	pricesError?: IPricesError | null;

	countries: string[] | null;
	enabledAnyAddressFromMap: boolean;
}

export default function AddressInput(props: IAddressInputProps) {
	const {
		localisation: { select, global, orders },
	} = useAppContext();

	const [open, setOpen] = useState<boolean>(false);
	const [internalError, setInternalError] = useState<string | null>(null);
	const textFieldRef = useRef<HTMLInputElement>(null);
	const [optionWasSelected, setOptionWasSelected] = useState<boolean>(false);

	const locationService = useFindLocation();

	const {
		token,
		selectedValue,
		setSelectedValue,
		internalAddress,
		handleValueChanged,
		handleAddressChanged,
		validateAndSave,
		lastAutoCompleteGeometry,
	} = useAddressValue(
		props.id,
		props.isGoogleEnabled,
		props.value,
		props.setShowMap,
		props.businessLanguage,
		props.onChange,
		setInternalError,
		props.setValidAddress,
		props.selectCallback,
		props.setCoords,
		props.enabledAnyAddressFromMap
	);

	const { updateOptions, computedOptions, isLoading } = useOptions(
		props.isGoogleEnabled,
		token,
		internalAddress,
		props.businessLanguage,
		props.lang,
		props.priorityCoords,
		setInternalError,
		props.countries
	);

	const computedNoResultsMessage = useMemo(() => {
		if (!props.value || props.value.length < 3) {
			return select.typeAddress;
		}
		if (props.value && props.value.length >= 3) {
			return select.notFoundAddress;
		}
		return select.notFoundByAddress;
	}, [props.value, select.notFoundAddress, select.notFoundByAddress, select.typeAddress]);

	const getGroupName = (option: IAddressOptionItem): string => {
		if (option.id === "custom") {
			return `${global.addressEntered}:`;
		} else if (!option.custom) {
			return `${global.searchResults}:`;
		} else {
			return "";
		}
	};

	const confirmAddressOnMap = (addressItem: IGeocodingResultItem) => {
		setInternalError(null);
		props.onChange({
			target: {
				name: props.id ? props.id : "address_input",
				value: addressItem.formatted_address || "",
			},
		} as ChangeEvent<HTMLInputElement>);
		handleAddressChanged({
			target: {
				name: props.id ? props.id : "address_input",
				value: addressItem.formatted_address || "",
			},
		} as ChangeEvent<HTMLInputElement>);

		props.setCoords &&
			props.setCoords([addressItem.geometry.location.lng, addressItem.geometry.location.lat]);
	};

	const computedError = useMemo(() => {
		if (internalError) {
			return internalError;
		}
		if (props.isError) {
			return props.helperText || "unknown error"; //TODO: add localization
		}
		if (!props.validAddress?.valid && props.validAddress?.purpose) {
			return props.validAddress.text;
		}
		if (props.pricesError) {
			if (props.pricesError.text && props.pricesError.internalCode === "address_error") {
				if (!props.value) return orders.addressFeedback;
				return props.pricesError.text;
			}
		}
		return "";
	}, [
		internalError,
		orders.addressFeedback,
		props.helperText,
		props.isError,
		props.pricesError,
		props.validAddress?.purpose,
		props.validAddress?.text,
		props.validAddress?.valid,
		props.value,
	]);

	const computedNeedZonesMap = useMemo(() => {
		return !!(
			((props.polygons && props.polygons.length > 0) ||
				(props.radius && props.radius.length > 0)) &&
			props.isGoogleEnabled
		);
	}, [props.isGoogleEnabled, props.polygons, props.radius]);

	const computedCenterLatLng = useMemo(() => {
		if (locationService.userLocationCoords) {
			return {
				lat: locationService.userLocationCoords.lat,
				lon: locationService.userLocationCoords.lon,
			};
		} else if (lastAutoCompleteGeometry) {
			return {
				lat: lastAutoCompleteGeometry.location.lat,
				lon: lastAutoCompleteGeometry.location.lng,
			};
		} else if (props.currentCoords) {
			return props.currentCoords;
		} else if (props.priorityCoords) {
			return props.priorityCoords;
		} else {
			return null;
		}
	}, [
		lastAutoCompleteGeometry,
		locationService.userLocationCoords,
		props.currentCoords,
		props.priorityCoords,
	]);

	useEffect(() => {
		if (props.setSelectedOnLoad && props.value && !selectedValue) {
			setSelectedValue({
				title: props.value,
				fullTitle: props.value,
				custom: true,
				id: "custom",
			});
		}
	}, [props.setSelectedOnLoad, props.value, selectedValue, setSelectedValue]);

	useEffect(() => {
		if (textFieldRef && textFieldRef.current) {
			const input = textFieldRef.current.getElementsByTagName("input")[0];
			if (input.value !== internalAddress) {
				setSelectedValue(computedOptions.find(x => x.id === "custom") || null);
				input.blur();
				setTimeout(() => {
					input.value = internalAddress || "";
				}, 1000);
			}
		}
	}, [computedOptions, internalAddress, setSelectedValue]);

	const setShowMap = props.setShowMap;
	useEffect(() => {
		if (locationService.userLocationCoords) setShowMap(true);
	}, [locationService.userLocationCoords, setShowMap]);

	return (
		<>
			<Autocomplete
				disableClearable={true}
				popupIcon={null}
				forcePopupIcon={false}
				loadingText={global.loadingText}
				noOptionsText={computedNoResultsMessage}
				//@ts-ignore
				value={selectedValue}
				id={props.id ? props.id : "address_input"}
				fullWidth={props.fullWidth}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				getOptionLabel={option => option?.title || ""}
				options={computedOptions}
				loading={isLoading}
				getOptionDisabled={option =>
					(option?.custom && option?.id === "custom" && !props.needDefaultAddress) ||
					false
				}
				// onFocus={() => {updateOptions(props.value || "")}}
				onFocus={() => {
					setOptionWasSelected(false);
				}}
				onBlur={(_: object) => {
					if (!optionWasSelected && internalAddress !== props.value) {
						const value = {
							title: internalAddress || "",
							custom: true,
							id: "custom",
							fullTitle: internalAddress || "",
						};
						handleValueChanged(_, value, false);
					} else {
						if (!props.needDefaultAddress) {
							handleValueChanged(_, null, true);
						}
					}
				}}
				onChange={(_: object, value: IAddressOptionItem | null | undefined) => {
					setOptionWasSelected(true);
					handleValueChanged(_, value || null);
				}}
				onInputChange={(event: object, value: string, reason: string) => {
					if (reason === "input") {
						updateOptions(value);
						handleAddressChanged(event as ChangeEvent<HTMLInputElement>);
					}
				}}
				filterOptions={x => x}
				groupBy={option => getGroupName(option)}
				PopperComponent={popperProps => (
					<AddressInputPopper {...popperProps} isGoogleEnabled={props.isGoogleEnabled} />
				)}
				renderOption={AddressInputOption}
				renderGroup={AddressInputGroup}
				renderInput={params => (
					<TextField
						{...params}
						ref={textFieldRef}
						required
						size={props.size ? props.size : "small"}
						label={props.label ? props.label : ""}
						error={props.isError || internalError !== null || computedError !== ""}
						helperText={
							<AddressInputHelperText
								error={computedError}
								hasZones={computedNeedZonesMap}
								setShowMap={props.setShowMap}
								errorType={props.pricesError?.internalCode || null}
								isValidAddress={
									!!props.validAddress ? props.validAddress.valid : true
								}
							/>
						}
						value={internalAddress}
						sx={{
							".MuiFormHelperText-root": {
								ml: 1,
							},
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Box>
									{!!internalAddress && (
										<IconButton
											size={"small"}
											onClick={(_: any) => {
												handleValueChanged(_, null, false);
											}}
											disabled={isLoading}
										>
											{isLoading ? (
												<CircularProgress color="inherit" size={15} />
											) : (
												<ClearOutlinedIcon fontSize={"small"} />
											)}
										</IconButton>
									)}
									{props.isGoogleEnabled && (
										<>
											<FindLocation service={locationService} />
											<IconButton
												size={"small"}
												onClick={() => props.setShowMap(true)}
											>
												<MapOutlinedIcon fontSize={"small"} />
											</IconButton>
										</>
									)}
								</Box>
							),
						}}
					/>
				)}
			/>

			<SelectAddressMapModal
				showMap={props.showMap}
				setShowMap={props.setShowMap}
				centerLat={computedCenterLatLng?.lat}
				centerLng={computedCenterLatLng?.lon}
				confirmAddressOnMap={confirmAddressOnMap}
				addressValue={internalAddress || props.value || ""}
				polygons={props.polygons}
				radius={props.radius}
				priorityCoords={props.priorityCoords}
				setValidAddress={props.setValidAddress}
				validateAndSave={validateAndSave}
				validAddress={props.validAddress}
				lang={props.lang}
				businessLanguage={props.businessLanguage || ""}
				pricesError={props.pricesError || null}
				locationService={locationService}
				enabledAnyAddressFromMap={props.enabledAnyAddressFromMap}
			/>
		</>
	);
}

const GroupHeader = styled("div")(({ theme }) => ({
	position: "sticky",
	top: "-8px",
	padding: "0px 10px",
	fontSize: "0.85rem",
	//TODO: add primary color
}));

const GroupItems = styled("ul")({
	padding: 0,
});

interface ISelectAddressMapModalProps {
	showMap: boolean;
	setShowMap: (value: boolean) => void;
	centerLat?: number;
	centerLng?: number;
	confirmAddressOnMap: (addressItem: IGeocodingResultItem) => void;
	addressValue?: string;
	polygons?: IZonePolygon[];
	radius?: IZoneRadius[];
	priorityCoords?: Coordinates | null;
	setValidAddress?: (value: IAddressValid | null) => void;
	validAddress?: IAddressValid | null;
	lang: string;
	businessLanguage: string;
	validateAndSave: (
		addrComponents: IAddressComponent[],
		address: string,
		coords: [number, number],
		placeId: string | null,
		isGoogle: boolean
	) => Promise<boolean>;
	pricesError: IPricesError | null;
	locationService: IUseFindLocationType;
	enabledAnyAddressFromMap: boolean;
}

function SelectAddressMapModal(props: ISelectAddressMapModalProps) {
	const {
		localisation: { maps },
	} = useAppContext();
	const { isMobile } = useScreenService();

	return (
		<ThemeModal
			open={props.showMap}
			setOpen={(show: boolean) => {
				if (!show) props.locationService.setUserLocationCoords(null);
				props.setShowMap(show);
			}}
			onClose={() => {
				props.locationService.setUserLocationCoords(null);
			}}
			title={maps.selectLocation}
			fullWidth={true}
			maxWidth={"lg"}
			sx={{ ".MuiPaper-root": { height: "100%" } }}
			fullScreen={isMobile}
			keepMounted={true}
		>
			<SelectAddressMap
				confirmCallback={props.confirmAddressOnMap}
				centerLat={props.centerLat}
				centerLng={props.centerLng}
				addressValue={props.addressValue}
				polygons={props.polygons}
				radius={props.radius}
				priorityCoords={props.priorityCoords}
				setShowMap={props.setShowMap}
				setValidAddress={props.setValidAddress}
				validateAndSave={props.validateAndSave}
				validAddress={props.validAddress}
				lang={props.lang}
				businessLanguage={props.businessLanguage}
				pricesError={props.pricesError}
				userLocationCoords={props.locationService.userLocationCoords}
				enabledAnyAddressFromMap={props.enabledAnyAddressFromMap}
			/>
		</ThemeModal>
	);
}

function AddressInputOption(props: any, option: IAddressOptionItem) {
	return (
		<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
			<Box display={"flex"}>
				<Box alignSelf={"center"}>
					{option.custom ? (
						option.id === "custom" ? (
							<EditLocationOutlinedIcon sx={{ color: "primary.main" }} />
						) : (
							<MapOutlinedIcon fontSize={"large"} sx={{ color: "primary.main" }} />
						)
					) : (
						<RoomOutlinedIcon fontSize={"large"} sx={{ color: "primary.main" }} />
					)}
				</Box>
				<Box ml={2}>
					<Box>{option.title}</Box>
					<Box>
						{option.subTitle && (
							<Box sx={{ typography: "body2", color: "text.secondary" }}>
								{option.subTitle}
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

function AddressInputGroup(params: AutocompleteRenderGroupParams) {
	return (
		<li key={params.key}>
			<GroupHeader>
				<Box sx={{ backgroundColor: "background.paper" }}>{params.group}</Box>
			</GroupHeader>
			<GroupItems aria-disabled={true}>{params.children}</GroupItems>
		</li>
	);
}

interface IAddressInputPopperProps extends PopperProps {
	isGoogleEnabled: boolean;
}

function AddressInputPopper(props: IAddressInputPopperProps) {
	const {
		localisation: { maps },
	} = useAppContext();
	const { colorScheme } = useColorScheme();

	return (
		<Popper
			{...props}
			sx={
				props.isGoogleEnabled
					? {
							".MuiPaper-rounded": {
								borderBottomLeftRadius: 0,
								borderBottomRightRadius: 0,
							},
						}
					: {}
			}
		>
			<>
				{props.children}
				{props.isGoogleEnabled && (
					<Paper
						sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, px: 3, py: 2 }}
						className={"MuiAutocomplete-paper MuiAutoComplete-listbox"}
					>
						<Box textAlign={"end"} pt={2}>
							<span className={"me-2 small"}>{maps.poweredBy}</span>
							<img
								src={colorScheme === "light" ? GoogleLight : GoogleDark}
								alt="Google"
								height={20}
							/>
						</Box>
					</Paper>
				)}
			</>
		</Popper>
	);
}

interface IZonesMapProps {
	polygons?: IZonePolygon[];
	radius?: IZoneRadius[];
	setShowMap: (show: boolean) => void;
	text: string;
	isError?: boolean;
	selectOnMap?: boolean;
}

function ZonesMap(props: IZonesMapProps) {
	const {
		localisation: { maps },
	} = useAppContext();

	const handleShow = () => {
		props.setShowMap(true);
	};

	return (
		<Typography
			fontSize={"0.75rem"}
			alignSelf={"center"}
			sx={
				props.isError
					? {
							a: {
								color: "error.main",
								textDecorationColor: "var(--mui-palette-error-main)",
							},
						}
					: {}
			}
		>
			{!!props.text && (
				<TgLink className={"cursor-pointer"} onClick={handleShow}>
					{props.text}
				</TgLink>
			)}
			{props.selectOnMap && (
				<SelectOnMapHelperText text={maps.selectOnMap} action={handleShow} />
			)}
		</Typography>
	);
}

interface IAddressInputHelperTextProps {
	setShowMap: (show: boolean) => void;
	error: string | null;
	hasZones: boolean;
	errorType: string | null;
	isValidAddress: boolean;
}

function AddressInputHelperText(props: IAddressInputHelperTextProps) {
	const {
		brandInfo,
		localisation: { maps },
	} = useAppContext();

	return (
		<Box>
			{brandInfo?.is_enabled_google_maps_api && !props.hasZones && !props.error && (
				<SelectOnMapHelperText
					text={maps.selectOnMap}
					action={() => props.setShowMap(true)}
				/>
			)}
			{!!props.error ? (
				<Box display={"flex"} flexWrap={"wrap"}>
					<Box mr={1} display={"flex"}>
						<Interweave tagName={"div"} content={props.error} />

						<Tooltip
							enterTouchDelay={0}
							title={
								<Interweave
									content={maps.addressErrorInstructions.replaceAll(
										"\n",
										"<br/>"
									)}
								/>
							}
						>
							<HelpOutlineOutlinedIcon
								sx={{
									color: "primary.error",
									fontSize: 16,
									alignSelf: "center",
									ml: 1,
								}}
								className={"cursor-pointer"}
							/>
						</Tooltip>
					</Box>

					{((props.errorType && props.errorType === "address_error") ||
						!props.isValidAddress) &&
						brandInfo?.is_enabled_google_maps_api && (
							<ZonesMap
								isError={true}
								setShowMap={props.setShowMap}
								text={!props.isValidAddress ? maps.tryOnMap : maps.checkOnMap}
							/>
						)}
				</Box>
			) : (
				props.hasZones &&
				brandInfo?.is_enabled_google_maps_api && (
					<ZonesMap setShowMap={props.setShowMap} text={""} selectOnMap={true} />
				)
			)}
		</Box>
	);
}

function SelectOnMapHelperText({ text, action }: { text: string; action: () => void }) {
	const computedSelect = useMemo(() => {
		if (text.includes("{p_link}")) return text.split(" {p_link}")[0];
		return "";
	}, [text]);

	const computedNextText = useMemo(() => {
		if (text.includes("{p_link}")) return text.split("{p_link} ")[1];
		return "";
	}, [text]);

	return (
		<>
			<Link color={"text.secondary"} onClick={action} sx={{ cursor: "pointer" }}>
				{computedSelect}
			</Link>{" "}
			{computedNextText}
		</>
	);
}

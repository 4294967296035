import Categories from "./Categories";
import ShopMenuStickyWrapper from "./ShopMenuStickyWrapper";


export default function ShopMenuCategories() {
    return (
        <div className={'d-flex flex-column h-100 border-end ps-1 pe-3 me-3'}>
            <ShopMenuStickyWrapper id={'categories-container'}>
                <Categories/>
            </ShopMenuStickyWrapper>
        </div>
    )
}

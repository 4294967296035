import { useEffect, useRef, useState } from "react";

import { IQrMenuButtonProps } from "../types";
import QrMenuButtonWrapper from "./QrMenuButtonWrapper";
import api from "../../../api";
import { useShopContext } from "../../context";
import QrMenuCallManagerModal from "../modals/QrMenuCallManagerModal";
import { useSelectedStoreOptional } from "../../SelectedStore/context";
import useThrottled from "../../../features/hooks/useThrottled";
import { Box, Typography } from "@mui/material";

export default function WaiterButton(props: IQrMenuButtonProps) {
	const { menuInStoreService, brandInfo } = useShopContext();
	const selectedStore = useSelectedStoreOptional();

	const [show, setShow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [disabledTime, setDisabledTime] = useState(0);

	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	const onClick = useThrottled(
		async () => {
			if (menuInStoreService.menuInStore?.id) {
				try {
					setIsLoading(true);
					setDisabledTime(0);

					await api.shop.text_notifications.send({
						target: "crm",
						type: "waiter",
						brand_id: brandInfo.id,
						store_id: selectedStore?.id,
						menu_in_store_id: menuInStoreService.menuInStore.id,
					});
					setShow(true);

					if (intervalRef.current) {
						clearTimeout(intervalRef.current);
					}
					setDisabledTime(20);
					intervalRef.current = setInterval(() => {
						setDisabledTime(prev => {
							const result = prev - 1;
							if (result === 0) {
								intervalRef.current && clearInterval(intervalRef.current);
							}
							return result;
						});
					}, 1_000);
				} finally {
					setIsLoading(false);
				}
			}
		},
		[brandInfo.id, menuInStoreService.menuInStore?.id, selectedStore?.id],
		20000
	);

	useEffect(() => {
		return () => {
			intervalRef.current && clearInterval(intervalRef.current);
		};
	}, []);

	return (
		<>
			<QrMenuButtonWrapper
				onClick={onClick}
				disabled={disabledTime > 0}
				loading={isLoading}
				loadingPosition={"end"}
			>
				<Box
					display={"flex"}
					flex={1}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					{props.buttonName}

					{!!disabledTime && <Typography component={"span"}>{disabledTime}</Typography>}
				</Box>
			</QrMenuButtonWrapper>

			<QrMenuCallManagerModal show={show} setShow={setShow} />
		</>
	);
}

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import ReceiptContent from "./ReceiptContent";
import { TgLink } from "../../helpers/ThemeComponents";
import NonModalDialogPage from "../NonModalDialog/NonModalDialogPage";
import useAppContext from "../../useAppContext";
import changePage from "../../helpers/changePage";
import f from "../../helpers/formatText";

export default function ReceiptPage() {
	const {
		localisation: { global, receipt },
		loadingManager,
	} = useAppContext();
	const { storeId, receiptId } = useParams();

	const {
		data: receiptData,
		isFetching,
		isLoading,
	} = useQuery({
		queryKey: ["receipt", receiptId],
		queryFn: async () => {
			if (!receiptId) return null;
			const response = await api.shop.basic.getReceipt(
				parseInt(receiptId),
				parseInt(storeId || "") || undefined
			);
			return response.data;
		},
		initialData: null,
	});

	const handleBackToShop = () => {
		if (storeId) {
			changePage(`/shop/${storeId}/menu`);
		} else {
			changePage(`/shop/select`);
		}
	};

	useEffect(() => {
		if (isFetching || isLoading) loadingManager.setIsLoading("receipt", true);
		else loadingManager.setIsLoading("receipt", false);
	}, [isFetching, isLoading, loadingManager]);

	return (
		<>
			{!!receiptData && (
				<NonModalDialogPage
					containerProps={{
						maxWidth: "sm",
					}}
					header={
						<Box textAlign={"start"} px={3}>
							<Box>{f(receipt.receiptNumber, { receipt_id: receiptData.id })}</Box>
							{!!storeId && (
								<Typography variant={"body2"}>
									<TgLink
										className={"cursor-pointer"}
										onClick={() => handleBackToShop()}
									>
										{global.backHome}
									</TgLink>
								</Typography>
							)}
						</Box>
					}
				>
					<ReceiptContent receipt={receiptData} />
				</NonModalDialogPage>
			)}
		</>
	);
}

import { useCallback } from "react";
import { AppleAuthResponse } from "../../../../../features/hooks/useAppleLogin/types";
import { OAuthAppleLoginToken } from "../../../../../api/auth/types";
import api from "../../../../../api";
import useAppleLogin from "../../../../../features/hooks/useAppleLogin";
import {
	AppleAuthApiRedirectUri,
	AppleAuthClientId,
	AppleAuthRedirectUri,
} from "../../../../../config";
import { IOAuthService, IOAuthServiceProps } from "../../../types";
import { getDeviceInfo } from "../../../../../helpers";

export default function useAppleLoginService(props: IOAuthServiceProps): IOAuthService {
	const onError = props.onError;
	const onSuccess = props.onSuccess;
	const setIsLoading = props.setIsLoading;
	const handleAppleLogin = useCallback(
		async (res: any) => {
			if (!res?.error) {
				const appleResponse = res as AppleAuthResponse;
				if (appleResponse.authorization.code && appleResponse.authorization.id_token) {
					try {
						setIsLoading && setIsLoading(true);
						const payload: OAuthAppleLoginToken = {
							access_token: appleResponse.authorization.code,
						};
						if (
							appleResponse.user?.name?.firstName &&
							appleResponse.user?.name?.lastName
						) {
							payload.first_name = appleResponse.user.name.firstName;
							payload.last_name = appleResponse.user.name.lastName;
						}
						payload.is_native = !!props.native;

						if (props.isNewLogin) {
							payload.is_new_login = true;
							payload.device_info = getDeviceInfo();
						}
						if (props.authSource) {
							payload.auth_source = props.authSource;
						}
						const response = await api.auth.loginByApple(payload);
						if (response.data) {
							onSuccess && onSuccess(response.data, "apple");
							return;
						}
					} catch (ex: any) {
						console.log(ex);
						onError && onError(props.unknownError || "");
						return;
					} finally {
						setIsLoading && setIsLoading(false);
					}
				}
			}
			if (res?.error?.error) {
				if (res.error.error === "popup_closed_by_user") {
					return;
				}
				if (res.error.error === "popup_blocked_by_browser") {
					onError && onError(props.openFormError || "");
					return;
				}
				onError && onError((props.unknownError || "") + " " + res.error.error);
				return;
			}
			onError && onError(props.unknownError || "");
		},
		[
			onError,
			props.unknownError,
			props.native,
			props.isNewLogin,
			props.authSource,
			props.openFormError,
			setIsLoading,
			onSuccess,
		]
	);

	const { login, loaded } = useAppleLogin({
		clientId: AppleAuthClientId,
		redirectURI: props.native ? AppleAuthApiRedirectUri : AppleAuthRedirectUri,
		responseMode: "form_post",
		responseType: "code",
		callback: handleAppleLogin,
		scope: "name email",
		usePopup: !props.native,
	});

	return { login, loaded };
}

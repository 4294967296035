import { MDBIcon, MDBPopover, MDBPopoverBody, MDBPopoverHeader } from "mdb-react-ui-kit";

import { TgLink } from "../../../../helpers/ThemeComponents";
import useAppContext from "../../../../useAppContext";
import Interweave from "../../../../features/Interweave";

export interface ILoyaltyInfoIconProps {
	localizations: {
		loyaltyInfo: string;
		incustLogin: string;
	};
	description?: string | null;
}

function LoyaltyInfoIcon(props: ILoyaltyInfoIconProps) {
	return (
		<div className={"ms-2"}>
			<MDBPopover
				placement={"top"}
				tag={"span"}
				btnChildren={
					<MDBIcon fas icon="info-circle" className={"theme-text cursor-pointer"} />
				}
				dismiss
			>
				<MDBPopoverHeader className={"theme-bg-paper theme-text"}>
					{props.localizations.loyaltyInfo}
				</MDBPopoverHeader>
				<MDBPopoverBody className={"tg-test theme-bg-paper"}>
					{props.description && (
						<Interweave content={props.description.replaceAll("\n", "<br/>")} />
					)}
				</MDBPopoverBody>
			</MDBPopover>
		</div>
	);
}

export default function RegisterOrLoginButton(props: ILoyaltyInfoIconProps) {
	return (
		<div className={"d-flex"}>
			<RegisterLink text={props.localizations.incustLogin} />
			{!!props.description && (
				<LoyaltyInfoIcon
					localizations={props.localizations}
					description={props.description}
				/>
			)}
		</div>
	);
}

interface IRegisterLinkProps {
	text: string;
}

function RegisterLink(props: IRegisterLinkProps) {
	const {
		authService: { setShowAuthorisationModal },
	} = useAppContext();

	return (
		<div className={"d-flex"}>
			<TgLink className={"cursor-pointer"} onClick={() => setShowAuthorisationModal(true)}>
				{/*<MDBIcon fas icon="gifts" className={'me-1'} />*/}
				<MDBIcon fas icon="gift" className={"me-1"} />
				{props.text}
			</TgLink>
		</div>
	);
}

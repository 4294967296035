import {throttle} from "lodash";
import {useCallback} from "react";


type throttleParams = Parameters<typeof throttle>


export default function useThrottled<T extends (...args: any) => any>(
    func: T,
    deps?: any[],
    wait?: throttleParams[1],
    options?: throttleParams[2],
): (...args: Parameters<T>) => ReturnType<typeof throttle<T>> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttled = useCallback(throttle((funcToCall: T, args: any) => {
        return funcToCall(...args)
    }, wait, options), [wait, JSON.stringify(options)])

    return useCallback((...args: Parameters<T>) => {
        return throttled(func, args)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [throttled, ...(deps || [])])
}

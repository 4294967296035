import { IIncustCoupon } from "../../../types";
import useAppContext from "../../../../../useAppContext";
import { Box, Theme, useColorScheme, useMediaQuery } from "@mui/material";
import { UsedBadge } from "../UsedBadge";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

export function ImageBox(props: { coupon: IIncustCoupon }) {
	const {
		localisation: { global },
	} = useAppContext();
	const colorScheme = useColorScheme();
	const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
	return (
		<>
			{props.coupon.image && (
				<Box
					position={"relative"}
					bgcolor={"background.default"}
					borderRadius={1}
					overflow={"visible"}
					sx={{
						borderBottomRightRadius: { xs: 0, sm: 1 },
						borderBottomLeftRadius: { xs: 0, sm: 1 },
					}}
				>
					{props.coupon?.redeem_dt && <UsedBadge label={global.usedCoupon} />}
					<Box
						component={"img"}
						src={props.coupon.image}
						alt={props.coupon.title || ""}
						sx={{
							maxWidth: isTablet
								? {
										xs: "140px",
										sm: "140px",
										md: "180px",
									}
								: { xs: "100%" },
							width: "auto",
							height: "auto",
							aspectRatio: "1 / 1",
							opacity: props.coupon.redeem_dt ? 0.5 : 1,
							objectFit: "contain",
						}}
					/>
				</Box>
			)}

			{!props.coupon?.image && (
				<Box position={"relative"} overflow={"visible"}>
					{props.coupon?.redeem_dt && <UsedBadge label={global.usedCoupon} />}
					<Box>
						<ConfirmationNumberIcon
							sx={{
								fontSize: { xs: `${(window.innerWidth / 3) * 2}px`, sm: "10.9em" },
								width: "100%",
								color: `primary.${colorScheme.mode}`,
							}}
						/>
					</Box>
				</Box>
			)}
		</>
	);
}

import useGoogleLoginService from "../../auth/Autorisation/external/oauth/google/useGoogleLoginService";
import { IOAuthService, IOAuthServiceProps } from "../../auth/Autorisation/types";
import useAppleLoginService from "../../auth/Autorisation/external/oauth/apple/useAppleLoginService";
import { OAuthType } from "./OAuthWidget";

export default function useOAuthWidgetProvider(provider: OAuthType) {
	switch (provider) {
		case "google":
			return useGoogleOAuthWidgetProvider;
		case "apple":
			return useAppleOAuthWidgetProvider;
		default:
			throw new Error("Unsupported provider");
	}
}

function useGoogleOAuthWidgetProvider(props: IOAuthServiceProps): IOAuthService {
	const { login: googleLogin, loaded: googleLoaded } = useGoogleLoginService({ ...props });

	return {
		login: googleLogin,
		loaded: googleLoaded,
	};
}

function useAppleOAuthWidgetProvider(props: IOAuthServiceProps): IOAuthService {
	const { login: appleLogin, loaded: appleLoaded } = useAppleLoginService({ ...props });

	return {
		login: appleLogin,
		loaded: appleLoaded,
	};
}

import useAppContext from "../../../../useAppContext";
import useMergeState from "../../../../helpers/useMergedState";
import { MakeInvoiceFormType } from "../types";
import useCurrency from "../../../../services/useCurrencyService";
import useIncustService, {
	useIncustCheckItemsFromInvoiceTemplateItems,
} from "../../../../features/services/useIncustService";
import { useSelectedStoreOptional } from "../../../SelectedStore/context";
import React, { FormEvent, useState } from "react";
import useNewPaymentsService from "../../hooks/useNewPaymentsService";
import { CreateInvoiceWebPayload, IInvoiceCreatedResult } from "../../../../api/invoice/types";
import api from "../../../../api";
import { Box, CircularProgress, Typography } from "@mui/material";
import { MakeInvoiceFormProps } from "./types";
import { ConfirmationComponent, PaymentDataComponent, PersonalDataComponent } from "./components";
import { calcConsentValues } from "../../../../auth/functions";
import changePage from "../../../../helpers/changePage";
import { PaymentsModal } from "../../Payment/Payments";

export default function MakeInvoiceForm(props: MakeInvoiceFormProps) {
	const {
		lang,
		brandInfo,
		localisation,
		showError,
		authService: { user, token: userToken },
		loadingManager: { isSomethingLoading },
	} = useAppContext();

	const [form, setForm] = useMergeState<MakeInvoiceFormType>({
		first_name: "",
		last_name: "",
		entered_amount: props.enteredAmount || null,
		count: null,
		marketing_consent: false,
	});

	const currency = useCurrency();

	const checkItems = useIncustCheckItemsFromInvoiceTemplateItems(
		props.invoiceTemplate?.items,
		form.count
	);
	const selectedStore = useSelectedStoreOptional();
	const incustService = useIncustService(
		brandInfo?.id,
		selectedStore?.id,
		brandInfo?.incust_data,
		lang,
		currency || "",
		checkItems,
		form.entered_amount,
		!props.noLoyaltyKey && !props.invoiceTemplate?.disabled_loyalty
	);

	const [showPayments, setShowPayments] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [errorText, setErrorText] = useState("");
	const paymentsService = useNewPaymentsService(
		selectedStore?.id, null, currency,
		null, undefined, null, props.invoiceTemplate?.id
	);

	const [invoiceCreated, setInvoiceCreated] = useState<IInvoiceCreatedResult | null>(null);

	const { makePrePayment } = paymentsService;
	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (invoiceCreated) {
			if (
				paymentsService.paymentsInfo?.single_payment_method?.provider === "incust_pay" ||
				paymentsService.paymentsInfo?.single_payment_method?.provider === "friend" ||
				paymentsService.paymentsInfo?.single_payment_method?.provider === "orange" ||
				paymentsService.forceSingleMethod?.provider === "incust_pay" ||
				paymentsService.forceSingleMethod?.provider === "friend" ||
				paymentsService.forceSingleMethod?.provider === "orange"
			) {
				setShowPayments(true);
				return;
			}

			if (
				(paymentsService.paymentsInfo?.single_payment_method?.is_online ||
					paymentsService.forceSingleMethod?.is_online) &&
				paymentsService.paymentsInfo?.single_payment_method?.need_comment !== "required" &&
				paymentsService.forceSingleMethod?.need_comment !== "required" &&
				paymentsService.paymentsInfo?.single_payment_method?.need_comment !== "optional" &&
				paymentsService.forceSingleMethod?.need_comment !== "optional"
			) {
				try {
					await makePrePayment(
						false,
						invoiceCreated.invoice.id,
						invoiceCreated.token_data.token
					);
					return;
				} catch (err) {
					console.log(err);
				}
			}
			if (!!user) {
				await api.auth.updateMarketingConsent({
					marketing_consent: !!form.marketing_consent,
				});
			}
			return setShowPayments(true);
		}

		if (!brandInfo) return;

		try {
			setIsLoading(true);
			const consentValues = calcConsentValues(
				form.is_accepted_agreement,
				form.marketing_consent,
				brandInfo?.consent_mode,
				user
			);

			const args: CreateInvoiceWebPayload = {
				group_id: brandInfo.group_id,
				payment_mode: props.paymentMode,
				email: form.email,
				phone: form.phone,
				first_name: form.first_name,
				last_name: form.last_name,
				is_accepted_agreement: consentValues.consent,
				menu_in_store_id: props.menuInStoreId,
				store_id: selectedStore?.id,
				invoice_template_id: props.invoiceTemplate?.id,
				entered_amount: form.entered_amount,
				count: form.count,
				with_url: false,
				successful_payment_callback_url: window.location.href,
				client_redirect_url: window.location.href,
				incust_check: incustService.processedCheck,
				user_comment: form.user_comment,
				external_transaction_id: props.externalId,
				marketing_consent: consentValues.market_consent,
			};
			const response = await api.invoice.createInvoice(args);
			setErrorText("");

			const currentLastPersonalData = api.shop.order.getLastPersonalData() || {};
			api.shop.order.saveLastPersonalData(
				JSON.stringify({
					...currentLastPersonalData,
					email: form.email,
					phone: form.phone,
					first_name: form.first_name,
					last_name: form.last_name,
				})
			);

			console.log("*** INVOICE RESPONSE", response.data);

			if (response.data.invoice.status === "payed") {
				changePage(`/shop/invoice/${response.data.invoice.id}`, {
					invoice_token: response.data.token_data.token,
					mode: null,
					entered_amount: null,
					invoice_template_id: null,
				});
			} else {
				setInvoiceCreated(response.data);
				props.onInvoiceCreated && props.onInvoiceCreated(response.data);

				if (
					paymentsService.paymentsInfo?.single_payment_method?.provider ===
						"incust_pay" ||
					paymentsService.paymentsInfo?.single_payment_method?.provider === "friend" ||
					paymentsService.paymentsInfo?.single_payment_method?.provider === "orange" ||
					paymentsService.forceSingleMethod?.provider === "incust_pay" ||
					paymentsService.forceSingleMethod?.provider === "friend" ||
					paymentsService.forceSingleMethod?.provider === "orange"
				) {
					setShowPayments(true);
					return;
				}

				if (
					(paymentsService.paymentsInfo?.single_payment_method?.is_online ||
						paymentsService.forceSingleMethod?.is_online) &&
					paymentsService.paymentsInfo?.single_payment_method?.need_comment !==
						"required" &&
					paymentsService.forceSingleMethod?.need_comment !== "required" &&
					paymentsService.paymentsInfo?.single_payment_method?.need_comment !==
						"optional" &&
					paymentsService.forceSingleMethod?.need_comment !== "optional"
				) {
					try {
						await makePrePayment(
							false,
							response.data.invoice.id,
							response.data.token_data.token
						);
						return;
					} catch (err) {
						console.log(err);
					}
				}
				setShowPayments(true);
			}
		} catch (ex: any) {
			if (ex?.response?.status === 409) {
				showError(ex, true);
				return;
			}
			setErrorText(ex?.response?.data?.detail || localisation.global.errApi);
		} finally {
			incustService.clearLoyaltyData();
			setIsLoading(false);
		}
	};

	if (!lang) return null; // for typescript

	if (!currency) {
		return <Typography color={"error"}>No currency</Typography>;
	}

	if (props.isLoading || (userToken && !user)) {
		return (
			<Box width={"100%"} display={"flex"} justifyContent={"center"} mt={4} mb={3}>
				{!isSomethingLoading && <CircularProgress size={"3rem"} />}
			</Box>
		);
	}

	return (
		<Box component={"form"} onSubmit={onSubmit}>
			<PersonalDataComponent
				form={form}
				setForm={setForm}
				invoiceTemplate={props.invoiceTemplate}
				isEmailRequiredForPayment={!!paymentsService.paymentsInfo?.email_required.length}
			/>

			<PaymentDataComponent
				{...props}
				paymentService={paymentsService}
				incustService={incustService}
				form={form}
				setForm={setForm}
				currency={currency}
			/>

			<ConfirmationComponent
				{...props}
				isLoading={isLoading}
				errorText={errorText}
				form={form}
				setForm={setForm}
				incustService={incustService}
				paymentService={paymentsService}
			/>

			<PaymentsModal
				show={showPayments}
				setShow={setShowPayments}
				type={"invoice"}
				selectedStoreId={selectedStore?.id}
				paymentService={paymentsService}
				invoiceCreated={invoiceCreated}
				user={user}
				invoiceToken={invoiceCreated?.token_data.token}
				onlyOnlinePayments={true}
			/>
		</Box>
	);
}

import {AppContextType} from "./types";
import {useContext} from "react";
import {AppContext} from "./AppContextProvider";


export default function useAppContext(): AppContextType {
    const context = useContext(AppContext)
    if (!context) {
        throw new Error('Unable to use app context not inside AppContextProvider')
    }
    return context
}

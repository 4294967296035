import React from "react";
import { Box } from "@mui/material";
import { Panorama } from "@mui/icons-material";
import { ShimmerIcon } from "./product/ProductGallery/utils";

export const ShopBannerSkeleton = (): React.JSX.Element => {
	return (
		<Box
			className="image-skeleton"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				zIndex: -1,
			}}
		>
			<Box
				sx={{
					position: "relative",
					overflow: "hidden",
					borderRadius: "14px",
					width: "100px",
				}}
			>
				<ShimmerIcon shimmerIntense={0.25}>
					<Box
						sx={{
							position: "relative",
							width: "100px",
							height: "90px",
							borderRadius: "14px",
							overflow: "hidden",
						}}
					>
						<Panorama
							sx={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								fontSize: "132px",
								color: "divider",
							}}
						/>
					</Box>
				</ShimmerIcon>
			</Box>
		</Box>
	);
};

import {useParams} from "react-router-dom";
import {useEffect} from "react";
import changePage from "../../../helpers/changePage";


export default function ProductPageRedirectToNew() {
    const {storeId, productId} = useParams()

    useEffect(() => {
        if (storeId) {
            if (productId) {
                changePage(`/shop/${storeId}/menu/product/${productId}`)
            } else {
                changePage(`/shop/${storeId}/menu`)
            }
        } else {
            changePage('/shop/select')
        }
    }, [productId, storeId]);

    return null
}

import {useState, useEffect, useCallback} from "react";

import {IFriendsService} from "./types";
import {IFriend} from "../../../api/shop/friends/types";
import api from "../../../api";
import {BrandInfo} from "../../../api/shop/basic/types";


export default function useFriendsService(brandInfo: BrandInfo | null, authService: any, showError: any): IFriendsService{

    const [isLoading, setIsLoading] = useState(false)

    const [friends, setFriends] =
        useState<IFriend[]>([])

    const loadFriends = useCallback(async () => {
        try {
            const response = await api.shop.friends
                .getFriends()
            setFriends(response.data)
            return response.data
        } catch (err) {
            return []
        }
    }, [])


    const deleteFriend = useCallback (async (friendId: number) => {
        try{
            setIsLoading(true)
            await api.shop.friends.deleteFriend(
                friendId,
            )
            return true
        }
        catch(ex: any){
            showError(ex)
            return false
        }
        finally{
            setIsLoading(false)
        }
    }, [
        showError,
    ])

    const clearFriends = useCallback(async () => {
        try{
            setIsLoading(true)
            await api.shop.friends.clearFriends()
            return true
        }
        catch(ex: any){
            showError(ex)
            return false
        }
        finally{
            setIsLoading(false)
        }
    }, [
        showError,
    ])

    useEffect(() => {
        if (authService.isAuthorised && brandInfo?.is_friend_payment) {
            loadFriends().then()
        } else {
            if(!authService.isAuthorised || !brandInfo?.is_friend_payment){
                setFriends([])
            }
        }
    }, [ authService.isAuthorised, loadFriends, brandInfo?.is_friend_payment])


    return {
        friends: friends || [],
        loadFriends, isLoading,
        isEmpty: !(friends?.length),
        deleteFriend: deleteFriend,
        clearFriends: clearFriends
    }
}

import Authorisation from "./Authorisation";
import { ThemeModal } from "../../helpers/ThemeComponents";
import useAuthorisationForm from "./useAuthorisationForm";
import useAppContext from "../../useAppContext";
import useAuthorisationModalTitle from "./useAuthorisationModalTitle";

export default function ShopAuthorisationModal() {
	const {
		authService: { showAuthorisationModal, setShowAuthorisationModal, user },
	} = useAppContext();

	const authorisationForm = useAuthorisationForm(showAuthorisationModal);
	const title = useAuthorisationModalTitle(authorisationForm.state);

	return (
		<ThemeModal
			open={showAuthorisationModal}
			setOpen={setShowAuthorisationModal}
			title={title}
			fullWidth
			keepMounted={!user}
			maxWidth={authorisationForm.state === "emailRegistration" ? "sm" : "xs"}
			sx={{ zIndex: 9991 }}
		>
			<Authorisation form={authorisationForm} />
		</ThemeModal>
	);
}

import {LocaleType} from "../services/useLocalisationService/types";

export interface IReceiptLocale extends LocaleType {
    scanButton: string
    successProcessHeader: string
    receiptNumber: string
    scanHeader: string
    scanScannedHeader: string
    scanReceiptOrderHeader: string
    receiptChecked: string
    receiptProcessedInMessanger: string
    scanReceiptNeedAuth: string
    scanReceiptExitWarning: string
    scanReceiptExitHeader: string
    scanReceiptContinueWith: string
    scanReceiptExternalLoginError: string
    scanReceiptNotConnectedLoyalty: string
    scanReceiptDisabled: string
    receiptBinCode: string
}


export const ReceiptLocale: IReceiptLocale = {
    scanButton: 'web app scan receipt button',
    successProcessHeader: 'web app scan receipt success process header',
    receiptNumber: 'web app receipt',
    scanHeader: 'web app scan receipt header',
    scanScannedHeader: 'web app scan receipt success header',
    scanReceiptOrderHeader: 'web app scan receipt awards after scan header',
    receiptChecked: 'web app scan receipt success checked text',
    receiptProcessedInMessanger:  'web app scan receipt processed in messanger',
    scanReceiptNeedAuth: 'web app scan receipt need auth text',
    scanReceiptExitWarning: 'web app scan receipt exit warning',
    scanReceiptExitHeader: 'web app scan receipt exit header',
    scanReceiptContinueWith: 'web app scan receipt continue in messanger',
    scanReceiptExternalLoginError: 'web app scan receipt external login unknown error',
    scanReceiptNotConnectedLoyalty: 'web app scan receipt loyalty not connected',
    scanReceiptDisabled: 'web app scan receipt disabled',
    receiptBinCode: 'web app scan receipt bin code text',
}

import axios from "axios";

import {
	IIncustCheck,
	IIncustCheckItem,
	IIncustCoupon,
	IIncustUserCardInfo,
} from "../../../shop/loyalty/types";
import { IIncustPDFResponse, IIncustCard } from "./types";

export const makeIncustCheck = (
	checkItems: IIncustCheckItem[],
	amount: number,
	currency: string,
	bonusesAmount: number,
	coupons: IIncustCoupon[] | null = null,
	externalId: string | null | undefined = null,
	token: string | null
): IIncustCheck | null => {
	let check_obj: IIncustCheck = {
		payment_type: "currency",
		payment_id: currency.toUpperCase(),
		amount: amount,
		amount_to_pay: amount,
		manual_rules: false,
		redeemed_coupons: [...(coupons || [])],
		check_items: checkItems,
		bonuses_redeemed_amount: bonusesAmount,
	};

	if (token && externalId) {
		check_obj.id_type = "external-id";
		check_obj.id = externalId;
	}

	return check_obj;
};

export const getBonusesItemByCurrency = (
	userCard: IIncustUserCardInfo | null | undefined,
	currency: string
) => {
	if (!userCard) return null;
	return userCard.bonuses?.find((i: any) => i.currency === currency);
};

export const getPDFCouponBase64 = async (url: string, lang: string): Promise<string | null> => {
	const response = await axios.get<IIncustPDFResponse>(url, { params: { lang: lang } });

	if (response.status === 200 && response.data.data) return response.data.data;
	return null;
};

export const getCardId = async (
	baseUrl: string,
	token: string,
	wlId: string,
	lang: string,
	loyaltyId: string,
	customerId: string,
	externalId: string
): Promise<IIncustCard | null | undefined> => {
	const cards = await getCards(baseUrl, token, wlId, lang);
	if (cards) {
		const externalCard = cards.find((i: IIncustCard) => i.code === externalId);
		const customerCard = cards.find((i: IIncustCard) => i.code === customerId);
		if (externalCard) return externalCard;
		if (customerCard) return customerCard;
	}

	const createdCard = await createCard(
		baseUrl,
		loyaltyId,
		token,
		wlId,
		lang,
		externalId,
		customerId
	);

	if (createdCard) return createdCard;
	return null;
};

const getCards = async (
	baseUrl: string,
	token: string,
	wlId: string,
	lang: string
): Promise<IIncustCard[] | null> => {
	const response = await axios.get<IIncustCard[]>(`${baseUrl}/v1/wlclient/cards`, {
		headers: {
			Authorization: `Bearer ${token}`,
			"X-Application-Id": wlId,
			"Accept-Language": lang || "en",
		},
	});

	console.log("*** GET CARDS RESPONSE", response);

	if (response.data) return response.data;
	return null;
};

const createCard = async (
	baseUrl: string,
	loyaltyId: string,
	token: string,
	wlId: string,
	lang: string,
	externalId: string,
	customerId: string
): Promise<IIncustCard | null> => {
	const code = externalId || customerId;

	let response;
	try {
		response = await axios.post<IIncustCard>(
			`${baseUrl}/v1/wlclient/loyalty/${loyaltyId}/customers/cards`,
			{ code: code },
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"X-Application-Id": wlId,
					"Accept-Language": lang || "en",
				},
			}
		);
	} catch (ex: any) {
		if (ex?.response?.status === 400) {
			if (ex.response.data.code === 71) {
				response = await axios.post<IIncustCard>(
					`${baseUrl}/v1/wlclient/loyalty/${loyaltyId}/customers/cards`,
					{ code: customerId },
					{
						headers: {
							Authorization: `Bearer ${token}`,
							"X-Application-Id": wlId,
							"Accept-Language": lang || "en",
						},
					}
				);
			} else {
				throw ex;
			}
		}
	}

	console.log("*** CREATE CARD RESPONSE", response);
	if (response) {
		let res = response.data;
		if (Array.isArray(res)) res = res[0];
		if (response.data) return res;
	}
	return null;
};

export const getPkpass = async (
	baseUrl: string,
	loyaltyId: string,
	cardId: string,
	token: string,
	wlId: string,
	lang: string
) => {
	const response = await axios.get(
		`${baseUrl}/v1/wlclient/pkpass/loyalty/${loyaltyId}/customers/card/${cardId}/generate`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Application-Id": wlId,
				"Accept-Language": lang || "en",
			},
			responseType: "blob",
		}
	);

	console.log("*** PKPASS RESPONSE", response);

	if (response.status === 201 && response.data) return response.data;
	return null;
};

import {Characteristic, Filter as FilterType, FilterTypeEnum} from "../../../api/shop/basic/types";
import useFilterValues from "./useFilterValues";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import {MDBInput} from "mdb-react-ui-kit";
import {useState} from "react";
import RangeFilter from "./RangeFilter";
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useSelectedStoreContext} from "../../SelectedStore/context";

interface FilterProps {
    filter: Characteristic
}


export default function Filter({filter}: FilterProps) {
    if (filter.filter_type === FilterTypeEnum.VALUE || filter.filter_type === FilterTypeEnum.MULTI) {
        return <SelectFilter filter={filter}/>
    }

    if (filter.filter_type === FilterTypeEnum.INTEGER || filter.filter_type === FilterTypeEnum.FLOAT) {
        return <NumberFilter filter={filter}/>
    }

    if (filter.filter_type === FilterTypeEnum.RANGE_INTEGER) {
        return <RangeFilterComponent filter={filter}/>
    }

    return null

}


function SelectFilter({filter}: FilterProps) {
    const {filtersService} = useSelectedStoreContext()
    const {
        selectedFilters,
        setSelectedFilters,
    } = filtersService
    const {values, isLoading} = useFilterValues(filter.id)

    const {menu} = useLocalisation()

    const isMultiple = filter.filter_type === FilterTypeEnum.MULTI

    const setFilter = (value: FilterType | null) => setSelectedFilters({[filter.id]: value})
    const resetFilter = () => setFilter(null)

    const getValue = (): FilterType["value"] | FilterType["values_list"] => {
        const selectedFilter: FilterType | null = selectedFilters[filter.id]
        if (isMultiple) {
            return selectedFilter?.values_list || []
        } else {
            return selectedFilter?.value || '--empty--'
        }
    }

    const isSelected = (value: string | null) => {
        const selectedFilter = selectedFilters[filter.id]
        if (!selectedFilter) return value === null
        if (value === null) {
            return isMultiple && selectedFilter.values_list?.length === 0
        }

        if (isMultiple) {
            return selectedFilter.values_list?.includes(value) ?? false
        } else {
            return selectedFilter.value === value
        }
    }

    const onChange = (event: SelectChangeEvent<FilterType["value"] | FilterType["values_list"]>) => {
        const value = event.target.value

        if (isMultiple) {
            let newValue: FilterType["values_list"]

            if (typeof value === 'string') {
                newValue = value.split(',')
            } else if (Array.isArray(value)) {
                newValue = value
            } else {
                throw new Error('invalid value')
            }

            if (!newValue.length) {
                resetFilter()
            } else {
                setFilter({
                    filter_type: FilterTypeEnum.MULTI,
                    values_list: newValue
                })
            }
        } else {
            if (Array.isArray(value)) {
                throw new Error('invalid value')
            }

            if (!value || value === '--empty--') {
                resetFilter()
            } else {
                setFilter({
                    filter_type: FilterTypeEnum.VALUE,
                    value: value
                })
            }
        }
    }

    return (
        <FormControl fullWidth variant={'standard'}>
            <InputLabel id={`filter-${filter.id}-label`}>
                {filter.name}
            </InputLabel>
            <Select
                id={`filter-${filter.id}-select`}
                labelId={`filter-${filter.id}-label`}
                multiple={isMultiple}
                label={filter.name}
                value={getValue()}
                onChange={onChange}
                disabled={isLoading}
            >
                {!isMultiple && (
                    <MenuItem key={'clear-button'} value={'--empty--'}>
                        {menu.filterNotSelected}
                    </MenuItem>
                )}

                {values.map((value) => (
                    <MenuItem key={`value=${value.value}`} value={value.value}>
                        {isMultiple ? (<>
                            <Checkbox checked={isSelected(value.value)} />
                            <ListItemText primary={value.display_value} />
                        </>) : (
                            value.display_value
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}


function NumberFilter({filter}: FilterProps) {
    const [value, setValue] = useState('')
    const {values, isLoading} = useFilterValues(filter.id)
    const numberValues = values.map(el => parseFloat(el.value)).filter(el => !isNaN(el))

    const type = filter.filter_type

    return (
        <MDBInput
            type={'number'}
            value={value}
            className={'tg-input'}
            step={type === FilterTypeEnum.FLOAT ? 0.1 : 1}
            min={Math.min(...numberValues)}
            max={Math.max(...numberValues)}
            disabled={isLoading}
            onChange={(e) => {
                const newValue = e.target.value
                if (
                    newValue === '' ||
                    (type === FilterTypeEnum.INTEGER && parseInt(newValue)) ||
                    (type === FilterTypeEnum.FLOAT && parseFloat(newValue))
                ) {
                    setValue(newValue)
                }
            }}
        />
    )
}


function RangeFilterComponent({filter}: FilterProps) {
    const {filtersService} = useSelectedStoreContext()
    const {menu} = useLocalisation()

    const {values, isLoading} = useFilterValues(filter.id)
    const numberValues = values.map(el => parseFloat(el.value)).filter(el => !isNaN(el))

    const minAllowed = Math.min(...numberValues)
    const maxAllowed = Math.max(...numberValues)

    const selectedFilter: FilterType | null = filtersService.selectedFilters[filter.id]

    const setFilter = (value: FilterType | undefined) => {
        filtersService.setSelectedFilters({[filter.id]: value})
    }

    return (
        <RangeFilter
            minAllowed={minAllowed}
            maxAllowed={maxAllowed}
            label={menu.rangeFilterLabel.replace('{filter_name}', filter.name)}
            min={selectedFilter?.range_min ?? null}
            max={selectedFilter?.range_max ?? null}
            disabled={isLoading || filtersService.isLoading}
            setMin={(min) => {
                if ((!selectedFilter?.range_max ?? null) === null && min === null) {
                    setFilter(undefined)
                } else {
                    setFilter({
                        filter_type: filter.filter_type,
                        range_min: min,
                        range_max: selectedFilter?.range_max,
                    })
                }
            }}
            setMax={(max) => {
                if ((selectedFilter?.range_min ?? null) === null && max === null) {
                    setFilter(undefined)
                } else {
                    setFilter({
                        filter_type: filter.filter_type,
                        range_min: selectedFilter?.range_min,
                        range_max: max,
                    })
                }
            }}
        />
    )
}

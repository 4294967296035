import {useState, useEffect, useCallback} from "react";

import {Product, Store} from "../../../api/shop/basic/types";
import {IFavoritesService} from "./types";
import {ICreateFavorites, IFavoriteProduct, IFavorites} from "../../../api/shop/favorites/types";
import api from "../../../api";
import useAppContext from "../../../useAppContext";


export default function useFavoritesService(
    store: Store | null,
): IFavoritesService{
    const {authService, showError} = useAppContext()

    const [favorites, setFavorites] =
        useState<IFavorites | null>(null)
    const [favoritesToken, setFavoritesToken] =
        useState<string | null>(null)

    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState("")

    const loadFavorites = useCallback(async () => {
        setIsLoading(true)
        try {
            const response = await api.shop.favorites
                .getFavorites(store?.id, favoritesToken)
            setFavorites(response.data)
            return response.data
        } catch (err) {
            showError(err)
            return null
        } finally {
            setIsLoading(false)
        }
    }, [favoritesToken, showError, store?.id])

    const addProductToFavorites = useCallback(async (productOrId: Product | number) => {
        if (!store?.id) return false
        const productId = typeof productOrId === 'number' ? productOrId : productOrId.id
        // Создаем избранное для неавторизованного пользователя
        if(!favorites && !authService.isAuthorised){
            const payload: ICreateFavorites = {
                store_id: store.id,
                products_ids: [productId]
            }

            try{
                setIsLoading(true)
                const response = await api.shop.favorites.createFavorites(payload)
                const favoritesData = response.data
                const favorites = favoritesData.favorites

                api.shop.favorites.setLocalFavoritesToken(favoritesData.token, store.id)
                setFavorites(favorites)
                setFavoritesToken(favoritesData.token)

                return true
            }
            catch(ex: any){
                showError(ex)
                return false
            }
            finally{
                setIsLoading(false)
            }
        }
        // Добавляем товар в избранное для авторизованного пользователя
        try{
            setIsLoading(true)
            const response = await api.shop.favorites
                .addProductToFavorites(productId, store.id, favoritesToken)
            const addedProduct = response.data
            setFavorites(prevState =>  {
                if (!prevState) {
                    throw new Error("Unknown case")
                }
                return {...prevState, favorite_products: [addedProduct, ...(prevState.favorite_products || [])]}
            })

            return true
        }
        catch (ex: any) {
            showError(ex)
            return false
        }
        finally{
            setIsLoading(false)
        }
    }, [
        authService.isAuthorised,
        favorites,
        favoritesToken,
        showError,
        store?.id,
    ])

    const deleteProductFromFavorites = useCallback (async (favProductId: number) => {
        try{
            setIsLoading(true)
            await api.shop.favorites.deleteProductFromFavorites(
                favProductId, store?.id, favoritesToken
            )
            setFavorites(prevState => {
                if (!prevState) {
                    throw new Error("Unknown case")
                }
                return {
                    ...prevState,
                    favorite_products: (prevState.favorite_products || [])
                        .filter(x => x.id !== favProductId)
                }
            })
            return true
        }
        catch(ex: any){
            showError(ex)
            return false
        }
        finally{
            setIsLoading(false)
        }
    }, [
        favoritesToken,
        showError,
        store?.id,
    ])

    const clearFavorites = useCallback(async () => {
        try{
            setIsLoading(true)
            await api.shop.favorites.clearFavorites(store?.id, favoritesToken)
                        setFavorites(prevState => {
                if (!prevState) {
                    throw new Error("Unknown case")
                }
                return {
                    ...prevState,
                    favorite_products: []
                }
            })
            return true
        }
        catch(ex: any){
            showError(ex)
            return false
        }
        finally{
            setIsLoading(false)
        }
    }, [
        favoritesToken,
        showError,
        store?.id,
    ])

    const getFavoriteProduct = useCallback((productId: number) => {
        if (!favorites?.favorite_products) return null
        return favorites.favorite_products.find(x => x.product.id === productId) ?? null
    }, [
        favorites?.favorite_products,
    ])

    useEffect(() => {
        if (store)
            setFavoritesToken(api.shop.favorites.getLocalFavoritesToken(store.id))
    }, [store])

    useEffect(() => {
        if ((store?.id && (favoritesToken || authService.isAuthorised)) && !search) {
            loadFavorites().then()
        } else {
            if(!authService.isAuthorised && !favoritesToken){
                setFavorites(null)
            }
        }
    }, [favoritesToken, authService.isAuthorised, store?.id, loadFavorites, search])

    useEffect(() => {
        if (authService.isAuthorised && favoritesToken && store?.id) {
            api.shop.favorites.syncFavorites({
                anon_favorites_token: favoritesToken,
                store_id: store.id
            }, api.auth.getCurrentToken()).then(() => {
                setFavorites(null)
                setFavoritesToken(null)
                api.shop.favorites.removeLocalFavoritesToken(store.id)
            })
        }
    }, [authService.isAuthorised, favoritesToken, store?.id])

    useEffect(() => {
        if(search){
            const favCopy = JSON.parse(JSON.stringify(favorites))
            const filtered = []
            for(let i = 0; i < favCopy.favorite_products.length; i++){
                const prod = favCopy.favorite_products[i]
                if(prod.product.name.toLowerCase().includes(search.toLowerCase())
                    || prod.product.description?.toLowerCase().includes(search.toLowerCase())){
                    filtered.push(prod)
                }
            }
            favCopy.favorite_products = filtered
            setFavorites(favCopy)
        }
    }, [search])

    return {
        favorites: favorites,
        token: favoritesToken,
        loadFavorites, isLoading,
        isEmpty: !(favorites?.favorite_products?.length),
        addProductToFavorites: addProductToFavorites,
        getFavoriteProduct: getFavoriteProduct,
        deleteProductFromFavorites: deleteProductFromFavorites,
        clearFavorites: clearFavorites,
        setSearch: setSearch,
        search: search,
    }
}

import {useState} from "react"

import useLocalisation from "../../hooks/localisation/useLocalisation";
import api from "../../api"
import {PurposeType} from "../../api/auth/types";
import {useLocation} from "react-router-dom";
import {Alert, Box, Button} from "@mui/material";
import useAppContext from "../../useAppContext";
import Interweave from "../../features/Interweave";


export default function EmailAlert(){
    const localisation = useLocalisation()
    const [emailSent, setEmailSent] = useState<boolean>(false)

    const location = useLocation()

    const {authService: {updateUser, user}} = useAppContext()

    const sendConfirmEmail = async() => {
        setEmailSent(true)
        const payload = {
            from_app: 'web',
            purpose: 'register' as PurposeType,
            email: user?.email ?? '',
        }
        await api.auth.sendConfirmEmail(payload)
    }

    if (!user?.email ||
        user.is_confirmed_email ||
        location.pathname.includes('auth/confirm')
    ) {
        return null
    }

    return (
        <Alert
            variant={'filled'}
            severity={'warning'}
            className={'w-100 top-alert rounded-7 mb-2'}
        >
            <Interweave content={localisation.global.notConfirmedEmail.replace("{email}", user.email)} />
            <br />

            <Box display={'flex'} gap={2}>
                <Button
                    size={'small'}
                    color={'inherit'}
                    onClick={() => updateUser(user)}
                >
                    {localisation.global.reloadPage}
                </Button>
                {!emailSent ? (
                    <Button
                        size={'small'}
                        color={'inherit'}
                        onClick={() => sendConfirmEmail()}
                    >
                        {localisation.auth.resendEmail}
                    </Button>
                ) : (
                    <span>{localisation.global.emailConfirmResent}</span>
                )}
            </Box>
        </Alert>
    )
}

export const ApiUrl = "https://testapi.payforsay.com";
export const WsUrl = "wss://testapi.payforsay.com";
export const ProdApiUrl = "https://api.payforsay.com";
export const ClientBotApiUrl = "https://testbot.payforsay.com";
export const ServiceBotApiUrl = "https://testservicebot.payforsay.com";
export const OpenStreetMapApiUrl = "https://nominatim.openstreetmap.org/";
export const PhotonApiUrl = "https://photon.komoot.io";
export const GoogleOAuthClientId = "";
export const InternalWidgetUrl = "https://webapp.test.payforsay.com";
export const AppleAuthClientId = "com.7loc.auth.client";
export const AppleAuthRedirectUri = "https://webapp.test.payforsay.com";
export const AppleAuthApiRedirectUri = "https://api.test.payforsay.com/auth/apple_oauth";

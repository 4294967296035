import {useMemo, useState, useCallback} from "react";

import {SidebarsServiceType} from "./type";

export default function useSidebarsService(): SidebarsServiceType {
    const [isMainSidebarOpen, setIsMainSidebarOpen] = useState(false)
    const [isProfileSidebarOpen, setIsProfileSidebarOpen] = useState(false)

    const switchToProfileSidebar = useCallback(() => {
        setIsMainSidebarOpen(false)
        setIsProfileSidebarOpen(true)
    }, [setIsMainSidebarOpen, setIsProfileSidebarOpen])

    const switchToMainSidebar = useCallback(() => {
        setIsProfileSidebarOpen(false)
        setIsMainSidebarOpen(true)
    }, [setIsMainSidebarOpen, setIsProfileSidebarOpen])

    return useMemo(() => ({
        isProfileSidebarOpen,
        setIsProfileSidebarOpen,
        isMainSidebarOpen,
        setIsMainSidebarOpen,
        switchToProfileSidebar,
        switchToMainSidebar,
    }), [
        isProfileSidebarOpen,
        setIsProfileSidebarOpen,
        isMainSidebarOpen,
        setIsMainSidebarOpen,
        switchToProfileSidebar,
        switchToMainSidebar,
    ])
}

import {ReactNode, useEffect, useState, useMemo} from 'react';

interface IBaseStickyProps {
    children: ReactNode
    id: string
    offset: number
    extraIsStickyClasses?: string
    callback?: (isSticky: boolean) => void
}

export default function StickyBase(props: IBaseStickyProps){
    const [isSticky, setSticky] = useState(false)

    const computedIsStickyClasses = useMemo(() => {
        const baseClass = "sticky-base"
        if(!!props.extraIsStickyClasses){
            return `${baseClass} ${props.extraIsStickyClasses}`
        }

        return baseClass
    }, [props.extraIsStickyClasses])

    const callback = props.callback
    useEffect(() => {
        const handleScroll = () => {
            const boundaryElement = document.getElementById(props.id)

            if (boundaryElement) {
                const boundaryTop = boundaryElement.getBoundingClientRect().top
                const isPastBoundary = boundaryTop <= props.offset
                if(callback) callback(isPastBoundary)
                setSticky(isPastBoundary)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [callback, props.id, props.offset])

    return (
        props.callback
        ? <>{props.children}</>
        :
            <div
                className={`${isSticky ? computedIsStickyClasses : ''}`}
                style={{top: `${props.offset}px`}}
            >
                {props.children}
            </div>
    )
}

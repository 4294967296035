import { useMemo } from "react";
import { ThemeModal } from "../../helpers/ThemeComponents";
import useAppContext from "../../useAppContext";
import Authorisation from "../../auth/Autorisation/Authorisation";
import useAuthorisationForm from "../../auth/Autorisation/useAuthorisationForm";
import useAuthorisationModalTitle from "../../auth/Autorisation/useAuthorisationModalTitle";

interface IShareAndEarnAuthProps {
	show: boolean;
	setShow: (value: boolean) => void;
	refCode: string;
	externalLoginCallback: () => void;
}

export default function ShareAndEarnAuthAuth(props: IShareAndEarnAuthProps) {
	const {
		authService: { user },
	} = useAppContext();
	const authorisationForm = useAuthorisationForm();

	const authModalTitle = useAuthorisationModalTitle(authorisationForm.state);

	const extraData = useMemo(() => {
		return { referral_code: props.refCode };
	}, [props.refCode]);

	return (
		<>
			<ThemeModal
				open={props.show}
				setOpen={props.setShow}
				title={authModalTitle}
				fullWidth
				keepMounted={false}
				maxWidth={authorisationForm.state === "emailRegistration" ? "sm" : "xs"}
				sx={{ zIndex: 999999 }}
			>
				{(!user || props.show) && (
					<Authorisation
						form={authorisationForm}
						onLoginCallback={() => {
							props.setShow(false);
						}}
						externalLoginPurpose={"invitation"}
						externalLoginExtraData={extraData}
						externalLoginCallback={props.externalLoginCallback}
						customVisible={props.show}
						customSetVisible={props.setShow}
					/>
				)}
			</ThemeModal>
		</>
	);
}

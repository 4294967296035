import { useState, useCallback } from "react";

import api from "../../../api";
import { ScanPayload, ScanResponseUnAuth } from "../../../api/shop/basic/types";
import changePage from "../../../helpers/changePage";

export default function useScanReceipt(): IUseScanReceipt {
	const [showModal, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [orderId, setOrderId] = useState<number | null>(null);
	const [storeId, setStoreId] = useState<number | null>(null);
	const [scannedResult, setScannedResult] = useState<ScanResponseUnAuth | null>(null);

	const makeScanAndSave = useCallback(
		async (payload: ScanPayload) => {
			try {
				setIsLoading(true);
				const payloadToSend = { ...payload };
				if (!!orderId) {
					payloadToSend.order_id = orderId;
				}
				if(storeId){
					payloadToSend.store_id = storeId;
				}
				const receipt = await api.shop.basic.makeScanAndSave(payloadToSend);
				setError(null);

				setShowModal(false);
				if (!!storeId) changePage(`/shop/${storeId}/receipt/${receipt.data.receipt_id}`);
				else changePage(`/shop/receipt/${receipt.data.receipt_id}`);
			} catch (ex: any) {
				if (ex.response?.data?.detail) setError(ex.response.data.detail);
				else setError(ex.message);
			} finally {
				setIsLoading(false);
			}
		},
		[orderId, storeId]
	);

	const makeScan = useCallback(async (payload: ScanPayload) => {
		try {
			setIsLoading(true);
			const receipt = await api.shop.basic.makeScan(payload);
			setScannedResult(receipt.data);
			setError(null);
		} catch (ex: any) {
			if (ex.response?.data?.detail) setError(ex.response.data.detail);
			else setError(ex.message);
		} finally {
			setIsLoading(false);
		}
	}, []);

	return {
		showModal,
		setShowModal,
		isLoading,
		makeScan,
		makeScanAndSave,
		error,
		setError,
		setOrderId,
		orderId,
		setStoreId,
		storeId,
		scannedResult,
		setScannedResult,
	};
}

export interface IUseScanReceipt {
	showModal: boolean;
	setShowModal: (show: boolean) => void;
	isLoading: boolean;
	error: string | null;
	makeScan: (payload: ScanPayload) => Promise<void>;
	makeScanAndSave: (payload: ScanPayload) => Promise<void>;
	setError: (error: string | null) => void;
	setOrderId: (orderId: number | null) => void;
	orderId: number | null;
	setStoreId: (storeId: number | null) => void;
	storeId: number | null;
	scannedResult: ScanResponseUnAuth | null;
	setScannedResult: (result: ScanResponseUnAuth | null) => void;
}

import Categories from "../../menu/Categories";
import { useSelectedStoreContextStoreOptional } from "../../SelectedStore/context";

export default function MainSidebarCategories() {
	const { categoriesService } = useSelectedStoreContextStoreOptional();

	return (
		<>
			<div className="py-2 pe-3 ps-2">
				{!!categoriesService.getCategories("top")?.length && (
					<Categories isSidebar={true} />
				)}
			</div>
		</>
	);
}

import { AuthorisationComponentProps } from "../types";
import EmailInput from "../inputs/EmailInput";
import PasswordInput from "../inputs/PasswordInput";
import FormError from "../FormError";
import { TgButton, TgLink } from "../../../helpers/ThemeComponents";
import { MDBSpinner } from "mdb-react-ui-kit";
import { FormEvent, MouseEvent } from "react";
import { getErrorText } from "../../../helpers/errors";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import RepeatPasswordInput from "../inputs/RepeatPasswordInput";
import AgreementCheckbox from "../inputs/AgreementCheckbox";
import FirstAndLastNamesInputs from "../inputs/FirstAndLastNamesInputs";
import useAppContext from "../../../useAppContext";
import BirthdayInput from "../inputs/BirthdayInput";

export default function EmailRegistration(props: AuthorisationComponentProps) {
	if (props.form.state !== "emailRegistration") {
		throw new Error("EmailRegistration support only emailRegistration state");
	}

	const localisation = useLocalisation();

	const {
		authService: { setShowAuthorisationModal },
		groupService: { group },
	} = useAppContext();

	const {
		authService: { register },
	} = useAppContext();

	const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (props.form.state !== "emailRegistration") return null; // for typescript

		if (!props.form.isAcceptedAgreement) {
			return props.form.setError("form", localisation.auth.errAgreement);
		}

		try {
			props.form.setIsLoading(true);
			props.form.resetErrors();
			const result = await register(
				{
					register_method: "email",
					email_data: {
						email: props.form.email,
						first_name: props.form.firstName,
						last_name: props.form.lastName,
						birth_date: props.form.birth_date,
						password: props.form.password,
					},
					is_accept_agreement: props.form.isAcceptedAgreement,
				},
				props.onLoginCallback
			);
			if (result) {
				setShowAuthorisationModal(false);
			}
		} catch (err: any) {
			props.form.setError("form", getErrorText(err, localisation.auth.unknownError));
		} finally {
			props.form.setIsLoading(false);
		}
	};

	const onSubmitButtonClick = (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
		if (props.form.state !== "emailRegistration") return; // never, for typescript

		if (props.form.password !== props.form.repeatPassword) {
			e.preventDefault();
			props.form.setError("repeatPassword", localisation.auth.passwordsDoNotMatchError);
		}

		if (!props.form.isAcceptedAgreement) {
			props.form.setError("form", localisation.auth.errAgreement);
			e.preventDefault();
		}
	};

	return (
		<div>
			<form onSubmit={onFormSubmit}>
				<EmailInput form={props.form} />
				<FirstAndLastNamesInputs form={props.form} />
				{group && group.is_ask_about_birthday && <BirthdayInput form={props.form} />}
				<PasswordInput form={props.form} />
				<RepeatPasswordInput form={props.form} />
				<AgreementCheckbox
					value={props.form.isAcceptedAgreement}
					setValue={newValue => props.form.setIsAcceptedAgreement(newValue)}
				/>

				<FormError errText={props.form.errors.form} />

				<TgButton
					type={"submit"}
					onClick={onSubmitButtonClick}
					disabled={props.form.isLoading}
					className={"w-100 mt-2 shadow-none"}
				>
					{props.form.isLoading && (
						<MDBSpinner size={"sm"} role={"status"} className={"me-2"} />
					)}
					{localisation.auth.registerButton}
				</TgButton>

				<div className={"form-text"}>
					<TgLink
						onClick={e => {
							e.preventDefault();
							props.form.chooseAuthMethod();
						}}
					>
						{localisation.auth.changeEmailButton}
					</TgLink>
				</div>
			</form>
		</div>
	);
}

export const downloadFromBase64 = (base64Data: string, fileName: string): void => {
    // Convert base64 to binary data
    const binaryData = window.atob(base64Data)

    // Create a Uint8Array from the binary data
    const uint8Array = new Uint8Array(binaryData.length)
    for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i)
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'application/pdf' })

    // Create a data URL from the Blob
    const url = URL.createObjectURL(blob)

    // Create a download link
    const a = document.createElement('a')
    a.href = url
    a.download = fileName

    // Programmatically trigger a click event on the link
    a.click()

    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(url)
}

import React, {ReactNode, useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import {Box, Collapse, IconButton, Skeleton} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";

interface IWalletCard {
    headerIcon?: ReactNode,
    headerText: string,
    isLoading: boolean,
    children: ReactNode,
    marginX?: number,
    sceleton?: ReactNode,
    isCollapsable?: boolean,
    handler?: (_: boolean) => void;
}

export function IncustWalletCard(props: IWalletCard) {
    const hasChildren = props.children !== null;
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (props.isCollapsable === undefined) {
            setOpen(true);
        }
    }, [props.isCollapsable]);

    return (
        <Card sx={{width: '100%', borderRadius: '0 0 0 0',}} elevation={0}>
            <CardHeader title={<Typography fontSize="large">
                {props.isLoading ? <Box width={'70%'}>
                        <Skeleton variant="text" sx={{fontSize: '1rem'}} width={'100%'}/>
                    </Box>
                    : props.headerText}
            </Typography>}
                        avatar={props.headerIcon ? (props.isLoading ?
                            <Skeleton width={'24px'} height={'24px'} variant={'circular'}/>
                            : props.headerIcon) : null}
                        action={props.isCollapsable ? (
                            <IconButton
                                onClick={() => setOpen(!open)}
                                aria-label="expand"
                                size="small"
                            >
                                {open ? <KeyboardArrowDownIcon
                                    sx={{
                                        color: 'text.secondary',
                                        fontSize: '2rem!important'
                                    }}
                                /> : <ArrowForwardIosIcon
                                    sx={{
                                        color: 'text.secondary',
                                        fontSize: '1.5rem!important'
                                    }}
                                />}
                            </IconButton>) : props.handler ? (
                            <IconButton
                                onClick={() => props.handler?.(true)}
                                aria-label="expand"
                                size="small"
                            >
                                {<AddIcon
                                    sx={{
                                        color: 'text.secondary',
                                        fontSize: '2rem!important'
                                    }}
                                />}
                            </IconButton>
                        ) : null
                        }
            />
            {hasChildren &&
                (<Collapse in={open} timeout="auto" unmountOnExit>
                    <Box my={1} mx={props.marginX ?? 3}>
                        {props.children}
                    </Box>
                </Collapse>)
            }
        </Card>
    )
}
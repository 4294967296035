import {Navigate} from "react-router-dom"
import {router} from "../index";

interface IRouteRedirectProps {
    to?: string
    params?: any
}

export default function RouteRedirect(props: IRouteRedirectProps) {
    const redirectPath = () => {
        const searchParams = new URLSearchParams(router.state.location.search)
        if(props.params){
            Object.keys(props.params).forEach(key => {
                if(searchParams.has(key))
                    searchParams.delete(key)
                if (props.params[key]) {
                    searchParams.set(key, props.params[key])
                }
            })
        }
        return `/${props.to ? props.to : ''}?${searchParams.toString()}`
    }


    return <Navigate to={redirectPath()}/>
}

import {
	useEffect,
	useState,
	useMemo,
	useRef,
	useCallback,
	HTMLAttributes,
	ReactNode,
	MutableRefObject,
} from "react";

import ProductAttributeGroups from "./ProductAttributeGroups";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { CartProduct, CreateCartAttributeData } from "../../../api/shop/cart/types";
import {
	Product,
	ProductCharacteristics,
	ProductModifier,
	ProductModifierOption,
	ProductModifiers,
} from "../../../api/shop/basic/types";
import { availableNoImageLanguages, ApiUrl } from "../../../config";
import classnames from "classnames";
import api from "../../../api";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import Carousel from "react-material-ui-carousel";
import useAppContext from "../../../useAppContext";
import { Box, Button, Grid, Tab, Tabs, Typography, useColorScheme } from "@mui/material";
import ProductPrice from "./ProductPrice";
import Share from "../../../features/Share";
import useScreenService from "../../../services/useScreenService";
import { SetState } from "../../../types";
import ChipButton from "../../../features/ChipButton";
import { AttributesRoot } from "./ProductAttributeGroups";
import ProductVariations from "./ProductVariations";
import useCartProduct from "../../services/useCartService/hooks/useCartProduct";
import ImageStub from "../../../features/ImageStub";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ProductCartMenu from "./ProductCartMenu";
import "./product.scss";
import useDetailedProductAnimations from "./hooks/useDetailedProductAnimations";
import { useShopContext } from "../../context";
import useFloatingSum, { IUseFloatingSumType } from "./hooks/useFloatingSum";
import ProductFloatingSumRoot from "./ProductFloating/ProductFloatingSumRoot";
import useProductGift, { IUseProductGiftType } from "./hooks/useProductGift";
import ProductGift from "./ProductGift/ProductGift";
import Interweave from "../../../features/Interweave";
import { FavoritesButton } from "./ProductCardButtons";
import ProductTopup from "./ProductTopup/ProductTopup";
import useProductTopup, { IUseProductTopup } from "./hooks/useProductTopup";
import useProductAttributes from "./hooks/useProductAttributes";
import useProductTabs from "./hooks/useProductTabs";
import useProductType, { IUseProductType } from "./hooks/useProductType";
import ProductFloatingQty from "./ProductFloating/ProductFloatingQty";
import { isIOS } from "../../../helpers";

import ProductGallery from "./ProductGallery";

type OnModifierClickType = (modifierId: number, option: string) => Promise<void>;

interface DetailedProductProps {
	isModal?: boolean;
	closeModal?: () => void;
}

export default function DetailedProduct({ isModal = false, closeModal }: DetailedProductProps) {
	const { localisation } = useAppContext();

	const [errorText, setErrorText] = useState("");

	const { product, cartProduct, isProductInCart } = useCartProduct();
	const { selectedAttributes, setSelectedAttributes, computedRequiredAttributesWarning } =
		useProductAttributes(product, setErrorText, cartProduct);

	const containerRef = useRef<HTMLDivElement | null>(null);
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [disabledMediaHeader, setDisabledMediaHeader] = useState<boolean>(false);

	const [productQuantity, setProductQuantity] = useState<number>(
		cartProduct?.quantity || product?.buy_min_quantity || 1
	);
	const floatingSumService = useFloatingSum(cartProduct);
	const topupService = useProductTopup(product, productQuantity, floatingSumService);

	const productType = useProductType(product, floatingSumService.floatingSumAmount);

	const scrollToTop = (timeout: number = 250) => {
		setTimeout(() => {
			if (containerRef.current) {
				containerRef.current.scrollIntoView({
					block: "start",
					inline: "nearest",
					behavior: "smooth",
				});
			}
		}, timeout);
	};

	useEffect(() => {
		if (product?.id) {
			setErrorText("");
			if (!isProductInCart) {
				setSelectedAttributes([]);
			}
		}
	}, [product?.id, isProductInCart, setSelectedAttributes]);

	useEffect(() => {
		computedRequiredAttributesWarning();
	}, [computedRequiredAttributesWarning]);

	useEffect(() => {
		const el = contentRef.current;
		const scrollContainerEl = document.getElementById("modal-or-swipeable-overflow-wrapper");
		if (el && scrollContainerEl) {
			if (el.clientHeight < scrollContainerEl.offsetHeight) {
				setDisabledMediaHeader(true);
			} else {
				setDisabledMediaHeader(false);
			}
		}
	}, [containerRef.current?.clientHeight]);

	if (!product) return null;

	return (
		<Box
			ref={containerRef}
			width={"100%"}
			height={"100%"}
			className={"shop-detailed-product"}
			display={"flex"}
			flexDirection={"column"}
			position={"sticky"}
		>
			<ProductMedia
				product={product}
				closeModal={closeModal}
				disabledMediaHeader={disabledMediaHeader}
			/>

			<Box pt={2} ref={contentRef}>
				{!product.is_available && (
					<div className={"text-danger mt-2 text-center"}>
						{localisation.cart.notAvailable}
					</div>
				)}

				<ProductContent
					product={product}
					scrollToTop={scrollToTop}
					productType={productType}
					floatingSumService={floatingSumService}
					topupService={topupService}
					selectedAttributes={selectedAttributes}
					cartProduct={cartProduct}
					isProductInCart={isProductInCart}
					productQty={productQuantity}
				/>

				{productType.computedNeedShowFloatingSum && product.floating_sum_settings && (
					<ProductFloatingSumRoot
						floatingSum={floatingSumService}
						floatingSumSettings={product.floating_sum_settings}
						cartProduct={cartProduct}
						product={product}
					/>
				)}

				{product.type === "gift" ? (
					<AttributesAndCartMenuComponentWithGift
						isModal={isModal}
						selectedAttributes={selectedAttributes}
						setSelectedAttributes={setSelectedAttributes}
						productQuantity={productQuantity}
						setProductQuantity={setProductQuantity}
						product={product}
						computedRequiredAttributesWarning={computedRequiredAttributesWarning}
						errorText={errorText}
						floatingSum={floatingSumService}
					/>
				) : (
					<AttributesAndCartMenuComponent
						isModal={isModal}
						selectedAttributes={selectedAttributes}
						setSelectedAttributes={setSelectedAttributes}
						productQuantity={productQuantity}
						setProductQuantity={setProductQuantity}
						product={product}
						computedRequiredAttributesWarning={computedRequiredAttributesWarning}
						errorText={errorText}
						floatingSum={floatingSumService}
						topupService={topupService}
					/>
				)}
			</Box>
		</Box>
	);
}

export type TabType = "description" | "characteristics" | "attributes";

interface TabPanelProps {
	tab: TabType;
	value: TabType;
	children: ReactNode;
}

function TabPanel({ tab, value, children }: TabPanelProps) {
	return (
		<div
			role={"tabpanel"}
			hidden={value !== tab}
			id={`tabpanel-${tab}`}
			aria-labelledby={`simple-tab-${tab}`}
			className={"w-100"}
		>
			{value === tab && (
				<Box pt={3} sx={{ color: "text.secondary" }}>
					{children}
				</Box>
			)}
		</div>
	);
}

interface IAttributeGroupsProps {
	selectedAttributes: CreateCartAttributeData[];
	product: Product | null;
	isModal: boolean;
	cartProduct: CartProduct | null;
	isProductInCart: boolean;
	setSelectedAttributes: SetState<CreateCartAttributeData[]>;
	productId: number | null;
}

export function AttributeGroups(props: IAttributeGroupsProps) {
	const { cartService } = useSelectedStoreContext();

	const checkIsAttrSelected = useCallback(
		(attributeId: number) => {
			return props.selectedAttributes.some(
				selectedAttr => selectedAttr.attribute_id === attributeId
			);
		},
		[props.selectedAttributes]
	);

	const { setSelectedAttributes } = props;
	const { addAttributeToProduct } = cartService;
	const selectAttribute = useCallback(
		(attributeId: number, quantity?: number) => {
			if (checkIsAttrSelected(attributeId)) {
				throw new Error(`Attribute ${attributeId} is already selected`);
			}

			if (props.isProductInCart && props.productId && props.cartProduct) {
				addAttributeToProduct(props.cartProduct.id, attributeId, quantity).then();
				setSelectedAttributes((prevState: CreateCartAttributeData[]) => [
					{
						attribute_id: attributeId,
						quantity: quantity || 1,
					},
					...prevState,
				]);
			} else {
				setSelectedAttributes((prevState: CreateCartAttributeData[]) => [
					{
						attribute_id: attributeId,
						quantity: quantity || 1,
					},
					...prevState,
				]);
			}
		},
		[
			checkIsAttrSelected,
			props.isProductInCart,
			props.productId,
			props.cartProduct,
			addAttributeToProduct,
			setSelectedAttributes,
		]
	);

	if (!props.product?.attribute_groups) return null;
	if (props.product?.attribute_groups && props.product?.attribute_groups.length < 1) return null;

	return (
		<ProductAttributeGroups
			product={props.product}
			cartProduct={props.cartProduct}
			isProductInCart={props.isProductInCart}
			selectedAttributes={props.selectedAttributes}
			setSelectedAttributes={props.setSelectedAttributes}
			selectAttribute={selectAttribute}
			unselectAttribute={(attributeId, needSelectAnotherAttrId) => {
				if (!checkIsAttrSelected(attributeId)) {
					throw new Error(`Attribute ${attributeId} is not selected`);
				}

				if (props.isProductInCart && props.cartProduct) {
					cartService
						.deleteAttributeFromProduct(props.cartProduct.id, attributeId)
						.then();
				}
				props.setSelectedAttributes((prevState: CreateCartAttributeData[]) =>
					prevState.filter((x: CreateCartAttributeData) => x.attribute_id !== attributeId)
				);

				if (needSelectAnotherAttrId) {
					if (props.isProductInCart && props.productId && props.cartProduct) {
						cartService
							.addAttributeToProduct(props.cartProduct.id, needSelectAnotherAttrId, 1)
							.then();
					}
					props.setSelectedAttributes((prevState: CreateCartAttributeData[]) => [
						{
							attribute_id: needSelectAnotherAttrId,
							quantity: 1,
						},
						...prevState,
					]);
				}
			}}
			setAttributeQuantity={(attributeId, quantity) => {
				if (!checkIsAttrSelected(attributeId)) {
					throw new Error(`Attribute ${attributeId} is not selected`);
				}

				if (props.isProductInCart && props.productId && props.cartProduct) {
					cartService
						.setProductAttrQty(props.cartProduct.id, attributeId, quantity)
						.then();
				}
				props.setSelectedAttributes((prevState: CreateCartAttributeData[]) =>
					prevState.map((selectedAttr: CreateCartAttributeData) => {
						if (selectedAttr.attribute_id !== attributeId) {
							return selectedAttr;
						} else {
							return {
								...selectedAttr,
								quantity: quantity,
							};
						}
					})
				);
			}}
			customClass={"product-modal-mods-wrapper"}
		/>
	);
}

interface CharacteristicsProps {
	characteristics: ProductCharacteristics;
}

function Characteristics({ characteristics }: CharacteristicsProps) {
	return (
		<Box width={"100%"}>
			{Object.entries(characteristics).map(([name, value]) => (
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					pb={3}
					mb={3}
					px={1}
					width={"100%"}
					borderBottom={1}
					borderColor={"borderColor"}
				>
					<Typography component={"span"} flex={1}>
						{name}
					</Typography>
					<span>{value}</span>
				</Box>
			))}
		</Box>
	);
}

interface ModifiersProps {
	modifiers: ProductModifiers;
	onModifierClick: OnModifierClickType;
	notAvailable?: boolean;
}

function Modifiers({ modifiers, onModifierClick, notAvailable }: ModifiersProps) {
	if (!Object.keys(modifiers).length) return null;

	return (
		<div className={"pt-2"}>
			{Object.values(modifiers).map((modifier, index) => (
				<Modifier
					key={modifier.id}
					modifier={modifier}
					needMarginTop={index > 0}
					onModifierClick={onModifierClick}
					notAvailable={notAvailable}
				/>
			))}
		</div>
	);
}

interface ModifierProps {
	modifier: ProductModifier;
	onModifierClick: OnModifierClickType;
	needMarginTop?: boolean;
	notAvailable?: boolean;
}

function Modifier({ modifier, needMarginTop, onModifierClick, notAvailable }: ModifierProps) {
	const { menu } = useLocalisation();

	return (
		<div className={classnames({ "mt-2": needMarginTop })}>
			<span className={"ps-1"}>
				{menu.modifierTitle
					.replace("{modifier_name}", modifier.name)
					.replace("{modifier_value}", modifier.value)}
			</span>
			<Grid container alignItems={"stretch"} spacing={2}>
				{modifier.options.map(option => (
					<Grid item xs={6} sm={4}>
						<ModifierItem
							key={option.value}
							option={option}
							modifier={modifier}
							onModifierClick={onModifierClick}
							notAvailable={notAvailable}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
}

interface ModifierItemProps {
	option: ProductModifierOption;
	modifier: ProductModifier;
	onModifierClick: OnModifierClickType;
	notAvailable?: boolean;
}

function ModifierItem({ option, modifier, onModifierClick, notAvailable }: ModifierItemProps) {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<ChipButton
			onClick={() => {
				setIsLoading(true);
				onModifierClick(modifier.id, option.orig_value).finally(() => {
					setIsLoading(false);
				});
			}}
			selected={isLoading || modifier.value === option.value}
			color={notAvailable ? "error" : "primary"}
			chipProps={{
				sx: {
					borderRadius: { xs: "1.2rem", sm: "1.5rem" },
					paddingX: "4px",
				},
			}}
			height={"100%"}
		>
			<Box
				display={"flex"}
				justifyContent={"center"}
				textAlign={"center"}
				alignItems={"center"}
				whiteSpace={"pre-wrap"}
				gap={1}
				height={"100%"}
			>
				<Typography variant={"body1"}>
					<span>{option.value}</span>
				</Typography>
			</Box>
		</ChipButton>
	);
}

export interface ProductMediaProps {
	product: Product;
	closeModal?: () => void;
	disabledMediaHeader: boolean;
}

function ProductMedia({ product, closeModal, disabledMediaHeader }: ProductMediaProps) {
	const { colorScheme } = useColorScheme();
	const mediaRef = useRef<HTMLDivElement | null>(null);
	const [isProductMediaVisible, setIsProductMediaVisible] = useState(true);

	useDetailedProductAnimations(mediaRef, isProductMediaVisible);

	useEffect(() => {
		const el = mediaRef.current;
		if (!el) return;

		const observer = new IntersectionObserver(
			([entry]) => {
				setIsProductMediaVisible(entry.isIntersecting);
			},
			{
				root: null,
				threshold: 0.5,
			}
		);
		observer.observe(el);

		return () => {
			observer.unobserve(el);
			observer.disconnect();
		};
	}, []);

	return (
		<>
			<ProductMediaBar
				imageUrl={product.image_url}
				product={product}
				show={!isProductMediaVisible && !disabledMediaHeader}
				closeModal={closeModal}
			/>
			<Box
				ref={mediaRef}
				position={"relative"}
				mt={"-74px"}
				id={"product-media-opacity-scroll"}
				borderBottom={1}
				borderColor={"divider"}
			>
				{!product.gallery ? (
					<>
						{!product.image_url ? (
							<Box
								width={"100%"}
								height={{
									xs: "calc(1rem + 20px + 14px)",
									md: "calc(1.5rem + 24px + 30px)",
								}}
								borderBottom={1}
								borderColor={colorScheme === "light" ? "grey.400" : "grey.600"}
							/>
						) : (
							<ProductMediaComponent
								product={product}
								media_type={"image"}
								media_url={product.image_url}
								needStub={false}
							/>
						)}
					</>
				) : (
					<ProductGallery product={product} disabledMediaHeader={disabledMediaHeader} />
					// <ProductCarousel product={product} disabledMediaHeader={disabledMediaHeader} />
				)}
			</Box>
		</>
	);
}

function ProductCarousel({ product }: ProductMediaProps) {
	const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);
	const videoRefs = useRef([]);
	const [videosRef, setVideosRef] = useState<any[]>([]);

	useEffect(() => {
		videoRefs.current = videoRefs.current.slice(0, product.gallery?.items.length);
	}, [product.gallery?.items.length]);

	if (!product.gallery) return null;

	const items: {
		id: number | string;
		media_type: string;
		media_url: string;
		position: number;
	}[] = [];

	if (product.image_url) {
		items.push({
			id: "product-image",
			media_type: "image",
			media_url: product.image_url,
			position: 0,
		});
	}

	product.gallery.items.forEach(item => {
		items.push(item);
	});

	if (!items.length) return null;

	if (!isFirstImageLoaded && !(items[0].media_type === "video")) {
		// noinspection JSUnusedGlobalSymbols
		return (
			<ProductMediaComponent
				key={"loading"}
				index={0}
				product={product}
				media_type={items[0].media_type}
				media_url={items[0].media_url}
				setVideosRef={setVideosRef}
				imgProps={{
					onLoad: () => {
						setIsFirstImageLoaded(true);
					},
				}}
			/>
		);
	}

	const handleSlideChange = (nextOrPrev: number | undefined, _: number | undefined) => {
		if (videosRef && videosRef.length > 0) {
			videosRef.forEach((ref: any) => {
				if (ref?.current) {
					ref?.current.pause();
				}
			});
			videosRef.forEach((ref: MutableRefObject<HTMLVideoElement>) => {
				if (ref?.current?.id === `video${nextOrPrev}`) {
					ref?.current.play().then(() => {
						if (!localStorage.getItem("videoSound")) {
							if (ref?.current?.muted || ref?.current?.muted === false)
								ref.current.muted = true;
						} else {
							if (ref?.current?.muted) ref.current.muted = false;
						}
					});
				}
			});
		}
	};

	return (
		<Carousel
			autoPlay={false}
			navButtonsAlwaysVisible
			duration={500}
			next={handleSlideChange}
			prev={handleSlideChange}
			navButtonsWrapperProps={{
				style: { pointerEvents: "none" },
			}}
			navButtonsProps={{
				style: { pointerEvents: "all" },
			}}
		>
			{items.map((item, index) => (
				<ProductMediaComponent
					key={"gallery-item-" + item.id}
					product={product}
					media_url={item.media_url}
					media_type={item.media_type}
					setVideosRef={setVideosRef}
					index={index}
				/>
			))}
		</Carousel>
	);
}

interface ProductMediaComponentProps {
	product: Product;
	media_type: string;
	media_url: string | null;
	needStub?: boolean;
	imgProps?: HTMLAttributes<HTMLImageElement>;
	imageRef?: MutableRefObject<HTMLDivElement | null>;
	setVideosRef?: (ref: MutableRefObject<HTMLVideoElement | null>[]) => void;
	index?: number;
}

function ProductMediaComponent({
	product,
	media_type,
	media_url,
	needStub,
	imageRef,
	setVideosRef,
	index,
	imgProps = {},
}: ProductMediaComponentProps) {
	const { lang } = useAppContext();
	const videoRef = useRef<HTMLVideoElement | null>(null);

	const computedProductImageStub = useMemo(() => {
		if (availableNoImageLanguages.includes(lang ?? ""))
			return `${ApiUrl}/static/images/store/no-image-${lang}.png`;
		return `${ApiUrl}/static/images/store/no-image.png`;
	}, [lang]);

	useEffect(() => {
		if (media_type === "video" && videoRef.current && setVideosRef) {
			// @ts-ignore
			setVideosRef((prev: MutableRefObject<HTMLVideoElement | null>[]) => [
				...prev,
				videoRef,
			]);
		}
	}, [media_type, setVideosRef]);

	useEffect(() => {
		const videoElement = videoRef.current;
		if (videoElement) {
			const handleVolumeChange = () => {
				if (videoElement.muted) {
					localStorage.removeItem("videoSound");
				} else {
					localStorage.setItem("videoSound", "1");
				}
			};

			videoElement.addEventListener("volumechange", handleVolumeChange);

			return () => {
				videoElement.removeEventListener("volumechange", handleVolumeChange);
			};
		}
	}, []);

	if (!media_url && !needStub) {
		return null;
	}

	if (!["image", "video"].includes(media_type)) {
		return null;
	}

	return (
		<div
			ref={imageRef}
			className={`media ${product.is_available ? "" : "opacity-65"}`}
			style={{
				paddingTop: isIOS() && videoRef?.current ? "53px" : "0px",
			}}
		>
			{!media_url || media_type === "image" ? (
				<img src={media_url || computedProductImageStub} alt={product.name} {...imgProps} />
			) : (
				<video
					id={`video${index || index === 0 ? index : media_url}`}
					ref={videoRef}
					controls
					autoPlay
					muted={!(localStorage.getItem("videoSound") && index === 0)}
					loop
					playsInline
					src={media_url}
				/>
			)}
		</div>
	);
}

interface IProductMediaBarProps {
	imageUrl: string | null;
	product: Product;
	show: boolean;
	closeModal?: () => void;
}

export function ProductMediaBar(props: IProductMediaBarProps) {
	return (
		<Box
			id={"product-modal-detailed-header"}
			position={"sticky"}
			top={0}
			height={"74px"}
			zIndex={9999}
			sx={{ background: "transparent" }}
			flexShrink={0}
			width={"100%"}
		>
			{props.show && (
				<Box
					id={"product-media-bar-background"}
					sx={{
						position: "absolute",
						backgroundColor: "background.paper",
						backgroundImage: "var(--mui-overlays-16)",
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
						boxShadow: "var(--mui-shadows-4)",
					}}
				></Box>
			)}
			<Box display={"flex"} position={"relative"} px={3} py={"10px"} alignItems={"center"}>
				<Box display={"flex"} id={"product-modal-detailed-header-content"}>
					{props.show && (
						<>
							<Box
								id={"product-media-bar-image"}
								height={"44px"}
								width={"44px"}
								borderRadius={"4px"}
							>
								{/*<AspectRatio ratio={'1/1'} objectFit={'contain'} sx={{borderRadius: '4px'}}>*/}
								{props.imageUrl ? (
									<ProductMediaBarImage
										src={props.imageUrl}
										alt={props.product.name}
									/>
								) : (
									<ImageStub emptyStub={false} ratio={[1, 1]} />
								)}
								{/*</AspectRatio>*/}
							</Box>
							<Box id={"product-media-bar-text"} ml={2} alignSelf={"center"}>
								<Typography fontWeight={"bold"}>{props.product.name}</Typography>
							</Box>
						</>
					)}
				</Box>

				<Box sx={{ ml: "auto" }}>
					<Button
						variant={"contained"}
						sx={{
							minWidth: "auto",
							borderRadius: "50px",
							padding: { xs: "8px", sm: "12px" },
							fontSize: { xs: "1.2rem", sm: "1.5rem" },
						}}
						onClick={props.closeModal}
					>
						<CloseIcon fontSize={"inherit"} />
					</Button>
				</Box>
			</Box>
		</Box>
	);
}

interface IAttributesAndCartMenuComponentProps {
	isModal?: boolean;
	selectedAttributes: CreateCartAttributeData[];
	setSelectedAttributes: SetState<CreateCartAttributeData[]>;
	productQuantity: number;
	setProductQuantity: SetState<number>;
	product: Product;
	computedRequiredAttributesWarning: () => boolean;
	errorText: string | null;
	floatingSum: IUseFloatingSumType;
	gift?: IUseProductGiftType | null;
	topupService?: IUseProductTopup;
}

function AttributesAndCartMenuComponent(props: IAttributesAndCartMenuComponentProps) {
	const { isEMenu } = useShopContext();
	const {
		localisation: { menu },
		authService: { user },
	} = useAppContext();
	const { cartService } = useSelectedStoreContext();
	const withAttrsBackground = "rgba(0, 0, 0, 0.015)";
	const attrsRef = useRef<HTMLDivElement | null>(null);
	const [isAttrGroupVisible, setIsAttrGroupVisible] = useState(false);
	const { isProductInCart, cartProduct } = useCartProduct();

	const computedCartMenuBackground = useMemo(() => {
		if (
			props.product.floating_sum_settings?.is_enabled ||
			props.product.type === "gift" ||
			props.product.type === "topup" ||
			props.product.floating_qty_enabled
		) {
			return withAttrsBackground;
		}
		if (
			!props.product.attribute_groups ||
			!props.product.attribute_groups.length ||
			!isAttrGroupVisible
		) {
			return "var(--mui-palette-background-paper)";
		}
		return withAttrsBackground;
	}, [
		isAttrGroupVisible,
		props.product.attribute_groups,
		props.product.floating_qty_enabled,
		props.product.floating_sum_settings?.is_enabled,
		props.product.type,
	]);

	useEffect(() => {
		const el = attrsRef.current;
		if (!el) return;

		const observer = new IntersectionObserver(
			([entry]) => {
				setIsAttrGroupVisible(entry.isIntersecting);
			},
			{
				root: null,
				threshold: 0,
			}
		);
		observer.observe(el);

		return () => {
			observer.unobserve(el);
			observer.disconnect();
		};
	}, []);

	const computedAttributesTitle = useMemo(() => {
		if (!isProductInCart || cartService.addingProductWithNewAttributesId)
			return menu.attributesHeader;
		return menu.attributesEditHeader;
	}, [
		cartService.addingProductWithNewAttributesId,
		isProductInCart,
		menu.attributesEditHeader,
		menu.attributesHeader,
	]);

	const computedAttributesSubTitle = useMemo(() => {
		if (cartService.addingProductWithNewAttributesId) return menu.attributesAddingNewSubHeader;
		if (!isProductInCart) return menu.attributesSubHeader;
		return menu.attributesEditSubHeader;
	}, [
		cartService.addingProductWithNewAttributesId,
		isProductInCart,
		menu.attributesAddingNewSubHeader,
		menu.attributesEditSubHeader,
		menu.attributesSubHeader,
	]);

	const computedNeedAttributes = useMemo(() => {
		return (
			props.product.attribute_groups &&
			props.product.attribute_groups.length > 0 &&
			props.product.type !== "info" &&
			props.product.type !== "gift" &&
			props.product.type !== "topup"
		);
	}, [props.product.attribute_groups, props.product.type]);

	const getCartProduct = cartService.getCartProduct;
	useEffect(() => {
		if (cartService.productVariationsState?.isAddToCart) {
			if (
				!cartService.productVariationsState ||
				!cartService.productVariationsState.needToChooseMode ||
				!getCartProduct(props.product.id)
			) {
				attrsRef.current?.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [
		cartService.productVariationsState,
		getCartProduct,
		props.product.id,
		props.product.attribute_groups,
	]);

	const setProductQuantity = props.setProductQuantity;
	useEffect(() => {
		if (cartService.addingProductWithNewAttributesId) {
			setProductQuantity(props.product.buy_min_quantity || 1);
		}
	}, [
		cartService.addingProductWithNewAttributesId,
		props.product.buy_min_quantity,
		setProductQuantity,
	]);

	return (
		<>
			{computedNeedAttributes && (
				<Box
					ref={attrsRef}
					sx={{ backgroundColor: withAttrsBackground }}
					px={3}
					className={"border-top"}
				>
					<ProductVariations product={props.product} />

					{(!cartService.productVariationsState ||
						!cartService.productVariationsState.needToChooseMode ||
						!cartService.getCartProduct(props.product.id)) && (
						<Box>
							<Box sx={{ pl: 1, mb: 1 }}>
								<Typography variant={"h6"} fontWeight={"bold"}>
									{computedAttributesTitle}
								</Typography>
								<Typography variant={"body2"} color={"text.secondary"} mb={2}>
									{computedAttributesSubTitle}
								</Typography>
							</Box>
							<AttributesRoot
								isModal={props.isModal}
								selectedAttributes={props.selectedAttributes}
								setSelectedAttributes={props.setSelectedAttributes}
							/>
						</Box>
					)}
				</Box>
			)}

			{props.product.type === "gift" && props.gift && (
				<Box
					ref={attrsRef}
					sx={{ backgroundColor: withAttrsBackground }}
					className={"border-top"}
				>
					<ProductGift
						gift={props.gift}
						product={props.product}
						floatingSum={props.floatingSum}
					/>
				</Box>
			)}

			{!!(props.product.type === "topup" && props.topupService) && (
				<ProductTopup service={props.topupService} floatingSum={props.floatingSum} />
			)}

			{props.product.floating_qty_enabled &&
				!props.product.floating_sum_settings?.is_enabled &&
				!computedNeedAttributes &&
				(props.product.type !== "topup" || (props.product.type === "topup" && !!user)) && (
					<Box sx={{ backgroundColor: withAttrsBackground }}>
						<ProductFloatingQty
							cartProduct={cartProduct}
							product={props.product}
							productQty={props.productQuantity}
							setProductQty={props.setProductQuantity}
							sx={{ px: "20px" }}
						/>
					</Box>
				)}

			{(!cartService.productVariationsState ||
				!cartService.productVariationsState.needToChooseMode ||
				!cartService.getCartProduct(props.product.id) ||
				!props.product.attribute_groups ||
				!props.product.attribute_groups.length) &&
				(!isEMenu || props.product.type === "info") && (
					<ProductCartMenu
						selectedAttributes={props.selectedAttributes}
						productQty={props.productQuantity}
						setProductQty={props.setProductQuantity}
						backgroundColor={computedCartMenuBackground}
						computedRequiredAttributesWarning={props.computedRequiredAttributesWarning}
						errorText={props.errorText}
						floatingSum={props.floatingSum}
						gift={props.gift}
						topupService={props.topupService}
					/>
				)}
		</>
	);
}

function AttributesAndCartMenuComponentWithGift(props: IAttributesAndCartMenuComponentProps) {
	const gift = useProductGift(props.product, props.productQuantity, props.floatingSum);

	return <AttributesAndCartMenuComponent {...props} gift={gift} />;
}

interface IProductTabs {
	product: Product | null;
}
function ProductTabs(props: IProductTabs) {
	const {
		groupService: { group },
	} = useAppContext();
	const { activeTab, availableTabs, setActiveTab } = useProductTabs(props.product);

	return (
		<>
			{availableTabs && availableTabs.length > 0 && (
				<Tabs
					sx={{ mt: 2 }}
					variant={"scrollable"}
					value={activeTab}
					selectionFollowsFocus
					onChange={(_, newValue) => setActiveTab(newValue)}
				>
					{availableTabs.map(tab => (
						<Tab
							key={tab}
							sx={{
								minWidth: "auto",
								px: "8px",
							}}
							value={tab}
							label={group?.texts.web.menu.product[`${tab}_tab_text`]}
						/>
					))}
				</Tabs>
			)}

			<Box width={"100%"}>
				{availableTabs.includes("description") && props.product?.type !== "info" && (
					<TabPanel tab={"description"} value={activeTab}>
						<Typography variant={"body2"} sx={{ pl: 1 }}>
							<Interweave className={"pb-4"} content={props.product?.description} />
						</Typography>
					</TabPanel>
				)}

				{availableTabs.includes("characteristics") && props.product?.characteristics && (
					<TabPanel tab={"characteristics"} value={activeTab}>
						<Characteristics characteristics={props.product.characteristics} />
					</TabPanel>
				)}
			</Box>
		</>
	);
}

interface IProductContent {
	product: Product | null;
	scrollToTop: (timeout?: number) => void;
	productType: IUseProductType;
	floatingSumService: IUseFloatingSumType;
	topupService: IUseProductTopup;
	selectedAttributes: CreateCartAttributeData[];
	cartProduct: CartProduct | null;
	isProductInCart: boolean;
	productQty: number;
}

function ProductContent(props: IProductContent) {
	const { localisation } = useAppContext();
	const { colorScheme } = useColorScheme();
	const { productsService, selectedStore, favoritesService } = useSelectedStoreContext();
	const { isMobile } = useScreenService();

	const priceBoxRef = useRef<HTMLDivElement | null>(null);

	const favoriteProduct = props.product
		? favoritesService.getFavoriteProduct(props.product.id)
		: null;
	const isInFavorites = !!favoriteProduct;

	const onModifierClick: OnModifierClickType = async (modifierId, option) => {
		if (!props.product) return;

		const filters = {
			[modifierId]: option,
			...Object.fromEntries(
				Object.values(props.product.modifiers)
					.filter(modifier => modifier.id !== modifierId)
					.map(modifier => [modifier.id, modifier.value])
			),
		};
		try {
			const response = await api.shop.basic.findProductModification(
				selectedStore.id,
				props.product.id,
				{
					find_for_characteristic_id: modifierId,
					filters: filters,
				}
			);
			productsService.selectProduct(response.data);
			props.scrollToTop();
		} catch (err) {}
	};

	const addOrRemoveFavoritesProduct = async () => {
		if (!props.product) return;

		if (isInFavorites)
			return await favoritesService.deleteProductFromFavorites(favoriteProduct.id);
		await favoritesService.addProductToFavorites(props.product.id);
	};

	if (!props.product) return <></>;

	return (
		<Box px={3} mb={2}>
			<Typography
				variant={isMobile ? "h5" : "h4"}
				lineHeight={"normal"}
				fontWeight={"bold"}
				display={"flex"}
				flexWrap={"wrap"}
				sx={{
					opacity: props.product.is_available ? 100 : 65,
				}}
			>
				<Interweave className={"me-1 flex-1"} content={props.product.name} />

				<div className={"fs-3 mt-1 d-flex"}>
					<FavoritesButton
						isInFavorites={isInFavorites}
						addOrRemoveFavoritesProduct={addOrRemoveFavoritesProduct}
					/>
					<Share
						copyDisabled={false}
						url={productsService.getProductLink(props.product.id)}
						title={props.product.name}
						description={props.product.description}
						customClass={"align-self-center ms-2"}
					/>
				</div>
			</Typography>

			{props.productType.computedNeedShowId && (
				<Typography
					variant={"body2"}
					color={colorScheme === "light" ? "grey.600" : "grey.400"}
				>
					<span>{localisation.menu.productIdText}</span>
					<span className={"ms-1"}>{props.product.product_id}</span>
				</Typography>
			)}

			{props.productType.computedNeedShowPrice && (
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					flexWrap={"wrap"}
					gap={2}
					mt={2}
					ref={priceBoxRef}
				>
					<Box>
						<ProductPrice
							productQty={props.productQty}
							product={props.product}
							cartProduct={props.cartProduct}
							isInCart={props.isProductInCart}
							variant={isMobile ? "h6" : "h5"}
							selectedAttributes={props.selectedAttributes}
							floatingSum={props.floatingSumService}
							forcedPrice={
								props.product.type === "topup"
									? props.topupService?.optionsService.computedAmountWithCharge
									: undefined
							}
							note={props.topupService.optionsService.computedChargeInfoString}
						/>
						{props.product.type === "gift" && (
							<Typography variant={"body2"} color={"text.secondary"} sx={{ pl: 1 }}>
								{localisation.global.bonuses}
							</Typography>
						)}
					</Box>
				</Box>
			)}

			{props.productType.computedNeedShowModifiers && props.product?.modifiers && (
				<Modifiers
					modifiers={props.product?.modifiers}
					onModifierClick={onModifierClick}
					notAvailable={!props.product?.is_available}
				/>
			)}

			{props.productType.computedNeedShowDescription && (
				<Box width={"100%"}>
					<Typography variant={"body2"} sx={{ pl: 1 }}>
						<Interweave className={"pb-4"} content={props.product?.description} />
					</Typography>
				</Box>
			)}

			<ProductTabs product={props.product} />
		</Box>
	);
}

const ProductMediaBarImage = styled("img")({
	borderRadius: 4,
	width: "100%",
	minHeight: "44px",
	objectFit: "contain",
});

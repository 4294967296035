import { useEffect, useMemo, useState } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import ProfileLayout from "../ProfileLayout";
import { ProfileTab } from "../types";
import useAppContext from "../../../useAppContext";
import api from "../../../api";
import { ILoyaltyInfo } from "../../../api/shop/loyalty/types";
import Divider from "@mui/material/Divider";
import useLocale from "../../../hooks/localisation/useLocale";
import useShare from "../../../features/hooks/useShare";
import { TgLink, ThemeModal } from "../../../helpers/ThemeComponents";
import { useShopContext } from "../../context";
import CollapsableComponent from "../../../features/CollapsableComponent";
import useScreenService from "../../../services/useScreenService";
import useIncustService from "../../../features/services/useIncustService";
import { IIncustSettings } from "../../loyalty/types";
import { ApiUrl } from "../../../config";
import ReferralWrapper from "../../loyalty/components/Referral/ReferralWrapper";
import ImageStub from "../../../features/ImageStub";
import MessangerIcon from "../../../features/MessangerIcon";
import ShareQrCode from "../../../components/ShareQrCode";
import Interweave from "../../../features/Interweave";
import { useSelectedStoreOptional } from "../../SelectedStore/context";
import useUserIncustCustomerData from "../../../hooks/incust/useUserIncustCustmerData";

export default function ProfileShareAndEarn() {
	const {
		brandInfo,
		authService: { user },
		botId,
		bot,
		lang,
	} = useAppContext();
	const selectedStore = useSelectedStoreOptional();

	const incustService = useIncustService(
		brandInfo?.id,
		selectedStore?.id,
		brandInfo?.incust_data,
		lang,
		""
	);

	const { customerData } = useUserIncustCustomerData();

	const [internalLoading, setInternalLoading] = useState<boolean>(false);
	const [referralCode, setReferralCode] = useState<string>("");
	const [loyaltyInfo, setLoyaltyInfo] = useState<ILoyaltyInfo | null>(null);

	const tab = ProfileTab.shareAndEarn;

	const baseUrl = window.location.origin;

	const computedShareUrl = useMemo(() => {
		if (selectedStore?.id) {
			return `${baseUrl}/shop/${selectedStore.id}/share_and_earn/${referralCode}`;
		}
		return `${baseUrl}/shop/share_and_earn/${referralCode}`;
	}, [baseUrl, referralCode, selectedStore?.id]);

	const computedBotQrUrl = useMemo(() => {
		if (!!botId && bot?.bot_type === "telegram") {
			let link = `https://t.me/${brandInfo?.bot_name}?start=referral-${referralCode}`;
			if (selectedStore?.id) {
				link += `store_id-${selectedStore.id}`;
			}
			return link;
		} else if (!!botId && bot?.bot_type === "whatsapp") {
			//TODO: add whatsapp referral link
			return "";
		}
		return "";
	}, [bot?.bot_type, botId, brandInfo?.bot_name, referralCode, selectedStore?.id]);

	const computedDescription = useMemo(() => {
		if (loyaltyInfo?.referral_program?.referrer_description) {
			return loyaltyInfo.referral_program?.referrer_description;
		}
		if (loyaltyInfo?.referral_program?.description) {
			return loyaltyInfo.referral_program?.description;
		}

		return "";
	}, [
		loyaltyInfo?.referral_program?.description,
		loyaltyInfo?.referral_program?.referrer_description,
	]);

	const computedTitle = useMemo(() => {
		if (loyaltyInfo?.referral_program?.referrer_title) {
			return loyaltyInfo.referral_program?.referrer_title;
		}
		if (loyaltyInfo?.referral_program?.title) {
			return loyaltyInfo.referral_program?.title;
		}

		return "";
	}, [loyaltyInfo?.referral_program?.referrer_title, loyaltyInfo?.referral_program?.title]);

	const computedPhoto = useMemo(() => {
		if (loyaltyInfo?.referral_program?.referrer_logo?.url) {
			return loyaltyInfo.referral_program?.referrer_logo.url;
		}

		if (
			incustService.settings &&
			!!incustService.settings.loyalty.photos &&
			incustService.settings.loyalty.photos.length > 0
		) {
			return incustService.settings.loyalty.photos[0];
		}
		return "";
	}, [incustService.settings, loyaltyInfo?.referral_program?.referrer_logo?.url]);

	useEffect(() => {
		if (brandInfo && customerData?.token) {
			setInternalLoading(true);
			api.shop.loyalty.getReferralCode(brandInfo.id).then(response => {
				setReferralCode(response.data.code || "");
				api.shop.loyalty
					.getLoyaltySettings()
					.then(response => setLoyaltyInfo(response.data))
					.finally(() => setInternalLoading(false));
			});
		} else {
			if (brandInfo) {
				setInternalLoading(true);
				api.shop.loyalty
					.getLoyaltySettings()
					.then(response => setLoyaltyInfo(response.data))
					.finally(() => setInternalLoading(false));
			}
		}
	}, [brandInfo, customerData?.token, lang]);

	return (
		<ProfileLayout tab={tab}>
			<MDBRow className={"justify-content-center"}>
				<MDBCol size={12} className={"text-start"}>
					<ReferralWrapper isChildLoading={internalLoading}>
						{!internalLoading && (
							<>
								{!user &&
									(computedPhoto ? (
										<img
											style={{ maxHeight: "14rem" }}
											src={computedPhoto}
											alt={computedTitle}
											className={"img-fluid mb-2"}
										/>
									) : (
										<ImageStub emptyStub={false} ratio={[1, 1]} />
									))}
								<ShareAndEarnHeader
									title={computedTitle}
									description={computedDescription}
								/>
								{!!user ? (
									<ShareAndEarnContentModal
										qrBotUrl={computedBotQrUrl}
										qrWebUrl={computedShareUrl}
										settings={incustService.settings}
									/>
								) : (
									<ShareAndEarnContent
										qrBotUrl={computedBotQrUrl}
										qrWebUrl={computedShareUrl}
										settings={incustService.settings}
									/>
								)}
							</>
						)}
					</ReferralWrapper>
				</MDBCol>
			</MDBRow>
		</ProfileLayout>
	);
}

interface IShareAndEarnContentProps {
	qrBotUrl: string | null;
	qrWebUrl: string;
	settings: IIncustSettings | null | undefined;
}

function ShareAndEarnContentModal(props: IShareAndEarnContentProps) {
	const {
		localisation: { profile },
	} = useAppContext();
	const [show, setShow] = useState<boolean>(false);

	return (
		<>
			<Button variant={"contained"} sx={{ mt: 2 }} onClick={() => setShow(true)}>
				{profile.shareAndEarn}
			</Button>
			<ThemeModal
				open={show}
				setOpen={setShow}
				title={profile.shareAndEarn}
				maxWidth={"xs"}
				classes={{
					paper: "w-100",
				}}
			>
				<ShareAndEarnContent
					qrBotUrl={props.qrBotUrl}
					qrWebUrl={props.qrWebUrl}
					settings={props.settings}
				/>
			</ThemeModal>
		</>
	);
}

function ShareAndEarnContent(props: IShareAndEarnContentProps) {
	const {
		botId,
		authService: { user, setShowAuthorisationModal },
		localisation: { profile },
		bot,
	} = useAppContext();
	const { webAppData } = useShopContext();

	const computedBotTypeName = useMemo(() => {
		if (bot?.bot_type) {
			return bot?.bot_type.charAt(0).toUpperCase() + bot?.bot_type.slice(1);
		}
		return "";
	}, [bot?.bot_type]);

	const computedLoyaltyPhoto = useMemo(() => {
		if (
			props.settings &&
			!!props.settings.loyalty.photos &&
			props.settings.loyalty.photos.length > 0
		) {
			return props.settings.loyalty.photos[0];
		}
		return `${ApiUrl}/static/images/default-404-image.png`;
	}, [props.settings]);

	return (
		<>
			{!!user ? (
				<>
					{!!botId && !!props.qrBotUrl && !!webAppData && (
						<>
							<ShareAndEarnContentItem
								qrText={profile.shareQrHeader}
								linkText={profile.shareMsgSubHeader.replace(
									"{messanger}",
									computedBotTypeName
								)}
								qrUrl={props.qrBotUrl}
								photo={computedLoyaltyPhoto}
							/>
							<Divider></Divider>
						</>
					)}

					{!webAppData && (
						<ShareAndEarnContentItem
							qrText={profile.shareQrHeader}
							linkText={profile.shareWebHeader}
							qrUrl={props.qrWebUrl}
							photo={computedLoyaltyPhoto}
						/>
					)}
				</>
			) : (
				<Box sx={{ mt: 3 }}>
					<TgLink
						className={"cursor-pointer"}
						onClick={() => setShowAuthorisationModal(true)}
					>
						{profile.needAuthForReferral}
					</TgLink>
				</Box>
			)}
		</>
	);
}

interface IShareAndEarnHeaderProps {
	title: string;
	description: string;
}

function ShareAndEarnHeader(props: IShareAndEarnHeaderProps) {
	const {
		localisation: { profile },
	} = useAppContext();
	const { isMobile } = useScreenService();

	return (
		<>
			{!!props.title && (
				<Typography variant={"h5"} fontWeight={"bold"}>
					{props.title}
				</Typography>
			)}

			<CollapsableComponent collapsedSize={isMobile ? 46 : 92} sx={{ width: "100%" }}>
				{!!props.description && (
					<Typography>
						<Interweave content={props.description.replaceAll("\n", "<br/>")} />
					</Typography>
				)}
			</CollapsableComponent>

			<Typography mt={3} fontStyle={"italic"}>
				{profile.shareHeader}
			</Typography>

			<Divider></Divider>
		</>
	);
}

interface IShareAndEarnContentItemProps {
	qrText: string;
	linkText: string;
	qrUrl: string;
	photo: string;
	qrWaUrl?: string;
}

export function ShareAndEarnContentItem(props: IShareAndEarnContentItemProps) {
	const { isShareAvailable, tryShare } = useShare();
	const { toastService } = useAppContext();
	const locale = useLocale("global");

	const handleCopyClickPar = (text: string) => {
		navigator.clipboard.writeText(text.toString()).then(() => {
			toastService.showToast({
				severity: "success",
				message: locale.copiedText,
			});
		});
	};

	const shareReferral = async () => {
		const blob = await fetch(props.photo).then(r => r.blob());

		const shareObj = {
			// title: profile.acceptInvitation,
			// text: props.qrUrl,
			files: [
				new File([blob], "referral.jpg", {
					type: blob.type,
				}),
			],
			url: props.qrUrl,
		};

		await tryShare(shareObj);
	};

	return (
		<Box>
			<Typography mt={0}>{props.linkText}</Typography>
			<Box mb={3} mt={2}>
				{isShareAvailable && (
					<ShareOutlinedIcon
						sx={{ mr: 1 }}
						color={"primary"}
						className={"cursor-pointer"}
						onClick={async () => await shareReferral()}
					/>
				)}
				<ContentCopyOutlinedIcon
					sx={{ mr: 2 }}
					color={"primary"}
					className={"cursor-pointer"}
					onClick={() => handleCopyClickPar(props.qrUrl)}
				/>

				<Link style={{ wordBreak: "break-word" }}>{props.qrUrl}</Link>
			</Box>

			<Typography my={2}>{props.qrText}</Typography>
			<ShareQrCode url={props.qrUrl} />

			{props.qrWaUrl && (
				<>
					<Typography mt={2}>{locale.msgAddFriendWa}</Typography>
					<Typography my={2}>
						<MessangerIcon botType={"whatsapp"} />
						<ContentCopyOutlinedIcon
							sx={{ mr: 2 }}
							color={"primary"}
							className={"cursor-pointer"}
							onClick={() => props.qrWaUrl && handleCopyClickPar(props.qrWaUrl)}
						/>

						<Link style={{ wordBreak: "break-word" }}>{props.qrWaUrl}</Link>
					</Typography>
				</>
			)}
		</Box>
	);
}

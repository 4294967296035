import useAppContext from "../useAppContext";
import QRCode from "qrcode.react";
import { Box, BoxProps } from "@mui/material";
import BluredQrCode from "../features/BluredQrCode";

interface ShareQrCodeProps {
	url: string;
	size?: number;
	blurred?: boolean;
	boxProps?: BoxProps;
}

export default function ShareQrCode(props: ShareQrCodeProps) {
	const {
		authService: { user, setShowAuthorisationModal },
		localisation: { auth },
	} = useAppContext();

	const size = props.size || 136;
	const { sx: boxSx, ...boxProps } = props.boxProps || {};

	return (
		<Box
			sx={{
				position: "relative",
				textAlign: "center",
				...boxSx,
			}}
			{...boxProps}
		>
			{!user || props.blurred ? (
				<BluredQrCode
					size={size}
					url={props.url}
					callback={() => setShowAuthorisationModal(true)}
					buttonText={auth.authorisationModalHeader}
				/>
			) : (
				<QRCode size={size} value={props.url} />
			)}
		</Box>
	);
}

import {useShopContext} from "../context";
import {Alert} from "@mui/material";
import useAppContext from "../../useAppContext";
import Interweave from "../../features/Interweave";


export default function ShopAutoTranslateWarning() {
    const {brandInfo} = useShopContext()
    const {localisation, lang} = useAppContext()

    if (
        !lang ||
        !brandInfo.is_translate ||
        lang === brandInfo.default_lang ||
        localisation.availableLangs.length <= 0 ||
        localisation.availableLangs.some(x => x.code === lang && !x.is_auto_translate)
    ) {
        return null
    }

    const computedToLang = (langCode: string) => {
        const sLang = localisation.availableLangs.find(x => x.code === langCode)
        return sLang?.original_name || sLang?.english_name || sLang?.code || "unknown"
    }

    return (
        <div className={'w-100'}>
            <Alert
                severity={'warning'}
                className={'w-100 mt-2 rounded-6'}
            >
                <Interweave content={
                    localisation.global.autoTranslateMsg
                    .replace(
                        "{orig_lang}",
                        computedToLang(brandInfo.default_lang)
                    )
                    .replace("{lang}", computedToLang(lang))
                } />
            </Alert>
        </div>
    )
}

import { AxiosInstance } from "axios";

import {
	CreateOrderInStorePayload,
	CreateOrderPayload,
	GetOrdersArguments,
	GetOrdersArgumentsCount,
	NotAvailableProduct,
	Order,
	SetFriendToInvoiceData,
	PaymentStatusResponse,
	UpdateOrder,
} from "./types";
import { CheckInvoiceStatus, Invoice } from "../../invoice/types";

export default function OrderAPIModule(instance: AxiosInstance) {
	const getPath = (path: string) => `store/order/${path}`;
	const getBasePath = (path: string) => `store/${path}`;

	const getHeaders = (token: string | null | undefined) =>
		token ? { Authorization: `Bearer ${token}` } : undefined;

	return {
		createOrder(data: CreateOrderPayload, cartToken: string | null | undefined = null) {
			let config: { headers?: any } = {};
			if (cartToken) config.headers = getHeaders(cartToken);
			return instance.post<Order>(getPath("create"), data, config);
		},
		updateOrder(
			data: UpdateOrder,
			orderId: number,
			cartToken: string | null | undefined = null
		) {
			let config: { headers?: any } = {};
			if (cartToken) config.headers = getHeaders(cartToken);
			return instance.post<Order>(getPath(`update/${orderId}`), data, config);
		},
		createOrderInStore(
			data: CreateOrderInStorePayload,
			cartToken: string | null | undefined = null
		) {
			let config: { headers?: any } = {};
			if (cartToken) config.headers = getHeaders(cartToken);
			return instance.post<Order>(getPath("createInStore"), data, config);
		},

		getOrders(args: GetOrdersArguments | GetOrdersArgumentsCount) {
			return instance.get(getPath("get_by_user"), { params: args });
		},

		getOrder(orderId: number, order_token?: string | null) {
			if (order_token) {
				return instance.get(getPath(`${orderId}`), { headers: getHeaders(order_token) });
			}
			return instance.get<Order>(getPath(`${orderId}`));
		},

		cancelOrder(orderId: number, token?: string | null | undefined) {
			return instance.post(
				getPath("cancel_order"),
				{
					order_id: orderId,
					order_status: "canceled",
				},
				{
					headers: getHeaders(token),
				}
			);
		},

		makeInvoice(brandId: number, orderId: number, token?: string | null | undefined) {
			return instance.post<Invoice>(getPath(`make_invoice/${orderId}`), null, {
				params: { brand_id: brandId },
				headers: getHeaders(token),
			});
		},

		setFriendToInvoice(data: SetFriendToInvoiceData) {
			const form = new FormData();

			form.set("friend_id", data.friendId.toString());
			if (data.orderId) {
				form.set("order_id", data.orderId.toString());
			}
			if (data.invoiceId) {
				form.set("invoice_id", data.invoiceId.toString());
			}
			if (data.friendComment) {
				form.set("friend_comment", data.friendComment.toString());
			}
			if (data.friendCommentMedia) {
				form.set("friend_comment_media", data.friendCommentMedia);
			}

			return instance.post<Invoice>(`payments/set_friend`, form);
		},

		sendInvoiceToBot(brandId: number, orderId: number) {
			return instance.post(getPath(`send_invoice_to_bot/${orderId}`), null, {
				params: { brand_id: brandId },
			});
		},

		getOrderPaymentStatus(orderId: number, token?: string | null | undefined) {
			return instance.get<PaymentStatusResponse>(getPath(`payment_status/${orderId}`), {
				headers: getHeaders(token),
			});
		},

		getInvoicePaymentStatus(token?: string | null | undefined, invoiceId?: number) {
			return instance.get<CheckInvoiceStatus>(`integration/payments/status`, {
				headers: getHeaders(token),
				params: { invoice_id: invoiceId },
			});
		},

		checkProductsAvailability(orderId: number, token?: string | null | undefined) {
			return instance.get<NotAvailableProduct[]>(
				getBasePath(`check_instock/order/${orderId}`),
				{ headers: getHeaders(token) }
			);
		},

		saveLastPersonalData(data: string) {
			localStorage.setItem(`lastPersonalData`, data);
		},

		getLastPersonalData(): { [key: string]: any } | null {
			const data = localStorage.getItem("lastPersonalData");
			if (!data) return null;
			return JSON.parse(data);
		},

		getOrderUserInfo(user_id: number, token?: string | null | undefined) {
			return instance.get(`/auth/user/friends/${user_id}`, {
				headers: getHeaders(token),
			});
		},
	};
}

import { useEffect, useRef } from "react";

import useDebounced from "./useDebounced";
import interpolateNumber from "../interpolateNumber";

interface InterpolationOption {
	triggerElId: string;
	triggerCheckElId?: string;
	offset?: number;

	boxShadowValue?: string;
	boxShadowVariable?: string;

	steps?: number;
	reverse?: boolean;
}

interface CalculatedOption {
	index: number;
	triggerElTop: number;
	triggerCheckElBottom: number;
	triggeredValue: number;
	triggered: boolean;

	shadowValue: string;

	steps: number;
	reverse: boolean;
}

interface useShadowInterpolationProps {
	offset?: number;
	elementId: string;
	options?: InterpolationOption[] | null;
}

export default function useShadowInterpolation(
	props: useShadowInterpolationProps,
	enabled: boolean = true
) {
	const optionsRef = useRef(props.options);

	const handleScroll = useDebounced(
		() => {
			const mainEl = document.getElementById(props.elementId);
			if (!mainEl) return;

			mainEl.style.boxShadow = "";
			const boxShadowOriginal = getComputedStyle(mainEl).boxShadow;
			const mailElRect = mainEl.getBoundingClientRect();

			const calculateBoxShadow = () => {
				if (props.offset && window.scrollY <= props.offset) {
					if (boxShadowOriginal !== "none") {
						return updateBoxShadowOpacity(0, boxShadowOriginal);
					}
					return boxShadowOriginal;
				}

				const options = optionsRef.current;
				if (!options?.length) return "none";

				const calculatedOptions: ReadonlyArray<CalculatedOption> = (
					options
						.map((option, index) => {
							const element = document.getElementById(option.triggerElId);
							if (!element) {
								return null;
							}

							const triggerElTop =
								element.getBoundingClientRect().top + (option.offset || 0);

							const triggerCheckEl = option.triggerCheckElId
								? document.getElementById(option.triggerCheckElId)
								: null;

							let triggerCheckElBottom: number;
							if (triggerCheckEl) {
								triggerCheckElBottom =
									triggerCheckEl.getBoundingClientRect().bottom;
							} else {
								triggerCheckElBottom = mailElRect.bottom;
							}

							const triggered = triggerCheckElBottom >= triggerElTop;

							const shadowValue = getBoxShadowValue(
								option.boxShadowValue,
								option.boxShadowVariable
							);

							return {
								index: index,
								triggerElTop: triggerElTop,
								triggerCheckElBottom: triggerCheckElBottom,
								triggered: triggered,
								triggeredValue: triggered ? triggerCheckElBottom - triggerElTop : 0,
								shadowValue: shadowValue,
								steps: option.steps || 50,
								reverse: option.reverse || false,
							};
						})
						.filter(x => x !== null) as Array<CalculatedOption>
				)
					.sort(x => x.triggerElTop)
					.reverse();

				for (const option of calculatedOptions) {
					if (option.triggered || option.index === 0) {
						return updateBoxShadowOpacity(
							option.triggeredValue,
							option.shadowValue,
							option.steps,
							option.reverse
						);
					}
				}
				if (boxShadowOriginal !== "none") {
					return updateBoxShadowOpacity(1, boxShadowOriginal, 1);
				}
				return boxShadowOriginal;
			};

			mainEl.style.boxShadow = calculateBoxShadow();
			mainEl.style.transition = "box-shadow 0s";
		},
		[props.elementId, props.offset],
		1,
		{
			maxWait: 250,
		}
	);

	useEffect(() => {
		optionsRef.current = props.options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(props.options)]);

	useEffect(() => {
		if (enabled) {
			window.addEventListener("scroll", handleScroll);
		}
		return () => window.removeEventListener("scroll", handleScroll);
	}, [enabled, handleScroll]);
}

const defaultBoxShadowVariable = "--mui-shadows-4";

function getBoxShadowValue(
	boxShadowValue?: string,
	boxShadowVariable: string = defaultBoxShadowVariable
) {
	const value =
		boxShadowValue ||
		getComputedStyle(document.documentElement).getPropertyValue(boxShadowVariable);
	const el = document.createElement("div");
	document.documentElement.appendChild(el);
	el.style.boxShadow = value;
	const computedValue = getComputedStyle(el).boxShadow;
	el.remove();
	return computedValue;
}

function updateBoxShadowOpacity(
	value: number,
	currentBoxShadow: string,
	steps: number = 1,
	reverse: boolean = false
): string {
	const rgbaMatches = currentBoxShadow.match(/rgba?\([^)]+\)/g);

	if (rgbaMatches) {
		return rgbaMatches.reduce((accumulator, rgbaValue) => {
			const [red, green, blue, alpha] = rgbaValue.match(/(\d(?:\.\d+)?)+/g) || [];

			if (red && green && blue) {
				const newOpacity = interpolateNumber(
					0,
					parseFloat(alpha) || 1,
					steps,
					value,
					reverse
				);

				const newRgba = `rgba(${red}, ${green}, ${blue}, ${newOpacity})`;
				return accumulator.replace(rgbaValue, newRgba);
			} else {
				console.error("Invalid rgba value:", rgbaValue);
				return accumulator;
			}
		}, currentBoxShadow);
	} else {
		console.error("Invalid box-shadow format");
		return currentBoxShadow;
	}
}

import { IUsePaymentsService } from "../hooks/useNewPaymentsService";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import BasePayButton from "../PaymentButtons/BasePayButton";
import PaymentItem from "./PaymentItem";
import { useMemo } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import useLocalisation from "../../../hooks/localisation/useLocalisation";

interface ISelectedPaymentProps {
	paymentService: IUsePaymentsService;
	totalAmount: number;
	currency: string | undefined;
	uniposDesc: string | null;
	selectedIncustAccount?: string | null;
	computedFee: string;
	computedFeeSum: string;
}

export default function SelectedPayment(props: ISelectedPaymentProps) {
	const localisation = useLocalisation();

	const computedDesc = useMemo(() => {
		if (props.paymentService.selectedProvider?.provider === "unipos" && props.uniposDesc) {
			return props.uniposDesc;
		}
		return props.paymentService.selectedProvider?.desc;
	}, [
		props.paymentService.selectedProvider?.desc,
		props.paymentService.selectedProvider?.provider,
		props.uniposDesc,
	]);

	return (
		<Box
			pb={
				props.paymentService.paymentsCollapseOpen &&
				props.paymentService.selectedProvider?.provider !== "incust_pay"
					? 2
					: 0
			}
		>
			<Box
				mt={3}
				mx={3}
				sx={{
					border: props.paymentService.selectedProvider ? 2 : 1,
					borderRadius: "8px",
					borderColor: props.paymentService.selectedProvider
						? "primary.main"
						: "text.secondary",
				}}
			>
				{props.paymentService.selectedProvider ? (
					<PaymentItem
						method={props.paymentService.selectedProvider}
						isLast={true}
						totalAmount={props.totalAmount}
						currency={props.currency}
						setSelectedProvider={props.paymentService.setSelectedProvider}
						setCollapse={() =>
							props.paymentService.setPaymentsCollapseOpen(
								!props.paymentService.paymentsCollapseOpen
							)
						}
						paymentsService={props.paymentService}
						isSelectItem={true}
						isCollapsed={props.paymentService.paymentsCollapseOpen}
						payButtonMaxHeight={null}
						setPayButtonMaxHeight={(_: number) => {}}
						selectedIncustAccount={props.selectedIncustAccount}
					/>
				) : (
					<Tooltip
						enterTouchDelay={0}
						title={localisation.orders.payChose}
						placement={"top"}
					>
						<span>
							<BasePayButton
								onClick={() => props.paymentService.setPaymentsCollapseOpen(true)}
								sx={{ borderBottom: 0, px: 3, py: 1.3 }}
								loading={false}
								isSelectedItem={true}
								isCollapsed={props.paymentService.paymentsCollapseOpen}
								disabled={true}
							>
								{localisation.payment.selectPayment}
							</BasePayButton>
						</span>
					</Tooltip>
				)}
			</Box>

			{!!(
				props.paymentService.selectedProvider &&
				props.paymentService.selectedProvider.provider !== "incust_pay" &&
				(computedDesc ||
					props.paymentService.selectedProvider.fee_value ||
					props.paymentService.selectedProvider.fee_percent)
			) && (
				<SelectedPaymentFeeAndDesc
					computedFee={props.computedFee}
					computedFeeSum={props.computedFeeSum}
					computedDesc={computedDesc}
					notShow={props.paymentService.paymentsCollapseOpen}
				/>
			)}
		</Box>
	);
}

interface ISelectedPaymentFeeAndDescProps {
	computedFee: string;
	computedFeeSum: string;
	computedDesc?: string | null;
	mx?: number;
	mt?: number;
	notShow?: boolean;
}

export function SelectedPaymentFeeAndDesc(props: ISelectedPaymentFeeAndDescProps) {
	const localisation = useLocalisation();

	if (props.notShow) return <></>;

	return (
		<>
			<Box
				sx={{ border: 1, borderRadius: "8px", borderColor: "divider" }}
				mt={props.mt || props.mt === 0 ? props.mt : 3}
				mx={props.mx || props.mx === 0 ? props.mx : 3}
			>
				<Box px={2} py={1.3}>
					{props.computedDesc && (
						<Box mb={props.computedFee ? 2 : 0}>
							<Typography
								variant={"body1"}
								fontWeight={"bold"}
								color={"text.secondary"}
							>
								{props.computedDesc}
							</Typography>
						</Box>
					)}
					{props.computedFee && (
						<Box display={"flex"} alignItems={"center"}>
							<Typography
								variant={"body1"}
								color={"text.secondary"}
								sx={{ pr: 1, lineHeight: 1 }}
							>
								{localisation.orders.payerFeeOrderListText}: {props.computedFeeSum}
							</Typography>
							<Tooltip title={props.computedFee} enterTouchDelay={0}>
								<IconButton
									onClick={() => {}}
									color="primary"
									sx={{ p: 0, fontSize: 2 }}
									aria-label={props.computedFee}
								>
									<HelpOutlineIcon width={"0.85em"} height={"0.85em"} />
								</IconButton>
							</Tooltip>
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
}

import React, {useState} from "react";

import {useShopContext} from "../context";
import api from "../../api";
import {Review} from "../../api/reviews/types";
import {Alert, Box, Button, Rating} from "@mui/material";
import useAppContext from "../../useAppContext";
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import Interweave from "../../features/Interweave";


interface ReviewComponentProps {
    needClose?: boolean
    onClose?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
}


export default function ReviewComponent(props: ReviewComponentProps){
    const {
        localisation,
        showError,
        authService: {
            user,
            isLoading: isAuthLoading,
            setShowAuthorisationModal,
        },
        groupService: {group},
    } = useAppContext()
    const {menuInStoreService} = useShopContext()

    const [isLoading, setIsLoading] = useState(false)
    const [rating, setRating] = useState(0)
    const [reviewText, setReviewText] = useState('')
    const [isSent, setIsSent] = useState(false)
    const [review, setReview] = useState<Review | null>(null)

    const handleReviewTextChange = (event: any) => setReviewText(event.target.value)

    const isValidReview = () => !!(rating && reviewText)

    const sendReview = async() => {
        if(!isValidReview()) return

        setIsLoading(true)
        try{
            const response =  await api.reviews.makeReview({
                review: {stars: rating},
                text: reviewText,
                menu_in_store_id: menuInStoreService.menuInStore?.id,
            })
            setReview(response.data)
            setIsSent(true)
        }
        catch(ex){
            showError(ex)
        }
        finally{
            setIsLoading(false)
        }
    }

    return (
        <Box>
            <div className="px-2 text-start">
                {isSent && review
                    ?
                        <>
                            <div>
                                {localisation.qrMenu.reviewSuccess}
                            </div>
                            <div className={'mt-2'}>
                                <Interweave
                                    content={
                                        localisation.qrMenu.reviewSuccessMsg
                                            .replace("{stars}", review.review.stars)
                                            .replace("{comment}", review.text)
                                    }
                                />
                            </div>
                        </>
                    :
                    <>
                        <div>
                            {localisation.qrMenu.stars}
                        </div>
                        <div className={'fs-3 shop-rating'}>
                            <Rating
                                disabled={!user}
                                value={rating}
                                onChange={(_, newValue) => {
                                    setRating(newValue || 0);
                                }}
                            />
                        </div>

                        <div className={'mt-2'}>
                            <TextField
                                multiline
                                fullWidth
                                disabled={!user}
                                label={localisation.qrMenu.reviewLabel}
                                value={reviewText}
                                onChange={handleReviewTextChange}
                                className={'tg-input'}
                            />
                        </div>
                    </>
                }
                {!user && !isAuthLoading && (
                    <Alert severity={'error'} sx={{mt: 3}} action={(
                        <Button
                            color={'inherit'}
                            onClick={() => {
                                setShowAuthorisationModal(true)
                            }}
                        >
                            {localisation.auth.loginRegisterButton}
                        </Button>
                    )}>
                        {group?.texts.review.authorisation_required_text}
                    </Alert>
                )}
            </div>
            <div className="text-end px-2 my-3">
                {props.needClose && (
                    <Button
                        onClick={props.onClose}
                        disabled={isLoading}
                    >
                        {localisation.global.close}
                    </Button>
                )}
                {!isSent &&
                    <LoadingButton
                        loading={isLoading}
                        variant={'contained'}
                        onClick={sendReview}
                        disabled={!isValidReview()}
                        className={'ms-2'}
                    >
                        {localisation.global.send}
                    </LoadingButton>
                }
            </div>
        </Box>
    )
}

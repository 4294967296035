import { Fragment, ReactNode, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import { Alert, Box, Button, CardActions, CircularProgress, Grid, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import formatCurrency from "../helpers/formatCurrency";
import { IIncustCheck } from "../shop/loyalty/types";
import useAppContext from "../useAppContext";
import { IIncustCouponShowData } from "../api/shop/loyalty/types";
import { downloadFromBase64 } from "./DownloadBase64";
import { getPDFCouponBase64 } from "./services/useIncustService/functions";
import CardMediaAspectRatio from "./CardMediaAspectRatio";
import useScreenService from "../services/useScreenService";
import formatNumber from "../helpers/formatNumber";
import useCalculatedSpecialAccountsCharges from "../shop/loyalty/useCalculatedSpecialAccountsCharges";
import SpecialAccountChargeTitle from "../shop/loyalty/SpecialAccountChargeTitle";
import Interweave from "./Interweave";
import useLocalisation from "../hooks/localisation/useLocalisation";

export interface ITotalItem {
	name: string;
	price?: number;
	value?: number | string;
	count?: number;
	isPrice?: boolean;
	isFinal?: boolean;
	isAfterFinal?: boolean;
	beforeValueSymbol?: string;
	nameNode?: ReactNode;
	isBold?: boolean;
	isBorder?: boolean;
}

export interface ITotalBaseProps {
	incustCheck?: IIncustCheck | null;
	payed?: boolean;
	lang: string | null;
	currency: string;
	showLoyaltyVouchers?: boolean;
	incust_vouchers?: IIncustCouponShowData[] | null;
}

export interface ITotalProps extends ITotalBaseProps {
	items: ITotalItem[];
	alwaysShowCount?: boolean;
	children?: ReactNode;
	payer_fee?: string | null;
	paid_sum?: string | null;
}

export default function Total(props: ITotalProps) {
	const localisation = useLocalisation();
	const { brandInfo } = useAppContext();
	const afterFinalItems = props.items.filter((item: ITotalItem) => item.isAfterFinal);
	const finalItem = props.items.find((item: ITotalItem) => item.isFinal);

	if (!props.items.length) return null;

	return (
		<>
			{props.items.map(
				(item, index) =>
					!item.isFinal &&
					!item.isAfterFinal && (
						<RegularTotalItem
							key={index}
							item={item}
							index={index}
							lang={props.lang}
							currency={props.currency}
							alwaysShowCount={props.alwaysShowCount}
						/>
					)
			)}

			{props.children && props.children}

			{!!finalItem && (
				<div className={"d-flex border-top fs-6"}>
					<div className={"fw-bold"}>
						<Interweave content={finalItem.name} />
					</div>
					<div className={`ms-auto fw-bold`}>
						{(finalItem.value || finalItem.value === 0) &&
							(finalItem.isPrice ? (
								formatCurrency(
									finalItem.value,
									brandInfo?.default_lang || props.lang,
									props.currency
								)
							) : (
								<Interweave content={finalItem.value.toString()} />
							))}
					</div>
				</div>
			)}

			{!!props.payer_fee && (
				<>
					<div className={"d-flex border-top fs-6"}>
						<div>
							<Interweave content={localisation.orders.payerFeeText} />
						</div>
						<div className={`ms-auto`}>{props.payer_fee}</div>
					</div>
					<div className={"d-flex border-top fs-6"}>
						<div className={"fw-bold"}>
							<Interweave content={localisation.orders.payedLabel} />:
						</div>
						<div className={`ms-auto fw-bold`}>{props.paid_sum}</div>
					</div>
				</>
			)}

			{!!afterFinalItems.length && (
				<div className="my-2">
					{afterFinalItems.map((item, index) => (
						<div className={"d-flex fs-6"} key={item.name + index}>
							<div>
								<Interweave content={item.name} />
							</div>
							<div className={"ms-auto"}>
								{(item.value || item.value === 0) &&
									(item.isPrice ? (
										formatCurrency(
											item.value,
											brandInfo?.default_lang || props.lang,
											props.currency
										)
									) : (
										<Interweave content={item.value.toString()} />
									))}
							</div>
						</div>
					))}
				</div>
			)}

			{props.incustCheck && (
				<div className="mt-3">
					<TotalLoyaltyAwards
						incustCheck={props.incustCheck}
						lang={props.lang}
						currency={props.currency}
						payed={props.payed}
						showLoyaltyVouchers={props.showLoyaltyVouchers}
						incust_vouchers={props.incust_vouchers}
					/>
				</div>
			)}
		</>
	);
}

interface IRegularTotalItemProps {
	item: ITotalItem;
	index: number;
	lang: string | null;
	currency: string;
	alwaysShowCount?: boolean;
}

function RegularTotalItem(props: IRegularTotalItemProps) {
	const { brandInfo } = useAppContext();
	return (
		<div
			className={`d-flex ${props.item.isBold ? "fw-bold" : ""} ${props.item.isBorder ? "border-bottom" : ""}`}
			key={props.item.name + props.index}
		>
			<div>
				<Interweave content={props.item.name} />
				<Typography ml={1} component={"span"}>
					{props.item.count && props.item.count > 1 && "x " + props.item.count}
				</Typography>

				{props.item.nameNode && props.item.nameNode}
			</div>
			<div className={"ms-auto"}>
				{props.item.value &&
					(props.item.isPrice ? (
						<Box textAlign={"end"}>
							<Typography fontWeight={props.item.isBold ? "bold" : ""}>
								{props.item.beforeValueSymbol && props.item.beforeValueSymbol}
								{formatCurrency(
									props.item.value,
									brandInfo?.default_lang || props.lang,
									props.currency
								)}
							</Typography>
							{(props.alwaysShowCount ||
								(props.item.count && props.item.count > 1)) &&
								props.item.price && (
									<Typography variant={"body2"} color={"text.secondary"} mb={1}>
										<small>
											{`${props.item.count} x ${formatCurrency(
												props.item.price,
												brandInfo?.default_lang || props.lang,
												props.currency
											)}`}
										</small>
									</Typography>
								)}
						</Box>
					) : (
						<Interweave content={props.item.value.toString()} />
					))}
			</div>
		</div>
	);
}

export function TotalLoyaltyAwards(props: ITotalBaseProps) {
	const { localisation } = useAppContext();

	if (
		!props.incustCheck ||
		(!props.incustCheck.bonuses_added_amount &&
			!props.incustCheck.emitted_coupons?.length &&
			!props.incustCheck.special_accounts_charges?.length)
	)
		return null;

	return (
		<>
			<CustomerAccounts
				lang={props.lang}
				payed={props.payed}
				incustCheck={props.incustCheck}
				currency={props.currency}
			/>

			{!!(
				props.incustCheck.bonuses_added_amount || props.incustCheck.emitted_coupons?.length
			) && (
				<>
					<Typography
						variant={"subtitle2"}
						fontWeight={"bold"}
						mt={2 * +!!props.incustCheck.special_accounts_charges?.length}
					>
						{props.payed
							? localisation.check.loyaltyIssuedPerOrderText
							: localisation.check.loyaltyToBeIssuedPerPaymentText}
					</Typography>

					<Bonuses
						incustCheck={props.incustCheck}
						payed={props.payed}
						lang={props.lang}
						currency={props.currency}
					/>

					<Coupons
						incustCheck={props.incustCheck}
						payed={props.payed}
						lang={props.lang}
						showLoyaltyVouchers={props.showLoyaltyVouchers}
						incust_vouchers={props.incust_vouchers}
					/>
				</>
			)}

			{!!(props.payed && props.incustCheck?.amount_to_pay === 0) && (
				<Alert severity={"success"} sx={{ mt: 2 }}>
					{localisation.global.fullPaidByBonuses}
				</Alert>
			)}
		</>
	);
}

interface IBonusesProps {
	incustCheck: IIncustCheck;
	payed?: boolean;
	lang: string | null;
	currency: string;
}

function Bonuses(props: IBonusesProps) {
	const { localisation, brandInfo } = useAppContext();

	if (!props.incustCheck.bonuses_added_amount) return null;

	return (
		<Typography variant={"body2"}>
			{props.payed
				? localisation.profile.loyaltyBonusesAwardedAfter + ": "
				: localisation.profile.loyaltyBonusesAwarded + ": "}
			<b>
				{formatNumber(
					props.incustCheck.bonuses_added_amount,
					brandInfo?.default_lang || props.lang
				)}
			</b>
		</Typography>
	);
}

interface ICouponsProps {
	incustCheck: IIncustCheck;
	payed?: boolean;
	lang: string | null;
	showLoyaltyVouchers?: boolean;
	incust_vouchers?: IIncustCouponShowData[] | null;
}

function Coupons(props: ICouponsProps) {
	const { localisation } = useAppContext();

	const computedCouponsHeader = useMemo(() => {
		if (props.payed && !props.showLoyaltyVouchers) {
			return localisation.profile.loyaltyCouponsAwardedAfter + ":";
		}

		if (props.payed && props.showLoyaltyVouchers) {
			return localisation.profile.loyaltyVouchersAwardedAfter + ":";
		}

		if (!props.payed && !props.showLoyaltyVouchers) {
			return localisation.profile.loyaltyCouponsAwarded + ":";
		}

		if (!props.payed && props.showLoyaltyVouchers) {
			return localisation.profile.loyaltyVouchersAwarded + ":";
		}

		return "";
	}, [
		localisation.profile.loyaltyCouponsAwarded,
		localisation.profile.loyaltyCouponsAwardedAfter,
		localisation.profile.loyaltyVouchersAwarded,
		localisation.profile.loyaltyVouchersAwardedAfter,
		props.payed,
		props.showLoyaltyVouchers,
	]);

	return (
		<>
			{!!props.incustCheck.emitted_coupons?.length && !props.incust_vouchers && (
				<Typography variant={"body2"}>
					{computedCouponsHeader} <b>{props.incustCheck.emitted_coupons.length}</b>
				</Typography>
			)}

			{!!(props.incust_vouchers && props.incust_vouchers.length > 0) && (
				<Box>
					<Typography variant={"body2"}>{computedCouponsHeader}</Typography>

					<Grid container spacing={2}>
						{props.incust_vouchers.map(
							(voucher: IIncustCouponShowData) =>
								voucher.url &&
								voucher.title && (
									<Grid item sm={6} className={"w-100"} key={voucher.title}>
										<VoucherCard lang={props.lang} voucher={voucher} />
									</Grid>
								)
						)}
					</Grid>
				</Box>
			)}
		</>
	);
}

interface IVoucherCardProps {
	voucher: IIncustCouponShowData;
	lang: string | null;
}

function VoucherCard(props: IVoucherCardProps) {
	const { localisation } = useAppContext();
	const { isMobile } = useScreenService();
	const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);

	const handleDownloadVoucher = async (voucher: IIncustCouponShowData) => {
		setIsPdfLoading(true);
		try {
			if (voucher.pdf_url && props.lang) {
				const fileName = `${voucher.code}.pdf`;
				const pdf_base64 = await getPDFCouponBase64(voucher.pdf_url, props.lang);
				if (pdf_base64) downloadFromBase64(pdf_base64, fileName);
			}
		} finally {
			setIsPdfLoading(false);
		}
	};

	const handleShow = (url: string) => {
		window.open(url, "_blank");
	};

	return (
		<Card
			className={"no-shadow border mt-1"}
			key={props.voucher.title}
			sx={{ display: "flex" }}
		>
			<div style={{ width: "auto", minWidth: "4rem" }}>
				{props.voucher.image && (
					<CardMediaAspectRatio
						aspectRatio={"1/1"}
						imageSrc={props.voucher.image}
						alt={props.voucher.title || ""}
						sx={{ flex: "1 0 auto" }}
					/>
				)}
			</div>

			<div style={{ width: "100%" }}>
				<Box
					className={"white-space-nowrap"}
					component="div"
					sx={{
						textOverflow: "ellipsis",
						overflow: "hidden",
						fontSize: "1rem",
						fontWeight: "600",
						p: 2,
						pb: 0,
						pt: 1,
						maxWidth: isMobile ? "15rem" : "12rem",
					}}
				>
					{props.voucher.title}
				</Box>

				<CardActions disableSpacing={true} className={`d-flex m-0 p-0 text-start mt-2`}>
					{!!props.voucher.pdf_url && (
						<div className={"ps-1"}>
							{isPdfLoading ? (
								<div className={"mx-1"}>
									<CircularProgress style={{ width: "14px", height: "14px" }} />
								</div>
							) : (
								<DownloadIcon
									onClick={() => handleDownloadVoucher(props.voucher)}
									className={"cursor-pointer"}
									color={"primary"}
									style={{ width: "20px", height: "20px" }}
								/>
							)}
						</div>
					)}

					{!!props.voucher.url && (
						<Button
							className={"w-100 m-0 p-0 ps-2"}
							onClick={() => handleShow(props.voucher.url || "")}
							size={"small"}
							style={{ justifyContent: "flex-start", fontSize: "0.675rem" }}
						>
							{localisation.global.showCoupon}
						</Button>
					)}
				</CardActions>
			</div>
		</Card>
	);
}

interface CustomerAccountsProps {
	incustCheck: IIncustCheck;
	payed?: boolean;
	lang: string | null;
	currency: string | null;
}

function CustomerAccounts(props: CustomerAccountsProps) {
	const { localisation, brandInfo } = useAppContext();

	console.log("props.incustCheck", props.incustCheck);

	const specialAccountsCharges = useCalculatedSpecialAccountsCharges(
		props.incustCheck.special_accounts_charges
	);

	if (!specialAccountsCharges?.length) return null;

	return (
		<>
			<Typography variant={"subtitle2"} fontWeight={"bold"}>
				{props.payed
					? localisation.check.loyaltyCustomerAccountsText
					: localisation.check.loyaltyCustomerAccountsToBeReplenishedText}
			</Typography>
			<Typography variant={"body2"}>
				{specialAccountsCharges.map((special, idx) => (
					<Fragment key={special.id}>
						{special.title || (
							<SpecialAccountChargeTitle specialAccountCharge={special} idx={idx} />
						)}
						{": "}
						<b>
							{formatCurrency(
								special.amount,
								brandInfo?.default_lang || props.lang,
								special.currency || props.currency
							)}
						</b>
						<br />
					</Fragment>
				))}
			</Typography>
		</>
	);
}

import useLocalisation from "../hooks/localisation/useLocalisation"
import useWorkingTimesService from "./services/useWorkingTimesService";
import {Alert} from "@mui/material";
import Interweave from "../features/Interweave";

export default function ShopClosedAlert(){
    const localisation = useLocalisation()
    const workingTime = useWorkingTimesService()

    if (!workingTime.isShopClosed) return null

    const getMessage = () => {
        if(workingTime.workingTimes?.current?.includes("pass")){
            return localisation.global.shopWeekend
        }
        else {
            return localisation.global.closedMessage
                .replace('{time}', workingTime.workingTimes?.current || '')
        }
    }

    return (
        <Alert
            severity={'warning'}
            variant={'filled'}
            className={'w-100 mb-2 rounded-6'}
        >
            <Interweave content={getMessage()} />
        </Alert>
    )
}

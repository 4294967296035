import {TgLinkProps} from "./types";
import {Link} from "@mui/material";

export default function TgLink({children, href='#', onClick, ...props}: TgLinkProps) {
    return (
        <Link
            href={href}
            onClick={(e) => {
                if (onClick) {
                    if (href === '#') {
                        e.preventDefault()
                    }
                    onClick(e)
                }
            }}
            {...props}
        >
            {children}
        </Link>
    )
}

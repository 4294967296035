import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import api from "../../../../api";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import Friends from "../../../friends/Friends";
import useAppContext from "../../../../useAppContext";
import { IFriend } from "../../../../api/shop/friends/types";
import UploadFileButton from "../../../../features/UploadFileButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SelectedFilePreview from "../../../../features/FileInputPreview";
import { PaymentPayload } from "../../../../api/shop/payments/types";
import { IUsePaymentsService } from "../../../payments/hooks/useNewPaymentsService";

interface ISelectFriendProps {
	orderId?: number | null;
	invoiceId?: number | null;
	onSelectFriendEnd: (selectedFriend: IFriend) => any;
	paymentService: IUsePaymentsService;
}

export default function SelectFriend(props: ISelectFriendProps) {
	const { localisation, friendsService } = useAppContext();
	const [selectedFriend, setSelectedFriend] = useState<IFriend | null>(null);
	const [friendComment, setFriendComment] = useState<string>("");
	const [friendCommentMedia, setFriendCommentMedia] = useState<File | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const handleRadioChange = (user_id: number) => {
		const selectedFriend = friendsService.friends.find(obj => obj.user_id === user_id);
		if (selectedFriend?.sending_possible) {
			setSelectedFriend(selectedFriend);
		}
	};

	const handleSelectFriendButtonClick = async () => {
		if (selectedFriend) {
			setIsLoading(true);
			try {
				await props.paymentService.makePrePayment();
				await api.shop.order.setFriendToInvoice({
					friendId: selectedFriend.user_id,
					orderId: props.orderId,
					invoiceId: props.invoiceId,
					friendComment: friendComment,
					friendCommentMedia: friendCommentMedia,
				});
				props.onSelectFriendEnd(selectedFriend);
			} catch (ex: any) {
				setError(ex.response?.data?.detail || ex.message || "unknown error");
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			<Box mt={2} gap={1}>
				<Friends
					mode={"select"}
					disableEmptyText={!friendsService.friends.length}
					checkedFriendUserId={selectedFriend?.user_id}
					handleSelectFriendClick={handleRadioChange}
					header={
						<Typography variant={"h6"}>
							{friendsService.friends.length
								? localisation.orders.friendSelect
								: localisation.orders.friendSelectNoFriends}
							:
						</Typography>
					}
				/>

				{!!friendsService.friends.length && (
					<>
						<TextField
							multiline
							minRows={1}
							maxRows={10}
							fullWidth
							label={localisation.orders.commentFriendLabel}
							value={friendComment || ""}
							onChange={event => setFriendComment(event.target.value)}
							sx={{ mt: 2 }}
							InputProps={{
								endAdornment: (
									<InputAdornment position={"end"}>
										<UploadFileButton
											icon
											onChange={e => {
												if (e.target.files?.length) {
													setFriendCommentMedia(e.target.files[0]);
												} else {
													setFriendCommentMedia(null);
												}
											}}
											inputProps={{
												accept:
													"audio/aac, " +
													"audio/mp4, " +
													"audio/mpeg, " +
													"audio/amr, " +
													"audio/ogg, " +
													"text/plain, " +
													"application/pdf, " +
													"application/vnd.ms-powerpoint, " +
													"application/msword, " +
													"application/vnd.ms-excel, " +
													"application/vnd.openxmlformats-officedocument.wordprocessingml.document, " +
													"application/vnd.openxmlformats-officedocument.presentationml.presentation, " +
													"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, " +
													"image/jpeg, " +
													"image/png, " +
													"video/mp4, " +
													"video/3gp, " +
													"image/webp",
											}}
										>
											<AttachFileIcon />
										</UploadFileButton>
									</InputAdornment>
								),
							}}
						/>
						{friendCommentMedia && (
							<SelectedFilePreview
								file={friendCommentMedia}
								onRemove={() => setFriendCommentMedia(null)}
								boxProps={{
									mt: 2,
									ml: 1,
								}}
							/>
						)}
					</>
				)}
				<Box mt={2} textAlign={"end"}>
					{error && <div className={"text-danger mb-3"}>{error}</div>}
					<LoadingButton
						onClick={handleSelectFriendButtonClick}
						disabled={!selectedFriend}
						variant={"contained"}
						loading={isLoading}
					>
						{localisation.orders.selectFriendConfirm}
					</LoadingButton>
				</Box>
			</Box>
		</>
	);
}

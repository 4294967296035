import {storeBasePath} from "../../base";
import {AxiosInstance} from "axios";
import {IFavorites, ICreateFavorites, ICreatedFavorites, ISyncFavorites} from "./types";

export default function FavoritesModule(
    instance: AxiosInstance
) {
    const getPath = (path: string) => `${storeBasePath}/favorites/${path}`
    const getHeaders = (token: string | null | undefined) =>
        token ? {"Authorization": `Bearer ${token}`} : undefined

    return {
        getFavorites(
            storeId?: number | null | undefined, token?: string | null | undefined
        ) {
            let config: {params: {store_id: number | null | undefined}, headers?: any} = {params: {store_id: storeId}}
            if(token) {
                config.headers = getHeaders(token)
            }

            return instance.get<IFavorites>(getPath(''), config)
        },

        createFavorites(payload: ICreateFavorites){
            return instance.post<ICreatedFavorites>(getPath('create'), payload)
        },

        addProductToFavorites(
            productId: number, storeId?: number | null | undefined, token?: string | null | undefined
        ){
            let config: {headers?: any} = {}
            if(token)
                config.headers = getHeaders(token)

            return instance.post(getPath(`add/${storeId}/${productId}`), null, config)
        },

        deleteProductFromFavorites(
            favProductId: number, storeId?: number | null | undefined, token?: string | null | undefined
        ){
            let config: {headers?: any} = {}
            if(token)
                config.headers = getHeaders(token)

            return instance.delete(getPath(`delete/${storeId}/${favProductId}`), config)
        },

        clearFavorites(
            storeId?: number | null | undefined, token?: string | null | undefined
        ){
            let config: {headers?: any} = {}
            if(token)
                config.headers = getHeaders(token)

            return instance.delete(getPath(`clear/${storeId}`), config)
        },

        syncFavorites(payload: ISyncFavorites, token?: string | null | undefined){
            let config: {headers?: any} = {}
            if(token)
                config.headers = getHeaders(token)

            return instance.post(getPath(`sync_favorites`), payload, config)
        },

        getLocalFavoritesToken(storeId: number) {
            return localStorage.getItem(`favorites-token-${storeId}`)
        },

        setLocalFavoritesToken(token: string, storeId: number) {
            localStorage.setItem(`favorites-token-${storeId}`, token)
        },

        removeLocalFavoritesToken(storeId: number) {
            localStorage.removeItem(`favorites-token-${storeId}`)
        }

    }
}

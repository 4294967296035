export const ProfileLocale = {
	profile: "web store profile header text",
	notAuthHeader: "web store profile not auth header text",
	notAuthSubHeader: "web store profile not auth sub header text",
	accountHeader: "web store profile account header text",
	accountsTitle: "accounts title",
	logout: "web store profile logout button text",
	incustLogin: "store brand incust auth button",
	loyaltyRedeem: "loyalty redeem bonuses header",
	loyaltyDiscount: "loyalty discount header",
	loyaltyBonusesAwarded: "loyalty bonuses awarded header",
	loyaltyCouponsAwarded: "loyalty coupons awarded header",
	loyaltyLogin: "loyalty login button",
	loyaltyCoupons: "loyalty coupons header",
	loyaltyNoAccounts: "no accounts text",
	loyaltyNoBonuses: "no bonuses text",
	loyaltyNoVouchers: "no vouchers text",
	loyaltyCouponsTitle: "user vouchers button",
	loyaltyShowQrCodeBody: "show qrcode body text",
	loyaltyConfirm: "loyalty confirm bonuses redeem message",
	loyaltyBonusesTooltip: "loyalty bonuses amount tooltip",
	loyaltyBonusesHeader: "service bot loyalty bonuses header",
	loyaltyCouponsErrHeader: "loyalty coupons cant redeem header",
	loyaltyAddCoupon: "loyalty add coupon header",
	loyaltyAddVoucherTitle: "add voucher result title text",
	loyaltyAddVoucher: "loyalty add voucher header",
	loyaltyCouponNotAdded: "coupon not added",
	loyaltyCouponIsExpired: "coupon is expired",
	loyaltyApplyCoupons: "loyalty apply coupons button",
	loyaltyApplyCoupon: "loyalty apply coupon button",
	applyCoupon: "apply coupon button",
	applyVoucherTitle: "apply voucher title",
	applyVoucherBody: "apply voucher body",
	deleteCouponConfirm: "coupon confirm delete text",
	successDeletedCoupon: "voucher success deleted text",
	titleDeletionCoupon: "voucher title delete text",
	loyaltyApplyCertificate: "loyalty apply certificate button",
	loyaltyAppliedCoupons: "loyalty applied coupons header",
	loyaltyUsersCoupons: "loyalty users coupons header",
	loyaltyExtCouponCode: "external coupon show code text",
	loyaltyExtCouponUseCode: "external coupon show code use text",
	loyaltyExtCouponCodeHeader: "external coupon show code header text",
	loyaltyCouponCodeHeader: "coupon show code header text",
	loyaltyCouponNotUse: "coupon show not use text",
	loyaltyScanCodeHeader: "scan code header text",
	loyaltyAddCouponExist: "add coupon to wallet exist text",
	loyaltyRedeemedCouponText: "loyalty redeemed coupon text",
	loyaltyAddCouponFailedText: "add coupon to wallet failed text",
	loyaltySpAccountTopped: "special account topped text",
	loyaltyCouponPeriodNoLimit: "coupon validity period not limited",
	loyaltyCouponCanUseWithOrder: "coupon can use for order",
	loyaltyCouponExpiredDate: "coupon expired date text",
	loyaltyCouponRedeemDate: "coupon redeem date text",
	ErrorDownloadFile: "error download file text",
	favoritesHeader: "web store favorites header",
	noFavoritesHeader: "web store favorites not found header",
	hiddenIncustWalletHeader: "store brand hidden incust wallet header",
	timezoneHeader: "timezone header",
	genderHeader: "web app profile gender header",
	languageHeader: "web app profile language header",
	loyaltyBonusesAwardedAfter: "loyalty bonuses awarded after header",
	loyaltyCouponsAwardedAfter: "loyalty coupons awarded after header",
	unableSaveCauseTg: "profile unable change data cause tg user error",
	loyaltyVouchersAwarded: "loyalty vouchers awarded header",
	loyaltyVouchersAwardedAfter: "loyalty vouchers awarded after header",
	myCard: "web app my card button",
	tempCode: "incust loyalty temporary code header",
	downloadCard: "incust loyalty download card button",
	tempCodeText: "web temp code text",
	downloadImgCard: "incust loyalty download image card button",
	digitalCardDesc: "incust loyalty digital card desc text",
	authAndSecurity: "web app auth and security header",
	passwordHeader: "web app password header",
	successAddedCouponToWallet: "add coupon to wallet success text",
	addedCouponSubHeader: "external coupon show end text",
	acceptCouponTitle: "incust loyalty accept coupon title",
	acceptCouponNoIdErr: "incust loyalty accept coupon no id error",
	acceptCouponBtn: "incust loyalty accept coupon add button",
	acceptCouponNeedLoyaltyAuth: "incust loyalty accept coupon need auth loyalty message",
	acceptCouponNeedAuth: "incust loyalty accept coupon need auth message",
	acceptCouponHeader: "incust loyalty accept coupon header",
	voucherTitle: "incust loyalty coupon title",
	acceptCouponWait: "incust loyalty accept coupon wait message",
	shareCouponTitle: "share coupon title text",
	resultApplyCouponTitle: "result apply voucher title text",
	shareCouponBody: "share coupon body text",
	shareCouponWeb: "share coupon web text",
	shareCouponMessenger: "share coupon messenger text",
	shareCouponBenefits: "coupon benefits share text",
	shareCouponFeeBonuses: "coupon fee amount bonuses",
	couponNotFound: "coupon not found short text",
	inputDataError: "auth invalid input data error",
	couponUsedAnotherUser: "coupon used another user text",
	getVoucher: "incust loyalty accept coupon receive header",
	shareAndEarn: "share and get benefits",
	scanOrCopy: "please scan qr code or copy link",
	acceptInvitation: "incust loyalty accept invitation header",
	noReferralCodeErr: "incust loyalty no referral code error",
	noReqUserIdErr: "web not req user id error",
	sameUserReferralErr: "incust loyalty accept invitation same user error",
	invitedHeader: "incust loyalty invited by header",
	invitedFriendHeader: "client bot add friend greeting text",
	notValidReferralCode: "incust loyalty not valid referral code",
	invitationAccepted: "incust loyalty accepted invitation success",
	accountAwarded: "loyalty account awarded after header",
	shareHeader: "incust loyalty referral share header",
	shareMsgSubHeader: "incust loyalty referral share messanger sub header",
	shareQrHeader: "incust loyalty referral share qr header",
	shareWebWithMsgHeader: "incust loyalty referral share web with msg sub header",
	shareWebHeader: "incust loyalty referral share web sub header",
	needAuthForReferral: "incust loyalty referral share need auth header",
	sameReferralHeader: "incust loyalty referral same user share header",
	sameUserFriendHeader: "web same user friend header",
	referralLearnMore: "incust loyalty referral learn more button",
	referralSummaryMessage: "incust loyalty referral summary message",
	referralLevel: "incust loyalty referral level",
	referralUnknownUser: "incust loyalty referral unknown user",
	referralSummaryQty: "incust loyalty referral summary referrals count",
	referralEarned: "incust loyalty referral summary earned",
	referralConfirmedAndAll: "incust loyalty referral summary confirmed and all label",
	friendsHeader: "web user profile friends header",
	noFriendsHeader: "web user profile no friends header",
	noFriendsSearchHeader: "web user profile no friends search header",
	friendAddMore: "web user profile add friend text",
	friendDelete: "web user profile delete friend text",
	referralEmptyBalanceWithoutRefs: "incust loyalty referral empty balance without refs text",
	referralEmptyBalanceWithRefs: "incust loyalty referral empty balance with refs text",
	referralWalletBalance: "incust loyalty referral wallet balance button",
	showInWalletButton: "show in wallet button",
	notLinkedText: "web app profile not linked text",
	showCardTimeout: "loyalty my card timeout button",
};

import {IQrMenuButtonProps} from "../types";
import QrMenuButtonWrapper from "./QrMenuButtonWrapper";
import {useSelectedStoreOptional} from "../../SelectedStore/context";
import changePage from "../../../helpers/changePage";

export default function MenuButton(props: IQrMenuButtonProps){
    const selectedStore = useSelectedStoreOptional()

    const onClick = () => {
        if (selectedStore?.id) {
            changePage(`/shop/${selectedStore?.id}/menu`)
        } else {
            changePage('/shop/select')
        }
    }

    if(!props.buttonName) return null

    return (
        <QrMenuButtonWrapper
            onClick={onClick}
        >
            {props.buttonName}
        </QrMenuButtonWrapper>
    )
}

import { ReactNode, useCallback, useMemo } from "react";
import { Box, Grid, Tooltip, Typography, TypographyVariant } from "@mui/material";
import Card from "@mui/material/Card";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CardHeader from "@mui/material/CardHeader";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";

import {
	IAccountsBenefits,
	IBonusesBenefits,
	IReferralProgramLevelSummary,
	IReferralProgramSummary,
} from "../../../../api/shop/loyalty/types";
import useAppContext from "../../../../useAppContext";
import formatCurrency from "../../../../helpers/formatCurrency";
import useCurrency from "../../../../services/useCurrencyService";
import { TgLink } from "../../../../helpers/ThemeComponents";
import changePage from "../../../../helpers/changePage";
import { useSelectedStoreOptional } from "../../../SelectedStore/context";
import Interweave from "../../../../features/Interweave";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

interface IReferralOverallProps {
	summary: IReferralProgramSummary;
}

export default function ReferralOverall(props: IReferralOverallProps) {
	const {
		lang,
		localisation: { profile },
		brandInfo,
	} = useAppContext();
	const currency = useCurrency();

	return (
		<Grid container spacing={2}>
			<ReferralOverallItem
				icon={<PeopleAltOutlinedIcon fontSize={"large"} sx={{ color: "primary.main" }} />}
				title={
					<Box display={"flex"}>
						<Box sx={{ mr: 1 }}>{profile.referralSummaryQty}</Box>
						<ReferralsTooltip
							all={props.summary.referrals_count || 0}
							confirmed={props.summary.confirmed_referrals_count || 0}
						/>
					</Box>
				}
				value={props.summary.referrals_count}
			/>

			<ReferralOverallItemBonusesItem
				bonuses={props.summary?.rewards?.bonuses || null}
				accounts={props.summary.rewards?.accounts || null}
				hasReferrals={!!props.summary.referrals_count && props.summary.referrals_count > 0}
			/>

			{props.summary.rewards?.accounts?.map((account: IAccountsBenefits) => (
				<ReferralOverallItem
					key={account.id}
					icon={
						<PaymentsOutlinedIcon fontSize={"large"} sx={{ color: "primary.main" }} />
					}
					title={`${profile.referralEarned} (${account.title})`}
					value={formatCurrency(
						account.amount?.toFixed(2) || 0,
						brandInfo?.default_lang || lang,
						account.currency || currency || ""
					)}
				/>
			))}

			{props.summary?.levels?.map((level: IReferralProgramLevelSummary) => (
				<ReferralOverallItemLevel key={level.level} level={level} />
			))}
		</Grid>
	);
}

interface IReferralOverallItemLevelProps {
	level: IReferralProgramLevelSummary;
}

function ReferralOverallItemLevel(props: IReferralOverallItemLevelProps) {
	const {
		lang,
		localisation: { profile },
		brandInfo,
	} = useAppContext();
	const currency = useCurrency();

	return (
		<>
			{!!(props.level.rewards?.bonuses && props.level.rewards?.bonuses.length > 0)
				? props.level.rewards?.bonuses?.map((bonus: IBonusesBenefits) => (
						<ReferralOverallItem
							key={props.level.level}
							icon={
								<PeopleAltOutlinedIcon
									fontSize={"small"}
									sx={{ color: "primary.main" }}
								/>
							}
							title={`${profile.referralLevel} ${props.level.level}`}
							value={props.level.referrals_count}
							subIcon={
								<PaymentsOutlinedIcon
									fontSize={"small"}
									sx={{ color: "primary.main" }}
								/>
							}
							subValue={formatCurrency(
								bonus.amount?.toFixed(2) || 0,
								brandInfo?.default_lang || lang,
								bonus.currency || ""
							)}
						/>
					))
				: !!(props.level.referrals_count && props.level.referrals_count > 0) && (
						<ReferralOverallItem
							key={props.level.level}
							icon={
								<PeopleAltOutlinedIcon
									fontSize={"large"}
									sx={{ color: "primary.main" }}
								/>
							}
							title={`${profile.referralLevel} ${props.level.level}`}
							value={props.level.referrals_count}
						/>
					)}

			{!!(props.level.rewards?.accounts && props.level.rewards.accounts.length > 0) &&
				props.level.rewards.accounts.map((account: IAccountsBenefits) => (
					<ReferralOverallItem
						key={`${account.id}${props.level.level}`}
						icon={
							<PaymentsOutlinedIcon
								fontSize={"large"}
								sx={{ color: "primary.main" }}
							/>
						}
						title={`${profile.referralLevel} ${props.level.level} (${account.title})`}
						value={formatCurrency(
							account.amount?.toFixed(2) || 0,
							brandInfo?.default_lang || lang,
							account.currency || currency || ""
						)}
					/>
				))}
		</>
	);
}

interface IReferralOverallItemBonusesItemProps {
	bonuses?: IBonusesBenefits[] | null;
	accounts?: IAccountsBenefits[] | null;
	hasReferrals: boolean;
}

function ReferralOverallItemBonusesItem(props: IReferralOverallItemBonusesItemProps) {
	const {
		lang,
		localisation: { profile },
		brandInfo,
	} = useAppContext();
	const currency = useCurrency();
	const selectedStore = useSelectedStoreOptional();

	const { customerData } = useUserIncustCustomerData();

	const computedHasBonuses = useMemo(() => {
		if (!props.bonuses || props.bonuses.length < 1) return false;
		return props.bonuses.length !== 0;
	}, [props.bonuses]);

	const computedHasAccounts = useMemo(() => {
		if (!props.accounts || props.accounts.length < 1) return false;
		return props.accounts.length !== 0;
	}, [props.accounts]);

	const computedBonusesTooltipDesc = useMemo(() => {
		if (props.hasReferrals) {
			return profile.referralEmptyBalanceWithRefs;
		}
		return profile.referralEmptyBalanceWithoutRefs;
	}, [
		profile.referralEmptyBalanceWithRefs,
		profile.referralEmptyBalanceWithoutRefs,
		props.hasReferrals,
	]);

	const getWalletBalance = useCallback(
		(rewardBonus: IBonusesBenefits | null, withCurrency: boolean = false) => {
			if (customerData?.user_card?.bonuses) {
				const currentCurrency = withCurrency ? currency : rewardBonus?.currency;
				const bonusItem = customerData?.user_card?.bonuses?.find(
					(bonus: any) => bonus.currency === currentCurrency
				);
				if (bonusItem) {
					return formatCurrency(
						bonusItem.bonuses_amount?.toFixed(2) || 0,
						brandInfo?.default_lang || lang,
						bonusItem.currency || ""
					);
				}
			}
			return formatCurrency(0, brandInfo?.default_lang || lang, currency || "");
		},
		[brandInfo?.default_lang, currency, lang, customerData?.user_card?.bonuses]
	);

	const handleWalletBalanceClick = () => {
		if (selectedStore?.id) {
			return changePage(`/shop/${selectedStore.id}/profile/loyalty`);
		}
		changePage("/shop/profile/loyalty");
	};

	return (
		<>
			{computedHasBonuses &&
				props.bonuses?.map((bonus: IBonusesBenefits) => (
					<ReferralOverallItem
						key={bonus.currency}
						icon={
							<PaymentsOutlinedIcon
								fontSize={"large"}
								sx={{ color: "primary.main" }}
							/>
						}
						title={`${profile.referralEarned} (${bonus.currency_name})`}
						value={formatCurrency(
							bonus.amount?.toFixed(2) || 0,
							brandInfo?.default_lang || lang,
							bonus.currency || ""
						)}
						linkNode={
							<Box textAlign={"end"}>
								<TgLink
									className={"cursor-pointer"}
									onClick={handleWalletBalanceClick}
								>
									{profile.referralWalletBalance.replace(
										"{amount}",
										getWalletBalance(bonus) || ""
									)}
								</TgLink>
							</Box>
						}
					/>
				))}

			{!computedHasBonuses && !computedHasAccounts && (
				<ReferralOverallItem
					icon={
						<PaymentsOutlinedIcon fontSize={"large"} sx={{ color: "primary.main" }} />
					}
					title={
						<Box display={"flex"}>
							<Box sx={{ mr: 1 }}>{profile.referralEarned}</Box>
							<ReferralsTooltip desc={computedBonusesTooltipDesc} />
						</Box>
					}
					value={formatCurrency(0, brandInfo?.default_lang || lang, currency || "")}
					linkNode={
						<Box textAlign={"end"}>
							<TgLink className={"cursor-pointer"} onClick={handleWalletBalanceClick}>
								{profile.referralWalletBalance.replace(
									"{amount}",
									getWalletBalance(null, true) || ""
								)}
							</TgLink>
						</Box>
					}
				/>
			)}
		</>
	);
}

interface IReferralOverallItemProps {
	icon: ReactNode;
	title: string | ReactNode;
	value: string | number | ReactNode;
	subIcon?: ReactNode;
	subValue?: string | number | ReactNode;
	linkNode?: ReactNode;
}

function ReferralOverallItem(props: IReferralOverallItemProps) {
	const isSubData = !!props.subIcon && !!props.subValue;

	return (
		<Grid item xs={12} md={6} lg={4} className={props.linkNode ? "h-100" : ""}>
			<Card className={"h-100"}>
				<CardHeader
					sx={{ pb: props.linkNode ? 0 : 3 }}
					title={
						<Typography variant={"body2"} sx={{ color: "text.secondary" }}>
							{props.title}
						</Typography>
					}
					subheader={
						<>
							<ReferralOverallItemSubheader
								icon={props.icon}
								value={props.value}
								isSubData={isSubData}
							/>
							{isSubData && (
								<ReferralOverallItemSubheader
									icon={props.subIcon}
									value={props.subValue}
									isSubData={isSubData}
									typographyVariant={"h6"}
								/>
							)}
						</>
					}
				/>
				{!!props.linkNode && <Box sx={{ px: 3, pb: 1 }}>{props.linkNode}</Box>}
			</Card>
		</Grid>
	);
}

interface IReferralOverallItemSubheaderProps {
	icon: ReactNode;
	value: string | number | ReactNode;
	isSubData?: boolean;
	typographyVariant?: TypographyVariant;
}

function ReferralOverallItemSubheader(props: IReferralOverallItemSubheaderProps) {
	const computedVariant = useMemo(() => {
		if (props.typographyVariant) return props.typographyVariant;
		if (!!props.isSubData) return "body2";
		return "h4";
	}, [props.isSubData, props.typographyVariant]);

	return (
		<Box justifyContent={"start"} display={"flex"}>
			<Box alignSelf={"center"}>{props.icon}</Box>
			<Box alignSelf={"center"} sx={{ ml: "auto" }}>
				<Typography variant={computedVariant} fontWeight={"bold"}>
					{props.value}
				</Typography>
			</Box>
		</Box>
	);
}

interface IReferralsTooltipProps {
	all?: number;
	confirmed?: number;
	desc?: string;
}

function ReferralsTooltip(props: IReferralsTooltipProps) {
	const {
		localisation: { profile },
	} = useAppContext();

	return (
		<Tooltip
			enterTouchDelay={0}
			title={
				!props.desc ? (
					<Interweave
						content={profile.referralConfirmedAndAll
							.replace("{all_count}", props.all?.toString() || "0")
							.replace("{confirmed_count}", props.confirmed?.toString() || "0")}
					/>
				) : (
					<Interweave content={props.desc} />
				)
			}
		>
			<HelpOutlineOutlinedIcon
				sx={{ color: "primary.main" }}
				fontSize={"small"}
				className={"cursor-pointer"}
			/>
		</Tooltip>
	);
}

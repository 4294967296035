import { Grid, Typography } from "@mui/material";

import { Order } from "../../../../../api/shop/order/types";
import useAppContext from "../../../../../useAppContext";
import { IIncustCouponShowData } from "../../../../../api/shop/loyalty/types";
import { VoucherCard } from "../../../../../features/Check/CheckAwards";
import { useState } from "react";
import CouponModal from "../../../../loyalty/components/Coupon/CouponModal";
import OrderContentWithTypeWrapper from "./OrderContentWithTypeBase";

interface IOrderContentGiftProps {
	order: Order;
}

export default function OrderContentGift(props: IOrderContentGiftProps) {
	const {
		localisation: { menu },
	} = useAppContext();

	const [showCouponModal, setShowCouponModal] = useState<boolean>(false);
	const [couponId, setCouponId] = useState<string | null>(null);

	const product = props.order.order_products[0];

	return (
		<OrderContentWithTypeWrapper product={product} createDate={props.order.create_date}>
			{product.price > 0 && (
				<Typography
					fontWeight={"bold"}
					variant={"body2"}
					mb={2}
					sx={{ color: "var(--mui-palette-warning-dark)" }}
				>
					{menu.giftPaidBonuses}
				</Typography>
			)}

			{!!(
				(props.order.original_incust_loyalty_check?.emitted_coupons &&
					props.order.original_incust_loyalty_check?.emitted_coupons.length) ||
				props.order?.incust_vouchers?.length
			) && (
				<Typography variant={"subtitle2"} fontWeight={"bold"} mt={2}>
					{menu.giftAwardsAfterHeader}
				</Typography>
			)}

			{!!props.order.incust_vouchers?.length && (
				<Grid container spacing={2}>
					{props.order.incust_vouchers.map(
						(voucher: IIncustCouponShowData) =>
							voucher.url &&
							voucher.title && (
								<Grid item sm={6} className={"w-100"} key={voucher.title}>
									<VoucherCard
										voucher={voucher}
										showCouponModal={showCouponModal}
										setShowCouponModal={setShowCouponModal}
										setCouponId={setCouponId}
									/>
								</Grid>
							)
					)}
				</Grid>
			)}
			<CouponModal show={showCouponModal} setShow={setShowCouponModal} couponId={couponId} />
		</OrderContentWithTypeWrapper>
		// <>
		// 	{product.product.image_url ? (
		// 		<img
		// 			width={"100%"}
		// 			height={"auto"}
		// 			src={product.product.image_url}
		// 			alt={product.name}
		// 		/>
		// 	) : (
		// 		<ImageStub ratio={[1, 1]} emptyStub={false} />
		// 	)}
		//
		// 	<Box sx={{ px: 3 }}>
		// 		<Box mt={2}>
		// 			<Typography variant={"h6"} fontWeight={"bold"}>
		// 				{f(check.storeNameText, { name: selectedStore.name })}
		// 			</Typography>
		// 			<Typography>{f(check.dateText, { datetime: date })}</Typography>
		// 			<Box display={"flex"} flexWrap={"wrap"} fontWeight={"bold"} mt={2}>
		// 				<Box>
		// 					<Link
		// 						href={undefined}
		// 						onClick={() => {
		// 							changePage(
		// 								`/shop/${selectedStore.id}/menu?product_id=${product.product.id}`
		// 							);
		// 						}}
		// 						target={"_blank"}
		// 						sx={{ cursor: "pointer" }}
		// 					>
		// 						<Interweave content={product.name} />
		// 					</Link>
		// 					{product.price > 0 && <> {formatNumber(product.price, lang)}</>}
		// 					{" x "}
		// 					{product.quantity}
		// 				</Box>
		// 				{product.price > 0 && <Box ml={"auto"}>{formatNumber(sum, lang)}</Box>}
		// 			</Box>
		//
		// 			{product.price > 0 && (
		// 				<Typography
		// 					fontWeight={"bold"}
		// 					variant={"body2"}
		// 					mb={2}
		// 					sx={{ color: "var(--mui-palette-warning-dark)" }}
		// 				>
		// 					{menu.giftPaidBonuses}
		// 				</Typography>
		// 			)}
		//
		// 			{!!(
		// 				(props.order.original_incust_loyalty_check?.emitted_coupons &&
		// 					props.order.original_incust_loyalty_check?.emitted_coupons.length) ||
		// 				props.order?.incust_vouchers?.length
		// 			) && (
		// 				<Typography variant={"subtitle2"} fontWeight={"bold"} mt={2}>
		// 					{menu.giftAwardsAfterHeader}
		// 				</Typography>
		// 			)}
		//
		// 			{!!props.order.incust_vouchers?.length && (
		// 				<Grid container spacing={2}>
		// 					{props.order.incust_vouchers.map(
		// 						(voucher: IIncustCouponShowData) =>
		// 							voucher.url &&
		// 							voucher.title && (
		// 								<Grid item sm={6} className={"w-100"} key={voucher.title}>
		// 									<VoucherCard
		// 										voucher={voucher}
		// 										showCouponModal={showCouponModal}
		// 										setShowCouponModal={setShowCouponModal}
		// 										setCouponId={setCouponId}
		// 									/>
		// 								</Grid>
		// 							)
		// 					)}
		// 				</Grid>
		// 			)}
		// 		</Box>
		// 	</Box>
		//
		// 	<CouponModal show={showCouponModal} setShow={setShowCouponModal} couponId={couponId} />
		// </>
	);
}

import {ReactNode} from "react";

import StickyComponent from "../../features/StickyComponent";

interface IShopMenuStickyWrapperProps {
    children: ReactNode
    id: string
}

export default function ShopMenuStickyWrapper(props: IShopMenuStickyWrapperProps) {
    return (
            <StickyComponent
                wrapperClasses={'flex-1 mb-2 d-none d-md-block'}
            >
                <div className={'scrollbar-thin'} style={{
                    maxHeight: 'calc(100vh - 100px)',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                }} id={props.id}>
                    {props.children}
                </div>
            </StickyComponent>
    )
}

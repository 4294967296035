import {AuthorisationComponentProps} from "../types";
import {ChangeEvent} from "react";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import TextField from "@mui/material/TextField";
import {Grid} from "@mui/material";


export default function FirstAndLastNamesInputs(props: AuthorisationComponentProps) {
    if (props.form.state !== 'emailRegistration') {
        throw new Error('FirstAndLastNamesInputs is not supported for emailRegistration')
    }

    const localisation = useLocalisation()

    const getOnChange = (field: 'firstName' | 'lastName') => (e: ChangeEvent<HTMLInputElement>) => {
        props.form.input(field, e.target.value)
    }

    return (<>
        <Grid container mt={1} spacing={1}>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    size={'small'}
                    required={true}
                    name={'firstName'}
                    type={'text'}
                    value={props.form.firstName}
                    onChange={getOnChange('firstName')}
                    label={localisation.auth.registerFirstNameLabel}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    size={'small'}
                    required={true}
                    name={'lastName'}
                    type={'text'}
                    value={props.form.lastName}
                    onChange={getOnChange('lastName')}
                    label={localisation.auth.registerLastNameLabel}
                />
            </Grid>
        </Grid>
    </>)
}

import {useEffect, RefObject, useState} from "react";

import useAnimationTimeline, {IAnimationTimelineItem} from "../../../../features/hooks/useAnimationTimeline";

export default function useDetailedProductAnimations(
    offsetElement: RefObject<HTMLDivElement | null>,
    trigger?: boolean,
) {
    const [animationItems, setAnimationItems] =
        useState<IAnimationTimelineItem[]>([])

    useAnimationTimeline('modal-or-swipeable-overflow-wrapper', animationItems)

    useEffect(() => {
        if(offsetElement && offsetElement.current){
            const growKeyframes = [
                {transform: 'translateY(-160px)'},
                {transform: 'translateY(-160px)', offset: 0.3},
                {transform: 'none'},
            ]
            const animationItems = [
                {
                    id: 'product-media-bar-background',
                    keyframes: [{opacity: '0'}, {opacity: '0', offset: 0.5}, {opacity: '1'}],
                    rangeStart: 0,
                    rangeEnd: offsetElement.current?.offsetHeight,
                    animationName: 'opacity',
                    scrollTimelineValue: 'scroll()',
                },
                {
                    id: 'product-media-bar-image',
                    keyframes: growKeyframes,
                    rangeStart: 0,
                    rangeEnd: offsetElement.current?.offsetHeight,
                    animationName: 'grow',
                    scrollTimelineValue: 'scroll()',
                },
                {
                    id: 'product-media-bar-text',
                    keyframes: growKeyframes,
                    rangeStart: 0,
                    rangeEnd: offsetElement.current?.offsetHeight,
                    offsetPlus: 30,
                    animationName: 'grow',
                    scrollTimelineValue: 'scroll()',
                },
                {
                    id: 'product-media-opacity-scroll',
                    keyframes: [{opacity: '1'}, {opacity: '1', offset: 0.85}, {opacity: '0'}],
                    animationName: 'opacityReverse',
                    scrollTimelineValue: "view()",
                },
                {
                    id: 'cart-controls',
                    keyframes: [
                        {boxShadow: 'var(--mui-shadows-4)'},
                        {boxShadow: 'var(--mui-shadows-4)', offset: 0.9},
                        {boxShadow: 'none'}],
                    animationName: 'cartControlsShadow',
                    scrollTimelineValue: "scroll()",
                },
            ]
            setAnimationItems(animationItems)
        }
    }, [offsetElement, trigger])
}

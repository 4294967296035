import useAppContext from "../../../useAppContext";
import useDatetimeService from "../../../services/useDatetimeService";
import { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IProfileOverallItemPropsBase } from "./ProfileOverallItemPropsBase";
import { InputAdornment, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface IProfileDateBirthProps extends IProfileOverallItemPropsBase {
	onChange: (value: Dayjs | null | string) => void;
	formValue?: string | null;
	fullWidth?: boolean;
	helperText?: string;
	tooltipText?: string;
	tooltipZIndex?: number;
}

export function ProfileDateBirth(props: IProfileDateBirthProps) {
	const {
		lang,
		authService: { user },
	} = useAppContext();
	const { computedLocaleText } = useDatetimeService();

	const dateValue = useMemo(() => {
		if (props.value) {
			try {
				return dayjs(new Date(props.value));
			} catch (err) {
				console.log(err);
				return undefined;
			}
		}
		return undefined;
	}, [props.value]);

	if (!lang || !computedLocaleText) {
		return null;
	}

	return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			adapterLocale={lang}
			localeText={{ ...computedLocaleText }}
		>
			<DatePicker
				className={props.fullWidth ? "w-100" : ""}
				disableFuture
				label={props.header}
				value={dateValue}
				onChange={props.onChange}
				slotProps={{
					textField: {
						size: "small",
						fullWidth: props.fullWidth,
						helperText: props.helperText,
						InputProps: props.tooltipText
							? {
									startAdornment: (
										<InputAdornment position="start">
											<Tooltip
												title={props.tooltipText}
												PopperProps={{
													style: { zIndex: props.tooltipZIndex || 9999 },
												}}
											>
												<HelpOutlineIcon />
											</Tooltip>
										</InputAdornment>
									),
								}
							: undefined,
					},
				}}
				readOnly={!!user?.birth_date}
			/>
		</LocalizationProvider>
	);
}

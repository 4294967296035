import QRCode from "qrcode.react";
import {Backdrop, Button, useColorScheme} from "@mui/material";

interface IBluredQrCodeProps {
    size: number
    url: string
    callback?: () => void
    buttonText?: string
}

export default function BluredQrCode(props: IBluredQrCodeProps) {
    const {colorScheme} = useColorScheme()

    return (
        <>
            <QRCode size={props.size} value={props.url}></QRCode>
            <Backdrop
                sx={{
                    color: 'white',
                    zIndex: (theme) => theme.zIndex.drawer - 1,
                    backdropFilter: 'blur(5px)',
                    position: 'absolute',
                    width: props.size + 10,
                    height: props.size + 10,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: colorScheme === 'light' ? 'rgb(255 255 255 / 70%)' : 'rgb(16 16 16 / 70%)',
                }}
                open={true}
            >
                {!!(props.callback && props.buttonText) && (
                    <Button variant={'contained'} onClick={props.callback}>
                        {props.buttonText}
                    </Button>
                )}
            </Backdrop>
        </>
    )
}

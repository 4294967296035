import { useMemo, useCallback, useState, useEffect, useRef } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { Alert, Box, Button, CircularProgress, Skeleton, Typography } from "@mui/material";

import { IScanReceiptBase } from "../types";
import { ScanPayload } from "../../../api/shop/basic/types";
import useAppContext from "../../../useAppContext";
import ScanReceiptExternalLogin from "./ScanReceiptExternalLogin";
import { ThemeModal } from "../../../helpers/ThemeComponents";
import Authorisation from "../../../auth/Autorisation/Authorisation";
import useAuthorisationForm from "../../../auth/Autorisation/useAuthorisationForm";
import Divider from "@mui/material/Divider";
import ScanReceiptExitPopConfirm from "./ScanReceiptExitPopConfirm";
import f from "../../../helpers/formatText";
import useExternalLoginExtraData from "../../../auth/Autorisation/external/useExternalLoginExtraData";
import useAuthSettings from "../../../services/useAuthSettings";
import { useSelectedStoreContextOptional } from "../../../shop/SelectedStore/context";
import { useParams } from "react-router-dom";
import useAuthorisationModalTitle from "../../../auth/Autorisation/useAuthorisationModalTitle";

interface IScanReceiptProps extends IScanReceiptBase {
	showExitPopConfirm: boolean;
	setShowExitPopConfirm: (value: boolean) => void;
	setShowModal: (value: boolean) => void;
}

export default function ScanReceipt(props: IScanReceiptProps) {
	const {
		authService: { user },
		localisation: { receipt, global },
		bot,
	} = useAppContext();

	const store = useSelectedStoreContextOptional();

	const [showAuthModal, setShowAuthModal] = useState(false);
	const [scannedResult, setScannedResult] = useState<string | null>(null);
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [loggedInByMessanger, setLoggedInByMessanger] = useState(false);

	const computedHeaderText = useMemo(() => {
		if (props.service.isLoading) {
			return receipt.scanScannedHeader;
		} else if (!!props.service.scannedResult && !user) {
			return receipt.receiptChecked;
		} else {
			return receipt.scanHeader;
		}
	}, [
		props.service.isLoading,
		props.service.scannedResult,
		receipt.scanHeader,
		receipt.scanScannedHeader,
		user,
		receipt.receiptChecked,
	]);

	const makeScanAndSave = props.service.makeScanAndSave;
	const makeScan = props.service.makeScan;

	const handleScanResult = useCallback(
		async (res: string) => {
			setScannedResult(res);

			const payload: ScanPayload = {
				with_make_check: true,
				data: res,
				store_id: store?.selectedStore?.id ?? null,
			};

			if (!!user) return await makeScanAndSave(payload);
			await makeScan(payload);
		},
		[store?.selectedStore?.id, user, makeScanAndSave, makeScan]
	);

	const setServiceScannedResult = props.service.setScannedResult;
	const onLoginCallback = useCallback(async () => {
		setShowAuthModal(false);
		setIsAuthorized(true);
	}, []);

	const handleBeforeUnload = useCallback(
		(e: BeforeUnloadEvent) => {
			//TODO: setShowExitPopConfirm
			if (props.service.isLoading || !!props.service.scannedResult) {
				e.preventDefault();
				e.stopPropagation();
			}
		},
		[props.service.isLoading, props.service.scannedResult]
	);

	useEffect(() => {
		if (isAuthorized) setServiceScannedResult(null);
		if (isAuthorized && !!user && scannedResult) {
			handleScanResult(scannedResult).then();
		}
	}, [isAuthorized, user, scannedResult, handleScanResult, setServiceScannedResult]);

	useEffect(() => {
		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [handleBeforeUnload]);

	useEffect(() => {
		if (loggedInByMessanger) setServiceScannedResult(null);
	}, [loggedInByMessanger, setServiceScannedResult]);

	if (loggedInByMessanger) {
		return (
			<Box>
				{!!bot && (
					<Typography textAlign={"start"} fontWeight={"bold"} sx={{ pb: 2, mb: 2 }}>
						{f(receipt.receiptProcessedInMessanger, {
							messanger: global[`${bot.bot_type}MessangerName`],
						})}
					</Typography>
				)}

				<Box width={"100%"} textAlign={"end"} onClick={() => props.setShowModal(false)}>
					<Button variant={"contained"}>{global.okButton}</Button>
				</Box>
			</Box>
		);
	}

	return (
		<>
			<Box>
				<Typography
					textAlign={"start"}
					fontWeight={"bold"}
					sx={{ pb: 2, mb: 2 }}
					className={"border-bottom"}
				>
					{computedHeaderText} {props.service.isLoading && <CircularProgress size={14} />}
				</Typography>

				{!!props.service.scannedResult && (
					<ScanReceiptUnAuth
						service={props.service}
						setLoggedInByMessanger={setLoggedInByMessanger}
						setShowAuthModal={setShowAuthModal}
					/>
				)}

				{!!props.service.error && !props.service.isLoading && (
					<Alert severity={"error"} sx={{ mb: 2 }}>
						{props.service.error}
					</Alert>
				)}

				<ScanReceiptScanner
					service={props.service}
					isAuthorized={isAuthorized}
					handleScanResult={handleScanResult}
				/>
			</Box>

			<ScanReceiptDialogs
				showAuthModal={showAuthModal}
				setShowAuthModal={setShowAuthModal}
				onLoginCallback={onLoginCallback}
			/>
		</>
	);
}

interface IScanReceiptUnAuthProps extends IScanReceiptBase {
	setLoggedInByMessanger: (value: boolean) => void;
	setShowAuthModal: (value: boolean) => void;
}

function ScanReceiptUnAuth(props: IScanReceiptUnAuthProps) {
	const {
		bot,
		localisation: { receipt, auth },
		brandInfo,
	} = useAppContext();

	const { storeId } = useParams();

	const [externalLoginError, setExternalLoginError] = useState<string | null>(null);

	const extraData = useExternalLoginExtraData();

	const authSettings = useAuthSettings(brandInfo?.group_id);

	const computedExtraData = useMemo(() => {
		if (!!extraData && props.service.scannedResult) {
			return {
				receipt: props.service.scannedResult.receipt,
				store_id: storeId ? parseInt(storeId || "") : "",
				...extraData,
			};
		}
		if (props.service.scannedResult) {
			return {
				receipt: props.service.scannedResult.receipt,
				store_id: storeId ? parseInt(storeId || "") : "",
			};
		}
		return null;
	}, [extraData, props.service.scannedResult, storeId]);

	return (
		<Box>
			<Typography variant={"body2"} fontWeight={"bold"} sx={{ mb: 2 }}>
				{receipt.scanReceiptNeedAuth}
			</Typography>

			{externalLoginError && (
				<Alert severity={"error"} sx={{ mb: 2 }}>
					{externalLoginError}
				</Alert>
			)}

			{!!(
				!!bot &&
				props.service.scannedResult &&
				computedExtraData &&
				authSettings.settings?.is_auth_messanger_enabled
			) && (
				<>
					<ScanReceiptExternalLogin
						type={bot.bot_type}
						purpose={"receipt"}
						extraData={computedExtraData}
						botId={bot.id}
						setLoggedInByMessanger={props.setLoggedInByMessanger}
						service={props.service}
						setExternalLoginError={setExternalLoginError}
						setShowAuthModal={props.setShowAuthModal}
					/>

					{(authSettings.settings?.is_auth_email_enabled ||
						authSettings.computedIsAnyOAuthEnabled) && (
						<Divider sx={{ my: 2 }}>{auth.orText}</Divider>
					)}
				</>
			)}

			{(authSettings.computedIsAnyOAuthEnabled ||
				authSettings.settings?.is_auth_email_enabled) && (
				<Button
					fullWidth={true}
					variant={"outlined"}
					onClick={() => props.setShowAuthModal(true)}
				>
					{(authSettings.settings?.is_auth_email_enabled &&
						!authSettings.computedIsAnyOAuthEnabled) ||
					!bot
						? f(receipt.scanReceiptContinueWith, { messanger: "Email" })
						: auth.chooseAnotherAuth}
				</Button>
			)}
		</Box>
	);
}

interface IScanReceiptDialogsProps {
	showAuthModal: boolean;
	setShowAuthModal: (value: boolean) => void;
	onLoginCallback: () => void;
}

function ScanReceiptDialogs(props: IScanReceiptDialogsProps) {
	const { bot } = useAppContext();
	const authorisationForm = useAuthorisationForm();
	const [showExitPopConfirm, setShowExitPopConfirm] = useState(false);

	const modalTitle = useAuthorisationModalTitle(authorisationForm.state);

	return (
		<>
			<ThemeModal
				open={props.showAuthModal}
				setOpen={props.setShowAuthModal}
				title={modalTitle}
				fullWidth
				maxWidth={authorisationForm.state === "emailRegistration" ? "sm" : "xs"}
			>
				<Authorisation
					form={authorisationForm}
					disabledMessengerExternalLogin={!!bot}
					onLoginCallback={props.onLoginCallback}
				/>
			</ThemeModal>

			<ScanReceiptExitPopConfirm
				show={showExitPopConfirm}
				setShow={setShowExitPopConfirm}
				confirmCallback={() => (window.location.href = "/")}
			/>
		</>
	);
}

interface IScanReceiptScannerProps extends IScanReceiptBase {
	isAuthorized: boolean;
	handleScanResult: (res: string) => void;
}

function ScanReceiptScanner(props: IScanReceiptScannerProps) {
	const scannerRef = useRef<HTMLDivElement | null>(null);

	return (
		<>
			{props.service.isLoading || props.isAuthorized ? (
				<Box sx={{ width: "100%" }} textAlign={"center"}>
					<Skeleton
						sx={{
							margin: 0,
							maxWidth: "350px",
							maxHeight: "350px",
							width: scannerRef.current?.clientWidth || "auto",
							height: scannerRef.current?.clientHeight || "auto",
						}}
						variant={"rectangular"}
					/>
				</Box>
			) : (
				<>
					{!props.service.scannedResult && (
						<Box ref={scannerRef}>
							<QrScanner
								containerStyle={{
									width: "min(350px, calc(100vw-16px)",
									height: "min(350px, calc(100vw-16px)",
									margin: 0,
								}}
								onDecode={props.handleScanResult}
								onError={error => console.log(error?.message)}
								scanDelay={1000}
							/>
						</Box>
					)}
				</>
			)}
		</>
	);
}

import {useEffect, useState} from "react"
import {
    MDBRow
} from "mdb-react-ui-kit"

import api from "../api"
import {TgLink} from "../helpers/ThemeComponents"
import useLocalisation from "../hooks/localisation/useLocalisation"
import useSearchParamsFixed from "../features/hooks/useSearchParamsFixed";
import useAppContext from "../useAppContext";

export function EmailConfirmPage(){
    const {
        brandInfo,
        setPageTitle,
        loadingManager: {setIsLoading},
    } = useAppContext()
    const [searchParams] = useSearchParamsFixed()

    const [confirmedEmail, setConfirmedEmail] = useState<string | null>(null)
    const [error, setError] = useState<string | null>(null)

    const token = searchParams.get('token')
    const localisation = useLocalisation()

    const handleBackToShop = () => {
        if (brandInfo?.id) {
            window.open('/shop/select?brand_id=' + brandInfo.id, '_self')
        }
    }

    useEffect(() => {
        if(token) {
            setIsLoading('confirmEmail', true)
            api.auth.confirmEmail(token).then(response => {
                if(response.data.confirmed){
                    setConfirmedEmail(response.data.email)
                    return
                }
            }).catch(err => {
                setError(err?.response?.data?.detail || 'Unknown error')
            }).finally(() => {
                setIsLoading('confirmEmail', false)
            })
        } else {
            setIsLoading('confirmEmail', false)
            setError(localisation.auth.errEmailNoToken)
        }
    }, [token, localisation.auth.errEmailNoToken, setIsLoading])

    useEffect(() => {
        setTimeout(() => {
            setPageTitle(`${brandInfo?.name || localisation.global.basePageTitle} - ${localisation.auth.emailConfirmHeader}`)
        }, 300)
    }, [
        brandInfo?.name,
        localisation.auth.emailConfirmHeader,
        localisation.global.basePageTitle,
        setPageTitle,
    ]);

    return (
        <MDBRow className={'justify-content-center text-center'}>
            {confirmedEmail ? (<>
                <div className={'theme-text fw-bold fs-3'}>
                    <span>{localisation.auth.emailConfirmSuccess} </span>
                    (<strong>{confirmedEmail}</strong>)
                </div>
                {brandInfo?.id && (
                    <div className={'mt-2 fw-bold fs-4'}>
                        <TgLink className={'cursor-pointer'} onClick={() => handleBackToShop()}>
                            {localisation.global.backHome}
                        </TgLink>
                    </div>
                )}
            </>) : error && (
                <div className={'text-danger mb-3'}>{error}</div>
            )}
        </MDBRow>
    )
}

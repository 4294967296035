import { fetchEventSource } from "@microsoft/fetch-event-source";
import React from "react";
import { SseUrl } from "../../../../../config";

export const useSSEPaymentStatus = (payment_id: number | null | undefined, userSessionId: string, profile_id: number | null | undefined) => {
	const [streamIsOpen, setStreamIsOpen] = React.useState<boolean>(false);
	type PaymentStatus = "payed" | "failed" | "pending" | null;
	const [externalPaymentStatus, setExternalPaymentStatus] = React.useState<PaymentStatus>(null);
	const [externalPaymentError, setExternalPaymentError] = React.useState<string | null>(null);
	const sseConnectionRef = React.useRef<AbortController | null>(null);

	const createConnection = React.useCallback(async () => {
		if (!payment_id || !profile_id) return;

		if (sseConnectionRef.current) {
			sseConnectionRef.current.abort();
			sseConnectionRef.current = null;
		}

		const controller = new AbortController();
		sseConnectionRef.current = controller;

		try {
			console.log(`Creating new SSE connection for ... payment_id=${payment_id}, profile_id=${profile_id}`);
			await fetchEventSource(`${SseUrl}/payments/${payment_id}/stream?profile_id=${profile_id}`, {
				headers: {
					Accept: "text/event-stream",
					"x-session-id": userSessionId,
				},
				signal: controller.signal,

				// Опції для стабільності з'єднання
				openWhenHidden: true,
				keepalive: true,

				fetch: (input, init) => {
					return fetch(input, {
						...init,
						// timeout: 60000,
						keepalive: true,
						credentials: "include",
					});
				},

				async onopen(e) {
					console.log("New SSE connection opened", e);
					setStreamIsOpen(true);
				},

				onmessage(ev) {
					if (!ev.data) return;

					try {
						const parsedData = JSON.parse(ev.data);

						if (["payed", "failed", "pending"].includes(parsedData?.status)) {
							setExternalPaymentStatus(parsedData.status);
							if (parsedData.status === "failed") {
								setExternalPaymentError(parsedData.title);
							}
						}
					} catch (e) {
						console.error("Error parsing SSE data:", e);
					}
				},

				onerror(err) {
					console.error("SSE connection error:", err);
					setStreamIsOpen(false);
				},

				onclose() {
					console.log("SSE connection closed");
					setStreamIsOpen(false);
				},
			});
		} catch (error) {
			console.error("Error creating SSE connection:", error);
			setStreamIsOpen(false);
		}
	}, [payment_id, userSessionId]);

	React.useEffect(() => {
		createConnection();

		return () => {
			if (sseConnectionRef.current) {
				sseConnectionRef.current.abort();
				sseConnectionRef.current = null;
			}
		};
	}, [createConnection]);

	return {
		externalPaymentStatus: externalPaymentStatus,
		externalPaymentError: externalPaymentError,
		setExternalPaymentStatus: setExternalPaymentStatus,
	};
};
import {styled} from "@mui/system";
import {Button, ButtonProps, IconButton} from "@mui/material";
import {
    ChangeEventHandler,
    InputHTMLAttributes,
    ReactNode,
} from "react";


const VisuallyHiddenInput = styled('input')({
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


interface UploadFileButtonProps {
    icon?: boolean
    children?: ReactNode
    buttonProps?: ButtonProps

    files?: File | File[] | null | undefined
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined

    inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'type'>
}

export default function UploadFileButton(props: UploadFileButtonProps) {
    const inputContent = (
        <VisuallyHiddenInput
            type={'file'}
            onChange={props.onChange}
            {...props.inputProps}
        />
    )

    if (props.icon) {
        return (
            <IconButton component={'label'} {...props.buttonProps}>
                {props.children}
                {inputContent}
            </IconButton>
        )
    }

    return (
        <Button component={"label"} variant={"contained"} {...props.buttonProps}>
            {inputContent}
        </Button>
    )
}

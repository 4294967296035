import { FormEvent, useState } from "react";
import TextField from "@mui/material/TextField";

import useAppContext from "../../../../useAppContext";
import FormFooter from "./FormFooter";
import { SendConfirmEmailData } from "../../../../api/auth/types";
import api from "../../../../api";
import { Alert, AlertTitle } from "@mui/material";
import Interweave from "../../../../features/Interweave";
import f from "../../../../helpers/formatText";

export default function EditEmail() {
	const {
		localisation: { auth },
		authService: { user },
	} = useAppContext();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [apiError, setApiError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);

	const submit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setApiError(null);

		try {
			setIsLoading(true);

			const response = await api.auth.checkIsEmailExists(email);
			if (response.status === 200) {
				if (response.data.is_exists) {
					setApiError(auth.changeEmailExistErr);
					return;
				}
			}

			const sendPayload: SendConfirmEmailData = {
				email: email,
				purpose: "change_email",
				user_id: user?.id,
				is_external_user: !user?.email,
			};

			await api.auth.sendConfirmEmail(sendPayload);
			setSuccess(true);
		} catch (ex: any) {
			setApiError(ex.response?.data?.detail || ex.message || "unknown error");
		} finally {
			setIsLoading(false);
		}
	};

	if (success) {
		return (
			<Alert
				severity={"success"}
				variant={"outlined"}
				className={"small ms-1 p-3 w-100 mt-2"}
			>
				<AlertTitle>
					<Interweave
						defaultLinkColor={"success.main"}
						content={auth.changeEmailConfirmSentTitle}
					/>
				</AlertTitle>
				<Interweave
					defaultLinkColor={"success.main"}
					content={f(auth.changeEmailConfirmSent, { email })}
				/>
			</Alert>
		);
	}

	return (
		<form onSubmit={submit}>
			<TextField
				sx={{ mb: 2 }}
				required
				size={"medium"}
				fullWidth
				id={"email"}
				type={"email"}
				label={auth.newEmailLabel}
				value={email}
				onChange={e => setEmail(e.target.value)}
			/>

			<FormFooter isLoading={isLoading} apiError={apiError} />
		</form>
	);
}

import { Box, Divider, IconButton, InputAdornment } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import useShare from "./hooks/useShare";
import { ReactNode } from "react";
import useAppContext from "../useAppContext";

export interface ShareTextInInputProps {
	url?: string;
	text?: string;
	imgUrl?: string;
	helperText?: ReactNode;
	textFieldProps?: TextFieldProps;
}

export default function ShareTextInInput(props: ShareTextInInputProps) {
	const {
		toastService,
		localisation: { global: locale },
	} = useAppContext();
	const { tryShare, isShareAvailable } = useShare();
	const onShareClick = async () => {
		let shareObj: ShareData = {
			files: [],
			url: props.url,
			text: props.text,
		};

		if (props.imgUrl) {
			try {
				const response = await fetch(props.imgUrl);
				if (response.ok) {
					const blob = await response.blob();
					shareObj.files = [
						new File([blob], "image.jpg", {
							type: blob.type,
						}),
					];
				} else {
					console.error("Fetch failed:", response.status);
				}
			} catch (error) {
				console.error("Fetch error:", error);
			}
		}

		await tryShare(shareObj);
	};

	const onCopyCLick = () => {
		const textToCopy = props.url || props.text;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy).then(() => {
				toastService.showToast({
					severity: "success",
					message: locale.copiedText,
				});
			});
		}
	};

	return (
		<TextField
			sx={{ mt: 2 }}
			fullWidth
			size={"medium"}
			value={props.url || props.text}
			helperText={props.helperText}
			{...props.textFieldProps}
			InputProps={{
				readOnly: true,
				...props.textFieldProps?.InputProps,
				sx: {
					pr: 2,
					...props.textFieldProps?.InputProps?.sx,
				},
				endAdornment: (
					<InputAdornment position={"end"}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								"& hr": {
									mx: 0.5,
								},
							}}
						>
							{isShareAvailable && (
								<>
									<IconButton onClick={onShareClick}>
										<ShareIcon />
									</IconButton>
									<Divider flexItem orientation={"vertical"} />
								</>
							)}
							<IconButton onClick={onCopyCLick}>
								<ContentCopyIcon />
							</IconButton>
						</Box>
					</InputAdornment>
				),
			}}
		/>
	);
}

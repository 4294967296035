import { IncustPayAccountData } from "./types";
import { CreditType, YesNoPossible } from "../../../loyalty/types";

export interface UnifiedSpecialAccountDataLimit {
	limit: number;
	available: number;
	used: number;
}

export type UnifiedSpecialAccountData = {
	id: string;
	title: string;
	available_amount: number | null;
	currency: string;
	limits: {
		month: UnifiedSpecialAccountDataLimit | null;
		day: UnifiedSpecialAccountDataLimit | null;
	} | null;
	security_code: boolean;
	odometer: YesNoPossible;
	vehicle_id: YesNoPossible;
	credit_type: CreditType;
	is_unlimited: boolean;
};

export default function useUnifiedSpecialAccountData<
	T extends IncustPayAccountData | null,
	RT extends T extends IncustPayAccountData ? UnifiedSpecialAccountData : null,
>(data: T): RT {
	if (data === null) {
		return null as RT;
	}

	switch (data.type) {
		case "special-account":
			return {
				id: data.special.id,
				title: data.special.title,
				available_amount: data.special.available_amount,
				currency: data.special.special_account.currency,
				limits: data.special.limits
					? {
							month: data.special.limits.month_limit
								? {
										limit: data.special.limits.month_limit,
										available: data.special.limits.month_limit_available || 0,
										used: data.special.limits.month_limit_used || 0,
									}
								: null,
							day: data.special.limits.day_limit
								? {
										limit: data.special.limits.day_limit,
										available: data.special.limits.day_limit_available || 0,
										used: data.special.limits.day_limit_used || 0,
									}
								: null,
						}
					: null,
				security_code: data.special.security_code,
				odometer: data.special.odometer,
				vehicle_id: "no",
				credit_type: data.special.credit_type,
				is_unlimited:
					data.special.credit_type === "credit-unlimited" &&
					data.special.available_amount === null,
			} as RT;
		case "corporate-special-account-access":
			const specialAccount =
				data.corporate_special_account_access.corporate_customer_special_account
					.special_account;
			return {
				id: data.corporate_special_account_access.id,
				title: specialAccount.title,
				available_amount: data.corporate_special_account_access.available_amount,
				currency: specialAccount.currency,
				limits: data.corporate_special_account_access.limits
					? {
							month: data.corporate_special_account_access.limits.user_month_limit
								? {
										limit: data.corporate_special_account_access.limits
											.user_month_limit,
										available:
											data.corporate_special_account_access.limits
												.user_month_limit_available || 0,
										used:
											data.corporate_special_account_access.limits
												.user_month_limit_used || 0,
									}
								: null,
							day: data.corporate_special_account_access.limits.user_day_limit
								? {
										limit: data.corporate_special_account_access.limits
											.user_day_limit,
										available:
											data.corporate_special_account_access.limits
												.user_day_limit_available || 0,
										used:
											data.corporate_special_account_access.limits
												.user_day_limit_used || 0,
									}
								: null,
						}
					: null,
				security_code: data.corporate_special_account_access.security_code,
				odometer: data.corporate_special_account_access.odometer,
				vehicle_id: data.corporate_special_account_access.vehicle_id,
				credit_type:
					data.corporate_special_account_access.corporate_customer_special_account
						.credit_type,
				is_unlimited:
					data.corporate_special_account_access.corporate_customer_special_account
						.credit_type === "credit-unlimited" &&
					data.corporate_special_account_access.available_amount === null,
			} as RT;
	}
}

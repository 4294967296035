import React from "react";
import {Button, ButtonProps} from "@mui/material";


export default function TgButton({children, size = 'large', variant = 'contained', ...props}: ButtonProps) {
    return (
        <Button
            size={size}
            variant={variant}
            {...props}
        >
            {children}
        </Button>
    )
}
